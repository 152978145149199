import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, Avatar, Button, Icon, Tooltip, Alert, Modal } from 'antd';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import agodaHomesLogo from 'images/agodahomes-logo-icon.png';
import bookingcomLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import staysuitesLogo from 'images/staysuites-logo-icon.png';
import hostPlatformLogo from 'images/hostplatform-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';
import BELogo from 'images/HBE-BE-logo.png';

import FormSelection from 'components/FormSelection/FormSelection';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import { findArrayValueAndIndex } from 'utils/general';
import intl from 'react-intl-universal';

import styles from './MCRateUpdate.module.css';

const otaToLogo = {
  web: { label: 'HostPlatform', logo: hostPlatformLogo },
  agoda: { label: 'Agoda', logo: agodaLogo },
  agodahomes: { label: 'Agoda Homes', logo: agodaHomesLogo },
  airbnb: { label: 'Airbnb', logo: airbnbLogo },
  bookingcom: { label: 'Booking.com', logo: bookingcomLogo },
  ctrip: { label: 'Ctrip', logo: ctripLogo },
  ctripCM: { label: 'Ctrip V2', logo: ctripLogo },
  expedia: { label: 'Expedia', logo: expediaLogo },
  staysuites: { label: 'StaySuites', logo: staysuitesLogo },
  traveloka: { label: 'Traveloka', logo: travelokaLogo },
  tiketcom: { label: 'Tiket.com', logo: tiketcomLogo },
  hbe: { label: 'Booking Website', logo: BELogo }
};

const DATA_TYPE_ROOM_TYPE = { code: 'roomType', label: 'Room Type' };
const DATA_TYPE_UNIT = { code: 'unit', label: 'Unit' };
const SUPPORTED_DATA_TYPES = [DATA_TYPE_ROOM_TYPE, DATA_TYPE_UNIT];
const SUPPORTED_DATA_TYPES_CODE_ONLY = SUPPORTED_DATA_TYPES.map(dataType => dataType.code);

const generateTypeObj = type => {
  if (SUPPORTED_DATA_TYPES_CODE_ONLY.includes(type)) {
    const key = type;
    const label = SUPPORTED_DATA_TYPES.find(dataType => dataType.code === type).label;

    return {
      key,
      label,
      itemSelectionFormName: `${key}sApplied`,
      itemSelectionFormLabel: `${label}s Applied`,
      rateFormNamePrefix: `${key}sRate`
    };
  }
};

const ItemInfo = ({ item }) => {
  console.log(otaToLogo, item.rates, 58);
  return (
    <span>
      <span style={{ marginRight: '2px' }}>{item.name}</span>
      {item.rates.map(rate => (
        // <Avatar key={rate.source} size={15} src={otaToLogo[rate.source].logo} style={{ marginRight: '2px' }} />
        <Avatar key={rate.name} size={15} src={otaToLogo[rate.source].logo} style={{ marginRight: '2px' }} />
      ))}
    </span>
  );
};

const RateInfo = ({ rate }) => {
  console.log(rate, 71);
  return (
    <div>
      <Avatar size="small" src={otaToLogo[rate.code].logo} />
      <span>{intl.get(`multicalendar.rate.${rate.label.replace(/\./g, '_')}`).d(rate.label)}</span>
    </div>
  );
};

const DerivedRateInfo = ({ derivedRateUnits, onViewMoreUnitsClick, label = '' }) => {
  const DISPLAY_LIMIT = 5;

  const Message = () => {
    return (
      <div className={styles.warningLabel}>
        {intl
          .getHTML('multicalendar.headerLabels.derivedInfo', { label })
          .d(`There are some ${label} using <strong>Derived Rate</strong> and those ${label} price will derive from Web Rate Price instead.`)}
        <Tooltip
          placement="right"
          title={() => {
            return (
              <div>
                <p>{intl.getHTML('multicalendar.headerLabels.derivedInfo2', { label }).d(`These ${label} are using derived rate:`)}</p>
                <ul>
                  {derivedRateUnits.slice(0, DISPLAY_LIMIT + 1).map((unit, index) => {
                    if (index < DISPLAY_LIMIT) {
                      return <li>{`${unit.name}`}</li>;
                    } else {
                      return (
                        <span className={styles.viewMoreLink} onClick={onViewMoreUnitsClick}>{`${intl
                          .get('multicalendar.headerLabels.viewAll')
                          .d('View all')} ${derivedRateUnits.length} ${label}`}</span>
                      );
                    }
                  })}
                </ul>
              </div>
            );
          }}
        >
          <Icon type="question-circle" />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles.derivedRateInfoContainer}>
      <Alert message={<Message />} type="warning" showIcon />
    </div>
  );
};

const FormInputRate = ({ form, name, defaultValue, placeholder, currency = '' }) => {
  return (
    <FormInputNumber
      form={form}
      name={name}
      className={styles.rateInput}
      precision={2}
      minValue={currency === 'USD' ? 10 : 50}
      // maxValue={20000}
      // formatter={value => value && `${value}`}
      // parser={value => value.replace(/[RM]\s?/g, '')}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/(,*)/g, '')}
      placeholder={`${currency ? currency : ''} ${placeholder}`}
      defaultValue={defaultValue}
    />
  );
};

const generateColumns = (form, selectedDataType, onViewMoreClick, currency) => {
  return [
    {
      title: intl.get('multicalendar.tableColumns.rateName').d('Rate Name'),
      dataIndex: 'rate.name',
      width: 170,
      render: (value, row, index) => {
        return (
          <>
            <RateInfo rate={row} />
            {row.derivedRateUnits && row.derivedRateUnits.length > 0 && (
              <DerivedRateInfo
                label={intl.get(`multicalendar.rateUpdateType.${selectedDataType.label}`).d(selectedDataType.label)}
                derivedRateUnits={row.derivedRateUnits}
                onViewMoreUnitsClick={onViewMoreClick(index)}
              />
            )}
          </>
        );
      }
    },
    {
      title: `${intl.get('multicalendar.tableColumns.new').d('New')}${intl
        .get(`multicalendar.rateUpdateType.${selectedDataType.label}`)
        .d(selectedDataType.label)}${intl.get('multicalendar.tableColumns.rate').d('Rate')} (${currency})`,
      width: 160,
      render: (value, row) => {
        const formName = `${selectedDataType.rateFormNamePrefix}.${row.code}`;
        return <FormInputRate form={form} name={formName} placeholder={'0.00'} currency={currency} />;
      }
    }
  ];
};

const formatToRates = (selectedItems, rateTypes) => {
  return selectedItems.reduce((allCombinedRates, item) => {
    item.rates.forEach(itemRate => {
      const [foundRate, foundIndex] = findArrayValueAndIndex(allCombinedRates, combinedRate => {
        return combinedRate.code === itemRate.source;
      });
      const foundRateType = rateTypes.find(rateType => rateType.code === itemRate.source);
      if (!foundRate) {
        allCombinedRates = [
          ...allCombinedRates,
          {
            ...foundRateType,
            derivedRateUnits: itemRate.isDerived
              ? [
                  {
                    id: item.id,
                    name: item.name
                  }
                ]
              : []
          }
        ];
      } else {
        if (itemRate.isDerived) {
          allCombinedRates[foundIndex] = {
            ...allCombinedRates[foundIndex],
            derivedRateUnits: [
              ...allCombinedRates[foundIndex].derivedRateUnits,
              {
                id: item.id,
                name: item.name
              }
            ]
          };
        }
      }
    });
    return allCombinedRates;
  }, []);
};

const MCRateUpdate = ({
  form,
  type,
  selectAllButtonId,
  clearAllButtonId,
  items,
  selectedItems,
  rateTypes,
  disclaimer,
  onSelectionChange,
  onSelectAll,
  onClearAll,
  currency,
  timezone
}) => {
  //tmp workaround, as rateTypes is not passing in data sometimes  !!
  const otaRates = [
    { code: 'web', label: 'Web Rate' },
    { code: 'airbnb', label: 'Airbnb Rate' },
    { code: 'agoda', label: 'Agoda Rate' },
    { code: 'agodahomes', label: 'Agoda Homes Rate' },
    { code: 'bookingcom', label: 'Booking.com Rate' },
    { code: 'ctrip', label: 'Ctrip Rate' },
    { code: 'expedia', label: 'Expedia Rate' },
    { code: 'ctripCM', label: 'Ctrip V2' },
    { code: 'staysuites', label: 'StaySuites Rate' },
    { code: 'derantau', label: 'DeRantau' },
    { code: 'traveloka', label: 'Traveloka Rate' },
    { code: 'tiketcom', label: 'Tiket.com Rate' }
  ];

  const shouldDisable = !items || items.length === 0;
  const shouldDisableAll = shouldDisable || items.length === selectedItems.length;
  const shouldDisableClear = shouldDisable || selectedItems.length === 0;
  const combinedRates = useMemo(() => formatToRates(selectedItems, otaRates), [selectedItems, otaRates]);
  const selectedDataType = useMemo(() => generateTypeObj(type), [type]);

  const handleOnViewMoreClick = index => () => {
    Modal.info({
      title: `${intl.get(`multicalendar.rateUpdateType.${selectedDataType.label}`).d(selectedDataType.label)} ${intl
        .get('multicalendar.headerLabels.usingDerived')
        .d('using derived rate')}`,
      content: (
        <div>
          {combinedRates.length > 0 &&
            combinedRates[index] &&
            combinedRates[index].derivedRateUnits &&
            combinedRates[index].derivedRateUnits.map(derivedRateUnit => {
              return <div key={derivedRateUnit.id}>{derivedRateUnit.name}</div>;
            })}
        </div>
      )
    });
  };

  const columns = generateColumns(form, selectedDataType, handleOnViewMoreClick, currency);
  const formLabel = disclaimer ? (
    <span>
      {intl.get(`multicalendar.rateUpdateType.${selectedDataType.itemSelectionFormLabel}`).d(selectedDataType.itemSelectionFormLabel)}{' '}
      <Tooltip title={disclaimer}>
        <Icon type="question-circle" />
      </Tooltip>
    </span>
  ) : (
    intl.get(`multicalendar.rateUpdateType.${selectedDataType.itemSelectionFormLabel}`).d(selectedDataType.itemSelectionFormLabel)
  );

  return !selectedDataType ? (
    <div>Type {type} is not supported </div>
  ) : (
    <Row gutter={16}>
      <Col span={8}>
        <FormSelection
          form={form}
          multipleMode={true}
          formLabel={formLabel}
          name={selectedDataType.itemSelectionFormName}
          placeholder={intl.get(`multicalendar.rateUpdateType.${selectedDataType.label}`).d(selectedDataType.label)}
          selections={items.map(item => {
            return {
              key: item.id,
              displayValue: <ItemInfo item={item} />,
              filterValue: item.name
            };
          })}
          defaultValue={[]}
          optionFilterProp="filtervalue"
          onChange={onSelectionChange}
        />
        {onSelectAll && (
          <Button id={selectAllButtonId} ghost type="primary" disabled={shouldDisableAll} onClick={onSelectAll}>
            {intl.get('multicalendar.headerLabels.selectAllLabel').d('Select All')}
          </Button>
        )}
        {onSelectAll && onClearAll && ' '}
        {onClearAll && (
          <Button id={clearAllButtonId} ghost type="primary" disabled={shouldDisableClear} onClick={onClearAll}>
            {intl.get('multicalendar.headerLabels.clearAll').d('Clear All')}
          </Button>
        )}
      </Col>
      <Col span={16}>
        {combinedRates && combinedRates.length > 0 && (
          <Table rowKey={record => `roomtype.${record.code}`} columns={columns} dataSource={combinedRates} bordered pagination={false} />
        )}
      </Col>
    </Row>
  );
};

MCRateUpdate.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.oneOf(SUPPORTED_DATA_TYPES_CODE_ONLY).isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  rateTypes: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearAll: PropTypes.func,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

export default MCRateUpdate;
