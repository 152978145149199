import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { generateEndDate, guard, numberWithCommas } from 'utils/general';

import HostProtect from './components/HostProtect/HostProtect';

import './ExpandedDetails.css';
import intl from 'react-intl-universal';

const DATE_FORMAT = 'YYYY-MM-DD';

const getDateRange = (selectedYear, selectedMonth, startDay) => {
  const startDate = moment(`${selectedMonth + 1}-1-${selectedYear}`)
    .add(startDay - 1, 'days')
    .format(DATE_FORMAT);
  const endDate = generateEndDate(startDate, { monthNumber: 1 }, 1).format(DATE_FORMAT);

  return `${startDate} to ${endDate}`;
};

const calculateOwnerRevenue = (totalExpenses, nettProfit) => {
  return Number(totalExpenses) + Number(nettProfit);
};

const calculateTotalNetProfit = (totalRevenue, totalExpenses) => {
  return Number(totalRevenue) - Number(totalExpenses);
};

const getCurrency = payoutDetail => guard(() => payoutDetail.unit.currency, 'RM');

const ExpandedDetails = ({ year, month, payoutDetail, hasProtection, isProtectionActive, shouldSplitBySqft, calculateOtherCharges }) => {
  const dateRange = getDateRange(year, month, payoutDetail.servicePackage.startDay);
  const isOneUnit = payoutDetail.servicePackage.numberOfUnits === 1;
  return (
    <div className="medium-font marginBottom20">
      <HostProtect protection={payoutDetail.protection} hasProtection={hasProtection} isProtectionActive={isProtectionActive} />
      <Row type="flex" justify="start" className="big-font gray">
        <Col>{payoutDetail.servicePackage.name}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        {intl.get('payout.headerLabels.range').d('Date Range')}
        <Col>{dateRange}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        {intl.get('payout.headerLabels.unitSize').d('Unit Size (square feet)')}
        <Col>
          {shouldSplitBySqft
            ? payoutDetail.servicePackage.sqFts.filter(split => split.id === payoutDetail.property.roomType)[0].sqft
            : payoutDetail.servicePackage.roomSizeInSqFt}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        {intl.get('payout.headerLabels.totalUnit').d('Total Units per Service Package')}
        <Col>{payoutDetail.servicePackage.numberOfUnits}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="big-font">{intl.get('payout.headerLabels.totalNet').d('Total Net Revenue')}</div>
        <div className="big-font">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </div>
      </Row>
      <Row type="flex" justify="space-between">
        <hr />
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          {intl.get('payout.headerLabels.freeUsed').d('Free stay used')} <br />
          {payoutDetail.freeStay.used}
          <span className="gray"> {intl.get('payout.headerLabels.night').d('night(s)')}</span>
        </Col>
        <Col span={9}>
          {intl.get('payout.headerLabels.freeBalance').d('Free stay balance')} <br />
          {payoutDetail.freeStay.balance}
          <span className="gray"> {intl.get('payout.headerLabels.night').d('night(s)')}</span>
        </Col>
      </Row>
      <hr />
      <Row type="flex" justify="space-between">
        <div className="big-font gray">{intl.get('payout.headerLabels.summary').d('Booking Summary')}</div>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="calendarSummary">
          {payoutDetail.reservations.map(r => {
            let startDate = r.startDate.substring(5).replace('-', '/');
            let endDate = r.endDate.substring(5).replace('-', '/');
            let cName = '';
            let stripeCharges = r.charges.stripeCharges ? r.charges.stripeCharges : 0;
            let bookingFee = `${getCurrency(payoutDetail)} ` + numberWithCommas(r.charges.total + stripeCharges);
            let toolTipTitle = (
              <>
                {intl.get('payout.headerLabels.bookingNet').d('Booking Net Price:')} {getCurrency(payoutDetail)} {numberWithCommas(r.charges.rental)}
                <br />
                {intl.get('payout.headerLabels.cleaningPaid').d('Cleaning Paid By Guest:')} {getCurrency(payoutDetail)}
                {numberWithCommas(r.charges.cleaning)}
                <br />
                {intl.get('payout.headerLabels.extraGuest').d('Extra Guest Fee:')} {getCurrency(payoutDetail)}{' '}
                {numberWithCommas(r.charges.extraGuest)}
                <br />
                {intl.get('payout.headerLabels.otherShared').d('Other Shared Charges:')} {getCurrency(payoutDetail)}{' '}
                {numberWithCommas(calculateOtherCharges(r.charges))}
              </>
            );
            return (
              <Row key={r.id} className={cName} type="flex">
                <Col className="float-center word-wrap" span={12}>
                  {startDate}
                  &nbsp;-&nbsp;
                  {endDate}
                </Col>
                <Col className="float-center" span={12}>
                  {r.numberOfPax} {intl.get('payout.headerLabels.pax').d('pax')}
                </Col>
                <Col className="float-center" span={12}>
                  {r.source || r.platform || '-'}
                </Col>
                <Tooltip overlayClassName="medium-font" placement="right" title={toolTipTitle}>
                  <Col span={12} className="float-center">
                    {bookingFee}
                  </Col>
                </Tooltip>
                <hr />
              </Row>
            );
          })}
        </div>
      </Row>
      <br />
      <Row type="flex" justify="space-between">
        <div className="bold">{intl.get('payout.headerLabels.totalReservation').d('Total Reservations')}</div>
        <div className="bold">{payoutDetail.reservations.filter(b => b.status === 'Confirmed' || 'checkin' || 'checkout').length}</div>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="bold">{intl.get('payout.headerLabels.bookingSummary').d('Booking Summary Total')}</div>
        <div className="bold">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </div>
      </Row>
      <hr />
      {payoutDetail.expenses && payoutDetail.expenses.length > 0 ? (
        <div>
          <Row type="flex" justify="space-between">
            <Col className="gray big-font">
              {isOneUnit
                ? `${intl.get('payout.headerLabels.expensesName').d('Expenses')}`
                : `${intl.get('payout.headerLabels.expensesNameMsg1').d('Expenses of')} ${payoutDetail.servicePackage.numberOfUnits} ${intl
                    .get('payout.headerLabels.expensesNameMsg2')
                    .d('Units')}`}
            </Col>
          </Row>
          {payoutDetail.expenses.map(expense => (
            <Row key={expense.name} type="flex" justify="space-between">
              {intl.get(`expenses.utilityType.${expense.name}`).d(expense.name)}
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(expense.amount)}
              </Col>
            </Row>
          ))}
          <Row type="flex" justify="space-between">
            <Col className="bold">{intl.get('payout.headerLabels.totalExpense').d('Total Expense')}</Col>
            <Col className="bold">
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalExpenses)}
              </Col>
            </Col>
          </Row>
          {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
            <Row type="flex" justify="space-between" className="small-font gray">
              {intl.get('payout.headerLabels.borneHost').d('Borne by Host:')}
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.host)}
              </Col>
            </Row>
          )}
          {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
            <Row type="flex" justify="space-between" className="small-font gray">
              {intl.get('payout.headerLabels.borneOwner').d('Borne by Owner:')}
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.owner)}
              </Col>
            </Row>
          )}
        </div>
      ) : (
        ''
      )}
      <hr />
      <Row className="big-font bold" type="flex" justify="space-between">
        {intl.get('payout.headerLabels.totalNet').d('Total Net Revenue')}
        <Col>
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </Col>
      </Row>
      {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
        <Row className="big-font bold" type="flex" justify="space-between">
          {intl.get('payout.headerLabels.totalProfit').d('Total Net Profit')}
          <Col>
            {getCurrency(payoutDetail)} {numberWithCommas(calculateTotalNetProfit(payoutDetail.totalRevenue, payoutDetail.borneExpenses.shared))}
          </Col>
        </Row>
      ) : (
        <Row className="big-font bold" type="flex" justify="space-between">
          {intl.get('payout.headerLabels.ownerRevenue').d("Owner's Revenue")}
          <Col>
            {getCurrency(payoutDetail)} {numberWithCommas(calculateOwnerRevenue(payoutDetail.borneExpenses.owner, payoutDetail.nettOwnersProfit))}
          </Col>
        </Row>
      )}
      {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
        <Row type="flex" justify="space-between">
          <Col className="red">{intl.get('payout.headerLabels.ownerBorne').d('Owner Borne Expenses')}</Col>
          <Col className="red">
            {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.owner)}
          </Col>
        </Row>
      )}
      <Row className="big-font" type="flex" justify="space-between">
        <Col>{intl.get('payout.headerLabels.ownerProfit').d("Owner's Net Profit")}</Col>
        <Col className="bold">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.nettOwnersProfit)}
        </Col>
      </Row>
      {!isOneUnit && (
        <Row className="big-font" type="flex" justify="space-between">
          <Col>{intl.get('payout.headerLabels.perUnit').d('Per Unit Net Revenue')}</Col>
          <Col className="bold">
            {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.perUnitRevenue)}
          </Col>
        </Row>
      )}
    </div>
  );
};

ExpandedDetails.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  payoutDetail: PropTypes.object.isRequired,
  hasProtection: PropTypes.bool,
  isProtectionActive: PropTypes.bool,
  shouldSplitBySqft: PropTypes.bool.isRequired,
  calculateOtherCharges: PropTypes.func.isRequired
};

ExpandedDetails.defaultProps = {
  hasProtection: false,
  isProtectionActive: false
};

export default ExpandedDetails;
