import React, { Component } from 'react';
import { Table, Button, Card, Input, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { getServicePackage } from '../../utils/apis/servicePackage';
import './ServicePackage.css';
import { guard, numberWithCommas } from './../../utils/general';
import { CSVLink } from 'react-csv';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const moment = require('moment');

class ServicePackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchNameText: '',
      searchUnitText: '',
      isLoading: true
    };
  }

  componentWillMount = () => {
    getServicePackage()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            data: res.data,
            isLoading: false
          });
        } else {
          console.log('There was an error fetching service package data');
          this.setState({ isLoading: false });
        }
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isLoading: false });
      });
  };

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  getColumns = checkIsAdminReadOnly => {
    return [
      {
        title: intl.get('servicePackage.tableColumns.package').d('Package Name'),
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          if (checkIsAdminReadOnly()) {
            return <span className="links">{text}</span>;
          } else {
            return (
              <Link to={`/servicepackage/${record._id}/edit`}>
                <span className="links">{text}</span>
              </Link>
            );
          }
        },

        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.package').d('Search Package Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchNameText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.host').d('Host'),
        dataIndex: 'host',
        key: 'host',
        render: (text, record) => text,
        sorter: (a, b) => a.host.localeCompare(b.host),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.hostName').d('Search Host')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchHostNameText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.host) return record.host.toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.packageType').d('Package Type'),
        dataIndex: 'packageType',
        key: 'packageType',
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return intl.get(`servicePackage.packageType.${item.packageType}`).d('Package Type');
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => intl.get(`servicePackage.packageType.${record.packageType}`).d(record.packageType) === value,
        render: (text, record) => {
          return intl.get(`servicePackage.packageType.${record.packageType}`);
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.revenueShare').d('Revenue Share'),
        dataIndex: 'revenueShare',
        key: 'revenueShare',
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return intl.get(`servicePackage.revenueShare.${item.revenueShare}`).d(item.revenueShare);
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => intl.get(`servicePackage.revenueShare.${record.revenueShare}`).d(record.revenueShare) === value,
        render: (text, record) => {
          return intl.get(`servicePackage.revenueShare.${record.revenueShare}`.d(record.revenueShare));
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.units').d('Units'),
        dataIndex: 'units',
        key: 'units',
        render: (text, record) => {
          const unitsToRender = record.units.slice(0, 5);
          const firstFiveUnits = unitsToRender.map(unit => {
            return (
              <React.Fragment key={unit.property + unit.code}>
                <span>{`${unit.property} | ${unit.code}`}</span>
                <br />
              </React.Fragment>
            );
          });
          return (
            <React.Fragment>
              {firstFiveUnits}
              {record.units.length > 5 ? (
                <span>
                  ... {intl.get('servicePackage.headerLabels.and').d('and')} {record.units.length - 5}{' '}
                  {intl.get('servicePackage.headerLabels.moreOf').d('more of')} {record.units.length}{' '}
                  {intl.get('servicePackage.headerLabels.unit').d('')}
                </span>
              ) : null}
            </React.Fragment>
          );
        },
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.unit').d('Search Unit')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUnitText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) =>
          record.units.filter(unit => (unit.property + ' ' + unit.code).toLowerCase().includes(value.toLowerCase())).length > 0,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.ownerShare').d('Owner Share'),
        dataIndex: 'ownerShare',
        key: 'ownerShare',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.ownerShare - b.ownerShare,
        render: (text, record) => {
          return text ? <span>{text}%</span> : 'N/A';
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.threshold').d('Threshold'),
        dataIndex: 'threshold',
        key: 'threshold',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.threshold - b.threshold,
        render: (text, record) => {
          return text ? (
            <span>
              {guard(() => record.currency, 'RM')} {numberWithCommas(text)}
            </span>
          ) : (
            'N/A'
          );
        }
      },
      {
        title: intl.get('servicePackage.tableColumns.ownerShareAfter').d('Owner Share After'),
        dataIndex: 'ownerShareAfterThreshold',
        key: 'ownerShareAfterThreshold',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.ownerShareAfterThreshold - b.ownerShareAfterThreshold,
        render: (text, record) => {
          return text ? <span>{text}%</span> : 'N/A';
        }
      }
    ];
  };

  constructCSV = () => {
    const { data } = this.state;
    return data.map(data => {
      const expensesSettingArray = Object.keys(data.expensesSetting).map(
        k =>
          intl.get(`servicePackage.expenseSetting.${k}`).d(k) +
          '-' +
          intl.get(`servicePackage.expensesPeople.${data.expensesSetting[k]}`).d(data.expensesSetting[k])
      );

      return {
        [intl.get('csv.packageName').d('Package Name')]: data.name,
        [intl.get('csv.packageType').d('Package Type')]: intl.get(`servicePackage.packageType.${data.packageType}`).d(data.packageType),
        [intl.get('csv.splitSquareFeet').d('Split By Square Feet')]: data.splitBySqft
          ? intl.get(`csv.trueFalse.${String(data.splitBySqft)}`).d(String(data.splitBySqft))
          : intl.get('csv.trueFalse.False').d('False'),
        [intl.get('csv.revenueShare').d('Revenue Share')]: intl.get(`servicePackage.revenueShare.${data.revenueShare}`.d(data.revenueShare)),
        [intl.get('csv.unit').d('Units')]: data.units.reduce(
          (accumulator, currentValue) => accumulator + currentValue.property + '|' + currentValue.code + ', ',
          ''
        ),
        [intl.get('csv.ownerShare').d('Owner Share')]: data.ownerShare,
        [intl.get('csv.threshold').d('Threshold')]: data.threshold,
        [intl.get('csv.ownerAfter').d('Owner Share After Threshold')]: data.ownerShareAfterThreshold,
        [intl.get('csv.cleaningPaidGuest').d('Cleaning Paid by Guest')]: data.cleaningPaidByGuest
          ? intl.get('csv.trueFalse.True').d('True')
          : intl.get('csv.trueFalse.False').d('False'),
        [intl.get('csv.calculations').d('Calculations')]: undefined,
        [intl.get('csv.expensesSettings').d('Expenses Settings')]: expensesSettingArray
      };
    });
  };

  render() {
    const { data, isLoading } = this.state;
    const { checkIsAdminReadOnly } = this.props;
    const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = String('service_package_')
      .concat(curDateTime.toString())
      .concat('.csv');
    return (
      <Card className="sp-list-card">
        <div className="sp-controls">
          <Link to="/servicepackage/new">
            <Button id="create-button7-sp" type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
              {intl.get('servicePackage.headerLabels.create').d('Create Service Package')}
            </Button>
          </Link>
          {this.props.checkAbleExportServicePackage() && (
            <CSVLink filename={csvName} data={this.constructCSV()} className="sp-csv">
              <Button id="csv-button6-sp" type="primary" icon="download" disabled={checkIsAdminReadOnly()}>
                {intl.get('servicePackage.headerLabels.csv').d('Download CSV')}
              </Button>
            </CSVLink>
          )}
        </div>
        <Table columns={this.getColumns(checkIsAdminReadOnly)} dataSource={data} scroll={{ x: 1000 }} loading={isLoading} />
      </Card>
    );
  }
}

export default withRouter(withAppContext(ServicePackage));
