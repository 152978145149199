import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';

import styles from './FormRadioButton.module.css';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const addRules = requiredErrorMessage => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return rules;
};

const FormRadioButton = ({
  buttonStyle,
  defaultValue,
  form,
  formLabel,
  label,
  onChange,
  name,
  requiredErrorMessage,
  size,
  selections,
  shouldDisable
}) => {
  const rules = addRules(requiredErrorMessage);
  return (
    <Fragment>
      {formLabel && <p className={styles.label}>{label}</p>}
      <FormItem label={formLabel} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(
          <RadioGroup disabled={shouldDisable} name={name} buttonStyle={buttonStyle} size={size} onChange={onChange}>
            {selections.map(selection => {
              let translation = getTranslation(selection, [
                `invoice.paymentStatus.${selection.displayValue}`
                // Add more translation keys as needed
              ]);
              return (
                <RadioButton key={selection.key || selection.value} value={selection.value}>
                  {translation ? translation : selection.displayValue || selection.label || selection.value}
                </RadioButton>
              );
            })}
          </RadioGroup>
        )}
      </FormItem>
    </Fragment>
  );
};

FormRadioButton.propTypes = {
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  form: PropTypes.object.isRequired,
  formLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  shouldDisable: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large'])
};

FormRadioButton.defaultProps = {
  buttonStyle: 'outline',
  defaultValue: undefined,
  form: {},
  label: '',
  onChange: undefined,
  onSearch: undefined,
  placeholder: '',
  shouldDisable: false,
  requiredErrorMessage: '',
  size: 'default'
};

export default FormRadioButton;

function getTranslation(selection, keys) {
  for (let key of keys) {
    let translation = intl.get(key);
    if (translation) {
      return translation;
    }
  }
  return null;
}

/* --------------------------------------Bare Radio Button------------------------------------------- */
export const BareRadioButton = ({ options, defaultValue, buttonStyle, isDiabled, onChange, value }) => {
  defaultValue = defaultValue || options[0].value;

  return (
    <RadioGroup defaultValue={defaultValue} buttonStyle={buttonStyle} disabled={isDiabled} onChange={onChange} {...(value && { value })}>
      {options.map(option => {
        let translation = getTranslation(option, [
          `rateModifier.rateModifierType.${option.label}`,
          `rateModifier.status.${option.label}`,
          `listings.listingType.${option.label}`,
          `reservations.typeReservation.${option.label}`
        ]);
        return (
          <RadioButton id={option.id} key={option.value} value={option.value}>
            {/* Displaying translated label if available, otherwise fallback to default label */}
            {translation ? translation : option.label || option.value}
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
};

BareRadioButton.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  isDiabled: PropTypes.bool,
  onChange: PropTypes.func
};

BareRadioButton.defaultProps = {
  buttonStyle: 'solid',
  isDiabled: false,
  onChange: undefined
};
/* --------------------------------------Bare Radio Button------------------------------------------- */
