import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, Result, Spin, message } from 'antd';
import ReactMarkdown from 'react-markdown';
import { getAgodaContract, signAgodaContract } from '../../../../utils/apis/agodaOnboarding';
import intl from 'react-intl-universal';

const SignAgodaContact = props => {
  const [loading, setLoading] = useState(false);
  const [agodaContact, setAgodaContact] = useState(``);
  const [contractCode, setContractCode] = useState('');

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const sampleMarkdown = '';

  useEffect(() => {
    console.log(props, 15);
    if (props.visible && props.selectedAgoda._id) {
      retrieveAgodaContract();
    }
  }, [props]);

  const retrieveAgodaContract = async () => {
    const { _id } = props.selectedAgoda;
    setLoading(true);
    const res = await getAgodaContract(_id);
    console.log(res, 24);
    if (res.status !== 'ok') {
      setIsError(true);
      setErrorMsg(res.message);
    } else {
      setIsError(false);
      setAgodaContact(res.data);
      setContractCode(res.ContractCode);
    }
    setLoading(false);
  };

  const SignAgodaContact = async () => {
    const { _id } = props.selectedAgoda;
    setLoading(true);
    const res = await signAgodaContract(_id, contractCode);
    console.log(res, 24);
    if (res.status !== 'ok') {
      message.error(`Error, ${res.message ? res.message : intl.get('hostConnect.agoda.message.failSign').d('Fail to sign contract')}`);
    } else {
      Modal.success({
        title: intl.get('hostConnect.agoda.message.yay').d('Yay! Signing contract success'),
        onOk: () => {
          window.location.reload();
        },
        okText: intl.get('hostConnect.agoda.headerLabels.close').d('Close')
      });
    }
    setLoading(false);
    props.onCancel();
  };

  return (
    <Modal
      visible={props.visible}
      title={intl.get('hostConnect.agoda.headerLabels.signContract').d('Sign Agoda Contract')}
      onCancel={props.onCancel}
      footer={
        isError ? (
          <Button type="primary" onClick={props.onCancel}>
            {intl.get('hostConnect.agoda.headerLabels.close').d('Close')}
          </Button>
        ) : (
          <Row type="flex" justify="end">
            <Col style={{ marginRight: 16 }}>
              <Button disabled={loading} onClick={props.onCancel}>
                {intl.get('hostConnect.agoda.headerLabels.close').d('Close')}
              </Button>
            </Col>
            <Col>
              <Button
                loading={loading}
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: intl.get('hostConnect.agoda.message.confirmSign').d('Confirm and sign the agreement'),
                    onOk: () => SignAgodaContact()
                  });
                }}
              >
                {intl.get('hostConnect.agoda.headerLabels.agree').d('Agree & Sign')}
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Spin size="large" spinning={loading}>
        {isError ? (
          <Result
            status="error"
            title={intl.get('hostConnect.agoda.message.failRetrieveContract').d('Failed to retrieve contract')}
            subTitle={errorMsg}
          ></Result>
        ) : (
          <ReactMarkdown children={agodaContact} />
        )}
      </Spin>
    </Modal>
  );
};

export default SignAgodaContact;
