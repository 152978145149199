import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { notification } from 'antd';
import uuid from 'uuid/v1';
import queryString from 'query-string';

import {
  buildListingWizardUri,
  buildLWCreateRoomTypeUri,
  buildLWCreateRoomTypeBasicUri,
  buildLWCreateRoomTypePlaceSetupUri,
  buildLWCreateRoomTypeRateUri,
  buildLWCreateRoomTypeAmenitiesUri
} from 'utils/routes';
import ProtectedRoute from 'utils/ProtectedRoute';
import { getRoomTypeAmenitiesConstant, getBedTypesConstant } from 'utils/apis/constants';

import BasicDetails from './BasicDetails/BasicDetails';
import PlaceSetup from './PlaceSetup/PlaceSetup';
import Rate from './Rate/Rate';
import Amenities from './Amenities/Amenities';
import intl from 'react-intl-universal';

const generateRoomTypeCode = name => {
  return `${name.substring(0, 3).toUpperCase()}-RT-${uuid()}`;
};

class CreateRoomType extends React.Component {
  constructor() {
    super();
    this.state = {
      hasFetchedBedTypes: false,
      bedTypesSelection: {},
      hasFetchedRoomTypeAmenities: false,
      isCreatingRoomType: false,
      roomTypeAmenities: {},
      payload: {},
      currency: 'RM',
      timezone: ''
    };
  }

  componentDidMount() {
    const { history, location, currency, timezone } = this.props;

    if (currency) {
      this.setState({ currency: currency });
    } else {
      const query = queryString.parse(location.search);
      if (query.currency) {
        this.setState({ currency: query.currency });
      }
    }

    if (timezone) {
      this.setState({ timezone: timezone });
    } else {
      const query = queryString.parse(location.search);
      if (query.timezone) {
        this.setState({ timezone: query.timezone });
      }
    }

    if (!this.checkHasPropertyId(location.search)) {
      history.push(buildListingWizardUri());
      return;
    }

    if (location.pathname !== buildLWCreateRoomTypeUri()) {
      if (location.pathname !== buildLWCreateRoomTypeBasicUri()) {
        history.push(buildLWCreateRoomTypeUri(location.search));
      }
    }
    this.fetchBedTypes();
    this.fetchRoomTypeAmenities();
  }

  checkHasPropertyId = searchQuery => {
    let hasPropertyId = true;
    if (searchQuery) {
      const query = queryString.parse(searchQuery);
      if (!query.propertyId) {
        hasPropertyId = false;
      }
    } else {
      hasPropertyId = false;
    }
    return hasPropertyId;
  };

  fetchBedTypes = () => {
    getBedTypesConstant()
      .then(res => {
        this.setBedTypesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchRoomTypeAmenities = () => {
    getRoomTypeAmenitiesConstant()
      .then(res => {
        this.setRoomTypeAmenitiesState(res);
      })
      .catch(err => console.log(err));
  };

  setBedTypesState = bedTypes => {
    // const formattedBedTypes = Object.keys(bedTypes).map(key => ({
    //   code: bedTypes[key].code,
    //   name: bedTypes[key].label
    // }));
    this.setState({
      hasFetchedBedTypes: true,
      bedTypesSelection: bedTypes
    });
  };

  setRoomTypeAmenitiesState = roomTypeAmenities => {
    let formattedRoomTypeAmenities = {};
    Object.keys(roomTypeAmenities).forEach(key => {
      const { code, label, type } = roomTypeAmenities[key];
      if (type in formattedRoomTypeAmenities) {
        formattedRoomTypeAmenities[type]['data'].push({
          key: code,
          value: label
        });
      } else {
        formattedRoomTypeAmenities[type] = {
          icon: this.getRoomTypeAmenitiesIcon(type),
          data: [
            {
              key: code,
              value: label
            }
          ]
        };
      }
    });
    this.setState({
      hasFetchedRoomTypeAmenities: true,
      roomTypeAmenities: formattedRoomTypeAmenities
    });
  };

  getRoomTypeAmenitiesIcon = type => {
    const icons = {
      Accessibility: 'bell',
      Bathroom: 'skin',
      'Food and Drink': 'coffee',
      'Media Technology': 'home',
      'Outdoor and View': 'cloud-o',
      'Room Amenities': 'hdd',
      'Service and Extras': 'smile-o'
    };
    return icons[type] || 'tool';
  };

  formatBasicDetailsData = data => ({
    name: data.name,
    roomSizeInSqFt: data.size,
    capacity: {
      adult: data.adultMaxOccupancy,
      child: data.childMaxOccupancy
    }
  });

  formatPlaceSetupData = data => {
    return {
      bathrooms: data.numOfBathRooms,
      livingrooms: data.numOfLivingRooms,
      bedrooms: data.bedrooms
        ? data.bedrooms.map(bedroom => {
            const { beds } = bedroom;
            const newBeds = Object.keys(beds)
              .map(bedKey => {
                const bed = beds[bedKey];
                return bed.count
                  ? {
                      type: bed.code,
                      count: bed.count
                    }
                  : undefined;
              })
              .filter(bed => !!bed); // filter bed that has count 0
            return { name: bedroom.name, beds: newBeds };
          })
        : []
    };
  };

  formatRateData = data => ({
    weekday: data.weekdayPrice,
    weekend: data.weekendPrice
  });

  formatAmenitiesData = data => {
    const formattedAmenities = Object.keys(data).reduce((newData, key) => {
      if (data[key] && Array.isArray(data[key])) {
        return [...newData, ...data[key]];
      }
      return newData;
    }, []);
    return {
      amenities: formattedAmenities
    };
  };

  extractBasicDetailsData = () => {
    const { payload } = this.state;

    return {
      name: payload.name,
      size: payload.roomSizeInSqFt,
      adultMaxOccupancy: payload.capacity && payload.capacity.adult ? payload.capacity.adult : 0,
      childMaxOccupancy: payload.capacity && payload.capacity.child ? payload.capacity.child : 0
    };
  };

  extractPlaceSetupData = () => {
    const { payload, bedTypesSelection } = this.state;

    return {
      numOfBathRooms: payload.bathrooms,
      numOfLivingRooms: payload.livingrooms,
      bedroomConfiguration: payload.bedrooms
        ? payload.bedrooms.map(bedroom => {
            const defaultBedrooms = Object.keys(bedTypesSelection).reduce((newBedTypes, key) => {
              return {
                ...newBedTypes,
                [key]: {
                  ...bedTypesSelection[key],
                  count: 0
                }
              };
            }, {});
            let totalBedCount = 0;
            const formattedBeds = bedroom.beds.reduce((newBeds, bed) => {
              const selectedBedTypeKey = Object.keys(bedTypesSelection).filter(key => {
                const bedType = bedTypesSelection[key];
                return bedType.code === bed.type;
              })[0];
              if (selectedBedTypeKey) {
                const newBed = {
                  ...bedTypesSelection[selectedBedTypeKey],
                  count: bed.count
                };
                totalBedCount += bed.count;
                return {
                  ...newBeds,
                  [selectedBedTypeKey]: newBed
                };
              }
              return newBeds;
            }, defaultBedrooms);
            return { ...bedroom, beds: formattedBeds, totalBedCount };
          })
        : []
    };
  };

  extractRateData = () => {
    const { payload } = this.state;

    return {
      weekdayPrice: payload.weekday,
      weekendPrice: payload.weekend
    };
  };

  /*
  Converting this format to the new format which Amenities is expecting
    payload:
    {
      amenities: ['key1', 'key2', ...],
      ...
    }
    && 
    propertyAmenities:
    {
      Activities: {
        icon: 'iconString',
        data: [{ key: 'key', value: 'val' }]
      },
      ...
    }
    ==>
    {
      Activities: ['key1', 'key2'],
      ...
    }
  */
  extractAmenitiesData = () => {
    let { payload, roomTypeAmenities } = this.state;
    let extractedAmenities = {};
    if (payload.amenities) {
      Object.keys(roomTypeAmenities).forEach(key => {
        extractedAmenities[key] = payload.amenities.filter(
          payloadValue => !!roomTypeAmenities[key].data.find(amentity => amentity.key === payloadValue)
        );
      });
    }

    return extractedAmenities;
  };

  handleOnSave = (url, formatDataFunc = data => data) => (formData = {}) => {
    const { history } = this.props;
    const formattedData = formatDataFunc(formData);
    this.setState(prevState => ({
      payload: { ...prevState.payload, ...formattedData }
    }));
    history.push(url);
  };

  handleOnSaveAndSend = (formData = {}) => {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const formattedData = this.formatAmenitiesData(formData);
    this.setState({
      isCreatingRoomType: true
    });

    this.setState((prevState, props) => {
      const newPayload = {
        ...prevState.payload,
        ...formattedData,
        property: query.propertyId,
        code: generateRoomTypeCode(prevState.payload.name)
      };
      props
        .onFinish(newPayload)
        .then(() => {
          this.setState({
            isCreatingRoomType: false
          });
          props.history.push(buildListingWizardUri());
        })
        .catch(e => {
          this.setState({
            isCreatingRoomType: false
          });
          notification.error({
            message: intl.get('listings.property.message.roomTypeError').d('Something went wrong and your room type is not created.')
            // description: e.message
          });
        });
      return {
        payload: newPayload
      };
    });
  };

  render() {
    const { title, location } = this.props;
    const { bedTypesSelection, isCreatingRoomType, roomTypeAmenities, hasFetchedRoomTypeAmenities, hasFetchedBedTypes } = this.state;
    return (
      <Fragment>
        <Switch>
          <ProtectedRoute
            path={buildLWCreateRoomTypeUri()}
            render={props => (
              <BasicDetails
                {...props}
                title={title}
                percentage={0}
                onSave={this.handleOnSave(buildLWCreateRoomTypePlaceSetupUri(location.search), this.formatBasicDetailsData)}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
            exact
          />
          <ProtectedRoute
            path={buildLWCreateRoomTypeBasicUri()}
            render={props => (
              <BasicDetails
                {...props}
                title={title}
                percentage={0}
                onSave={this.handleOnSave(buildLWCreateRoomTypePlaceSetupUri(location.search), this.formatBasicDetailsData)}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateRoomTypePlaceSetupUri()}
            render={props => (
              <PlaceSetup
                {...props}
                title={title}
                percentage={25}
                onSave={this.handleOnSave(buildLWCreateRoomTypeRateUri(location.search), this.formatPlaceSetupData)}
                onBack={this.handleOnSave(buildLWCreateRoomTypeBasicUri(location.search), this.formatPlaceSetupData)}
                bedTypes={bedTypesSelection}
                defaultValues={this.extractPlaceSetupData()}
                hasFetchedBedTypes={hasFetchedBedTypes}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateRoomTypeRateUri()}
            render={props => (
              <Rate
                {...props}
                title={title}
                percentage={50}
                onSave={this.handleOnSave(buildLWCreateRoomTypeAmenitiesUri(location.search), this.formatRateData)}
                onBack={this.handleOnSave(buildLWCreateRoomTypePlaceSetupUri(location.search), this.formatRateData)}
                defaultValues={this.extractRateData()}
                currency={this.state.currency}
                timezone={this.state.timezone}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateRoomTypeAmenitiesUri()}
            render={props => (
              <Amenities
                {...props}
                isLastPage
                title={title}
                percentage={75}
                onSave={this.handleOnSaveAndSend}
                onBack={this.handleOnSave(buildLWCreateRoomTypeRateUri(location.search), this.formatAmenitiesData)}
                hasFetchedRoomTypeAmenities={hasFetchedRoomTypeAmenities}
                roomTypeAmenities={roomTypeAmenities}
                defaultValues={this.extractAmenitiesData()}
                isLoading={isCreatingRoomType}
              />
            )}
          />
          <Redirect from="*" to="/404NotFound" />
        </Switch>
      </Fragment>
    );
  }
}

CreateRoomType.propTypes = {
  title: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

CreateRoomType.defaultProps = {
  onFinish: () => {}
};

export default CreateRoomType;
