import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import { REACT_APP_ENDPOINT } from 'config/env';

import PhotoDisplayCard from './components/PhotoDisplayCard/PhotoDisplayCard';

import styles from './UploadPhoto.module.css';
import intl from 'react-intl-universal';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';

const uploadOptions = {
  server: REACT_APP_ENDPOINT,
  signingUrlQueryParams: { uploadType: 'avatar' }
};
const s3Url = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;

const UploadContainer = () => (
  <div className={styles.dragBoxContentContainer}>
    <div className={styles.dragBoxContentInnerContainer}>
      <p className={styles.dragBoxContentIcon}>
        <Icon type="picture" />
      </p>
      <p className={styles.dragBoxContentTitle}>
        {intl.get('listings.unit.headerLabels.uploadPhotoMsg').d('Click or drag image(s) to this area to upload')}
      </p>
    </div>
  </div>
);

class UploadPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: this.props.photos, // Initialize photo state with the photos prop
      boxesPerRow: 3,
      rowHeight: 420, // Initial row height
      height: 420
    };
  }

  componentDidMount() {
    // Add event listener for window resize events
    window.addEventListener('resize', this.handleResize);
    // Call handleResize initially to set initial state
    this.handleResize();
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    // Check if the photos prop has changed
    if (prevProps.photos !== this.props.photos) {
      // Update the state with the new photos prop
      this.setState({ photo: this.props.photos });
      this.handleResize();
    }
  }

  calculateBoxesPerRow = containerWidth => {
    // Your logic to calculate the number of boxes per row based on container width
    if (containerWidth >= 992) {
      return 3; // Example: 4 boxes per row for large screens
    } else if (containerWidth >= 768) {
      return 2; // Example: 3 boxes per row for medium screens
    } else {
      return 1; // Example: 2 boxes per row for small screens
    }
  };

  calculateRowHeight = containerWidth => {
    if (containerWidth >= 992) {
      return 420; // Example: 4 boxes per row for large screens
    } else if (containerWidth >= 768) {
      return 420; // Example: 3 boxes per row for medium screens
    } else {
      return 280; // Example: 2 boxes per row for small screens
    }
  };

  calculateHeight = containerWidth => {
    const { photo } = this.state;
    if (photo.length > 0) {
      if (containerWidth >= 992) {
        const formula = Math.ceil(photo.length / 3);
        return 425 * formula; // Example: 4 boxes per row for large screens
      } else if (containerWidth >= 768) {
        const formula = Math.ceil(photo.length / 2);
        return 425 * formula; // Example: 3 boxes per row for medium screens
      } else {
        const formula = Math.ceil(photo.length / 1);
        return 280 * formula; // Example: 2 boxes per row for small screens
      }
    }
    return 0;
  };

  handleResize = () => {
    const containerWidth = window.innerWidth;
    const newRowHeight = this.calculateRowHeight(containerWidth);
    const newHeight = this.calculateHeight(containerWidth);
    const newBoxesPerRow = this.calculateBoxesPerRow(containerWidth);
    this.setState({ boxesPerRow: newBoxesPerRow, rowHeight: newRowHeight, height: newHeight });
  };

  onChange = (sourceId, sourceIndex, targetIndex) => {
    const { onDrag } = this.props;
    const nextState = swap(this.state.photo, sourceIndex, targetIndex);
    //this.setState({ photo: nextState });
    onDrag(nextState);
  };

  render() {
    const { onCaptionChange, onImageDelete, onImageMakePrimary, onUploadFinish } = this.props;
    const { photo, boxesPerRow, rowHeight, height } = this.state;
    return (
      <GridContextProvider onChange={this.onChange}>
        <div className={styles.gridContainer}>
          <GridDropZone id="ROOT" boxesPerRow={boxesPerRow} rowHeight={rowHeight} style={{ height: height }}>
            {photo.map((photo, index) => (
              <GridItem key={photo.link}>
                <div className={styles.gridItem}>
                  <PhotoDisplayCard
                    key={index}
                    imageLink={photo.link}
                    imageCaption={photo.caption}
                    onCaptionChange={onCaptionChange(index)}
                    onDelete={onImageDelete(index)}
                    onMakePrimaryClick={onImageMakePrimary(index, this.state.photo)}
                  />
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </div>
        <div className={styles.uploadContainer}>
          <DropzoneS3Uploader
            onFinish={onUploadFinish}
            s3Url={s3Url}
            maxSize={1024 * 1024 * 5}
            upload={uploadOptions}
            className={styles.dragBoxContainer}
          >
            <UploadContainer />
          </DropzoneS3Uploader>
        </div>
      </GridContextProvider>
    );
  }
}

UploadPhoto.propTypes = {
  onCaptionChange: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onImageMakePrimary: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  containerClassname: PropTypes.string,
  photos: PropTypes.array
};

UploadPhoto.defaultProps = {
  containerClassname: '',
  photos: [],
  onCaptionChange: () => {},
  onImageDelete: () => {},
  onImageMakePrimary: () => {},
  onUploadFinish: () => {}
};

export default UploadPhoto;
