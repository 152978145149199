import React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import PropTypes from 'prop-types';

import styles from './HostProtect.module.css';
import intl from 'react-intl-universal';

const HostProtected = ({ protection }) => {
  return (
    <div className={`${styles.hostProtect} ${styles.hostProtected}`}>
      <Row className={styles.hostProtectedTitle}>
        <Col span={3}>
          <Icon type="safety-certificate" theme="twoTone" twoToneColor="#52c41a" className="protected-icon" />
        </Col>
        <Col span={21}>
          {intl.get('payout.hostProtect.restEasy').d('Rest Easy')}
          <br />
          {intl.getHTML('payout.hostProtect.covered').d('You are covered by <b>HostProtect!</b>')}
        </Col>
      </Row>

      <hr />

      <Row type="flex" justify="space-between" className="protected-information">
        <Col span={12}>{intl.get('payout.hostProtect.certificate').d('Certificate No.')}</Col>
        <Col span={12} className="protected-text">
          {protection.certNo}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="protected-information">
        <Col span={10}>{intl.get('payout.hostProtect.protectionPeriod').d('Protection Period')}</Col>
        <Col span={14} className="protected-date">
          {protection.insuranceStartDate && protection.insuranceEndDate ? protection.insuranceStartDate + ' to ' + protection.insuranceEndDate : '-'}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="protected-information">
        <Col span={12}>{intl.get('payout.hostProtect.risk').d('All Risk Master Policy')}</Col>
        <Col span={12} className="protected-text">
          {protection.masterPolicyNos ? protection.masterPolicyNos.allRisk : '-'}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="protected-information">
        <Col span={12}>{intl.get('payout.hostProtect.liability').d('Public Liability Master Policy')}</Col>
        <Col span={12} className="protected-text">
          {protection.masterPolicyNos ? protection.masterPolicyNos.publicLiability : '-'}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" className="protected-information">
        <Col span={12}>{intl.get('payout.hostProtect.insured').d('Insured Location')}</Col>
        <Col span={12} className="protected-text">
          {protection.location ? protection.location : '-'}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        {protection.file && (
          <Button type="primary" className="hostProtectButtons" block href={protection.file.link} target="_blank">
            {intl.get('payout.hostProtect.download').d('Download Host Certificate')}
          </Button>
        )}
        <Button
          type="primary"
          className="hostProtectButtons"
          block
          href="https://www.docdroid.net/7Ef2A9p/property-claim-form-all-risk.pdf"
          target="_blank"
        >
          {intl.get('payout.hostProtect.claim').d('All Risk Claim Form')}
        </Button>
        <Button
          type="primary"
          className="hostProtectButtons"
          block
          href="https://www.docdroid.net/Kv8OC6V/public-liability-claim-form.pdf"
          target="_blank"
        >
          {intl.get('payout.hostProtect.liabilityClaim').d('Public Liability Claim Form')}
        </Button>
      </Row>
    </div>
  );
};

const HostUnProtected = ({ isProtectionExpired = false }) => {
  const description = isProtectionExpired ? (
    <p>
      {intl
        .getHTML('payout.hostProtect.expired')
        .d("This unit's <b>HostProtect</b> is expired. You are at risk of bearing the costs of guest damages and accidents.")}
    </p>
  ) : (
    <p>
      {intl
        .getHTML('payout.hostProtect.notCovered')
        .d('This unit is not covered by <b>HostProtect!</b> You are at risk of bearing the costs of guest damages and accidents.')}
    </p>
  );
  const type = isProtectionExpired ? 'clock-circle-o' : 'warning';

  return (
    <div className={`${styles.hostProtect} ${styles.hostUnProtected}`}>
      <Row className="protectedTitle">
        <Col span={3}>
          <Icon type={type} theme="twoTone" twoToneColor="#ff5630" className="protected-icon" />
        </Col>
        <Col span={21}>
          {description}
          <a href="https://hostastay.com/host-protect/" target="_blank" rel="noopener noreferrer">
            {' '}
            {intl.get('payout.hostProtect.moreInfo').d('More Info')}
          </a>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Button
          type="primary"
          block
          href="https://docs.google.com/forms/d/e/1FAIpQLSd6zl-p_0hoW5H8gth-wtXT88GMKOWo-GSsstRh8AS3gQ1vEA/viewform?usp=send_form"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span role="img" aria-label="shield">
            🛡️ {intl.get('payout.hostProtect.now').d('HostProtect Now')}
          </span>
        </Button>
      </Row>
    </div>
  );
};

const HostProtect = ({ protection, hasProtection, isProtectionActive }) => {
  return hasProtection ? (
    isProtectionActive ? (
      <HostProtected protection={protection} />
    ) : (
      <HostUnProtected isProtectionExpired={true} />
    )
  ) : (
    <HostUnProtected isProtectionExpired={false} />
  );
};

HostProtect.propTypes = {
  protection: PropTypes.object,
  hasProtection: PropTypes.bool,
  isProtectionActive: PropTypes.bool
};

HostProtect.defaultProps = {};

export default HostProtect;
