import React, { Component } from 'react';
import { Input, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

class OTARateInput extends Component {
  static propTypes = {
    rate: PropTypes.object,
    updateRates: PropTypes.func,
    currency: PropTypes.string
  };

  static defaultProps = {
    rate: {},
    currency: 'RM',
    updateRates: () => {}
  };

  constructor(props) {
    super(props);
    this.onWeekdayChange = this.onWeekdayChange.bind(this);
    this.onWeekendChange = this.onWeekendChange.bind(this);
  }

  onWeekdayChange = e => {
    const weekday = parseInt(e.target.value);
    const { updateRates } = this.props;
    updateRates({ weekday });
  };

  onWeekendChange = e => {
    const weekend = parseInt(e.target.value);
    const { updateRates } = this.props;
    updateRates({ weekend });
  };

  render() {
    const { rate } = this.props;
    const { weekday, weekend, isDerived } = rate;

    return (
      <>
        <Row gutter={8}>
          <Col xl={12} xs={24}>
            <label className="booking-label">{intl.get('hostConnect.integration.headerLabels.weekdayRate').d('Weekday Rate')}</label>
            <Input
              name="weekday"
              type="number"
              disabled={isDerived}
              addonBefore={this.props.currency ? this.props.currency : 'RM'}
              value={weekday || 0}
              onChange={this.onWeekdayChange}
            />
          </Col>
          <Col xl={12} xs={24}>
            <label className="booking-label">{intl.get('hostConnect.integration.headerLabels.weekendRate').d('Weekend Rate')}</label>
            <Input
              name="weekend"
              type="number"
              disabled={isDerived}
              addonBefore={this.props.currency ? this.props.currency : 'RM'}
              value={weekend || 0}
              onChange={this.onWeekendChange}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(OTARateInput);
