import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import RowFormCounter from '../RowFormCounter/RowFormCounter';
import intl from 'react-intl-universal';

const FIELD_NAME_NAME = 'name';
const FIELD_NAME_ROOM_SIZE = 'roomSizeInSqFt';
const FIELD_NAME_WEEKDAY_PRICE = 'weekdayPrice';
const FIELD_NAME_WEEKEND_PRICE = 'weekendPrice';
const FIELD_NAME_CAPACITY_ADULT = 'adultCapacity';
const FIELD_NAME_CAPACITY_CHILDREN = 'childrenCapacity';
class BasicDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { form, cardClassname, defaultValues } = this.props;
    return (
      <Card title={intl.get('host.headerLabels.basic').d('Basic Information')} className={cardClassname}>
        <Row gutter={16} type="flex" justify="start">
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name={FIELD_NAME_NAME}
              defaultValue={defaultValues[FIELD_NAME_NAME]}
              inputType="input"
              label={intl.get('listings.roomType.headerLabels.name').d('Name')}
              placeholder={intl.get('listings.roomType.placeholder.roomType').d('Type in your room type name')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.name').d('Name is required')}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_ROOM_SIZE}
              defaultValue={defaultValues[FIELD_NAME_ROOM_SIZE]}
              minValue={1}
              inputType="input"
              label={intl.get('listings.roomType.headerLabels.size').d('Size (sqft)')}
              placeholder={intl.get('listings.roomType.placeholder.size').d('Size of your room type')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.sizeMsg').d('Please provide size of your room type in sqft')}
              size="large"
              customStyle={{
                paddingTop: 0
              }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_WEEKDAY_PRICE}
              defaultValue={defaultValues[FIELD_NAME_WEEKDAY_PRICE]}
              label={`${intl.get('listings.roomType.headerLabels.weekday').d('Weekday Pricing')} (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder={intl.get('listings.roomType.placeholder.weekday').d('Price to charge on weekday')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.weekdayMsg').d('Please set a price for weekday.')}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_WEEKEND_PRICE}
              defaultValue={defaultValues[FIELD_NAME_WEEKEND_PRICE]}
              label={`${intl.get('listings.roomType.headerLabels.weekend').d('Weekend Pricing')} (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder={intl.get('listings.roomType.placeholder.weekend').d('Price to charge on weekend')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.weekendMsg').d('Please set a price for weekend.')}
              size="large"
            />
          </Col>
        </Row>
        <p style={{ marginBottom: '12px', fontSize: '16px' }}>{intl.get('listings.roomType.headerLabels.maxOccupancy').d('Max Occupancy')}</p>
        <RowFormCounter
          form={form}
          label={intl.get('listings.roomType.headerLabels.adult').d('Adult')}
          name={FIELD_NAME_CAPACITY_ADULT}
          defaultValue={defaultValues[FIELD_NAME_CAPACITY_ADULT]}
        />
        <RowFormCounter
          form={form}
          label={intl.get('listings.roomType.headerLabels.child2').d('Child')}
          name={FIELD_NAME_CAPACITY_CHILDREN}
          defaultValue={defaultValues[FIELD_NAME_CAPACITY_CHILDREN]}
        />
      </Card>
    );
  }
}

BasicDetailsCard.propTypes = {
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BasicDetailsCard.defaultProps = {
  cardClassname: '',
  defaultValues: {},
  form: {}
};

export default BasicDetailsCard;
