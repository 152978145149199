import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { withAppContext } from 'context/AppContext';
import { buildTaskManagementUri } from 'utils/routes';

import CloseButton from 'components/CloseButton/CloseButton';
import intl from 'react-intl-universal';

import './TaskForm.css';

import { getConstants } from 'utils/apis/constants';
import { getProperties } from 'utils/apis/property';
import { getRoomTypesAndUnits } from 'utils/apis/roomtype';
import { createNewTask, getChecklistByPropertyId, getCleaningTeamByPropertyId } from 'utils/apis/taskManagement';

const FormItem = Form.Item;
const { Option } = Select;

const MODE_NEW = 'new';
const MODE_EDIT = 'edit';

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: MODE_NEW,
      isLoading: false,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      priorityTypeOptions: [],
      priorityType: 'Urgent',
      propertyOptions: [],
      property: '',
      fullListingOptions: [],
      roomTypeOptions: [],
      roomType: '',
      unitListingOptions: [],
      unitListing: '',
      cleaningTeamOptions: [],
      cleaningTeam: '',
      checklistOptions: [],
      checklist: '',
      maxNoOfCleaner: 1
    };
  }

  componentDidMount() {
    getConstants('taskPriority').then(res => {
      if (res && res.status === 200) {
        let priorityType = Object.keys(res.data).map(i => {
          return res.data[i];
        });
        if (priorityType.length > 0) {
          this.setState({
            priorityTypeOptions: priorityType.map(i => {
              return {
                value: i.code,
                label: i.label
              };
            })
          });
        }
      } else {
        console.log('Error while retrieving priority types');
      }
    });

    getProperties()
      .then(properties => {
        let propertyOptions = [];
        if (properties.length > 0) {
          propertyOptions = properties.map(property => {
            return { value: property._id, label: property.name };
          });
          this.setState({ propertyOptions, isLoading: false });
        }
      })
      .catch(err => {
        message.error(err);
        this.setState({ isLoading: false });
        return [{ label: '', value: '' }];
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.props;
    if (this.state.property && prevState.property !== this.state.property) {
      const startDateString = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateString = moment()
        .endOf('month')
        .format('YYYY-MM-DD');
      getRoomTypesAndUnits(this.state.property, startDateString, endDateString).then(res => {
        let roomTypeOptions = [];
        let fullListingOptions = [];
        if (res.length > 0) {
          roomTypeOptions = res.map(roomType => {
            return { value: roomType._id, label: roomType.name };
          });
          fullListingOptions = res.map(fullRoomType => {
            return {
              roomTypeId: fullRoomType._id,
              units: fullRoomType.units
            };
          });
          form.setFieldsValue({
            roomType: undefined
          });
          this.setState({ roomTypeOptions, fullListingOptions, roomType: '' });
        }
      });

      getCleaningTeamByPropertyId(this.state.property)
        .then(cleaningTeams => {
          let cleaningTeamOptions = [];
          if (cleaningTeams.data.length > 0) {
            cleaningTeamOptions = cleaningTeams.data.map(cleaningTeam => {
              return { value: cleaningTeam._id, label: cleaningTeam.name, numberOfCleaner: cleaningTeam.users.length };
            });
          }
          form.setFieldsValue({
            cleaningTeam: undefined
          });
          this.setState({ cleaningTeamOptions, cleaningTeam: '' });
        })
        .catch(err => {
          message.error(err);
          return [{ label: '', value: '', numberOfCleaner: 0 }];
        });

      getChecklistByPropertyId(this.state.property)
        .then(checklists => {
          let checklistOptions = [];
          if (checklists.data.length > 0) {
            checklistOptions = checklists.data.map(checklist => {
              return { value: checklist._id, label: checklist.name };
            });
          }
          form.setFieldsValue({
            checklist: undefined
          });
          this.setState({ checklistOptions, checklist: '' });
        })
        .catch(err => {
          message.error(err);
          return [{ label: '', value: '' }];
        });
    }

    if (prevState.roomType !== this.state.roomType) {
      let unitListingOptions = [];
      if (this.state.roomType) {
        unitListingOptions = this.state.fullListingOptions
          .filter(roomType => roomType.roomTypeId === this.state.roomType)[0]
          .units.map(unitListing => {
            return { value: unitListing._id, label: unitListing.name };
          });
        // this.setState({ unitListingOptions });
      }
      form.setFieldsValue({
        unitListing: undefined
      });
      this.setState({ unitListingOptions, unitListing: '' });
    }

    if (
      prevState.cleaningTeam !== this.state.cleaningTeam ||
      JSON.stringify(prevState.cleaningTeamOptions) !== JSON.stringify(this.state.cleaningTeamOptions)
    ) {
      let maxNoOfCleaner = 1;
      if (this.state.cleaningTeam !== '' && this.state.cleaningTeamOptions.length > 0) {
        maxNoOfCleaner = this.state.cleaningTeamOptions.find(team => team.value === this.state.cleaningTeam).numberOfCleaner;
      }

      this.setState({ maxNoOfCleaner });
      form.setFieldsValue({ noOfCleaner: maxNoOfCleaner });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;
    const { mode } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        if (mode === MODE_NEW) {
          this.setState({
            // isSaveButtonLoading: true
          });
          createNewTask(values).then(res => {
            if (res.status === 200) {
              history.push(`${buildTaskManagementUri()}/tasklist`);
              message.success(intl.get('taskManagement.newTaskForm.headerLabels.taskCreated').d('New task success created'));
            }
          });
        } else if (mode === MODE_EDIT) {
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const taskId = match.params.id;
  };

  validateMaxNoOfCleaner = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value > this.state.maxNoOfCleaner) {
      callback(intl.get('taskManagement.newTaskForm.headerLabels.exceedCleaner').d('Exceed maximum number of cleaner in the team'));
    } else {
      callback();
    }
  };

  cleanerIncrement = () => {
    const { form } = this.props;
    const { maxNoOfCleaner } = this.state;
    if (form.getFieldValue('noOfCleaner') < maxNoOfCleaner) {
      form.setFieldsValue({ noOfCleaner: form.getFieldValue('noOfCleaner') + 1 });
    }
  };

  cleanerDecrease = () => {
    const { form } = this.props;
    if (form.getFieldValue('noOfCleaner') > 1) {
      form.setFieldsValue({
        noOfCleaner: form.getFieldValue('noOfCleaner') - 1
      });
    }
  };

  render() {
    const { form, history, checkIsAllowCreateTask } = this.props;
    const { getFieldDecorator } = form;
    const {
      mode,
      isLoading,
      isSaveButtonLoading,
      priorityTypeOptions,
      priorityType,
      propertyOptions,
      roomTypeOptions,
      unitListingOptions,
      cleaningTeam,
      cleaningTeamOptions,
      checklistOptions
    } = this.state;

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Card
          className="task-form-card"
          title={intl.get('taskManagement.newTaskForm.headerLabels.basicInfo').d('Basic Information')}
          loading={isLoading}
        >
          <CloseButton
            onClick={() => {
              history.push(`${buildTaskManagementUri()}/tasklist`);
            }}
          />
          <Row type="flex" justify="start" gutter={36}>
            <FormItem label={intl.get('taskManagement.newTaskForm.headerLabels.priority').d('Priority')} className="task-form-list-half">
              {getFieldDecorator('priority', {
                initialValue: priorityType,
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.priorityMsg').d('Please select the priority for your task')
                  }
                ]
              })(
                <Select
                  placeholder={intl.get('reservations.reservationDetails.placeholder.priority').d('Select the priority')}
                  // optionFilterProp='children'
                  onChange={e => this.setState({ priorityType: e })}
                  disabled={priorityTypeOptions.length === 0}
                >
                  {priorityTypeOptions.map(priorityOption => {
                    return (
                      <Option key={priorityOption.value} value={priorityOption.value}>
                        {intl.get(`taskManagement.taskList.priority.${priorityOption.label}`).d('')}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.newTaskForm.headerLabels.property').d('Property')} className="task-form-list-half">
              {getFieldDecorator('property', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.propertyMsg').d('Please select a property')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.property').d('Select a property')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ property: e })}
                  disabled={propertyOptions.length === 0}
                >
                  {propertyOptions.map(propertyOption => {
                    return (
                      <Option key={propertyOption.value} value={propertyOption.value}>
                        {propertyOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.newTaskForm.headerLabels.roomType').d('Room Type')} className="task-form-list-half">
              {getFieldDecorator('roomType', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.roomTypeRule').d('Please select a room type!')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.roomType').d('Select a room type')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ roomType: e })}
                  disabled={roomTypeOptions.length === 0}
                >
                  {roomTypeOptions.map(roomTypeOption => {
                    return (
                      <Option key={roomTypeOption.value} value={roomTypeOption.value}>
                        {roomTypeOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.newTaskForm.headerLabels.unitListing').d('Unit Listing')} className="task-form-list-half">
              {getFieldDecorator('unit', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.unitRule').d('Please select a unit!')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.unit').d('Select a unit')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ unitListing: e })}
                  disabled={unitListingOptions.length === 0}
                >
                  {unitListingOptions.map(unitListingOption => {
                    return (
                      <Option key={unitListingOption.value} value={unitListingOption.value}>
                        {unitListingOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.get('taskManagement.newTaskForm.headerLabels.team').d('Team Assign')}
              className="task-form-list-half"
              help={
                this.state.property && cleaningTeamOptions.length === 0 ? (
                  <span>
                    {intl.get('taskManagement.newTaskForm.headerLabels.noCleanTeam').d('No cleaning team created, click ')}
                    <a target="_blank" href="/task-management/cleaning-team">
                      {intl.get('taskManagement.newTaskForm.headerLabels.link').d('link')}
                    </a>{' '}
                    {intl.get('taskManagement.newTaskForm.headerLabels.toCreate').d('to create')}
                  </span>
                ) : (
                  ''
                )
              }
            >
              {getFieldDecorator('cleaningTeam', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.teamMsg').d('Please select a cleaning team')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.team').d('Select a cleaning team')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ cleaningTeam: e })}
                  disabled={cleaningTeamOptions.length === 0}
                >
                  {cleaningTeamOptions.map(cleaningTeam => {
                    return (
                      <Option key={cleaningTeam.value} value={cleaningTeam.value}>
                        {cleaningTeam.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.get('taskManagement.newTaskForm.headerLabels.checklist').d('Checklist')}
              className="task-form-list-half"
              help={
                this.state.property && checklistOptions.length === 0 ? (
                  <span>
                    {intl.get('taskManagement.newTaskForm.headerLabels.noChecklist').d('No checklist created, click ')}
                    <a target="_blank" href="/task-management/checklist">
                      {intl.get('taskManagement.newTaskForm.headerLabels.link').d('link')}
                    </a>{' '}
                    {intl.get('taskManagement.newTaskForm.headerLabels.toCreate').d('to create')}
                  </span>
                ) : (
                  ''
                )
              }
            >
              {getFieldDecorator('checklist', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.checklistMsg').d('Please select a checklist')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.checkList').d('Select checklist')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ checklist: e })}
                  disabled={checklistOptions.length === 0}
                >
                  {checklistOptions.map(checklist => {
                    return (
                      <Option key={checklist.value} value={checklist.value}>
                        {checklist.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.get('taskManagement.newTaskForm.headerLabels.numCleaner').d('Number of Cleaner')}
              style={{ padding: '0px 18px' }}
              className="inputaddon"
            >
              {getFieldDecorator('noOfCleaner', {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.cleanerMsg').d('At least one cleaner is require')
                  },
                  { validator: this.validateMaxNoOfCleaner }
                ]
              })(
                <Input
                  type="number"
                  addonBefore={<Icon type="minus" onClick={() => (cleaningTeam ? this.cleanerDecrease() : {})} />}
                  addonAfter={<Icon type="plus" style={{ color: '#fff' }} onClick={() => (cleaningTeam ? this.cleanerIncrement() : {})} />}
                  style={{ width: '50%', textAlign: 'center' }}
                  disabled={!cleaningTeam}
                />
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.newTaskForm.headerLabels.notes').d('Notes')} className="task-form-list">
              {getFieldDecorator('notes')(
                <Input.TextArea
                  placeholder={intl.get('reservations.reservationDetails.placeholder.leaveMsg').d('Leave a message...')}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              )}
            </FormItem>
          </Row>
          <Row type="flex" justify="center">
            <FormItem>
              {mode === 'new' &&
                (checkIsAllowCreateTask() && (
                  <Button id="create-button-automatedtask" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                    {intl.get('taskManagement.newTaskForm.headerLabels.createTask').d('Create Task')}
                  </Button>
                ))}
            </FormItem>
          </Row>
        </Card>
      </Form>
    );
  }
}

export default withRouter(withAppContext(Form.create()(TaskForm)));
