import React from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import styles from './BasicDetailsCard.module.css';
import intl from 'react-intl-universal';

class BasicDetailsCard extends React.Component {
  render() {
    const { form, cardClassname, defaultValues } = this.props;
    return (
      <Card title={intl.get('host.headerLabels.basic').d('Basic Information')} className={cardClassname}>
        <Row gutter={16}>
          <Col span={24}>
            <Alert
              className={styles.notes}
              message={intl.get('listings.unit.headerLabels.tips').d('Small Tips')}
              description={
                <div>
                  <p className={styles.notesText}>
                    {intl
                      .get('listings.unit.headerLabels.tipsDesc')
                      .d('Give your unit a unique naming so that you can identify them easily in future.')}
                  </p>
                  <p className={styles.notesText}>
                    {intl.get('listings.unit.headerLabels.example').d('For example: E-11-02, A studio with contemporary design theme.')}
                  </p>
                </div>
              }
              type="info"
              showIcon
            />
            <br />
            <FormInput
              form={form}
              name={'name'}
              defaultValue={defaultValues.name}
              inputType="input"
              label={intl.get('listings.unit.headerLabels.unitName').d('Unit Name')}
              placeholder={intl.get('listings.unit.placeholder.unitName').d('Type in your unit name')}
              requiredErrorMessage={intl.get('listings.unit.placeholder.unitNameMsg').d('Unit name is required')}
              size="large"
            />
            <FormInputNumber
              form={form}
              name={'maxFreeStay'}
              defaultValue={defaultValues.maxFreeStay || 0}
              minValue={0}
              label={intl.get('listings.unit.headerLabels.freeStay').d('Maximum Free Stay (days)')}
              placeholder={intl.get('listings.unit.placeholder.freeStay').d('Maximum free stay for this unit in days')}
              size="large"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

BasicDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

BasicDetailsCard.defaultProps = {
  defaultValues: {}
};

export default BasicDetailsCard;
