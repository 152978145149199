import React from 'react';
import { Row, Col, Tabs, Skeleton, Empty, Timeline, Descriptions } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import PayoutList from './components/PayoutList/PayoutList';
import PayoutDetails from './components/PayoutDetails/PayoutDetails';
import PayoutGraphData from './components/PayoutGraph/PayoutGraph';
import PayoutAttachment from './components/PayoutExpensesAttachment/PayoutExpensesAttachment';
import { checkIsObjectEmpty } from 'utils/general';
import intl from 'react-intl-universal';

import styles from './Body.module.css';

const TabPane = Tabs.TabPane;

const checkShouldShowCalendar = payoutDetails => {
  return !payoutDetails.find(payoutDetail => payoutDetail.shouldHideCalendar);
};

const NoData = ({ message }) => {
  return <Empty description={<span className={styles.noData}>{message}</span>} />;
};

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const activityLogCreatedBy = createdBy => {
  return createdBy ? `${createdBy.userProfile.firstName} ${createdBy.userProfile.lastName}` : 'N/A';
};

const constructOwnerDetails = (ownerOptions, ownerId) => {
  let ownerName;
  if (ownerId) {
    const foundOwner = ownerOptions.find(ownerOption => String(ownerOption.value) === String(ownerId));
    ownerName = foundOwner ? (foundOwner.label ? foundOwner.label : '') : '';
  }

  return ownerName;
};

const checkDifferences = (data, index, type, ownerOptions = [], currency = 'RM') => {
  switch (type) {
    case 'creator':
      return (
        <Descriptions.Item label={<span style={{ fontWeight: 600 }}>{intl.get('payout.headerLabels.createdBy').d('Created By')}</span>}>
          {activityLogCreatedBy(data.createdBy)}
        </Descriptions.Item>
      );

    case 'owner':
      return (
        <Descriptions.Item label={<span style={{ fontWeight: 600 }}>{intl.get('payout.headerLabels.owner').d('Owner')}</span>}>
          {constructOwnerDetails(ownerOptions, data.newData ? data.newData.ownerId : data.oldData.ownerId)}
        </Descriptions.Item>
      );

    case 'data':
      return data.newData
        ? data.newData.data.payoutDetails.map(detail => (
            <Descriptions.Item
              key={detail.unit._id}
              span={3}
              label={
                <span style={{ fontWeight: 600 }}>
                  {intl.get('payout.headerLabels.unit').d('Unit')} {`${detail.unit.name}`}
                </span>
              }
            >
              {`${currency} ${Number(detail.nettProfit).toFixed(2)}`}
            </Descriptions.Item>
          ))
        : data.oldData.data.payoutDetails.map(detail => (
            <Descriptions.Item
              key={detail.unit._id}
              span={3}
              label={
                <span style={{ fontWeight: 600 }}>
                  {intl.get('payout.headerLabels.unit').d('Unit')} {`${detail.unit.name}`}
                </span>
              }
            >
              {`${currency} ${Number(detail.nettProfit).toFixed(2)}`}
            </Descriptions.Item>
          ));

    default:
      break;
  }
};

const Body = ({
  isPayoutDetailsLoading,
  isPayoutCalendarLoading,
  isPayoutGraphLoading,
  isPayoutExpensesAttachmentLoading,
  year,
  month,
  integrationSourceConstants,
  packageTypeConstants,
  revenueShareConstants,
  selectedHostImage,
  selectedOwnerName,
  payoutDetails,
  payoutCalendarData,
  payoutGraphData,
  payoutExpensesAttachment,
  activityLog,
  ownerOptions,
  isOwner,
  currency = 'RM'
}) => {
  const hasPayoutDetails = payoutDetails.length > 0;
  const hasPayoutCalendarData = !checkIsObjectEmpty(payoutCalendarData);
  const hasPayoutGraphData = payoutGraphData && payoutGraphData.year;
  const shouldShowCalendar = hasPayoutDetails && checkShouldShowCalendar(payoutDetails);
  const hasActivityLog = activityLog.length > 0;

  return (
    <Tabs defaultActiveKey="payoutDetails">
      <TabPane tab={intl.get('payout.headerLabels.detail').d('Details')} key="payoutDetails">
        <Skeleton loading={isPayoutDetailsLoading} active>
          {hasPayoutDetails ? (
            <PayoutDetails
              year={year}
              month={month}
              integrationSourceConstants={integrationSourceConstants}
              packageTypeConstants={packageTypeConstants}
              revenueShareConstants={revenueShareConstants}
              selectedHostImage={selectedHostImage}
              selectedOwnerName={selectedOwnerName}
              payoutDetails={payoutDetails}
            />
          ) : (
            <NoData message={intl.get('payout.headerLabels.noData').d('There are no units available for this owner')} />
          )}
        </Skeleton>
      </TabPane>

      {shouldShowCalendar && (
        <TabPane tab={intl.get('payout.headerLabels.calandar').d('Calendar')} key="2">
          <Skeleton loading={isPayoutCalendarLoading} active>
            {hasPayoutCalendarData ? (
              <Row type="flex" justify="center" className="full-width">
                <Col span={22}>
                  <PayoutList data={payoutCalendarData} />
                </Col>
              </Row>
            ) : (
              <NoData message={intl.get('payout.headerLabels.noData').d('There are no units available for this owner')} />
            )}
          </Skeleton>
        </TabPane>
      )}

      <TabPane tab={intl.get('payout.headerLabels.graph').d('Graph Statistic')} key="3">
        <Skeleton loading={isPayoutGraphLoading} active>
          {hasPayoutGraphData ? (
            <Row type="flex" justify="center">
              <PayoutGraphData data={payoutGraphData} />
            </Row>
          ) : (
            <NoData message={intl.get('payout.headerLabels.noRevenue').d('There are no revenue available for this owner')} />
          )}
        </Skeleton>
      </TabPane>

      <TabPane tab={intl.get('payout.headerLabels.expenses').d('Expenses Attachment')} key="4">
        <Skeleton loading={isPayoutExpensesAttachmentLoading} active>
          {payoutExpensesAttachment ? (
            <Row type="flex" justify="center">
              <PayoutAttachment data={payoutExpensesAttachment} />
            </Row>
          ) : (
            <NoData message={intl.get('payout.headerLabels.noExpense').d('There are no expenses attachment available for this owner')} />
          )}
        </Skeleton>
      </TabPane>

      {!isOwner && (
        <TabPane tab={intl.get('payout.headerLabels.activity').d('Activity Log')} key="5">
          {hasActivityLog ? (
            <div>
              <Timeline>
                <Descriptions
                  title={`${intl.get('payout.headerLabels.logPayout').d('Activity Log for Payout of')} ${moment(
                    month + 1 + '/' + year,
                    'M/YYYY'
                  ).format('MMMM YYYY')}`}
                ></Descriptions>
                {activityLog
                  .sort(function compare(a, b) {
                    let dateA = new Date(a.createdAt);
                    let dateB = new Date(b.createdAt);
                    return dateB - dateA;
                  })
                  .map((log, idx) => (
                    <Timeline.Item key={log._id}>
                      <Descriptions
                        title={intl
                          .get(`reservations.activityLogs.headerLabels.${capitalizeFirstLetter(log.method)}`)
                          .d(capitalizeFirstLetter(log.method))}
                        size="small"
                        bordered
                        colon={false}
                      >
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: 600 }}>
                              {log.method === 'create'
                                ? intl.get('payout.headerLabels.auditedAt').d('Audited At')
                                : intl.get('payout.headerLabels.unauditedAt').d('Unaudited At')}
                            </span>
                          }
                        >
                          {moment(log.createdAt).format('DD-MMM-YYYY HH:mm')}
                        </Descriptions.Item>
                        {checkDifferences(log, idx, 'creator', [], currency)}
                        {checkDifferences(log, idx, 'owner', ownerOptions, currency)}
                        {checkDifferences(log, idx, 'data', [], currency)}
                      </Descriptions>
                    </Timeline.Item>
                  ))}
              </Timeline>
            </div>
          ) : (
            <NoData message={intl.get('payout.headerLabels.noLog').d('There are no activity log available for this host payout')} />
          )}
        </TabPane>
      )}
    </Tabs>
  );
};

Body.propTypes = {
  isPayoutDetailsLoading: PropTypes.bool.isRequired,
  isPayoutCalendarLoading: PropTypes.bool.isRequired,
  isPayoutGraphLoading: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  integrationSourceConstants: PropTypes.array.isRequired,
  packageTypeConstants: PropTypes.array.isRequired,
  revenueShareConstants: PropTypes.array.isRequired,
  selectedHostImage: PropTypes.string.isRequired,
  selectedOwnerName: PropTypes.string.isRequired,
  payoutDetails: PropTypes.array.isRequired,
  payoutCalendarData: PropTypes.object.isRequired,
  payoutGraphData: PropTypes.object.isRequired
};

export default Body;
