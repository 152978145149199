import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Button } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import { REACT_APP_ENDPOINT } from 'config/env';

import styles from './UploadFiles.module.css';
import intl from 'react-intl-universal';

const uploadOptions = {
  server: REACT_APP_ENDPOINT,
  signingUrlQueryParams: { uploadType: 'avatar' }
};
const s3Url = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;

const UploadFiles = ({ files, containerClassname, onUploadFinish, onFileDelete, checkIsAdmin }) => {
  return (
    <Row type="flex" align="middle" className={containerClassname}>
      <Col span={24} className={styles.fileContainer}>
        <DropzoneS3Uploader
          maxSize={1024 * 1024 * 10}
          onFinish={onUploadFinish}
          s3Url={s3Url}
          upload={uploadOptions}
          className={styles.dragBoxContainer}
        >
          <Button className={styles.button}>
            <Icon type="upload" /> {intl.get('invoice.headerLabels.clicktoUpload').d('Click to Upload Files')}
          </Button>
        </DropzoneS3Uploader>
        <div className={styles.fileList}>
          {files.map((file, index) => (
            <div key={file.link}>
              <a href={file.link} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
              {checkIsAdmin && (
                <span className={styles.delete}>
                  <Icon onClick={onFileDelete(index)} type="close" style={{ cursor: 'pointer' }} />
                </span>
              )}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

UploadFiles.propTypes = {
  onUploadFinish: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func,
  checkIsAdmin: PropTypes.bool,
  containerClassname: PropTypes.string,
  files: PropTypes.array
};

UploadFiles.defaultProps = {
  containerClassname: '',
  files: [],
  onUploadFinish: () => {},
  onFileDelete: () => {},
  checkIsAdmin: false
};

export default UploadFiles;
