import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Row, Table } from 'antd';

import { useGetPaginatedRateModifiers } from 'utils/apis/rateModifier';
import { generateDisplayPercentage, generateDisplayFee, getConstantLabel, guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

import { useFetchConstant } from 'hooks/constants';

import ModalRateModifier from '../components/ModalRateModifier/ModalRateModifier';

import styles from '../RateModifier.module.css';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const formatRateModifier = (rateModfiers, rateModifierTypes, rateModifierSigns) => {
  return rateModfiers.map((rateModifier, index) => {
    const rateModifierTypeObject = rateModifierTypes.find(type => type.code === rateModifier.type);
    const rateModifierSignObject = rateModifierSigns.find(sign => sign.isPositive === rateModifier.isPositive);

    return {
      [intl.get('csv.num').d('No.')]: index + 1,
      [intl.get('csv.hostName').d('Host Name')]: rateModifier.hostName + index,
      [intl.get('csv.rateName').d('Rate Name')]: rateModifier.name,
      [intl.get('csv.modifierType').d('Modifier Type')]: guard(
        () => intl.get(`rateModifier.rateType.${rateModifierTypeObject.label}`).d(rateModifierTypeObject.label),
        ''
      ),
      [intl.get('csv.markup').d('Markup/Markdown')]: guard(() => rateModifierSignObject.code, ''),
      [intl.get('csv.currency').d('Currency')]: rateModifier.currency,
      [intl.get('csv.amount').d('Amount/Percentage')]: guard(
        () => (rateModifierTypeObject.isPercentage ? generateDisplayPercentage(rateModifier.amount, true) : generateDisplayFee(rateModifier.amount)),
        ''
      ),
      [intl.get('csv.remark').d('Remarks')]: rateModifier.remarks
    };
  });
};

const DownloadCSV = ({ data, rateModifierTypes, rateModifierSigns }) => (
  <CSVLink target="_blank" filename={`Rate_Modifiers.csv`} data={formatRateModifier(data, rateModifierTypes, rateModifierSigns)}>
    <Button id="csv-button2-rate" type="primary" icon="download" className={`${styles.buttonCSV}`}>
      {intl.get('rateModifier.headerLabels.csv').d('Download CSV')}
    </Button>
  </CSVLink>
);

const constructColumns = (rateModifierTypes, rateModifierSigns, handleOnClickEditRateModifier) => [
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.rateName').d('Rate Name'), 'name', { hasSorter: true }),
    ...constructColumnFilterSearch('name', intl.get('tables.rateName').d('Search Rate Name')),
    render: (text, record) => {
      return (
        <Button type="link" onClick={() => handleOnClickEditRateModifier(record)}>
          {text}
        </Button>
      );
    }
  },
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.host').d('Host Name'), 'hostName', { hasSorter: true }),
    ...constructColumnFilterSearch('hostName', intl.get('tables.hostName').d('Search Host'))
  },
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.modifierType').d('Modifier Type'), 'type', { hasSorter: true }),
    ...constructColumnFilterRadio('type', rateModifierTypes),
    render: type => {
      return intl.get(`rateModifier.rateType.${getConstantLabel(rateModifierTypes, type)}`).d('Modifier Type');
    }
  },
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.markup').d('Markup/Markdown'), 'isPositive', { hasSorter: true }),
    render: sign => getConstantLabel(rateModifierSigns, sign, 'isPositive')
  },
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.amount').d('Amount/Percentage'), 'amount', { hasSorter: true }),
    render: (text, record) => {
      const rateModifierTypeObject = rateModifierTypes.find(type => type.code === record.type);
      return guard(
        () => (rateModifierTypeObject.isPercentage ? generateDisplayPercentage(text, true) : generateDisplayFee(text, true, 0, record.currency)),
        ''
      );
    }
  },
  {
    ...constructColumn(intl.get('rateModifier.tableColumns.remark').d('Remarks'), 'remarks', { hasSorter: true, width: '15%' }),
    ...constructColumnFilterSearch('remarks', intl.get('tables.remark').d('Search Remarks'))
  }
];

const useFetchConstants = () => {
  const { selection: rateModifierTypes, isLoading: isRateModifierTypesLoading } = useFetchConstant('rateModifierType');
  const { selection: rateModifierSigns, isLoading: isRateModifierSignsLoading } = useFetchConstant('rateModifierSign');

  const selections = { rateModifierTypes, rateModifierSigns };
  const isLoading = isRateModifierTypesLoading || isRateModifierSignsLoading;

  return { selections, isLoading };
};

const Standard = ({ isAllowCreateRateModifier, isAllowEditRateModifier, isAllowDeleteRateModifier, checkIsAdminReadOnly, ...props }) => {
  const [query, setQuery] = useState({});
  const [isShowModalRateModifier, setIsShowModalRateModifier] = useState(false);
  const [selectedRateModifier, setSelectedRateModifier] = useState('');

  const {
    isLoading: isConstantsLoading,
    selections: { rateModifierTypes, rateModifierSigns }
  } = useFetchConstants();
  const { paginatedData: rateModifiers, total, isLoading: isRateModifiersLoading, refetch: refetchRateModifiers } = useGetPaginatedRateModifiers(
    query
  );

  const isLoading = isConstantsLoading || isRateModifiersLoading;

  const handleOnClickEditRateModifier = rateModifier => {
    setIsShowModalRateModifier(true);
    setSelectedRateModifier(rateModifier);
  };

  const handleOnModalRateModifierClose = () => {
    setIsShowModalRateModifier(false);
    setSelectedRateModifier('');
    refetchRateModifiers();
  };

  return (
    <>
      {isShowModalRateModifier && (
        <ModalRateModifier
          isShowModalRateModifier={isShowModalRateModifier}
          selectedRateModifier={selectedRateModifier}
          onModalRateModifierClose={handleOnModalRateModifierClose}
          rateModifierTypes={rateModifierTypes}
          rateModifierSigns={rateModifierSigns}
          isAllowEditRateModifier={isAllowEditRateModifier}
          isAllowDeleteRateModifier={isAllowDeleteRateModifier}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      )}
      {isAllowCreateRateModifier && (
        <Row>
          <Button
            id="create-button3-rate"
            type="primary"
            icon="plus"
            onClick={() => setIsShowModalRateModifier(true)}
            disabled={checkIsAdminReadOnly()}
          >
            {intl.get('rateModifier.headerLabels.create').d('Create Rate Modifier')}
          </Button>
          {props.checkAbleExportRateModifier() && !checkIsAdminReadOnly() && (
            <DownloadCSV data={rateModifiers} rateModifierTypes={rateModifierTypes} rateModifierSigns={rateModifierSigns} />
          )}
        </Row>
      )}
      <Row className={styles.table}>
        <Table
          loading={isLoading}
          rowKey={record => record._id}
          dataSource={rateModifiers}
          columns={constructColumns(rateModifierTypes, rateModifierSigns, handleOnClickEditRateModifier)}
          pagination={{ total }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </Row>
    </>
  );
};

export default withAppContext(Standard);
