import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';
import { Row, Col, notification, Table, Button, Card, Input, Icon, Checkbox } from 'antd';
import intl from 'react-intl-universal';

import { getAirbnbUsers, getAllAirbnbNotification } from 'utils/apis/integration';

const airbnbUrl = 'https://www.airbnb.com';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: []
    };
  }

  componentDidMount() {
    getAirbnbUsers().then(res => {
      const airbnbUsers = res.data.map(d => d._id);
      getAllAirbnbNotification(airbnbUsers).then(res => {
        if (res && res.status >= 200 && res.status < 300) {
          // console.log(res);
          this.setState({
            notifications: res.data
          });
        } else {
          notification.error({
            message: 'Trouble retrieving data! Please try again later.'
          });
        }
      });
    });
  }

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  render() {
    const { notifications } = this.state;
    const { checkIsAdminReadOnly } = this.props;

    const TypeEnum = [
      { key: 'resolution', value: 'resolution', label: intl.get('notifications.type.resolution').d('Resolution') },
      { key: 'notification', value: 'notification', label: intl.get('notifications.type.notification').d('Notification') },
      { key: 'review', value: 'review', label: intl.get('notifications.type.review').d('Review') }
    ];

    const notificationColumn = checkIsAdminReadOnly => [
      {
        title: 'Airbnb ID',
        key: 'airbnb.userId',
        dataIndex: 'airbnb.userId',
        sorter: (a, b) => a.airbnb.userId && b.airbnb.userId && a.airbnb.userId.localeCompare(b.airbnb.userId),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.airbnb').d('Search host ID')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUserId"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.airbnb.userId.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (userId, record) => {
          console.log(82, record);
          return userId;
        }
      },
      {
        title: intl.get('notifications.tableColumns.type').d('Type'),
        key: 'type',
        dataIndex: 'type',
        sorter: (a, b) => a.type && b.type && a.type.localeCompare(b.type),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Checkbox.Group
              ref={ele => (this.searchInput = ele)}
              defaultValue={selectedKeys[0]}
              style={{ width: '100%' }}
              onChange={e => setSelectedKeys(e)}
              value={selectedKeys}
            >
              {TypeEnum.map(value => (
                <Row key={value.value}>
                  <Checkbox style={{ height: '32px' }} value={value.value}>
                    {value.label}
                  </Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.type.includes(value),
        render: (text, record) => <span>{intl.get(`notifications.type.${record.type}`).d(record.type)}</span>
        // onFilterDropdownVisibleChange: visible => {
        //   if (visible) {
        //     setTimeout(() => {
        //       this.searchInput.focus();
        //     });
        //   }
        // }
      },
      {
        title: intl.get('notifications.tableColumns.title').d('Title'),
        key: 'title',
        sorter: (a, b) => {
          if (a.type === 'resolution' && b.type === 'resolution') {
            return a.reason_name && b.reason_name && a.reason_name.localeCompare(b.reason_name);
          } else {
            return a.title && b.title && a.title.localeCompare(b.title);
          }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.title').d('Search Title')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchTitle"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.type === 'resolution') {
            return record.reason_name.toLowerCase().includes(value.toLowerCase());
          } else {
            return record.title.toLowerCase().includes(value.toLowerCase());
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (userId, record) => {
          if (record.type === 'resolution') {
            return record.reason_name;
          } else {
            return record.title;
          }
        }
      },
      {
        title: intl.get('notifications.tableColumns.detail').d('Details'),
        key: 'details',
        sorter: (a, b) => {
          if (a.type === 'resolution' && b.type === 'resolution') {
            return a.notes && b.notes && a.notes.localeCompare(b.notes);
          } else {
            return a.subtitle && b.subtitle && a.subtitle.localeCompare(b.subtitle);
          }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.detail').d('Search Details')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchDetails"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.type === 'resolution') {
            return record.notes.toLowerCase().includes(value.toLowerCase());
          } else {
            return record.subtitle.toLowerCase().includes(value.toLowerCase());
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (userId, record) => {
          if (record.type === 'resolution') {
            return record.notes;
          } else {
            return record.subtitle;
          }
        }
      },
      {
        title: intl.get('notifications.tableColumns.action').d('Actions'),
        key: 'actions',
        render: (x, record) => {
          let ctaUrl = '';
          if (record.type === 'resolution') {
            ctaUrl = airbnbUrl + record.detail_link;
          } else {
            ctaUrl = record.cta_url;
          }
          return (
            <>
              <a target="_blank" rel="noopener noreferrer" href={ctaUrl}>
                <Button>{intl.get('notifications.tableColumns.link').d('Link')}</Button>
              </a>
            </>
          );
        }
      }
    ];

    console.log(notification);
    return (
      <React.Fragment>
        <Card className="list-card">
          <Row type="flex" justify="center">
            <Col style={{ width: '100%' }}>
              <Table scroll={{ x: 1000 }} columns={notificationColumn(checkIsAdminReadOnly)} dataSource={notifications} />
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default withRouter(withAppContext(Notifications));
