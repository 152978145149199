import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import moment from 'moment';

import { useFetchConstant } from 'hooks/constants';
import { getConstantLabel, generateDisplayFee, guard } from 'utils/general';
import { buildListingWizardUri } from 'utils/routes';
import { useGetUnitListings } from 'utils/apis/unit';

import CSVDownload from 'components/CSVDownload/CSVDownload';

import styles from './Header.module.css';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const handleOnClickCSVDownload = async (statesMYSelection, units, setCSVName, setCSVData) => {
  const setName = setCSVName => {
    const currentTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = `Listing_.${currentTime}.csv`;
    setCSVName(csvName);
  };

  const constructCSVData = units => {
    const constructCostObjects = operationCostObject => {
      const constructCostObject = (costName, costObject) => {
        let csvColumns = {};

        csvColumns[`${costName} ${intl.get('csv.operationPerDay').d('(Operation Cost per day)')}`] = generateDisplayFee(
          costObject.perDay,
          false,
          '0.00'
        );
        csvColumns[`${costName} ${intl.get('csv.operationPerMonth').d('(Operation Cost per month)')}`] = generateDisplayFee(
          costObject.perMonth,
          false,
          '0.00'
        );
        csvColumns[`${costName} ${intl.get('csv.operationPerReservation').d('(Operation Cost per reservation)')}`] = generateDisplayFee(
          costObject.perCheckOut,
          false,
          '0.00'
        );

        return csvColumns;
      };

      const electricityObject = !!operationCostObject ? operationCostObject.electricity || {} : {};
      const waterObject = !!operationCostObject ? operationCostObject.water || {} : {};
      const internetObject = !!operationCostObject ? operationCostObject.internet || {} : {};
      const cleaningObject = !!operationCostObject ? operationCostObject.cleaning || {} : {};
      const laundryObject = !!operationCostObject ? operationCostObject.laundry || {} : {};
      const serviceObject = !!operationCostObject ? operationCostObject.service || {} : {};
      const checkInObject = !!operationCostObject ? operationCostObject.checkIn || {} : {};
      const checkOutObject = !!operationCostObject ? operationCostObject.checkOut || {} : {};

      return {
        ...constructCostObject(intl.get('csv.electricity').d('Electricity'), electricityObject),
        ...constructCostObject(intl.get('csv.water').d('Water'), waterObject),
        ...constructCostObject(intl.get('csv.internet').d('Internet'), internetObject),
        ...constructCostObject(intl.get('csv.cleaning').d('Cleaning'), cleaningObject),
        ...constructCostObject(intl.get('csv.laundry').d('Laundry'), laundryObject),
        ...constructCostObject(intl.get('csv.service').d('Service'), serviceObject),
        ...constructCostObject(intl.get('csv.checkIn').d('Check In'), checkInObject),
        ...constructCostObject(intl.get('csv.checkOut').d('Check Out'), checkOutObject)
      };
    };

    const constructDescriptionObjects = detailedDescriptionObject => {
      const sanitizeString = string => {
        let sanitizedString = '';
        if (string) {
          sanitizedString = string.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');
          sanitizedString = sanitizedString.replace(/"/g, '""');
        }
        return sanitizedString;
      };

      const constructDescriptionObject = (descriptionName, description) => {
        let csvColumn = {};
        csvColumn[`${descriptionName} ${intl.get('csv.description').d('(Description)')}`] = sanitizeString(description);

        return csvColumn;
      };

      const summary = (!!detailedDescriptionObject && detailedDescriptionObject.summary) || '-';
      const space = (!!detailedDescriptionObject && detailedDescriptionObject.space) || '-';
      const access = (!!detailedDescriptionObject && detailedDescriptionObject.access) || '-';
      const interaction = (!!detailedDescriptionObject && detailedDescriptionObject.interaction) || '-';
      const neighborhoodOverview = (!!detailedDescriptionObject && detailedDescriptionObject.neighborhoodOverview) || '-';
      const transit = (!!detailedDescriptionObject && detailedDescriptionObject.transit) || '-';
      const notes = (!!detailedDescriptionObject && detailedDescriptionObject.notes) || '-';
      const houseRules = (!!detailedDescriptionObject && detailedDescriptionObject.houseRules) || '-';

      return {
        ...constructDescriptionObject(intl.get('csv.summary').d('Summary'), summary),
        ...constructDescriptionObject(intl.get('csv.space').d('Space'), space),
        ...constructDescriptionObject(intl.get('csv.access').d('Access'), access),
        ...constructDescriptionObject(intl.get('csv.interaction').d('Interaction'), interaction),
        ...constructDescriptionObject(intl.get('csv.neighborhoodOverview').d('Neighborhood Overview'), neighborhoodOverview),
        ...constructDescriptionObject(intl.get('csv.transit').d('Transit'), transit),
        ...constructDescriptionObject(intl.get('csv.notes').d('Notes'), notes),
        ...constructDescriptionObject(intl.get('csv.houseRules').d('HouseRules'), houseRules)
      };
    };

    return units.map(unit => {
      return {
        [intl.get('csv.key').d('Key')]: unit._id,
        [intl.get('csv.unitName').d('Unit Name')]: unit.name,
        [intl.get('csv.propertyName').d('Property Name')]: guard(() => unit.roomType.property.name, '-'),
        [intl.get('csv.roomType').d('Room Type Name')]: guard(() => unit.roomType.name, '-'),
        [intl.get('csv.timezone').d('Timezone')]: guard(() => unit.roomType.property.host.timezone, '-'),
        [intl.get('csv.longitude').d('Longitude')]: guard(() => unit.roomType.property.longitude, '-'),
        [intl.get('csv.latitude').d('Latitude')]: guard(() => unit.roomType.property.latitude, '-'),
        [intl.get('csv.street').d('Street')]: guard(() => unit.roomType.property.street, '-'),
        [intl.get('csv.city').d('City')]: guard(() => unit.roomType.property.city, '-'),
        [intl.get('csv.state').d('State')]: guard(() => getConstantLabel(statesMYSelection, unit.roomType.property.state), '-'),
        [intl.get('csv.zipcode').d('ZipCode')]: guard(() => unit.roomType.property.zipCode, '-'),
        [intl.get('csv.countryCode').d('Country Code')]: guard(() => unit.roomType.property.countryCode, '-'),

        [intl.get('csv.host').d('Host')]: guard(() => unit.roomType.property.host.name, '-'),
        [intl.get('csv.owner').d('Owner')]: guard(() => `${unit.ownedBy.userProfile.firstName} ${unit.ownedBy.userProfile.lastName}`, '-'),
        [intl.get('csv.contractPeriodStart').d('Contract Period Start Date')]: guard(() => unit.contractPeriod.start, '-'),
        [intl.get('csv.contractPeriodEnd').d('Contract Period End Date')]: guard(() => unit.contractPeriod.end, '-'),
        [intl.get('csv.parentUnit').d('Parent Unit')]: guard(() => unit.parent.name, '-'),
        [intl.get('csv.parentRoomType').d('Parent Room Type Name')]: guard(() => unit.parent.roomType.name, '-'),
        [intl.get('csv.insuranceCertNo').d('Insurance Cert No')]: guard(() => unit.protection.certNo, '-'),
        [intl.get('csv.insuranceStartDate').d('Insurance Start Date')]: guard(() => unit.protection.insuranceStartDate, '-'),
        [intl.get('csv.insuranceEndDate').d('Insurance End Date')]: guard(() => unit.protection.insuranceEndDate, '-'),
        [intl.get('csv.currency').d('Currency')]: guard(() => unit.roomType.property.host.currency, '-'),
        [intl.get('csv.weekdayPrice').d('Weekday Price')]: guard(() => generateDisplayFee(unit.rate.weekday, false, '0.00')),
        [intl.get('csv.weekendPrice').d('Weekend Price')]: guard(() => generateDisplayFee(unit.rate.weekend, false, '0.00')),

        ...constructCostObjects(unit.operationCost),
        ...constructDescriptionObjects(unit.detailedDescription),

        [intl.get('csv.createdAt').d('Created At')]: unit.createdAt
      };
    });
  };

  setName(setCSVName);
  await setCSVData(constructCSVData(units));
};

const Header = ({ onClickClearAll, checkIsAdminReadOnly, ...props }) => {
  const { selection: statesMYSelection } = useFetchConstant('statesMY');
  const { data: units, isLoading: isLoadingCSV } = useGetUnitListings();
  const [csvName, setCSVName] = useState('');
  const [csvData, setCSVData] = useState([]);

  return (
    <div className={styles.header}>
      <div>
        <Button id="create-button6-listing" type="primary" icon="plus" href={buildListingWizardUri()} disabled={checkIsAdminReadOnly()}>
          {intl.get('listings.headerLabels.create').d('Create Listing')}
        </Button>
      </div>
      <div>
        <Button id="reset-button1-listing" onClick={onClickClearAll}>
          {intl.get('listings.headerLabels.reset').d('Reset All')}
        </Button>
        {props.checkAbleExportListing() && (
          <CSVDownload
            id={'csv-button5-listing'}
            className={styles.csvDownload}
            filename={csvName}
            data={csvData}
            onClick={() => handleOnClickCSVDownload(statesMYSelection, units, setCSVName, setCSVData)}
            isLoading={isLoadingCSV}
            checkIsAdminReadOnly={checkIsAdminReadOnly}
          />
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  onClickClearAll: PropTypes.func.isRequired
};

export default withAppContext(Header);
