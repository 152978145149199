import React, { Component } from 'react';
import { Form, Modal, notification, Row } from 'antd';
import csvjson from 'csvjson';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormSelection from 'components/FormSelection/FormSelection';
import { getExpensesListing } from 'utils/apis/expenses';
import intl from 'react-intl-universal';
import { guard } from 'utils/general';

class CsvModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    servicePackages: PropTypes.array.isRequired
  };

  static defaultProps = {
    closeModal: () => {},
    visible: false,
    servicePackages: []
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currency: 'RM'
      // startDate: moment()
      //   .startOf('month')
      //   .format(DATE_FORMAT),
      // endDate: moment()
      //   .endOf('month')
      //   .format(DATE_FORMAT)
    };
  }

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  downloadCsv = expenses => {
    const { closeModal } = this.props;

    const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const filename = `expenses_${curDateTime}.csv`;

    const csvData = csvjson.toCSV(expenses, { headers: 'key', encoding: 'utf-8' });
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData], { type: 'text/csv;charset=utf-8' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ isLoading: false });
    closeModal();
  };

  fetchCsv = () => {
    const { year, month, host, form } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const servicePackageIds = values.servicePackages || [];
        getExpensesListing(year, month, host && host !== 'all' ? host : null, servicePackageIds).then(async res => {
          const allExpenses = [];
          console.log(88, res.data);
          if (res.data.length > 0) {
            notification.info({
              message: intl.get('reservations.csvModal.generateMsg').d('Generating CSV...'),
              description: intl
                .get('reservations.csvModal.generateDesc')
                .d('Please wait while we generate the CSV file for you. It will be downloaded automatically once it is done.')
            });
            this.setState({
              currency: guard(() => res.data[0].host.currency, 'RM')
            });
            for (let i = 0; i < res.data.length; i++) {
              const formattedReservations = this.formatCsvData(res.data[i].expenses, res.data[i].svcPacName);
              allExpenses.push(formattedReservations);
            }
            this.downloadCsv(allExpenses);
          } else {
            const { closeModal } = this.props;

            notification.error({
              message: 'No Expenses',
              description: 'There are no reservations within the selected date range.'
            });

            this.setState({ isLoading: false });
            closeModal();
          }
        });
      }
    });
  };

  formatCsvData = (expenses, svcPacName) => {
    return expenses.map(a => {
      const {
        unitNo,
        electricity,
        water,
        internet,
        cleaning,
        laundry,
        checkIn,
        checkOut,
        service,
        presetelectricity,
        presetwater,
        presetinternet,
        presetcleaning,
        presetlaundry,
        presetcheckIn,
        presetcheckOut,
        presetservice,
        others,
        toiletry
      } = a;

      let moreThanOneAmount = (a, b) => {
        return a + ' ,' + (b.amount ? b.amount : '-');
      };

      let moreThanOneLabel = (a, b) => {
        return a + ' ,' + (b.label ? b.label : '-');
      };

      return {
        [intl.get('csv.servicePackage').d('Service Package Name')]: svcPacName,
        [intl.get('csv.currency').d('Currency')]: this.state.currency,
        [intl.get('csv.unitLabel').d('Unit')]: unitNo || '',
        [intl.get('csv.electricity').d('Electricity')]: electricity || 0,
        [intl.get('csv.presetElectricity').d('Preset Electricity')]: presetelectricity || 0,
        [intl.get('csv.totalElectricity').d('Total Electricity')]: electricity + presetelectricity || 0,
        [intl.get('csv.water').d('Water')]: water || 0,
        [intl.get('csv.presetWater').d('Preset Water')]: presetwater || 0,
        [intl.get('csv.totalWater').d('Total Water')]: water + presetwater || 0,
        [intl.get('csv.internet').d('Internet')]: internet || 0,
        [intl.get('csv.presetInternet').d('Preset Internet')]: presetinternet || 0,
        [intl.get('csv.totalInternet').d('Total Internet')]: internet + presetinternet || 0,
        [intl.get('csv.cleaning').d('Cleaning')]: cleaning || 0,
        [intl.get('csv.presetCleaning').d('Preset Cleaning')]: presetcleaning || 0,
        [intl.get('csv.totalCleaning').d('Total Cleaning')]: cleaning + presetcleaning || 0,
        [intl.get('csv.laundry').d('Laundry')]: laundry || 0,
        [intl.get('csv.presetLaundry').d('Preset Laundry')]: presetlaundry || 0,
        [intl.get('csv.totalLaundry').d('Total Laundry')]: laundry + presetlaundry || 0,
        [intl.get('csv.checkIn').d('Check-In')]: checkIn || 0,
        [intl.get('csv.presetCheckIn').d('Preset Check-In')]: presetcheckIn || 0,
        [intl.get('csv.totalCheckIn').d('Total Check-In')]: checkIn + presetcheckIn || 0,
        [intl.get('csv.checkOut').d('Check-Out')]: checkOut || 0,
        [intl.get('csv.presetCheckOut').d('Preset Check-Out')]: presetcheckOut || 0,
        [intl.get('csv.totalCheckOut').d('Total Check-Out')]: checkOut + presetcheckOut || 0,
        [intl.get('csv.service').d('Service')]: service || 0,
        [intl.get('csv.presetService').d('Preset Service')]: presetservice || 0,
        [intl.get('csv.totalService').d('Total Service')]: service + presetservice || 0,
        [intl.get('csv.other').d('Others')]: others
          ? others && others.length > 0
            ? others.length === 1
              ? others[0].label
                ? others[0].label
                : '-'
              : others.reduce(moreThanOneLabel, '')
            : ''
          : '-',
        [intl.get('csv.otherAmount').d('Others Amount')]: others
          ? others && others.length > 0
            ? others.length === 1
              ? others[0].amount
                ? others[0].amount
                : '-'
              : others.reduce(moreThanOneAmount, '')
            : ''
          : '-',
        [intl.get('csv.toiletry').d('Toiletry')]: toiletry
          ? toiletry && toiletry.length > 0
            ? toiletry.length === 1
              ? toiletry[0].label
                ? toiletry[0].label
                : '-'
              : toiletry.reduce(moreThanOneLabel, '')
            : ''
          : '-',
        [intl.get('csv.toiletryAmount').d('Toiletry Amount')]: toiletry
          ? toiletry && toiletry.length > 0
            ? toiletry.length === 1
              ? toiletry[0].amount
                ? toiletry[0].amount
                : '-'
              : toiletry.reduce(moreThanOneAmount, '')
            : ''
          : '-'
      };
    });
  };

  handleOnOk = () => {
    this.setState({ isLoading: true });

    this.fetchCsv();
  };

  render() {
    const { visible, closeModal, form, servicePackages, year, month } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={`${intl.get('expenses.headerLabels.csvTitle').d('Download Expenses CSV for')} ${year}/${month + 1}`}
        destroyOnClose={true}
        maskClosable={false}
        cancelButtonProps={{ id: 'closecsv-cfm-button1b-expcsv' }}
        cancelText={intl.get('expenses.headerLabels.close').d('Close')}
        okButtonProps={{ loading: isLoading, id: 'csv-cfm-button1a-expcsv' }}
        okText={intl.get('expenses.headerLabels.csv').d('Download CSV')}
        onOk={this.handleOnOk}
      >
        <Row type="flex" justify="space-around" align="middle">
          <FormSelection
            form={form}
            multipleMode
            formLabel={intl.get('expenses.headerLabels.servicePackageExport').d('Select the service packages you wish to export: ')}
            name="servicePackages"
            defaultValue={[]}
            placeholder={intl.get('expenses.placeholder.servicePackage').d('Select service packages')}
            selections={servicePackages.map(svcPck => {
              return { key: svcPck.svcId, displayValue: `${svcPck.svcPacName}` };
            })}
          />
        </Row>
      </Modal>
    );
  }
}

export default Form.create()(CsvModal);
