import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';
import { Row, Col, notification, Table, Button, Modal, Input, Form, Icon, Select, Card, Tabs } from 'antd';

import queryString from 'query-string';

import { getHosts } from 'utils/apis/host';
import { refreshCode, getAirbnbUsers, updateAirbnbUser } from 'utils/apis/integration';
import { guard } from 'utils/general';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const Option = Select.Option;
const airbnbUserUrl = 'https://www.airbnb.com/users/show/';
class AirbnbButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airbnbs: [],
      record: null,
      showModal: false,
      hostMapping: [],
      searchUserId: '',
      searchNickname: '',
      searchHost: '',
      type: 'users'
    };
    this.showSetNickname = this.showSetNickname.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
  }

  componentDidMount() {
    const { location, history } = this.props;

    const script = document.createElement('script');
    let code = queryString.parse(location.search).code;
    getHosts()
      .then(hostRes => {
        if (hostRes && hostRes.status === 200 && hostRes.data) {
          let hostMapping = hostRes.data.map(host => {
            return {
              _id: host._id,
              name: host.name
            };
          });
          let hostOptions = hostRes.data.map(host => {
            return <Option value={host._id}>{host.name}</Option>;
          });
          this.setState({
            hostOptions,
            hostMapping
          });
        } else {
          // console.log('getHost have issues brah');
        }
      })
      .catch(ex => {
        // console.log(ex);
      });
    if (code) {
      refreshCode(code).then(res => {
        if (res && res.status === 200) {
          notification.open({
            message: intl.get('hostConnect.airbnb.message.hostSucessMsg').d('Alright!'),
            description: intl.get('hostConnect.airbnb.message.hostSucessDesc').d('Airbnb User authorization is succesfully processed!')
          });
          getAirbnbUsers().then(res => {
            this.setState({ airbnbs: res.data });
          });
        } else {
          notification.open({
            message: intl.get('hostConnect.airbnb.message.hostFailMsg').d('Error!'),
            description: intl.get('hostConnect.airbnb.message.hostFailDesc').d('Encountered an error when trying to authorize Airbnb User')
          });
        }
        history.push('/integrations/airbnb-connect');
      });
    }
    script.src = 'https://www.airbnb.com/platform_js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    getAirbnbUsers().then(res => {
      this.setState({ airbnbs: res.data });
    });
  }
  submitEdit = airbnbId => () => {
    const { form } = this.props;

    updateAirbnbUser(airbnbId, form.getFieldsValue().nickname, form.getFieldsValue().host).then(res => {
      if (res && res.status >= 200 && res.status < 300) {
        notification.success({
          message: intl.get('hostConnect.airbnb.message.updateSucess').d('Airbnb user saved succesfully!')
        });
        getAirbnbUsers().then(res => {
          this.hideModal();
          this.setState({ airbnbs: res.data });
        });
      } else {
        notification.error({
          message: intl.get('hostConnect.airbnb.message.updateFail').d('Airbnb user save error!')
        });
      }
    });
  };
  showSetNickname = record => () => {
    this.setState({
      showModal: true,
      record
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  render() {
    let { showModal, record, hostOptions, hostMapping } = this.state;
    const { checkIsAllowCreateIntegration, checkIsAllowEditIntegration, checkIsAdminReadOnly, form } = this.props;

    const isAllowCreate = !!checkIsAllowCreateIntegration();
    const isAllowEdit = !!checkIsAllowEditIntegration();

    const columns = checkIsAdminReadOnly => [
      {
        title: intl.get('hostConnect.airbnb.tableColumns.id').d('Airbnb ID'),
        key: 'userId',
        dataIndex: 'userId',
        sorter: (a, b) => a.userId && b.userId && a.userId.localeCompare(b.userId),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.airbnb').d('Search host ID')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUserId"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.userId.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (userId, record) => {
          if (checkIsAdminReadOnly) {
            return userId;
          } else {
            return (
              <a target="_blank" rel="noopener noreferrer" href={airbnbUserUrl + userId}>
                {userId}
              </a>
            );
          }
        }
      },
      {
        title: intl.get('hostConnect.airbnb.tableColumns.nickname').d('Nickname'),
        key: 'nickname',
        dataIndex: 'nickname',
        sorter: (a, b) => a.nickname && b.nickname && a.nickname.localeCompare(b.nickname),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.nickname').d('Search nickname')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUserId"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.nickname) {
            return record.nickname.toLowerCase().includes(value.toLowerCase());
          } else {
            return '';
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (nickname, record) => {
          if (!nickname) {
            return (
              <Button onClick={this.showSetNickname(record)}>{intl.get('hostConnect.airbnb.headerLabels.setNickname').d('Set a Nickname')}</Button>
            );
          }
          return nickname;
        }
      },
      {
        title: intl.get('hostConnect.airbnb.tableColumns.host').d('Host'),
        key: 'host',
        dataIndex: 'host',
        sorter: (a, b) => a.host && b.host && a.host.localeCompare(b.host),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.hostName').d('Search Host')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchHost"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.host) {
            const hostMap = hostMapping.find(hostMap => String(hostMap._id) === String(record.host));
            return guard(() => hostMap.name.toLowerCase().includes(value.toLowerCase()), false);
          } else {
            return '';
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (host, record) => {
          if (!host) {
            return <Button onClick={this.showSetNickname(record)}>Assign A Host</Button>;
          }
          let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === host.toString())[0];
          if (hostMap) {
            return hostMap.name;
          } else {
            return host;
          }
        }
      },
      {
        title: intl.get('hostConnect.airbnb.tableColumns.listing').d('Airbnb Listings'),
        key: 'listings',
        dataIndex: 'listings',
        sorter: (a, b) => a.listings.length - b.listings.length,
        render: (listings, record) => {
          return listings ? listings.length : 0;
        }
      },
      {
        title: intl.get('hostConnect.airbnb.tableColumns.action').d('Actions'),
        key: 'actions',
        render: (x, record) => {
          return (
            <Button onClick={this.showSetNickname(record)} disabled={!isAllowEdit || checkIsAdminReadOnly()}>
              {intl.get('hostConnect.ctrip.headerLabels.edit').d('Edit')}
            </Button>
          );
        }
      }
    ];

    let redirectUri = window.location.toString().split('?')[0];

    let airbnbKey = process.env.REACT_APP_AIRBNB_KEY;
    return (
      <React.Fragment>
        <Card className="list-card">
          {record && (
            <Modal
              title={
                <React.Fragment>
                  {intl.get('hostConnect.airbnb.headerLabels.edit').d('Edit Airbnb User ')}
                  <a target="_blank" rel="noopener noreferrer" href={airbnbUserUrl + record.userId}>
                    {(record.nickname ? record.nickname + ' ' : '') + intl.get('hostConnect.airbnb.headerLabels.id').d('(ID: ') + record.userId + ')'}
                  </a>
                </React.Fragment>
              }
              visible={showModal}
              onOk={this.submitEdit(record._id)}
              onCancel={this.hideModal}
              okText={intl.get('reservations.headerLabels.save').d('Save')}
              cancelText={intl.get('reservations.headerLabels.cancel').d('Cancel')}
            >
              <Form onSubmit={this.submitEdit(record._id)}>
                <FormItem label={intl.get('hostConnect.airbnb.headerLabels.nickname').d('Nickname')}>
                  {form.getFieldDecorator('nickname', {
                    rules: [],
                    initialValue: record.nickname
                  })(
                    <Input
                      prefix={<Icon type="user" />}
                      placeholder={intl.get('hostConnect.airbnb.placeholder.nickname').d('Airbnb User Nickname')}
                    />
                  )}
                </FormItem>

                <FormItem label={intl.get('hostConnect.airbnb.headerLabels.host').d('Host')}>
                  {form.getFieldDecorator('host', {
                    rules: [],
                    initialValue: record.host
                  })(
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {hostOptions}
                    </Select>
                  )}
                </FormItem>
              </Form>
            </Modal>
          )}
          <Row type="flex" justify="left">
            {isAllowCreate && !checkIsAdminReadOnly() && (
              <Col style={{ marginBottom: 13 }}>
                <span className="airbnb-connect" data-client-id={airbnbKey} data-scope="vr" data-redirect-uri={redirectUri} />
              </Col>
            )}
          </Row>
          <Row type="flex" justify="center">
            <Col style={{ width: '100%' }}>
              <Table scroll={{ x: 1000 }} columns={columns(checkIsAdminReadOnly)} dataSource={this.state.airbnbs} />
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default Form.create()(withRouter(withAppContext(AirbnbButton)));
