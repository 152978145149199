import React from 'react';
import { Row, Col, Button, List, Divider } from 'antd';

import Counter from 'components/Counter/Counter';

import styles from './Bedrooms.module.css';
import intl from 'react-intl-universal';

export const BedRoomListItem = ({ title, description, onEditClick, onRemoveClick, shouldDisableRemove }) => {
  const roomNumber = title.split(' ')[1]; // Assuming title is "Room 1"
  const roomName = intl.get('listings.roomType.headerLabels.room').d('Room');
  const translatedTitle = `${roomName} ${roomNumber}`;
  return (
    <List.Item>
      <List.Item.Meta title={translatedTitle} description={description} />
      <div className={styles.bedRoomListItemButtonContainer}>
        <Button ghost type="primary" onClick={onEditClick}>
          {intl.get('listings.roomType.headerLabels.edit').d('Edit')}
        </Button>
        <Button icon="delete" disabled={shouldDisableRemove} ghost type="danger" onClick={onRemoveClick} />
      </div>
    </List.Item>
  );
};

export const BedConfiguration = ({ beds, onBedDecrement, onBedIncrement, roomName }) => {
  const roomNumber = roomName.split(' ')[1]; // Assuming title is "Room 1"
  const room = intl.get('listings.roomType.headerLabels.room').d('Room');
  const translatedTitle = `${room} ${roomNumber}`;
  return (
    <div>
      <h4>
        {intl.get('listings.roomType.headerLabels.editing').d('Editing:')} {translatedTitle}
      </h4>
      <Divider />
      {beds &&
        beds.length > 0 &&
        beds.map(bed => (
          <Row key={bed.label} type="flex" justify="start" className={styles.listArrangementDetails}>
            <Col span={16} md={13}>
              <p style={{ marginBottom: '8px', fontSize: '16px' }}>{intl.get(`listings.roomType.bedType.${bed.label}`).d('bedType')}</p>
            </Col>
            <Col span={8} md={11}>
              <Counter count={bed.count} onDecrement={onBedDecrement(bed.key)} onIncrement={onBedIncrement(bed.key)} />
            </Col>
          </Row>
        ))}
    </div>
  );
};
