import React, { Component } from 'react';
import { Input, Form, Button, Select, Radio, Card, Icon, Modal, notification } from 'antd';
import { getRevenueShare, saveRevenueShare } from '../../utils/apis/revenueshare';
import { getHosts } from '../../utils/apis/host';
import { getConstants } from '../../utils/apis/constants';
import { withRouter } from 'react-router-dom';
import './RevenueShareForm.css';
import CloseButton from './../../components/CloseButton/CloseButton';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const modeConst = {
  NEW: 'new',
  UPDATE: 'update'
};

class RevenueShareForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: modeConst.NEW,
      data: {
        calculations: [{}]
      },
      hostOptions: null,
      shareTypes: null,
      thresholdTypes: null,
      isSaveButtonLoading: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.addSteps = this.addSteps.bind(this);
    this.removeSteps = this.removeSteps.bind(this);
    this.isSavable = this.isSavable.bind(this);
  }
  isSavable = () => {
    const { mode, data } = this.state;
    return mode === modeConst.NEW || (data && !data.isDefault);
  };
  componentWillMount = () => {
    getConstants('shareTypes')
      .then(res => {
        if (res && res.status === 200 && res.data) {
          this.setState({
            shareTypes: Object.keys(res.data).map(key => {
              return res.data[key];
            })
          });
        } else {
          notification.error({
            message: 'Share Types Constant loading failed!'
          });
        }
      })
      .catch(ex => {
        notification.error({
          message: 'Share Types Constant loading failed!'
        });
      });
    getConstants('thresholdTypes')
      .then(res => {
        if (res && res.status === 200 && res.data) {
          this.setState({
            thresholdTypes: Object.keys(res.data).map(key => {
              return res.data[key];
            })
          });
        } else {
          notification.error({
            message: 'Threshold Types Constant loading failed!'
          });
        }
      })
      .catch(ex => {
        notification.error({
          message: 'Threshold Types Constant loading failed!'
        });
      });
    getHosts()
      .then(res => {
        if (res && res.status === 200 && res.data) {
          this.setState({
            hostOptions: res.data
          });
        } else {
          notification.error({
            message: 'Host load failed!'
          });
        }
      })
      .catch(ex => {
        notification.error({
          message: 'Host load failed!'
        });
      });
    if (this.props.match.params.id) {
      getRevenueShare(this.props.match.params.id)
        .then(res => {
          if (res && res.status === 200 && res.data) {
            this.setState({
              data: res.data,
              mode: modeConst.UPDATE
            });
          } else {
            notification.error({
              message: 'Provided id does not return any Revenue Share!'
            });
          }
        })
        .catch(ex => {
          notification.error({
            message: 'Provided id does not return any Revenue Share!'
          });
        });
    }
  };
  componentWillUpdate = (prevProps, prevState) => {};

  onSubmit = e => {
    e.preventDefault();
    const { mode } = this.state;
    const {
      match: { params },
      form,
      history
    } = this.props;
    const state = this;
    if (this.isSavable()) {
      state.setState({ isSaveButtonLoading: true });
      form.validateFields((err, values) => {
        if (err) {
          return;
        } else {
          saveRevenueShare(form.getFieldsValue(), mode !== modeConst.NEW ? params.id : undefined)
            .then(() => {
              history.push('/revenueShare');
            })
            .catch(ex => {
              const errorMessage = ex.response.data && ex.response.data.message ? ex.response.data.message : 'Create failed!';
              Modal.error({
                title: intl.get('revenueShare.message.similarName').d('Similar Name'),
                content: <div>{errorMessage}</div>,

                onCancel() {
                  state.setState({ isSaveButtonLoading: false });
                }
              });
            });
        }
      });
    } else {
      notification.error({
        message: intl.get('revenueShare.message.unsavable').d('This revenue share is unsaveble!')
      });
    }
  };

  addSteps = e => {
    const { thresholdTypes, shareTypes } = this.state;
    this.state.data.calculations.push({
      thresholdType: thresholdTypes[0].code,
      shareType: shareTypes[0].code
    });
    this.setState({ data: this.state.data });
  };

  removeSteps = e => {
    this.state.data.calculations.splice(this.state.data.calculations.length - 1, 1);
    this.setState({ data: this.state.data });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, shareTypes, hostOptions, thresholdTypes, isSaveButtonLoading } = this.state;

    const isLoading = !shareTypes || !thresholdTypes;
    return (
      <Card title={intl.get('host.headerLabels.basic').d('Basic Information')} className="host-form-card" loading={isLoading}>
        <CloseButton
          onClick={() => {
            this.props.history.push('/revenueshare');
          }}
        />
        <Form onSubmit={this.onSubmit}>
          <FormItem key={`name`} label={intl.get('revenueShare.headerLabels.revenueName').d('Revenue Share Name')} colon={false}>
            <div className="guest-input-control-wrapper">
              {getFieldDecorator(`name`, {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: intl.get('revenueShare.placeholder.revenueMsg').d('Please input name')
                  }
                ],
                initialValue: data.name
              })(
                <Input
                  disabled={!this.isSavable()}
                  placeholder={intl.get('revenueShare.placeholder.revenue').d('Name this revenue share')}
                  onBlur={this.handleSubmit}
                />
              )}
            </div>
          </FormItem>
          <FormItem key={`hosts`} label={intl.get('revenueShare.headerLabels.host').d('Hosts')} colon={false}>
            <div className="guest-input-control-wrapper">
              {getFieldDecorator(`hosts`, {
                initialValue: data.hosts
              })(
                <Select
                  disabled={!this.isSavable()}
                  mode={'multiple'}
                  placeholder={intl.get('revenueShare.placeholder.host').d('Select relevant hosts')}
                  onBlur={this.handleSubmit}
                >
                  {hostOptions
                    ? hostOptions.map(hostOption => {
                        return <Option key={hostOption._id}>{hostOption.name}</Option>;
                      })
                    : []}
                </Select>
              )}
            </div>
          </FormItem>
          {!isLoading ? (
            data.calculations.map((calculation, i) => {
              let shareTypeValue = calculation ? calculation.shareType || shareTypes[0].code : shareTypes[0].code;
              let thresholdTypeValue = calculation ? calculation.thresholdType || thresholdTypes[0].code : thresholdTypes[0].code;
              return (
                <Card title={intl.get('revenueShare.headerLabels.step').d('Step ') + (i + 1)}>
                  {i !== 0 ? (
                    <FormItem key={`calculations[${i}].thresholdType`}>
                      {getFieldDecorator(`calculations[${i}].thresholdType`, {
                        rules: [
                          {
                            required: true,
                            message: intl.get('revenueShare.placeholder.threshold').d('Please choose a threshold type')
                          }
                        ],
                        initialValue: thresholdTypeValue
                      })(
                        <RadioGroup buttonStyle="solid" style={{ width: '100%' }}>
                          {thresholdTypes.map(thresholdType => {
                            return (
                              <RadioButton disabled={!this.isSavable() && thresholdType.code !== thresholdTypeValue} value={thresholdType.code}>
                                {thresholdType.code === 4
                                  ? intl.get('revenueShare.headerLabels.afterExpenses').d('After Expenses')
                                  : intl.get(`servicePackage.thresholdObject.${thresholdType.label}`).d('thresholdType')}
                              </RadioButton>
                            );
                          })}
                        </RadioGroup>
                      )}
                    </FormItem>
                  ) : (
                    ''
                  )}
                  <FormItem key={`calculations[${i}].shareType`}>
                    {getFieldDecorator(`calculations[${i}].shareType`, {
                      rules: [
                        {
                          required: true,
                          message: intl.get('revenueShare.placeholder.shareType').d('Please choose a share type')
                        }
                      ],
                      initialValue: shareTypeValue
                    })(
                      <RadioGroup buttonStyle="solid" style={{ width: '100%' }}>
                        {shareTypes.map(shareType => {
                          return (
                            <RadioButton disabled={!this.isSavable() && shareType.code !== shareTypeValue} value={shareType.code}>
                              {intl.get(`servicePackage.shareTypes.${shareType.label}`).d('shareType')}
                            </RadioButton>
                          );
                        })}
                      </RadioGroup>
                    )}
                  </FormItem>
                  {this.isSavable() && i === data.calculations.length - 1 ? (
                    <div>
                      <Button size="large" onClick={this.addSteps}>
                        <Icon type="plus" theme="outlined" />
                        {intl.get('revenueShare.headerLabels.addSteps').d('Add Steps')}
                      </Button>
                      {i !== 0 ? (
                        <Button size="large" onClick={this.removeSteps}>
                          <Icon type="minus" theme="outlined" />
                          {intl.get('revenueShare.headerLabels.removeSteps').d('Remove Step')}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              );
            })
          ) : (
            <Card loading={true} />
          )}
          <FormItem className="host-button-wrapper">
            <Button disabled={!this.isSavable() || isLoading} type="primary" htmlType="submit" loading={isSaveButtonLoading}>
              {isLoading ? intl.get('listings.unit.headerLabels.saving').d('Saving') : intl.get('listings.unit.headerLabels.save').d('Save')}
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

export default withRouter(Form.create()(RevenueShareForm));
