import React, { Component } from 'react';
import { Form, Icon, Button, Popconfirm, Row, Col } from 'antd';
import './TransportFieldSet.css';
import { constructTwoDecimalsRule } from 'utils/general';
import FormInput from 'components/FormInput/FormInput';
import intl from 'react-intl-universal';

import styles from './TaxFieldSet.module.css';

class ActivityProvided extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false
    };
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('addOns').filter(key => key !== k);
    // can use data-binding to set
    form.setFieldsValue({
      addOns: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [...fieldsToValidate, `activity[${key}]`, `amount[${key}]`];
      }, [])
    );
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('addOns');
    const nextKeys = keys.concat(Math.max(0, ...keys) + 1);
    // console.log(44, keys, nextKeys);
    form.setFieldsValue({
      addOns: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
  };

  componentDidMount() {
    const { data, form } = this.props;
    let addOns = data.map(d => d.key);
    if (addOns.length > 0) {
      form.setFieldsValue(
        {
          addOns
        },
        this.setState({
          update: true
        })
      );
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { data, form } = this.props;
    const { update } = this.state;
    if (update) {
      data.forEach(d => {
        let obj = {};
        let key = `activity[${d.key}]`;
        let key2 = `amount[${d.key}]`;
        obj[key] = d.activity;
        obj[key2] = d.amount;
        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { form, currency } = this.props;
    getFieldDecorator('addOns', { initialValue: [] });
    const keys = getFieldValue('addOns');
    const formItems = keys.map((k, index) => {
      return (
        <React.Fragment key={index}>
          <Row justify="start" gutter={8}>
            <Col span={12}>
              <FormInput
                className={styles.formInput}
                name={`activity[${k}]`}
                form={form}
                requiredErrorMessage={intl.get('listings.bookingEngine.placeholder.activity').d('Please input activity')}
                // defaultValue={k.values}
              />
            </Col>
            <Col span={11}>
              <FormInput
                form={form}
                addonBefore={currency || 'RM'}
                extraRules={[constructTwoDecimalsRule()]}
                name={`amount[${k}]`}
                requiredErrorMessage={intl.get('listings.bookingEngine.placeholder.amount').d('Please input amount')}
              />
            </Col>
            <Col span={1}>
              <Popconfirm
                title={intl.get('listings.bookingEngine.placeholder.remove').d('Are you sure you want to remove this activity?')}
                onConfirm={() => this.remove(k)}
                okText={intl.get('listings.bookingEngine.placeholder.yes').d('Yes')}
                cancelText={intl.get('listings.bookingEngine.placeholder.no').d('No')}
              >
                <Button className="booking-form-danger" shape="circle" type="danger" style={{ marginTop: '10px' }}>
                  <Icon type="minus" />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </React.Fragment>
      );
    });
    return (
      <Form>
        <Row justify="start" gutter={8}>
          <Col span={12}>{intl.get('listings.bookingEngine.headerLabels.activities').d('Activities')}</Col>
          <Col span={12}>
            {intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')} ({currency})
          </Col>
        </Row>
        {formItems}
        <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
          <Icon type="plus" /> {intl.get('listings.bookingEngine.headerLabels.addActivity').d('Add activity')}
        </Button>
      </Form>
    );
  }
}

const WrappedActivityProvided = ActivityProvided;
export default WrappedActivityProvided;
