import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import styles from './Rate.module.css';
import intl from 'react-intl-universal';

class Rate extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    onBack(allFieldsValue);
  };

  render() {
    const { defaultValues, form, isLastPage, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>{intl.get('listings.roomType.headerLabels.charge').d('How much do you wish to charge ?')}</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInputNumber
              defaultValue={defaultValues.weekdayPrice}
              form={form}
              name="weekdayPrice"
              label={`${intl.get('listings.roomType.headerLabels.weekday').d('Weekday Pricing')} (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder={intl.get('listings.roomType.placeholder.weekday').d('Price to charge on weekday')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.weekdayMsg').d('Please set a price for weekday.')}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInputNumber
              defaultValue={defaultValues.weekendPrice}
              form={form}
              name="weekendPrice"
              label={`${intl.get('listings.roomType.headerLabels.weekend').d('Weekend Pricing')} (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder={intl.get('listings.roomType.placeholder.weekend').d('Price to charge on weekend')}
              requiredErrorMessage={intl.get('listings.roomType.placeholder.weekendMsg').d('Please set a price for weekend.')}
              size="large"
            />
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

Rate.propTypes = {
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

Rate.defaultProps = {
  isLastPage: false
};

const WrappedFormRate = Form.create()(Rate);

export default WrappedFormRate;
