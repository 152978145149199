import React, { useState, useEffect } from 'react';
import { withAppContext } from 'context/AppContext';
import { Modal, Row, Col, Card, notification, Table, Button, Form, Message } from 'antd';

import { getColumnFilterSearchProps, getColumnSorterProps } from 'components/Table/Table';
import FormSelection from '../../../components/FormSelection/FormSelection';
import FormInput from '../../../components/FormInput/FormInput';
import FormInputNumber from '../../../components/FormInputNumber/FormInputNumber';

import { getCtripListings, pullNewCtripListing, putCtripListing } from 'utils/apis/integration';
import { getHosts } from '../../../utils/apis/host';
import { guard } from 'utils/general';
import intl from 'react-intl-universal';

const MODAL_CONTENT = {
  NEW_CTRIP: 'newCtrip',
  UPDATE_CTRIP: 'updateCtrip'
};

const MESSAGE_TYPE = {
  SUCCESS_UPDATE: 'successUpdate',
  ERROR_UPDATE: 'errorUpdate',
  SUCCESS_PULL: 'successPull',
  ERROR_PULL: 'errorPull'
};

const generateTableSetting = ctripListingsData =>
  ctripListingsData.map(listing => ({
    _id: listing._id,
    ctripListingId: listing.ctripListingId,
    status: listing.status,
    ctripPropertyName: listing.ctripPropertyName || ' Property ID Only (Temporary)',
    host: listing.host,
    rooms: listing.rooms.length
  }));

const mapHostListsOptions = hostListsData =>
  hostListsData.map(host => ({
    label: host.name,
    value: host._id
  }));

const generateNotificationMessage = (messageType, listingId = null, error = null) => {
  switch (messageType) {
    case MESSAGE_TYPE.SUCCESS_PULL:
      return {
        message: `${intl.get('hostConnect.ctrip.message.sucessMsg').d('Succesfully retrieve Hotel')} (${listingId})`,
        description: <div>{intl.get('hostConnect.ctrip.message.sucessDesc').d('Please assign a host to this Ctrip Listing')}</div>
      };
    case MESSAGE_TYPE.ERROR_PULL:
      return {
        message: `${intl.get('hostConnect.ctrip.message.failMsg').d('Failed to retrieve Ctrip Listing')}`,
        description: (
          <div>
            {intl.get('hostConnect.ctrip.message.failDesc1').d('The provided listing ID')} ({listingId}){' '}
            {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
            <div>
              {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fctrip-connect"
              >
                {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
              </a>
              !
            </div>
          </div>
        )
      };
    case MESSAGE_TYPE.SUCCESS_UPDATE:
      return {
        message: `${intl.get('hostConnect.ctrip.message.updateMsg').d('Nice One')}`,
        description: (
          <div>
            <p>
              <b>{intl.get('hostConnect.ctrip.message.updateDesc').d('Succesfully added Host to Ctrip Listing')}</b>
            </p>
          </div>
        )
      };
    case MESSAGE_TYPE.ERROR_UPDATE:
      return {
        message: `${intl.get('hostConnect.ctrip.message.updateFailMsg').d('Oh Crap')}`,
        description: (
          <div>
            <p>
              <b>{intl.get('hostConnect.ctrip.message.updateFailDesc1').d('Failed to update Ctrip Listing')}</b>
            </p>
            {intl.get('hostConnect.ctrip.message.updateFailDesc2').d('Try refreshing the page. If problem presist, contact ')}
            <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
              {intl.get('hostConnect.ctrip.message.updateFailDesc3').d('tech support!')}
            </a>
            !
          </div>
        )
      };
    default:
  }
};

const generateModalTitle = selectedCtripListing => {
  return `${intl.get('hostConnect.ctrip.headerLabels.editing').d('Editing')} ${selectedCtripListing.ctripPropertyName} (${
    selectedCtripListing.ctripListingId
  })`;
};

const getTableColumns = (filtersInfo, sorterInfo, handleOnShowEditModal, ctripListings, isAllowEdit, checkIsAdminReadOnly) => {
  const tableColumns = [];

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.id').d('Ctrip Property ID'),
    dataIndex: 'ctripListingId',
    key: 'ctripListingId',
    ...getColumnFilterSearchProps('ctripListingId', intl.get('tables.ctrip').d('Ctrip Property ID'), { filtersInfo }),
    ...getColumnSorterProps('ctripListingId', { sorterInfo })
  });

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.status').d('Status'),
    dataIndex: 'status',
    key: 'status',
    filters: Array.from(new Set(ctripListings.map(listing => listing.status))).map(item => ({
      text: intl.get(`hostConnect.ctrip.status.${item}`).d(item),
      value: item
    })),
    onFilter: (value, record) => record.status === value,
    render: (text, record) => {
      return intl.get(`hostConnect.ctrip.status.${record.status}`).d(record.status);
    }
  });

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.property').d('Ctrip Property Name'),
    dataIndex: 'ctripPropertyName',
    key: 'ctripPropertyName',
    ...getColumnFilterSearchProps('ctripPropertyName', intl.get('tables.ctripProperty').d('Ctrip Property Name'), { filtersInfo }),
    ...getColumnSorterProps('ctripPropertyName', { sorterInfo })
  });

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.host').d('Host'),
    dataIndex: 'host',
    key: 'host',
    ...getColumnFilterSearchProps('host', intl.get('tables.hostName').d(''), { filtersInfo }),
    ...getColumnSorterProps('host', { sorterInfo }),
    render: (text, record) => guard(() => text.name, '')
  });

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.room').d('Ctrip Rooms'),
    dataIndex: 'rooms',
    key: 'rooms',
    ...getColumnSorterProps('rooms', { sorterInfo })
  });

  tableColumns.push({
    title: intl.get('hostConnect.ctrip.tableColumns.action').d('Actions'),
    key: 'action',
    render: (text, record) => {
      return (
        <Button onClick={handleOnShowEditModal(record)} disabled={!isAllowEdit || checkIsAdminReadOnly()}>
          {intl.get('hostConnect.ctrip.headerLabels.edit').d('Edit')}
        </Button>
      );
    }
  });

  return tableColumns;
};

const CtripListing = ({ form, checkIsAllowCreateIntegration, checkIsAllowEditIntegration, checkIsAdminReadOnly }) => {
  const [filtersInfo, setFilters] = useState({});
  const [sorterInfo, setSorter] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [refetchTableListing, setRefetchTableListing] = useState(true);

  const [ctripListings, setCtripListings] = useState([]);
  const [selectedCtripListing, setSelectedCtripListing] = useState({});
  const [hostLists, setHostsLists] = useState([]);

  const isAllowCreate = !!checkIsAllowCreateIntegration();
  const isAllowEdit = !!checkIsAllowEditIntegration();

  useEffect(() => {
    if (refetchTableListing) {
      setIsTableLoading(true);

      getCtripListings()
        .then(ctripListings => {
          const listingTableData = generateTableSetting(ctripListings);

          setCtripListings(listingTableData);
          refetchCtripListings(false);
        })
        .catch(ex => Message.error(ex.toString()));

      setIsTableLoading(false);
    }
  }, [refetchTableListing]);

  useEffect(() => {
    getHosts().then(response => {
      const mappedHostListsOptions = mapHostListsOptions(response.data);
      setHostsLists(mappedHostListsOptions);
    });
  }, []);

  const handleOnTableChange = (filtersInfo, sorterInfo) => {
    setFilters(filtersInfo);
    setSorter(sorterInfo);
  };

  const handleOnOpenCloseModal = (boolean, modalContentType) => {
    setIsShowModal(boolean);

    if (modalContentType) {
      setModalContent(modalContentType);
    }
  };

  const refetchCtripListings = isRefetch => {
    setRefetchTableListing(isRefetch);
  };

  const switchModalNewToEdit = ctripListing => {
    setSelectedCtripListing(ctripListing);
    setModalContent(MODAL_CONTENT.UPDATE_CTRIP);
  };

  const onClickEditModal = ctripListing => e => {
    setSelectedCtripListing(ctripListing);
    handleOnOpenCloseModal(true, MODAL_CONTENT.UPDATE_CTRIP);
  };

  const handleOnFormSave = e => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const ctripListingId = selectedCtripListing.ctripListingId;
        const ctripId = selectedCtripListing._id;

        const updateListingPayload = {
          ...values,
          ctripListingId
        };

        return putCtripListing(ctripId, updateListingPayload)
          .then(() => {
            const successUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_UPDATE);
            notification.success(successUpdateMessage);

            refetchCtripListings(true);

            setIsModalLoading(false);
            handleOnOpenCloseModal(false);
          })
          .catch(ex => {
            const errorUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_UPDATE);
            notification.error(errorUpdateMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const handleOnClickSaveNew = async e => {
    setIsModalLoading(true);

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        return pullNewCtripListing(values.ctripListingId)
          .then(ctripListing => {
            const successPullMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_PULL, values.ctripListingId);
            notification.success(successPullMessage);

            refetchCtripListings(true);
            switchModalNewToEdit(ctripListing);

            setIsModalLoading(false);
          })
          .catch(ex => {
            console.log('ex :>> ', ex);

            const errorPullMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_PULL, values.ctripListingId, ex);
            notification.error(errorPullMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  return (
    <Card className="list-card">
      <Row>
        <Col className="list-card-control-section">
          <Button
            type="primary"
            icon="plus"
            onClick={() => handleOnOpenCloseModal(true, MODAL_CONTENT.NEW_CTRIP)}
            loading={isModalLoading}
            disabled={!isAllowCreate || checkIsAdminReadOnly()}
          >
            {intl.get('hostConnect.ctrip.headerLabels.new').d('New Ctrip')}
          </Button>
        </Col>
        <Col>
          <Table
            rowKey={record => record._id}
            dataSource={ctripListings}
            columns={getTableColumns(filtersInfo, sorterInfo, onClickEditModal, ctripListings, isAllowEdit, checkIsAdminReadOnly)}
            onChange={(pagination, filtersInfo, sorterInfo) => {
              handleOnTableChange(filtersInfo, sorterInfo);
            }}
            loading={isTableLoading}
          />
        </Col>
      </Row>
      {modalContent === MODAL_CONTENT.NEW_CTRIP ? (
        <Modal
          title={intl.get('hostConnect.ctrip.headerLabels.newProperty').d('New Ctrip Property')}
          visible={isShowModal}
          onOk={handleOnClickSaveNew}
          onCancel={() => handleOnOpenCloseModal(false, '')}
          okText={intl.get('rateModifier.headerLabels.save').d('Save')}
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInputNumber
            name={'ctripListingId'}
            formLabel={intl.get('hostConnect.ctrip.headerLabels.ctripPropertyId').d('Ctrip property Id:')}
            placeholder={intl.get('hostConnect.ctrip.placeholder.ctripPropertyId').d('Ctrip property Id (example: 5694326)')}
            form={form}
            requiredErrorMessage={intl.get('hostConnect.ctrip.placeholder.ctripPropertyIdMsg').d('Please input listing Id')}
            disabled={isModalLoading}
          />
        </Modal>
      ) : (
        <Modal
          title={generateModalTitle(selectedCtripListing)}
          visible={isShowModal}
          onOk={handleOnFormSave}
          onCancel={() => handleOnOpenCloseModal(false, '')}
          okText={intl.get('rateModifier.headerLabels.save').d('Save')}
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name={'ctripPropertyName'}
            formLabel={intl.get('hostConnect.ctrip.headerLabels.assignNickname').d('Assign a property nickname:')}
            placeHolder={intl.get('hostConnect.ctrip.placeholder.assignNickname').d('Please input property nickname')}
            form={form}
            defaultValue={selectedCtripListing.ctripPropertyName}
            requiredErrorMessage={intl.get('hostConnect.ctrip.placeholder.assignNicknameMsg').d('Please input a property nickname')}
            disabled={isModalLoading}
          />
          <FormSelection
            name={'host'}
            formLabel={intl.get('hostConnect.ctrip.headerLabels.assignHost').d('Assign a host:')}
            placeHolder={intl.get('hostConnect.ctrip.placeholder.assignHost').d('Please select and assign a host')}
            form={form}
            defaultValue={guard(() => selectedCtripListing.host._id, '')}
            selections={hostLists}
            disabled={!isModalLoading}
            allowClear={false}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Form.create()(withAppContext(CtripListing));
