import React, { Component } from 'react';
import { Form, Icon, Button, Popconfirm, Row, Col } from 'antd';
import './TransportFieldSet.css';
import { constructTwoDecimalsRule } from 'utils/general';
import FormInput from 'components/FormInput/FormInput';

import FormSelection from 'components/FormSelection/FormSelection';
import intl from 'react-intl-universal';

class CleaningProvided extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      selectedRoomTypes: [],
      availableRoomTypes: [],
      counter: 1,
      concatRoomTypes: []
    };
  }

  remove = k => {
    const { form, data } = this.props;
    const obj = form.getFieldValue('cleaningFees');
    // can use data-binding to get
    // const newKeys = Object.keys(form.getFieldValue('cleaningFees')).filter(key => key !== k);
    // can use data-binding to set
    const newAvailableRoomType = obj[k].roomTypes.map(newRoomTypeId => {
      const selectedRoomType = data.find(roomType => {
        return roomType.key === newRoomTypeId;
      });
      return selectedRoomType;
    });
    this.setState(prevState => ({
      availableRoomTypes: [...prevState.availableRoomTypes, ...newAvailableRoomType],
      concatRoomTypes: [
        ...prevState.concatRoomTypes,
        ...newAvailableRoomType.map(item => ({
          key: item.key,
          displayValue: item.name,
          filterValue: item.name
        }))
      ]
    }));
    delete obj[k];
    form.setFieldsValue({
      cleaningFees: obj
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = Object.keys(form.getFieldValue('cleaningFees'));
    const nextKeys = Object.keys(keys).length.toString();
    const currObj = form.getFieldValue('cleaningFees');
    let obj = {};

    //use state counter to ensure no overlap, coz now overlap makin the new row does not pop up !
    obj[this.state.counter] = { amount: 0.0, roomTypes: [], rooms: [] };
    const finalObj = { ...currObj, ...obj };
    this.setState({ counter: this.state.counter + 1 });
    form.setFieldsValue({
      cleaningFees: finalObj
    });

    // const newValues = form.getFieldsValue();
    // newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
  };

  componentDidMount() {
    const { data, form } = this.props;
    // const { selectedRoomTypes } = this.state;
    let cF = data.map(d => d.cleaningFee);
    let obj = {};
    const keys = [...new Set(cF)]; //remove duplicate cleaning fee of roomtypes for obj key
    keys.forEach((key, index) => {
      let arr = [];
      let roomArr = [];
      data.forEach(d => {
        if (d.cleaningFee === key) {
          arr.push(d.key);
          roomArr.push(d);
          // selectedRoomTypes.push(d);
        }
      });
      obj[index] = { amount: key, roomTypes: arr, rooms: roomArr };
    });
    if (cF.length > 0) {
      form.setFieldsValue(
        {
          cleaningFees: obj
        },
        this.setState({
          update: true,
          allRoomTypes: data
          // selectedRoomTypes: data,
        })
      );
    }
    this.setState({ counter: Object.keys(keys).length.toString() });
  }

  // return arg newRoomTypeIds
  //global
  // need to changee form state ?
  handleOnRoomTypeChange = (rooms, index) => newRoomTypeIds => {
    const { data, form } = this.props;
    const { availableRoomTypes, concatRoomTypes } = this.state;

    const obj = form.getFieldValue('cleaningFees');

    //Remove
    if (obj[index].roomTypes.length > newRoomTypeIds.length) {
      const removedRoomTypes = obj[index].roomTypes.filter(roomTypeId => !newRoomTypeIds.includes(roomTypeId));
      //console.log('Removed Room Types:', removedRoomTypes);

      // Remove roomTypes and rooms based on removedRoomTypes
      obj[index].roomTypes = obj[index].roomTypes.filter(roomTypeId => !removedRoomTypes.includes(roomTypeId));
      obj[index].rooms = obj[index].rooms.filter(room => !removedRoomTypes.includes(room.key));

      const newAvailableRoomTypes = removedRoomTypes.map(roomTypeId => {
        const roomType = data.find(room => room.key === roomTypeId);
        return {
          key: roomType.key,
          displayValue: roomType.name,
          filterValue: roomType.name
        };
      });

      this.setState(prevState => ({
        availableRoomTypes: [...prevState.availableRoomTypes, ...newAvailableRoomTypes],
        concatRoomTypes: [...prevState.concatRoomTypes, ...newAvailableRoomTypes]
      }));
    } else {
      // Add In
      const addedRoomTypes = newRoomTypeIds.filter(roomTypeId => !obj[index].roomTypes.includes(roomTypeId));
      //console.log('Added Room Types:', addedRoomTypes);

      // Add roomTypes and rooms based on addedRoomTypes
      const newRoomTypes = addedRoomTypes.map(roomTypeId => {
        const roomType = data.find(room => room.key === roomTypeId);
        return roomType.key;
      });

      const newRooms = addedRoomTypes.map(roomTypeId => {
        return data.find(room => room.key === roomTypeId);
      });

      obj[index].roomTypes = [...obj[index].roomTypes, ...newRoomTypes];
      obj[index].rooms = [...obj[index].rooms, ...newRooms];

      const newAvailableRoomTypes = availableRoomTypes.filter(roomType => !addedRoomTypes.includes(roomType.key));

      this.setState({
        availableRoomTypes: newAvailableRoomTypes,
        concatRoomTypes: newAvailableRoomTypes.map(item => ({
          key: item.key,
          displayValue: item.displayValue || item.name,
          filterValue: item.filterValue || item.name
        }))
      });
    }

    form.setFieldsValue({
      cleaningFees: obj
    });
  };

  // componentDidUpdate(prevProp, prevState) {
  //     const { data, form } = this.props;
  //     const { update } = this.state;
  //     if (update) {
  //         data.forEach(d => {
  //             let obj = {};
  //             let key = `amount${d.key}`;
  //             let key2 = `roomTypes${d.key}`;
  //             obj[key] = d.amount;
  //             obj[key2] = d.roomTypes;
  //             form.setFieldsValue({
  //                 ...obj
  //             });
  //         });
  //         this.setState({
  //             update: false
  //         });
  //     }
  // }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { form, currency } = this.props;
    const { availableRoomTypes, concatRoomTypes } = this.state;

    getFieldDecorator('cleaningFees', { initialValue: [] });
    const keys = getFieldValue('cleaningFees');
    //console.log(keys, 1);
    const formItems = Object.keys(keys).map((k, index) => {
      //their own value + concat (global) !
      const selection = keys[k].rooms
        .map(item => {
          return {
            key: item.key,
            displayValue: item.name,
            filterValue: item.name
          };
        })
        .concat(concatRoomTypes);

      //here need to make some changes

      const filteredSelection = selection.filter((item, index) => {
        const _value = JSON.stringify(item);
        return (
          index ===
          selection.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      return (
        <React.Fragment key={index}>
          <Row justify="start" gutter={8}>
            <Col span={12}>
              <FormInput
                form={form}
                addonBefore={currency || 'RM'}
                extraRules={[constructTwoDecimalsRule()]}
                name={`amounts[${k}]`}
                defaultValue={keys[k].amount}
                requiredErrorMessage={intl.get('listings.bookingEngine.placeholder.amount').d('Please input amount')}
              />
            </Col>
            <Col span={11}>
              <FormSelection
                form={form}
                name={`roomType[${k}]`}
                isHideLabel={true}
                multipleMode={true}
                placeholder={intl.get('listings.bookingEngine.headerLabels.roomType').d('Room Type')}
                selections={filteredSelection.length === 0 ? selection : filteredSelection}
                defaultValue={keys[k].roomTypes}
                optionFilterProp="filtervalue"
                onChange={this.handleOnRoomTypeChange(keys[k].rooms, k)}
              />
            </Col>
            <Col span={1}>
              <Popconfirm
                title={intl.get('listings.bookingEngine.placeholder.remove').d('Are you sure you want to remove this activity?')}
                onConfirm={() => this.remove(k)}
                okText={intl.get('listings.bookingEngine.placeholder.yes').d('Yes')}
                cancelText={intl.get('listings.bookingEngine.placeholder.no').d('No')}
              >
                <Button className="booking-form-danger" shape="circle" type="danger" style={{ marginTop: '10px' }}>
                  <Icon type="minus" />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </React.Fragment>
      );
    });
    return (
      <Form>
        <Row justify="start" gutter={8}>
          <Col span={12}>
            {intl.get('listings.bookingEngine.headerLabels.cleaningFee').d('Cleaning Fee')} ({currency})
          </Col>
          <Col span={11}>{intl.get('listings.bookingEngine.headerLabels.roomTypeApplied').d('Room Type(s) applied')}</Col>
        </Row>
        {formItems}
        <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
          <Icon type="plus" /> {intl.get('listings.bookingEngine.headerLabels.addCleaningFee').d('Add cleaning fee')}
        </Button>
      </Form>
    );
  }
}

const WrappedCleaningProvided = CleaningProvided;
export default WrappedCleaningProvided;
