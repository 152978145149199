import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { buildListingUri } from 'utils/routes';
import intl from 'react-intl-universal';

import styles from './Finish.module.css';

const Finish = () => (
  <div className={styles.container}>
    <Icon type="check-circle" style={{ fontSize: '56px', color: '#1F883C' }} />
    <h2 className={styles.title}>{intl.get('listings.unit.headerLabels.wellDone').d('Well done!')}</h2>
    <h3 className={styles.subtitle}>{intl.get('listings.unit.headerLabels.unitSuccess').d("You've successfully created your unit.")}</h3>
    <Link to={buildListingUri()} className={styles.link}>
      {intl.get('listings.unit.headerLabels.goListing').d('Go to unit listings page')}
    </Link>
  </div>
);

export default Finish;
