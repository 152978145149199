import React from 'react';
import { Table, Button, Card, Modal, Icon, message, Alert, Divider, Input, Select, Row, Col } from 'antd';
import { withAppContext } from 'context/AppContext';
import { getHosts } from 'utils/apis/host';
import moment from 'moment';
import queryString from 'query-string';
import styles from './BillingInvoice.module.css';
import intl from 'react-intl-universal';

import { getInvoices, createInvoice, updateInvoice, deleteInvoice, updatePaymentStatus, getFpxPaymentStatus } from 'utils/apis/billingInvoice';
import { generateDisplayFee } from 'utils/general';

import InvoiceModal from './components/InvoiceModal/InvoiceModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';

const Option = Select.Option;

const DeleteModal = ({ record }) => (
  <div>
    <Alert
      message={intl.get('invoice.message.deleteWarning').d('Warning')}
      description={intl.get('invoice.message.deleteDesc').d('You will not be able to undo this action.')}
      type="warning"
      showIcon
    />
    <p />
    <Card title={`${intl.get('invoice.headerLabels.invoiceNum').d('Invoice Number: ')} ${record.invoiceNo} `}>
      <p>
        <b>{intl.get('invoice.headerLabels.invoiceAmount').d('Invoice Amount: ')}</b>
        {generateDisplayFee(record.amount)}
      </p>
      <p>
        <b>{intl.get('invoice.headerLabels.invoiceDate2').d('Invoice Date: ')}</b>
        {moment(record.invoiceDate).format('YYYY-MM-DD')}
      </p>
      <p>
        <b>{intl.get('invoice.headerLabels.invoiceDueDate2').d('Invoice Due Date: ')}</b>
        {moment(record.invoiceDueDate).format('YYYY-MM-DD')}
      </p>
      <p>
        <b>{intl.get('invoice.headerLabels.paymentStatus2').d('Payment Status: ')}</b>
        {intl.get(`invoice.paymentStatus.${record.paymentStatus.charAt(0).toUpperCase() + record.paymentStatus.slice(1)}`).toString()}
      </p>
      <p>
        <b>{intl.get('invoice.headerLabels.invoicePDF').d('Invoice PDF File: ')}</b>
        {!!record.files
          ? record.files.map((file, index) => (
              <div>
                <a href={file.link}>{file.name}</a>
              </div>
            ))
          : intl.get('invoice.message.notIncluded').d('Not Included')}
      </p>
    </Card>
  </div>
);

class BillingInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      searchInvoiceText: '',
      searchHostText: '',
      originalData: [],
      hostEmail: '',
      selectedInvoice: {},
      filteredHostOptions: [],
      isModalClicked: false,
      isEditClicked: false,
      isDeleteClicked: false,
      isConfirmedPayment: false,
      confirmationData: []
    };
  }

  getNumbersOfInvoices = searchQuery => {
    getInvoices()
      .then(invoices => {
        let data = invoices;
        const hostQuery = queryString.parse(searchQuery);
        if (hostQuery && (hostQuery.host && hostQuery.host !== 'all')) {
          data = invoices.filter(invoice => String(invoice.host) === String(hostQuery.host));
        }

        this.setState({
          originalData: invoices,
          invoices: data.map(data => ({
            ...data,
            key: data._id
          }))
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  componentDidMount = async () => {
    const { history } = this.props;
    const invoiceQuery = queryString.parse(history.location.search);
    const searchQuery = history.location.search;

    if (searchQuery.includes('?success')) {
      Modal.success({
        centered: true,
        title: intl.get('invoice.message.paymentSucessTitle').d('Payment succesfully made!'),
        content: intl.get('invoice.message.paymentSucessContent').d('You have successfuly made a payment.'),
        onOk() {},
        onCancel() {}
      });
      if (invoiceQuery) {
        await updatePaymentStatus(invoiceQuery.token);
        this.getNumbersOfInvoices(searchQuery);
      }
    } else if (searchQuery.includes('?fpx_success=true')) {
      const paymentQuery = queryString.parse(searchQuery);
      getFpxPaymentStatus(paymentQuery.payment_intent)
        .then(async paymentStatus => {
          const charges = paymentStatus.charges.data[0];
          this.setState({
            confirmationData: charges || '',
            isConfirmedPayment: true
          });

          if (paymentQuery.token && charges && charges.status === 'succeeded') {
            await updatePaymentStatus(invoiceQuery.token);
            this.getNumbersOfInvoices(searchQuery);
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    } else if (searchQuery === '?cancelled') {
      Modal.error({
        centered: true,
        title: intl.get('invoice.message.cancelledTitle').d('There was an error processing your payment.'),
        content: intl.get('invoice.message.cancelledContent').d('Please try making your payment again.'),
        onOk() {},
        onCancel() {}
      });
    }

    this.getNumbersOfInvoices(searchQuery);

    getHosts()
      .then(res => {
        const { originalData } = this.state;

        const filteredHostOptions = originalData.map(ori => {
          return res.data.find(data => {
            return data._id === ori.host._id;
          });
        });

        this.setState({
          filteredHostOptions: [...new Set(filteredHostOptions)].map(data => {
            return { value: data.name, key: data._id };
          })
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  columns = ({ isAdmin, isAdminReadOnly }) => {
    return [
      {
        title: intl.get('invoice.tableColumns.invoiceNo').d('Invoice Number'),
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
        sorter: (a, b) => a.invoiceNo && b.invoiceNo && a.invoiceNo.localeCompare(b.invoiceNo),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.invoiceNum').d('Search invoice number')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={confirm}
              name="searchInvoiceText"
            />
            <Button type="primary" onClick={confirm}>
              {intl.get('invoice.headerLabels.search').d('Search')}
            </Button>
            <Button onClick={clearFilters}>{intl.get('invoice.headerLabels.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.invoiceNo.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (text, record) => {
          if (record.paymentStatus === 'unpaid' && moment(new Date(), 'YYYY-MM-DD') > moment(record.invoiceDueDate)) {
            return (
              <span>
                <Icon className={`${styles.warningOverdueInvoice}`} type="warning" />
                {record.invoiceNo}
              </span>
            );
          } else {
            return record.invoiceNo;
          }
        }
      },
      {
        title: intl.get('invoice.tableColumns.host').d('Host Name'),
        dataIndex: 'host.name',
        key: 'host.name',
        sorter: (a, b) => a.host.name && b.host.name && a.host.name.localeCompare(b.host.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.hostName').d('Search Host')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={confirm}
              name="searchHostText"
            />
            <Button type="primary" onClick={confirm}>
              {intl.get('invoice.headerLabels.search').d('Search')}
            </Button>
            <Button onClick={clearFilters}>{intl.get('invoice.headerLabels.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.host.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('invoice.tableColumns.invoiceDate').d('Invoice Date'),
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.invoiceDate && b.invoiceDate && a.invoiceDate.localeCompare(b.invoiceDate)
      },
      {
        title: intl.get('invoice.tableColumns.invoiceDueDate').d('Invoice Due Date'),
        dataIndex: 'invoiceDueDate',
        key: 'invoiceDueDate',
        sorter: (a, b) => a.invoiceDueDate && b.invoiceDueDate && a.invoiceDueDate.localeCompare(b.invoiceDueDate)
      },
      {
        title: intl.get('invoice.tableColumns.invoiceAmount').d('Invoice Amount'),
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount && b.amount && a.amount.localeCompare(b.amount),
        render: (text, record) => generateDisplayFee(record.amount)
      },
      {
        title: intl.get('invoice.tableColumns.paymentStatus').d('Payment Status'),
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        filters: Array.from(
          new Set(
            this.state.invoices.map(item => {
              return item.paymentStatus.charAt(0).toUpperCase() + item.paymentStatus.slice(1);
            })
          )
        ).map(item => {
          return {
            text: intl.get(`invoice.paymentStatus.${item}`).d('Payment Status'),
            value: item
          };
        }),
        onFilter: (value, record) => record.paymentStatus === value.toLowerCase(),
        render: (text, record) => {
          return intl.get(`invoice.paymentStatus.${record.paymentStatus.charAt(0).toUpperCase() + record.paymentStatus.slice(1)}`);
        }
      },
      {
        title: intl.get('invoice.tableColumns.action').d('Action'),
        key: 'action',
        render: (text, record) => {
          return (
            <div>
              {isAdmin ? (
                <>
                  <span>
                    <Icon
                      type="edit"
                      className={isAdminReadOnly ? styles.actionIconDisabled : styles.actionIcon}
                      onClick={() => {
                        if (!isAdminReadOnly) {
                          this.handleOnClickedEditBtn(record);
                        }
                      }}
                    />
                  </span>
                  <Divider type="vertical" />
                  <span>
                    <Icon
                      type="delete"
                      className={isAdminReadOnly ? styles.actionIconDisabled : styles.actionIcon}
                      onClick={() => {
                        if (!isAdminReadOnly) {
                          this.handleOnClickedDeleteBtn(record);
                        }
                      }}
                    />
                  </span>
                </>
              ) : (
                <span>
                  <Icon type="eye" className={styles.actionIcon} onClick={() => this.handleOnClickedViewBtn(record)} />
                </span>
              )}
            </div>
          );
        }
      }
    ];
  };

  handleOnCreateSubmit = (form, files, record, emailFlag = false, hostEmail) => {
    form.validateFieldsAndScroll((err, values) => {
      console.log(values);
      if (!err) {
        Modal.confirm({
          title: intl.get('invoice.message.createTitle').d('Are you sure you want to create this invoice?'),
          content: `${intl.get('invoice.message.createContent').d('This will send a confirmation email to:')} ${hostEmail}`,
          onOk() {
            const formattedInvoice = {
              host: values.host,
              invoiceNo: values.invoiceNo,
              invoiceDate: values.invoiceDate.format('YYYY-MM-DD'),
              invoiceDueDate: values.invoiceDueDate.format('YYYY-MM-DD'),
              amount: values.amount,
              paymentStatus: values.paymentStatus,
              files
            };
            createInvoice(formattedInvoice)
              .then(data => {
                message.success(intl.get('invoice.message.invoiceSuccess').d('Invoice is successfully created!'));
                !!data && window.location.reload();
              })
              .catch(ex => {
                message.error(ex.toString());
                console.error(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnEditSubmit = (form, files, record, emailFlag = false, hostEmail) => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formattedInvoice = {
          host: values.host,
          invoiceNo: values.invoiceNo,
          invoiceDate: values.invoiceDate.format('YYYY-MM-DD'),
          invoiceDueDate: values.invoiceDueDate.format('YYYY-MM-DD'),
          amount: values.amount,
          paymentStatus: values.paymentStatus,
          files: files || record.files,
          sendEmail: emailFlag
        };
        if (emailFlag) {
          Modal.confirm({
            title: `${intl.get('invoice.message.updateTitle').d('Are you sure you update this invoice and send email?')}`,
            content: `${intl.get('invoice.message.updateContent').d('This will send an email to:')} ${hostEmail}`,
            onOk() {
              updateInvoice(record._id, formattedInvoice)
                .then(data => {
                  message.success(intl.get('invoice.message.updateSuccess').d('Update success!'));
                  !!data && window.location.reload();
                })
                .catch(ex => {
                  message.error(ex.toString());
                  console.error(ex);
                });
            },
            onCancel() {}
          });
        } else {
          Modal.confirm({
            title: intl.get('invoice.message.updateTitle2').d('Are you sure you want to update this invoice?'),
            onOk() {
              updateInvoice(record._id, formattedInvoice)
                .then(data => {
                  message.success(intl.get('invoice.message.updateSuccess').d('Update success!'));
                  !!data && window.location.reload();
                })
                .catch(ex => {
                  message.error(ex.toString());
                  console.error(ex);
                });
            },
            onCancel() {}
          });
        }
      }
    });
  };

  handleOnDeleteSubmit = () => {
    const { selectedInvoice } = this.state;
    Modal.confirm({
      title: intl.get('invoice.message.deleteTitle').d('Are you sure you want to delete this invoice?'),
      onOk() {
        deleteInvoice({ id: selectedInvoice._id })
          .then(() => {
            message.success(intl.get('invoice.message.deleteSuccess').d('Invoice deleted!'));
            window.location.reload();
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {}
    });
  };

  handleOnClickedViewBtn = record => {
    this.setState({
      selectedInvoice: record,
      isModalClicked: true
    });
  };

  handleOnClickedEditBtn = record => {
    this.setState({
      selectedInvoice: record,
      isModalClicked: true,
      isEditClicked: true
    });
  };

  handleOnClickedDeleteBtn = record => {
    this.setState({
      isDeleteClicked: true,
      selectedInvoice: record
    });
  };

  handleOnDeleteCancel = () => {
    this.setState({
      isDeleteClicked: false
    });
  };

  handleOnModalClicked = () => {
    this.setState({
      isModalClicked: true,
      selectedInvoice: null
    });
  };

  handleOnCancelModal = () => {
    this.setState({
      isModalClicked: false,
      isEditClicked: false
    });
  };

  handleOnCancelConfirmation = () => {
    this.setState({
      isConfirmedPayment: false
    });
  };

  handleOnHostChange = selectedHost => {
    const { originalData } = this.state;

    if (selectedHost === 'all') {
      this.setState({
        invoices: originalData
      });
    } else {
      this.setState({
        invoices: originalData.filter(data => String(data.host._id) === String(selectedHost))
      });
    }
    const { history } = this.props;
    history.push(`${history.location.pathname}?host=${selectedHost}`);
  };

  render() {
    const { checkIsAdmin, history, checkIsAdminReadOnly, isBanned } = this.props;
    const {
      isModalClicked,
      isDeleteClicked,
      filteredHostOptions,
      invoices,
      selectedInvoice,
      isConfirmedPayment,
      confirmationData,
      isEditClicked
    } = this.state;
    const hostQuery = queryString.parse(history.location.search);
    const hostDefaultValue = hostQuery.host || 'all';

    return (
      <div>
        {isBanned && (
          <Alert
            message={intl.get('invoice.message.warning').d('Warning')}
            description={intl.get('invoice.message.clearPayment').d('Please clear your payment in order to continue to use system.')}
            type="warning"
            showIcon
          />
        )}
        <Card className={styles.billingCard}>
          {isConfirmedPayment && (
            <ConfirmationModal visible={isConfirmedPayment} confirmationData={confirmationData} onCancel={this.handleOnCancelConfirmation} />
          )}
          {isModalClicked && (
            <InvoiceModal
              visible={isModalClicked}
              handleOnInvoiceSubmit={selectedInvoice ? this.handleOnEditSubmit : this.handleOnCreateSubmit}
              record={selectedInvoice}
              isEditClicked={isEditClicked}
              formDisabled={!checkIsAdmin()}
              onCancel={this.handleOnCancelModal}
            />
          )}
          {isDeleteClicked && (
            <Modal
              visible={isDeleteClicked}
              maskClosable={false}
              title={`Invoice Number: ${selectedInvoice.invoiceNo}`}
              onCancel={this.handleOnDeleteCancel}
              footer={[
                <Button key="back" onClick={this.handleOnDeleteCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleOnDeleteSubmit}>
                  Delete
                </Button>
              ]}
            >
              <DeleteModal record={selectedInvoice} />
            </Modal>
          )}
          {checkIsAdmin() && (
            <div className="list-card-control-section">
              <Row type="flex">
                <Col
                  style={{
                    marginRight: '8px',
                    marginBottom: '8px'
                  }}
                >
                  <Button type="primary" icon="plus" onClick={this.handleOnModalClicked} disabled={checkIsAdminReadOnly()}>
                    Create Invoice
                  </Button>
                </Col>
                <Col>
                  <Alert
                    type="info"
                    description={
                      <span>
                        <strong>Visible to Admin Only : </strong> To enable automatic unlocking from Stripe payment, the host needs to settle three or
                        fewer outstanding invoices.
                      </span>
                    }
                    style={{
                      padding: 0,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      paddingBottom: 2
                    }}
                  />
                </Col>
              </Row>

              <Row type="flex" justify="end" align="right" gutter={16}>
                <span className={styles.hostSelectTitle}>Host : </span>
                <Select
                  defaultValue={hostDefaultValue}
                  className={styles.hostSelects}
                  showSearch
                  placeholder="Select a host"
                  optionFilterProp="children"
                  onChange={this.handleOnHostChange}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option key="all" value="all">
                    All
                  </Option>
                  {filteredHostOptions.map(host => (
                    <Option key={host.key} value={host.key}>
                      {host.value}
                    </Option>
                  ))}
                </Select>
              </Row>
            </div>
          )}
          <Table
            loading={!invoices}
            columns={this.columns({ isAdmin: checkIsAdmin(), isAdminReadOnly: checkIsAdminReadOnly() })}
            dataSource={invoices}
          />
        </Card>
      </div>
    );
  }
}

export default withAppContext(BillingInvoice);
