import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import intl from 'react-intl-universal';

const CSVDownload = ({ id, filename, data, className, onClick, isLoading, style, checkIsAdminReadOnly }) => {
  return (
    <CSVLink filename={filename} data={data} className={className} style={style}>
      <Button id={id} type="primary" icon="download" onClick={onClick} loading={isLoading} disabled={isLoading || checkIsAdminReadOnly()}>
        {isLoading ? intl.get('listings.headerLabels.loadCSV').d('Loading CSV') : intl.get('listings.headerLabels.downloadCSV').d('Download CSV')}
      </Button>
    </CSVLink>
  );
};

CSVDownload.propTypes = {
  filename: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
};

CSVDownload.defaultProps = {
  className: '',
  onClick: undefined,
  isLoading: false
};

export default CSVDownload;
