import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Form, Icon, Input, message, Modal, Row, Select } from 'antd';

import { withAppContext } from 'context/AppContext';
import { buildTaskManagementUri } from 'utils/routes';

import CloseButton from 'components/CloseButton/CloseButton';

import { getUsers } from 'utils/apis/user';
import { getHostsAutomated } from 'utils/apis/host';
import { getPropertiesByHostId } from 'utils/apis/property';
import { createCleaningTeam, getCleaningTeamById, deleteTeamById, updateCleaningTeam } from 'utils/apis/taskManagement';
import intl from 'react-intl-universal';

import './CleaningTeamForm.css';

const FormItem = Form.Item;
const { Option } = Select;

const MODE_NEW = 'new';
const MODE_EDIT = 'edit';

class CleaningTeamForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      mode: MODE_NEW,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      hostOptions: [],
      host: '',
      propertyOptions: [],
      property: [],
      userOptions: [],
      users: []
    };

    this.getHosts = this.getHosts.bind(this);
    this.getCleaners = this.getCleaners.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    Promise.all([this.getHosts(), this.getCleaners()]).then(() => this.setState({ isLoading: false }));

    if (this.props.match.params.id) {
      this.getTeamById(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.host !== this.state.host) {
      let propertyOptions = [];

      getPropertiesByHostId(this.state.host).then(res => {
        propertyOptions = res.map(property => ({ label: property.name, value: property._id }));
        if (this.props.match.params.id) {
          for (let i = 0; i < this.state.property.length; i++) {
            console.log(61, propertyOptions.some(p => p.value === this.state.property[i]));
            if (!propertyOptions.some(p => p.value === this.state.property[i])) {
              this.props.form.setFieldsValue({
                properties: []
              });
              break;
            } else {
              this.props.form.setFieldsValue({
                properties: this.state.property
              });
            }
          }
        } else {
          this.props.form.setFieldsValue({
            properties: []
          });
          this.setState({ property: [] });
        }
        this.setState({ propertyOptions });
      });
    }
  }

  getHosts = async () => {
    const res = await getHostsAutomated();

    let hostOptions = [];

    if (res.status === 200) {
      hostOptions = res.data.map(host => {
        return { label: host.name, value: host._id, type: host.hostType, allowRentalListing: host.allowRentalListing };
      });
      if (hostOptions.length === 1) {
        this.setState({ host: hostOptions[0].value });
        this.props.form.setFieldsValue({
          host: hostOptions[0].value
        });
      }
      this.setState({ hostOptions });
    }
  };

  getCleaners = async () => {
    const res = await getUsers();
    let userOptions = [];

    if (res.status === 200) {
      userOptions = res.data.map(user => ({ label: `${user.userProfile.firstName} ${user.userProfile.lastName}`, value: user._id }));
      this.setState({ userOptions });
    }
  };

  getTeamById = async id => {
    try {
      let mode = MODE_EDIT;
      const res = await getCleaningTeamById(id);

      if (res && res.data && res.status === 200) {
        this.props.form.setFields({
          host: {
            value: res.data.host
          },
          properties: {
            value: res.data.properties
          },
          teamName: {
            value: res.data.name
          },
          users: {
            value: res.data.users
          }
        });

        this.setState({
          mode,
          host: res.data.host,
          property: res.data.properties,
          users: res.data.users,
          isLoading: false
        });
      }
    } catch (ex) {
      message.error(intl.get('taskManagement.cleaner.message.errorRetrieve').d('Error retrieving cleaning team'));
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;
    const { mode } = this.state;
    form.validateFields((err, values) => {
      if (!err) {
        if (mode === MODE_NEW) {
          this.setState({ isSaveButtonLoading: true });
          createCleaningTeam(values)
            .then(res => {
              if (res.status === 200) {
                history.push(`${buildTaskManagementUri()}/cleaning-team`);
                message.success(
                  `${intl.get('taskManagement.cleaner.message.cleanTeam').d('Cleaning team')} (${res.data.name}) ${intl
                    .get('taskManagement.cleaner.message.successCreate')
                    .d('success created')}`
                );
              }
            })
            .catch(ex => {
              message.error(ex);
            });
        } else if (mode === MODE_EDIT) {
          const teamId = match.params.id;

          const handleOnOk = async () => {
            try {
              this.setState({ isSaveButtonLoading: true });
              const res = await updateCleaningTeam(teamId, values);
              if (res && res.status === 204) {
                history.push(`${buildTaskManagementUri()}/cleaning-team`);
                message.success(intl.get('taskManagement.cleaner.message.updated').d('Cleaning team updated!'));
                this.setState({ isSaveButtonLoading: false });
              }
            } catch (ex) {
              message.error(ex);
              this.setState({ isSaveButtonLoading: false });
            }
          };

          Modal.confirm({
            title: intl.get('taskManagement.cleaner.message.confirmTitle').d('Are you sure want to overwrite existing data?'),
            content: intl
              .get('taskManagement.cleaner.message.confirmContent')
              .d('You will not be able to undo this action, but you may update it again.'),
            onOk: handleOnOk,
            onCancel() {},
            okButtonProps: { id: 'save-cfm-button-editct' },
            cancelButtonProps: { id: 'cancelsave-cfm-button-editct' }
          });
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const teamId = match.params.id;

    const handleOnOk = async () => {
      try {
        this.setState({ isDeleteButtonLoading: true });
        const res = await deleteTeamById(teamId);

        if (res.status === 204) {
          this.setState({ isDeleteButtonLoading: false });
          history.push(`${buildTaskManagementUri()}/cleaning-team`);
          message.success(intl.get('taskManagement.cleaner.message.deleted').d('Cleaning team deleted!'));
        } else {
          message.error(intl.get('taskManagement.cleaner.message.deletedError').d('Something went wrong and cleaning team is not deleted.'));
          this.setState({ isDeleteButtonLoading: false });
        }
      } catch (ex) {
        message.error(ex || intl.get('taskManagement.cleaner.message.deletedError').d('Something went wrong and cleaning team is not deleted.'));
        this.setState({ isDeleteButtonLoading: false });
      }
    };

    Modal.confirm({
      title: intl.get('taskManagement.cleaner.message.deleteTitle').d('Are you sure want to delete this cleaning team?'),
      content: intl.get('taskManagement.cleaner.message.deleteContent').d('This action cannot be reversed. Once deleted, it cannot be recovered.'),
      onOk: handleOnOk,
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button-editct' },
      cancelButtonProps: { id: 'cancel-cfm-button-editct' }
    });
  };

  render() {
    const { form, history, checkIsAllowCreateTask, checkIsAllowDeleteTask, checkIsAllowEditTask, checkIsAdminReadOnly } = this.props;
    const { getFieldDecorator } = form;
    const { isLoading, mode, isSaveButtonLoading, isDeleteButtonLoading, hostOptions, propertyOptions, userOptions } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Card className="ct-form-card" title={intl.get('taskManagement.cleaner.headerLabels.cleaningTeam').d('Cleaning Team')} loading={isLoading}>
          <CloseButton
            onClick={() => {
              history.push(`${buildTaskManagementUri()}/cleaning-team`);
            }}
          />
          <Row type="flex" justify="start" gutter={36}>
            <FormItem label={intl.get('taskManagement.cleaner.headerLabels.forHost').d('For Host')} className="ct-form-list">
              {getFieldDecorator('host', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.hostMsg').d('Please select a host')
                  }
                ]
              })(
                <Select
                  showSearch
                  placeholder={intl.get('reservations.reservationDetails.placeholder.host').d('Select a host')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ host: e })}
                >
                  {hostOptions.map(hostOption => {
                    return (
                      <Option key={hostOption.value} value={hostOption.value}>
                        {hostOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.cleaner.headerLabels.property').d('Properties')} className="ct-form-list">
              {getFieldDecorator('properties', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.atLeastPropertyMsg').d('Please select at least one property')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.atLeastProperty').d('Select at least one property')}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ property: e })}
                  disabled={propertyOptions.length === 0}
                >
                  {propertyOptions.map(hostOption => {
                    return (
                      <Option key={hostOption.value} value={hostOption.value}>
                        {hostOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.cleaner.headerLabels.team').d('Team Name')} className="ct-form-list">
              {getFieldDecorator('teamName', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.teamName').d('Please enter team name')
                  }
                ]
              })(<Input placeholder={intl.get('reservations.reservationDetails.placeholder.egTeam').d('eg. KLCC team')} />)}
            </FormItem>
            <FormItem label={intl.get('taskManagement.cleaner.headerLabels.applyUser').d('Apply to Users')} className="ct-form-list">
              {getFieldDecorator('users', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.userMsg').d('Please select at least one user')
                  }
                ]
              })(
                <Select
                  showArrow
                  showSearch
                  placeholder={intl.get('reservations.reservationDetails.placeholder.user').d('Select at least one user')}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ users: e })}
                  disabled={userOptions.length === 0}
                >
                  {userOptions.map(user => {
                    return (
                      <Option key={user.value} value={user.value}>
                        {user.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Row>
        </Card>
        <div className="save-and-delete-buttons">
          <FormItem className="ct-button-wrapper">
            {mode === 'new' ? (
              checkIsAllowCreateTask() && (
                <Button id="create-button-ct" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                  {intl.get('taskManagement.cleaner.headerLabels.createForm').d('Create')}
                </Button>
              )
            ) : (
              <React.Fragment>
                {checkIsAllowEditTask() && (
                  <Button id="save-button-edit-ct" type="primary" htmlType="submit" loading={isSaveButtonLoading} disabled={checkIsAdminReadOnly()}>
                    {intl.get('taskManagement.cleaner.headerLabels.save').d('Save')}
                  </Button>
                )}
                {checkIsAllowDeleteTask() && (
                  <Button
                    id="del-button-edit-ct"
                    type="danger"
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                    onClick={this.handleDelete}
                    disabled={checkIsAdminReadOnly()}
                  >
                    {intl.get('taskManagement.cleaner.headerLabels.delete').d('Delete')}
                  </Button>
                )}
              </React.Fragment>
            )}
          </FormItem>
        </div>
      </Form>
    );
  }
}

export default withRouter(withAppContext(Form.create()(CleaningTeamForm)));
