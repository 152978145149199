import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import Card from 'components/Card/Card';
import intl from 'react-intl-universal';

import {
  getAgodaSyncStatusConstant,
  getAirbnbSyncStatusConstant,
  getBookingcomSyncStatusConstant,
  getCtripSyncStatusConstant,
  getExpediaSyncStatusConstant,
  getIntegrationSourcesConstant,
  getCtripCMSyncStatusConstant,
  getTravelokaSyncStatusConstant,
  getTiketcomSyncStatusConstant
} from 'utils/apis/constants';
import { getProperties } from 'utils/apis/property';
import { getUnits } from 'utils/apis/unit';
import {
  getIntegratedHotelByPropertyId,
  getIntegratedListingByUnitId,
  unsyncAgoda,
  unsyncAgodaHomes,
  unsyncBookingcomHotel,
  unsyncContentAirbnb,
  unsyncCtripHotel,
  unsyncExpediaHotel,
  unSyncCtripCMHotel,
  putUnsyncTraveloka,
  putUnsyncTiketcom
} from 'utils/apis/integration';

import { errorHandlerWrapper, getIntegrationTypesConstant, guard } from 'utils/general';
import { buildIntegrationUri, buildIOTAFormUri, getIOTAFormQuery } from 'utils/routes';

import ListingIntegration from './ListingIntegration/ListingIntegration';
import HotelIntegration from './HotelIntegration/HotelIntegration';
import AirbnbSeasonalRuleModal from './components/AirbnbSeasonalRuleModal/AirbnbSeasonalRuleModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import OTAHotelForm from './components/OTAHotelForm/OTAHotelForm';
import OTAListingForm from './components/OTAListingForm/OTAListingForm';
import { withAppContext } from 'context/AppContext';

const TabPane = Tabs.TabPane;

const { LISTING_TYPE, HOTEL_TYPE } = getIntegrationTypesConstant();

const useSelectedTabDetails = uriIntegrationType => {
  const [selectedTab, setSelectedTab] = useState(uriIntegrationType || LISTING_TYPE.code);
  const isSelectedListingTab = useMemo(() => selectedTab === LISTING_TYPE.code, [selectedTab]);
  const isSelectedHotelTab = useMemo(() => selectedTab === HOTEL_TYPE.code, [selectedTab]);

  const handleOnChangeTab = integrationTypeCode => {
    setSelectedTab(integrationTypeCode);
  };

  return { selectedTab, isSelectedListingTab, isSelectedHotelTab, handleOnChangeTab };
};

const useFetchConstants = () => {
  const [isLoadingConstants, setIsLoadingConstants] = useState(false);
  const [otaSyncStatusConstants, setOTASyncStatusConstants] = useState({});
  const [integrationSourcesConstant, setIntegrationSourcesConstant] = useState({});

  const fetchOTASyncStatusConstants = async () => {
    setIsLoadingConstants(true);

    const [
      agodaSyncStatusConstant,
      airbnbSyncStatusConstant,
      bookingcomSyncStatusConstant,
      ctripSyncStatusConstant,
      expediaSyncStatusConstant,
      ctripCMSyncStatusConstant,
      travelokaSyncStatusConstant,
      tiketcomSyncStatusConstant,
      integrationSourcesConstant
    ] = await errorHandlerWrapper(
      Promise.all([
        getAgodaSyncStatusConstant(),
        getAirbnbSyncStatusConstant(),
        getBookingcomSyncStatusConstant(),
        getCtripSyncStatusConstant(),
        getExpediaSyncStatusConstant(),
        getCtripCMSyncStatusConstant(),
        getTravelokaSyncStatusConstant(),
        getTiketcomSyncStatusConstant(),
        getIntegrationSourcesConstant()
      ]),
      []
    );

    const otaSyncStatusConstants = {
      AGODA: agodaSyncStatusConstant,
      AIRBNB: airbnbSyncStatusConstant,
      BOOKINGCOM: bookingcomSyncStatusConstant,
      CTRIP: ctripSyncStatusConstant,
      EXPEDIA: expediaSyncStatusConstant,
      CTRIPCM: ctripCMSyncStatusConstant,
      TRAVELOKA: travelokaSyncStatusConstant,
      TIKETCOM: tiketcomSyncStatusConstant
    };

    setOTASyncStatusConstants(otaSyncStatusConstants);
    setIntegrationSourcesConstant(integrationSourcesConstant);

    setIsLoadingConstants(false);
  };

  useEffect(() => {
    fetchOTASyncStatusConstants();
  }, []);

  return { isLoadingConstants, otaSyncStatusConstants, integrationSourcesConstant };
};

const useFetchPropertyData = uriPropertyId => {
  const [isLoadingPropertyData, setIsLoadingPropertyData] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  // selectedProperty is only use for the OTAHotelForm modal, please remove once the code has improved
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedPropertyHostId, setSelectedPropertyHostId] = useState(undefined);
  const [selectedPropertyId, setSelectedPropertyId] = useState(undefined);

  const fetchPropertyData = useCallback(async () => {
    setIsLoadingPropertyData(true);

    const localProperties = await errorHandlerWrapper(getProperties({ fields: ['_id', 'name', 'host'] }), []);

    const propertyOptions = localProperties.map(property => ({ value: property._id, label: property.name }));
    const selectedProperty = guard(
      () => (!!uriPropertyId ? localProperties.find(property => property._id === uriPropertyId) : localProperties[0]),
      {}
    );
    const selectedPropertyId = selectedProperty._id;

    setProperties(localProperties);
    setPropertyOptions(propertyOptions);
    setSelectedProperty(selectedProperty);
    setSelectedPropertyHostId(selectedProperty.host);
    setSelectedPropertyId(selectedPropertyId);

    setIsLoadingPropertyData(false);
  }, [uriPropertyId]);

  const handleOnChangeProperty = useCallback(
    newPropertyId => {
      const newSelectedProperty = properties.find(property => property._id === newPropertyId);
      setSelectedProperty(newSelectedProperty);
      setSelectedPropertyHostId(newSelectedProperty.host);
      setSelectedPropertyId(newPropertyId);
    },
    [properties]
  );

  useEffect(() => {
    fetchPropertyData();
  }, [fetchPropertyData]);

  return { isLoadingPropertyData, propertyOptions, selectedProperty, selectedPropertyHostId, selectedPropertyId, handleOnChangeProperty };
};

const useFetchUnitData = (isSelectedListingTab, isLoadingPropertyData, uriUnitId, selectedPropertyId) => {
  const [isLoadingUnitData, setIsLoadingUnitData] = useState(false);
  const [units, setUnits] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  // selectedUnit is only use for the OTAListingForm modal, please remove once the code has improved
  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedUnitId, setSelectedUnitId] = useState(undefined);

  const fetchUnitData = useCallback(async () => {
    setIsLoadingUnitData(true);

    const localUnits = !!selectedPropertyId
      ? await errorHandlerWrapper(getUnits(selectedPropertyId, { fields: ['_id', 'name', 'roomType'] }), [])
      : [];

    const unitOptions = localUnits.map(unit => ({ value: unit._id, label: unit.name }));
    const selectedUnit = guard(() => (!!uriUnitId && localUnits.find(unit => unit._id === uriUnitId)) || localUnits[0], {});
    const selectedUnitId = selectedUnit._id;

    setUnits(localUnits);
    setUnitOptions(unitOptions);
    setSelectedUnit(selectedUnit);
    setSelectedUnitId(selectedUnitId);

    setIsLoadingUnitData(false);
  }, [uriUnitId, selectedPropertyId]);

  const handleOnChangeUnit = useCallback(
    newUnitId => {
      const newSelectedUnit = units.find(property => property._id === newUnitId);
      setSelectedUnit(newSelectedUnit);
      setSelectedUnitId(newUnitId);
    },
    [units]
  );

  useEffect(() => {
    if (isSelectedListingTab && !isLoadingPropertyData && !!selectedPropertyId) {
      fetchUnitData();
    }
  }, [isSelectedListingTab, isLoadingPropertyData, selectedPropertyId, fetchUnitData]);

  return { isLoadingUnitData, unitOptions, selectedUnit, selectedUnitId, handleOnChangeUnit };
};

const useFetchListingIntegrationData = (isLoadingUnitData, selectedUnitId) => {
  const [isLoadingListingIntegration, setIsLoadingListingIntegration] = useState(false);
  const [listingIntegrations, setListingIntegrations] = useState([]);

  const fetchListingIntegrationData = useCallback(async () => {
    setIsLoadingListingIntegration(true);

    const unformattedListingIntegration = await errorHandlerWrapper(getIntegratedListingByUnitId(selectedUnitId), {});

    const otaObjects = unformattedListingIntegration.ota;
    const otaObjectKeysFiltered = guard(() => Object.keys(otaObjects).filter(otaKey => !!otaObjects[otaKey]), []);
    const otaListings = otaObjectKeysFiltered.map(otaKey => {
      const ota = otaObjects[otaKey];

      // createEditModalData is only needed inside the modal, please remove after code improved
      const createEditModalData = {
        _id: unformattedListingIntegration.unit._id,
        unit: unformattedListingIntegration.unit.name,
        airbnbId: ota._id,
        airbnbListingId: ota.listingId,
        foreignListingId: ota.foreignListingId,
        syncCategory: ota.syncCategory,
        errorMessage: ota.errorMessage,
        rates: ota.rates,
        rate: ota.rate
          ? {
              _id: ota.rate._id,
              name: ota.rate.name,
              weekday: ota.rate.weekday,
              weekend: ota.rate.weekend,
              isDerived: ota.rate.isDerived,
              calculation: ota.rate.calculation
            }
          : ota.rates && ota.rates[0]
          ? {
              _id: ota.rates[0].rate ? ota.rates[0].rate._id : '',
              name: ota.rates[0].rate ? ota.rates[0].rate.name : '-',
              weekday: ota.rates[0].rate ? ota.rates[0].rate.weekday : '-',
              weekend: ota.rates[0].rate ? ota.rates[0].rate.weekend : '-',
              isDerived: ota.rates[0].rate && ota.rates[0].rate.isDerived,
              calculation: ota.rates[0].rate && ota.rates[0].rate.calculation
            }
          : {
              _id: '',
              name: '-',
              weekday: '-',
              weekend: '-',
              isDerived: undefined,
              calculation: undefined
            }
      };
      const airbnbDetail = !!ota.listingId ? { airbnbDetail: { foreignAirbnbId: ota.foreignListingId, airbnbListingId: ota.listingId } } : {};

      return {
        source: otaKey,
        unitId: unformattedListingIntegration.unit._id, // this is use for filter only, can remove after code improved
        listingName: ota.listingName,
        ...createEditModalData,
        ...airbnbDetail,
        status: ota.status,
        currency: guard(() => ota.currency, 'RM'),
        propertyCurrency: unformattedListingIntegration.property.currency
      };
    });

    setListingIntegrations(otaListings);

    setIsLoadingListingIntegration(false);
  }, [selectedUnitId]);

  useEffect(() => {
    if (!isLoadingUnitData && selectedUnitId) {
      fetchListingIntegrationData();
    }
  }, [isLoadingUnitData, selectedUnitId, fetchListingIntegrationData]);

  return { listingIntegrations, isLoadingListingIntegration, fetchListingIntegrationData };
};

const useFetchHotelIntegrationData = (isLoadingPropertyData, selectedPropertyId) => {
  const [isLoadingHotelIntegration, setIsLoadingHotelIntegration] = useState(false);
  const [hotelIntegrations, setHotelIntegrations] = useState([]);

  const fetchHotelIntegrationData = useCallback(async () => {
    setIsLoadingHotelIntegration(true);

    const unformattedHotelIntegration = await errorHandlerWrapper(getIntegratedHotelByPropertyId(selectedPropertyId), {});

    const otaObjects = unformattedHotelIntegration.ota;
    const otaObjectKeysFiltered = guard(() => Object.keys(otaObjects).filter(otaKey => !!otaObjects[otaKey]), []);
    const otaHotels = otaObjectKeysFiltered.map(otaKey => {
      const ota = otaObjects[otaKey];
      // createEditModalData is only needed inside the modal, please remove after code improved
      const createEditModalData = {
        _id: ota._id,
        statusMsg: ota.statusMsg,
        property: unformattedHotelIntegration.property._id,
        roomTypes: ota.roomTypes,
        foreignPropertyId: ota.foreignPropertyId,
        foreignPropertyName: ota.foreignPropertyName,
        code: ota.code,
        policies: ota.policies,
        currency: ota.currency ? ota.currency : 'RM'
      };

      return {
        source: otaKey,
        status: ota.status,
        propertyCurrency: unformattedHotelIntegration.property.currency,
        ...createEditModalData,
        roomTypeCount: ota.roomTypes.length,
        unitCount: guard(
          () => ota.roomTypes.map(roomType => roomType.roomType.rooms || 0).reduce((totalRoomCount, roomCount) => totalRoomCount + roomCount, 0),
          0
        )
      };
    });

    setHotelIntegrations(otaHotels);

    setIsLoadingHotelIntegration(false);
  }, [selectedPropertyId]);

  useEffect(() => {
    if (!isLoadingPropertyData && selectedPropertyId) {
      fetchHotelIntegrationData();
    }
  }, [isLoadingPropertyData, selectedPropertyId, fetchHotelIntegrationData]);

  return { hotelIntegrations, isLoadingHotelIntegration, fetchHotelIntegrationData };
};

const useSetUri = (history, selectedTab, selectedPropertyId, selectedUnitId) => {
  const setUri = useCallback(() => {
    const uri = buildIOTAFormUri({ propertyId: selectedPropertyId, unitId: selectedUnitId, integrationType: selectedTab });
    history.replace(uri);
  }, [history, selectedTab, selectedPropertyId, selectedUnitId]);

  useEffect(() => {
    setUri();
  }, [setUri]);
};

const OTAForm = ({ history, location, checkIsAdminReadOnly }) => {
  const [{ propertyId: uriPropertyId, unitId: uriUnitId, integrationType: uriIntegrationType }] = useState(() => getIOTAFormQuery(location.search));

  const { selectedTab, isSelectedListingTab, isSelectedHotelTab, handleOnChangeTab } = useSelectedTabDetails(uriIntegrationType);

  const [isShowModalAirbnbSeasonalRule, setIsShowModalAirbnbSeasonalRule] = useState(false);
  const [isShowModalEditHotelIntegration, setIsShowModalEditHotelIntegration] = useState(false);
  const [isShowModalDeleteHotelIntegration, setIsShowModalDeleteHotelIntegration] = useState(false);
  const [isShowModalCreateEditListingIntegration, setIsShowModalCreateEditListingIntegration] = useState(false);
  const [isShowModalDeleteListingIntegration, setIsShowModalDeleteListingIntegration] = useState(false);
  const [selectedAirbnbListingId, setSelectedAirbnbListingId] = useState('');
  const [selectedIntegrationSourceCode, setSelectedIntegrationSourceCode] = useState('');

  /* ------------------------------------Hooks to fetch data-------------------------------------- */
  const { isLoadingConstants, otaSyncStatusConstants, integrationSourcesConstant } = useFetchConstants();
  const {
    isLoadingPropertyData,
    propertyOptions,
    selectedProperty,
    selectedPropertyHostId,
    selectedPropertyId,
    handleOnChangeProperty
  } = useFetchPropertyData(uriPropertyId);
  const { isLoadingUnitData, unitOptions, selectedUnit, selectedUnitId, handleOnChangeUnit } = useFetchUnitData(
    isSelectedListingTab,
    isLoadingPropertyData,
    uriUnitId,
    selectedPropertyId
  );
  const { listingIntegrations, isLoadingListingIntegration, fetchListingIntegrationData } = useFetchListingIntegrationData(
    isLoadingUnitData,
    selectedUnitId
  );
  const { hotelIntegrations, isLoadingHotelIntegration, fetchHotelIntegrationData } = useFetchHotelIntegrationData(
    isLoadingPropertyData,
    selectedPropertyId
  );

  useSetUri(history, selectedTab, selectedPropertyId, selectedUnitId);

  /* ------------------------------------Loading state-------------------------------------- */
  const isLoadingDefaultData = useMemo(() => isLoadingConstants || isLoadingPropertyData || isLoadingUnitData, [
    isLoadingConstants,
    isLoadingPropertyData,
    isLoadingUnitData
  ]);

  /* ------------------------------------Functions-------------------------------------- */
  const handleOnCloseOTAForm = () => history.push(buildIntegrationUri());

  const handleOnConfirmDelete = () => {
    try {
      switch (selectedIntegrationSourceCode) {
        case integrationSourcesConstant.AGODA.code:
          unsyncAgoda(selectedPropertyId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.AGODAHOMES.code:
          unsyncAgodaHomes(selectedAirbnbListingId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.AIRBNB.code:
          unsyncContentAirbnb(selectedAirbnbListingId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.BOOKINGCOM.code:
          unsyncBookingcomHotel(selectedPropertyId).then(() => window.location.reload());
          break;
        // case integrationSourcesConstant.CTRIP.code:
        //   unsyncCtripHotel(selectedPropertyId).then(() => window.location.reload());
        //   break;
        case integrationSourcesConstant.CTRIPCM.code:
          const ctripListingId = hotelIntegrations.find(hotel => hotel.source === selectedIntegrationSourceCode).foreignPropertyId;
          unSyncCtripCMHotel(ctripListingId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.EXPEDIA.code:
          const otaId = hotelIntegrations.find(hotel => hotel.source === selectedIntegrationSourceCode)._id;
          unsyncExpediaHotel(otaId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.TRAVELOKA.code:
          const travelokaId = hotelIntegrations.find(hotel => hotel.source === selectedIntegrationSourceCode)._id;
          putUnsyncTraveloka(travelokaId).then(() => window.location.reload());
          break;
        case integrationSourcesConstant.TIKETCOM.code:
          const tiketcomId = hotelIntegrations.find(hotel => hotel.source === selectedIntegrationSourceCode)._id;
          putUnsyncTiketcom(tiketcomId).then(() => window.location.reload());
          break;
        default:
          return;
      }
    } catch (ex) {}
  };

  /* ------------------------------------Hotel Modals-------------------------------------- */
  const handleOnClickButtonAirbnbSeasonalRule = airbnbListingId => {
    setSelectedAirbnbListingId(airbnbListingId);
    setIsShowModalAirbnbSeasonalRule(true);
  };

  const handleOnCancelModalAirbnbSeasonalRule = () => {
    setSelectedAirbnbListingId('');
    setIsShowModalAirbnbSeasonalRule(false);
  };

  const handleOnClickButtonCreateEditHotelIntegration = source => {
    setSelectedIntegrationSourceCode(source);
    setIsShowModalEditHotelIntegration(true);
  };

  const handleOnConfirmModalCreateEditHotelIntegration = () => {
    fetchHotelIntegrationData();
  };

  const handleOnCancelModalCreateEditHotelIntegration = () => {
    setIsShowModalEditHotelIntegration(false);
  };

  const handleOnClickButtonDeleteHotelIntegration = source => {
    setSelectedIntegrationSourceCode(source);
    setIsShowModalDeleteHotelIntegration(true);
  };

  const handleOnCancelModalDeleteHotelIntegration = () => {
    setIsShowModalDeleteHotelIntegration(false);
  };

  /* ------------------------------------Integration Modals-------------------------------------- */
  const handleOnClickButtonCreateEditListingIntegration = source => {
    setSelectedIntegrationSourceCode(source);
    setIsShowModalCreateEditListingIntegration(true);
  };

  const handleOnCloseCreateEditModal = shouldFetchListingIntegrationData => {
    setIsShowModalCreateEditListingIntegration(false);

    if (shouldFetchListingIntegrationData) {
      fetchListingIntegrationData();
    }
  };

  const handleOnClickButtonDeleteListingIntegration = (source, airbnbListingId) => {
    setSelectedAirbnbListingId(airbnbListingId);
    setSelectedIntegrationSourceCode(source);
    setIsShowModalDeleteListingIntegration(true);
  };

  const handleOnCancelModalDeleteListingIntegration = () => {
    setIsShowModalDeleteListingIntegration(false);
  };

  //console.log(otaSyncStatusConstants);
  /* ------------------------------------Render-------------------------------------- */

  return (
    <>
      {isShowModalAirbnbSeasonalRule && (
        <AirbnbSeasonalRuleModal
          isShowModal={isShowModalAirbnbSeasonalRule}
          onModalCancel={handleOnCancelModalAirbnbSeasonalRule}
          airbnbListingId={selectedAirbnbListingId}
          host={selectedPropertyHostId}
        />
      )}
      {isShowModalEditHotelIntegration && (
        <OTAHotelForm
          integrationSourcesConstant={integrationSourcesConstant}
          hotelIntegrations={hotelIntegrations}
          selectedProperty={selectedProperty}
          selectedIntegrationSourceCode={selectedIntegrationSourceCode}
          onAfterSave={handleOnConfirmModalCreateEditHotelIntegration}
          onClose={handleOnCancelModalCreateEditHotelIntegration}
        />
      )}
      {isShowModalCreateEditListingIntegration && (
        <OTAListingForm
          history={history}
          integrationSourcesConstant={integrationSourcesConstant}
          listingIntegrations={listingIntegrations}
          selectedIntegrationSourceCode={selectedIntegrationSourceCode}
          propertyHostId={selectedPropertyHostId}
          unit={selectedUnit}
          onClose={handleOnCloseCreateEditModal}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      )}
      {isShowModalDeleteListingIntegration && (
        <ConfirmationModal
          title={intl.get('hostConnect.integration.headerLabels.unsync').d('Unsync Integration')}
          message={intl.get('hostConnect.integration.headerLabels.unsyncMsg').d('Are you sure you want to remove the integration?')}
          visible={isShowModalDeleteListingIntegration}
          cancelText={intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
          okText={intl.get('hostConnect.integration.headerLabels.delete').d('Delete')}
          onOk={handleOnConfirmDelete}
          onCancel={handleOnCancelModalDeleteListingIntegration}
        />
      )}
      {isShowModalDeleteHotelIntegration && (
        <ConfirmationModal
          title={intl.get('hostConnect.integration.headerLabels.unsync').d('Unsync Integration')}
          message={intl.get('hostConnect.integration.headerLabels.unsyncMsgProperty').d('Are you sure you want to unsync the property?')}
          visible={isShowModalDeleteHotelIntegration}
          cancelText={intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
          okText={intl.get('hostConnect.integration.headerLabels.unsyncLabel').d('Unsync')}
          onOk={handleOnConfirmDelete}
          onCancel={handleOnCancelModalDeleteHotelIntegration}
        />
      )}
      <Card onClose={handleOnCloseOTAForm}>
        <Tabs activeKey={selectedTab} onChange={handleOnChangeTab}>
          <TabPane
            tab={intl.get(`hostConnect.integration.integrationType.${LISTING_TYPE.tabTitle}`).d(LISTING_TYPE.tabTitle)}
            key={LISTING_TYPE.code}
          >
            {isSelectedListingTab && (
              <ListingIntegration
                isLoadingDefaultData={isLoadingDefaultData}
                isLoadingListingIntegration={isLoadingListingIntegration}
                otaSyncStatusConstants={otaSyncStatusConstants}
                propertyOptions={propertyOptions}
                unitOptions={unitOptions}
                listingIntegrations={listingIntegrations}
                selectedPropertyId={selectedPropertyId}
                selectedUnitId={selectedUnitId}
                onChangeProperty={handleOnChangeProperty}
                onChangeUnit={handleOnChangeUnit}
                onClickButtonSeasonal={handleOnClickButtonAirbnbSeasonalRule}
                onClickButtonCreateEdit={handleOnClickButtonCreateEditListingIntegration}
                onClickButtonDelete={handleOnClickButtonDeleteListingIntegration}
                checkIsAdminReadOnly={checkIsAdminReadOnly}
              />
            )}
          </TabPane>
          <TabPane tab={intl.get(`hostConnect.integration.integrationType.${HOTEL_TYPE.tabTitle}`).d(HOTEL_TYPE.tabTitle)} key={HOTEL_TYPE.code}>
            {isSelectedHotelTab && (
              <HotelIntegration
                isLoadingDefaultData={isLoadingDefaultData}
                isLoadingHotelsIntegration={isLoadingHotelIntegration}
                otaSyncStatusConstants={otaSyncStatusConstants}
                propertyOptions={propertyOptions}
                hotelIntegrations={hotelIntegrations}
                selectedPropertyId={selectedPropertyId}
                onChangeProperty={handleOnChangeProperty}
                onClickButtonCreateEdit={handleOnClickButtonCreateEditHotelIntegration}
                onClickButtonDelete={handleOnClickButtonDeleteHotelIntegration}
                checkIsAdminReadOnly={checkIsAdminReadOnly}
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default withAppContext(withRouter(OTAForm));
