import React, { Component } from 'react';
import moment from 'moment';
import { Col, Select, Row, Tabs, DatePicker, Card, notification, message, Button, Switch, Modal, Skeleton } from 'antd';
import { withAppContext } from 'context/AppContext';

import LineGraph from 'components/LineGraph/LineGraph';
import Histogram from 'components/Histogram/Histogram';
import PieChart from 'components/PieChart/PieChart';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import { MONTH_FORMAT } from 'utils/constants';
import { buildReportingUri } from 'utils/routes';
import queryString from 'query-string';
import { numberWithCommas, rounds } from 'utils/general';
import {
  getProfitAndExpenses,
  getOccupancyComparison,
  getOccupancyByType,
  getRevenueComparison,
  getRevenueByType,
  getMonthlyRevenue,
  getMonthlyExpenses,
  getOwnerHostProfitData,
  getPlatformSourceRevenue,
  getIsReportFreeze,
  freezeReport
} from 'utils/apis/reporting';
import { getProperties } from 'utils/apis/property';
import { checkHostIsAllowedAllOptions } from 'utils/apis/host';

import CSVDownload from './components/CSVDownload/CSVDownload';
import './Reporting.css';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const SummaryCard = ({ title, currentAmount, shouldInvertDiffAmountColor, diffAmount, children, isLoading, currency, timezone }) => {
  const getColor = () => {
    if (diffAmount === 0) {
      return 'grey';
    }
    if (shouldInvertDiffAmountColor) {
      return diffAmount < 0 ? 'green' : 'red';
    }
    return diffAmount < 0 ? 'red' : 'green';
  };
  return (
    <Card loading={isLoading}>
      {title && <h2>{title}</h2>}
      <Row type="flex" justify="center">
        <span className="reportingcurrencyFont">{currency} </span>
        <span className="mainValue">{numberWithCommas(currentAmount)}</span>
        <span
          style={{
            color: getColor(),
            fontSize: '100%',
            position: 'relative',
            top: '15px'
          }}
        >
          {(diffAmount < 0 ? `▼ ${currency}` : diffAmount > 0 ? `▲ ${currency}` : '') + numberWithCommas(Math.abs(diffAmount))}
        </span>
      </Row>
      {children}
    </Card>
  );
};

const ComparisonCard = ({ title, onDateChange, children, date, selectedMonth = '2019-02', isLoading }) => {
  return (
    <Card loading={isLoading} style={{ height: '100%' }}>
      <div style={{ minHeight: '76px' }}>
        <h2>{title}</h2>
        {onDateChange && (
          <DatePicker
            style={{ marginBottom: '16px' }}
            disabledDate={value => {
              if (value.month() !== date.month()) {
                return true;
              }
              return false;
            }}
            value={date}
            defaultValue={moment()}
            onChange={onDateChange}
            placeholder="Select date"
          />
        )}
      </div>
      {children}
    </Card>
  );
};

const getTotalAmount = (data, position) => {
  return position > 0 && data && data.length > position ? data[position].Host + data[position].Owner : 0;
};

const constructReadableYearMonthFormat = (year, month) => {
  if (year && month > -1) {
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  } else {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  }
};

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: moment().format('YYYY'),
      month: moment().format('M') - 1, // months start at 0,
      revenueWeekDate: moment(),
      occupancyWeekDate: moment(),
      property: null,
      propertyOptions: [],
      revenueData: [],
      expensesData: [],
      profitData: [],
      weeklyOccupancy: [],
      monthlyOccupancy: [],
      yearlyOccupancy: [],
      roomTypeOccupancy: [],
      sourceOccupancy: [],
      weeklyRevenue: [],
      monthlyRevenue: [],
      yearlyRevenue: [],
      roomTypeRevenue: [],
      sourceRevenue: [],
      monthlyRevenueCSV: [],
      monthlyExpensesBreakDownCSV: [],
      ownerHostProfitCSV: [],
      platformSourceRevenueCSV: [],
      isLoading: true,
      fetchingMonthlyRevenue: false,
      fetchingMonthlyExpenses: false,
      fetchingOwnerHostProfit: false,
      fetchingPlatformSourceRevenue: false,
      isHostAllowToViewAllProperty: false,
      freezeReportData: {
        profitAndExpenses: {},
        occupancyByType: {
          roomType: {},
          source: {}
        },
        occupancyByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        },
        revenueByType: {
          roomType: {},
          source: {}
        },
        revenueByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        }
      },
      isReportFreeze: false,
      isShowEditButton: false,
      isConfirmed: false,
      currency: 'MYR',
      timezone: ''
      // selectedRadio: 'Off'
    };
    this.handleOnPropertyChange = this.handleOnPropertyChange.bind(this);
    this.handleOnMonthChange = this.handleOnMonthChange.bind(this);
    this.handleOnOccupancyWeekChange = this.handleOnOccupancyWeekChange.bind(this);
    this.handleOnRevenueWeekChange = this.handleOnRevenueWeekChange.bind(this);
    this.handleOnReportFreeze = this.handleOnReportFreeze.bind(this);
  }

  monthlyRevenueName = 'Monthly Revenue Breakdown';
  monthlyExpensesName = 'Monthly Expenses Breakdown';
  ownerHostProfitName = 'Owner Host Profit';
  platformSourceRevenueName = 'Platform Source Revenue';

  componentDidMount = async () => {
    const { location, form } = this.props;
    const queryStringDetails = location.search && queryString.parse(location.search);

    this.setState({ isLoading: true });
    const { user: viewingUser } = this.props;
    if (!viewingUser.isAdmin) {
      if (viewingUser.roles && viewingUser.roles.length > 0 && viewingUser.roles[0].permissions.includes(18)) {
        this.setState({ isShowEditButton: true });
      }
    } else if (viewingUser.isAdmin) {
      this.setState({ isShowEditButton: true });
    }
    const property = await this.firstPropertyId(queryStringDetails.property, queryStringDetails.date);
    this.setURLQuery({ propertyId: property, date: queryStringDetails.date });

    let mountYear, mountMonth;
    if (queryStringDetails.date) {
      [mountYear, mountMonth] = queryStringDetails.date.split('-').map(Number);
      mountMonth = mountMonth - 1;
    } else {
      mountMonth = this.state.month;
      mountYear = this.state.year;
    }

    this.setState({
      property: property,
      year: mountYear,
      month: mountMonth
    });
  };

  firstPropertyId = async (selectedPropertyId, date) => {
    let firstPropertyId;
    let foundProperty;
    await getProperties()
      .then(properties => {
        if (selectedPropertyId) {
          foundProperty = properties.find(res => {
            return String(res._id) === String(selectedPropertyId);
          });
          firstPropertyId = foundProperty._id;
        } else {
          firstPropertyId = properties[0]._id;
        }
        if (selectedPropertyId) {
          const firstProperty = foundProperty;
          this.setState({
            propertyOptions: properties.map(u => {
              return { value: u._id, label: u.name };
            }),
            property: firstProperty._id
          });
          const currentDate = moment(date);
          console.log(currentDate);
          Promise.all([
            this.fetchIsReportFreeze(firstProperty._id, currentDate.format('YYYY-MM')),
            this.fetchProfitAndExpensesData(firstProperty._id, currentDate.format('YYYY-MM')),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM-DD'), 'weekly'),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'monthly'),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'yearly'),
            this.fetchOccupancyByType(firstProperty._id, currentDate.format('YYYY-MM'), 'roomType'),
            this.fetchOccupancyByType(firstProperty._id, currentDate.format('YYYY-MM'), 'source'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM-DD'), 'weekly'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'monthly'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'yearly'),
            this.fetchRevenueByType(firstProperty._id, currentDate.format('YYYY-MM'), 'roomType'),
            this.fetchRevenueByType(firstProperty._id, currentDate.format('YYYY-MM'), 'source')
          ]).finally(() => {
            this.setState({ isLoading: false });
            this.fetchIsHostAllowedViewAllProperties().then(isHostAllowToViewAllProperty => {
              this.setState({
                isHostAllowToViewAllProperty
              });
            });
          });
        } else {
          const firstProperty = properties[0];
          this.setState({
            propertyOptions: properties.map(u => {
              return { value: u._id, label: u.name, currency: u.currency, timezone: u.timezone };
            }),
            property: firstProperty._id,
            currency: firstProperty.currency,
            timezone: firstProperty.timezone
          });
          const currentDate = new moment();
          Promise.all([
            this.fetchIsReportFreeze(firstProperty._id, currentDate.format('YYYY-MM')),
            this.fetchProfitAndExpensesData(firstProperty._id, currentDate.format('YYYY-MM')),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM-DD'), 'weekly'),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'monthly'),
            this.fetchOccupancyComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'yearly'),
            this.fetchOccupancyByType(firstProperty._id, currentDate.format('YYYY-MM'), 'roomType'),
            this.fetchOccupancyByType(firstProperty._id, currentDate.format('YYYY-MM'), 'source'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM-DD'), 'weekly'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'monthly'),
            this.fetchRevenueComparison(firstProperty._id, currentDate.format('YYYY-MM'), 'yearly'),
            this.fetchRevenueByType(firstProperty._id, currentDate.format('YYYY-MM'), 'roomType'),
            this.fetchRevenueByType(firstProperty._id, currentDate.format('YYYY-MM'), 'source')
          ]).finally(() => {
            this.setState({ isLoading: false });
            this.fetchIsHostAllowedViewAllProperties().then(isHostAllowToViewAllProperty => {
              this.setState({
                isHostAllowToViewAllProperty
              });
            });
          });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        console.error(err);
        message.error(err);
      });
    return firstPropertyId;
  };

  setURLQuery = ({ propertyId, date }) => {
    const { month, year } = this.state;
    const { history } = this.props;

    if (!date) {
      date = constructReadableYearMonthFormat(year, month);
    }
    const monthWithYearInString = moment(date).format(MONTH_FORMAT);
    history.replace(buildReportingUri({ property: propertyId, date: monthWithYearInString }));
  };

  fetchIsReportFreeze = (propertyId, date) => {
    // console.log(date);
    return getIsReportFreeze(propertyId, date).then(res => {
      // console.log('isReportFreeze', res);
      this.setState({ isReportFreeze: res.data });
    });
  };

  fetchOccupancyComparison = (propertyId, date, type) => {
    return getOccupancyComparison(propertyId, date, type).then(res => {
      // console.log('OccupancyComparison ', type, res);
      const previousNightKeys = Object.keys(res.data.totalPreviousNights);
      const currentNightKeys = Object.keys(res.data.totalCurrentNights);
      let isPreviousMonthLonger = false;
      let keysToLoop = currentNightKeys;
      if (previousNightKeys.length > currentNightKeys.length) {
        isPreviousMonthLonger = true;
        keysToLoop = previousNightKeys;
      }
      var freezeReportData = { ...this.state.freezeReportData };
      if (type === 'monthly') {
        freezeReportData.occupancyByComparison.monthly = res.data;
      } else if (type === 'yearly') {
        freezeReportData.occupancyByComparison.yearly = res.data;
      } else if (type === 'weekly') {
        freezeReportData.occupancyByComparison.weekly = res.data;
      }
      this.setState({
        freezeReportData,
        [`${type}Occupancy`]: keysToLoop.reduce((occupancyRates, key, index) => {
          const currentNight = !isPreviousMonthLonger ? res.data.totalCurrentNights[key] : res.data.totalCurrentNights[currentNightKeys[index]];
          const previousNight = isPreviousMonthLonger ? res.data.totalPreviousNights[key] : res.data.totalPreviousNights[previousNightKeys[index]];
          const currentPossibleBookedNights = !isPreviousMonthLonger
            ? res.data.possibleCurrentBookedNights[key]
            : res.data.possibleCurrentBookedNights[currentNightKeys[index]];
          const previousPossibleBookedNights = isPreviousMonthLonger
            ? res.data.possiblePreviousBookedNights[key]
            : res.data.possiblePreviousBookedNights[previousNightKeys[index]];
          let date;
          if (type === 'weekly') {
            date = moment(key).format('ddd');
          } else if (type === 'monthly') {
            date = moment(key).format('D');
          } else if (type === 'yearly') {
            date = moment(key).format('MMM');
          }
          return [
            ...occupancyRates,
            {
              date,
              Current: !!currentPossibleBookedNights ? rounds((currentNight / currentPossibleBookedNights) * 100, 2) : 0,
              Previous: !!previousPossibleBookedNights ? rounds((previousNight / previousPossibleBookedNights) * 100, 2) : 0
            }
          ];
        }, [])
      });
    });
  };

  fetchOccupancyByType = (propertyId, date, type) => {
    return getOccupancyByType(propertyId, date, type).then(res => {
      // console.log('OccupancyByType ', type, res);
      var freezeReportData = { ...this.state.freezeReportData };
      if (type === 'roomType') {
        freezeReportData.occupancyByType.roomType = res.data;
      } else if (type === 'source') {
        freezeReportData.occupancyByType.source = res.data;
      }
      this.setState({
        freezeReportData,
        [`${type}Occupancy`]: Object.keys(res.data).map(key => {
          const numberOfNights = res.data[key];
          if (type === 'source') {
            return {
              type: key,
              count: numberOfNights,
              color: key
                ? key === 'Airbnb'
                  ? '#EE7476'
                  : key === 'Agoda'
                  ? '#9B9795'
                  : key === 'Agoda Homes'
                  ? '#FF537E'
                  : key === 'Booking.com'
                  ? '#283475'
                  : key === 'Ctrip'
                  ? ' #F8981E'
                  : key === 'Expedia'
                  ? '#FBC300'
                  : key === 'HostPlatform'
                  ? '#00b9c6'
                  : key === 'ExtIntegration'
                  ? '#00f'
                  : key === 'Tiket.com'
                  ? '#0ff'
                  : ''
                : ''
            };
          } else {
            return {
              type: key,
              count: numberOfNights
            };
          }
        })
      });
    });
  };

  fetchRevenueComparison = (propertyId, date, type) => {
    console.log(date);
    return getRevenueComparison(propertyId, date, type).then(res => {
      console.log(res);
      // console.log('RevenueComparison ', type, res);
      const previousRevenueKeys = Object.keys(res.data.previousRevenue);
      const currentRevenueKeys = Object.keys(res.data.currentRevenue);
      // console.log(332, res);
      let isPreviousMonthLonger = false;
      let keysToLoop = currentRevenueKeys;
      if (previousRevenueKeys.length > currentRevenueKeys.length) {
        isPreviousMonthLonger = true;
        keysToLoop = previousRevenueKeys;
      }
      var freezeReportData = { ...this.state.freezeReportData };
      if (type === 'monthly') {
        freezeReportData.revenueByComparison.monthly = res.data;
      } else if (type === 'yearly') {
        freezeReportData.revenueByComparison.yearly = res.data;
      } else if (type === 'weekly') {
        freezeReportData.revenueByComparison.weekly = res.data;
      }
      console.log(type);
      console.log(freezeReportData);
      this.setState({
        freezeReportData,
        [`${type}Revenue`]: keysToLoop.reduce((revenues, key, index) => {
          const currentRevenue = !isPreviousMonthLonger ? res.data.currentRevenue[key] : res.data.currentRevenue[currentRevenueKeys[index]];
          const previousRevenue = isPreviousMonthLonger ? res.data.previousRevenue[key] : res.data.previousRevenue[previousRevenueKeys[index]];
          let date;
          if (type === 'weekly') {
            date = moment(key).format('ddd');
          } else if (type === 'monthly') {
            date = moment(key).format('D');
          } else if (type === 'yearly') {
            date = moment(key).format('MMM');
          }
          return [
            ...revenues,
            {
              date,
              Current: currentRevenue || 0,
              Previous: previousRevenue || 0
            }
          ];
        }, [])
      });
    });
  };

  fetchRevenueByType = (propertyId, date, type) => {
    return getRevenueByType(propertyId, date, type).then(res => {
      // console.log('RevenueByType ', type, res);
      var freezeReportData = { ...this.state.freezeReportData };
      if (type === 'roomType') {
        freezeReportData.revenueByType.roomType = res.data;
      } else if (type === 'source') {
        freezeReportData.revenueByType.source = res.data;
      }
      this.setState({
        freezeReportData,
        [`${type}Revenue`]: Object.keys(res.data).map(key => {
          const revenue = res.data[key];
          if (type === 'source') {
            return {
              type: key,
              revenue: revenue,
              color: key
                ? key === 'Airbnb'
                  ? '#EE7476'
                  : key === 'Agoda'
                  ? '#9B9795'
                  : key === 'Agoda Homes'
                  ? '#FF537E'
                  : key === 'Booking.com'
                  ? '#283475'
                  : key === 'Ctrip'
                  ? ' #F8981E'
                  : key === 'Expedia'
                  ? '#FBC300'
                  : key === 'HostPlatform'
                  ? '#00b9c6'
                  : key === 'ExtIntegration'
                  ? '#00f'
                  : key === 'Tiket.com'
                  ? '#0ff'
                  : ''
                : ''
            };
          } else {
            return {
              type: key,
              revenue: revenue
            };
          }
        })
      });
    });
  };

  fetchProfitAndExpensesData = (propertyId, date) => {
    this.setState({ isLoading: true });
    return getProfitAndExpenses(propertyId, date).then(res => {
      // console.log('ProfitAndExpenses', res);
      var freezeReportData = { ...this.state.freezeReportData };
      freezeReportData.profitAndExpenses = res.data;
      this.setState({
        freezeReportData,
        revenueData: res.data.map(data => {
          return {
            month: data.month.label,
            Revenue: Number((data.profit.host + data.profit.owner + data.expenses.host + data.expenses.owner).toFixed(2))
          };
        }),
        expensesData: res.data.map(data => {
          return {
            month: data.month.label,
            Host: data.expenses.host,
            Owner: data.expenses.owner
          };
        }),
        profitData: res.data.map(data => {
          return {
            month: data.month.label,
            Host: data.profit.host,
            Owner: data.profit.owner
          };
        })
      });
    });
  };

  handleOnMonthChange(date) {
    const year = date.format('YYYY');
    const month = parseInt(date.format('M'), 10) - 1; // months start at 0
    date.set('date', 1);
    this.setState({
      revenueWeekDate: date,
      occupancyWeekDate: date,
      year,
      month,
      monthlyRevenueCSV: [],
      monthlyExpensesBreakDownCSV: [],
      ownerHostProfitCSV: [],
      platformSourceRevenueCSV: [],
      freezeReportData: {
        profitAndExpenses: {},
        occupancyByType: {
          roomType: {},
          source: {}
        },
        occupancyByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        },
        revenueByType: {
          roomType: {},
          source: {}
        },
        revenueByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        }
      }
    });

    this.setURLQuery({ propertyId: this.state.property, date: constructReadableYearMonthFormat(year, month) });

    Promise.all([
      this.fetchIsReportFreeze(this.state.property, date.format('YYYY-MM')),
      this.fetchProfitAndExpensesData(this.state.property, date.format('YYYY-MM')),
      this.fetchOccupancyComparison(this.state.property, date.format('YYYY-MM-DD'), 'weekly'),
      this.fetchOccupancyComparison(this.state.property, date.format('YYYY-MM'), 'monthly'),
      this.fetchOccupancyComparison(this.state.property, date.format('YYYY-MM'), 'yearly'),
      this.fetchOccupancyByType(this.state.property, date.format('YYYY-MM'), 'roomType'),
      this.fetchOccupancyByType(this.state.property, date.format('YYYY-MM'), 'source'),
      this.fetchRevenueComparison(this.state.property, date.format('YYYY-MM-DD'), 'weekly'),
      this.fetchRevenueComparison(this.state.property, date.format('YYYY-MM'), 'monthly'),
      this.fetchRevenueComparison(this.state.property, date.format('YYYY-MM'), 'yearly'),
      this.fetchRevenueByType(this.state.property, date.format('YYYY-MM'), 'roomType'),
      this.fetchRevenueByType(this.state.property, date.format('YYYY-MM'), 'source')
    ]).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  handleOnOccupancyWeekChange(date, dateString) {
    this.setState({
      isLoading: false,
      occupancyWeekDate: date
    });
    this.fetchOccupancyComparison(this.state.property, date.format('YYYY-MM-DD'), 'weekly').finally(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  handleOnRevenueWeekChange(date, dateString) {
    this.setState({
      isLoading: true,
      revenueWeekDate: date
    });
    this.fetchRevenueComparison(this.state.property, date.format('YYYY-MM-DD'), 'weekly').finally(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  handleOnPropertyChange(newProperty) {
    const propertyInfo = this.state.propertyOptions.find(propertyOption => propertyOption.value === newProperty);
    this.setState({
      property: newProperty,
      monthlyRevenueCSV: [],
      monthlyExpensesBreakDownCSV: [],
      ownerHostProfitCSV: [],
      platformSourceRevenueCSV: [],
      currency: propertyInfo.currency,
      timezone: propertyInfo.timezone,
      freezeReportData: {
        profitAndExpenses: {},
        occupancyByType: {
          roomType: {},
          source: {}
        },
        occupancyByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        },
        revenueByType: {
          roomType: {},
          source: {}
        },
        revenueByComparison: {
          weekly: {},
          monthly: {},
          yearly: {}
        }
      }
    });
    this.setURLQuery({ propertyId: newProperty });

    const dateString = `${this.state.year}-${this.state.month + 1}`;
    Promise.all([
      this.fetchIsReportFreeze(newProperty, dateString),
      this.fetchProfitAndExpensesData(newProperty, dateString),
      this.fetchOccupancyComparison(newProperty, dateString, 'weekly'),
      this.fetchOccupancyComparison(newProperty, dateString, 'monthly'),
      this.fetchOccupancyComparison(newProperty, dateString, 'yearly'),
      this.fetchOccupancyByType(newProperty, dateString, 'roomType'),
      this.fetchOccupancyByType(newProperty, dateString, 'source'),
      this.fetchRevenueComparison(newProperty, dateString, 'weekly'),
      this.fetchRevenueComparison(newProperty, dateString, 'monthly'),
      this.fetchRevenueComparison(newProperty, dateString, 'yearly'),
      this.fetchRevenueByType(newProperty, dateString, 'roomType'),
      this.fetchRevenueByType(newProperty, dateString, 'source')
    ]).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  handleOnReportFreeze = async () => {
    this.setState({ isLoading: true });
    // const currentDate = new moment();
    const { year, month } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');
    // console.log(year, month, selectedDate.format('YYYY-MM'));
    const propertyId = await this.getSelectedPropertyId();

    return freezeReport({
      propertyId: this.state.property,
      date: selectedDate.format('YYYY-MM'),
      isFreezeReport: this.state.isReportFreeze,
      data: this.state.freezeReportData,
      csvPropertyId: propertyId
    }).then(res => {
      // console.log(res);
      this.fetchIsReportFreeze(this.state.property, selectedDate.format('YYYY-MM'));
      this.setState({
        isLoading: false,
        monthlyRevenueCSV: [],
        monthlyExpensesBreakDownCSV: [],
        ownerHostProfitCSV: [],
        platformSourceRevenueCSV: []
      });
    });
  };

  fetchIsHostAllowedViewAllProperties = async () => {
    const { getUserHosts, checkIsAdmin } = this.props;
    if (!checkIsAdmin()) {
      const hosts = getUserHosts();
      const isHostAllowToViewAllProperty = await checkHostIsAllowedAllOptions(hosts[0]);
      return isHostAllowToViewAllProperty;
    } else {
      return false;
    }
  };

  getSelectedPropertyId = async () => {
    const { property, isHostAllowToViewAllProperty } = this.state;
    // if (isHostAllowToViewAllProperty) {
    //   return undefined;
    // }
    return property;
  };

  handleOnFetchMonthlyRevenueCsvData = async () => {
    const { year, month } = this.state;
    const dateString = `${year}-${month + 1}`;
    this.setState({
      fetchingMonthlyRevenue: true
    });
    const propertyId = await this.getSelectedPropertyId();
    getMonthlyRevenue(propertyId, dateString)
      .then(res => {
        const reservations = res.data;
        const formattedReservations = reservations.map(reservation => {
          const otherRevenue = reservation.revenue
            ? Object.keys(reservation.revenue).reduce((totalAmount, key) => {
                if (key === 'rental' || key === 'cleaning') {
                  return totalAmount;
                }
                const amount = reservation.revenue[key];
                return totalAmount + amount;
              }, 0)
            : 0;
          const addOnRevenue = reservation.addOn
            ? Object.keys(reservation.addOn).reduce((totalAmount, key) => {
                const amount = reservation.addOn[key];
                return totalAmount + amount;
              }, 0)
            : 0;

          return {
            [intl.get('csv.bookingSource').d('Booking Source')]: reservation.platform,
            [intl.get('csv.bookingType').d('Booking Type')]: reservation.bookingType,
            [intl.get('csv.propertyName').d('Property Name')]: reservation.propertyName,
            [intl.get('csv.roomType').d('Room Type')]: reservation.roomTypeName,
            [intl.get('csv.unitName').d('Unit Name')]: reservation.unitName,
            [intl.get('csv.confirmationCode').d('Confirmation Code')]: reservation.code,
            [intl.get('csv.otaCode').d('OTA Confirmation Code')]: reservation.sourceId,
            [intl.get('csv.checkIn').d('Check-In')]: reservation.startDate,
            [intl.get('csv.checkOut').d('Check-Out')]: reservation.endDate,
            [intl.get('csv.bookedNight').d('Booked Nights')]: reservation.nights,
            [intl.get('csv.guest').d('Guest Name')]: reservation.guestName,
            [intl.get('csv.pax').d('Number of pax')]: reservation.numberOfPax,
            [intl.get('csv.rentalRevenue').d('Rental Revenue')]:
              reservation.revenue && reservation.revenue.rental ? Number(reservation.revenue.rental).toFixed(2) : 0,
            [intl.get('csv.cleaningRevenue').d('Cleaning Revenue')]:
              reservation.revenue && reservation.revenue.cleaning ? Number(reservation.revenue.cleaning).toFixed(2) : 0,
            [intl.get('csv.addOnRevenue').d('Add On Revenue')]: addOnRevenue ? Number(addOnRevenue).toFixed(2) : 0,
            [intl.get('csv.platformFee').d('Platform Fee')]: reservation && reservation.platformFee ? Number(reservation.platformFee).toFixed(2) : 0,
            [intl.get('csv.otherRevenue').d('Other Revenue')]: otherRevenue ? Number(otherRevenue).toFixed(2) : 0,
            [intl.get('csv.sst').d('Sales Service Tax')]: reservation.taxes && reservation.taxes.sst ? Number(reservation.taxes.sst).toFixed(2) : 0,
            [intl.get('csv.tourism').d('Tourism Tax')]:
              reservation.taxes && reservation.taxes.tourism ? Number(reservation.taxes.tourism).toFixed(2) : 0,
            [intl.get('csv.heritage').d('Heritage Tax')]:
              reservation.taxes && reservation.taxes.heritage ? Number(reservation.taxes.heritage).toFixed(2) : 0,
            [intl.get('csv.otaTax').d('OTA Tax')]: reservation.taxes && reservation.taxes.ota ? Number(reservation.taxes.ota).toFixed(2) : 0,
            [intl.get('csv.currency').d('Currency')]: reservation.currency
          };
        });
        this.setState({
          monthlyRevenueCSV: formattedReservations,
          fetchingMonthlyRevenue: false
        });
      })
      .catch(e => {
        notification.error({
          message: 'There is an error when fetching data',
          description: `Message: ${e.message}`
        });
        this.setState({
          fetchingMonthlyRevenue: false
        });
      });
  };

  handleOnFetchMonthlyExpensesCsvData = async () => {
    const { year, month } = this.state;
    const dateString = `${year}-${month + 1}`;
    this.setState({
      fetchingMonthlyExpenses: true
    });
    const propertyId = await this.getSelectedPropertyId();
    getMonthlyExpenses(propertyId, dateString)
      .then(res => {
        const unitExpenses = res.data;
        const formattedExpenses = unitExpenses.map(currentUnitExpenses => {
          return {
            [intl.get('csv.propertyName').d('Property Name')]: currentUnitExpenses.propertyName,
            [intl.get('csv.roomType').d('Room Type')]: currentUnitExpenses.roomTypeName,
            [intl.get('csv.unitName').d('Unit Name')]: currentUnitExpenses.unitName,
            [intl.get('csv.electricity').d('Electricity')]:
              currentUnitExpenses && currentUnitExpenses.electricity ? Number(currentUnitExpenses.electricity).toFixed(2) : 0,
            [intl.get('csv.water').d('Water')]: currentUnitExpenses && currentUnitExpenses.water ? Number(currentUnitExpenses.water).toFixed(2) : 0,
            [intl.get('csv.internet').d('Internet')]:
              currentUnitExpenses && currentUnitExpenses.internet ? Number(currentUnitExpenses.internet).toFixed(2) : 0,
            [intl.get('csv.cleaning').d('Cleaning')]:
              currentUnitExpenses && currentUnitExpenses.cleaning ? Number(currentUnitExpenses.cleaning).toFixed(2) : 0,
            [intl.get('csv.laundry').d('Laundry')]:
              currentUnitExpenses && currentUnitExpenses.laundry ? Number(currentUnitExpenses.laundry).toFixed(2) : 0,
            [intl.get('csv.service').d('Service')]:
              currentUnitExpenses && currentUnitExpenses.service ? Number(currentUnitExpenses.service).toFixed(2) : 0,
            [intl.get('csv.checkIn').d('Check In')]:
              currentUnitExpenses && currentUnitExpenses.checkIn ? Number(currentUnitExpenses.checkIn).toFixed(2) : 0,
            [intl.get('csv.checkOut').d('Check Out')]:
              currentUnitExpenses && currentUnitExpenses.checkOut ? Number(currentUnitExpenses.checkOut).toFixed(2) : 0,
            [intl.get('csv.toiletrySets').d('Toiletry Sets')]:
              currentUnitExpenses && currentUnitExpenses.toiletry ? Number(currentUnitExpenses.toiletry).toFixed(2) : 0,
            [intl.get('csv.others').d('Others')]:
              currentUnitExpenses && currentUnitExpenses.others ? Number(currentUnitExpenses.others).toFixed(2) : 0,
            [intl.get('csv.currency').d('Currency')]: currentUnitExpenses && currentUnitExpenses.currency ? currentUnitExpenses.currency : 'RM'
          };
        });
        this.setState({
          monthlyExpensesBreakDownCSV: formattedExpenses,
          fetchingMonthlyExpenses: false
        });
      })
      .catch(e => {
        notification.error({
          message: 'There is an error when fetching data',
          description: `Message: ${e.message}`
        });
        this.setState({
          fetchingMonthlyExpenses: false
        });
      });
  };

  handleOnFetchOwnerHostProfitCsvData = async () => {
    const { year, month } = this.state;
    const dateString = `${year}-${month + 1}`;
    this.setState({ fetchingOwnerHostProfit: true });
    const propertyId = await this.getSelectedPropertyId();
    getOwnerHostProfitData(propertyId, dateString)
      .then(res => {
        const ownerHostProfitCSV = res.data.map(unitWithProfit => {
          return {
            [intl.get('csv.owner').d('Owner')]: unitWithProfit.ownerName,
            [intl.get('csv.propertyName').d('Property Name')]: unitWithProfit.propertyName,
            [intl.get('csv.roomType').d('Room Type')]: unitWithProfit.roomTypeName,
            [intl.get('csv.unitLabel').d('Unit')]: unitWithProfit.name,
            [intl.get('csv.totalRevenueAfterPlatform').d('Total Revenue After Platform Fee')]: Number(
              unitWithProfit.profit.host + unitWithProfit.profit.owner + unitWithProfit.expenses.host + unitWithProfit.expenses.owner
            ).toFixed(2),
            [intl.get('csv.hostRevenue').d('Host Revenue')]: Number(unitWithProfit.profit.host + unitWithProfit.expenses.host).toFixed(2),
            [intl.get('csv.ownerRevenue').d("Owner's Revenue")]: Number(unitWithProfit.profit.owner + unitWithProfit.expenses.owner).toFixed(2),
            [intl.get('csv.totalExpenses').d('Total Revenue')]: Number(unitWithProfit.expenses.host + unitWithProfit.expenses.owner).toFixed(2),
            [intl.get('csv.hostBorne').d('Host Borne Expense')]: Number(unitWithProfit.expenses.host).toFixed(2),
            [intl.get('csv.ownerBorne').d('Owner Borne Expense')]: Number(unitWithProfit.expenses.owner).toFixed(2),
            [intl.get('csv.hostNetProfit').d('Host Net Profit')]: Number(unitWithProfit.profit.host).toFixed(2),
            [intl.get('csv.ownerNetProfit').d('Owner Net Profit')]: Number(unitWithProfit.profit.owner).toFixed(2),
            [intl.get('csv.currency').d('Currency')]: unitWithProfit.currency
          };
        });
        this.setState({ ownerHostProfitCSV: ownerHostProfitCSV, fetchingOwnerHostProfit: false });
      })
      .catch(e => {
        notification.error({
          message: 'There is an error when fetching data',
          description: `Message: ${e.message}`
        });
        this.setState({
          fetchingOwnerHostProfit: false
        });
      });
  };

  handleOnFetchPlatformSourceRevenueCsvData = async () => {
    const { year, month } = this.state;
    const dateString = `${year}-${month + 1}`;
    this.setState({ fetchingPlatformSourceRevenue: true });
    const propertyId = await this.getSelectedPropertyId();
    getPlatformSourceRevenue(propertyId, dateString)
      .then(res => {
        const platformSourceRevenueCSV = res.data.map(platformSourceRevenue => {
          return {
            [intl.get('csv.bookingType').d('Booking Type')]: platformSourceRevenue.bookingType,
            [intl.get('csv.bookingSource').d('Booking Source')]: platformSourceRevenue.platform,
            [intl.get('csv.unitNum').d('Number Of Units')]: platformSourceRevenue.totalNumberOfUnits || 0,
            [intl.get('csv.nightsBooked').d('Nights Booked')]: platformSourceRevenue.totalNights || 0,
            [intl.get('csv.occupancyRate').d('Occupancy Rate (%)')]: Number((platformSourceRevenue.occupancyRate || 0) * 100).toFixed(2),
            [`${intl.get('csv.totalRevenue').d('Total Revenue')}`]: platformSourceRevenue.totalRevenue
              ? Number(platformSourceRevenue.totalRevenue).toFixed(2)
              : 0,
            [`${intl.get('csv.totalPlatformFee').d('Total Platform Fee')}`]: platformSourceRevenue.totalPlatformFee
              ? Number(platformSourceRevenue.totalPlatformFee).toFixed(2)
              : 0,
            [intl.get('csv.currency').d('Currency')]: platformSourceRevenue.currency
          };
        });
        this.setState({ platformSourceRevenueCSV, fetchingPlatformSourceRevenue: false });
      })
      .catch(e => {
        notification.error({
          message: 'There is an error when fetching data',
          description: `Message: ${e.message}`
        });
        this.setState({
          fetchingOwnerHostProfit: false
        });
      });
  };

  constructCsvFilename = (filename = '') => {
    const { year, month, property, propertyOptions } = this.state;
    const formattedMonth = month < 10 ? `0${month + 1}` : month + 1;
    const selectedProperty = propertyOptions.find(propertyOption => propertyOption.value === property);
    const propertyName = (selectedProperty && selectedProperty.label) || '';
    const prefix = `${year}_${formattedMonth}_${propertyName}__reporting overview`;
    return `${prefix}_${String(filename).toUpperCase()}.csv`;
  };

  render() {
    const {
      revenueData,
      expensesData,
      profitData,
      weeklyOccupancy,
      monthlyOccupancy,
      yearlyOccupancy,
      roomTypeOccupancy,
      sourceOccupancy,
      weeklyRevenue,
      monthlyRevenue,
      yearlyRevenue,
      roomTypeRevenue,
      sourceRevenue,
      occupancyWeekDate,
      revenueWeekDate,
      isLoading,
      fetchingMonthlyRevenue,
      fetchingMonthlyExpenses,
      fetchingOwnerHostProfit,
      fetchingPlatformSourceRevenue,
      monthlyRevenueCSV,
      monthlyExpensesBreakDownCSV,
      ownerHostProfitCSV,
      platformSourceRevenueCSV,
      isReportFreeze,
      isShowEditButton,
      // isConfirmed,
      month,
      year
    } = this.state;

    const { checkIsAdminReadOnly } = this.props;
    //console.log(weeklyRevenue)

    return (
      <div className="gutterWidth">
        <Row type="flex" /* justify="end" */ justify="space-between" className="rep-title" align="middle">
          <Col className="rep-wrapper">
            {isShowEditButton && (
              <>
                <label className="el-pageTitle">{intl.get('reporting.headerLabels.auditReport').d('Audit Report:')} </label>
                {/* <Button type={isReportFreeze ? 'primary' : 'default'} onClick={this.handleOnReportFreeze}>
                  {isReportFreeze ? 'Audit' : 'Edit'}
                </Button> */}
                <Switch
                  checked={isReportFreeze}
                  disabled={checkIsAdminReadOnly()}
                  onClick={e => {
                    if (e) {
                      Modal.confirm({
                        centered: true,
                        okText: intl.get('reporting.headerLabels.confirm').d('Confirm'),
                        title: intl.get('reporting.headerLabels.auditTitle').d('Audit Confirmation'),
                        content: `${intl.get('reporting.headerLabels.auditContent').d('Confirm to Audit')} ${intl
                          .get(`payout.months.${moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM')}`)
                          .d(moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM'))} ${moment(month + 1 + '/' + year, 'M/YYYY').format(
                          'YYYY'
                        )} ${intl.get('reporting.headerLabels.report').d('report?')}`,
                        onOk: this.handleOnReportFreeze
                      });
                    } else {
                      Modal.confirm({
                        centered: true,
                        okText: intl.get('reporting.headerLabels.confirm').d('Confirm'),
                        title: intl.get('reporting.headerLabels.offTitle').d('Turn Off Audit'),
                        content: `${intl.get('reporting.headerLabels.offContent').d('Turning off Audit will update the')} ${intl
                          .get(`payout.months.${moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM')}`)
                          .d(moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM'))} ${moment(month + 1 + '/' + year, 'M/YYYY').format(
                          'YYYY'
                        )} ${intl.get('reporting.headerLabels.offContent2').d('report based on current settings. Confirm turn off audit?')}`,
                        onOk: this.handleOnReportFreeze
                      });
                    }
                  }}
                />
              </>
            )}
          </Col>
          <Col>
            <Row type="flex">
              <Col className="rep-wrapper">
                <MonthPicker
                  todayButtonId="today-date-button3-report"
                  label={intl.get('reporting.headerLabels.month').d('Month: ')}
                  defaultValue={moment(constructReadableYearMonthFormat(year, month))}
                  disabled={isLoading}
                  onChange={this.handleOnMonthChange}
                />
              </Col>
              <Col className="rep-wrapper">
                <label className="el-pageTitle">{intl.get('reporting.headerLabels.property').d('Property:')} </label>
                <Select
                  showSearch
                  optionFilterProp="children"
                  className="select"
                  value={this.state.property}
                  onChange={this.handleOnPropertyChange}
                  name="property"
                  style={{ width: 230 }}
                  loading={isLoading}
                >
                  {this.state.propertyOptions.map(location => (
                    <Option value={location.value} key={location.value}>
                      {location.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: '0px', marginTop: '-15px' }}>
          <TabPane tab={intl.get('reporting.headerLabels.reporting').d('Reporting')} key="1" className="rep-container">
            <Row gutter={16} type="flex" justify="center">
              <Col span={24} lg={8} className="rep-card">
                <SummaryCard
                  title={intl.get('reporting.chartName.revTrend').d('Revenue Trend')}
                  currentAmount={revenueData && revenueData.length > 0 ? revenueData[revenueData.length - 1].Revenue : 0}
                  diffAmount={
                    revenueData && revenueData.length > 1
                      ? revenueData[revenueData.length - 1].Revenue - revenueData[revenueData.length - 2].Revenue
                      : 0
                  }
                  isLoading={isLoading}
                  currency={this.state.currency}
                  timezone={this.state.timezone}
                >
                  <LineGraph
                    height={400}
                    graphData={revenueData}
                    fields={['Revenue']}
                    xAxisName="month"
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                  />
                </SummaryCard>
              </Col>
              <Col span={24} lg={8} className="rep-card">
                <SummaryCard
                  title={intl.get('reporting.chartName.expenseTrend').d('Expenses Trend')}
                  currentAmount={getTotalAmount(expensesData, expensesData.length - 1)}
                  shouldInvertDiffAmountColor
                  diffAmount={getTotalAmount(expensesData, expensesData.length - 1) - getTotalAmount(expensesData, expensesData.length - 2)}
                  isLoading={isLoading}
                  currency={this.state.currency}
                  timezone={this.state.timezone}
                >
                  <Histogram
                    height={400}
                    xAxisName="month"
                    fields={['Host', 'Owner']}
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                    graphData={expensesData}
                  />
                </SummaryCard>
              </Col>
              <Col span={24} lg={8} className="rep-card">
                <SummaryCard
                  title={intl.get('reporting.chartName.profitTrend').d('Profit Trend')}
                  currentAmount={getTotalAmount(profitData, profitData.length - 1)}
                  diffAmount={getTotalAmount(profitData, profitData.length - 1) - getTotalAmount(profitData, profitData.length - 2)}
                  isLoading={isLoading}
                  currency={this.state.currency}
                  timezone={this.state.timezone}
                >
                  <Histogram
                    height={400}
                    xAxisName="month"
                    fields={['Host', 'Owner']}
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                    graphData={profitData}
                  />
                </SummaryCard>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={intl.get('reporting.headerLabels.revenue').d('Revenue')} key="2">
            <Row className="rep-container" type="flex" justify="center">
              <Col span={24} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.dailyRev').d('Daily Revenue Comparison')}>
                  <LineGraph
                    height={400}
                    graphData={monthlyRevenue}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                  />
                </ComparisonCard>
              </Col>
            </Row>
            <Row gutter={16} className="rep-container" type="flex" justify="start">
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard
                  title={intl.get('reporting.chartName.weeklyRev').d('Weekly Revenue Comparison')}
                  date={revenueWeekDate}
                  onDateChange={this.handleOnRevenueWeekChange}
                  isLoading={isLoading}
                >
                  <LineGraph
                    height={400}
                    graphData={weeklyRevenue}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                  />
                </ComparisonCard>
              </Col>
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.monthlyRev').d('Monthly Revenue Comparison')}>
                  <LineGraph
                    height={400}
                    graphData={yearlyRevenue}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${this.state.currency}${numberWithCommas(val, 0)}`}
                  />
                </ComparisonCard>
              </Col>
            </Row>
            <Row gutter={16} className="rep-container" type="flex" justify="start">
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.revByRoomType').d('Revenue By Room Type')}>
                  {roomTypeRevenue && roomTypeRevenue.length > 0 ? (
                    <PieChart graphData={roomTypeRevenue} field="revenue" dimension="type" />
                  ) : (
                    <div className="no-data-reporting-container">{intl.get('reporting.headerLabels.noData').d('No Data')}</div>
                  )}
                </ComparisonCard>
              </Col>
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.revByBookingSource').d('Revenue By Booking Source')}>
                  {sourceRevenue && sourceRevenue.length > 0 ? (
                    <PieChart graphData={sourceRevenue} field="revenue" dimension="type" isCustomColor />
                  ) : (
                    <div className="no-data-reporting-container">{intl.get('reporting.headerLabels.noData').d('No Data')}</div>
                  )}
                </ComparisonCard>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={intl.get('reporting.headerLabels.occupancy').d('Occupancy')} key="3">
            <Row className="rep-container" type="flex" justify="center">
              <Col span={24} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.dailyOccupancy').d('Daily Occupancy Comparison')}>
                  <LineGraph
                    height={400}
                    graphData={monthlyOccupancy}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${val}%`}
                  />
                </ComparisonCard>
              </Col>
            </Row>
            <Row gutter={16} className="rep-container" type="flex" justify="start">
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard
                  isLoading={isLoading}
                  title={intl.get('reporting.chartName.weeklyOccupancy').d('Weekly Occupancy Comparison')}
                  date={occupancyWeekDate}
                  onDateChange={this.handleOnOccupancyWeekChange}
                >
                  <LineGraph
                    height={400}
                    graphData={weeklyOccupancy}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${val}%`}
                  />
                </ComparisonCard>
              </Col>
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.monthlyOccupancy').d('Monthly Occupancy Comparison')}>
                  <LineGraph
                    height={400}
                    graphData={yearlyOccupancy}
                    fields={['Current', 'Previous']}
                    xAxisName="date"
                    yAxisLabelFormatter={val => `${val}%`}
                  />
                </ComparisonCard>
              </Col>
            </Row>
            <Row gutter={16} className="rep-container" type="flex" justify="start">
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.bookingsByRoomType').d('Bookings By Room Type')}>
                  {roomTypeOccupancy && roomTypeOccupancy.length > 0 ? (
                    <PieChart graphData={roomTypeOccupancy} field="count" dimension="type" />
                  ) : (
                    <div className="no-data-reporting-container">{intl.get('reporting.headerLabels.noData').d('No Data')}</div>
                  )}
                </ComparisonCard>
              </Col>
              <Col span={24} lg={12} className="rep-card">
                <ComparisonCard isLoading={isLoading} title={intl.get('reporting.chartName.bookingsByBookingSource').d('Bookings By Booking Source')}>
                  {sourceOccupancy && sourceOccupancy.length > 0 ? (
                    <PieChart graphData={sourceOccupancy} field="count" dimension="type" isCustomColor />
                  ) : (
                    <div className="no-data-reporting-container">{intl.get('reporting.headerLabels.noData').d('No Data')}</div>
                  )}
                </ComparisonCard>
              </Col>
            </Row>
          </TabPane>
          {this.props.checkAbleExportReport() && (
            <TabPane tab={intl.get('reporting.headerLabels.download').d('Download')} key="4">
              <Row className="rep-container">
                <Col span={24} lg={8} className="rep-card">
                  <Card>
                    <CSVDownload
                      onDownloadClick={this.handleOnFetchMonthlyRevenueCsvData}
                      fileName={this.constructCsvFilename(this.monthlyRevenueName)}
                      title={this.monthlyRevenueName}
                      isLoading={fetchingMonthlyRevenue}
                      data={monthlyRevenueCSV}
                      disabled={checkIsAdminReadOnly()}
                    />
                    <CSVDownload
                      onDownloadClick={this.handleOnFetchMonthlyExpensesCsvData}
                      fileName={this.constructCsvFilename(this.monthlyExpensesName)}
                      title={this.monthlyExpensesName}
                      isLoading={fetchingMonthlyExpenses}
                      data={monthlyExpensesBreakDownCSV}
                      disabled={checkIsAdminReadOnly()}
                    />
                    <CSVDownload
                      onDownloadClick={this.handleOnFetchOwnerHostProfitCsvData}
                      fileName={this.constructCsvFilename(this.ownerHostProfitName)}
                      title={this.ownerHostProfitName}
                      isLoading={fetchingOwnerHostProfit}
                      data={ownerHostProfitCSV}
                      disabled={checkIsAdminReadOnly()}
                    />
                    <CSVDownload
                      onDownloadClick={this.handleOnFetchPlatformSourceRevenueCsvData}
                      fileName={this.constructCsvFilename(this.platformSourceRevenueName)}
                      title={this.platformSourceRevenueName}
                      isLoading={fetchingPlatformSourceRevenue}
                      data={platformSourceRevenueCSV}
                      disabled={checkIsAdminReadOnly()}
                    />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
export default withAppContext(Reporting);
