import React from 'react';
import { AutoComplete, Input, Icon } from 'antd';
import intl from 'react-intl-universal';

class AutoSearch extends React.Component {
  state = {
    filteredDataSource: []
  };
  handleSearch = dataSource => value => {
    if (value) {
      const regex = new RegExp(value);
      this.setState({
        filteredDataSource: [value]
          .concat(dataSource.filter(dataSource => regex.test(dataSource.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))))
          .reduce((uniqueArr, currentValue) => {
            if (!uniqueArr.includes(currentValue)) {
              uniqueArr.push(currentValue);
            }
            return uniqueArr;
          }, [])
      });
    } else {
      this.setState({
        filteredDataSource: dataSource
      });
    }
  };

  render() {
    const { dataSource, onSelect, defaultValue, disabled } = this.props;
    const { filteredDataSource } = this.state;
    return (
      <div>
        <AutoComplete
          disabled={disabled}
          defaultValue={defaultValue}
          dataSource={filteredDataSource}
          style={{ width: '100%' }}
          onSelect={onSelect}
          onSearch={this.handleSearch(dataSource)}
          placeholder={intl.get('hostConnect.integration.placeholder.propertyId').d('Input Property ID here.')}
        >
          <Input suffix={<Icon type="search" className="certain-category-icon" />} />
        </AutoComplete>
      </div>
    );
  }
}
AutoSearch.defaultProps = {
  onSelect: value => {
    console.log(value);
  },
  defaultValue: '',
  disabled: false
};

export default AutoSearch;
