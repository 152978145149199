import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Col } from 'antd';

import FormSelection from '../FormSelection/FormSelection';

import styles from './AmenitiesSelection.module.css';
import intl from 'react-intl-universal';

const AmenitiesSelection = ({ form, items, title, fieldName, icon, defaultValue }) => {
  return (
    <Col span={24} md={11} className={styles.formItem}>
      <Card
        headStyle={{ backgroundColor: '#CBF2F4' }}
        title={
          <div className={styles.amenitiesTitle}>
            <Icon className={styles.amenitiesIcon} type={icon} /> {intl.get(`listings.property.amnetitiesType.${title}`).d('Amnetities Type')}
          </div>
        }
        bordered={true}
      >
        <FormSelection
          multipleMode
          name={fieldName}
          label={intl.get(`listings.property.amnetitiesType.${title}`).d('Amnetities Type')}
          placeholder={
            intl.get('listings.property.please').d('Please select ') +
            intl.get(`listings.property.amnetitiesType.${title}`) +
            intl.get('listings.property.amnetitiesName').d(' amenities')
          }
          form={form}
          defaultValue={defaultValue}
          selections={items}
          size="large"
        />
      </Card>
    </Col>
  );
};

AmenitiesSelection.propTypes = {
  form: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  icon: PropTypes.string
};

AmenitiesSelection.defaultProps = {
  defaultValue: [],
  icon: ''
};

export default AmenitiesSelection;
