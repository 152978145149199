import React from 'react';
import { Modal, DatePicker, message } from 'antd';
import moment from 'moment';
import intl from 'react-intl-universal';

import { updateReservation } from 'utils/apis/reservation';

import styles from './MCModalReservationOnDrop.module.css';

const drawModalUnableToMoveBlockedByReservation = reservationRemarks => {
  const blockedBy = reservationRemarks.substring(11);
  const processedReservationBlockedBy = <span className={`${styles.targetUnitNameColor}`}>{blockedBy}</span>;

  const title = (
    <>
      {intl.get('multicalendar.message.cannotMove').d('Cannot Move a System Generated Block')}
      <br />
      <br />
    </>
  );
  const content = (
    <>
      {intl.get('multicalendar.message.cannotMoveMsg').d('This block is generated by system automatically when parent/child unit has a reservation.')}
      <br />
      <br />
      {intl.get('multicalendar.message.cannotMoveMsg2').d(`Please update the linked reservation in`)} {processedReservationBlockedBy}{' '}
      {intl.get('multicalendar.message.cannotMoveMsg3').d(`to remove this block.`)}
    </>
  );

  return Modal.error({
    title: title,
    content: content
  });
};

const drawModalUnableToMoveReservationBetweenProperty = () => {
  const title = <>{intl.get('multicalendar.message.error').d('Error')}</>;
  const content = <>{intl.get('multicalendar.message.cannotMoveToAnotherProperty').d('Cannot move a reservation to another property.')}</>;

  return Modal.error({
    title: title,
    content: content
  });
};

const drawModalUnableToUpdateOTAReservationDate = (reservationCode, reservationPlatform) => {
  const processedReservationCode = <span className={`${styles.reservationCodeColor}`}>{reservationCode}</span>;
  const processedReservationPlatform = <span className={`${styles.reservationPlatformColor}`}>{reservationPlatform}</span>;
  const processedHostPlatform = <span className={`${styles.reservationPlatformColor}`}>Host Platform</span>;

  const title = (
    <>
      <span>{intl.get('multicalendar.message.cannotChangeOTAReservation').d(`Cannot Change OTA's Reservation Date`)}</span>
      <br />
      <br />
    </>
  );
  const content = (
    <>
      <span>
        {intl.get('multicalendar.message.confirmationCode').d('Confirmation Code:')} {processedReservationCode}
      </span>
      <br />
      <span>
        {intl.get('multicalendar.message.source').d('Source:')} {processedReservationPlatform}
      </span>
      <br />
      <br />
      {intl.get('multicalendar.message.cannotChangeOTAReservationMsg').d('This reservation is made in')} {processedReservationPlatform}.{' '}
      {intl.get('multicalendar.message.cannotChangeOTAReservationMsg2').d('Changing reservation date is only allow for reservation made in')}{' '}
      {processedHostPlatform}.
      <br />
      <br />
      {intl.get('multicalendar.message.cannotChangeOTAReservationMsg3').d('Please change the date in the')} {processedReservationPlatform}{' '}
      {intl.get('multicalendar.message.cannotChangeOTAReservationMsg4').d('website.')}
    </>
  );

  return Modal.error({
    title: title,
    content: content
  });
};

const drawModalUpdateListingOTAReservation = (props, reservationCode, reservationPlatform) => {
  const processedReservationCode = <span className={`${styles.reservationCodeColor}`}>{reservationCode}</span>;
  const processedReservationPlatform = <span className={`${styles.reservationPlatformColor}`}>{reservationPlatform}</span>;

  const title = (
    <>
      <span>
        {intl.get('multicalendar.message.moveReservation').d('Moving')} {processedReservationPlatform}{' '}
        {intl.get('multicalendar.message.moveReservation2').d('Reservation to Another Unit')}
      </span>
      <br />
      <br />
    </>
  );

  const content = (
    <>
      <span>
        {intl.get('multicalendar.message.confirmationCode').d('Confirmation Code:')} {processedReservationCode}
      </span>
      <br />
      <br />
      {intl.get('multicalendar.message.moveReservationMsg1').d('Changing the reservation here will not update the reservation in')}{' '}
      {processedReservationPlatform} {intl.get('multicalendar.message.moveReservationMsg2').d(', but create a block instead.')}
      <br />
      <br />
      <span className={`${styles.warningColor}`}>
        {intl.get('multicalendar.message.moveReservationMsg3').d('This will result blocking in both unit, for the selected date range.')}
      </span>
      {intl.get('multicalendar.message.moveReservationMsg4').d('Please update in the')} {processedReservationPlatform}{' '}
      {intl.get('multicalendar.message.moveReservationMsg5').d('website if possible.')}
    </>
  );

  return Modal.confirm({
    title: title,
    content: content,
    okType: 'danger',
    okText: intl.get('hostConnect.integration.headerLabels.continue').d('Continue'),
    cancelText: intl.get('multicalendar.headerLabels.cancel').d('Cancel'),
    onOk() {
      drawModalConfirmMovingReservation(props);
    }
  });
};

const drawModalClashedReservation = props => {
  const { reservationCode, targetUnitName } = props;
  const processedReservationCode = <span className={`${styles.reservationCodeColor}`}>{reservationCode}</span>;
  const processedTargetUnitName = <span className={`${styles.targetUnitNameColor}`}>{targetUnitName}</span>;

  const title = (
    <>
      <span>{intl.get('multicalendar.message.clashingReservation').d('Clashing Reservation')}</span>
      <br />
      <br />
    </>
  );
  const content = (
    <>
      {intl.get('multicalendar.message.clashingMsg').d('You are moving a reservation with confirmation code')} {processedReservationCode}{' '}
      {intl.get('multicalendar.message.clashingMsg2').d('to')} {processedTargetUnitName}.
      <br />
      {intl
        .get('multicalendar.message.clashingMsg3')
        .d('This will cause double booking until one of the reservation is updated, do you wish to continue?')}
    </>
  );

  return Modal.confirm({
    title: title,
    content: content,
    icon: 'danger',
    okType: 'danger',
    okText: intl.get('listings.bookingEngine.placeholder.yes').d('Yes'),
    cancelText: intl.get('listings.bookingEngine.placeholder.no').d('No'),
    onOk() {
      drawModalConfirmMovingReservation(props);
    }
  });
};

const drawModalConfirmMovingReservation = props => {
  const {
    reservationId,
    reservationCode,
    targetUnitName,
    targetUnitId,
    roomTypeId,
    reservationStartDate,
    reservationEndDate,
    isMovingListingOtaToDifferentUnit,
    handleOnMCRateDateActionSuccess
  } = props;
  const DATE_FORMAT = 'YYYY-MM-DD';

  const processedReservationCode = <span className={`${styles.reservationCodeColor}`}>{reservationCode}</span>;
  const processedTargetUnitName = <span className={`${styles.targetUnitNameColor}`}>{targetUnitName}</span>;

  const processedReservationStartDate = moment(reservationStartDate, DATE_FORMAT);
  const processedReservationEndDate = moment(reservationEndDate, DATE_FORMAT);
  let updatedReservationStartDate = reservationStartDate;
  let updatedReservationEndDate = reservationEndDate;

  const title = (
    <>
      <span>{intl.get('multicalendar.message.movingReservation').d('Moving Reservation')}</span>
      <br />
    </>
  );
  const content = (
    <>
      <span>
        {intl.get('multicalendar.message.confirmationCode').d('Confirmation Code:')} {processedReservationCode}
      </span>
      <br />
      {intl.get('multicalendar.message.movingReservationMsg').d(`Do you wish to move the reservation to`)} {processedTargetUnitName} ?
      <br />
      <DatePicker.RangePicker
        className={`${styles.confirmMovingReservationRangePicker}`}
        defaultValue={[processedReservationStartDate, processedReservationEndDate]}
        disabled={isMovingListingOtaToDifferentUnit}
        onChange={(value, dateString) => {
          updatedReservationStartDate = dateString[0];
          updatedReservationEndDate = dateString[1];
        }}
      />
    </>
  );

  return Modal.confirm({
    title: title,
    content: content,
    onOk() {
      if (updatedReservationStartDate === updatedReservationEndDate) {
        updatedReservationEndDate = moment(updatedReservationStartDate)
          .add(1, 'days')
          .format(DATE_FORMAT);
      }
      const body = {
        startDate: updatedReservationStartDate,
        endDate: updatedReservationEndDate,
        unit: targetUnitId
      };

      const isIgnoreClash = true;
      updateReservation(reservationId, body, isIgnoreClash)
        .then(res => {
          if (res && res.status === 200) {
            handleOnMCRateDateActionSuccess({
              currentRoomTypeId: roomTypeId,
              selectedDate: body.startDate
            });
            message.success(intl.get('multicalendar.message.reservationUpdated').d('Your reservation has been updated'));
          } else {
            message.error(intl.get('multicalendar.message.reservationError').d('Error when updating reservation, please contact our support'));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  });
};

const MCModalReservationOnDrop = props => {
  const {
    reservation,
    isClashed,
    isUpdateToDifferentProperty,
    isUpdateToDifferentUnit,
    isUpdateToDifferentDate,
    isListingOta,
    isIntegratedOta,
    integratedOtaLabel,
    blockedBy,
    roomTypeId,
    handleOnMCRateDateActionSuccess
  } = props;
  const { _id, code, targetUnitId, targetUnitName, startDate, endDate, remarks } = reservation;

  const isNotMovingReservation = !isUpdateToDifferentUnit && !isUpdateToDifferentDate;
  const isIntegratedOtaUpdatingDate = isIntegratedOta && isUpdateToDifferentDate;
  const isMovingListingOtaToDifferentUnit = isUpdateToDifferentUnit && isListingOta;
  const reservationProps = {
    reservationId: _id,
    reservationCode: code,
    targetUnitName,
    targetUnitId,
    roomTypeId,
    reservationStartDate: startDate,
    reservationEndDate: endDate,
    isMovingListingOtaToDifferentUnit,
    handleOnMCRateDateActionSuccess
  };

  let drawModal = Modal;
  if (isNotMovingReservation) {
    // do not pop any modal if the reservation is not moving anywhere
  } else {
    if (blockedBy) {
      drawModal = drawModalUnableToMoveBlockedByReservation(remarks);
    } else if (isUpdateToDifferentProperty) {
      drawModal = drawModalUnableToMoveReservationBetweenProperty();
    } else if (isIntegratedOtaUpdatingDate) {
      drawModal = drawModalUnableToUpdateOTAReservationDate(code, integratedOtaLabel);
    } else if (isMovingListingOtaToDifferentUnit) {
      drawModal = drawModalUpdateListingOTAReservation(reservationProps, code, integratedOtaLabel);
    } else if (isClashed) {
      drawModal = drawModalClashedReservation(reservationProps);
    } else {
      drawModal = drawModalConfirmMovingReservation(reservationProps);
    }
  }

  return drawModal;
};

export default MCModalReservationOnDrop;
