import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Icon, Table, Tooltip } from 'antd';

import circledS from 'images/circled-s.png';
import { useGetPaginatedUnitListings } from 'utils/apis/unit';
import { buildListingDetailsUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import intl from 'react-intl-universal';
import { generateDisplayFee } from 'utils/general';

const getUnitRenderProp = checkIsAdminReadOnly => ({
  render: (text, record) => {
    const isCreatedFromStaySuites = record.isCreatedFromStaySuites;
    const hasProtection = record.hasProtection;
    const isProtectionActive = record.isProtectionActive;

    const title = `HostProtect ${
      hasProtection
        ? isProtectionActive
          ? intl.get('listings.headerLabels.activate').d('Activated')
          : intl.get('listings.headerLabels.expired').d('Expired')
        : intl.get('listings.headerLabels.notActivated').d('Not Activated')
    }`;
    const type = hasProtection ? (isProtectionActive ? 'check-circle' : 'clock-circle-o') : 'warning';
    const twoToneColor = hasProtection && isProtectionActive ? '#52c41a' : '#db4437';

    return (
      <div>
        {isCreatedFromStaySuites && (
          <Tooltip title="This unit is created from StaySuites">
            <Avatar key={`isCreatedFromStaySuites - ${record._id}`} size={16} src={circledS} style={{ marginBottom: '4px' }} />{' '}
          </Tooltip>
        )}
        <Tooltip title={title}>
          <Icon type={type} theme="twoTone" twoToneColor={twoToneColor} />{' '}
        </Tooltip>
        <Link to={buildListingDetailsUri(record._id)}>{text}</Link>
      </div>
    );
  }
});

const constructColumns = ({ filters, sorter }, checkIsAdminReadOnly) => [
  {
    ...constructColumn(intl.get('listings.tableColumns.unit').d('Unit Name'), 'unitName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('unitName', intl.get('tables.unit').d('Search Unit Name'), filters),
    ...getUnitRenderProp(checkIsAdminReadOnly)
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.property').d('Property Name'), 'propertyName'),
    ...constructColumnFilterSearch('propertyName', intl.get('tables.property').d('Search Property Name'), filters)
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.roomType').d('Room Type Name'), 'roomTypeName'),
    ...constructColumnFilterSearch('roomTypeName', intl.get('tables.roomType').d('Search Room Type Name'), filters)
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.weekday').d('Weekday Price'), 'weekdayPrice', { isRM: false }),
    render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.weekend').d('Weekend Price'), 'weekendPrice', { isRM: false }),
    render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.host').d('Host Name'), 'hostName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('hostName', intl.get('tables.hostName').d('Search Host'), filters)
  },
  {
    ...constructColumn(intl.get('listings.tableColumns.owner').d('Owner Name'), 'ownerName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('ownerName', intl.get('tables.owner').d('Search Owner Name'), filters)
  }
];

const Body = ({ query, tableQuery, onTableChange, checkIsAdminReadOnly }) => {
  const { paginatedData: units, isLoading, total } = useGetPaginatedUnitListings(query);

  return (
    <Table
      rowKey={record => record._id}
      dataSource={units}
      columns={constructColumns(tableQuery, checkIsAdminReadOnly)}
      scroll={{ x: 1000 }}
      onChange={(pagination, filters, sorter) => onTableChange({ pagination, filters, sorter })}
      pagination={{ total }}
      loading={isLoading}
    />
  );
};

Body.propTypes = {
  query: PropTypes.object.isRequired,
  tableQuery: PropTypes.object,
  onTableChange: PropTypes.func.isRequired
};

export default Body;
