import React, { Component } from 'react';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';
import DataSet from '@antv/data-set';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

class Histogram extends Component {
  static propTypes = {
    height: PropTypes.number,
    graphData: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired
  };

  componentDidMount() {
    // Refer to this issue: https://github.com/alibaba/BizCharts/issues/279#issuecomment-400438180
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { height, graphData, fields, xAxisName, yAxisLabelFormatter } = this.props;
    const ds = new DataSet();
    const dv = ds.createView().source(graphData);

    const mapToLang = name => intl.get(`reporting.monthAbbr.${name}`).d(name);

    dv.transform({
      type: 'fold',
      fields,
      key: 'key',
      value: 'value'
    }).transform({
      type: 'map',
      callback: obj => {
        if (obj.key === 'Host') {
          obj.key = intl.get('reporting.headerLabels.host').d('Host');
        }
        if (obj.key === 'Owner') {
          obj.key = intl.get('reporting.headerLabels.owner').d('Owner');
        }
        return obj;
      }
    });
    return (
      <Chart height={height} data={dv} forceFit>
        <Legend
          textStyle={{
            fontSize: '14'
          }}
        />
        <Axis
          name={xAxisName}
          label={{
            formatter: mapToLang // Use the mapToChinese function to display Chinese month names
          }}
        />
        <Axis
          name="value"
          label={{
            formatter: yAxisLabelFormatter
          }}
        />
        <Tooltip />
        <Geom type="intervalStack" position="month*value" color={'key'} />
      </Chart>
    );
  }
}

export default Histogram;
