import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import AmenitiesSelection from 'components/AmenitiesSelection/AmenitiesSelection';
import intl from 'react-intl-universal';

import styles from './Amenities.module.css';

class Amenities extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    onBack(allFieldsValue);
  };

  render() {
    const { defaultValues, form, hasFetchedRoomTypeAmenities, isLoading, isLastPage, percentage, roomTypeAmenities, title } = this.props;

    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
        showNextButtonLoading={isLoading}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>{intl.get('listings.roomType.headerLabels.amnetities').d('What amenities do your room provides?')}</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          {hasFetchedRoomTypeAmenities &&
            roomTypeAmenities &&
            Object.keys(roomTypeAmenities).map(key => (
              <AmenitiesSelection
                form={form}
                key={key}
                title={key}
                fieldName={String(key).toLowerCase()}
                items={roomTypeAmenities[key].data}
                icon={roomTypeAmenities[key].icon}
                defaultValue={defaultValues[key]}
              />
            ))}
        </Row>
      </ListingWizardLayout>
    );
  }
}

Amenities.propTypes = {
  form: PropTypes.object.isRequired,
  hasFetchedRoomTypeAmenities: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  roomTypeAmenities: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func
};

Amenities.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isLastPage: false
};

const WrappedFormAmenities = Form.create()(Amenities);

export default WrappedFormAmenities;
