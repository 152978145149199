module.exports = {
  REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://localhost:8000/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'https://nebulapi-asg.hostplatform.com/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://192.168.0.23:8000/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://172.16.50.58:8000/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://172.16.50.81:8005/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://localhost:8000/v1',
  REACT_APP_BOOKING_ENGINE_URL: process.env.REACT_APP_BOOKING_ENGINE_URL || 'http://localhost:3002',
  REACT_APP_HOTEL_BOOKING_ENGINE_URL: process.env.REACT_APP_HOTEL_BOOKING_ENGINE_URL || 'http://localhost:3002',
  REACT_APP_IS_SHOW_TRAVELOKA: process.env.REACT_APP_IS_SHOW_TRAVELOKA || true,
  REACT_APP_IS_SHOW_TIKETCOM: process.env.REACT_APP_IS_SHOW_TIKETCOM || true,
  REACT_APP_IS_SHOW_INVENTORY: process.env.REACT_APP_IS_SHOW_INVENTORY || true,
  REACT_APP_IS_SHOW_PROMOTION: process.env.REACT_APP_IS_SHOW_PROMOTION || true,
  REACT_APP_IS_SHOW_ADDON: process.env.REACT_APP_IS_SHOW_ADDON || true,

  REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT: process.env.REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT,
  REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS: process.env.REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS
};
