import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Col, Form, message, Modal, Row, Select, Skeleton, DatePicker, Input } from 'antd';
import { getProperties } from 'utils/apis/property';
import { DATE_FORMAT } from 'utils/constants';

import './McModalBlockWizard.css';
import { createReservation, getUnitListings, deleteMultipleBlockReservation } from 'utils/apis/reservation';
import intl from 'react-intl-universal';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const latestAvailableUpdateDate = moment().add(729, 'days');
const BOOKINGTYPE_MAINTENANCE = 5;
const BOOKINGTYPE_BLOCK = 6;

const getDisabledDate = currentDate => {
  const isDisable =
    currentDate <
      moment()
        .subtract(1, 'day')
        .endOf('day') || currentDate > latestAvailableUpdateDate;

  return isDisable;
};

class McModalBlockWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedPropertyName: '',
      selectedRoomType: '',
      selectedUnit: [],
      selectedDateRange: [],
      selectedDateRangeString: [],
      unitOptions: [],
      roomTypeAndUnits: [],
      fullUnitOptions: [],
      roomTypeOptions: [],
      isNoUnit: false,
      remarks: ''
    };
    this.setCurrentDate = this.setCurrentDate.bind(this);
    this.handleOnUnitChange = this.handleOnUnitChange.bind(this);
    this.handleOnRemarksChange = this.handleOnRemarksChange.bind(this);
  }

  componentDidMount = async () => {
    this.getPropertyName();
    this.setCurrentDate(moment());
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedPropertyId !== this.props.selectedPropertyId) {
      this.getPropertyName();
      this.getRoomTypeAndUnit();
    }

    if (
      prevState.selectedDateRangeString[0] !== this.state.selectedDateRangeString[0] ||
      prevState.selectedDateRangeString[1] !== this.state.selectedDateRangeString[1]
    ) {
      this.getRoomTypeAndUnit();
    }
  }

  setCurrentDate = date => {
    const today = date;
    // console.log(today);
    this.setState({
      selectedDateRange: [moment(today), moment(today).add(1, 'day')],
      selectedDateRangeString: [
        moment(today).format(DATE_FORMAT),
        moment(today)
          .add(1, 'day')
          .format(DATE_FORMAT)
      ]
    });
  };

  getPropertyName = async () => {
    try {
      const { selectedPropertyId } = this.props;
      const property = await getProperties();
      if (property.length > 0) {
        const propertyName = property.filter(property => String(property._id) === String(selectedPropertyId));
        if (propertyName.length > 0) {
          this.setState({ selectedPropertyName: propertyName[0].name });
        }
      }
    } catch (err) {
      // console.log(err);
      message.error(err);
      this.setState({ selectedPropertyName: '' });
    }
  };

  getRoomTypeAndUnit = async () => {
    const { selectedPropertyId } = this.props;
    const { selectedDateRangeString } = this.state;
    this.setState({ loading: true });
    const getAllUnitsAndRoomType = await getUnitListings({
      propertyId: selectedPropertyId,
      startDate: selectedDateRangeString[0],
      endDate: moment(selectedDateRangeString[1])
        .add(1, 'day')
        .format(DATE_FORMAT),
      isBookingLogic: true
    });
    // console.log(getAllUnitsAndRoomType);
    if (getAllUnitsAndRoomType.status === 200) {
      let units = getAllUnitsAndRoomType.data.sort((a, b) => {
        return a.name - b.name;
      });
      if (units.length > 0) {
        const fullUnitOptions = units.map(u => {
          return {
            id: u._id,
            name: u.name,
            roomType: u.roomType._id,
            roomTypeName: u.roomType.name,
            isAvailable: u.reservations.length === 0
          };
        });
        let roomTypeOptions = units
          .filter((unit, i, arr) => {
            return unit.roomType && unit.roomType._id && arr.map(unit => unit.roomType._id.toString()).indexOf(unit.roomType._id.toString()) === i;
          })
          .map(k => {
            return {
              id: k.roomType._id,
              name: k.roomType.name
            };
          });
        // console.log(fullUnitOptions);
        this.setState({
          fullUnitOptions,
          roomTypeOptions,
          isNoUnit: false
        });
        // console.log(roomTypeOptions);
        let selectedRoomType = this.state.selectedRoomType;
        if (!this.state.selectedRoomType || !roomTypeOptions.map(roomTypeOption => roomTypeOption.id).includes(this.state.selectedRoomType)) {
          selectedRoomType = roomTypeOptions.length > 0 ? roomTypeOptions[0].id : null;
          this.setState({ selectedRoomType });
        }

        let unitOptions = fullUnitOptions.filter(u => u.roomType === selectedRoomType);
        this.setState({ unitOptions });
        // console.log(this.state.selectedUnit);
        // console.log(unitOptions);
        if (
          this.state.selectedUnit.length === 0 ||
          !fullUnitOptions
            .filter(unitOption => unitOption.isAvailable)
            .map(unitOption => unitOption.id)
            .includes(this.state.selectedUnit)
        ) {
          let selectedUnit = [];
          if (fullUnitOptions.filter(unitOption => unitOption.isAvailable).length > 0) {
            selectedUnit.push(fullUnitOptions.filter(unitOption => unitOption.isAvailable)[0].id);
          }
          this.setState({
            selectedUnit
          });
        }

        this.setState({ loading: false });
      } else {
        this.setState({
          fullUnitOptions: [],
          roomTypeOptions: [],
          unitOptions: [],
          selectedRoomType: '',
          selectedUnit: [],
          isNoUnit: true,
          loading: false
        });
      }
    }
  };

  blockMaintenance = (event, bookingType, unitIdArr, remarks, selectedDateRange) => {
    const { onCancel, handleOnMCBlockDateActionSuccess } = this.props;
    // console.log(bookingType, unitIdArr, remarks, selectedDateRange);
    event.preventDefault();
    const startDate = moment(selectedDateRange[0]).format(DATE_FORMAT);
    const endDate = moment(selectedDateRange[1])
      .add(1, 'day')
      .format(DATE_FORMAT);
    createReservation({
      startDate: startDate,
      endDate: endDate,
      bookingType,
      unit: unitIdArr,
      remarks: remarks
    })
      .then(res => {
        message.info('Blocking dates...');
        // console.log(res);
        if (res && res.status === 201) {
          setTimeout(() => {
            message.success('Block dates created!');
            onCancel();
            handleOnMCBlockDateActionSuccess({ selectedDate: startDate });
          }, 2000);
        } else {
          message.error(intl.get('multicalendar.message.blockError').d('Encounter dates that could not be block!'));
          onCancel();
        }
      })
      .catch(err => {});
  };

  unblockReservation = (event, bookingType, unitIdArr, remarks, selectedDateRange) => {
    this.setState({ loading: true });
    const { onCancel, handleOnMCBlockDateActionSuccess } = this.props;
    // console.log(215, bookingType, unitIdArr, remarks, selectedDateRange);
    event.preventDefault();
    const startDate = moment(selectedDateRange[0]).format(DATE_FORMAT);
    const endDate = moment(selectedDateRange[1]).format(DATE_FORMAT);
    deleteMultipleBlockReservation({
      startDate: startDate,
      endDate: endDate,
      bookingType,
      unit: unitIdArr,
      remarks: remarks
    })
      .then(res => {
        // console.log(res);
        message.info('Unblocking dates...');
        if (res && res.status === 201) {
          setTimeout(() => {
            message.success('Dates unblocked!');
            this.setState({ loading: false });
            onCancel();
            handleOnMCBlockDateActionSuccess({ selectedDate: startDate });
          }, 2000);
        } else {
          message.error(intl.get('multicalendar.message.unblockError').d('Dates could not be unblock!'));
          onCancel();
        }
      })
      .catch(err => {});
  };

  handleOnRoomTypeChange = e => {
    this.setState({ selectedRoomType: e });
  };

  handleOnUnitChange = e => {
    // console.log(e);
    this.setState({ selectedUnit: e });
  };

  handleOnSelectAllUnits = () => {
    // console.log(e);
    this.setState({
      selectedUnit: this.props.onlyMYGA
        ? this.state.fullUnitOptions.map(unit => unit.id)
        : this.state.fullUnitOptions.filter(unit => unit.isAvailable).map(unit => unit.id)
    });
  };

  handleOnRemarksChange = e => {
    this.setState({ remarks: e.target.value });
  };

  render() {
    const { isShow, onCancel, onlyMYGA } = this.props;
    const { loading, selectedPropertyName, selectedUnit, selectedDateRange, remarks, fullUnitOptions } = this.state;
    return (
      <Modal
        visible={isShow}
        title={`${intl.get('multicalendar.headerLabels.blockWizardFor').d('Block wizard for Property')} ${selectedPropertyName}`}
        destroyOnClose
        confirmLoading={loading}
        onCancel={onCancel}
        footer={[
          <Button id="close-button1-block" key="back" onClick={onCancel}>
            {intl.get('multicalendar.headerLabels.close').d('Close')}
          </Button>,
          <Button
            id="maintenancce-button1-block"
            key="maintenance"
            onClick={e => this.blockMaintenance(e, BOOKINGTYPE_MAINTENANCE, selectedUnit, remarks, selectedDateRange)}
          >
            {intl.get('multicalendar.headerLabels.maintanence').d('Maintenance')}
          </Button>,
          <Button
            type="danger"
            id="block-button1-block"
            key="block"
            onClick={e => this.blockMaintenance(e, BOOKINGTYPE_BLOCK, selectedUnit, remarks, selectedDateRange)}
          >
            {intl.get('multicalendar.headerLabels.block').d('Block')}
          </Button>,
          onlyMYGA && (
            <Button
              type="primary"
              id="block-button1-block"
              key="unblock"
              onClick={e => this.unblockReservation(e, [BOOKINGTYPE_BLOCK, BOOKINGTYPE_MAINTENANCE], selectedUnit, remarks, selectedDateRange)}
            >
              {intl.get('multicalendar.headerLabels.unblock').d('Unblock')}
            </Button>
          )
        ]}
      >
        {loading ? (
          <Skeleton loading={loading} />
        ) : (
          <Form>
            <Row>
              <Col span={24}>
                <div className="block-row">
                  <label className="block-label">{intl.get('multicalendar.headerLabels.dateRange').d('Date Range')}</label>
                  <RangePicker
                    value={selectedDateRange}
                    disabledDate={getDisabledDate}
                    ranges={{
                      [intl.get('multicalendar.placeholder.thisWeek').d('This week')]: [
                        moment(this.state.selectedDateRange[0]).startOf('day'),
                        moment(this.state.selectedDateRange[0]).endOf('week')
                      ],
                      [intl.get('multicalendar.placeholder.thisMonth').d('This month')]: [
                        moment(this.state.selectedDateRange[0]).startOf('day'),
                        moment(this.state.selectedDateRange[0]).endOf('month')
                      ]
                    }}
                    onChange={(date, dateString) => {
                      // console.log(date, dateString);
                      this.setState({ selectedDateRange: date, selectedDateRangeString: dateString });
                    }}
                  />
                </div>
              </Col>
              {onlyMYGA && (
                <Col span={24}>
                  <div className="block-row">
                    <label className="block-label">{intl.get('multicalendar.headerLabels.unit').d('Unit')}</label>
                    <Select
                      mode="multiple"
                      placeholder={intl.get('multicalendar.placeholder.unit').d('Select unit(s)')}
                      value={selectedUnit}
                      onChange={this.handleOnUnitChange}
                      disabled={this.state.isNoUnit}
                    >
                      <OptGroup label={intl.get('multicalendar.headerLabels.unit').d('Unit')}>
                        {fullUnitOptions
                          // .filter(unit => unit.isAvailable)
                          .map(unit => (
                            <Option key={unit.id} value={unit.id}>
                              {`${unit.name} (${unit.roomTypeName})`}
                            </Option>
                          ))}
                      </OptGroup>
                      {/* <OptGroup label="Unavailable Units">
                      {fullUnitOptions
                        // .filter(unit => !unit.isAvailable)
                        .map(unit => (
                          <Option key={unit.id} value={unit.id} disabled>
                            {`${unit.name} (${unit.roomTypeName})`}
                          </Option>
                        ))}
                    </OptGroup> */}
                    </Select>
                  </div>

                  <Button
                    id={'select-all-button'}
                    ghost
                    type="primary"
                    disabled={this.state.isNoUnit}
                    onClick={this.handleOnSelectAllUnits}
                    style={{ marginTop: '5px' }}
                  >
                    {intl.get('multicalendar.headerLabels.selectAllLabel').d('Select All')}
                  </Button>
                </Col>
              )}
              {!onlyMYGA && (
                <Col span={24}>
                  <div className="block-row">
                    <label className="block-label">{intl.get('multicalendar.headerLabels.unit').d('Unit')}</label>
                    <Select
                      mode="multiple"
                      placeholder={intl.get('multicalendar.placeholder.unit').d('Select unit(s)')}
                      value={selectedUnit}
                      onChange={this.handleOnUnitChange}
                      disabled={this.state.isNoUnit}
                    >
                      <OptGroup label={intl.get('multicalendar.headerLabels.unit').d('Unit')}>
                        {fullUnitOptions
                          .filter(unit => unit.isAvailable)
                          .map(unit => (
                            <Option key={unit.id} value={unit.id}>
                              {`${unit.name} (${unit.roomTypeName})`}
                            </Option>
                          ))}
                      </OptGroup>
                      <OptGroup label={intl.get('multicalendar.placeholder.unavailableUnits').d('Unavailable Units')}>
                        {fullUnitOptions
                          .filter(unit => !unit.isAvailable)
                          .map(unit => (
                            <Option key={unit.id} value={unit.id} disabled>
                              {`${unit.name} (${unit.roomTypeName})`}
                            </Option>
                          ))}
                      </OptGroup>
                    </Select>
                  </div>
                </Col>
              )}
              <Col span={24}>
                <div className="block-row">
                  <label className="block-label">{intl.get('multicalendar.headerLabels.remark').d('Remarks (Optional)')}</label>
                  <Input
                    value={remarks}
                    onChange={this.handleOnRemarksChange}
                    placeholder={intl.get('multicalendar.placeholder.remark').d('Please insert your remarks')}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

McModalBlockWizard.propTypes = {
  form: PropTypes.object.isRequired,
  isShow: PropTypes.bool,
  selectedPropertyId: PropTypes.string.isRequired,
  selectedRateFilters: PropTypes.array.isRequired
};

McModalBlockWizard.defaultProps = {
  form: {},
  isShow: false,
  selectedRateFilters: []
};

export default withRouter(Form.create()(McModalBlockWizard));
