import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getHosts } from '../../../utils/apis/host';
import { getExpediaProduct, updateExpediaHost } from '../../../utils/apis/integration';
import { Select, Form, Input, Modal, notification } from 'antd';
import intl from 'react-intl-universal';

const Option = Select.Option;
const FormItem = Form.Item;

class ExpediaListingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostOptions: [],
      isEdit: false,
      isSaving: false
    };
    this.isEdit = this.isEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.hostOptions) {
      getHosts()
        .then(hostRes => {
          let hostOptions = hostRes.data.map(host => {
            return (
              <Option value={host._id} key={host._id}>
                {host.name}
              </Option>
            );
          });
          this.setState({
            hostOptions
          });
        })
        .catch(ex => {
          notification.error({
            message: `${intl.get('hostConnect.expedia.message.hostError').d('Something went wrong and could not get host options')}`
          });
        });
    } else {
      this.setState({
        hostOptions: this.props.hostOptions
      });
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.expedia !== this.props.expedia) {
      let { setFieldsValue } = this.props.form;
      setFieldsValue({
        host: this.props.expedia ? this.props.expedia.host : '',
        expediaPropertyId: this.props.expedia && this.props.expedia.expediaPropertyId
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        isSaving: true
      });
      if (this.isEdit()) {
        let { expedia } = this.props;
        if (!expedia) {
          notification.error({
            message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
            description: (
              <div>
                <p>
                  <b>
                    {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.expediaPropertyId})
                  </b>
                </p>
                {intl.get('hostConnect.bookingcom.message.failDesc2').d('Try refresh the page')}
              </div>
            )
          });
        }
        updateExpediaHost(expedia._id, values.host)
          .then(res => {
            this.setState({
              isSaving: false
            });
            if (this.props.refreshIntoEdit(expedia.expediaPropertyId)) {
              notification.success({
                message: `${intl.get('hostConnect.bookingcom.message.sucessMsg').d('Nice One')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.expedia.message.successAdd').d('Succesfully added Host to Expedia Hotel')}</b>
                    </p>
                  </div>
                )
              });
              this.props.hideModal();
            } else {
              notification.error({
                message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.expedia.message.failAdd').d('Failed to update Expedia Hotel')}</b>
                    </p>
                    {intl
                      .get('hostConnect.bookingcom.message.updateFailDesc2')
                      .d('We are quite sure the hotel was updated succesfully, try refreshing the page. If problem presist, contact ')}
                    <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                      {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                    </a>
                    !
                  </div>
                )
              });
            }
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
              description: (
                <div>
                  <p>
                    <b>{intl.get('hostConnect.expedia.message.failAdd').d('Failed to update Expedia Hotel')}</b>
                  </p>
                  {intl.get('hostConnect.ctrip.message.updateFailDesc2').d('Try refreshing the page. If problem presist, contact ')}
                  <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                    {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                  </a>
                  !
                </div>
              )
            });
          });
      } else {
        notification.info({
          message: `${intl.get('hostConnect.expedia.message.asking').d('Asking Expedia nicely for Hotel Id')} (${values.expediaPropertyId})`
        });
        getExpediaProduct(values.expediaPropertyId)
          .then(res => {
            if (res && res.status === 200) {
              if (this.props.refreshIntoEdit(values.expediaPropertyId)) {
                notification.success({
                  message: `${intl.get('hostConnect.bookingcom.message.successRetrieveMsg').d('Succesfully retrieve Hotel')} (${
                    values.expediaPropertyId
                  })`,
                  description: <div>{intl.get('hostConnect.expedia.message.assignHost').d('Please assign a host to this Expedia Property')}</div>
                });
              } else {
                notification.error({
                  message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                  description: (
                    <div>
                      <p>
                        <b>
                          {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.expediaPropertyId})
                        </b>
                      </p>
                      {intl
                        .get('hostConnect.bookingcom.message.failRetrieveDesc')
                        .d('We are quite sure the hotel was retrieved succesfully, try refreshing the page. If problem presist, contact ')}
                      <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                        {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                      </a>
                      !
                    </div>
                  )
                });
              }
            } else {
              notification.error({
                message: `${intl.get('hostConnect.expedia.message.failRetrieve').d('Failed to retrieve Expedia Hotel')}`,
                description: (
                  <div>
                    {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.expediaPropertyId}) i
                    {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                    <div>
                      {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fexpedia-connect"
                      >
                        {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                      </a>
                      !
                    </div>
                  </div>
                )
              });
            }
            this.setState({
              isSaving: false
            });
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.expedia.message.failRetrieve').d('Failed to retrieve Expedia Hotel')}`,
              description: (
                <div>
                  {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.expediaPropertyId}){' '}
                  {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                  <div>
                    {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fexpedia-connect"
                    >
                      {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                    </a>
                    !
                  </div>
                </div>
              )
            });
          });
      }
    });
  };

  isEdit() {
    let { expedia } = this.props;
    return !!expedia;
  }

  render() {
    let { expedia, visible, hideModal } = this.props;
    let { getFieldDecorator } = this.props.form;
    let { isSaving } = this.state;
    return (
      <Modal
        title={
          <React.Fragment>
            {expedia
              ? `${intl.get('hostConnect.bookingcom.headerLabels.editing').d('Editing')} ${
                  expedia.expediaPropertyName ? expedia.expediaPropertyName : ''
                } (${expedia.expediaPropertyId})`
              : intl.get('hostConnect.expedia.headerLabels.new').d('New Expedia Property')}
          </React.Fragment>
        }
        visible={visible}
        onOk={
          !isSaving
            ? this.onSubmit
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl.get('hostConnect.bookingcom.message.desc1').d('We are still working on it.')
                });
              }
        }
        onCancel={
          !isSaving
            ? hideModal
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl.get('hostConnect.expedia.message.desc').d('We are still trying to pull from Expedia, do not close this page yet.')
                });
              }
        }
        okText={intl.get('reservations.headerLabels.save').d('Save')}
        cancelText={intl.get('reservations.headerLabels.cancel').d('Cancel')}
        maskClosable={false}
      >
        <Form onSubmit={this.onSubmit}>
          {this.isEdit() ? (
            <React.Fragment>
              <FormItem label={intl.get('hostConnect.bookingcom.headerLabels.assignHost').d('Assign a host:')}>
                {getFieldDecorator('host', {
                  rules: []
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.hostOptions}
                  </Select>
                )}
              </FormItem>
            </React.Fragment>
          ) : (
            <FormItem label={intl.get('hostConnect.expedia.headerLabels.expediaProperty').d('Expedia Property Id')}>
              {getFieldDecorator('expediaPropertyId', {
                rules: [
                  {
                    required: true,
                    pattern: '^[0-9]+$',
                    message: intl.get('hostConnect.bookingcom.placeholder.bookingIdMsg').d('Please insert numbers only!')
                  }
                ]
              })(<Input placeholder={intl.get('hostConnect.expedia.placeholder.expediaProperty').d('Expedia Property Id (example: 263492)')} />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(withRouter(ExpediaListingForm));
