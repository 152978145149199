import React, { Component } from 'react';
import { getConstants } from './../../utils/apis/constants';
import { Form, Input, Icon, Button, Popconfirm, Select, Row, Col } from 'antd';
import './TransportFieldSet.css';
import { PropTypes } from 'prop-types';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const Option = Select.Option;

class TransportFieldSet extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      vehicleColorOptions: [],
      update: false
    };
  }

  remove = k => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('keys').filter(key => key !== k);

    // can use data-binding to set
    form.setFieldsValue({
      keys: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [...fieldsToValidate, `vehicleType[${key}]`, `vehicleModel[${key}]`, `vehicleNo[${key}]`];
      }, []),
      { first: true },
      (err, values) => {
        const newValues = form.getFieldsValue();
        newValues.keys = newKeys; //in case setFieldsValue sets keys after the getFieldsValue
        onChange(newValues, !err);
      }
    );
  };

  add = () => {
    const { form, onChange } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(Math.max(0, ...keys) + 1);
    form.setFieldsValue({
      keys: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
    onChange(newValues, false);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    // e.preventDefault();
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  componentDidMount() {
    const { data, form } = this.props;
    let keys = data.map(d => d.key);
    if (keys.length > 0) {
      form.setFieldsValue(
        {
          keys
        },
        this.setState({
          update: true
        })
      );
    }
    getConstants('vehicleColors').then(resVehicleColors => {
      if (resVehicleColors && resVehicleColors.status === 200) {
        let vehicleColorOptions = Object.keys(resVehicleColors.data).map(k => {
          return resVehicleColors.data[k];
        });
        if (vehicleColorOptions.length > 0) {
          this.setState({
            vehicleColorOptions
          });
        }
      } else {
        console.log('Error while retrieving unit views');
      }
    });
  }

  componentDidUpdate(prevProp, prevState) {
    const { update } = this.state;
    const { data, form } = this.props;
    if (update) {
      data.forEach(d => {
        let obj = {};
        let key = `vehicleType[${d.key}]`;
        let key2 = `vehicleModel[${d.key}]`;
        let key3 = `vehicleNo[${d.key}]`;
        let key4 = `vehicleColor[${d.key}]`;
        obj[key] = d.vehicleType;
        obj[key2] = d.vehicleModel;
        obj[key3] = d.vehicleNo;
        obj[key4] = d.vehicleColor;
        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { vehicleColorOptions } = this.state;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => {
      return (
        <React.Fragment>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`vehicleType[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <label>{intl.get('booking_form.guestDetails.vehicleType').d('Vehicle Type')}</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleType[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.vehicleType').d("Please input vehicle's type.")
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.vehicleType').d('Vehicle Type')} onBlur={this.handleSubmit} />)}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`vehicleModel[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <label>{intl.get('booking_form.guestDetails.vehicleModel').d('Vehicle Model')}</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleModel[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.vehicleModel').d("Please input vehicle's model.")
                      }
                    ]
                  })(<Input placeholder={intl.get('booking_form.guestDetails.vehicleModel').d('Vehicle Model')} onBlur={this.handleSubmit} />)}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`vehicleNo[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <label>{intl.get('booking_form.guestDetails.vehicleRegistrationNumber').d('Vehicle Registration Number')}</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`vehicleNo[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl
                          .get('booking_form.guestDetails.alertMessage.vehicleRegistrationNumber')
                          .d("Please input vehicle's registration number.")
                      }
                    ]
                  })(
                    <Input
                      placeholder={intl.get('booking_form.guestDetails.vehicleRegistrationNumber').d('Vehicle Registration Number')}
                      onBlur={this.handleSubmit}
                    />
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`vehicleColor[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <label>{intl.get('booking_form.guestDetails.vehicleColor').d('Vehicle Color')}</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  <div>
                    {getFieldDecorator(`vehicleColor[${k}]`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      initialValue: vehicleColorOptions[0],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: intl.get('booking_form.guestDetails.alertMessage.vehicleColor').d("Please input vehicle's color.")
                        }
                      ]
                    })(
                      <Select showSearch onBlur={this.handleSubmit}>
                        {vehicleColorOptions.map(vehicleColorOption => {
                          return (
                            <Option value={vehicleColorOption}>
                              {intl.get(`booking_form.guestDetails.vehicleColorOptions.${vehicleColorOption}`)}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                </div>
              </FormItem>
            </Col>
          </Row>
          <FormItem className="guest-input">
            <div className="booking-form-item-control-wrapper">
              <Popconfirm
                title={intl.get('booking_form.guestDetails.areYouSureRemoveVehicle').d('Are you sure you want to remove this Vehicle?')}
                onConfirm={() => this.remove(k)}
                okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
              >
                <Button className="booking-form-danger" type="danger" style={{ width: '100%' }}>
                  <Icon type="minus" /> {intl.get('booking_form.guestDetails.removeVehicle').d('Remove vehicle')}
                </Button>
              </Popconfirm>
            </div>
          </FormItem>
        </React.Fragment>
      );
    });
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <FormItem className="guest-input">
          <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addVehicle').d('Add vehicle')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedTransportDynamicFieldSet = Form.create()(TransportFieldSet);
export default WrappedTransportDynamicFieldSet;
