import React, { useMemo } from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import { constructTwoDecimalsRule } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';

import styles from './BookingEngineConfigDetailsCard.module.css';

const PriceInformation = ({ form, cardClassname, defaultBookingEngineRoomTypeCleaningFee }) => {
  return (
    <Card title={intl.get('listings.bookingEngine.headerLabels.priceInfo').d('Price Information')} className={cardClassname}>
      <FormInput
        form={form}
        name="bookingEngineRoomTypeCleaningFee"
        label={intl.get('listings.bookingEngine.headerLabels.roomTypeCleaningFee').d('Room Type Cleaning Fee')}
        placeholder="0.00"
        defaultValue={defaultBookingEngineRoomTypeCleaningFee}
        extraProps={{ addonBefore: 'RM' }}
        extraRules={[constructTwoDecimalsRule()]}
        size="large"
      />
    </Card>
  );
};

const BasicInformation = ({ form, cardClassname, defaultBookingEngineRoomTypeDisplayName }) => {
  return (
    <Card title={intl.get('host.headerLabels.basic').d('Basic Information')} className={cardClassname}>
      <FormInput
        form={form}
        name="bookingEngineRoomTypeDisplayName"
        defaultValue={defaultBookingEngineRoomTypeDisplayName}
        inputType="input"
        label={intl.get('listings.bookingEngine.headerLabels.externalDisplayName').d('External Display Name')}
        placeholder={intl.get('listings.bookingEngine.placeholder.displayName').d('Type in display name for booking website')}
        size="large"
      />
    </Card>
  );
};

const GeneralAlert = () => {
  return (
    <Alert
      className={styles.notes}
      message={intl.get('listings.bookingEngine.headerLabels.note').d('Notes')}
      description={
        <div>
          <p className={styles.notesText}>
            {intl.get('listings.bookingEngine.headerLabels.noteMsg').d('All settings configured here will be shown in booking website.')}
          </p>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

const BookingEngineConfigDetailsCard = ({ form, cardClassname, defaultValues }) => {
  const defaultBookingEngineRoomTypeDisplayName = useMemo(() => defaultValues.bookingEngineRoomTypeDisplayName, [defaultValues]);
  const defaultBookingEngineRoomTypeCleaningFee = useMemo(() => defaultValues.bookingEngineRoomTypeCleaningFee, [defaultValues]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <GeneralAlert />
      </Col>
      <Col span={24}>
        <BasicInformation
          form={form}
          cardClassname={cardClassname}
          defaultBookingEngineRoomTypeDisplayName={defaultBookingEngineRoomTypeDisplayName}
        />
      </Col>
      <Col span={24}>
        <PriceInformation
          form={form}
          cardClassname={cardClassname}
          defaultBookingEngineRoomTypeCleaningFee={defaultBookingEngineRoomTypeCleaningFee}
        />
      </Col>
    </Row>
  );
};

BookingEngineConfigDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

BookingEngineConfigDetailsCard.defaultProps = {
  defaultValues: {}
};

export default BookingEngineConfigDetailsCard;
