import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, Spin, Modal } from 'antd';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import OTALogo from 'components/OTALogo/OTALogo';

import { getRoomTypeByProperty } from 'utils/apis/integration';

import AgodaForm from './components/Agoda/AgodaForm';
import BookingcomForm from './components/Bookingcom/BookingcomForm';
import CtripForm from './components/Ctrip/CtripForm';
import CtripCMForm from './components/CtripCM/CtripCMForm';
import ExpediaForm from './components/Expedia/ExpediaForm';
import TravelokaForm from './components/Traveloka/TravelokaForm';
import TiketcomForm from './components/Tiketcom/TiketcomForm';

import { REACT_APP_IS_SHOW_TIKETCOM, REACT_APP_IS_SHOW_TRAVELOKA } from 'config/env';

const TabPane = Tabs.TabPane;

const useSelectedTabDetails = selectedIntegrationSource => {
  const [selectedTab, setSelectedTab] = useState(selectedIntegrationSource);

  const handleOnChangeTab = integrationSourceCode => {
    setSelectedTab(integrationSourceCode);
  };

  return { selectedTab, handleOnChangeTab };
};

const useFetchAndConstructPropertyWithRoomTypes = selectedProperty => {
  const [selectedPropertyWithRoomTypes, setselectedPropertyWithRoomTypes] = useState({});

  const fetchAndConstructPropertyWithRoomTypes = useCallback(async () => {
    const roomTypes = await getRoomTypeByProperty(selectedProperty._id).then(res => res.data);

    setselectedPropertyWithRoomTypes({ ...selectedProperty, roomTypes });
  }, [selectedProperty]);

  useEffect(() => {
    fetchAndConstructPropertyWithRoomTypes();
  }, [fetchAndConstructPropertyWithRoomTypes]);

  return { selectedPropertyWithRoomTypes };
};

const OTAHotelForm = ({ integrationSourcesConstant, hotelIntegrations, selectedProperty, selectedIntegrationSourceCode, onAfterSave, onClose }) => {
  const { selectedTab, handleOnChangeTab } = useSelectedTabDetails(selectedIntegrationSourceCode || integrationSourcesConstant.AGODA.code);
  const { selectedPropertyWithRoomTypes } = useFetchAndConstructPropertyWithRoomTypes(selectedProperty);

  const selectedHotelIntegration = useMemo(() => hotelIntegrations.find(hotelIntegration => hotelIntegration.source === selectedTab), [
    hotelIntegrations,
    selectedTab
  ]);

  const [isCTripLoading, setIsCTripLoading] = useState(true);
  return (
    <Modal
      visible={true}
      title={`${intl
        .get('hostConnect.integration.headerLabels.otaIntegrationProperty')
        .d('OTA Integration for Property')} ${selectedPropertyWithRoomTypes.name || ''}`}
      width="60%"
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      centered
      onCancel={onClose}
      bodyStyle={{ height: '75vh' }}
    >
      <Tabs activeKey={selectedTab} onChange={handleOnChangeTab}>
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.AGODA.code} text={integrationSourcesConstant.AGODA.label} />}
          key={integrationSourcesConstant.AGODA.code}
        >
          <AgodaForm property={selectedPropertyWithRoomTypes} onClose={onClose} data={selectedHotelIntegration} />
        </TabPane>
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.EXPEDIA.code} text={integrationSourcesConstant.EXPEDIA.label} />}
          key={integrationSourcesConstant.EXPEDIA.code}
        >
          <ExpediaForm property={selectedPropertyWithRoomTypes} onClose={onClose} data={selectedHotelIntegration} />
        </TabPane>
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.BOOKINGCOM.code} text={integrationSourcesConstant.BOOKINGCOM.label} />}
          key={integrationSourcesConstant.BOOKINGCOM.code}
        >
          <BookingcomForm property={selectedPropertyWithRoomTypes} onClose={onClose} onAfterSave={onAfterSave} data={selectedHotelIntegration} />
        </TabPane>
        {/* <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.CTRIP.code} text={integrationSourcesConstant.CTRIP.label} />}
          key={integrationSourcesConstant.CTRIP.code}
        >
          <Spin spinning={isCTripLoading}>
            <CtripForm
              property={selectedPropertyWithRoomTypes}
              onClose={onClose}
              data={selectedHotelIntegration}
              stopLoading={() => setIsCTripLoading(false)}
            />
          </Spin>
        </TabPane> */}
        <TabPane
          tab={<OTALogo size="small" otaCode={integrationSourcesConstant.CTRIPCM.code} text={integrationSourcesConstant.CTRIPCM.label} />}
          key={integrationSourcesConstant.CTRIPCM.code}
        >
          <CtripCMForm property={selectedPropertyWithRoomTypes} onClose={onClose} onAfterSave={onAfterSave} data={selectedHotelIntegration} />
        </TabPane>
        {REACT_APP_IS_SHOW_TRAVELOKA && (
          <TabPane
            tab={<OTALogo size="small" otaCode={integrationSourcesConstant.TRAVELOKA.code} text={integrationSourcesConstant.TRAVELOKA.label} />}
            key={integrationSourcesConstant.TRAVELOKA.code}
          >
            <TravelokaForm
              property={selectedPropertyWithRoomTypes}
              onClose={onClose}
              onAfterSave={onAfterSave}
              selectedHotelIntegration={selectedHotelIntegration}
            />
          </TabPane>
        )}

        {REACT_APP_IS_SHOW_TIKETCOM && (
          <TabPane
            tab={<OTALogo size="small" otaCode={integrationSourcesConstant.TIKETCOM.code} text={integrationSourcesConstant.TIKETCOM.label} />}
            key={integrationSourcesConstant.TIKETCOM.code}
          >
            <TiketcomForm
              property={selectedPropertyWithRoomTypes}
              onClose={onClose}
              onAfterSave={onAfterSave}
              selectedHotelIntegration={selectedHotelIntegration}
            />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

OTAHotelForm.propTypes = {
  integrationSourcesConstant: PropTypes.object.isRequired,
  hotelIntegrations: PropTypes.array,
  selectedProperty: PropTypes.object.isRequired,
  selectedIntegrationSourceCode: PropTypes.string,
  onAfterSave: PropTypes.func,
  onClose: PropTypes.func
};

OTAHotelForm.defaultProps = {
  hotelIntegrations: [],
  selectedIntegrationSourceCode: undefined,
  onAfterSave: () => {},
  onClose: () => {}
};

export default OTAHotelForm;
