import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import debounce from 'lodash.debounce';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import intl from 'react-intl-universal';

import styles from './Location.module.css';

import { Country, State, City } from 'country-state-city';

class Location extends React.Component {
  constructor() {
    super();
    // This is temporary setting to Malaysia geocode
    this.state = {
      hasCompletedForm: false,
      latitude: 4.2105,
      longitude: 101.9758
    };
  }

  getStateValue = stateCode => {
    const { states } = this.props;
    return states.find(state => state.key === stateCode).value;
  };

  getCountryValue = countryCode => {
    // const { countries } = this.props;
    // return countries.find(country => country.key === countryCode).value;

    return Country.getAllCountries()
      .map(country => {
        return {
          key: country.isoCode,
          label: country.name
        };
      })
      .find(country => country.key === countryCode).value;
  };

  handleOnFormChange = (fieldName, extraAction = () => {}) => event => {
    const { form } = this.props;
    // console.log('fieldName', fieldName);

    if (fieldName === 'country') {
      form.setFieldsValue({
        state: undefined
      });
    }

    extraAction(event);

    const fieldValue = this.extractFieldValue(event);
    const allFieldsValue = form.getFieldsValue();
    // Update the field value because onChange has not update the form value yet
    allFieldsValue[fieldName] = fieldValue;
    const hasCompletedForm = this.checkHasFormCompleted(allFieldsValue);
    if (this.state.hasCompletedForm !== hasCompletedForm) {
      this.setState({
        hasCompletedForm: hasCompletedForm
      });
    }

    if (hasCompletedForm /*  && form.getFieldValue('country') === 'MY' */) {
      this.debounceHandleOnMapSearch(allFieldsValue);
    }
  };

  extractFieldValue = event => {
    let fieldValue = event;
    if (typeof fieldValue === 'object' && fieldValue.target) {
      fieldValue = fieldValue.target.value;
    }
    return fieldValue;
  };

  checkHasFormCompleted = allFieldsValue => {
    // Check all fields value and make sure they are set
    let hasCompletedForm = true;
    for (let i = 0; i < Object.keys(allFieldsValue).length; i++) {
      const key = Object.keys(allFieldsValue)[i];
      if (allFieldsValue[key]) {
        continue;
      } else {
        hasCompletedForm = false;
        break;
      }
    }
    return hasCompletedForm;
  };

  handleOnMapSearch = addressInformation => {
    const isMalaysia = addressInformation.country === 'MY';
    const address = `${addressInformation.address}, ${addressInformation.city}, ${addressInformation.zipcode} ${
      isMalaysia ? this.getStateValue(addressInformation.state) : addressInformation.state
    }, ${this.getCountryValue(addressInformation.country)}
    `;

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        return this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng
        });
      })
      .catch(error => {
        console.error(error);
        console.error(intl.get('listings.property.message.mapError').d('This address is not found in Google Map. Please use a different one'));
      });
  };

  debounceHandleOnMapSearch = debounce(this.handleOnMapSearch, 500);

  handleOnMapMarkerMove = newLocation => {
    this.setState({
      latitude: newLocation.lat,
      longitude: newLocation.lng
    });
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    const { latitude, longitude } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave({
          ...values,
          latitude,
          longitude
        });
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const { latitude, longitude } = this.state;
    const allFieldsValue = form.getFieldsValue();
    const values = {
      ...allFieldsValue,
      latitude,
      longitude
    };
    onBack(values);
  };

  render() {
    const {
      defaultValues,
      form,
      hasFetchedCountries,
      hasFetchedStates,
      isLastPage,
      percentage,
      title,
      countries,
      states,
      onCountriesChange
    } = this.props;
    const { hasCompletedForm } = this.state;

    const formattedStates = states.map(state => ({
      key: state.key,
      value: state.value.replace(/\./g, '_')
    }));

    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>{intl.get('listings.property.where').d('Where your place located?')}</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormSelection
              name="country"
              label={intl.get('listings.property.country').d('Country')}
              requiredErrorMessage={intl.get('host.placeholder.countryMsg').d('Please select country that your property locate')}
              placeholder={intl.get('host.placeholder.country').d('Select a country')}
              form={form}
              defaultValue={defaultValues.country}
              shouldDisable={!hasFetchedCountries}
              selections={Country.getAllCountries().map(country => {
                return {
                  key: country.isoCode,
                  label: intl.get(`countries.${country.name.replace(/\./g, '_')}`).d(country.name)
                };
              })}
              // selections={countries}
              onChange={this.handleOnFormChange('country', onCountriesChange)}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInput
              label={intl.get('listings.property.street').d('Street Address')}
              name="address"
              placeholder={intl.get('listings.property.street').d('Street Address')}
              requiredErrorMessage={intl.get('host.placeholder.streetMsg').d('Please provide street address that your property locate')}
              form={form}
              defaultValue={defaultValues.address}
              onChange={this.handleOnFormChange('address')}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={12} className={styles.formItem}>
            <FormInput
              name="city"
              label={intl.get('listings.property.city').d('City')}
              placeholder={intl.get('listings.property.city').d('City')}
              requiredErrorMessage={intl.get('host.placeholder.cityMsg').d('Please provide city that your property locate')}
              form={form}
              defaultValue={defaultValues.city}
              onChange={this.handleOnFormChange('city')}
              size="large"
            />
          </Col>
          <Col span={11} className={styles.formItem}>
            <FormInput
              name="zipcode"
              label={intl.get('listings.property.zipcode').d('Zipcode')}
              placeholder={intl.get('listings.property.zipcode').d('Zipcode')}
              requiredErrorMessage={intl.get('host.placeholder.zipMsg').d('Please provide the ZIP Code of your property')}
              form={form}
              defaultValue={defaultValues.zipcode}
              onChange={this.handleOnFormChange('zipcode')}
              extraRules={[
                {
                  max: 6,
                  message: intl.get('host.message.zipError1').d('ZIP Code has maximum 6 numbers')
                },
                {
                  min: 5,
                  message: intl.get('host.message.zipError2').d('ZIP Code has minimum 5 numbers')
                },
                {
                  pattern: /^[0-9]*$/,
                  message: intl.get('host.message.zipError3').d('ZIP Code must be number')
                }
              ]}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            {form.getFieldValue('country') === 'MY' ? (
              <FormSelection
                label={intl.get('listings.property.state').d('State')}
                name="state"
                requiredErrorMessage={intl.get('listings.property.message.propertyLocate').d('StaPlease provide state that your property locate')}
                placeholder={intl.get('host.placeholder.state').d('Select a state')}
                form={form}
                defaultValue={defaultValues.state}
                // shouldDisable={!hasFetchedStates}
                selections={formattedStates}
                onChange={this.handleOnFormChange('state')}
                size="large"
                formatValue={value => value.replace(/\./g, '_')}
              />
            ) : (
              <FormInput
                name="state"
                label={intl.get('listings.property.state').d('State')}
                placeholder={intl.get('listings.property.state').d('State')}
                requiredErrorMessage={intl.get('listings.property.message.propertyLocate').d('Please provide state that your property locate')}
                form={form}
                defaultValue={defaultValues.state}
                onChange={this.handleOnFormChange('state')}
                size="large"
              />
            )}
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24}>
            {!hasCompletedForm ? (
              <div>{intl.get('listings.property.mapMsg1').d('Google map will load after you complete the form')}</div>
            ) : (
              <div>
                <h3>{intl.get('listings.property.mapMsg2').d('Move the pin to update your property location')}</h3>
                <GoogleMap
                  lng={this.state.longitude}
                  lat={this.state.latitude}
                  onMarkerMove={this.handleOnMapMarkerMove}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            )}
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

Location.propTypes = {
  countries: PropTypes.array.isRequired,
  defaultValues: PropTypes.object,
  hasFetchedCountries: PropTypes.bool.isRequired,
  hasFetchedStates: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

Location.defaultProps = {
  countries: [],
  defaultValues: {},
  hasFetchedCountries: false,
  hasFetchedStates: false,
  isLastPage: false,
  states: []
};

const WrappedLocation = Form.create({})(Location);

export default WrappedLocation;
