import React from 'react';
import { Collapse, Col, Row, Spin, Tooltip, Icon } from 'antd';
import PropTypes from 'prop-types';

import styles from './MCCollapse.module.css';
import intl from 'react-intl-universal';

const { Panel } = Collapse;

const RoomTypeTitle = ({ roomType, clashingReservationIconStyle }) => (
  <span className={styles.roomType}>
    {roomType.clashedUnitIds.length > 0 && <Icon className={`${clashingReservationIconStyle}`} type="warning" />}
    {roomType.name}
  </span>
);

const UnitTitle = ({ className, unit, clashingReservationIconStyle }) => (
  <>
    <span className={className}>
      {unit.hasClashed && <Icon className={`${clashingReservationIconStyle}`} type="warning" />}
      {unit.name}
    </span>
    {unit.taskStatus && <CustomTagGreen text={unit.taskStatus} rateLength={Object.keys(unit.rates).length} />}
  </>
);

const CustomTagGreen = ({ text, rateLength }) => {
  let styling = {};
  switch (text) {
    case 'Dirty':
      styling = {
        color: '#db4537',
        border: '1px solid #db4537',
        backgroundColor: '#fff1f0',
        marginLeft: 16
      };
      break;

    case 'Cleaning':
      styling = {
        color: '#FFB800',
        border: '1px solid #FFB800',
        backgroundColor: '#fffbe6',
        marginLeft: 16
      };
      break;

    case 'Ready':
      styling = {
        color: '#52c41a',
        border: '1px solid #b7eb8f',
        backgroundColor: '#f6ffed',
        marginLeft: 16
      };
      break;

    default:
      break;
  }

  switch (rateLength) {
    case 0:
    case 1:
    case 2:
      styling = {
        ...styling,
        marginTop: -47
      };
      break;

    default:
      styling = {
        ...styling,
        marginTop: -1 * 20 * rateLength
      };
      break;
  }

  return (
    <div
      style={{
        ...styling,
        fontSize: 10,
        fontWeight: 500,
        width: '50%',
        borderRadius: 4,
        paddingLeft: 2,
        paddingRight: 2
      }}
    >
      {intl.get(`overview.cleaningStatus.${text}`).d(text)}
    </div>
  );
};

const MCCollapse = props => {
  const { roomType, activePanels, isLoading, calculateRatesHeight, clashingReservationIconStyle, ...antdCollapseProps } = props;

  const RATETYPES_NAME = {
    web: 'Web Rate',
    agoda: 'Agoda Rate',
    agodahomes: 'Agoda Homes Rate',
    expedia: 'Expedia Rate',
    // ctrip: 'Ctrip Rate',
    ctripCM: 'Ctrip Rate',
    airbnb: (
      <Tooltip
        title={intl
          .get('multicalendar.headerLabels.airbnbTooltip')
          .d('Calendar rates may differ if Airbnb discount rules has been applied on Airbnb site')}
      >
        <span>
          {intl.get('multicalendar.headerLabels.airbnbRate').d('Airbnb Rate')} <Icon type="question-circle" theme="filled" />
        </span>
      </Tooltip>
    ),
    bookingcom: 'Booking.com Rate',
    staysuites: 'StaySuites Rate',
    traveloka: 'Traveloka Rate',
    tiketcom: 'Tiket.com Rate'
  };

  const calculateCollapseHeight = ({ roomType, unit }) => {
    const initialHeight = roomType ? 74 : 49;
    const rates = roomType ? roomType.rates : unit.rates;

    const height = calculateRatesHeight(initialHeight, rates, 2);

    return height;
  };

  const header = (
    <Spin spinning={isLoading}>
      <Tooltip title={roomType.name} placement="left" mouseEnterDelay={1}>
        <Row type="flex" className={`${styles.collapseRow}`}>
          <Col span={15} lg={12} className={`${styles.collapseRowLeftCol}`} style={{ height: calculateCollapseHeight({ roomType }) }}>
            <RoomTypeTitle roomType={roomType} clashingReservationIconStyle={clashingReservationIconStyle} />
          </Col>

          <Col span={9} lg={12} className={`${styles.collapseRowRightCol}`}>
            <div className={styles.inventory}>{intl.get('multicalendar.headerLabels.inventory').d('Inventory')}</div>
            <div className={`${styles.ratesBox}`}>
              {Object.keys(roomType.rates).map(rate => (
                <div key={rate} className={styles.rate}>
                  {typeof RATETYPES_NAME[rate] === 'string'
                    ? intl.get(`multicalendar.rate.${RATETYPES_NAME[rate].replace(/\./g, '_')}`).d(RATETYPES_NAME[rate])
                    : RATETYPES_NAME[rate]}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Tooltip>
    </Spin>
  );

  return (
    <Panel {...antdCollapseProps} header={header} key={roomType._id} className={`${styles.collapse}`}>
      {activePanels.includes(String(roomType._id)) && roomType.units && (
        <div className={`${styles.collapseContent}`}>
          {roomType.units.map(unit => {
            return (
              <Tooltip
                key={unit._id}
                title={
                  <div>
                    <Row>{unit.name}</Row>
                    <Row>
                      {unit.taskStatus && (
                        <a href={`/task-management/task/${unit.taskId}/edit`}>
                          <Icon type="link" /> {intl.get('multicalendar.headerLabels.viewTask').d('View Task')}
                        </a>
                      )}
                    </Row>
                  </div>
                }
                placement="left"
                mouseEnterDelay={1}
              >
                <Row type="flex" className={`${styles.collapseRow} ${styles.unitCollapseRow}`}>
                  <Col span={15} lg={12} className={`${styles.collapseRowLeftCol}`} style={{ height: calculateCollapseHeight({ unit }) }}>
                    <UnitTitle className={styles.unitName} unit={unit} clashingReservationIconStyle={clashingReservationIconStyle} />
                  </Col>

                  <Col span={9} lg={12} className={`${styles.collapseRowRightCol}`}>
                    <Row className={`${styles.ratesBox}`}>
                      {Object.keys(unit.rates).map(rate => (
                        <Row key={rate} className={`${styles.rate}`}>
                          {typeof RATETYPES_NAME[rate] === 'string'
                            ? intl.get(`multicalendar.rate.${RATETYPES_NAME[rate].replace(/\./g, '_')}`).d(RATETYPES_NAME[rate])
                            : RATETYPES_NAME[rate]}
                        </Row>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Tooltip>
            );
          })}
        </div>
      )}
    </Panel>
  );
};

MCCollapse.propTypes = {
  roomType: PropTypes.object.isRequired
};

MCCollapse.defaultProps = {
  roomType: {}
};

export default MCCollapse;
