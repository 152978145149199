import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import { SEPARATOR_DASH, STANDARD_RATE_CODE, RATE_DISTRIBUTION_TAB, RATE_DERIVATIVE, RATE_DERIVATIVES } from 'utils/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';
import intl from 'react-intl-universal';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[2];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.rate._id === rateId || rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

class CtripCMAdvancedOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,
      roomsOfRates: []
    };
  }

  componentDidMount = async () => {
    const { roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants } = this.props;

    this.setState({ isLoadingRateDistribution: true });

    const roomsOfRates = await this.getRoomsOfRates({ roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      roomMapping.map(async room => {
        const roomType = !!property.roomTypes ? Object.values(property.roomTypes).find(roomType => room.roomType === roomType._id) : '';
        const roomName = !!roomType ? roomType.name : '';
        const roomId = !!roomType ? roomType._id : '';

        const rates = await getWebRateByRoomType(room.roomType);
        const webRate = rates.data.find(rate => rate.code === STANDARD_RATE_CODE);

        const roomRates = room.rates.map(rateObject => {
          const otaId = String(rateObject.ctripRateId) || '';
          let calculation = rateObject.rate ? rateObject.rate.calculation || {} : {};

          if (!rateObject.rate || !rateObject.rate.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: webRate.weekday,
              weekend: webRate.weekend
            }
          };

          const rate = {
            isDerived: rateObject.rate ? rateObject.rate.isDerived : false,
            weekday: rateObject.rate ? rateObject.rate.weekday : 0,
            weekend: rateObject.rate ? rateObject.rate.weekend : 0,
            name: rateObject.ctripRateName,
            // id: rateObject.ctripRateId,
            calculation
          };

          return { otaId, rate };
        });

        return {
          roomId,
          roomName,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();

        const payload = {
          rateDistributionPayload: getRateDistributionPayload(fieldValues)
        };

        onConfirm(payload);
      }
    });
  };
  render() {
    const { roomsOfRates, isLoadingRateDistribution } = this.state;
    const { form, isVisible, onClose, currency } = this.props;

    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab={intl.get('hostConnect.integration.headerLabels.rateDistribution').d('Rate Distribution')} key="rateDistribution">
            <Skeleton loading={isLoadingRateDistribution} active>
              <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={currency} />
            </Skeleton>
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

CtripCMAdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  roomsOfRates: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired
};

CtripCMAdvancedOptionsModal.defaultProps = {
  isVisible: false,
  roomsOfRates: [],
  currency: 'RM',
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(CtripCMAdvancedOptionsModal);
