import React from 'react';
import { Icon, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';

const OTAStatusIcon = props => {
  let { tooltip, type, icon, color } = props;
  if (!color) {
    color = type === 'ok' ? '#289D48' : type === 'pending' ? '#FFD966' : type === 'error' ? '#FF5630' : type === 'nosync' ? '#bdbdbd' : '#FF5630';
  }
  if (icon) {
    return (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.others').d('Others')}>
        <Icon type={icon} theme="filled" style={{ color }} />
      </Tooltip>
    );
  } else {
    return type === 'ok' ? (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.syncActive').d('Synchronization Active')}>
        <Icon type="check-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : type === 'pending' ? (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.pending').d('Pending for Review')}>
        <Icon type="sync" spin="true" />
      </Tooltip>
    ) : type === 'error' ? (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.errorSync').d('Error while Synching')}>
        <Icon type="exclamation-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : type === 'nosync' ? (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.noSync').d('No Synchronization')}>
        <Icon type="minus-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : (
      <Tooltip title={tooltip || intl.get('hostConnect.integration.otaStatus.unknown').d('Unknown Status')}>
        <Icon type="question-circle" theme="filled" style={{ color }} />
      </Tooltip>
    );
  }
};

export default withRouter(OTAStatusIcon);
