import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getHosts } from '../../../utils/apis/host';
import { getBookingcomProduct, updateBookingcomHost } from '../../../utils/apis/integration';
import { Select, Form, Input, Modal, notification } from 'antd';
import intl from 'react-intl-universal';

const Option = Select.Option;
const FormItem = Form.Item;

class BookingcomListingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostOptions: [],
      isEdit: false,
      isSaving: false
    };
    this.isEdit = this.isEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    if (!this.props.hostOptions) {
      getHosts()
        .then(hostRes => {
          if (hostRes && hostRes.status === 200 && hostRes.data) {
            let hostOptions = hostRes.data.map(host => {
              return <Option value={host._id}>{host.name}</Option>;
            });
            this.setState({
              hostOptions
            });
          } else {
            // console.log('getHost have issues brah');
          }
        })
        .catch(ex => {
          // console.log(ex);
        });
    } else {
      this.setState({
        hostOptions: this.props.hostOptions
      });
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.bookingcom !== this.props.bookingcom) {
      let { setFieldsValue } = this.props.form;
      setFieldsValue({
        host: this.props.bookingcom ? this.props.bookingcom.host : '',
        bookingcomPropertyId: this.props.bookingcom && this.props.bookingcom.bookingcomPropertyId
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        isSaving: true
      });
      if (this.isEdit()) {
        let { bookingcom } = this.props;
        if (!bookingcom) {
          notification.error({
            message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
            description: (
              <div>
                <p>
                  <b>
                    {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.bookingcomPropertyId})
                  </b>
                </p>
                {intl.get('hostConnect.bookingcom.message.failDesc2').d('Try refresh the page')}
              </div>
            )
          });
        }
        updateBookingcomHost(bookingcom._id, values.host)
          .then(res => {
            this.setState({
              isSaving: false
            });
            if (this.props.refreshIntoEdit(bookingcom.bookingcomPropertyId)) {
              notification.success({
                message: `${intl.get('hostConnect.bookingcom.message.sucessMsg').d('Nice One')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.bookingcom.message.successDesc').d('Succesfully added Host to Booking.com Hotel')}</b>
                    </p>
                  </div>
                )
              });
              this.props.hideModal();
            } else {
              notification.error({
                message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.bookingcom.message.updateFailDesc1').d('Failed to update Booking.com Hotel')}</b>
                    </p>
                    {intl
                      .get('hostConnect.bookingcom.message.updateFailDesc2')
                      .d('We are quite sure the hotel was updated succesfully, try refreshing the page. If problem presist, contact ')}
                    <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
                      {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                    </a>
                    !
                  </div>
                )
              });
            }
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
              description: (
                <div>
                  <p>
                    <b>{intl.get('hostConnect.bookingcom.message.updateFailDesc1').d('Failed to update Booking.com Hotel')}</b>
                  </p>
                  {intl.get('hostConnect.ctrip.message.updateFailDesc2').d('Try refreshing the page. If problem presist, contact ')}
                  <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
                    {intl.get('hostConnect.ctrip.message.updateFailDesc3').d('tech support')}
                  </a>
                  !
                </div>
              )
            });
          });
      } else {
        notification.info({
          message: `${intl.get('hostConnect.bookingcom.message.asking').d('Asking Booking.com nicely for Hotel Id')} (${values.bookingcomPropertyId})`
        });
        getBookingcomProduct(values.bookingcomPropertyId)
          .then(res => {
            if (res && res.status === 200) {
              if (this.props.refreshIntoEdit(values.bookingcomPropertyId)) {
                notification.success({
                  message: `${intl.get('hostConnect.bookingcom.message.successRetrieveMsg').d('Succesfully retrieve Hotel')} (${
                    values.bookingcomPropertyId
                  })`,
                  description: (
                    <div>{intl.get('hostConnect.bookingcom.message.successRetrieveDesc').d('Please assign a host to this Booking.com Property')}</div>
                  )
                });
              } else {
                notification.error({
                  message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                  description: (
                    <div>
                      <p>
                        <b>
                          {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.bookingcomPropertyId})
                        </b>
                      </p>
                      {intl
                        .get('hostConnect.bookingcom.message.failRetrieveDesc')
                        .d('We are quite sure the hotel was retrieved succesfully, try refreshing the page. If problem presist, contact ')}
                      <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
                        {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                      </a>
                      !
                    </div>
                  )
                });
              }
            } else {
              notification.error({
                message: `${intl.get('hostConnect.bookingcom.message.failedRetrieveBooking').d('Failed to retrieve Booking.com Hotel')}`,
                description: (
                  <div>
                    {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.bookingcomPropertyId}){' '}
                    {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                    <div>
                      {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fbookingcom-connect"
                      >
                        {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                      </a>
                      !
                    </div>
                  </div>
                )
              });
            }
            this.setState({
              isSaving: false
            });
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.bookingcom.message.failedRetrieveBooking').d('Failed to retrieve Booking.com Hotel')}`,
              description: (
                <div>
                  {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.bookingcomPropertyId}){' '}
                  {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                  <div>
                    {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fbookingcom-connect"
                    >
                      {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                    </a>
                    !
                  </div>
                </div>
              )
            });
          });
      }
    });
  };

  isEdit() {
    let { bookingcom } = this.props;
    return !!bookingcom;
  }

  render() {
    let { bookingcom, visible, hideModal } = this.props;
    let { getFieldDecorator } = this.props.form;
    let { isSaving } = this.state;
    return (
      <Modal
        title={
          <React.Fragment>
            {bookingcom && bookingcom.propertyData
              ? `${intl.get('hostConnect.bookingcom.headerLabels.editing').d('Editing')} ${bookingcom.propertyData.HotelName} (${
                  bookingcom.bookingcomPropertyId
                })`
              : intl.get('hostConnect.bookingcom.headerLabels.newbooking').d('New Booking.com Property')}
          </React.Fragment>
        }
        visible={visible}
        onOk={
          !isSaving
            ? this.onSubmit
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl.get('hostConnect.bookingcom.message.desc1').d('We are still working on it.')
                });
              }
        }
        onCancel={
          !isSaving
            ? hideModal
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl
                    .get('hostConnect.bookingcom.message.desc2')
                    .d('We are still trying to pull from Booking.com, do not close this page yet.')
                });
              }
        }
        okText={intl.get('reservations.headerLabels.save').d('Save')}
        cancelText={intl.get('reservations.headerLabels.cancel').d('Cancel')}
        maskClosable={false}
      >
        <Form onSubmit={this.onSubmit}>
          {this.isEdit() ? (
            <React.Fragment>
              {/* <div>{`${bookingcom.propertyData ? bookingcom.propertyData.name || '<No hotel name>' : '<No hotel name>'} (${bookingcom.bookingcomPropertyId})`}</div> */}
              <FormItem label={intl.get('hostConnect.bookingcom.headerLabels.assignHost').d('Assign a host:')}>
                {getFieldDecorator('host', {
                  rules: []
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.hostOptions}
                  </Select>
                )}
              </FormItem>
            </React.Fragment>
          ) : (
            <FormItem label={intl.get('hostConnect.bookingcom.headerLabels.bookingId').d('Booking.com Property Id')}>
              {getFieldDecorator('bookingcomPropertyId', {
                rules: [
                  {
                    required: true,
                    pattern: '^[0-9]+$',
                    message: intl.get('hostConnect.bookingcom.placeholder.bookingIdMsg').d('Please insert numbers only!')
                  }
                ]
              })(<Input placeholder={intl.get('hostConnect.bookingcom.placeholder.bookingId').d('Booking.com Property Id (example: 263492)')} />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(withRouter(BookingcomListingForm));
