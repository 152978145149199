import React, { Component } from 'react';
import { withAppContext } from 'context/AppContext';

import ExpediaListingForm from './ExpediaListingForm';

import { getHosts } from '../../../utils/apis/host';
import { getExpedias } from '../../../utils/apis/integration';

import { Select, Table, Form, Card, Button, notification, Input, Icon } from 'antd';
import intl from 'react-intl-universal';

const Option = Select.Option;

class ExpediaListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expedias: [],
      hostMapping: [],
      showModal: false,
      selectedExpedia: null,
      searchPropertyId: '',
      searchPropertyName: '',
      searchHost: ''
    };
    this.hideModal = this.hideModal.bind(this);
    this.getExpediaData = this.getExpediaData.bind(this);
    this.constructColumn = this.constructColumn.bind(this);
    this.refreshIntoEdit = this.refreshIntoEdit.bind(this);
  }

  componentDidMount() {
    this.getExpediaData();
    getHosts()
      .then(hostRes => {
        if (hostRes && hostRes.status === 200 && hostRes.data) {
          let hostMapping = hostRes.data.map(host => {
            return {
              _id: host._id,
              name: host.name
            };
          });
          let hostOptions = hostRes.data.map(host => {
            return <Option value={host._id}>{host.name}</Option>;
          });
          this.setState({
            hostOptions,
            hostMapping
          });
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  async refreshIntoEdit(expediaPropertyId) {
    let expedias = await this.getExpediaData().catch(ex => {
      notification.error({
        message: ex
      });
    });
    let expedia = expedias.filter(expedia => expedia.expediaPropertyId.toString() === expediaPropertyId.toString())[0];
    if (expedia) {
      this.setState({
        selectedExpedia: expedia
      });
      return true;
    } else {
      notification.error({
        message: intl.get('hostConnect.expedia.message.refreshError').d('Something wrong whent trying to refresh expedia list')
      });
      return false;
    }
  }

  getExpediaData() {
    return new Promise((resolve, reject) => {
      getExpedias()
        .then(res => {
          if (res && res.status === 200) {
            this.setState({
              expedias: res.data
            });
            return resolve(res.data);
          } else {
            return reject('Failed to get expedia list');
          }
        })
        .catch(ex => {
          return reject('Failed to get expedia list');
        });
    });
  }

  renderExpediaTitle(expedia) {
    return (
      <div>
        {expedia.expediaPropertyName
          ? expedia.expediaPropertyName || intl.get('hostConnect.bookingcom.headerLabels.noPropertyName').d('No Property Name')
          : intl.get('hostConnect.bookingcom.headerLabels.noProperty').d('No Property')}
      </div>
    );
  }
  renderExpediaCardContent(expedia) {
    return (
      <div>
        {intl.get('hostConnect.bookingcom.headerLabels.hotelId').d('Hotel ID:')} {expedia.expediaPropertyId}
      </div>
    );
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  openForm = expedia => e => {
    if (!expedia) {
      this.setState({
        selectedExpedia: null,
        showModal: true
      });
    } else {
      this.setState({
        selectedExpedia: expedia,
        showModal: true
      });
    }
  };

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  constructColumn(isAllowEdit, checkIsAdminReadOnly) {
    const { hostMapping, expedias } = this.state;
    return [
      {
        title: intl.get('hostConnect.expedia.tableColumns.id').d('Expedia Property ID'),
        key: 'expediaPropertyId',
        dataIndex: 'expediaPropertyId',
        sorter: (a, b) => a.expediaPropertyId && b.expediaPropertyId && a.expediaPropertyId.localeCompare(b.expediaPropertyId),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.booking').d('Search Property ID')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyId"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.expediaPropertyId.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('hostConnect.expedia.tableColumns.status').d('Status'),
        key: 'status',
        dataIndex: 'status',
        filters: Array.from(
          new Set(
            expedias.map(item => {
              return item.status.charAt(0).toUpperCase() + item.status.slice(1);
            })
          )
        ).map(item => {
          return { text: intl.get(`hostConnect.ctrip.status.${item}`).d(item), value: item };
        }),
        onFilter: (value, record) => record.status.charAt(0).toUpperCase() === value.charAt(0).toUpperCase(),
        render: (text, record) => {
          return intl
            .get(`hostConnect.ctrip.status.${record.status.charAt(0).toUpperCase() + record.status.slice(1)}`)
            .d(record.status.charAt(0).toUpperCase() + record.status.slice(1));
        }
      },
      {
        title: intl.get('hostConnect.expedia.tableColumns.property').d('Expedia Property Name'),
        key: 'expediaPropertyName',
        dataIndex: 'expediaPropertyName',
        sorter: (a, b) => a.expediaPropertyName && b.expediaPropertyName && a.expediaPropertyName.localeCompare(b.expediaPropertyName),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.property').d('Search Property Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyName"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.expediaPropertyName.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('hostConnect.expedia.tableColumns.host').d('Host'),
        key: 'host',
        dataIndex: 'host',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.hostName').d('Search Host')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchHost"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.host) {
            let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === record.host.toString())[0];
            return hostMap.name.toLowerCase().includes(value.toLowerCase());
          } else {
            return '';
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (host, record) => {
          if (!host) {
            return intl.get('hostConnect.bookingcom.headerLabels.noHost').d('No Host Assigned');
            // return <Button onClick={this.showSetNickname(record)}>Assign A Host</Button>;
          }
          let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === host.toString())[0];
          if (hostMap) {
            return hostMap.name;
          } else {
            return host;
          }
        }
      },
      {
        title: intl.get('hostConnect.expedia.tableColumns.rooms').d('Expedia Rooms'),
        key: 'data.roomTypes',
        dataIndex: 'data.roomTypes',
        sorter: (a, b) => a.data.roomTypes.length - b.data.roomTypes.length,
        render: (rooms, record) => {
          return rooms.length > 0 ? rooms.length : '-';
        }
      },
      {
        title: intl.get('hostConnect.expedia.tableColumns.action').d('Actions'),
        key: 'actions',
        render: (x, record) => {
          return (
            <Button onClick={this.openForm(record)} disabled={!isAllowEdit || checkIsAdminReadOnly()}>
              {intl.get('hostConnect.ctrip.headerLabels.edit').d('Edit')}
            </Button>
          );
        }
      }
    ];
  }

  render() {
    let { showModal, selectedExpedia, hostOptions } = this.state;
    const { checkIsAllowCreateIntegration, checkIsAllowEditIntegration, checkIsAdminReadOnly } = this.props;

    const isAllowCreate = !!checkIsAllowCreateIntegration();
    const isAllowEdit = !!checkIsAllowEditIntegration();

    return (
      <React.Fragment>
        <ExpediaListingForm
          expedia={selectedExpedia}
          hostOptions={hostOptions}
          visible={showModal}
          hideModal={this.hideModal}
          refreshIntoEdit={this.refreshIntoEdit}
        />
        <Card className="list-card">
          <div className="list-card-control-section">
            <Button type="primary" icon="plus" onClick={this.openForm()} disabled={!isAllowCreate || checkIsAdminReadOnly()}>
              {intl.get('hostConnect.expedia.headerLabels.create').d('Create Expedia')}
            </Button>
          </div>
          <Table scroll={{ x: 1000 }} columns={this.constructColumn(isAllowEdit, checkIsAdminReadOnly)} dataSource={this.state.expedias} />
        </Card>
      </React.Fragment>
    );
  }
}

export default Form.create()(withAppContext(ExpediaListing));
