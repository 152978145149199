import React, { Component } from 'react';
import { withAppContext } from 'context/AppContext';

import AgodaListingForm from './AgodaListingForm';
import moment from 'moment';

import { getHosts } from '../../../utils/apis/host';
import { getAgodas } from '../../../utils/apis/integration';
import RegisterAgodaModal from './RegisterAgodaModal/RegisterAgodaModal';

import { Select, Table, Form, Card, Button, notification, Input, Icon, Row, Spin, Tooltip, Tag, Col } from 'antd';

import styles from './AgodaListing.module.css';
import CSVDownload from 'components/CSVDownload/CSVDownload';
import { guard } from 'utils/general';
import SignAgodaContact from './SignAgodaContractModal/SignAgodaContractModal';
import { getAgodaContractstatus } from 'utils/apis/constants';
import ManageAdgodaChainIdModal from './ManageAgodaChainIDModal/ManageAgodaChainIDModal';
import intl from 'react-intl-universal';

const Option = Select.Option;

class AgodaListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      agodas: [],
      hostMapping: [],
      showModal: false,
      selectedAgoda: null,
      searchPropertyId: '',
      searchPropertyName: '',
      searchHost: '',
      isLoadingCSV: true,
      csvData: [],
      csvName: '',
      registerAgodaModalVisible: false,
      signedAgodaContractVisible: false,
      agodaContractStatus: [],
      manageAgodaChainIdModalVisible: false
    };
    this.hideModal = this.hideModal.bind(this);
    this.getAgodaData = this.getAgodaData.bind(this);
    this.constructColumn = this.constructColumn.bind(this);
    this.refreshIntoEdit = this.refreshIntoEdit.bind(this);
  }

  componentWillMount() {
    this.getAgodaContractstatus();
    this.getAgodaData();

    getHosts()
      .then(hostRes => {
        if (hostRes && hostRes.status === 200 && hostRes.data) {
          let hostMapping = hostRes.data.map(host => {
            return {
              _id: host._id,
              name: host.name
            };
          });
          let hostOptions = hostRes.data.map(host => {
            return <Option value={host._id}>{host.name}</Option>;
          });
          this.setState({
            hostOptions,
            hostMapping
          });
        } else {
          // console.log('getHost have issues brah');
        }
      })
      .catch(ex => {
        // console.log(ex);
      });
  }

  async refreshIntoEdit(agodaPropertyId) {
    let agodas = await this.getAgodaData().catch(ex => {
      notification.error({
        message: ex
      });
    });
    let agoda = agodas.filter(agoda => agoda.agodaPropertyId.toString() === agodaPropertyId.toString())[0];
    if (agoda) {
      this.setState({
        selectedAgoda: agoda
      });
      return true;
    } else {
      notification.error({
        message: intl.get('hostConnect.agoda.message.refreshError').d('Something wrong whent trying to refresh agoda list')
      });
      return false;
    }
  }

  getAgodaContractstatus = async () => {
    const res = await getAgodaContractstatus();
    var agodaContractStatusList = [];
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        const data = res[key];
        agodaContractStatusList.push({
          text: intl.get(`hostConnect.agoda.contractStatus.${data.label}`).d(data.label),
          value: data.code
        });
      }
    }
    console.log(agodaContractStatusList, 107);
    this.setState({
      agodaContractStatus: agodaContractStatusList
    });
  };

  getAgodaData() {
    return new Promise((resolve, reject) => {
      getAgodas()
        .then(res => {
          if (res && res.status === 200) {
            console.log(res, 115);
            this.setState({
              agodas: res.data,
              isLoadingCSV: false,
              loading: false
            });
            return resolve(res.data);
          } else {
            this.setState({
              loading: false
            });
            return reject('Failed to get agoda list');
          }
        })
        .catch(ex => {
          this.setState({
            loading: false
          });
          return reject('Failed to get agoda list');
        });
    });
  }

  renderAgodaTitle(agoda) {
    return (
      <div>
        {agoda.propertyData
          ? agoda.propertyData.name || intl.get('hostConnect.bookingcom.headerLabels.noPropertyName').d('No Property Name')
          : intl.get('hostConnect.bookingcom.headerLabels.noProperty').d('No Property')}
      </div>
    );
  }
  renderAgodaCardContent(agoda) {
    return (
      <div>
        {intl.get('hostConnect.bookingcom.headerLabels.hotelId').d('Hotel ID:')} {agoda.agodaPropertyId}
      </div>
    );
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  openForm = agoda => e => {
    if (!agoda) {
      this.setState({
        selectedAgoda: null,
        showModal: true
      });
    } else {
      this.setState({
        selectedAgoda: agoda,
        showModal: true
      });
    }
  };

  openRegisterAgodaForm = () => {
    this.setState({
      registerAgodaModalVisible: true
    });
  };

  openManageAgodaChainModal = () => {
    this.setState({
      manageAgodaChainIdModalVisible: true
    });
  };

  openSignAgodaControactModal = () => {
    this.setState({
      signedAgodaContractVisible: true
    });
  };

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  handleSetCsvName = name => () => {
    this.setState({ csvName: name });
  };

  handleSetCsvData(data) {
    this.setState({ csvData: data });
  }

  handleOnClickCSVDownload = async (data, hostMapping) => {
    // const setName = setCSVName => {
    const currentTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = `Agoda_.${currentTime}.csv`;
    // };
    const constructCSVData = data => {
      return data.map(d => {
        return {
          Key: d._id,
          'Agoda Property ID': d.agodaPropertyId,
          'Agoda Property Name': d.propertyData.name,
          Status: d.status,
          'Platform Type': d.isHomes ? 'Agoda Home' : 'Agoda',

          'Agoda Rooms Connected': guard(() => d.rooms.length, '-'),

          Host: guard(() => {
            let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === d.host.toString())[0];
            if (hostMap) {
              return hostMap.name;
            } else {
              return d.host;
            }
          }, '-'),
          'Currency (Property Data)': guard(() => d.propertyData.currency, '-'),
          'Language (Property Data)': guard(() => d.propertyData.language, '-'),
          'Live Status (Property Data)': guard(() => d.propertyData.live_status, '-'),
          'Name (Property Data)': guard(() => d.propertyData.name, '-'),
          'Occupancy Model (Property Data)': guard(() => d.propertyData.occupancy_model, '-'),
          'Agoda Live Status': guard(() => d.pushNewListingStatus.status, '-'),
          'Created At': guard(() => d.createdAt, '-'),
          'Updated At': guard(() => d.updatedAt, '-')
        };
      });
    };

    // setName(setCSVName);
    // await setCSVData(constructCSVData(data));
    this.setState({
      csvName: csvName,
      csvData: constructCSVData(data)
    });
  };

  constructColumn(isAllowEdit, checkIsAdminReadOnly) {
    const { hostMapping, agodas } = this.state;
    return [
      {
        title: intl.get('hostConnect.agoda.tableColumns.id').d('Agoda Property ID'),
        key: 'agodaPropertyId',
        dataIndex: 'agodaPropertyId',
        sorter: (a, b) => a.agodaPropertyId && b.agodaPropertyId && a.agodaPropertyId.localeCompare(b.agodaPropertyId),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.booking').d('Search Property ID')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyId"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.agodaPropertyId.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.status').d('Status'),
        key: 'status',
        dataIndex: 'status',
        filters: Array.from(
          new Set(
            agodas.map(item => {
              return item.status.charAt(0).toUpperCase() + item.status.slice(1);
            })
          )
        ).map(item => {
          return { text: intl.get(`hostConnect.ctrip.status.${item}`).d(item), value: item };
        }),
        onFilter: (value, record) => record.status.charAt(0).toUpperCase() === value.charAt(0).toUpperCase(),
        render: (text, record) => {
          return intl
            .get(`hostConnect.ctrip.status.${record.status.charAt(0).toUpperCase() + record.status.slice(1)}`)
            .d(record.status.charAt(0).toUpperCase() + record.status.slice(1));
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.property').d('Agoda Property Name'),
        key: 'propertyData',
        dataIndex: 'propertyData',
        sorter: (a, b) => a.propertyData.name && b.propertyData.name && a.propertyData.name.localeCompare(b.propertyData.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.property').d('Search Property Name')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchPropertyName"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.propertyData.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: propertyData => {
          if (!propertyData) {
            return intl.get('hostConnect.bookingcom.headerLabels.noPropertyData').d('No Property Data');
          }
          if (!propertyData.name) {
            return intl.get('hostConnect.bookingcom.headerLabels.noPropertyName').d('No Property Name');
          }
          return propertyData.name;
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.host').d('Host'),
        key: 'host',
        dataIndex: 'host',
        sorter: (a, b) => {
          let hostMapA = hostMapping.find(hostMap => a.host && hostMap._id.toString() === a.host.toString());
          let hostMapB = hostMapping.find(hostMap => b.host && hostMap._id.toString() === b.host.toString());
          if (hostMapA && hostMapB) {
            return hostMapA.name && hostMapB.name && hostMapA.name.localeCompare(hostMapB.name);
          } else {
            return a.host;
          }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder={intl.get('tables.hostName').d('Search Host')}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchHost"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              {intl.get('tables.search').d('Search')}
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>{intl.get('tables.reset').d('Reset')}</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.host) {
            let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === record.host.toString())[0];
            if (hostMap) {
              return hostMap.name.toLowerCase().includes(value.toLowerCase());
            } else {
              return '';
            }
          }
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        },
        render: (host, record) => {
          if (!host) {
            return intl.get('hostConnect.bookingcom.headerLabels.noHost').d('No Host Assigned');
            // return <Button onClick={this.showSetNickname(record)}>Assign A Host</Button>;
          }
          let hostMap = hostMapping.filter(hostMap => hostMap._id.toString() === host.toString())[0];
          if (hostMap) {
            return hostMap.name;
          } else {
            return host;
          }
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.room').d('Agoda Rooms'),
        key: 'rooms',
        dataIndex: 'rooms',
        sorter: (a, b) => a.rooms.length - b.rooms.length,
        render: (rooms, record) => {
          return rooms ? rooms.length : 0;
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.type').d('Platform Type'),
        key: 'isHomes',
        dataIndex: 'isHomes',
        filters: [
          {
            text: 'Agoda Homes',
            value: true
          },
          {
            text: 'Agoda Hotel',
            value: false
          }
        ],
        onFilter: (value, record) => record.isHomes === value,
        render: (text, record) => {
          return record.isHomes ? 'AgodaHomes' : 'Agoda';
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.push').d('Push by Host Platform'),
        key: 'pushByHP',
        dataIndex: 'pushByHP',
        filters: [
          {
            text: intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes'),
            value: true
          },
          {
            text: intl.get('booking_form.guestDetails.alertMessage.no').d('No'),
            value: false
          }
        ],
        onFilter: (value, record) => record.pushByHP === value,
        render: (rooms, record) => {
          if (this.props.user.isAdmin === true) {
            return rooms
              ? intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')
              : intl.get('booking_form.guestDetails.alertMessage.no').d('No');
          } else {
            return '-';
          }
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.agodaLiveStatus').d('Agoda Live Status'),
        render: (data, records) => {
          if (records.pushByHP) {
            const {
              PropertyLived,
              ContractSigned,
              FraudCheckPassed,
              PhotosIngested,
              PropertyReady,
              RatePlansAvailable,
              RoomsAvailable
              // ReprocessRequired,
            } = data.pushNewListingStatus;
            // Render accordingly
            console.log(data, 455);
            const renderFunction = (name, statusType) => {
              return (
                <Tag
                  color={statusType === true ? 'green' : 'red'}
                  style={{
                    width: 170,
                    margin: 2
                  }}
                >
                  <Row type="flex" justify="start">
                    <Col span={19}>{name}</Col>
                    <Col span={1}>:</Col>
                    <Col span={4}>
                      {statusType === true
                        ? intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')
                        : intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
                    </Col>
                  </Row>
                </Tag>
              );
            };
            if (PropertyLived) {
              return (
                <Tooltip
                  title={
                    <Row style={{}}>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.contractSigned').d('Contract Signed'), ContractSigned)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.fraudCheck').d('Fraud Check Passed'), FraudCheckPassed)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.photosIngested').d('Photos Ingested'), PhotosIngested)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.propertyReady').d('Property Ready'), PropertyReady)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.ratePlansAvailable').d('Rate Plans Available'), RatePlansAvailable)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.roomAvailable').d('Rooms Available'), RoomsAvailable)}
                      </Col>
                    </Row>
                  }
                >
                  <Tag color="green">
                    <Icon type="check-circle" /> {intl.get('hostConnect.agoda.tableColumns.propertyLive').d('Property Live')}
                  </Tag>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip
                  title={
                    <Row>
                      <Col
                        span={24}
                        style={{
                          width: '100%'
                        }}
                      >
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.contractSigned').d('Contract Signed'), ContractSigned)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.fraudCheck').d('Fraud Check Passed'), FraudCheckPassed)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.photosIngested').d('Photos Ingested'), PhotosIngested)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.propertyReady').d('Property Ready'), PropertyReady)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.ratePlansAvailable').d('Rate Plans Available'), RatePlansAvailable)}
                      </Col>
                      <Col span={24}>
                        {renderFunction(intl.get('hostConnect.agoda.tableColumns.roomAvailable').d('Rooms Available'), RoomsAvailable)}
                      </Col>
                    </Row>
                  }
                >
                  <Tag color="red">
                    <Icon type="close-circle" /> {intl.get('hostConnect.agoda.tableColumns.propertyNotLive').d('Property Not Live')}
                  </Tag>
                </Tooltip>
              );
            }
          } else {
            return (
              <Tooltip title={intl.get('hostConnect.agoda.message.onlyApplicable').d('Only applicable to listing push by Host Platform.')}>-</Tooltip>
            );
          }
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.contractStatus').d('Contract Status'),
        key: 'agodaContractstatus',
        dataIndex: 'agodaContractstatus',
        filters: this.state.agodaContractStatus,
        width: '15%',
        onFilter: (value, record) => record.agodaContractstatus === value,
        render: (value, record) => {
          const filterInfo = this.state.agodaContractStatus.find(x => x.value === record.agodaContractstatus);
          if (filterInfo) {
            if (filterInfo.text === guard(() => this.state.agodaContractStatus.find(x => x.value === 'contractReady').text, false)) {
              return (
                <Button
                  disabled={record.signedAgodaContract === true || !record.pushByHP || record.pushByHP === false}
                  onClick={() => {
                    this.setState({
                      signedAgodaContractVisible: true,
                      selectedAgoda: record
                    });
                  }}
                >
                  {intl.get(`hostConnect.agoda.contractStatus.${filterInfo.text}`).d(filterInfo.text)}
                </Button>
              );
            }

            if (record.pushByHP) {
              return intl.get(`hostConnect.agoda.contractStatus.${filterInfo.text}`).d(filterInfo.text);
            } else {
              return '-';
            }
          } else {
            return '-';
          }
        }
      },
      {
        title: intl.get('hostConnect.agoda.tableColumns.action').d('Actions'),
        key: 'actions',
        render: (x, record) => {
          return (
            <Button onClick={this.openForm(record)} disabled={!isAllowEdit || checkIsAdminReadOnly()}>
              {intl.get('hostConnect.ctrip.headerLabels.edit').d('Edit')}
            </Button>
          );
        }
      }
    ];
  }

  render() {
    let { showModal, selectedAgoda, hostOptions, isLoadingCSV, csvData, csvName, hostMapping } = this.state;
    const { checkIsAllowCreateIntegration, checkIsAllowEditIntegration, checkIsAdminReadOnly } = this.props;
    const isAllowCreate = !!checkIsAllowCreateIntegration();
    const isAllowEdit = !!checkIsAllowEditIntegration();

    // console.log(384, this.state.agodas, hostMapping);

    return (
      <React.Fragment>
        <AgodaListingForm
          agoda={selectedAgoda}
          hostOptions={hostOptions}
          visible={showModal}
          hideModal={this.hideModal}
          refreshIntoEdit={this.refreshIntoEdit}
        />
        <Card className="list-card">
          <div className={styles.header}>
            <div>
              <Button type="primary" icon="plus" onClick={this.openForm()} disabled={!isAllowCreate || checkIsAdminReadOnly()}>
                {intl.get('hostConnect.agoda.headerLabels.connect').d('Connect with Agoda')}
              </Button>
            </div>
            <div>
              {this.props.user.isAdmin === true && (
                <Button
                  type="primary"
                  icon="plus"
                  onClick={this.openManageAgodaChainModal}
                  disabled={!isAllowCreate || checkIsAdminReadOnly()}
                  style={{
                    marginRight: 5
                  }}
                  loading={this.state.loading}
                >
                  {intl.get('hostConnect.agoda.headerLabels.hostChainId').d('Host Chain ID')}
                </Button>
              )}

              <Button
                type="primary"
                icon="plus"
                //  onClick={this.openForm()}
                onClick={this.openRegisterAgodaForm}
                disabled={!isAllowCreate || checkIsAdminReadOnly()}
                loading={this.state.loading}
              >
                {intl.get('hostConnect.agoda.headerLabels.pushListing').d('Push New Listing')}
              </Button>

              <CSVDownload
                id={'csv-button5-listing'}
                className={styles.csvDownload}
                filename={csvName}
                data={csvData}
                onClick={() => this.handleOnClickCSVDownload(this.state.agodas, hostMapping)}
                isLoading={isLoadingCSV}
                checkIsAdminReadOnly={checkIsAdminReadOnly}
              />
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <Table scroll={{ x: 1000 }} columns={this.constructColumn(isAllowEdit, checkIsAdminReadOnly)} dataSource={this.state.agodas} />
          </Spin>
          <RegisterAgodaModal
            visible={this.state.registerAgodaModalVisible}
            onCancel={() =>
              this.setState({
                registerAgodaModalVisible: false
              })
            }
          />
          <SignAgodaContact
            visible={this.state.signedAgodaContractVisible}
            onCancel={() =>
              this.setState({
                signedAgodaContractVisible: false
              })
            }
            selectedAgoda={this.state.selectedAgoda}
          />
          <ManageAdgodaChainIdModal
            visible={this.state.manageAgodaChainIdModalVisible}
            onCancel={() => this.setState({ manageAgodaChainIdModalVisible: false })}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default Form.create()(withAppContext(AgodaListing));
