import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import { REACT_APP_ENDPOINT } from 'config/env';

import PhotoDisplayCard from './components/PhotoDisplayCard/PhotoDisplayCard';

import styles from './UploadTaskPhoto.module.css';
import intl from 'react-intl-universal';

const uploadOptions = {
  server: REACT_APP_ENDPOINT,
  signingUrlQueryParams: { uploadType: 'avatar' }
};
const s3Url = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;

const UploadContainer = () => (
  <div className={styles.dragBoxContentContainer}>
    <div className={styles.dragBoxContentInnerContainer}>
      <p className={styles.dragBoxContentIcon}>
        <Icon type="picture" />
      </p>
      <p className={styles.dragBoxContentTitle}>
        {intl.get('taskManagement.taskList.headerLabels.dragImage').d('Click or drag image(s) to this area to upload')}
      </p>
    </div>
  </div>
);

class UploadTaskPhoto extends React.Component {
  render() {
    const { containerClassname, photos, onCaptionChange, onImageDelete, onImageMakePrimary, onUploadFinish, disabled } = this.props;
    return (
      <Row type="flex" className={containerClassname} gutter={{ xs: 0, md: 8, lg: 16 }}>
        {photos.map((photo, index) => (
          <Col key={photo.link} span={24} md={12} lg={8} className={styles.photoContainer}>
            <PhotoDisplayCard
              key={index}
              imageLink={photo.link}
              imageCaption={photo.caption}
              onCaptionChange={onCaptionChange(index)}
              onDelete={onImageDelete(index)}
              onMakePrimaryClick={onImageMakePrimary(index)}
            />
          </Col>
        ))}
        <Col span={24} md={photos.length > 0 ? 12 : 24} lg={photos.length > 0 ? 8 : 24} className={styles.photoContainer}>
          {!disabled && (
            <DropzoneS3Uploader
              onFinish={onUploadFinish}
              s3Url={s3Url}
              maxSize={1024 * 1024 * 5}
              upload={uploadOptions}
              className={styles.dragBoxContainer}
            >
              <UploadContainer />
            </DropzoneS3Uploader>
          )}
        </Col>
      </Row>
    );
  }
}

UploadTaskPhoto.propTypes = {
  onCaptionChange: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onImageMakePrimary: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  containerClassname: PropTypes.string,
  photos: PropTypes.array
};

UploadTaskPhoto.defaultProps = {
  containerClassname: '',
  photos: [],
  onCaptionChange: () => {},
  onImageDelete: () => {},
  onImageMakePrimary: () => {},
  onUploadFinish: () => {}
};

export default UploadTaskPhoto;
