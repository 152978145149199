import React from 'react';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormTimePicker from 'components/FormTimePicker/FormTimePicker';
import intl from 'react-intl-universal';

class BasicDetailsCard extends React.Component {
  // NOTE: Mainly for performance gain, if this component doesn't render as expected, try to comment out this function and test again
  shouldComponentUpdate = nextProps => {
    return nextProps.hasFetchedPropertyTypes !== this.props.hasFetchedPropertyTypes || nextProps.shouldUpdate;
  };
  checkMaxIsLongerThanMin = (rule, value, callback) => {
    const form = this.props.form;
    if (Number(value) < Number(form.getFieldValue('minNight'))) {
      callback('Max night should be more than min night');
    } else {
      callback();
    }
  };

  validateMaxNight = (rule, value, callback) => {
    const form = this.props.form;
    form.validateFields(['maxNight'], { force: true });
    callback();
  };

  render() {
    const { form, cardClassname, hasFetchedPropertyTypes, propertyTypes, defaultValues } = this.props;

    console.log('type', form.getFieldValue('propertyTypes'));

    return (
      <Card title={intl.get('host.headerLabels.basic').d('Basic Information')} className={cardClassname}>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name={'name'}
              defaultValue={defaultValues.name}
              inputType="input"
              label={intl.get('listings.property.name').d('Property Name')}
              placeholder={intl.get('listings.property.property').d('Type in your property name')}
              requiredErrorMessage={intl.get('listings.property.propertyMsg').d('Please provide a property name')}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              name="propertyTypes"
              label={intl.get('listings.property.propertyTypeName').d('Property Type')}
              requiredErrorMessage={intl.get('listings.property.propertyTypeMsg').d('Please select your property type')}
              placeholder={intl.get('listings.property.propertyType').d('Choose your property type')}
              form={form}
              defaultValue={defaultValues.type}
              shouldDisable={!hasFetchedPropertyTypes}
              selections={propertyTypes}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormTimePicker
              form={form}
              label={intl.get('listings.property.checkIn').d('Check in time')}
              fieldName="checkInTime"
              ruleMessage={intl.get('listings.property.placeholder.checkIn').d('Please provide check in time.')}
              defaultValue={defaultValues.checkInTime || '14:00'}
              size="large"
              minuteStep={30}
            />
          </Col>
          <Col span={24} md={12}>
            <FormTimePicker
              form={form}
              label={intl.get('listings.property.checkOut').d('Check out time')}
              fieldName="checkOutTime"
              ruleMessage={intl.get('listings.property.placeholder.checkOut').d('Please provide check out time.')}
              defaultValue={defaultValues.checkOutTime || '14:00'}
              size="large"
              minuteStep={30}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              label={intl.get('listings.property.minNight').d('Min. night(s)')}
              name="minNight"
              placeholder={intl.get('listings.property.placeholder.minNight').d('Leave empty for no limit')}
              minValue={1}
              extraRules={[
                {
                  validator: this.validateMaxNight
                }
              ]}
              defaultValue={defaultValues.minNight}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              label={intl.get('listings.property.maxNight').d('Max. night(s)')}
              name="maxNight"
              placeholder={intl.get('listings.property.placeholder.minNight').d('Leave empty for no limit')}
              minValue={1}
              extraRules={[
                {
                  validator: this.checkMaxIsLongerThanMin
                }
              ]}
              defaultValue={defaultValues.maxNight}
              size="large"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

BasicDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  shouldUpdate: PropTypes.bool
};

BasicDetailsCard.defaultProps = {
  defaultValues: {},
  shouldUpdate: true
};

export default BasicDetailsCard;
