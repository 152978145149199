import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Col, Icon, Row, Table, Tooltip } from 'antd';
import moment from 'moment';

import { withAppContext } from 'context/AppContext';
import { useGetHosts } from 'utils/apis/host';
import { useGetPaginatedHostInventory, useGetHostInventoryUsage, useGetHostInventoryCsvData } from 'utils/apis/billingInventory';

import { guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import { BasicSelect } from 'components/FormSelection/FormSelection';

import { BodyContainer, HeaderContainer, InventoryContainer, StatBoxContainer, StatTitle, StatValue } from './BillingInventory.styles';
import intl from 'react-intl-universal';

const MONTH_YEAR_FORMAT = 'YYYY-MM';

const useHostOptions = (getUserHosts, checkIsAdmin) => {
  const [selectedHostId, setSelectedHostId] = useState();

  const constructHostOptions = useCallback(hosts => {
    const hostOptions = hosts.map(host => ({ label: host.name, value: host._id }));
    return hostOptions;
  }, []);

  const { data: hostOptions, isLoading: isLoadingHostOptions } = useGetHosts(constructHostOptions);

  useEffect(() => {
    if (!selectedHostId) {
      if (checkIsAdmin() && hostOptions) {
        setSelectedHostId(hostOptions[0].value);
      } else {
        const userHosts = getUserHosts();
        setSelectedHostId(userHosts[0]);
      }
    }
  }, [selectedHostId, hostOptions, getUserHosts, checkIsAdmin]);

  return { isLoadingHostOptions, hostOptions, selectedHostId, setSelectedHostId };
};

const constructColumns = () => {
  const PLAN_ACTIVE_SELECTIONS = [
    { label: intl.get('invoice.headerLabels.active').d('Active'), value: true },
    { label: intl.get('invoice.headerLabels.inactive').d('Inactive'), value: false }
  ];

  return [
    {
      ...constructColumn(intl.get('invoice.tableColumns.unit').d('Unit'), 'unitName', { hasSorter: true }),
      ...constructColumnFilterSearch('unitName', intl.get('tables.unit').d('Search Unit Name')),
      render: (text, record) => {
        if (record.isUnitDeleted) {
          return (
            <>
              {text}{' '}
              <Tooltip title={intl.get('invoice.message.unitDeleted').d('This unit has been deleted')}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </>
          );
        } else {
          return <Link to={`/listing/${record.unitId}`}>{text}</Link>;
        }
      }
    },
    {
      ...constructColumn(intl.get('invoice.tableColumns.property').d('Property'), 'propertyName', { hasSorter: true }),
      ...constructColumnFilterSearch('propertyName', intl.get('tables.property').d('Search Property Name'))
    },
    {
      ...constructColumn(intl.get('invoice.tableColumns.hostManage').d('HostManage'), 'isHostManageActive'),
      ...constructColumnFilterRadio('isHostManageActive', PLAN_ACTIVE_SELECTIONS),
      render: isHostManageActive => {
        return isHostManageActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn(intl.get('invoice.tableColumns.hostConnect').d('HostConnect'), 'isHostConnectActive'),
      ...constructColumnFilterRadio('isHostConnectActive', PLAN_ACTIVE_SELECTIONS),
      render: isHostConnectActive => {
        return isHostConnectActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn(intl.get('invoice.tableColumns.dora').d('D.O.R.A'), 'isDORAActive'),
      ...constructColumnFilterRadio('isDORAActive', PLAN_ACTIVE_SELECTIONS),
      render: isDoraActive => {
        return isDoraActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn(intl.get('invoice.tableColumns.hostProtect').d('HostProtect Expired Date'), 'hostProtectExpiredDate', { hasSorter: true })
    }
  ];
};

const StatisticBox = ({ title, value, loading }) => (
  <StatBoxContainer>
    <StatTitle span={24}>{title}</StatTitle>
    <StatValue span={24}>{loading ? <Icon type="loading" spin /> : value}</StatValue>
  </StatBoxContainer>
);

const DownloadCsvButton = ({ monthWithYear, hostId, hostOptions }) => {
  const { data: csvData, isLoading: isCsvDataLoading } = useGetHostInventoryCsvData(monthWithYear, hostId);
  let newCsvData = [];
  if (Array.isArray(csvData) && csvData.length > 0) {
    csvData.forEach(hostData => {
      const translatedHostData = {};
      // Iterate over the key-value pairs of each hostData object
      for (const key in hostData) {
        const value = hostData[key];
        if (Object.hasOwnProperty.call(hostData, key)) {
          if (key === 'Host Connect') {
            value = intl.get(`csv.backendKey.${value}`).d(value);
          }
          if (key === 'Host Manage') {
            value = intl.get(`csv.backendKey.${value}`).d(value);
          }
          if (key === 'DORA') {
            value = intl.get(`csv.backendKey.${value}`).d(value);
          }
        }
        const translatedKey = intl.get(`csv.backendKey.${key}`).d(key);
        translatedHostData[translatedKey] = value;
        //console.log(`${intl.get(`csv.backendKey.${key}`).d(key)}: ${value}`);
      }
      newCsvData.push(translatedHostData);
    });
  }

  const getCsvFileName = () => {
    const defaultFileName = 'Inventory Reporting';
    const stringMonthYear = moment(monthWithYear).format('MMM YYYY');
    const defaultFileNameWithMonthYear = `${stringMonthYear} - ${defaultFileName}`;

    const hostLabel = guard(() => hostOptions.find(option => option.value === hostId).label);
    const fileName = !!hostLabel ? `${hostLabel} - ${defaultFileNameWithMonthYear}` : `${defaultFileNameWithMonthYear}`;

    return `${fileName}.csv`;
  };

  return (
    <CSVLink filename={getCsvFileName()} data={newCsvData || []}>
      <Button type="primary" icon="download" loading={isCsvDataLoading}>
        {isCsvDataLoading ? intl.get('invoice.headerLabels.fetching').d('Fetching...') : intl.get('invoice.headerLabels.download').d('Download CSV')}
      </Button>
    </CSVLink>
  );
};

const BillingInventory = ({ getUserHosts, checkIsAdmin }) => {
  const [monthWithYear, setMonthWithYear] = useState(moment().format(MONTH_YEAR_FORMAT));
  const [query, setQuery] = useState({});
  const [isFetchCsvButtonClicked, setIsFetchCsvButtonClicked] = useState(false);

  const { isLoadingHostOptions, hostOptions, selectedHostId, setSelectedHostId } = useHostOptions(getUserHosts, checkIsAdmin);
  const { paginatedData: inventoryStatuses, total, isLoading: isFetchingInventoryStatuses } = useGetPaginatedHostInventory(
    { ...query, monthWithYear },
    selectedHostId
  );
  const { data: unitUsageStats, isLoading: isUnitUsageStatsLoading } = useGetHostInventoryUsage(monthWithYear, selectedHostId);

  useEffect(() => {
    setIsFetchCsvButtonClicked(false);
  }, [selectedHostId, monthWithYear]);

  const constructUnitUsageStats = useCallback(
    stats =>
      guard(
        () => [
          { title: intl.get('invoice.headerLabels.entryPlan').d('Entry Plan'), value: stats.entryPlan || 0 },
          { title: intl.get('invoice.headerLabels.starterPlan').d('Starter Plan'), value: stats.starterPlan || 0 },
          { title: intl.get('invoice.headerLabels.standardPlan').d('Standard Plan'), value: stats.standardPlan || 0 },
          { title: intl.get('invoice.headerLabels.hostProtect').d('Host Protect'), value: stats.hostProtect || 0 }
        ],
        []
      ),
    []
  );

  const handleOnMonthYearChange = date => {
    const formattedDate = date.format(MONTH_YEAR_FORMAT);
    setMonthWithYear(formattedDate);
  };

  return (
    <InventoryContainer>
      <HeaderContainer>
        <Row type="flex" justify="end" gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
          <Col>
            <MonthPicker defaultValue={moment(monthWithYear)} onChange={handleOnMonthYearChange} />
          </Col>
          {!isLoadingHostOptions && (
            <Col>
              <BasicSelect
                label={intl.get('invoice.headerLabels.hostLabel').d('Host')}
                selections={hostOptions}
                value={selectedHostId}
                onChange={setSelectedHostId}
                placeholder="No Host"
              />
            </Col>
          )}
        </Row>
      </HeaderContainer>

      <BodyContainer>
        <Row gutter={[16, { xs: 16, sm: 16, lg: 0 }]}>
          {constructUnitUsageStats(unitUsageStats).map(stat => (
            <Col span={24} sm={12} md={6} key={stat.title}>
              <StatisticBox title={stat.title} value={stat.value} loading={isUnitUsageStatsLoading} />
            </Col>
          ))}
        </Row>
        <Row type="flex" justify="end" style={{ marginBottom: 20, marginTop: 40 }}>
          {isFetchCsvButtonClicked ? (
            <DownloadCsvButton hostId={selectedHostId} monthWithYear={monthWithYear} hostOptions={hostOptions} />
          ) : (
            <Button type="primary" icon="download" onClick={() => setIsFetchCsvButtonClicked(true)}>
              {intl.get('invoice.headerLabels.csv').d('Fetch CSV Data')}
            </Button>
          )}
        </Row>
        <Table
          loading={isFetchingInventoryStatuses}
          rowKey={record => record.unitId}
          dataSource={inventoryStatuses}
          columns={constructColumns()}
          pagination={{ total }}
          scroll={{ x: 1000 }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </BodyContainer>
    </InventoryContainer>
  );
};

export default withAppContext(BillingInventory);
