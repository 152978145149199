import React, { Component } from 'react';
import { Row, Col } from 'antd';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';

import { SEPARATOR_DASH, PRICING_TAB, CLEANING_FEE, CHARGE_TYPE } from 'utils/constants';
import { getConstants } from 'utils/apis/constants';
import intl from 'react-intl-universal';

const PricingFormInputNumber = ({ form, name, label, currency = 'RM' }) => {
  return (
    <FormInputNumber
      form={form}
      name={name}
      label={label}
      precision={2}
      minValue={0}
      maxValue={9999}
      formatter={value => value && `${currency} ${value}`}
      parser={value => value.replace(/[RM]\s?/g, '')}
      defaultValue={0}
    />
  );
};

class AdvancedOptionsBookingcomPricingTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldNameCleaningFee: `${PRICING_TAB}${SEPARATOR_DASH}${CLEANING_FEE}`,
      fieldNameChargeType: `${PRICING_TAB}${SEPARATOR_DASH}${CHARGE_TYPE}`,

      chargeTypeConstants: []
    };
  }

  componentDidMount = async () => {
    let { pricingSetting } = this.props;

    this.setPricing(pricingSetting);

    const chargeTypeConstants = await getConstants('bookingcomFeePolicy').then(res => Object.values(res.data.CHARGE_TYPE));

    this.setState({ chargeTypeConstants });
  };

  setPricing = pricingSetting => {
    const { fieldNameCleaningFee, fieldNameChargeType } = this.state;
    const { form } = this.props;

    const cleaningFee = Number(pricingSetting.cleaningFee) / 10 ** Number(pricingSetting.decimalPlaces) || 0;
    const chargeType = Number(pricingSetting.chargeType) || 12;
    form.setFieldsValue({
      [fieldNameCleaningFee]: cleaningFee,
      [fieldNameChargeType]: chargeType
    });
  };

  render() {
    const { fieldNameCleaningFee, fieldNameChargeType, chargeTypeConstants } = this.state;
    const { form, currency } = this.props;

    const chargeTypeOptions = chargeTypeConstants.map(type => {
      return {
        key: type.code,
        value: type.label
      };
    });

    return (
      <Row>
        <h3>{intl.get('hostConnect.integration.headerLabels.cleaningFee').d('Cleaning Fee')}</h3>
        <Col span={12}>
          <PricingFormInputNumber
            form={form}
            name={fieldNameCleaningFee}
            label={`${intl.get('hostConnect.integration.headerLabels.cleaningFee').d('Cleaning Fee')} (${currency})`}
            currency={currency}
          />
        </Col>
        <Col span={12}>
          <FormSelection
            form={form}
            name={fieldNameChargeType}
            label={intl.get('hostConnect.integration.headerLabels.chargeTypeLabel').d('Charge Type')}
            defaultValue={12}
            selections={chargeTypeOptions}
            allowClear={false}
          />
        </Col>
      </Row>
    );
  }
}

export default AdvancedOptionsBookingcomPricingTab;
