import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import { SEPARATOR_DASH, STANDARD_RATE_CODE, RATE_DISTRIBUTION_TAB, RATE_DERIVATIVE, RATE_DERIVATIVES } from 'utils/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';

import AdvancedOptionsModal from '../../../../../OTAIntegration/OTAForm/components/AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../../OTAIntegration/OTAForm/components/AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => String(room.roomId) === String(roomId));

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: {} };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }
  return payload;
};

class RateOptionModal extends Component {
  static propTypes = {
    roomMapping: PropTypes.array.isRequired,
    rateModifierTypeConstants: PropTypes.array.isRequired,
    rateModifierSignConstants: PropTypes.array.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isVisible: false,
    onConfirm: () => {},
    onClose: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,

      roomsOfRates: [],
      pricingSetting: {}
    };
  }

  componentDidMount = async () => {
    const { roomMapping, rateModifierTypeConstants, rateModifierSignConstants } = this.props;
    let { pricingSetting } = this.props;

    this.setState({ isLoadingRateDistribution: true, pricingSetting });

    const roomsOfRates = await this.getRoomsOfRates({ roomMapping, rateModifierTypeConstants, rateModifierSignConstants });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ roomMapping, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      roomMapping.map(async room => {
        const roomType = room;
        const roomName = !!roomType ? (roomType.externalDisplayName ? roomType.externalDisplayName : roomType.name) : '';
        const roomId = !!roomType ? roomType.key : '';

        const rates = await getWebRateByRoomType(roomId);
        const webRate = rates.data.find(rate => rate.code === STANDARD_RATE_CODE);

        const roomRates = [room.rate].map(rateObject => {
          let calculation = rateObject.calculation;

          if (!rateObject.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: webRate.weekday,
              weekend: webRate.weekend
            }
          };

          const rate = {
            isDerived: rateObject.isDerived,
            weekday: rateObject.weekday,
            weekend: rateObject.weekend,
            calculation
          };

          return { rate };
        });

        return {
          roomId,
          roomName,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();

        const payload = {
          rateDistributionPayload: getRateDistributionPayload(fieldValues)
        };

        onConfirm(payload);
      }
    });
  };

  render() {
    const { isLoadingRateDistribution, roomsOfRates } = this.state;
    const { form, isVisible, onClose } = this.props;
    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Skeleton loading={isLoadingRateDistribution} active>
          <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={this.props.currency} />
        </Skeleton>
      </AdvancedOptionsModal>
    );
  }
}

RateOptionModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  roomMapping: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

RateOptionModal.defaultProps = {
  isVisible: false,
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(RateOptionModal);
