import React from 'react';
import { Modal, Row, Switch, Col, Button, Tag } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { BasicSelect } from 'components/FormSelection/FormSelection';
import MonthPicker from 'components/MonthPicker/MonthPicker';

import styles from './Header.module.css';
import intl from 'react-intl-universal';

const CUSTOM_TAG_STYLE = {
  red: { borderColor: '#db4537', color: '#db4537', backgroundColor: '#fff1f0' },
  blue: {
    borderColor: '#00b9c6',
    color: '#00b9c6',
    backgroundColor: '#e6f7ff'
  }
};

const Header = ({
  hostOptions,
  ownerOptions,
  selectedHostId,
  selectedOwnerId,
  selectedOwnerName,
  defaultDateValue,
  onMonthChange,
  onHostChange,
  onOwnerChange,
  isPayoutFreeze,
  handleOnPayoutFreeze,
  month,
  year,
  handleOnToggleModal,
  isOwner,
  checkIsAdminReadOnly
}) => {
  const handleOnMonthChange = async date => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth();

    onMonthChange(year, month);
  };

  const handleOnHostChange = async (currentHostId, hostOptions) => {
    if (currentHostId !== selectedHostId) {
      const currentHost = hostOptions.find(hostOption => hostOption.value === currentHostId);
      const currentHostImage = currentHost.image;
      const currentHostOwners = currentHost.owners;

      onHostChange(currentHostId, currentHostImage, currentHostOwners);
    }
  };

  const handleOnOwnerChange = async (currentOwnerId, currentOwnerElement) => {
    if (currentOwnerId !== selectedOwnerId) {
      const currentOwnerName = currentOwnerElement.props.label;

      onOwnerChange(currentOwnerId, currentOwnerName);
    }
  };

  return (
    <Row type="flex" justify="space-between" className="rep-title" align="middle">
      {/* <Col className="rep-wrapper" style={{ marginBottom: '8px' }}> */}
      {/* <label className="el-pageTitle">Audit Payout: </label> */}
      {isPayoutFreeze && !isOwner && (
        <Tag
          style={{
            ...CUSTOM_TAG_STYLE.blue
          }}
        >
          {intl.get('payout.headerLabels.audited').d('Audited')}
        </Tag>
      )}
      {!isPayoutFreeze && !isOwner && <Tag>{intl.get('payout.headerLabels.unaudited').d('Unaudited')}</Tag>}

      {!isOwner && (
        <Button id="ea-button1-payout" onClick={handleOnToggleModal} type="primary" style={{ marginRight: '4px' }} disabled={checkIsAdminReadOnly()}>
          {intl.get('payout.headerLabels.auditWizard').d('Audit Wizard')}
        </Button>
      )}
      {/* </Col>
      <Col className="rep-wrapper">
        <Row type="flex" justify="end"> */}
      <MonthPicker
        className={styles.firstRowItem}
        label={intl.get('payout.headerLabels.month').d('Month: ')}
        defaultValue={defaultDateValue}
        onChange={handleOnMonthChange}
      />
      <BasicSelect
        label={intl.get('payout.headerLabels.host').d('Host')}
        selections={hostOptions}
        value={selectedHostId}
        selectClassName={`${styles.firstRowItem} ${styles.selection}`}
        placeholder={intl.get('payout.placeholder.host').d('Select a host')}
        onChange={currentHostId => handleOnHostChange(currentHostId, hostOptions)}
      />
      <BasicSelect
        label={intl.get('payout.headerLabels.owner').d('Owner')}
        selections={ownerOptions}
        value={selectedOwnerId}
        selectClassName={`${styles.firstRowItem} ${styles.selection}`}
        placeholder={intl.get('payout.placeholder.owner').d('Select a Owner')}
        onChange={handleOnOwnerChange}
      />
      {/* </Row>
      </Col> */}
    </Row>
  );
};

Header.propTypes = {
  hostOptions: PropTypes.array.isRequired,
  ownerOptions: PropTypes.array.isRequired,
  selectedHostId: PropTypes.string.isRequired,
  selectedOwnerId: PropTypes.string.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onHostChange: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired
};

export default Header;
