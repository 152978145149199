import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { DropTarget } from 'react-dnd';
import moment from 'moment';

import { checkIsOverlapped, numberWithCommas, differenceBetweenDates, constructShortenedFinancialNumber } from 'utils/general';
import MCReservation from '../MCReservation/MCReservation';
import MCModalReservationOnDrop from '../MCModalReservationOnDrop/MCModalReservationOnDrop';

import styles from './MCRatePerDay.module.css';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const DATE_FORMAT = 'YYYY-MM-DD';

const checkIsNonProfitBooking = (reservationsColorsConstants, bookingType) => {
  return (
    !!bookingType &&
    !!reservationsColorsConstants.find(reservationDetail => reservationDetail.bookingType === bookingType && reservationDetail.isNonProfitBookingType)
  );
};

const checkIsBlockReservation = (reservationsColorsConstants, bookingType) => {
  return (
    !!bookingType &&
    !!reservationsColorsConstants.find(reservationDetail => reservationDetail.bookingType === bookingType && reservationDetail.isBlockRservation)
  );
};

const constructDraggedReservationStatus = ({
  integrationSourceOptions,
  unitReservations,
  sourcePropertyId,
  targetPropertyId,
  sourceUnitId,
  targetUnitId,
  draggedReservation,
  currentStartDate,
  draggedReservationDuration,
  preProcessReservationEndDate
}) => {
  const { _id, startDate, endDate, source, blockedBy } = draggedReservation;

  const currentEndDate = moment(currentStartDate)
    .add(draggedReservationDuration, 'days')
    .format(DATE_FORMAT);
  const processedCurrentEndDate = preProcessReservationEndDate(currentEndDate);
  const processedEndDate = preProcessReservationEndDate(endDate);

  // check if is clashed
  const onDragIsClashedReservation =
    unitReservations.filter(unitReservation => {
      const processedUnitReservationEndDate = preProcessReservationEndDate(unitReservation.endDate);

      return (
        unitReservation._id !== _id &&
        checkIsOverlapped(currentStartDate, processedCurrentEndDate, unitReservation.startDate, processedUnitReservationEndDate)
      );
    }).length > 0;

  // check if is changing Property/Unit/Date
  const isUpdateToDifferentProperty = sourcePropertyId !== targetPropertyId;
  const isUpdateToDifferentUnit = sourceUnitId !== targetUnitId;
  const isUpdateToDifferentDate = startDate !== currentStartDate || processedEndDate !== processedCurrentEndDate;

  // check if updating integrated OTA startDate or endDate
  const integratedOta = integrationSourceOptions.find(integrationSource => integrationSource.isIntegrated && integrationSource.value === source);
  const isListingOta = !!integratedOta && (integratedOta.value === 'agodahomes' || integratedOta.value === 'airbnb');
  const isIntegratedOta = !!integratedOta;
  const integratedOtaLabel = isListingOta || isIntegratedOta ? integratedOta.label : '';

  return {
    onDragIsClashedReservation,
    isUpdateToDifferentProperty,
    isUpdateToDifferentUnit,
    isUpdateToDifferentDate,
    isListingOta,
    isIntegratedOta,
    integratedOtaLabel,
    blockedBy
  };
};

const generateMCReservations = ({
  reservations,
  reservationCounter,
  propertyId,
  roomTypeId,
  unitId,
  unitName,
  integrationSourceOptions,
  bookingTypeOptions,
  bookingStatusOptions,
  month,
  reservationsColorsConstants,
  handleOnMCRateDateActionSuccess,
  permissions,
  permissionConstants,
  isAdmin,
  currency
}) => {
  reservations.sort((reservation1, reservation2) => (reservation1.endDate > reservation2.endDate ? -1 : 1));
  const formattedReservations = [];

  for (let i = 0; i < reservations.length; i++) {
    const reservation = reservations[i];
    const { startDate, endDate } = reservation;
    const moveDownNumber = i + reservationCounter - reservations.length;
    // const firstDayOfTheMonth = moment(month)
    //   .startOf('month')
    //   .format(DATE_FORMAT);

    // const moveDownNumber = startDate === firstDayOfTheMonth && reservations.length > 1 ? 0 : i + reservationCounter - reservations.length;

    const reservationDuration = differenceBetweenDates(startDate, endDate);
    formattedReservations.push(
      <MCReservation
        key={reservation._id}
        reservation={reservation}
        duration={reservationDuration}
        propertyId={propertyId}
        roomTypeId={roomTypeId}
        unitId={unitId}
        unitName={unitName}
        integrationSourceOptions={integrationSourceOptions}
        bookingTypeOptions={bookingTypeOptions}
        bookingStatusOptions={bookingStatusOptions}
        month={month}
        moveDownPx={moveDownNumber * 6} // 6 is the number of px to move down
        reservationsColorsConstants={reservationsColorsConstants}
        checkIsNonProfitBooking={checkIsNonProfitBooking}
        checkIsBlockReservation={checkIsBlockReservation}
        handleOnMCRateDateActionSuccess={handleOnMCRateDateActionSuccess}
        permissions={permissions}
        permissionConstants={permissionConstants}
        isAdmin={isAdmin}
        currency={currency}
      />
    );
  }

  return formattedReservations;
};

const dropTarget = {
  drop(props, monitor) {
    const {
      propertyId,
      roomTypeId,
      unitId,
      unitName,
      integrationSourceOptions,
      date,
      unitReservations,
      preProcessReservationEndDate,
      handleOnMCRateDateActionSuccess
    } = props;
    const sourcePropertyId = monitor.getItem().sourcePropertyId;
    const sourceUnitId = monitor.getItem().sourceUnitId;
    const duration = monitor.getItem().duration;
    const draggedReservation = monitor.getItem().reservation;

    if (draggedReservation) {
      const updatedDraggedReservation = {
        ...draggedReservation,
        targetUnitId: unitId,
        targetUnitName: unitName,
        startDate: date,
        endDate: moment(date)
          .add(duration, 'days')
          .format(DATE_FORMAT)
      };

      const draggedReservationStatus = constructDraggedReservationStatus({
        integrationSourceOptions,
        unitReservations,
        sourcePropertyId,
        targetPropertyId: propertyId,
        sourceUnitId,
        targetUnitId: unitId,
        draggedReservation,
        currentStartDate: date,
        draggedReservationDuration: duration,
        preProcessReservationEndDate
      });

      const {
        onDragIsClashedReservation,
        isUpdateToDifferentProperty,
        isUpdateToDifferentUnit,
        isUpdateToDifferentDate,
        isListingOta,
        isIntegratedOta,
        integratedOtaLabel,
        blockedBy
      } = draggedReservationStatus;

      MCModalReservationOnDrop({
        roomTypeId,
        reservation: updatedDraggedReservation,
        isClashed: onDragIsClashedReservation,
        isUpdateToDifferentProperty,
        isUpdateToDifferentUnit,
        isUpdateToDifferentDate,
        isListingOta,
        isIntegratedOta,
        integratedOtaLabel,
        blockedBy,
        handleOnMCRateDateActionSuccess
      });
    }
  }
};

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    item: monitor.getItem()
  };
};

class RatePerDay extends Component {
  render() {
    const {
      ratePerDayWidth,

      isRoomType,
      inventory,
      rates,
      reservations,
      reservationCounter,

      propertyId,
      unitId,
      roomTypeId,
      unitName,
      integrationSourceOptions,
      bookingTypeOptions,
      bookingStatusOptions,

      onClickRateDay,
      onHoverRateDay,

      backgroundColor,
      date,
      month,

      unitReservations,

      preProcessReservationEndDate,
      reservationsColorsConstants,
      handleOnMCRateDateActionSuccess,
      permissions,
      permissionConstants,
      isAdmin,
      currency,
      timezone
    } = this.props;

    // dnd props
    const { connectDropTarget, isOver, item } = this.props;
    const height = rates.length === 1 ? 50 : 25;

    let onDragHoverHighlightColor = '';
    let onDragHoverHighlightWidth = 0;
    let onDragHoverHighlightHeight = 0;

    // if is dragging a Reservation over this Rate Day
    if (isOver) {
      const sourcePropertyId = item.sourcePropertyId;
      const sourceUnitId = item.sourceUnitId;
      const duration = item.duration;
      const draggedReservation = item.reservation;

      const draggedReservationStatus = constructDraggedReservationStatus({
        integrationSourceOptions,
        unitReservations,
        sourcePropertyId,
        targetPropertyId: propertyId,
        sourceUnitId,
        targetUnitId: unitId,
        roomTypeId,
        draggedReservation,
        currentStartDate: date,
        draggedReservationDuration: duration,
        preProcessReservationEndDate,
        handleOnMCRateDateActionSuccess
      });

      const {
        onDragIsClashedReservation,
        isIntegratedOta,
        isUpdateToDifferentProperty,
        isUpdateToDifferentDate,
        blockedBy
      } = draggedReservationStatus;

      const isDropActionBlocked = !!blockedBy || isUpdateToDifferentProperty || (isIntegratedOta && isUpdateToDifferentDate);

      if (isDropActionBlocked) {
        onDragHoverHighlightColor = styles.onDragReservationHighlightActionBlocked;
      } else if (onDragIsClashedReservation) {
        onDragHoverHighlightColor = styles.onDragReservationHighlightClashed;
      } else {
        onDragHoverHighlightColor = styles.onDragReservationHighlight;
      }
      onDragHoverHighlightWidth = ratePerDayWidth * duration || 0;
      onDragHoverHighlightHeight = height * rates.length;
    }

    // Room Types
    if (isRoomType) {
      return (
        <Row className={`${styles.rateBlockPerDay} ${styles.roomTypeBlock} ${backgroundColor}`}>
          <Col className={`${styles.rateBlock} ${styles.inventoryBox} ${styles.inventoryDetailBox}`}>{inventory}</Col>

          {/* Rates, will resize the row based on Rates.Size */}
          {rates.map((rate, index) => (
            <Col
              key={index}
              style={{ width: `${ratePerDayWidth}px`, height: `${height}px`, lineHeight: `${height}px` }}
              className={styles.rateBlock}
              onClick={onClickRateDay}
              onMouseOver={onHoverRateDay}
            >
              {`${currency ? currency : ''} ${constructShortenedFinancialNumber(rate, false)}`}
            </Col>
          ))}
        </Row>
      );
    } else {
      return connectDropTarget(
        <div className={`${styles.rateBlockPerDay} ${backgroundColor}`}>
          {reservations.length > 0 &&
            generateMCReservations({
              reservations,
              reservationCounter,
              propertyId,
              roomTypeId,
              unitId,
              unitName,
              integrationSourceOptions,
              bookingTypeOptions,
              bookingStatusOptions,
              month,
              reservationsColorsConstants,
              handleOnMCRateDateActionSuccess,
              permissions,
              permissionConstants,
              isAdmin,
              currency
            })}
          {/* draw highlight when dragging reservation */}
          <div
            className={`${styles.onDragReservation} ${onDragHoverHighlightColor}`}
            style={{ width: `${onDragHoverHighlightWidth}px`, height: `${onDragHoverHighlightHeight}px` }}
          />
          {/* Rates, will resize the row based on Rates.Size */}
          {rates.map((rate, index) => (
            <Col
              key={index}
              style={{ width: `${ratePerDayWidth}px`, height: `${height}px`, lineHeight: `${height}px` }}
              className={styles.rateBlock}
              onClick={onClickRateDay}
              onMouseOver={onHoverRateDay}
            >
              {`${currency} ${constructShortenedFinancialNumber(rate, false)}`}
            </Col>
          ))}
        </div>
      );
    }
  }
}

export default DropTarget('draggedReservation', dropTarget, collect)(withAppContext(RatePerDay));
