import React, { useState } from 'react';
import { Table, Button, Icon, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import OTALogo from 'components/OTALogo/OTALogo';
import intl from 'react-intl-universal';

import { useGetPaginatedReservations } from 'utils/apis/reservation';
import { useFetchConstant } from 'hooks/constants';
import { cleanNumber } from 'utils/general';
import { handleOnAggregationTableChange, constructColumn, constructColumnFilterSearch } from 'utils/table/table';

import CsvModal from './../components/CsvModal/CsvModal';
import PdfCardModal from 'components/ReceiptTemplate/PdfCardModal';

import './Reservation.css';

import { MIN_INTL_CONTACT_NO_LEN } from 'utils/constants';
import { withAppContext } from 'context/AppContext';

const constructColumns = (bookingTypes, generateReport, { filters, sorter }, checkIsAdminReadOnly) => {
  const getReceiptRenderProp = bookingTypes => ({
    render: (text, record) => {
      const bookingType = !!bookingTypes.find(type => type.code === text && type.hasPrice);
      if (bookingType) {
        return (
          <span>
            <Button type="primary" onClick={generateReport(record._id)} disabled={checkIsAdminReadOnly()}>
              <Icon type="download" />
            </Button>
          </span>
        );
      } else {
        return;
      }
    }
  });

  const getCodeRenderProp = () => ({
    render: (text, record) => (
      <Link to={`/reservation/${record._id}/edit`}>
        <OTALogo otaCode={record.platform} text={text} size="small" />
      </Link>
    )
  });

  const getContactNoRenderProp = () => ({
    render: text => {
      const contactNo = text || 'N/A';
      return contactNo && contactNo.length > MIN_INTL_CONTACT_NO_LEN ? (
        <Tooltip title={intl.get('reservations.headerLabels.chat').d('Chat on WhatsApp')}>
          <a href={`https://wa.me/${cleanNumber(contactNo)}`} target="_blank" rel="noopener noreferrer">
            {contactNo}
          </a>
        </Tooltip>
      ) : (
        <>{contactNo}</>
      );
    }
  });

  return [
    {
      ...constructColumn(intl.get('reservations.tableColumns.code').d('Confirmation Code'), 'code', { hasSorter: true, sorter, width: 150 }),
      ...constructColumnFilterSearch('code', intl.get('tables.confirmationCode').d('Search Confirmation Code'), filters),
      ...getCodeRenderProp()
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.checkIn').d('Check-in Date'), 'startDate', { hasSorter: true, sorter })
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.checkOut').d('Check-out Date'), 'endDate', { hasSorter: true, sorter })
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.pax').d('Number of pax'), 'pax', { hasSorter: false, isObj: true, isNumber: true })
      // ...constructColumnFilterSearch('propertyName', 'Search Property Name', filters)
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.addOns').d('Add-ons'), 'addon', { hasSorter: false, isObj: true })
      // ...constructColumnFilterSearch('addon', 'Search Add On', filters)
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.guest').d('Guest Name'), 'guestName', { hasSorter: true, sorter }),
      ...constructColumnFilterSearch('guestName', intl.get('tables.guestName').d('Search Guest Name'), filters)
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.contactNumber').d('Contact Number'), 'contactNumber'),
      ...constructColumnFilterSearch('contactNumber', intl.get('tables.contactNo').d('Search Contact Number'), filters),
      ...getContactNoRenderProp()
    },
    {
      ...constructColumn(intl.get('reservations.tableColumns.receipt').d('Receipt'), 'bookingType'),
      ...getReceiptRenderProp(bookingTypes)
    }
  ];
};

const useFetchConstants = () => {
  const { selection: bookingStatuses, isLoading: isBookingStatusesLoading } = useFetchConstant('bookingStatus');
  const { selection: bookingTypes, isLoading: isBookingTypesLoading } = useFetchConstant('bookingTypes');
  const { selection: states, isLoading: isStatesLoading } = useFetchConstant('statesMY');
  const { selection: countries, isLoading: isCountriesLoading } = useFetchConstant('countries', { valueKey: 'iso2', labelKey: 'name' });

  const selections = { bookingStatuses, bookingTypes, states, countries };
  const isLoading = isBookingStatusesLoading || isBookingTypesLoading || isStatesLoading || isCountriesLoading;

  return { selections, isLoading };
};

const AddOn = props => {
  const [query, setQuery] = useState({ isAddOn: true });
  const [tableQuery, setTableQuery] = useState({});
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [isCSVModalVisible, setIsCSVModalVisible] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState();
  const {
    selections: { bookingStatuses, bookingTypes, states, countries },
    isLoading: isConstantLoading
  } = useFetchConstants();
  const { paginatedData: reservations, total, isLoading: isReservationsLoading } = useGetPaginatedReservations(query);
  const { checkIsAdminReadOnly } = props;
  const isLoading = isConstantLoading || isReservationsLoading;

  const generateReport = reservationId => e => {
    setSelectedReservationId(reservationId);
    setIsReceiptModalVisible(true);
  };

  const handleClose = () => {
    setSelectedReservationId();
    setIsReceiptModalVisible(false);
  };

  const onTableDataChange = newQuery => {
    setTableQuery(newQuery);
    handleOnAggregationTableChange(newQuery, setQuery);
  };

  const handleOnClickClearAll = () => {
    const newQuery = {
      pagination: { current: 1, pageSize: 10 },
      filters: {},
      sorter: {},
      isAddOn: true
    };
    onTableDataChange(newQuery);
  };

  return (
    <>
      <div className="sp-controls">
        <div></div>
        <div>
          <Button onClick={handleOnClickClearAll} style={{ marginRight: 5 }}>
            {intl.get('reservations.headerLabels.reset').d('Reset All')}
          </Button>
          <Button id="csv-button1-res" icon="download" type="primary" onClick={() => setIsCSVModalVisible(true)} disabled={checkIsAdminReadOnly()}>
            {intl.get('reservations.headerLabels.csv').d('Download CSV')}
          </Button>
        </div>
      </div>
      <Table
        style={{ whiteSpace: 'pre' }}
        rowKey={record => record._id}
        columns={constructColumns(bookingTypes, generateReport, tableQuery, checkIsAdminReadOnly)}
        dataSource={reservations}
        scroll={{ x: 1000 }}
        loading={isLoading}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter, isAddOn: true })}
        pagination={{ total }}
      />
      {selectedReservationId && <PdfCardModal reservationId={selectedReservationId} visible={isReceiptModalVisible} handleClose={handleClose} />}
      <CsvModal
        visible={isCSVModalVisible}
        closeModal={() => setIsCSVModalVisible(false)}
        bookingStatus={bookingStatuses}
        bookingTypes={bookingTypes}
        countries={countries}
        states={states}
        isAddOn={true}
      />
    </>
  );
};

export default withAppContext(AddOn);
