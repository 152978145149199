import React, { Component } from 'react';
import { Table, Button, Card } from 'antd';
import { getRevenueShare } from '../../utils/apis/revenueshare';
import { withRouter, Link } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';
import './RevenueShare.css';
import intl from 'react-intl-universal';

class RevenueShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      searchNameText: '',
      searchUnitText: ''
    };
  }

  columns = checkIsAdminReadOnly => [
    {
      title: intl.get('revenueShare.tableColumns.revenue').d('Revenue Share Name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        if (checkIsAdminReadOnly()) {
          return <span>{name}</span>;
        } else {
          return <Link to={'/revenueshare/' + record._id + '/edit'}>{name}</Link>;
        }
      }
    }
  ];

  componentWillMount = () => {
    getRevenueShare().then(res => {
      if (res.status === 200 && res.data) {
        this.setState({
          data: res.data
        });
      } else {
      }
    });
  };

  render() {
    const { checkIsAdminReadOnly } = this.props;

    return (
      <Card className="rs-list-card">
        <div className="list-card-control-section">
          <Link to={'/revenueshare/new'}>
            <Button type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
              {intl.get('revenueShare.headerLabels.create').d('Create Revenue Share')}
            </Button>
          </Link>
        </div>
        <Table loading={!this.state.data} columns={this.columns(checkIsAdminReadOnly)} dataSource={this.state.data} />
      </Card>
    );
  }
}

export default withAppContext(withRouter(RevenueShare));
