import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter, Link } from 'react-router-dom';
import { Card, Select, Col, Icon, Table, Button, Spin, Tooltip, Tag, Modal, message } from 'antd';

import { getConstants } from 'utils/apis/constants';
import { constructFullName, cleanNumber, guard, getConstantLabel } from 'utils/general';
import { calculatePaymentReceived, calculateTotalAmount, calculateTotalServiceFee } from 'utils/transaction';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterCheckbox } from 'utils/table/table';
import { updateReservation } from 'utils/apis/reservation';
import { getDifferenceBetweenDate } from 'utils/date';

import OTALogo from 'components/OTALogo/OTALogo';

import styles from './ReservationList.module.css';

import { MIN_INTL_CONTACT_NO_LEN } from 'utils/constants';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const { Option } = Select;
const getSumAmount = amountObj => {
  return amountObj ? Object.keys(amountObj).reduce((sum, key) => (key === 'details' ? sum : sum + parseFloat(amountObj[key] || 0)), 0) : 0;
};

class ReservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateMYConstant: [],
      countriesConstant: [],
      taskStatusConstant: [],
      bookingStatusConstant: [],
      bookingTypeConstant: [],

      showPrice: false

      // bookingStatusOptions: []
    };
  }

  componentDidMount = async () => {
    const stateMYConstant = await getConstants('statesMY').then(resState => Object.values(resState.data));
    const countriesConstant = await getConstants('countries').then(resCountry => Object.values(resCountry.data));
    const taskStatusConstant = await getConstants('taskStatusMC').then(resTask => {
      return Object.values(resTask.data).map(data => ({ ...data, value: data.label }));
    });
    const bookingStatusConstant = await getConstants('bookingStatus').then(resBooking => {
      return Object.values(resBooking.data)
        .map(data => ({ ...data, value: data.code }))
        .filter(bs => bs.code !== 'Nebula Cancelled/ Possible Error' && bs.code !== 'Stripe payment is not completed');
    });
    const bookingTypesConstant = await getConstants('bookingTypes').then(resBooking => {
      return Object.values(resBooking.data).map(data => ({ ...data, value: data.code }));
    });

    this.setState({
      stateMYConstant: stateMYConstant,
      countriesConstant: countriesConstant,
      taskStatusConstant: taskStatusConstant,
      bookingStatusConstant: bookingStatusConstant,
      bookingTypesConstant: bookingTypesConstant,
      showPrice: this.props.checkIsAdmin() || this.props.checkIsAllowViewTransaction()
    });
  };

  getBookingStatusLabelForCSV = bookingStatusCode => {
    const { bookingStatusConstant } = this.state;
    const bookingStatusLabel = getConstantLabel(bookingStatusConstant, bookingStatusCode);
    return bookingStatusLabel || '-';
  };

  getBookingTypeLabelForCSV = (bookingTypesCode = 0) => {
    const { bookingTypesConstant } = this.state;
    const bookingTypeLabel = getConstantLabel(bookingTypesConstant, bookingTypesCode);
    return bookingTypeLabel || '-';
  };

  getPropertyAddress = (property, stateMYConstant, countriesConstant) => {
    const propertyState = stateMYConstant.find(state => state.code === property.state) || property.state;
    const propertyCountry = countriesConstant.find(country => country.iso2 === property.countryCode);

    let propertyAddressArray = [];
    propertyAddressArray.push(property.street);
    propertyAddressArray.push(property.city);
    propertyAddressArray.push(property.zipCode);
    propertyAddressArray.push(propertyState.label);
    propertyAddressArray.push(propertyCountry.name);

    return propertyAddressArray.join(', ');
  };

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  getReservationListCSV = (reservationListData, stateMYConstant, countriesConstant, showPrice) => {
    let reservationListCSV = [];

    for (let i = 0; i < reservationListData.length; i++) {
      const reservation = reservationListData[i];
      const {
        bookingType,
        bookingStatus,
        code,
        createdAt,
        createdBy,
        charges,
        deposit,
        endDate,
        guestDetails,
        payment,
        priceDetails,
        remarks,
        transactionRemarks,
        startDate,
        source,
        platform,
        tax,
        addon,
        unit
      } = reservation;

      const checkInDate = startDate;
      const checkOutDate = endDate;

      const guestName = constructFullName(guestDetails.userProfile.firstName, guestDetails.userProfile.lastName, guestDetails.userProfile.middleName);
      const guestContact = guard(() => guestDetails.userProfile.contactNos[0], '-');

      const unitName = unit.name;

      const property = unit.roomType.property;
      const propertyName = property.name;
      // const propertyAddress = this.getPropertyAddress(property, stateMYConstant, countriesConstant);

      // const outstandingAmounts = getSumAmount(charges) - getSumAmount(payment);
      // const outstandingAmountsFormatted = 'RM' + numberWithCommas(parseFloat(outstandingAmounts));

      const paymentDetails = this.getPaymentDetails(payment);
      // let moreThanOneItem = (a, b) => {
      //   return a + ' ;' + (b.item ? b.item : '-');
      // };
      // let moreThanOneLabel = (a, b) => {
      //   return a + ' ;' + (b.label ? b.label : '-');
      // };
      let calculateTotalAddOnAmount = (addons = {}) => {
        const allAddOns =
          addons &&
          Object.keys(addons).map(key => {
            return addons[key] && addons[key] > 0 ? addons[key] : 0;
          });
        return allAddOns.reduce((total, amount) => total + amount, 0);
      };

      const createdByName = constructFullName(createdBy.userProfile.firstName, createdBy.userProfile.lastName, '');

      // const sourceProcessed = !!source ? source : '-';

      // const remarksProcessed = !!remarks ? remarks : '-';

      if (showPrice) {
        reservationListCSV.push({
          [intl.get('csv.bookingType').d('Booking Type')]: intl
            .get(`booking_form.bodyLabels.bookingTypeOptions.${this.getBookingTypeLabelForCSV(bookingType)}`)
            .d(this.getBookingTypeLabelForCSV(bookingType)),
          [intl.get('csv.hostName').d('Host Name')]: unit.roomType.property.host.name || '',
          [intl.get('csv.propertyName').d('Property Name')]: propertyName,
          [intl.get('csv.roomType').d('Room Type')]: unit.roomType.name,
          [intl.get('csv.unitName').d('Unit Name')]: unitName,
          [intl.get('csv.checkIn').d('Check In')]: checkInDate,
          [intl.get('csv.checkOut').d('Check out')]: checkOutDate,
          [intl.get('csv.confirmationCode').d('Confirmation Code')]: code || '',
          [intl.get('csv.createdAt').d('Created At')]: createdAt || '',
          [intl.get('csv.createdBy').d('Created By')]: createdByName || '',
          [intl.get('csv.bookingSource').d('Booking Source')]: platform || source || '',
          [intl.get('csv.bookingStatus').d('Booking Status')]: intl
            .get(`reservations.reservationDetails.bookingStatus.${this.getBookingStatusLabelForCSV(bookingStatus)}`)
            .d(this.getBookingStatusLabelForCSV(bookingStatus)),
          [intl.get('csv.pax').d('Number of pax')]: guestDetails.numberOfPax || 0,
          [intl.get('csv.remark').d('Remarks')]: remarks || '',
          [intl.get('csv.depositCollected').d('Deposit Collected')]: (deposit && deposit.collect) || 0,
          [intl.get('csv.depositRefunded').d('Deposit Deposit Refunded')]: (deposit && deposit.refund) || 0,
          [intl.get('csv.roomRate').d('Room Rate')]: (charges && charges.rental) || 0,
          [intl.get('csv.platformFee').d('Platform Fee')]: (priceDetails && priceDetails.platformFee) || 0,
          [intl.get('csv.cleaningFee').d('Cleaning Fee')]: (charges && charges.cleaning) || 0,
          [intl.get('csv.extraGuest').d('Extra Guest Fee')]: (charges && charges.extraGuest) || 0,
          [intl.get('csv.earlyCheckIn').d('Early Check-in Fee')]: (charges && charges.earlyCheckIn) || 0,
          [intl.get('csv.lateCheckOut').d('Late Check-out Fee')]: (charges && charges.lateCheckOut) || 0,
          [intl.get('csv.shuttleFee').d('Shuttle Fee')]: (charges && charges.shuttle) || 0,
          [intl.get('csv.transportation').d('Transportation Fee')]: (charges && charges.transportation) || 0,
          [intl.get('csv.breakfast').d('Breakfast Fee')]: (charges && charges.breakfast) || 0,
          [intl.get('csv.lunch').d('Lunch Fee')]: (charges && charges.lunch) || 0,
          [intl.get('csv.dinner').d('Dinner Fee')]: (charges && charges.dinner) || 0,
          [intl.get('csv.other').d('Other Fee')]: (charges && charges.other) || 0,
          [intl.get('csv.addOns').d('Add-ons')]: addon ? calculateTotalAddOnAmount(addon) : 0,
          [intl.get('csv.serviceFee').d('Total Service Fee')]: calculateTotalServiceFee(charges),
          [intl.get('csv.price').d('Total Price')]: calculateTotalAmount(charges, tax, addon),
          [intl.get('csv.nights').d('Total Nights')]: getDifferenceBetweenDate(startDate, endDate) || '',
          [intl.get('csv.averagePrice').d('Average Price per Night')]:
            calculateTotalAmount(charges, tax, addon) / (getDifferenceBetweenDate(startDate, endDate) || 1),
          [intl.get('csv.sst').d('Sales Service Tax')]: (tax && tax.sst) || 0,
          [intl.get('csv.tourism').d('Tourism Tax')]: (tax && tax.tourism) || 0,
          [intl.get('csv.heritage').d('Heritage Tax')]: (tax && tax.heritage) || 0,
          [intl.get('csv.firstName').d('First Name')]: guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
          [intl.get('csv.lastName').d('Last Name')]: guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
          [intl.get('csv.guest').d('Guest Name')]: guestName,
          [intl.get('csv.contact').d('Contact Number')]: guestContact,
          [intl.get('csv.paymentMethod').d('Payment Method')]: paymentDetails.methods,
          [intl.get('csv.credit/debit').d('Credit Card/Debit Card No.')]: paymentDetails.cardNos,
          [intl.get('csv.expiry').d('Expiry Date')]: paymentDetails.expiryDates,
          [intl.get('csv.paymentRemarks').d('Payment Remarks')]: paymentDetails.remarks,
          [intl.get('csv.transactionRemarks').d('Transaction Remarks')]: transactionRemarks,
          [intl.get('csv.paymentReceived').d('Payment Received')]: calculatePaymentReceived(payment)
        });
      } else {
        reservationListCSV.push({
          [intl.get('csv.bookingType').d('Booking Type')]: this.getBookingTypeLabelForCSV(bookingType),
          [intl.get('csv.hostName').d('Host Name')]: unit.roomType.property.host.name || '',
          [intl.get('csv.propertyName').d('Property Name')]: propertyName,
          [intl.get('csv.roomType').d('Room Type')]: unit.roomType.name,
          [intl.get('csv.unitName').d('Unit Name')]: unitName,
          [intl.get('csv.checkIn').d('Check In')]: checkInDate,
          [intl.get('csv.checkOut').d('Check out')]: checkOutDate,
          [intl.get('csv.nights').d('Total Nights')]: getDifferenceBetweenDate(startDate, endDate) || '',
          [intl.get('csv.confirmationCode').d('Confirmation Code')]: code || '',
          [intl.get('csv.createdAt').d('Created At')]: createdAt || '',
          [intl.get('csv.createdBy').d('Created By')]: createdByName || '',
          [intl.get('csv.bookingSource').d('Booking Source')]: platform || source || '',
          [intl.get('csv.bookingStatus').d('Booking Status')]: this.getBookingStatusLabelForCSV(bookingStatus),
          [intl.get('csv.pax').d('Number of pax')]: guestDetails.numberOfPax || 0,
          [intl.get('csv.remark').d('Remarks')]: remarks || '',
          [intl.get('csv.firstName').d('First Name')]: guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
          [intl.get('csv.lastName').d('Last Name')]: guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
          [intl.get('csv.guest').d('Guest Name')]: guestName,
          [intl.get('csv.contact').d('Contact Number')]: guestContact
        });
      }
    }

    // if empty data
    if (!reservationListCSV || !reservationListCSV.length) {
      reservationListCSV.push({
        [intl.get('csv.no').d('No.')]: intl.get('csv.NODATA').d('NO DATA'),
        [intl.get('csv.checkIn').d('Check In')]: '-',
        [intl.get('csv.checkOut').d('Check out')]: '-',
        [intl.get('csv.name').d('Name')]: '-',
        [intl.get('csv.hp').d('H/P')]: '-',
        [intl.get('csv.unitName').d('Unit Name')]: '-',
        [intl.get('csv.propertyName').d('Property Name')]: '-',
        [intl.get('csv.propertyAddr').d('Property Address')]: '-',
        [intl.get('csv.outstandingPayment').d('Outstanding Payment')]: '-',
        [intl.get('csv.source').d('Source')]: '-',
        [intl.get('csv.remarkFromReservation').d('Remark from Reservation')]: '-'
      });
    }

    return reservationListCSV;
  };

  renderDownloadCSVButton = reservationList => {
    const { stateMYConstant, countriesConstant, showPrice } = this.state;

    if (!!reservationList && stateMYConstant.length > 0 && countriesConstant.length > 0) {
      return (
        <CSVLink
          target="_blank"
          filename={`${reservationList.csvName}.csv`}
          data={this.getReservationListCSV(reservationList.data, stateMYConstant, countriesConstant, showPrice)}
        >
          <Button id={reservationList.printButtonId} type="primary" icon="download" className={`${styles.cardTitleButton}`}>
            {intl.get('overview.headerLabels.csv').d('Download CSV')}
          </Button>
        </CSVLink>
      );
    } else {
      return <Spin className={`${styles.cardTitleButton}`} />;
    }
  };

  handleOnBookingStatusChange = (res, bookingStatus) => {
    Modal.confirm({
      title: intl
        .get('overview.message.bookingChangeTitle', { code: res.code })
        .d(`Are you sure you want to update booking status for booking ${res.code}?`),
      content: intl.get('overview.message.bookingChangeContent').d('You will not be able to undo this action, but you may update it again.'),
      onOk() {
        updateReservation(res._id, { bookingStatus: bookingStatus })
          .then(res => {
            if (res.status === 200) {
              message.success(intl.get('overview.message.reservationUpdated').d('Reservation updated!'));
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else if (res.status === 403) {
              message.warning(intl.get('overview.message.reservationError').d('Reservation cannot be updated due to dependency.'));
            } else if (res.status === 400 && res.data.code === '30001') {
              message.error(intl.get('overview.message.reservationError2').d('Reservation is not updated due to clashing with other reservation.'));
            } else {
              message.error(intl.get('overview.message.reservationError3').d('Something went wrong and reservation is not updated.'));
            }
          })
          .catch(ex => {
            console.log(ex);
            message.error(ex);
          });
      },
      onCancel() {}
    });
  };

  renderCleaningStatus = taskStatus => {
    const status = taskStatus.status;
    const taskId = taskStatus.taskId;
    let style = { marginTop: 4 };

    switch (status) {
      case 'Ready':
        return (
          <Col style={{ ...style }}>
            <Tag style={{ ...style }} color="green">
              {intl.get('overview.headerLabels.room').d('Room')} {intl.get(`overview.cleaningStatus.${status}`).d(status)}
            </Tag>
          </Col>
        );

      case 'Cleaning':
        return (
          <Col style={{ ...style }}>
            <Tooltip
              title={
                <a href={`/task-management/task/${taskId}/edit`}>
                  <Icon type="link" /> {intl.get('overview.headerLabels.viewTask').d('View Task')}
                </a>
              }
            >
              <Tag style={{ ...style, color: '#FFB800', border: '1px solid #FFB800', backgroundColor: '#fffbe6' }}>
                {intl.get('overview.headerLabels.room').d('Room')} {intl.get(`overview.cleaningStatus.${status}`).d(status)}
              </Tag>
            </Tooltip>
          </Col>
        );

      case 'Dirty':
        return (
          <Col style={{ ...style }}>
            <Tooltip
              title={
                <a href={`/task-management/task/${taskId}/edit`}>
                  <Icon type="link" /> {intl.get('overview.headerLabels.viewTask').d('View Task')}
                </a>
              }
            >
              <Tag color="red">
                {intl.get('overview.headerLabels.room').d('Room')} {intl.get(`overview.cleaningStatus.${status}`).d(status)}
              </Tag>
            </Tooltip>
          </Col>
        );

      default:
        return (
          <Col style={{ ...style }}>
            <Tag>{intl.get('overview.headerLabels.noTask').d('No Task Available')}</Tag>
          </Col>
        );
    }
  };

  constructColumns = ({ filters, sorter }) => {
    const getCodeRenderProp = () => ({
      render: (text, record) => (
        <Link to={`/reservation/${record._id}/edit`}>
          <OTALogo otaCode={record.platform} text={text} size="small" />
        </Link>
      )
    });

    const getContactNoRenderProp = () => ({
      render: text => {
        const contactNo = text || 'N/A';
        return contactNo && contactNo.length > MIN_INTL_CONTACT_NO_LEN ? (
          <Tooltip title={intl.get('reservations.guestDetails.headerLabels.whatsapp').d('Chat on WhatsApp')}>
            <a href={`https://wa.me/${cleanNumber(contactNo)}`} target="_blank" rel="noopener noreferrer">
              {contactNo}
            </a>
          </Tooltip>
        ) : (
          <>{contactNo}</>
        );
      }
    });

    return [
      {
        ...constructColumn(intl.get('overview.tableColumns.code').d('Confirmation Code'), 'code', {
          hasSorter: true,
          sorter,
          width: 150,
          fixed: 'left'
        }),
        ...constructColumnFilterSearch('code', intl.get('tables.confirmationCode').d('Search Confirmation Code'), filters),
        ...getCodeRenderProp()
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.property').d('Property Name'), 'unit.roomType.property.name', {
          hasSorter: true,
          sorter,
          width: 150
        }),
        ...constructColumnFilterCheckbox('unit.roomType.property.name', this.props.propertyOptions)
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.roomType').d('Room Type Name'), 'unit.roomType.name', {
          hasSorter: true,
          sorter,
          width: 150
        }),
        ...constructColumnFilterSearch('unit.roomType.name', intl.get('tables.roomType').d('Search Room Type Name'), filters)
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.unit').d('Unit Name'), 'unit.name', { hasSorter: true, sorter }),
        ...constructColumnFilterSearch('unit.name', intl.get('tables.unit').d('Search Unit Name'), filters)
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.cleaning').d('Cleaning Status'), 'taskStatus.status', { hasSorter: true, width: 150 }),
        ...constructColumnFilterCheckbox('taskStatus.status', this.state.taskStatusConstant),
        render: (text, record) => {
          return this.renderCleaningStatus(record.taskStatus);
        }
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.booking').d('Booking Status'), 'bookingStatus', { hasSorter: true, width: 200 }),
        ...constructColumnFilterCheckbox('bookingStatus', this.state.bookingStatusConstant),
        // render: bookingStatus => getConstantLabel(this.state.bookingStatusConstant, bookingStatus)
        render: (bookingStatus, record) => {
          return (
            <Select
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              defaultValue={bookingStatus}
              onChange={e => this.handleOnBookingStatusChange(record, e)}
              style={{ width: 160 }}
            >
              {this.state.bookingStatusConstant
                .filter(bs => bs.code !== 'Cancelled by OTA' && bs.code !== 'Cancelled by Guest')
                .map(bkStat => (
                  <Option value={bkStat.value} key={bkStat.value}>
                    {intl.get(`reservations.reservationDetails.bookingStatus.${bkStat.label}`).d(bkStat.label)}
                  </Option>
                ))}
            </Select>
          );
        }
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.guestName').d('Guest Name'), 'guestDetails.userProfile.guestName', {
          hasSorter: true,
          sorter
        }),
        ...constructColumnFilterSearch('guestDetails.userProfile.guestName', intl.get('tables.guestName').d('Search Guest Name'), filters)
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.checkIn').d('Check-in Date'), 'startDate', { hasSorter: true, sorter, width: 120 })
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.checkOut').d('Check-out Date'), 'endDate', { hasSorter: true, sorter, width: 120 })
      },
      {
        ...constructColumn(intl.get('overview.tableColumns.contact').d('Contact Number'), 'guestDetails.userProfile.contactNos[0]'),
        ...constructColumnFilterSearch('guestDetails.userProfile.contactNos[0]', intl.get('tables.contactNo').d('Search Contact Number'), filters),
        ...getContactNoRenderProp()
      }
    ];
  };

  render() {
    const {
      isLoading,

      todayConstant,
      tomorrowConstant,

      reservationList,
      titleDateRange,
      dashboardStyle,
      handleOnTableDataChange,
      tableQuery
    } = this.props;

    const title = (
      <>
        {reservationList.dashboardTitle + titleDateRange}
        {this.props.checkAbleExportOverview() && this.renderDownloadCSVButton(reservationList)}
      </>
    );

    return (
      <Card title={title} className={dashboardStyle} loading={isLoading}>
        <Table
          rowKey={record => record._id}
          columns={this.constructColumns(tableQuery)}
          dataSource={reservationList.data}
          scroll={{ x: 1500 }}
          loading={isLoading}
          onChange={(pagination, filters, sorter) => handleOnTableDataChange({ pagination, filters, sorter })}
          pagination={{ pageSize: 10 }}
        />
      </Card>
    );
  }
}

export default withRouter(withAppContext(ReservationList));
