import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'antd';
import { withAppContext } from 'context/AppContext';

import Body from './components/Body/Body';
import { handleOnAggregationTableChange } from 'utils/table/table';

import listingStyles from '../Listing/Listing.module.css';
import styles from './Rental.module.css';
import intl from 'react-intl-universal';

const handleOnClickClearAll = setQuery => {
  const newQuery = {
    pagination: { current: 1, pageSize: 10 },
    filters: {},
    sorter: {}
  };
  handleOnAggregationTableChange(newQuery, setQuery);
};

const handleOnTableChange = setQuery => newQuery => {
  handleOnAggregationTableChange(newQuery, setQuery);
};

const Rental = ({ isAllowEditRental, checkIsAdminReadOnly }) => {
  const [query, setQuery] = useState({});

  return (
    <Card className={listingStyles.card}>
      <div className={`${listingStyles.header} ${styles.header}`}>
        <Button onClick={() => handleOnClickClearAll(setQuery)}>{intl.get('listings.headerLabels.reset').d('Reset All')}</Button>
      </div>
      <div>
        <Body
          query={query}
          isAllowEditRental={isAllowEditRental}
          onTableChange={handleOnTableChange(setQuery)}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      </div>
    </Card>
  );
};

Rental.propTypes = {
  isAllowEditRental: PropTypes.bool.isRequired
};

export default withAppContext(Rental);
