import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Progress, Button, Icon, Divider, Form } from 'antd';

import logo from 'images/hostastay-icon.png';
import { buildListingWizardUri } from 'utils/routes';

import styles from './ListingWizardLayout.module.css';
import intl from 'react-intl-universal';

const Header = ({ title, percentage }) => {
  return (
    <Fragment>
      <Row className={styles.headerContainer} type="flex" align="middle" justify="left">
        <div style={{ marginRight: '20px' }}>
          <Link to={buildListingWizardUri()}>
            <img className={styles.headerLogo} src={logo} alt="hostastay-logo" width="auto" />
          </Link>
        </div>
        <h2 className={styles.headerTitle}>{title}</h2>
      </Row>
      <Row>
        <Progress showInfo={false} strokeWidth={5} strokeLinecap="square" strokeColor="#00b9c6" percent={percentage} />
      </Row>
    </Fragment>
  );
};

const Footer = ({ isLastPage, onBackButtonClick, onNextButtonClick, showNextButtonLoading }) => {
  const nextButtonDetails = isLastPage
    ? {
        text: intl.get('listings.headerLabels.finish').d('Finish'),
        icon: 'check'
      }
    : {
        text: intl.get('listings.headerLabels.next').d('Next'),
        icon: 'right'
      };

  return (
    <div className={styles.footerContainer}>
      <Divider />
      <div className={styles.buttonsContainer}>
        {onBackButtonClick ? (
          <Button className={`${styles.footerNavButton}`} type="primary" size="large" onClick={onBackButtonClick}>
            <Icon type="left" /> {intl.get('listings.headerLabels.back').d('Back')}
          </Button>
        ) : (
          <span />
        )}
        <Button
          className={`${styles.footerNavButton}`}
          id={isLastPage ? 'finish-button1-createlisting' : ''}
          type="primary"
          size="large"
          htmlType="submit"
          onClick={onNextButtonClick}
          loading={showNextButtonLoading}
        >
          {showNextButtonLoading ? intl.get('listings.headerLabels.loading').d('Loading') : nextButtonDetails.text}
          {!showNextButtonLoading && <Icon type={nextButtonDetails.icon} />}
        </Button>
      </div>
    </div>
  );
};

class ListingWizardLayout extends React.Component {
  render() {
    const { title, percentage, children, isLastPage, onBackButtonClick, onNextButtonClick, showNextButtonLoading } = this.props;
    return (
      <div>
        <Header title={title} percentage={percentage} />
        <Row className={styles.contentContainer} type="flex">
          <Form onSubmit={onNextButtonClick} style={{ width: '100%' }}>
            {children}
            <Footer
              isLastPage={isLastPage}
              onBackButtonClick={onBackButtonClick}
              onNextButtonClick={onNextButtonClick}
              showNextButtonLoading={showNextButtonLoading}
            />
          </Form>
        </Row>
      </div>
    );
  }
}

ListingWizardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLastPage: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  showNextButtonLoading: PropTypes.bool
};

ListingWizardLayout.defaultProps = {
  isLastPage: false,
  onBackButtonClick: undefined,
  onNextButtonClick: undefined,
  showNextButtonLoading: false
};

export default ListingWizardLayout;
