import React, { Component } from 'react';
import { Row, Col, Card, Tabs, Skeleton, Alert, DatePicker, Icon, Radio, notification, message, Button } from 'antd';
import moment from 'moment';

import { withAppContext } from 'context/AppContext';
import { getReservations } from 'utils/apis/reservation';
import { getClashingReservationDetails } from 'utils/apis/multiCalendar';
import { getBookingStatusConstant } from 'utils/apis/constants';
import { buildBillingInvoiceUri, buildMultiCalendarUri } from 'utils/routes';
import { getProperties } from 'utils/apis/property';
import { getInvoices } from 'utils/apis/billingInvoice';
import { getBookingIssueList, getHealthcheck } from 'utils/apis/bookingIssue';

import ReservationList from './components/ReservationList/ReservationList';
import BookingIssueList from './components/BookingIssueList/BookingIssueList';

import styles from './Overview.module.css';
import intl from 'react-intl-universal';

const { RangePicker } = DatePicker;
const TabPane = Tabs.TabPane;

const DATE_FORMAT = 'YYYY-MM-DD';

const RESERVATION_DATE_RANGE_TODAY = 0;
const RESERVATION_DATE_RANGE_TOMORROW = 1;
const RESERVATION_DATE_RANGE_7DAYS = 7;
const RESERVATION_DATE_RANGE_30DAYS = 30;
const RESERVATION_DATE_RANGE_CUSTOM = 'custom_date_range';

const TODAY = 'Today';
const TOMORROW = 'Tomorrow';
const DATE_DISPLAY_FORMAT = 'D MMM';

const TODAY_MOMENT = moment();
const FUTURE_30_DAYS_MOMENT = moment().add(30, 'days');
const PAST_30_DAYS_MOMENT = moment().subtract(30, 'days');

// const CHECKIN = 'checkin';
// const CHECKOUT = 'checkout';
// const HOSTING = 'hosting';
// const ALL = 'all';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clashingReservationDetails: undefined,
      activeBookingStatuses: [],
      unpaidInvoicesCounter: [],
      bookingIssueList: [],

      reservationDateRange: RESERVATION_DATE_RANGE_TODAY,
      startDate: moment().format(DATE_FORMAT),

      endDate: moment().format(DATE_FORMAT),
      dates: [],

      reservationLists: [],
      reservationStatistic: [],
      propertyOptions: [],
      tableQuery: {},

      isLoading: true,
      errorMessage: '',
      tabIndex: '0',

      isShowingCustomDateRangePicker: false,
      isShowingClashingReservationBanner: true,
      isShowingUnpaidInvoiceBanner: true,
      isShowingOverdueInvoiceBanner: true,

      loading: false,
      bookingIssueVisible: false
    };
  }

  componentDidMount = async () => {
    const { startDate, endDate } = this.state;
    const { checkIsAdmin, checkIsAllowViewBilling } = this.props;

    this.setState({ isLoading: true });

    // this.GetBookingIssueList();

    const activeBookingStatuses = await this.getBookingStatusConstant();
    const tabIndex = sessionStorage.getItem('tabIndex') || '0';
    // If is admin the do not run
    if (checkIsAdmin()) {
      return;
    }
    getProperties().then(properties => {
      let propertyOptions = [];
      if (properties.length > 0) {
        propertyOptions = properties.map(property => {
          return { value: property.name, label: property.name, key: property._id };
        });
      }
      this.setState({ propertyOptions });
    });
    const { reservationLists, reservationStatistic, errorMessage } = await this.getReservationListAndStatistic({
      startDate,
      endDate,
      activeBookingStatuses
    });
    const clashingReservationDetails = !checkIsAdmin()
      ? await getClashingReservationDetails().catch(e => {
          notification.error({ message: 'Error when Fetching Clashing Reservation', description: e.message });
        })
      : [];

    const { unpaidInvoicesCounter, overdueInvoicesCounter } = checkIsAllowViewBilling()
      ? await this.fetchInvoices()
      : {
          unpaidInvoicesCounter: [],
          overdueInvoicesCounter: []
        };

    this.setState({
      isLoading: false,
      activeBookingStatuses,
      reservationLists,
      reservationStatistic,
      errorMessage,
      clashingReservationDetails,
      unpaidInvoicesCounter,
      overdueInvoicesCounter,
      tabIndex
    });
  };

  fetchInvoices = async () => {
    const invoices = await getInvoices();
    let unpaidInvoicesCounter = [];
    let overdueInvoicesCounter = [];

    if (invoices) {
      unpaidInvoicesCounter = invoices.filter(invoice => invoice.paymentStatus === 'unpaid').length;
      overdueInvoicesCounter = invoices.filter(
        invoice => invoice.paymentStatus === 'unpaid' && moment(new Date(), 'YYYY-MM-DD') > moment(invoice.invoiceDueDate)
      ).length;
    }

    return { unpaidInvoicesCounter, overdueInvoicesCounter };
  };

  // GetBookingIssueList = async () => {
  //   const res = await getBookingIssueList();

  //   console.log('booking issue', res);

  //   if (res.status === 200) {
  //     this.setState({
  //       bookingIssueList: res.data
  //     });
  //   }
  // };

  // GetHealthcheck = async () => {
  //   this.setState({
  //     loading: true
  //   });

  //   const res = await getHealthcheck();

  //   console.log('getHealthcheck', res);

  //   if (res.status === 200) {
  //     this.setState({
  //       loading: false
  //     });

  //     message.success('Email Send Successfully!');
  //   }
  // };

  getBookingStatusConstant = async () => {
    const bookingStatuses = await getBookingStatusConstant();
    let activeBookingStatuses = [];

    if (bookingStatuses) {
      activeBookingStatuses = Object.values(bookingStatuses)
        .filter(bookingStatusValue => bookingStatusValue.isActive)
        .map(bookingStatusValue => bookingStatusValue.code);
    }

    return activeBookingStatuses;
  };

  getReservationListAndStatistic = async ({ startDate, endDate, activeBookingStatuses, query }) => {
    return getReservations({ startDate: startDate, endDate: endDate, query }).then(res => {
      let reservationLists = [];
      let reservationStatistic = [];
      let errorMessage = '';

      if (res.status === 200) {
        let { reservations } = res.data;

        for (let i = 0; i < reservations.length; i++) {
          if (reservations[i].guestDetails.userProfile === null) {
            errorMessage = 'Error data';
          }
          break;
        }

        if (!errorMessage) {
          const reservationsProcessed = JSON.parse(JSON.stringify(reservations));
          const checkInList = reservationsProcessed
            .filter(res => {
              //enable extended booking to be displayed as well
              const isBookingValid =
                ['0', '1', '2', '3', '4', '7'].includes(String(res.bookingType)) &&
                activeBookingStatuses.includes(String(res.bookingStatus)) &&
                res.startDate >= startDate;

              const hasDuplicatedMatch =
                res.duplicatedFrom &&
                reservationsProcessed.some(otherRes => {
                  return otherRes._id === res.duplicatedFrom && otherRes.unit._id === res.unit._id && otherRes.endDate === res.startDate;
                });

              return isBookingValid && !hasDuplicatedMatch;
            })
            .sort((current, next) => {
              return current.startDate > next.startDate ? 1 : current.startDate < next.startDate ? -1 : 0;
            })
            .map(reservation => {
              let startDateDisplay = moment(reservation.startDate).format(DATE_DISPLAY_FORMAT);

              if (reservation.startDate === moment().format(DATE_FORMAT)) {
                startDateDisplay = TODAY;
              } else if (
                reservation.startDate ===
                moment()
                  .add(1, 'days')
                  .format(DATE_FORMAT)
              ) {
                startDateDisplay = TOMORROW;
              }

              return { ...reservation, startDateDisplay };
            });
          const checkOutList = reservationsProcessed
            .filter(res => {
              const isBookingValid =
                ['0', '1', '2', '3', '4', '7'].includes(String(res.bookingType)) &&
                activeBookingStatuses.includes(String(res.bookingStatus)) &&
                res.endDate >= startDate &&
                res.endDate <= endDate;

              const hasDuplicatedToMatch =
                res.duplicatedTo &&
                res.duplicatedTo.some(duplicatedId =>
                  reservationsProcessed.some(
                    otherRes => otherRes._id === duplicatedId && otherRes.unit._id === res.unit._id && otherRes.startDate === res.endDate
                  )
                );

              return isBookingValid && !hasDuplicatedToMatch;
            })
            .sort((current, next) => {
              return current.endDate > next.endDate ? 1 : current.endDate < next.endDate ? -1 : 0;
            })
            .map(reservation => {
              let endDateDisplay = moment(reservation.endDate).format(DATE_DISPLAY_FORMAT);

              if (reservation.endDate === moment().format(DATE_FORMAT)) {
                endDateDisplay = TODAY;
              } else if (
                reservation.endDate ===
                moment()
                  .add(1, 'days')
                  .format(DATE_FORMAT)
              ) {
                endDateDisplay = TOMORROW;
              }

              return { ...reservation, endDateDisplay };
            });
          const currentlyHostingList = reservationsProcessed
            .filter(res => {
              const isBookingTypeValid = ['0', '1', '2', '3', '4', '7'].includes(String(res.bookingType));
              const isBookingStatusValid = activeBookingStatuses.includes(String(res.bookingStatus));

              // First condition: General booking type/status check and date range check
              const isDateRangeValid = (res.startDate < startDate && res.endDate > startDate) || (res.startDate > startDate && res.endDate < endDate);

              const conditionOne = isBookingTypeValid && isBookingStatusValid && isDateRangeValid;

              // Second condition: Specific to booking type '1' with additional duplicatedFrom logic
              const isTypeOneBooking = ['1'].includes(String(res.bookingType)) && isBookingStatusValid && res.startDate >= startDate;
              let conditionTwo = false;

              if (isTypeOneBooking) {
                if (res.duplicatedFrom) {
                  const matchedReservation = reservationsProcessed.find(otherRes => otherRes._id === res.duplicatedFrom);

                  if (matchedReservation && res.unit._id === matchedReservation.unit._id) {
                    conditionTwo = true;
                  }
                  //if no matching, it wont be in hosting
                } else {
                  // No duplicatedFrom field, fall back to original condition
                  conditionTwo = true;
                }
              }
              return conditionOne || conditionTwo;
            })
            .sort((current, next) => {
              return current.startDate > next.startDate ? 1 : current.startDate < next.startDate ? -1 : 0;
            })
            .map(reservation => {
              return {
                ...reservation,
                startDateDisplay: moment(reservation.startDate).format(DATE_DISPLAY_FORMAT),
                endDateDisplay: moment(reservation.endDate).format(DATE_DISPLAY_FORMAT)
              };
            });
          const allResList = reservationsProcessed
            .filter(res => {
              return (
                ['0', '1', '2', '3', '4', '7'].includes(String(res.bookingType)) &&
                (res.startDate >= startDate || res.endDate <= startDate || (res.startDate <= startDate && res.endDate >= startDate))
              );
            })
            .sort((current, next) => {
              return moment(current.createdAt).format(DATE_FORMAT) > moment(next.createdAt).format(DATE_FORMAT)
                ? 1
                : moment(current.createdAt).format(DATE_FORMAT) < moment(next.createdAt).format(DATE_FORMAT)
                ? -1
                : 0;
            })
            .map(reservation => {
              return {
                ...reservation,
                startDateDisplay: moment(reservation.startDate).format(DATE_DISPLAY_FORMAT),
                endDateDisplay: moment(reservation.endDate).format(DATE_DISPLAY_FORMAT)
              };
            });

          reservationLists = [
            {
              code: 'checkIn',
              dashboardTitle: intl.get('overview.dashboard.checkInTitle').d('Guest Check-in'),
              dashboardFooter: intl.get('overview.dashboard.checkInFooter').d('Check-in'),
              csvName: 'Guest_Check_In',
              data: checkInList,
              printButtonId: 'inprint-button2-overview'
            },
            {
              code: 'checkOut',
              dashboardTitle: intl.get('overview.dashboard.checkOutTitle').d('Guest Check-out'),
              dashboardFooter: intl.get('overview.dashboard.checkOutFooter').d('Check-out'),
              csvName: 'Guest_Check_Out',
              data: checkOutList,
              printButtonId: 'outprint-button1-overview'
            },
            {
              code: 'currentHosting',
              dashboardTitle: intl.get('overview.dashboard.hostingTitle').d('Currently Hosting'),
              dashboardFooter: intl.get('overview.dashboard.hostingFooter').d('Hosting'),
              csvName: 'Currently_Hosting',
              data: currentlyHostingList,
              printButtonId: 'nowprint-button3-overview'
            },
            {
              code: 'allRes',
              dashboardTitle: intl.get('overview.dashboard.allTitle').d('All Reservation'),
              dashboardFooter: intl.get('overview.dashboard.allFooter').d('All'),
              csvName: 'All_Reservation',
              data: allResList,
              printButtonId: 'nowprint-button4-overview'
            }
          ];
          reservationStatistic = [
            {
              code: 'Total Check-In',
              value: checkInList.length
            },
            {
              code: 'Total Check-Out',
              value: checkOutList.length
            },
            {
              code: 'Currently Hosting',
              value: currentlyHostingList.length
            },
            {
              code: 'Total Reservation',
              value: allResList.length
            }
          ];
        }
      } else {
        errorMessage = 'No data';
      }

      return { reservationLists, reservationStatistic, errorMessage };
    });
  };

  getDashboardDateRangeString = () => {
    const { startDate, endDate } = this.state;
    const dashboardDateRangeString = intl.get('overview.headerLabels.for').d(' for ');

    // if startDate = endDate, it is today
    if (startDate === endDate) {
      if (TODAY_MOMENT.isSame(startDate, 'date')) {
        return `${dashboardDateRangeString}${intl.get('overview.headerLabels.today').d('Today')}`;
      } else if (
        TODAY_MOMENT.clone()
          .add(1, 'days')
          .isSame(startDate, 'date')
      ) {
        return `${dashboardDateRangeString}${intl.get('overview.headerLabels.tomorrow').d('Tomorrow')}`;
      }
    }

    return `${dashboardDateRangeString}${startDate} ${intl.get('overview.headerLabels.to').d('to')} ${endDate}`;
  };

  generateLinkToMultiCalendar = clashingReservationDetails => {
    const firstProperty = clashingReservationDetails.properties[0];
    const firstMonthWithYear = firstProperty.monthWithYears[0];
    const firstRoomType = firstMonthWithYear.roomTypes[0];

    const propertyId = firstProperty._id;
    const roomTypeId = firstRoomType._id;
    const month = firstMonthWithYear.month;
    const year = firstMonthWithYear.year;

    const linkToMultiCalendar = buildMultiCalendarUri({ property: propertyId, roomType: roomTypeId, date: `${year}-${month}` });

    return linkToMultiCalendar;
  };

  // getDisabledDateRange = currentDate => {
  //   return !currentDate.isBetween(TODAY_MOMENT, FUTURE_30_DAYS_MOMENT);
  // };

  getDisabledDateRange = current => {
    const { dates } = this.state;
    if (!dates) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
    const tooEarly = dates[0] && dates[0].diff(current, 'days') >= 30;
    return !!tooEarly || !!tooLate;
  };

  onOpenChange = open => {
    if (open) {
      this.setState({ dates: [null, null] });
    } else {
      this.setState({ dates: null });
    }
  };

  getAndSetReservationListAndStatistic = async (startDate, endDate, query = {}) => {
    const { activeBookingStatuses } = this.state;

    const { reservationLists, reservationStatistic, errorMessage } = await this.getReservationListAndStatistic({
      startDate,
      endDate,
      activeBookingStatuses,
      query
    });

    this.setState({
      startDate,
      endDate,
      reservationLists,
      reservationStatistic,
      errorMessage
    });
  };

  handleOnReservationDateRangeChange = async e => {
    const dateRange = e.target.value;

    if (dateRange === RESERVATION_DATE_RANGE_CUSTOM) {
      this.setState({ isShowingCustomDateRangePicker: true, reservationDateRange: dateRange });
    } else {
      this.setState({ isShowingCustomDateRangePicker: false, isLoading: true });

      const startDate = moment()
        .add(dateRange === RESERVATION_DATE_RANGE_TOMORROW ? 1 : 0, 'days')
        .format(DATE_FORMAT);

      const endDate = moment()
        .add(dateRange, 'days')
        .format(DATE_FORMAT);

      await this.getAndSetReservationListAndStatistic(startDate, endDate);

      this.setState({ reservationDateRange: dateRange, isLoading: false });
    }
  };

  handleOnDateRangePickerChange = async (date, dateString) => {
    if (Array.isArray(date) && date.length > 0) {
      this.setState({ isLoading: true });

      const [startDate, endDate] = dateString;

      await this.getAndSetReservationListAndStatistic(startDate, endDate);

      this.setState({ isLoading: false });
    }
  };

  handleOnTableDataChange = async newQuery => {
    this.setState({ tableQuery: newQuery });

    // handleOnAggregationTableChange(newQuery);
    // await this.getAndSetReservationListAndStatistic(startDate, endDate, newQuery);
  };

  handleOnTabChange = index => {
    sessionStorage.setItem('tabIndex', index);
    this.setState({ tabIndex: index });
  };

  handleCloseClashing = () => {
    this.setState({ isShowingClashingReservationBanner: false });
  };

  handleCloseUnpaidInvoice = () => {
    this.setState({ isShowingUnpaidInvoiceBanner: false });
  };

  handleCloseOverdueInvoice = () => {
    this.setState({ isShowingOverdueInvoiceBanner: false });
  };

  render() {
    const {
      clashingReservationDetails,
      unpaidInvoicesCounter,
      overdueInvoicesCounter,
      bookingIssueList,

      reservationDateRange,

      reservationLists,
      reservationStatistic,
      propertyOptions,
      tabIndex,

      isShowingCustomDateRangePicker,

      isLoading,
      errorMessage,
      tableQuery
    } = this.state;
    const dashboardDateRangeString = this.getDashboardDateRangeString();

    return (
      <div className={`${styles.overviewBody}`}>
        {clashingReservationDetails && clashingReservationDetails.totalNumberOfClashes > 0 && (
          <a className={`${styles.warningOnClashingReservationLink}`} href={this.generateLinkToMultiCalendar(clashingReservationDetails)}>
            <Row className={`${styles.warningOnClashingReservationRow}`}>
              <Alert
                type="error"
                closable
                afterClose={this.handleCloseClashing}
                message={
                  <div>
                    <Icon className={`${styles.warningOnClashingReservationIcon}`} type="warning" />
                    {intl.get('overview.headerLabels.clashWarning').d('There is a total of ')}
                    <span className={`${styles.warningOnClashingReservationTotalNumber}`}>
                      {clashingReservationDetails.totalNumberOfClashes}
                    </span>{' '}
                    {intl
                      .get('overview.headerLabels.clashWarning2')
                      .d('clashing reservations! To fix the problem, click here to redirect to the multi calendar.')}
                  </div>
                }
              />
            </Row>
          </a>
        )}

        {overdueInvoicesCounter > 0 && (
          <a className={`${styles.warningOnOverdueLink}`} href={buildBillingInvoiceUri()}>
            <Row className={`${styles.warningOnOverdueRow}`}>
              <Alert
                type="error"
                closable
                afterClose={this.handleCloseOverdueInvoice}
                message={
                  <div>
                    <Icon className={`${styles.warningOnOverdueIcon}`} type="warning" />
                    {intl.get('overview.headerLabels.clashWarning').d('There is a total of ')}
                    <b>{overdueInvoicesCounter}</b>{' '}
                    {intl
                      .get('overview.headerLabels.overdueInvoice')
                      .d('overdue invoice(s)! To check on the invoices, click here to redirect to billing invoice page.')}
                  </div>
                }
              />
            </Row>
          </a>
        )}

        {bookingIssueList.length > 0 && (
          <a
            className={`${styles.warningOnOverdueLink}`}
            onClick={() =>
              this.setState({
                bookingIssueVisible: true
              })
            }
          >
            <Row className={`${styles.warningOnOverdueRow}`}>
              <Alert
                type="error"
                // closable
                // afterClose={this.handleCloseOverdueInvoice}
                message={
                  <div>
                    <Icon className={`${styles.warningOnOverdueIcon}`} type="warning" />
                    {intl.get('overview.headerLabels.clashWarning').d('There is a total of ')}
                    <b>{bookingIssueList.length}</b>{' '}
                    {intl.get('overview.headerLabels.integrationIssue').d('integration issues! To check on the issues, click here to open it.')}
                  </div>
                }
              />
            </Row>
          </a>
        )}

        {unpaidInvoicesCounter > 0 && (
          <a className={`${styles.warningOnInvoiceLink}`} href={buildBillingInvoiceUri()}>
            <Row className={`${styles.warningOnInvoiceRow}`}>
              <Alert
                type="warning"
                closable
                afterClose={this.handleCloseUnpaidInvoice}
                message={
                  <div>
                    <Icon className={`${styles.warningOnInvoiceIcon}`} type="warning" />
                    {intl.get('overview.headerLabels.clashWarning').d('There is a total of ')}
                    <b>{unpaidInvoicesCounter}</b>{' '}
                    {intl
                      .get('overview.headerLabels.unpaidInvoice')
                      .d('unpaid invoice(s)! To check on the invoices, click here to redirect to billing invoice page.')}
                  </div>
                }
              />
            </Row>
          </a>
        )}

        <Row className={`${styles.buttonRow}`} style={{ backgroundColor: '#fff', marginTop: 12 }}>
          <Radio.Group value={reservationDateRange} onChange={this.handleOnReservationDateRangeChange} buttonStyle="solid">
            <Radio.Button id="day-button1-overview" value={RESERVATION_DATE_RANGE_TODAY}>
              {intl.get('overview.headerLabels.today').d('Today')}
            </Radio.Button>
            <Radio.Button id="day-button4-overview" value={RESERVATION_DATE_RANGE_TOMORROW}>
              {intl.get('overview.headerLabels.tomorrow').d('Tomorrow')}
            </Radio.Button>
            <Radio.Button id="day-button2-overview" value={RESERVATION_DATE_RANGE_7DAYS}>
              {intl.get('overview.headerLabels.7day').d('7 Days')}
            </Radio.Button>
            <Radio.Button id="day-button3-overview" value={RESERVATION_DATE_RANGE_30DAYS}>
              {intl.get('overview.headerLabels.30day').d('30 Days')}
            </Radio.Button>
            <Radio.Button id="customday-button5-overview" value={RESERVATION_DATE_RANGE_CUSTOM}>
              {intl.get('overview.headerLabels.custom').d('Custom')}
            </Radio.Button>
          </Radio.Group>
          {isShowingCustomDateRangePicker && (
            <RangePicker
              className={styles.dateRangePicker}
              allowClear
              disabledDate={this.getDisabledDateRange}
              onChange={this.handleOnDateRangePickerChange}
              onCalendarChange={val => {
                this.setState({
                  dates: val
                });
              }}
              onOpenChange={this.onOpenChange}
            />
          )}
        </Row>
        {/* statistic overview */}
        <Row type="flex" gutter={[12, 12]} style={{ alignContent: 'top' }} justify="center">
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Card bodyStyle={{ textAlign: 'center' }} loading={isLoading}>
              <div className={`${styles.summaryTitle}`}>{intl.get('overview.headerLabels.checkIn').d('Check-in')}</div>
              <div className={`${styles.summaryBody}`}>
                {reservationStatistic.map(resStat => (resStat.code === 'Total Check-In' ? resStat.value : null))}
              </div>
            </Card>
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Card bodyStyle={{ textAlign: 'center' }} loading={isLoading}>
              <div className={`${styles.summaryTitle}`}>{intl.get('overview.headerLabels.checkOut').d('Check-out')}</div>
              <div className={`${styles.summaryBody}`}>
                {reservationStatistic.map(resStat => (resStat.code === 'Total Check-Out' ? resStat.value : null))}
              </div>
            </Card>
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Card bodyStyle={{ textAlign: 'center' }} loading={isLoading}>
              <div className={`${styles.summaryTitle}`}>{intl.get('overview.headerLabels.hosting').d('Hosting')}</div>
              <div className={`${styles.summaryBody}`}>
                {reservationStatistic.map(resStat => (resStat.code === 'Currently Hosting' ? resStat.value : null))}
              </div>
            </Card>
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Card bodyStyle={{ textAlign: 'center' }} loading={isLoading}>
              <div className={`${styles.summaryTitle}`}>{intl.get('overview.headerLabels.all').d('All')}</div>
              <div className={`${styles.summaryBody}`}>
                {reservationStatistic.map(resStat => (resStat.code === 'Total Reservation' ? resStat.value : null))}
              </div>
            </Card>
          </Col>
        </Row>

        {/* each dashboard component */}
        <Card className={styles.dashboard}>
          <Tabs defaultActiveKey="0" size={'large'} activeKey={tabIndex} onTabClick={this.handleOnTabChange}>
            {reservationLists.map((reservationList, index) => (
              <TabPane tab={reservationList.dashboardFooter} key={index} xl={24} lg={24}>
                <Skeleton loading={isLoading} active>
                  <ReservationList
                    isLoading={isLoading}
                    todayConstant={TODAY}
                    tomorrowConstant={TOMORROW}
                    reservationList={reservationList}
                    titleDateRange={dashboardDateRangeString}
                    dashboardStyle={`${styles.dashboard}`}
                    handleOnTableDataChange={this.handleOnTableDataChange}
                    tableQuery={tableQuery}
                    propertyOptions={propertyOptions}
                  />
                </Skeleton>
              </TabPane>
            ))}
          </Tabs>
        </Card>

        {errorMessage ? <div>{errorMessage}</div> : ''}

        <BookingIssueList
          data={bookingIssueList}
          visible={this.state.bookingIssueVisible}
          onCancel={() =>
            this.setState({
              bookingIssueVisible: false
            })
          }
        />
      </div>
    );
  }
}

export default withAppContext(Overview);
