import React, { useState } from 'react';
import { Modal, Col, Row, Icon, Button, Message, Select, Tooltip, Divider } from 'antd';
import moment from 'moment';
import { withAppContext } from 'context/AppContext';

import { deleteReservation, updateReservation, getReservationsById } from 'utils/apis/reservation';
import { numberWithCommas } from 'utils/general';
import { buildReservationUri } from 'utils/routes';

import styles from './MCModalReservationSummary.module.css';
import intl from 'react-intl-universal';

const { Option } = Select;

const getLabel = (id, options) => {
  if (String(id)) {
    const filteredOptions = options.filter(option => String(option.value) === String(id));
    if (filteredOptions && filteredOptions.length > 0) {
      return filteredOptions[0].label;
    }
  }

  return '-';
};

const formatDate = date => {
  return moment(date).format('Do MMM');
};

const authorizedItem = (keys, isAdmin, permissions) => {
  if (isAdmin) {
    return true;
  }

  return keys.filter(key => permissions.includes(key)).length > 0 ? true : false;
};

const getReservationDetails = (
  reservation,
  isNonProfitBooking,
  unitName,
  integrationSourceOptions,
  bookingTypeOptions,
  bookingStatusOptions,
  permissions,
  permissionConstants,
  isAdmin,
  currency,
  checkIsAdminReadOnly
) => {
  console.log(reservation);
  //determine deposit status
  const depositStatus = () => {
    const refundTransaction = reservation.depositTransaction.find(transaction => transaction.subType === 'refund');
    if (refundTransaction) {
      return {
        title: `${currency} ${refundTransaction.amount} ${intl.get('multicalendar.headerLabels.depositRefunded').d('Security Deposit Refunded')}`
      };
    }

    // Check for collect transactions
    const collectTransaction = reservation.depositTransaction.find(transaction => transaction.subType === 'collect');
    if (collectTransaction) {
      return {
        title: `${currency} ${collectTransaction.amount} ${intl.get('multicalendar.headerLabels.depositCollected').d('Security Deposit Collected')}`
      };
    }
    return 'noDeposit';
  };

  const reservationDetails = [];
  // Populate reservation detail that going to show
  // General details that will show in both types of booking
  const unitNameDetail = { icon: 'home', display: unitName };
  const startEndDate = {
    icon: 'calendar',
    display: `${formatDate(reservation.startDate)} - ${formatDate(reservation.endDate)}`
  };

  reservationDetails.push(unitNameDetail, startEndDate);

  // General detail that show at the last
  const bookingType = {
    icon: 'global',
    display:
      reservation.bookingType === 3
        ? intl
            .get(`multicalendar.rate.${getLabel(reservation.platform, integrationSourceOptions).replace(/\./g, '_')}`)
            .d(getLabel(reservation.platform, integrationSourceOptions))
        : intl
            .get(`reservations.reservationDetails.bookingTypeOptions.${getLabel(reservation.bookingType, bookingTypeOptions)}`)
            .d(getLabel(reservation.bookingType, bookingTypeOptions))
  };
  const bookingCreatedDate = {
    icon: 'edit',
    display: (
      <Tooltip
        title={
          intl.get('multicalendar.headerLabels.creationDate').d('Creation Date: ') + moment(reservation.createdAt).format('DD-MMM-YYYY HH:mm:ss')
        }
        placement="bottom"
      >
        {moment(reservation.createdAt).format('DD-MMM-YYYY HH:mm')}
      </Tooltip>
    )
  };
  const ratePlanTooltip = reservation.ratePlanName
    ? intl.get('multicalendar.headerLabels.ratePlan').d('Rate Date: ') + reservation.ratePlanName
    : intl.get('multicalendar.headerLabels.ratePlan').d('Rate Date: ') + reservation.ratePlanId;
  const ratePlan = {
    icon: 'info-circle',
    display: (
      <Tooltip title={ratePlanTooltip} placement="bottom">
        {reservation.ratePlanName ? reservation.ratePlanName : reservation.ratePlanId}
      </Tooltip>
    )
  };

  //initallly is undefined type
  let deposit;
  if (reservation.depositTransaction.length > 0) {
    deposit = {
      icon: 'transaction',
      display: (
        <Tooltip title={depositStatus().tooltip} placement="bottom">
          {depositStatus().title}
        </Tooltip>
      )
    };
  } else {
    deposit = {
      icon: 'transaction',
      display: `${intl.get('multicalendar.headerLabels.noDeposit').d('No Security Deposit Collected')}`
    };
  }

  // Details that will show in non profit booking
  if (isNonProfitBooking) {
    const remarks = { icon: 'profile', display: reservation.remarks || '-' };
    if (reservation.guestDetails) {
      const guestName = { icon: 'user', display: reservation.guestDetails.name || '-' };
      const guestContact = { icon: 'mobile', display: reservation.guestDetails.contactNo || '-' };
      const guestPax = {
        icon: 'team',
        display: (reservation.guestDetails.numberOfPax || 0) + intl.get('multicalendar.headerLabels.guestsLabel').d(' Guest(s) ')
      };
      reservationDetails.push(guestName, guestContact, guestPax);

      if (reservation.duplicatedFrom) {
        const duplicatedFrom = {
          icon: 'text',
          code: reservation.duplicatedFrom.code,
          text: 'Original Booking Code: ',
          display:
            (
              <a target="_blank" rel="noopener noreferrer" href={buildReservationUri({ res: reservation.duplicatedFrom._id })}>
                &nbsp; <Icon type="link" />
                {`${reservation.duplicatedFrom.code}`}
              </a>
            ) || '-'
        };
        reservationDetails.push(duplicatedFrom);
      }

      if (reservation.duplicatedTo) {
        reservation.duplicatedTo.forEach(res => {
          const duplicatedTo = {
            icon: 'text',
            code: res.code,
            text: 'Extended Booking Code: ',
            display:
              (
                <a target="_blank" rel="noopener noreferrer" href={buildReservationUri({ res: res._id })}>
                  &nbsp; <Icon type="link" />
                  {`${res.code}`}
                </a>
              ) || '-'
          };
          reservationDetails.push(duplicatedTo);
        });
      }
    }
    reservationDetails.push(bookingType, remarks, bookingCreatedDate);
  } else {
    // Details that will show in profit booking
    const guestName = { icon: 'user', display: reservation.guestDetails.name || '-' };
    const guestContact = { icon: 'mobile', display: reservation.guestDetails.contactNo || '-' };
    const guestPax = {
      icon: 'team',
      display: (reservation.guestDetails.numberOfPax || 0) + intl.get('multicalendar.headerLabels.guestsLabel').d(' Guest(s) ')
    };
    const bookingStatus = {
      icon: 'check-circle-o',
      display: intl
        .get(`reservations.reservationDetails.bookingStatus.${getLabel(reservation.bookingStatus, bookingStatusOptions)}`)
        .d(getLabel(reservation.bookingStatus, bookingStatusOptions))
    };
    const bookingPrice = { icon: 'wallet', display: `${currency} ${numberWithCommas(reservation.reservationPrice)}` };

    if (authorizedItem([permissionConstants.TRANSACTION_VIEW], isAdmin, permissions)) {
      reservationDetails.push(guestName, guestContact, guestPax, bookingStatus, bookingPrice, bookingType, bookingCreatedDate);
      if (deposit) {
        reservationDetails.push(deposit);
      }
    } else {
      reservationDetails.push(guestName, guestContact, guestPax, bookingStatus, bookingType, bookingCreatedDate);
    }

    if (reservation.ratePlanName || reservation.ratePlanId) {
      reservationDetails.push(ratePlan);
    }
    if (reservation.duplicatedFrom) {
      const duplicatedFrom = {
        icon: 'text',
        code: reservation.duplicatedFrom.code,
        text: intl.get('multicalendar.headerLabels.originalBookingCode').d('Original Booking Code: '),
        display:
          (
            <a target="_blank" rel="noopener noreferrer" href={buildReservationUri({ res: reservation.duplicatedFrom._id })}>
              &nbsp; <Icon type="link" />
              {`${reservation.duplicatedFrom.code}`}
            </a>
          ) || '-'
      };
      reservationDetails.push(duplicatedFrom);
    }

    if (reservation.duplicatedTo) {
      reservation.duplicatedTo.forEach(res => {
        const duplicatedTo = {
          icon: 'text',
          code: res.code,
          text: intl.get('multicalendar.headerLabels.extendedBookingCode').d('Extended Booking Code: '),
          display:
            (
              <a target="_blank" rel="noopener noreferrer" href={buildReservationUri({ res: res._id })}>
                &nbsp; <Icon type="link" />
                {`${res.code}`}
              </a>
            ) || '-'
        };
        reservationDetails.push(duplicatedTo);
      });
    }
  }

  return reservationDetails;
};

// const renderFooter = ({
//   reservation,
//   roomTypeId,
//   isBlockReservation,
//   onClickCancelReservationSummary,
//   handleOnMCRateDateActionSuccess,
//   onClickUnblockButton,
//   allowCreateRes
// }) => (
//   <Row>
//     {isBlockReservation && !reservation.blockedBy ? (
//       <Col className={`${styles.unblockButton}`}>
//         <Button type="danger" onClick={() => handleOnUnblock({ reservation, roomTypeId, handleOnMCRateDateActionSuccess })}>
//           Unblock
//         </Button>
//         <Button style={{ margin: 0, color: '#f00', borderColor: '#f00' }} onClick={onClickUnblockButton}>
//           Unblock by date
//         </Button>
//       </Col>
//     ) : (
//       <Col className={`${styles.unblockButton}`}>
//         {allowCreateRes && (
//           <a href={`/bookingForm/duplicate?resId=${reservation._id}`} rel="noopener noreferrer">
//             <Button type="primary" disabled={!!reservation.blockedBy}>
//               Duplicate Booking
//             </Button>
//           </a>
//         )}
//         <a target="_blank" href={`/reservation/${reservation._id}/?selectedTab=activityLogs`} rel="noopener noreferrer">
//           <Button>Activity Log</Button>
//         </a>
//       </Col>
//     )}
//     <Col className={`${styles.buttons}`}>
//       <Button onClick={onClickCancelReservationSummary}>Cancel</Button>
//       <a href={`/reservation/${reservation._id}`} rel="noopener noreferrer">
//         <Button type="primary" disabled={!!reservation.blockedBy}>
//           View Booking
//         </Button>
//       </a>
//     </Col>
//   </Row>
// );

const handleOnUnblock = ({ reservation, roomTypeId, handleOnMCRateDateActionSuccess }) => {
  const reservationId = reservation._id;

  Modal.confirm({
    title: intl
      .get('multicalendar.message.unblockBlockingForm', {
        startDate: reservation.startDate,
        endDate: reservation.endDate
      })
      .d(`Are you sure you want to unblock the blocking from ${reservation.startDate} to ${reservation.endDate}?`),
    onOk() {
      deleteReservation(reservationId)
        .then(res => {
          if (res.status === 204) {
            handleOnMCRateDateActionSuccess({
              currentRoomTypeId: roomTypeId,
              selectedDate: reservation.startDate
            });
          } else if (res.status === 403) {
            Message.warning(intl.get('multicalendar.message.cannotBlock').d('Cannot unblock due to dependency.'));
          } else {
            Message.error(intl.get('multicalendar.message.unblockFail').d('Unblock failed, please contact our support.'));
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    },
    onCancel() {}
  });
};

const MCModalReservationSummary = ({
  reservation,
  isNonProfitBooking,
  isBlockReservation,
  unitName,
  roomTypeId,
  integrationSourceOptions,
  bookingTypeOptions,
  bookingStatusOptions,
  backgroundColor,
  showModalReservationOnClick,
  onClickCancelReservationSummary,
  handleOnMCRateDateActionSuccess,
  onClickUnblockButton,
  permissions,
  permissionConstants,
  isAdmin,
  currency,
  checkIsAdminReadOnly
}) => {
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(reservation.bookingStatus);
  const bookingStatusUpdateOptions = bookingStatusOptions.filter(status => status.isUpdatable);
  const shouldDisableUpdateStatusButton = selectedBookingStatus === reservation.bookingStatus;
  const isSystemBlock = !!reservation.blockedBy;

  const handleUpdateStatusButtonClick = () => {
    Modal.confirm({
      title: intl.get('multicalendar.headerLabels.updateReservationStatus').d('Update Reservation Status'),
      content: (
        <>
          {intl
            .getHTML('multicalendar.headerLabels.changeBookingStatus', {
              label: intl
                .get(`reservations.reservationDetails.bookingStatus.${getLabel(reservation.bookingStatus, bookingStatusUpdateOptions)}`)
                .d(getLabel(reservation.bookingStatus, bookingStatusUpdateOptions)),
              label2: intl
                .get(`reservations.reservationDetails.bookingStatus.${getLabel(selectedBookingStatus, bookingStatusUpdateOptions)}`)
                .d(getLabel(reservation.bookingStatus, bookingStatusUpdateOptions))
            })
            .d(
              `Are you sure you want to change booking status from ${getLabel(reservation.bookingStatus, bookingStatusUpdateOptions)} to ${getLabel(
                selectedBookingStatus,
                bookingStatusUpdateOptions
              )}`
            )}
        </>
      ),
      onOk() {
        const body = {
          bookingStatus: selectedBookingStatus
        };

        const isIgnoreClash = true;
        updateReservation(reservation._id, body, isIgnoreClash)
          .then(res => {
            if (res && res.status === 200) {
              handleOnMCRateDateActionSuccess({
                currentRoomTypeId: roomTypeId,
                selectedDate: reservation.startDate
              });
              Message.success(intl.get('multicalendar.message.reservationUpdated').d('Your reservation has been updated'));
            } else {
              Message.error(intl.get('multicalendar.message.reservationError').d('Error when updating reservation, please contact our support'));
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  return (
    <Modal
      title={
        <div className={`${styles.title} ${backgroundColor}`}>
          {isSystemBlock
            ? '(System-generated block)'
            : reservation.sourceId && !['bookingcom', 'ctripcm'].includes(reservation.source)
            ? `${reservation.code} (${reservation.sourceId})`
            : reservation.code}
        </div>
      }
      visible={showModalReservationOnClick}
      footer={null}
      width={700}
      onCancel={onClickCancelReservationSummary}
      destroyOnClose={true}
    >
      <Row>
        <Col span={17}>
          <div className={`${styles.details}`}>
            {getReservationDetails(
              reservation,
              isNonProfitBooking,
              unitName,
              integrationSourceOptions,
              bookingTypeOptions,
              bookingStatusOptions,
              permissions,
              permissionConstants,
              isAdmin,
              currency,
              checkIsAdminReadOnly
            ).map((reservationDetail, index) => (
              <>
                {reservationDetail.icon !== 'text' && (
                  <Row key={index} className={`${styles.detail}`} type="flex">
                    <Col className={`${styles.detailIcon}`} span={4}>
                      <Icon type={reservationDetail.icon} />
                    </Col>
                    <Col className={`${styles.detailDisplay}`} span={20}>
                      {reservationDetail.display}
                    </Col>
                  </Row>
                )}
                {reservationDetail.icon === 'text' && (
                  <>
                    <Row key={index + reservationDetail.text} className={`${styles.detail}`} type="flex">
                      <Col className={`${styles.detailResDisplay}`} span={20}>
                        {reservationDetail.text}
                      </Col>
                    </Row>
                    <Row key={index + reservationDetail.code} className={`${styles.detail}`} type="flex">
                      <Col className={`${styles.detailResDisplay}`} span={20}>
                        {reservationDetail.display}
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ))}
          </div>
          {!isBlockReservation && !isSystemBlock && (
            <Row>
              <Col>{intl.get('multicalendar.headerLabels.quickStatusUpdate').d('Quick Status Update:')}</Col>
              <Col>
                <Row gutter={8}>
                  <Col span={16}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={selectedBookingStatus}
                      onChange={setSelectedBookingStatus}
                      style={{ width: '100%' }}
                    >
                      {bookingStatusUpdateOptions.map(option => (
                        <Option value={option.value} key={option.value}>
                          {intl.get(`reservations.reservationDetails.bookingStatus.${option.label}`).d(option.label)}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      disabled={shouldDisableUpdateStatusButton || checkIsAdminReadOnly()}
                      onClick={handleUpdateStatusButtonClick}
                    >
                      {intl.get('multicalendar.headerLabels.updateStatus').d('Update Status')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={1}>
          <div className={`${styles.vl}`}></div>
        </Col>
        <Col span={6}>
          <div className={`${styles.allButtons}`}>
            <Row className={`${styles.modalButtons}`}>
              <a target="_blank" href={`/reservation/${reservation._id}`} rel="noopener noreferrer">
                <Button type="primary" disabled={!!reservation.blockedBy} block>
                  {intl.get('multicalendar.headerLabels.viewBooking').d('View Booking')}
                </Button>
              </a>
            </Row>
            {isBlockReservation && !reservation.blockedBy ? (
              <div>
                <Row className={`${styles.modalButtons}`}>
                  <Button
                    type="danger"
                    disabled={checkIsAdminReadOnly()}
                    onClick={() => handleOnUnblock({ reservation, roomTypeId, handleOnMCRateDateActionSuccess })}
                    block
                  >
                    {intl.get('multicalendar.headerLabels.unblock').d('Unblock')}
                  </Button>
                </Row>
                <Row className={`${styles.modalButtons}`}>
                  <Button
                    style={{ margin: 0, color: '#f00', borderColor: '#f00' }}
                    disabled={checkIsAdminReadOnly()}
                    onClick={onClickUnblockButton}
                    block
                  >
                    {intl.get('multicalendar.headerLabels.unblockByDate').d('Unblock by date')}
                  </Button>
                </Row>
              </div>
            ) : (
              <div>
                <Row className={`${styles.modalButtons}`}>
                  {authorizedItem([permissionConstants.RESERVATIONS_CREATE], isAdmin, permissions) && (
                    <a target="_blank" href={`/bookingForm/duplicate?resId=${reservation._id}`} rel="noopener noreferrer">
                      <Button type="primary" disabled={!!reservation.blockedBy || checkIsAdminReadOnly()} block>
                        {intl.get('multicalendar.headerLabels.duplicateBooking').d('Duplicate Booking')}
                      </Button>
                    </a>
                  )}
                </Row>
                <Row className={`${styles.modalButtons}`}>
                  <a target="_blank" href={`/reservation/${reservation._id}/?selectedTab=activityLogs`} rel="noopener noreferrer">
                    <Button block>{intl.get('multicalendar.headerLabels.log').d('Activity Log')}</Button>
                  </a>
                </Row>
              </div>
            )}
          </div>
          <Row>
            <Button onClick={onClickCancelReservationSummary} block>
              {intl.get('multicalendar.headerLabels.cancel').d('Cancel')}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default withAppContext(MCModalReservationSummary);
