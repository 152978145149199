import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Alert, Button, Input, Table, Icon, Tooltip, message } from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import styles from '../../Overview.module.css';
import { getHealthcheck } from 'utils/apis/bookingIssue';
import intl from 'react-intl-universal';

const BulkImportMOdal = props => {
  // // Your array of objects
  // const dataArray = [
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   },
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   }
  //   // ... (other objects in the array)
  // ];

  // // Use map to create a new array with haveError removed
  // const newArray = dataArray.map(obj => {
  //   // Create a copy of the object without the haveError property
  //   const newObj = { ...obj };
  //   delete newObj.haveError;
  //   return newObj;
  // });

  // console.log('newArray', newArray);

  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState();
  const [searchText, setSearchText] = useState('');

  // const GetHealthcheck = async () => {
  //   const res = await getHealthcheck();

  //   console.log('getHealthcheck', res);

  //   if (res.status === 200) {
  //   message.success('Email Send Successfully!');
  //   }
  // };

  const getColumnSearchProps = (dataIndex, customName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node);
          }}
          placeholder={`Search ${customName}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          marginRight: 8,
          fontSize: 18,
          color: filtered ? '#ffc069' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text => {
      if (text) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        );
      }
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText('');
  };

  const columns = [
    {
      title: intl.get('reservations.transactions.tableDetails.date').d('Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      //   sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (value, record) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: intl.get('overview.tableColumns.platform').d('Platform'),
      dataIndex: 'ota',
      key: 'ota'
      //   ...getColumnSearchProps('ota', 'ota')
    },
    {
      title: intl.get('overview.tableColumns.name').d('Name'),
      dataIndex: 'hostName',
      key: 'hostName'
      //   ...getColumnSearchProps('hostName', 'Name')
    },
    {
      title: intl.get('overview.tableColumns.property').d('Property Name'),
      dataIndex: 'roomTypeId',
      key: 'roomTypeId',
      //   ...getColumnSearchProps('roomTypeId', 'Property')
      render: (value, record) => {
        return <div>{value ? value.property.name : 'N/A'}</div>;
      }
    },
    {
      title: intl.get('overview.tableColumns.roomType').d('Room Type'),
      dataIndex: 'roomTypeId',
      key: 'roomTypeId',
      //   ...getColumnSearchProps('roomTypeId', 'Room Type')
      render: (value, record) => {
        return <div>{value ? value.name : 'N/A'}</div>;
      }
    },
    {
      title: intl.get('overview.tableColumns.desc').d('Description'),
      dataIndex: 'description',
      key: 'description',
      //   ...getColumnSearchProps('description', 'Description')
      render: (value, record) => {
        return (
          <Tooltip
            overlayStyle={{ maxWidth: '90%' }}
            title={
              <div>
                {value.map((item, index) => {
                  return (
                    <Row key={index} style={{ padding: 8 }}>
                      {item}
                    </Row>
                  );
                })}
              </div>
            }
          >
            {value[0]}
          </Tooltip>
        );
      }
    },
    {
      title: intl.get('overview.tableColumns.codeLabel').d('Code'),
      dataIndex: 'code',
      key: 'code'
      //   ...getColumnSearchProps('status', 'Status Code')
    }
  ];

  return (
    <Modal
      title={intl.get('overview.headerLabels.bookingIssue').d('Booking Issues')}
      visible={props.visible}
      onCancel={() => {
        props.onCancel();
      }}
      width={'90%'}
      footer={null}
    >
      <Alert
        type="error"
        style={{ marginBottom: 24 }}
        message={
          <div>
            <Icon className={`${styles.warningOnClashingReservationIcon}`} type="warning" />
            {intl
              .get('overview.headerLabels.detectedIssue')
              .d('Detected isssue could possible effecting pricing and inventory update to target OTA.')}
          </div>
        }
      />

      {/* <Row style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => GetHealthcheck()}>
          Send Email
        </Button>
      </Row> */}

      <Table
        bordered
        size="small"
        rowKey={'id'}
        // scroll={{
        //   x: true
        // }}
        columns={columns}
        dataSource={props.data}
        loading={loading}
      />

      <div>{intl.get('overview.headerLabels.contact').d('Please contact customer service for further assistance.')}</div>
    </Modal>
  );
};

export default BulkImportMOdal;
