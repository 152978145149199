import React from 'react';
import { Card, Row } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import styles from './HotelDetails.module.css';
import intl from 'react-intl-universal';

const HotelDetails = ({ isEdit, travelokasSelection, selectedTravelokaId, setSelectedTravelokaId, selectedTraveloka }) => {
  return (
    <Card title={intl.get('hostConnect.integration.headerLabels.hotelDetail').d('Hotel Details')}>
      <label className="ota-label">{intl.get('hostConnect.traveloka.headerLabels.travelokaHotel').d('Traveloka Hotel')}</label>
      <BasicSelect
        selectClassName={styles.selection}
        selections={travelokasSelection}
        value={selectedTravelokaId}
        placeholder={intl.get('hostConnect.traveloka.placeholder.travelokaHotel').d('Select a Traveloka hotel')}
        onChange={setSelectedTravelokaId}
        isDisabled={isEdit}
      />
      {selectedTraveloka.travelokaActualPropertyId ? (
        <Row style={{ marginTop: 12 }}>
          <div>
            <b>
              {intl
                .get('hostConnect.integration.message.foundHotel', {
                  foundHotel: 'Found Hotel:'
                })
                .d('Found Hotel:')}
            </b>{' '}
            ({selectedTraveloka.travelokaActualPropertyId}){' '}
            {selectedTraveloka.travelokaPropertyName || intl.get('hostConnect.traveloka.placeholder.noPropertName').d('No Property Name')}
          </div>
        </Row>
      ) : (
        <Row style={{ marginTop: 12 }}>
          <div>{intl.get('hostConnect.traveloka.placeholder.travelokaCode').d('Please input a valid Traveloka Hotel Code')}</div>
        </Row>
      )}
    </Card>
  );
};

export default HotelDetails;
