import { httpClient } from './../httpClient';

export const getLocaleForUser = () =>
  httpClient
    .get('/locale')
    .then(response => response)
    .catch(error => {
      return error.response;
    });

//put it inside body, is it ok ?
export const setLocaleForDatabase = locale =>
  httpClient
    .post('/locale', { locale })
    .then(response => response)
    .catch(error => {
      return error.response;
    });
