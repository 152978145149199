import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { getConstants } from 'utils/apis/constants';
import { CONTACT_NUMBER_REGEX, EMAIL_REGEX } from 'utils/constants';
import { Form, Input, Icon, Button, Select, Popconfirm, Col, Row } from 'antd';

import './DynamicFieldSet.css';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const Option = Select.Option;
let uuid = 0;

class DynamicFieldSet extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      countryOptions: [],
      stateOptions: [],
      update: false
    };
  }

  remove = k => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('keys').filter(key => key !== k);

    // can use data-binding to set
    form.setFieldsValue({
      keys: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [...fieldsToValidate, `firstName[${key}]`, `lastName[${key}]`, `nationality[${key}]`, `contactNo[${key}]`];
      }, []),
      { first: true },
      (err, values) => {
        const newValues = form.getFieldsValue();
        newValues.keys = newKeys; //in case setFieldsValue sets keys after the getFieldsValue
        onChange(newValues, !err);
      }
    );
  };

  add = () => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(uuid);
    uuid++;
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
    onChange(newValues, false);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    // e.preventDefault();
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  checkNationalityMY = k => {
    const { form } = this.props;
    const nationality = form.getFieldValue(`nationality[${k}]`);
    return nationality === 'MY';
  };

  componentDidMount() {
    const { data, form } = this.props;
    if (data.length > 0) {
      data[0]['key'] = 0;
    }

    let keys = data.map(d => d.key);
    if (keys.length > 0) {
      form.setFieldsValue(
        {
          keys
        },
        this.setState({
          update: true
        })
      );
    } else {
      form.setFieldsValue({
        keys: [0]
      });
      uuid++;
    }

    getConstants('countries').then(resCountries => {
      if (resCountries && resCountries.status === 200) {
        let countries = Object.keys(resCountries.data).map(k => {
          return resCountries.data[k];
        });
        if (countries.length > 0) {
          this.setState({
            countryOptions: countries.map(u => {
              return { value: u.iso2, label: u.name };
            })
          });
        }
      } else {
        console.log('Error while retrieving countries');
      }
    });

    getConstants('statesMY').then(resStates => {
      if (resStates && resStates.status === 200) {
        let states = Object.keys(resStates.data).map(k => {
          return resStates.data[k];
        });
        if (states.length > 0) {
          this.setState({
            stateOptions: states.map(state => {
              return { value: state.code, label: state.label };
            })
          });
        }
      } else {
        console.log('Error while retrieving states');
      }
    });
  }

  componentDidUpdate(prevProp, prevState) {
    const { update } = this.state;
    const { data, form } = this.props;
    if (update) {
      data.forEach(d => {
        let obj = {};
        let key = `firstName[${d.key}]`;
        let key2 = `lastName[${d.key}]`;
        let key3 = `nationality[${d.key}]`;
        let key4 = `contactNo[${d.key}]`;
        let key5 = `icNo[${d.key}]`;
        let key6 = `email[${d.key}]`;
        let key7 = `state[${d.key}]`;
        obj[key] = d.firstName;
        obj[key2] = d.lastName;
        obj[key3] = d.nationality;
        obj[key4] = d.contactNo;
        obj[key5] = d.icNo;
        obj[key6] = d.email;
        obj[key7] = d.state;
        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { disabled } = this.props;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => {
      const { countryOptions, stateOptions } = this.state;
      return (
        <React.Fragment>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.firstName').d('First Name')} required={true} key={`firstName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`firstName[${k}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.firstName').d("Please input guest's first name.")
                      },
                      {
                        //no digits and special characters regex
                        pattern: /^[^!"#$%&'+/:;<=>?[\\\]^_`{|}~]{1,25}$/u, // Regex for letters in any language
                        message: intl.get('booking_form.guestDetails.alertMessage.firstNameRegex').d('First name must contain only letters.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.firstName').d('First Name')}
                      onBlur={this.handleSubmit}
                      autoComplete="firstName"
                      onBeforeInputCapture={this.handleSubmit}
                    />
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.lastName').d('Last Name')} required={true} key={`lastName${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`lastName[${k}]`, {
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: intl.get('booking_form.guestDetails.alertMessage.lastName').d("Please input guest's last name.")
                      },
                      {
                        //no digits and special characters regex
                        pattern: /^[^!"#$%&'+/:;<=>?[\\\]^_`{|}~]{1,25}$/u, // Regex for letters in any language
                        message: intl.get('booking_form.guestDetails.alertMessage.lastNameRegex').d('Last name must contain only letters.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.lastName').d('Last Name')}
                      onBlur={this.handleSubmit}
                      autoComplete="lastName"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.nationality').d('Nationality (Optional)')} key={`nationality${k}`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`nationality[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur']
                  })(
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder={intl.get('booking_form.guestDetails.placeholder.selectCountry').d('Select a Country')}
                      optionFilterProp="children"
                      onBlur={this.handleSubmit}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {countryOptions.map(countries => (
                        <Option value={countries.value} key={countries.value}>
                          {intl.get(`countries.${countries.label.replace(/\./g, '_')}`).d(countries.label)}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.contactNumber').d('Contact Number')} key={`contactNo[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`contactNo[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        whitespace: true,
                        pattern: CONTACT_NUMBER_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.contactNumber').d("Please input guest's contact number.")
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.contactNumber').d('Contact Number')}
                      onBlur={this.handleSubmit}
                      autoComplete="contactNo"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.state').d('State (Optional)')} key={`state[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {this.checkNationalityMY(k)
                    ? getFieldDecorator(`state[${k}]`)(
                        <Select
                          disabled={disabled}
                          showSearch
                          placeholder={intl.get('booking_form.guestDetails.placeholder.selectState').d('Select a State')}
                          optionFilterProp="children"
                          onBlur={this.handleSubmit}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {stateOptions.map(state => (
                            <Option value={state.value} key={state.value}>
                              {intl.get(`statesMY.${state.label.replace(/\./g, '_')}`).d(state.label)}
                            </Option>
                          ))}
                        </Select>
                      )
                    : getFieldDecorator(`state[${k}]`, {
                        rules: [
                          {
                            pattern: /^[a-zA-Z ]*$/,
                            whitespace: true,
                            message: intl.get('booking_form.guestDetails.alertMessage.state').d("Please input guest's state. (Alphabets only)")
                          }
                        ]
                      })(
                        <Input
                          disabled={disabled}
                          placeholder={intl.get('booking_form.guestDetails.placeholder.state').d('State')}
                          onBlur={this.handleSubmit}
                          autoComplete="state"
                        />
                      )}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem label={intl.get('booking_form.guestDetails.email').d('E-mail (Optional)')} key={`email[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`email[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        pattern: EMAIL_REGEX,
                        message: intl.get('booking_form.guestDetails.alertMessage.email').d('Please enter a valid email address.')
                      }
                    ]
                  })(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.placeholder.email').d('Email')}
                      type="email"
                      onBlur={this.handleSubmit}
                      autoComplete="emailField"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <Col span={24}>
              <FormItem label={intl.get('booking_form.guestDetails.ic').d('IC/Passport No, (Optional)')} key={`icNo[${k}]`}>
                <div className="guest-input-control-wrapper">
                  {getFieldDecorator(`icNo[${k}]`)(
                    <Input
                      disabled={disabled}
                      placeholder={intl.get('booking_form.guestDetails.placeholder.ic').d('IC/Passport No.')}
                      onBlur={this.handleSubmit}
                      autoComplete="icNo"
                    />
                  )}
                </div>
              </FormItem>
            </Col>
          </Row>
          <FormItem className="guest-input">
            {keys.length > 1 ? (
              <div className="guest-input-control-wrapper">
                <Popconfirm
                  title={intl.get('booking_form.guestDetails.areYouSureRemoveGuest').d('Are you sure you want to remove this guest?')}
                  onConfirm={() => this.remove(k)}
                  okText={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                  cancelText={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
                >
                  <Button type="danger" disabled={keys.length === 1} style={{ width: '100%' }}>
                    <Icon type="minus" /> {intl.get('booking_form.guestDetails.removeGuest').d('Remove guest')}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}
          </FormItem>
        </React.Fragment>
      );
    });
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <FormItem className="guest-input">
          <Button disabled={disabled} type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> {intl.get('booking_form.guestDetails.addGuest').d('Add guest')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create()(DynamicFieldSet);
export default WrappedDynamicFieldSet;
