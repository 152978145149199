import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, Modal, Avatar, notification, Tabs, Radio, Input } from 'antd';

import { withAppContext } from 'context/AppContext';
import { getPropertyTypesConstant, getCountriesConstant, getStatesConstant, getPropertyAmenitiesConstant } from 'utils/apis/constants';
import { convertAmenitiesSelectionToObj, capitalizeFirstLetter } from 'utils/general';
import { putUpdateProperty, deleteRemovePropertyValidation, deleteProperty } from 'utils/apis/property';
import { buildListingUri, buildIOTAFormUri, buildServicePackageEditUri } from 'utils/routes';

import airbnbLogo from 'images/airbnb-logo.png';
import ctripLogo from 'images/ctrip-logo.png';
import bookingcomLogo from 'images/booking-logo-icon.png';

import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import BookingEngineConfigDetailsCard from './components/BookingEngineConfigDetailsCard/BookingEngineConfigDetailsCard';
import LocationCard from './components/LocationCard/LocationCard';
import AmenitiesCard from '../components/AmenitiesCard/AmenitiesCard';
import PhotosCard from '../components/PhotosCard/PhotosCard';
import intl from 'react-intl-universal';

import styles from './Property.module.css';
import { listingDeletionReasons } from 'utils/constants';

const TabPane = Tabs.TabPane;
class Property extends Component {
  constructor() {
    super();

    this.state = {
      isSaving: false,
      isDeleting: false,
      deletionReasonModal: false,
      selectedReason: null,
      otherReason: ''
    };
  }

  extractBasicDetailsData = () => {
    const { data } = this.props;

    return {
      name: data.name,
      type: data.propertyTypes ? Number(data.propertyTypes) : undefined,
      checkInTime: data.checkInTime,
      checkOutTime: data.checkOutTime,
      minNight: data.minNight,
      maxNight: data.maxNight,
      displayName: data.displayName ? data.displayName : undefined
    };
  };

  extractBookingEngineConfigDetailsData = () => {
    const { data } = this.props;

    return {
      bookingEnginePropertyDisplayName: data.displayName
    };
  };

  extractLocationData = () => {
    const { data } = this.props;

    return {
      country: data.countryCode,
      address: data.street,
      city: data.city,
      zipcode: data.zipCode,
      state: data.state,
      latitude: data.latitude,
      longitude: data.longitude
    };
  };

  extractAmenitiesData = () => {
    const { data, propertyAmenities } = this.props;
    // const { propertyAmenities } = this.state;
    return convertAmenitiesSelectionToObj(data.propertyAmenities, propertyAmenities);
  };

  extractPhotosData = () => {
    const { data } = this.props;
    return {
      photos:
        data.images && data.images.length > 0
          ? data.images.map(image => ({
              link: image.imageUrl,
              caption: image.caption[0] && image.caption[0].text ? image.caption[0].text : ''
            }))
          : []
    };
  };

  handleOnSave = () => {
    const { form, data } = this.props;
    const payload = [form.getFieldsValue()]
      .map(this.formatAmenities)
      .map(this.formatTime)
      .map(this.formatBookingEngineConfigDetails)
      .map(this.formatImages)[0];
    this.setState({
      isSaving: true
    });
    return putUpdateProperty(data._id, payload)
      .then(data => {
        this.setState({
          isSaving: false
        });
        notification.success({
          message: intl.get('listings.property.message.updated').d('Your property has been updated!')
        });
      })
      .catch(e => {
        notification.error({
          message: intl.get('listings.property.message.updateError').d('Something went wrong and your property is not updated')
          // description: e.message
        });
      });
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const onOkAction = this.handleOnSave;
        Modal.confirm({
          title: intl.get('listings.property.message.updateTitle').d('Do you want to update this property?'),
          content: intl
            .get('listings.property.message.updateContent')
            .d('Updating this property will affect all units and room types that tied to this property.'),
          onOk() {
            return onOkAction();
          },
          onCancel() {}
        });
      }
    });
  };

  formatAmenities = formFields => {
    const newFormFields = { ...formFields };
    const { propertyAmenities } = this.props;
    const filtteredAmenitiesFormData = Object.keys(propertyAmenities).reduce((formattedData, key) => {
      const newKey = String(key).toLowerCase();
      const newData = {
        ...formattedData,
        [newKey]: formFields[newKey]
      };
      // Remove key from form
      delete newFormFields[newKey];
      return newData;
    }, {});

    const formattedAmenities = Object.keys(filtteredAmenitiesFormData).reduce((newData, key) => {
      if (filtteredAmenitiesFormData[key] && Array.isArray(filtteredAmenitiesFormData[key])) {
        return [...newData, ...filtteredAmenitiesFormData[key]];
      }
      return newData;
    }, []);

    return {
      ...newFormFields,
      propertyAmenities: formattedAmenities
    };
  };

  formatTime = formFields => {
    return {
      ...formFields,
      checkInTime: formFields.checkInTime.format('HH:mm'),
      checkOutTime: formFields.checkOutTime.format('HH:mm')
    };
  };

  formatBookingEngineConfigDetails = formFields => {
    return {
      ...formFields,
      displayName: formFields.bookingEnginePropertyDisplayName
    };
  };

  handleModalOk = async () => {
    const { history, data } = this.props;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { selectedReason, otherReason } = values;

        // Determine the reason to use
        const reason = selectedReason === 'others' ? otherReason : selectedReason;
        console.log(reason);

        try {
          // Call the updateDeletedUnit function
          await deleteProperty(data._id, reason);

          // Close the modal after action
          this.setState({
            deletionReasonModal: false,
            selectedReason: null // Reset selection after modal closes
          });

          // Show success notification
          notification.success({
            message: intl.get('listings.property.propertyDeleted').d('Property Deleted')
          });

          // Redirect to the listing URI
          history.push(buildListingUri());
        } catch (error) {
          // Handle any errors that occur during the update
          console.error('Error updating property:', error);
          notification.error({
            message: intl.get('listings.unit.message.errorOccured').d('An Error Occured'),
            description: intl.get('listings.property.message.unableToDelete').d('Unable to delete this Property, Please try again later')
          });
        }
      }
    });
  };

  // Handle radio button change
  handleOptionChange = e => {
    this.setState({
      selectedReason: e.target.value
    });
  };

  handleOptionChange = e => {
    this.setState({
      selectedReason: e.target.value,
      otherReason: '' // reset 'otherReason' if another option is selected
    });
  };

  formatImages = formFields => {
    return {
      ...formFields,
      photos: undefined,
      images:
        formFields.photos && formFields.photos.length > 0
          ? formFields.photos.map(data => ({
              imageUrl: data.link,
              caption: data.caption ? [{ text: data.caption }] : undefined
            }))
          : []
    };
  };

  onDeleteProperty = () => {
    const { data, history } = this.props;
    this.setState({
      isDeleting: true
    });
    deleteRemovePropertyValidation(data._id)
      .then(() => {
        this.setState({
          isDeleting: false,
          deletionReasonModal: true // show modal after deletion
        });
      })
      .catch(err => {
        this.setState({
          isDeleting: false
        });
        if (err.response && err.response.status && err.response.status === 400) {
          const errorRes = err.response.data || {};
          this.propertyDeleteErrorMessage(errorRes);
        } else {
          //message.error('Oops! Something went wrong. Please try again later.');
        }
      });
  };

  propertyDeleteErrorMessage = errorRes => {
    const { unitData } = this.props;
    let isUnitInRelationshipMsg = [];
    let servicePackageNameMsg = [];
    let syncedOtasErrMsgMsg = [];
    let actionIndex = 0;
    let roomTypeOtasMsgDisplayed = false;
    console.log(errorRes);

    Modal.error({
      title: intl.get('listings.property.message.unableToDelete').d('Unable to delete this Property'),
      content: (
        <div>
          {errorRes.map((error, index) => {
            const isUnitInRelationship = error.isUnitInRelationship;
            const servicePackageName = error.servicePackage && error.servicePackage.name;
            const servicePackageID = error.servicePackage && error.servicePackage._id;
            const syncedOtas = error.syncedOtas;
            const roomTypeOTAs = [...new Set(error.roomTypeOTAs)];

            console.log(roomTypeOTAs);

            if (isUnitInRelationship) {
              isUnitInRelationshipMsg.push(isUnitInRelationship);
            }
            if (servicePackageName) {
              servicePackageNameMsg.push(servicePackageName);
            }
            if (syncedOtas && syncedOtas.length > 0) {
              syncedOtasErrMsgMsg.push(syncedOtas);
            }
            if (roomTypeOTAs && roomTypeOTAs.length > 0) {
              syncedOtasErrMsgMsg.push(roomTypeOTAs);
            }

            const roomTypeOtasMsg = roomTypeOTAs.length !== 0 && !roomTypeOtasMsgDisplayed && (
              <p>
                {intl.get('listings.property.message.syncWith').d('Room Type(s) currently synced with')}{' '}
                <b>
                  <a
                    href={buildIOTAFormUri({ propertyId: unitData.roomType.property._id, unitId: error.unitId, integrationType: 'hotel' })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {roomTypeOTAs
                      .map(syncedOta => capitalizeFirstLetter(syncedOta))
                      .reduce(
                        (roomTypeOtasErrMsg, source, i) =>
                          `${roomTypeOtasErrMsg}${
                            i === roomTypeOTAs.length - 1 ? `${intl.get('listings.roomType.message.and').d(' and ')}` : ', '
                          }${source}`
                      )}
                  </a>
                </b>{' '}
                <hr />
              </p>
            );
            //Ensure that the display message only Display ONCE !
            if (roomTypeOtasMsg) {
              roomTypeOtasMsgDisplayed = true;
            }

            const servicePackageErrMsg = servicePackageName && (
              <span>
                {intl.get('listings.unit.message.packagedUnder').d('packaged under')}{' '}
                <a href={buildServicePackageEditUri(servicePackageID)} target="_blank" rel="noopener noreferrer">
                  <b>{servicePackageName}</b>
                </a>{' '}
                {intl.get('listings.unit.message.servicePackage').d('service package')}
              </span>
            );

            const syncedOtasErrMsg = !!syncedOtas && Array.isArray(syncedOtas) && syncedOtas.length > 0 && (
              <span>
                {intl.get('listings.unit.message.syncWith').d('synced with')}{' '}
                <b>
                  <a
                    href={buildIOTAFormUri({ propertyId: unitData.roomType.property._id, unitId: error.unitId })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {syncedOtas
                      .map(syncedOta => capitalizeFirstLetter(syncedOta))
                      .reduce(
                        (syncedOtasErrMsg, source, i) =>
                          `${syncedOtasErrMsg}${
                            i === syncedOtas.length - 1 ? `${intl.get('listings.roomType.message.and').d(' and ')}` : ', '
                          }${source}`
                      )}
                  </a>
                </b>
              </span>
            );

            return (
              <div key={index}>
                {roomTypeOtasMsg}
                {error.unitName && (
                  <div>
                    <p>
                      {error.unitName} {intl.get('listings.roomType.message.is').d('is')}
                      {isUnitInRelationship && `${intl.get('listings.unit.message.havingParentChild').d('having parent-child relation')}`}
                      {isUnitInRelationship && servicePackageName && `${intl.get('listings.roomType.message.and').d(' and ')}`}
                      {servicePackageErrMsg}
                      {(isUnitInRelationship || servicePackageName) && syncedOtasErrMsg && `${intl.get('listings.roomType.message.and').d(' and ')}`}
                      {syncedOtasErrMsg}.
                    </p>
                  </div>
                )}
              </div>
            );
          })}
          <hr />
          <p>
            {intl.get('listings.property.message.toDelete').d('To delete this Property, please')}:
            {isUnitInRelationshipMsg && isUnitInRelationshipMsg.length > 0 ? (
              <div>
                {++actionIndex}) {intl.getHTML('listings.unit.message.unlink').d('Unlink the <b>parent-child unit(s)</b>')}
              </div>
            ) : null}
            {servicePackageNameMsg && servicePackageNameMsg.length > 0 ? (
              <div>
                {++actionIndex}) {intl.getHTML('listings.roomType.message.removeFromServicePackage').d('Remove all units from the service package')}
              </div>
            ) : null}
            {syncedOtasErrMsgMsg && syncedOtasErrMsgMsg.length > 0 ? (
              <div>
                {++actionIndex}) {intl.get('listings.roomType.message.desyncAll').d('Desync all listings OTAs')}
              </div>
            ) : null}
          </p>
          <p></p>
        </div>
      )
    });
  };

  handleOnDelete = e => {
    e.preventDefault();

    const onOkAction = this.onDeleteProperty;

    Modal.confirm({
      title: intl.get('listings.property.message.deleteTitle').d('Are you sure you want to delete this property?'),
      content: intl
        .get('listings.property.message.deleteContent')
        .d('Deleting this property will also delete ALL the room type(s) and unit(s) that linked to this property'),
      onOk() {
        return onOkAction();
      },
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button3a-editlisting' },
      cancelButtonProps: { id: 'canceldel-cfm-button3b-editlisting' }
    });
  };

  render() {
    const {
      checkIsAllowDeleteListing,
      form,
      hasFetchedUnit,
      shouldUpdateBasicDetails,
      shouldUpdateLocation,
      shouldUpdateAmenities,

      propertyAmenities,
      countriesSelection,
      statesSelection,
      propertyTypesSelection,

      hasFetchedPropertyAmenities,
      hasFetchedStates,
      hasFetchedCountries,
      hasFetchedPropertyTypes,

      allowBookingEngine,
      checkIsAdminReadOnly
    } = this.props;
    const { isSaving, isDeleting } = this.state;
    return (
      <div>
        <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
          <Tabs type="card" className={styles.unitTab} defaultActiveKey="1">
            <TabPane
              tab={intl.get('host.headerLabels.general').d('General Details')}
              key="1"
              className={styles.unitTabPane}
              forceRender={hasFetchedUnit}
            >
              <BasicDetailsCard
                form={form}
                cardClassname={styles.cardContainer}
                propertyTypes={propertyTypesSelection}
                hasFetchedPropertyTypes={hasFetchedPropertyTypes}
                defaultValues={this.extractBasicDetailsData()}
                shouldUpdate={shouldUpdateBasicDetails}
              />
              <LocationCard
                form={form}
                cardClassname={styles.cardContainer}
                hasFetchedStates={hasFetchedStates}
                hasFetchedCountries={hasFetchedCountries}
                states={statesSelection}
                countries={countriesSelection}
                defaultValues={this.extractLocationData()}
                shouldUpdate={shouldUpdateLocation}
              />
              <AmenitiesCard
                form={form}
                cardClassname={styles.cardContainer}
                hasFetchedAmenities={hasFetchedPropertyAmenities}
                amenities={propertyAmenities}
                defaultValues={this.extractAmenitiesData()}
                shouldUpdate={shouldUpdateAmenities}
              />
              <PhotosCard
                form={form}
                defaultValues={this.extractPhotosData()}
                captionPrefix="Property Image"
                cardClassname={styles.cardContainer}
                hasFetchedPhotos={hasFetchedUnit}
              />
            </TabPane>

            {allowBookingEngine === true && (
              <TabPane
                tab={intl.get('host.headerLabels.bookingEngineConfiguration').d('Booking Website Configuration')}
                key="2"
                className={styles.unitTabPane}
                forceRender={hasFetchedUnit}
              >
                <BookingEngineConfigDetailsCard
                  form={form}
                  cardClassname={styles.cardContainer}
                  defaultValues={this.extractBookingEngineConfigDetailsData()}
                />
              </TabPane>
            )}
          </Tabs>
          <Row type="flex" justify="start" gutter={8}>
            <Col>
              <Button
                id="save-button3-editlisting"
                type="primary"
                className={styles.margin}
                size="large"
                htmlType="submit"
                loading={isSaving}
                disabled={checkIsAdminReadOnly()}
              >
                {isSaving ? intl.get('listings.unit.headerLabels.saving').d('Saving') : intl.get('listings.unit.headerLabels.save').d('Save')}
              </Button>
              {checkIsAllowDeleteListing() && (
                <Button
                  id="del-button3-editlisting"
                  type="danger"
                  size="large"
                  onClick={this.handleOnDelete}
                  loading={isDeleting}
                  disabled={checkIsAdminReadOnly()}
                >
                  {intl.get('listings.unit.headerLabels.delete').d('Delete')}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
        {this.state.deletionReasonModal && (
          <Modal
            title={intl.get('listings.property.propertyDeleted').d('Property Deleted')}
            visible={this.state.deletionReasonModal}
            onOk={this.handleModalOk}
            okText={intl.get('listings.unit.headerLabels.confirm').d('Confirm')}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={false}
          >
            <p>
              {intl
                .get('listings.property.message.deleteReason')
                .d('The property has been deleted successfully. May we ask what is the reason leading to the deletion of this listing?')}
            </p>
            <Form>
              <Form.Item>
                {form.getFieldDecorator('selectedReason', {
                  rules: [{ required: true, message: intl.get('listings.unit.placeholder.selectReason').d('Please select a reason for deletion') }]
                })(
                  <Radio.Group onChange={this.handleOptionChange} value={this.state.selectedReason}>
                    {listingDeletionReasons.map(reason => (
                      <div key={reason.value}>
                        <Radio value={reason.key}>{intl.get(`listings.listingDeletionReason.${reason.label}`).d(reason.label)}</Radio>
                      </div>
                    ))}
                    <div>
                      <Radio value="others">{intl.get('listings.unit.headerLabels.others').d('Others')}</Radio>
                    </div>
                  </Radio.Group>
                )}
              </Form.Item>

              {this.state.selectedReason === 'others' && (
                <Form.Item>
                  {form.getFieldDecorator('otherReason', {
                    rules: [
                      {
                        required: true,
                        message: intl.get('listings.unit.placeholder.provideReason').d(`Please provide a reason if selecting 'Others'`)
                      }
                    ]
                  })(
                    <Input.TextArea
                      placeholder={intl.get('listings.unit.placeholder.specifyReason').d(`Please specify your reason`)}
                      rows={4}
                      style={{ marginTop: 8 }}
                    />
                  )}
                </Form.Item>
              )}
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

Property.propTypes = {
  form: PropTypes.object.isRequired,
  checkIsAdmin: PropTypes.func,
  data: PropTypes.object,
  hasFetchedUnit: PropTypes.bool,

  propertyAmenities: PropTypes.object,
  countriesSelection: PropTypes.array,
  statesSelection: PropTypes.array,
  propertyTypesSelection: PropTypes.array,

  hasFetchedPropertyAmenities: PropTypes.bool,
  hasFetchedStates: PropTypes.bool,
  hasFetchedCountries: PropTypes.bool,
  hasFetchedPropertyTypes: PropTypes.bool,

  allowBookingEngine: PropTypes.bool
};

Property.defaultProps = {
  checkIsAdmin: () => {},
  data: {},
  hasFetchedUnit: false,

  propertyAmenities: {},
  countriesSelection: [],
  statesSelection: [],
  propertyTypesSelection: [],

  hasFetchedPropertyAmenities: false,
  hasFetchedStates: false,
  hasFetchedCountries: false,
  hasFetchedPropertyTypes: false,

  allowBookingEngine: false
};

// NOTE: The purpose of having this class is for performance so that it doesn't rerender everything whenever user input something.
class WrappedFormProperty extends Component {
  RenderForm;

  constructor() {
    super();
    this.state = {
      shouldUpdateBasicDetails: true,
      shouldUpdateLocation: true,
      shouldUpdateAmenities: true,

      hasFetchedCountries: false,
      hasFetchedPropertyAmenities: false,
      hasFetchedPropertyTypes: false,
      hasFetchedStates: false,

      countriesSelection: [],
      propertyAmenities: {},
      propertyTypesSelection: [],
      statesSelection: []
    };
    const onFieldChangeAction = this.onFieldChange.bind(this);
    this.RenderForm = Form.create({
      onFieldsChange: onFieldChangeAction
    })(Property);
  }

  componentDidMount() {
    this.fetchPropertyAmenities();
    this.fetchPropertyTypes();
    this.fetchCountries();
    this.fetchStates('my'); //NOTE: Temporary hard coded
  }

  fetchPropertyTypes = () => {
    getPropertyTypesConstant()
      .then(res => {
        console.log('fetchPropertyTypes', res);
        this.setPropertTypesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchCountries = () => {
    getCountriesConstant()
      .then(res => {
        this.setCountriesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchStates = countryIsoCode => {
    getStatesConstant(countryIsoCode)
      .then(res => {
        this.setStatesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchPropertyAmenities = () => {
    getPropertyAmenitiesConstant()
      .then(res => {
        this.setPropertyAmenitiesState(res);
      })
      .catch(err => console.log(err));
  };

  setPropertTypesState = propertyTypes => {
    const formattedPropertyTypes = Object.keys(propertyTypes).map(key => ({
      key: propertyTypes[key].code,
      value: propertyTypes[key].label
    }));

    this.setState({
      hasFetchedPropertyTypes: true,
      propertyTypesSelection: formattedPropertyTypes
    });
  };

  setCountriesState = countries => {
    const formattedCountries = Object.keys(countries)
      .map(key => ({
        key: countries[key].iso2,
        value: countries[key].name
      }))
      .filter(country => country.key === 'MY'); // Only Malaysia has state now...

    this.setState({
      hasFetchedCountries: true,
      countriesSelection: formattedCountries
    });
  };

  setStatesState = states => {
    const formattedStates = Object.keys(states).map(key => ({
      key: states[key].code,
      value: states[key].label
    }));

    this.setState({
      hasFetchedStates: true,
      statesSelection: formattedStates
    });
  };

  setPropertyAmenitiesState = propertyAmenities => {
    let formattedPropertyAmenities = {};
    Object.keys(propertyAmenities).forEach(key => {
      const { code, label, type, airbnb, ctrip, bookingcom } = propertyAmenities[key];
      const formattedLabel = (
        <div>
          {intl.get(`listings.property.propertyAmnetities.${label}`).d(label)}{' '}
          {airbnb && (
            <>
              <Avatar size={15} src={airbnbLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
          {bookingcom && (
            <>
              <Avatar size={15} src={bookingcomLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
          {ctrip && (
            <>
              <Avatar size={15} src={ctripLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
        </div>
      );

      if (type in formattedPropertyAmenities) {
        formattedPropertyAmenities[type]['data'].push({
          key: code,
          value: formattedLabel
        });
      } else {
        formattedPropertyAmenities[type] = {
          icon: this.getPropertyAmenitiesIcon(type),
          data: [
            {
              key: code,
              value: formattedLabel
            }
          ]
        };
      }
    });
    this.setState({
      hasFetchedPropertyAmenities: true,
      propertyAmenities: formattedPropertyAmenities
    });
  };

  getPropertyAmenitiesIcon = type => {
    const icons = {
      Activities: 'dribbble',
      'Business Facilities': 'desktop',
      'Cleaning Services': 'smile-o',
      'Common Area': 'home',
      'Entertainment & Family Services': 'team',
      'Food & Drink': 'coffee',
      'Front Desk Services': 'customer-service',
      Miscellaneous: 'safety',
      Shops: 'shop',
      Transportation: 'car'
    };
    return icons[type] || 'tool';
  };

  onFieldChange = (props, fields) => {
    const { propertyAmenities } = this.state;
    const basicDetailFields = ['name', 'propertyTypes', 'checkInTime', 'checkOutTime', 'minNight', 'maxNight', 'displayName'];
    const locationFields = ['zipCode', 'countryCode', 'city', 'street', 'state', 'longitude', 'latitude'];
    const amenitiesFields = Object.keys(propertyAmenities).map(key => String(key).toLowerCase());

    const newState = {
      shouldUpdateBasicDetails: false,
      shouldUpdateLocation: false,
      shouldUpdateAmenities: false
    };
    if (basicDetailFields.includes(...Object.keys(fields))) {
      newState.shouldUpdateBasicDetails = true;
    }
    if (locationFields.includes(...Object.keys(fields))) {
      newState.shouldUpdateLocation = true;
    }
    if (amenitiesFields.includes(...Object.keys(fields))) {
      newState.shouldUpdateAmenities = true;
    }
    this.setState(newState);
  };

  render() {
    return <this.RenderForm {...this.props} {...this.state} />;
  }
}

export default withRouter(withAppContext(WrappedFormProperty));
