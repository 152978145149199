import React, { Component } from 'react';
import { Card, Form, Row, Col, Alert } from 'antd';
import { withRouter } from 'react-router-dom';
import { constructTwoDecimalsRule } from 'utils/general';

import WrappedActivityProvided from 'components/DynamicFieldSet/ActivityFieldSet';
// import WrappedTaxProvided from 'components/DynamicFieldSet/AddOnTaxFieldSet';
import FormInput from 'components/FormInput/FormInput';

import styles from './AddOn.css';
import WrappedCleaningProvided from 'components/DynamicFieldSet/CleaningFieldSet';
import intl from 'react-intl-universal';

const GeneralAlert = () => {
  return (
    <Alert
      className={styles.notes}
      message={intl.get('listings.bookingEngine.headerLabels.note').d('Notes')}
      description={
        <div>
          <p className={styles.notesText}>
            {intl.get('listings.bookingEngine.headerLabels.noteMsg').d('All settings configured here will be shown in booking website.')}
          </p>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

// const TaxForm = ({ form, defaultValues, onChange }) => {
//   return <WrappedTaxProvided form={form} defaultValues={defaultValues} onChange={onChange} />;
// };

const ActivityForm = ({ form, defaultValues, onChange, currency }) => {
  return <WrappedActivityProvided form={form} data={defaultValues} onChange={onChange} currency={currency} />;
};

const CleaningForm = ({ form, defaultValues, onChange, currency = 'RM' }) => {
  return <WrappedCleaningProvided form={form} data={defaultValues} onChange={onChange} currency={currency} />;
};

const generateForm = ({ form, defaultValues, currency = 'RM' }) => {
  return (
    <div>
      <Card
        title={intl.get('listings.bookingEngine.headerLabels.additionalSettings').d('Additional Settings')}
        className="add-on-details-card-container"
        bodyStyle={{ paddingTop: '10px' }}
      >
        <Row justify="start" gutter={8}>
          <Col span={8}>
            <div className="form-row">
              <label className="roomType-label">{intl.get('listings.bookingEngine.headerLabels.roomType').d('Room Type')}</label>
            </div>
          </Col>
          <Col span={8}>
            <div className="form-row">
              <label className="capacity-label">{intl.get('listings.bookingEngine.headerLabels.forGuest').d('For each guest after')}</label>
            </div>
          </Col>
          <Col span={8}>
            <div className="form-row">
              <label className="amount-label">
                {intl.get('listings.bookingEngine.headerLabels.amount').d('Amount per additional guest')} ({currency})
              </label>
            </div>
          </Col>
          {/* <Col span={6}>
            <div className="form-row">
              <label className="amount-label">Cleaning Fee(RM)</label>
            </div>
          </Col> */}
        </Row>
        {defaultValues.roomTypes.map(roomType => {
          return (
            <Row justify="start" gutter={[8, 0]}>
              <Col span={8}>
                <FormInput
                  className={styles.formInput}
                  name={`${roomType.key}.name`}
                  form={form}
                  defaultValue={
                    roomType.name +
                    ` (${intl.get('listings.bookingEngine.headerLabels.max').d('max')} ${roomType.capacity} ${intl
                      .get('listings.bookingEngine.headerLabels.pax')
                      .d('pax')})`
                  }
                  disabled={true}
                />
              </Col>
              <Col span={8}>
                <FormInput
                  className={styles.formInput}
                  name={`${roomType.key}.number`}
                  form={form}
                  defaultValue={roomType.extraGuest === undefined ? 0 : roomType.extraGuest.number}
                />
              </Col>
              <Col span={8}>
                <FormInput
                  className={styles.formInput}
                  name={`${roomType.key}.amount`}
                  form={form}
                  addonBefore={currency}
                  extraRules={[constructTwoDecimalsRule()]}
                  defaultValue={roomType.extraGuest === undefined ? 0 : roomType.extraGuest.amount}
                />
              </Col>
              {/* <Col span={6}>
                <FormInput
                  className={styles.formInput}
                  name={`${roomType.key}.cleaningFee`}
                  form={form}
                  addonBefore="RM"
                  extraRules={[constructTwoDecimalsRule()]}
                  defaultValue={roomType.cleaningFee}
                />
              </Col> */}
            </Row>
          );
        })}
      </Card>
      <Card
        title={intl.get('listings.bookingEngine.headerLabels.cleaningFee').d('Cleaning Fee')}
        className="add-on-details-card-container"
        bodyStyle={{ paddingTop: '10px' }}
      >
        <CleaningForm form={form} defaultValues={defaultValues.roomTypes} currency={currency} />
      </Card>
      {/* <Card title="Tax" className="add-on-details-card-container" bodyStyle={{ paddingTop: '10px' }}>
        <TaxForm form={form} defaultValues={defaultValues.taxes} />
      </Card> */}
      <Card
        title={intl.get('listings.bookingEngine.headerLabels.addOns').d('Add-ons')}
        className="add-on-details-card-container"
        bodyStyle={{ paddingTop: '10px' }}
      >
        <ActivityForm form={form} defaultValues={defaultValues.addOns} currency={currency} />
      </Card>
      <Card
        title={intl.get('listings.bookingEngine.headerLabels.serviceFee').d('Service Fee')}
        className="add-on-details-card-container"
        bodyStyle={{ paddingTop: '10px' }}
      >
        <Row justify="start">
          <Col span={4}>
            <FormInput className={styles.formInput} name={`serviceFee`} form={form} defaultValue={defaultValues.serviceFee} addonAfter={`%`} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

class AddOnForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomTypes: [],
      addOns: [],
      taxes: [],
      selectedRoomTypes: [],
      tax: [],
      activities: [],
      isButtonLoading: false,
      isLoading: true,
      guestDetailsNextReady: false,
      vehicleDetailsNextReady: true,
      itemsProvidedDetailsNextReady: true,
      serviceFee: 0
    };
    // this.handleOnInputChange = this.handleOnInputChange.bind(this);
    // this.handleOnStartCalendarChange = this.handleOnStartCalendarChange.bind(this);
    // this.handleOnEndCalendarChange = this.handleOnEndCalendarChange.bind(this);
    // this.handleGuestSubmit = this.handleGuestSubmit.bind(this);
    // this.handleVechicleSubmit = this.handleVehicleSubmit.bind(this);
    this.handleItemsProvidedSubmit = this.handleItemsProvidedSubmit.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleItemsProvidedSubmit = (values, nextReady) => {
    if (values) {
      const itemsProvided = values.keys.map(key => {
        return {
          key,
          item: values.item ? values.item[key] : '',
          label: values.label ? values.label[key] : ''
        };
      });
      this.setState({
        itemsProvided
      });
    }

    this.setState({
      itemsProvidedDetailsNextReady: nextReady
    });
  };

  // componentDidMount = async () => {
  //   const { roomTypes } = this.props;

  //   const newStateToSet = {};
  //   const newFormValue = {};
  // };

  // componentDidUpdate(prevProp, prevState) { }

  handleOnSubmit(e) {
    e.preventDefault();

    // const { activities } = this.state;

    this.setState({ isButtonLoading: true });
  }

  render() {
    // const { isButtonLoading, isFeeReady, currentUnit, originalBookingTotal, bookingTotal, bookingNights } = this.state;
    const { form, defaultValues, currency } = this.props;
    return (
      <Form className="addon-form-form">
        <Row className="addon-form-arrangement">
          <Col span={24}>
            <GeneralAlert />
          </Col>
          <Col span={24} className="addon-form">
            <div className="steps-content">{generateForm({ form: form, defaultValues: defaultValues, currency })}</div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default withRouter(Form.create()(AddOnForm));
