import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

import { AppContext } from 'context/AppContext';

import { formatToDateString, getCurrentMoment } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { buildTaskManagementChecklistCreateUri, buildTaskManagementChecklistEditUri } from 'utils/routes';

import { StyledCard, StyledHeader } from './Checklist.styles';
import { getAllChecklists } from 'utils/apis/taskManagement';
import intl from 'react-intl-universal';

const Checklist = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    getAllChecklists().then(res => {
      if (res.status === 200) {
        setChecklists(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.checklistName').d('Checklist Name'), 'checklistName', {
          linkFunc: record => buildTaskManagementChecklistEditUri(record._id)
        }),
        ...constructColumnFilterSearch('checklistName', intl.get('tables.checklistName').d('Search Checklist'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.hostName').d('Host Name'), 'host'),
        ...constructColumnFilterSearch('host', intl.get('tables.hostName').d('Search Host'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.property').d('Property Name'), 'property'),
        ...constructColumnFilterSearch('property', intl.get('tables.property').d('Search Property Name'))
      }
    ];
  };

  const constructCSV = checklists => {
    return checklists.map(checklist => {
      return {
        [intl.get('csv.checkListName').d('Checklist Name')]: checklist.checklistName,
        [intl.get('csv.host').d('Host')]: checklist.host,
        [intl.get('csv.propertyName').d('Property Name')]: checklist.property,
        [intl.get('csv.items').d('Items')]: checklist.items
      };
    });
  };

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);
  const csvName = useMemo(() => `checklists_${currentDate}.csv`, [currentDate]);

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementChecklistCreateUri()}>
            <Button id="create-button-checklist" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              {intl.get('taskManagement.checklist.headerLabels.createChecklist').d('Create Checklist')}
            </Button>
          </Link>
        )}
        <CSVLink filename={csvName} data={constructCSV(checklists)}>
          <Button id="csv-button-checklist" type="primary" icon="download" disabled={contextProvider.checkIsAdminReadOnly()}>
            {intl.get('taskManagement.taskList.headerLabels.csv').d('Download CSV')}
          </Button>
        </CSVLink>
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={checklists} />
    </StyledCard>
  );
};

export default Checklist;
