import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button, Card, Skeleton, Row, Col, message } from 'antd';

import FormSelection from 'components/FormSelection/FormSelection';

import { getConstants } from 'utils/apis/constants';
import { STANDARD_RATE_CODE } from 'utils/constants';
import { generatePercentageFromDisplay, guard } from 'utils/general';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { getAgodas, syncAgoda } from 'utils/apis/integration';

import OTARateInput from '../../../OTARateInput/OTARateInput';
import AgodaHomesAdvancedOptionsModal from './components/AgodaHomesAdvancedOptionsModal';
import intl from 'react-intl-universal';

class AgodaHomesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agodas: null,
      agodaSelections: null,
      rate: undefined,
      occupancyRate: undefined,
      agodaRoomRates: {},
      currency: 'RM',

      isSaving: false,
      rateModifierTypeConstants: [],
      rateModifierSignConstants: [],

      isAdvancedOptionsModalVisible: false
    };
  }

  componentDidMount = async () => {
    const { form, listingIntegration, propertyHostId } = this.props;
    if (listingIntegration && listingIntegration.currency) {
      this.setState({ currency: listingIntegration.currency });
    }

    getAgodas(propertyHostId, 'homes').then(res => {
      if (res.status === 200 && res.data) {
        const agodas = res.data;
        const agodaSelections = agodas.map(agoda => {
          return {
            key: agoda._id,
            value: agoda.propertyData
              ? agoda.propertyData.name + ' (' + agoda.agodaPropertyId + ')' ||
                intl.get('hostConnect.bookingcom.headerLabels.noPropertyName').d('No Property Name')
              : intl.get('hostConnect.bookingcom.headerLabels.noProperty').d('No Property')
          };
        });

        this.setState({
          agodas,
          agodaSelections
        });

        if (listingIntegration && listingIntegration._id) {
          form.setFieldsValue({
            agoda: listingIntegration.airbnbId
          });
          this.setState({
            rate: listingIntegration.rates.length > 0 && listingIntegration.rates[0].rate
          });
        } else {
          this.getWebRate();
        }
      }
    });

    const rateModifierTypeConstants = await getConstants('rateModifierType').then(res => Object.values(res.data));
    const rateModifierSignConstants = await getConstants('rateModifierSign').then(res => Object.values(res.data));

    this.setState({ rateModifierTypeConstants, rateModifierSignConstants });
  };

  getWebRate = async () => {
    const {
      unit: { roomType }
    } = this.props;
    const webRates = await getWebRateByRoomType(roomType._id);
    const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
    if (stdRate) {
      const { weekday, weekend, isDerived = false } = stdRate;
      this.updateRates({ weekday, weekend, isDerived });
    }
  };

  updateRates = newRates => {
    const { rate } = this.state;

    this.setState({ rate: { ...rate, ...newRates } });
  };

  constructRoomMapping = () => {
    const { listingIntegration, form } = this.props;
    const { rate, agodas, occupancyRate } = this.state;

    const roomMapping = [];
    if (listingIntegration) {
      const latestRate = rate || (listingIntegration.rates.length > 0 ? listingIntegration.rates[0].rate : listingIntegration.rate);
      roomMapping.push({
        agodaRatePlanId: listingIntegration.rates.length > 0 && listingIntegration.rates[0].agodaRatePlanId,
        ...(occupancyRate && { occupancyRate }),
        rate: latestRate
      });
    } else {
      const selectedAgodaId = form.getFieldValue('agoda');
      const selectedAgoda = agodas.find(agoda => agoda._id.toString() === selectedAgodaId);
      roomMapping.push({
        agodaRatePlanId: selectedAgoda.ratePlans[0].rateplan_id,
        ...(occupancyRate && { occupancyRate }),
        rate
      });
    }

    return roomMapping;
  };

  constructRatePlanMap = selectedAgodaId => {
    const { agodas } = this.state;

    const selectedAgoda = guard(() => (selectedAgodaId ? agodas.find(agoda => String(agoda._id) === selectedAgodaId) : undefined));

    if (!selectedAgoda) {
      return [];
    }

    const room = selectedAgoda.rooms[0];
    return room.rates.map(rate => {
      const selectedRatePlan = selectedAgoda.ratePlans.find(ratePlan => String(ratePlan.rateplan_id) === String(rate.agodaRatePlanId));
      if (!selectedRatePlan) {
        return message.error(intl.get('hostConnect.integration.message.syncIssue').d('Sync issue detected, local rate plan is not same with Agoda'));
      }
      return {
        ratePlanName: selectedRatePlan.rateplan_name,
        roomId: String(room.agodaRoomId),
        ratePlan_id: String(rate.agodaRatePlanId)
      };
    });
  };

  getAgodaRoomRates = () => {
    const { form } = this.props;
    const { agodas } = this.state;

    const selectedAgoda = form.getFieldValue('agoda');

    const agodaRoomRates = agodas.find(agoda => String(agoda._id) === String(selectedAgoda)).rooms[0];

    return agodaRoomRates;
  };

  handleOnAdvanceOptionsConfirm = rateDistributionPayload => {
    const { rate, occupancyRate, rateModifierTypeConstants, rateModifierSignConstants } = this.state;

    let processedRate = {};
    let processedOccupancyRate = undefined;
    const rateObject = rateDistributionPayload.length > 0 && rateDistributionPayload[0].rates.length > 0 && rateDistributionPayload[0].rates[0];
    if (!!rateObject) {
      processedRate = {
        weekday: rateObject.rate.weekdayRate,
        weekend: rateObject.rate.weekendRate,
        isDerived: rateObject.rate.isDerived
      };
      processedOccupancyRate = rateObject.occupancyRate;

      const isDerived = rateObject.rate.isDerived;
      if (!!isDerived) {
        const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
        const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
        let amount = rateObject.rate.modifierAmount;

        amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

        processedRate.calculation = {
          amount,
          type: rateObject.rate.modifierType,
          isPositive
        };
      }
    } else {
      processedRate = rate;
      processedOccupancyRate = occupancyRate;
    }

    this.setState({ rate: processedRate, occupancyRate: processedOccupancyRate, isAdvancedOptionsModalVisible: false });
  };

  handleOnSubmit = async () => {
    const { form, unit } = this.props;

    this.setState({
      isSaving: true
    });

    const roomMapping = this.constructRoomMapping();

    syncAgoda(form.getFieldValue('agoda'), null, unit._id, roomMapping)
      .then(() => {
        this.setState(
          {
            isSaving: false
          },
          () => {
            // onAfterSave(); // As of now we can't use onAfterSave() because it load too fast and the data hasn't update to latest sync status yet..
            window.location.reload();
          }
        );
      })
      .catch(() => {
        this.setState({
          isSaving: false
        });
      });
  };

  handleOnAdvanceOptionsClick = isVisible => e => {
    const { occupancyRate } = this.state;

    const agodaRoomRates = isVisible ? this.getAgodaRoomRates() : {};
    const agodaRate = agodaRoomRates.rates && agodaRoomRates.rates[0];
    const processedOccupancyRate = !occupancyRate && agodaRate && agodaRate.occupancyRate;

    e.preventDefault();
    this.setState({
      agodaRoomRates,
      ...(processedOccupancyRate && { occupancyRate: processedOccupancyRate }),
      isAdvancedOptionsModalVisible: isVisible
    });
  };

  handleOnListingsChange = value => {
    const { agodas } = this.state;
    const selectedListingInfo = agodas.find(agoda => agoda._id === value);
    if (selectedListingInfo) {
      this.setState({
        currency: selectedListingInfo.propertyData.currency
      });
    }
  };

  render() {
    const {
      agodas,
      agodaSelections,
      rate,
      occupancyRate,
      agodaRoomRates,
      isSaving,
      isAdvancedOptionsModalVisible,
      rateModifierTypeConstants,
      rateModifierSignConstants,
      currency
    } = this.state;

    const {
      form,
      onClose,
      unit: { roomType },
      checkIsAdminReadOnly
    } = this.props;

    const { getFieldValue } = this.props.form;
    const isLoading = !agodas;
    const selectedAgodaId = getFieldValue('agoda');
    let ratePlanMap = [];
    if (!isLoading) {
      ratePlanMap = this.constructRatePlanMap(selectedAgodaId);
    }

    return !isLoading ? (
      <>
        <Form>
          <FormSelection
            form={form}
            name="agoda"
            formLabel={intl.get('hostConnect.integration.headerLabels.agodaHome').d('Agoda Homes')}
            allowClear={false}
            selections={agodaSelections}
            onChange={this.handleOnListingsChange}
          />
          {ratePlanMap.map((ratePlan, index) => {
            return (
              <Card key={index} title={ratePlan.ratePlanName}>
                <OTARateInput rate={rate} updateRates={this.updateRates} currency={currency} />
              </Card>
            );
          })}
          {!!ratePlanMap && ratePlanMap.length > 0 && (
            <Row style={{ marginTop: '20px' }}>
              <a href=" " onClick={this.handleOnAdvanceOptionsClick(true)}>
                {intl.get('hostConnect.integration.headerLabels.advanced').d('Advanced Options')}
              </a>
            </Row>
          )}
          <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
            <Col>
              <Button type="secondary" onClick={onClose}>
                {intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" disabled={isLoading || isSaving || !selectedAgodaId || checkIsAdminReadOnly()} onClick={this.handleOnSubmit}>
                {intl.get('hostConnect.integration.headerLabels.save').d('Save')}
              </Button>
            </Col>
          </Row>
        </Form>

        {isAdvancedOptionsModalVisible && (
          <AgodaHomesAdvancedOptionsModal
            agodaRoomRates={agodaRoomRates}
            rate={rate}
            roomType={roomType}
            occupancyRate={occupancyRate}
            rateModifierTypeConstants={rateModifierTypeConstants}
            rateModifierSignConstants={rateModifierSignConstants}
            isVisible={isAdvancedOptionsModalVisible}
            onConfirm={this.handleOnAdvanceOptionsConfirm}
            onClose={this.handleOnAdvanceOptionsClick(false)}
            currency={currency}
          />
        )}
      </>
    ) : (
      <Skeleton />
    );
  }
}

AgodaHomesForm.propTypes = {
  listingIntegration: PropTypes.object,
  unit: PropTypes.object.isRequired,
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Form.create()(withRouter(AgodaHomesForm));
