import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';

import { REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';
import { generateDisplayPercentage, generateDisplayFee, checkHasValue } from 'utils/general';

import styles from './RateModifierFormItems.module.css';
import intl from 'react-intl-universal';

const getModifierAmountString = (rateModifierAmount, isPercentage) => {
  if (!checkHasValue(rateModifierAmount)) {
    return '1';
  } else if (isPercentage) {
    return generateDisplayPercentage(rateModifierAmount, true);
  } else {
    return generateDisplayFee(rateModifierAmount);
  }
};

const RateModifierFormItems = ({
  form,
  title,
  isPercentage,
  modifierTypeName,
  defaultModifierType,
  modifierSignName,
  defaultModifierSign,
  modifierAmountName,
  defaultRateModifierAmount,
  minValue,
  rateModifierTypeConstants,
  rateModifierSignConstants,
  onTypeChange,
  onSignChange,
  onAmountChange,
  currency = 'RM',
  timezone
}) => {
  const maxValue = isPercentage ? 100 : undefined;
  const precision = isPercentage ? 0 : 2;
  const formatter = isPercentage ? value => `${value}%` : value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const parser = isPercentage ? value => value.replace(/[^0-9]/g, '') : value => value.replace(REGEX_NOT_NUMBER_N_DOT, '');

  const modifierAmountString = getModifierAmountString(defaultRateModifierAmount, isPercentage);

  const defaultModifierAmount = modifierAmountString.replace(REGEX_NOT_NUMBER_N_DOT, '') * 1;
  const modifierAmountStyle = isPercentage ? styles.modifierAmountPercentage : '';

  return (
    <>
      <Row>{title}</Row>
      <Row type="flex" justify="space-between">
        <Col span={8}>
          <FormSelection
            form={form}
            name={modifierTypeName}
            isHideLabel
            requiredErrorMessage={intl.get('hostConnect.integration.placeholder.modifierType').d('Please select a modifier type')}
            defaultValue={defaultModifierType}
            selections={rateModifierTypeConstants}
            onChange={onTypeChange}
          />
        </Col>

        <Col span={3}>
          <FormSelection
            form={form}
            name={modifierSignName}
            isHideLabel
            requiredErrorMessage={intl.get('hostConnect.integration.placeholder.modifierSign').d('Please select a modifier sign')}
            defaultValue={defaultModifierSign}
            selections={rateModifierSignConstants}
            onChange={onSignChange}
          />
        </Col>

        <Col span={12}>
          <FormInputNumber
            form={form}
            name={modifierAmountName}
            classNameInput={modifierAmountStyle}
            requiredErrorMessage={intl.get('hostConnect.integration.placeholder.amount').d('Please enter an amount')}
            defaultValue={defaultModifierAmount}
            minValue={minValue}
            maxValue={maxValue}
            precision={precision}
            formatter={formatter}
            parser={parser}
            onChange={onAmountChange}
          />
        </Col>
      </Row>
    </>
  );
};

RateModifierFormItems.propTypes = {
  form: PropTypes.object.isRequired,
  title: PropTypes.any,
  isPercentage: PropTypes.bool.isRequired,
  modifierTypeName: PropTypes.string.isRequired,
  defaultModifierType: PropTypes.oneOf(['amount', 'percentage']),
  modifierSignName: PropTypes.string.isRequired,
  defaultModifierSign: PropTypes.oneOf(['+', '-']),
  modifierAmountName: PropTypes.string.isRequired,
  defaultRateModifierAmount: PropTypes.number.isRequired,
  minValue: PropTypes.number,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  onTypeChange: PropTypes.func,
  onSignChange: PropTypes.func,
  onAmountChange: PropTypes.func
};

RateModifierFormItems.defaultProps = {
  title: '',
  minValue: 1,
  onTypeChange: undefined,
  onSignChange: undefined,
  onAmountChange: undefined
};

export default RateModifierFormItems;
