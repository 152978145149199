import React from 'react';
import { Card, Row } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import styles from './HotelDetails.module.css';
import intl from 'react-intl-universal';

const HotelDetails = ({ isEdit, tiketcomsSelection, selectedTiketcomId, setSelectedTiketcomId, selectedTiketcom }) => {
  return (
    <Card title={intl.get('hostConnect.integration.headerLabels.hotelDetail').d('Hotel Details')}>
      <label className="ota-label">{intl.get('hostConnect.ticketcom.headerLabels.tiketcomHotel').d('Tiket.com Hotel')}</label>
      <BasicSelect
        selectClassName={styles.selection}
        selections={tiketcomsSelection}
        value={selectedTiketcomId}
        placeholder={intl.get('hostConnect.ticketcom.placeholder.tiketcomHotel').d('Select a Tiket.com hotel')}
        onChange={setSelectedTiketcomId}
        isDisabled={isEdit}
      />
      {selectedTiketcom.tiketcomPropertyId ? (
        <Row style={{ marginTop: 12 }}>
          <div>
            <b>
              {intl
                .get('hostConnect.integration.message.foundHotel', {
                  foundHotel: 'Found Hotel:'
                })
                .d('Found Hotel:')}{' '}
            </b>{' '}
            ({selectedTiketcom.tiketcomPropertyId}){' '}
            {selectedTiketcom.tiketcomPropertyName || intl.get('hostConnect.traveloka.placeholder.noPropertName').d('No Property Name')}
          </div>
        </Row>
      ) : (
        <Row style={{ marginTop: 12 }}>
          <div>{intl.get('hostConnect.ticketcom.placeholder.validCode').d('Please input a valid Tiket.com Hotel Code')}</div>
        </Row>
      )}
    </Card>
  );
};

export default HotelDetails;
