import React, { Component } from 'react';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import NumberToWords from 'number-to-words';
import intl from 'react-intl-universal';
import { LangContextConsumer } from '../../utils/LocaleProvider';
import { jsPDF } from 'jspdf';
import { font } from '../../fonts/NotoSansSC-Regular-normal';

import { numberWithCommas } from 'utils/general';
export default class RefundableDeposit extends Component {
  static propTypes = {
    data: PropTypes.object,
    currency: PropTypes.string
  };

  handleOnClickPdfBtn = url => {
    window.open(url, '_blank');
  };

  constructDataForRefundDepositPdf = reservation => {
    const { depositCollected, guestDetails, unit } = reservation;
    return {
      propertyName: unit.roomType.property.name || '',
      depositCollected: depositCollected || 0,
      guestName: (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
      hostEmail: unit.roomType.property.host.email || '-',
      hostContactNo: unit.roomType.property.host.contactNo || '-',
      hostData: unit.roomType.property.host || {}
    };
  };

  generateDepositWording = deposit => {
    const depositValuesAfterSplits = Number(deposit)
      .toFixed(2)
      .split('.');
    const combinedDepositWording = depositValuesAfterSplits.reduce((text, currentWord) => {
      if (!!!text) {
        return `${text} ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} RINGGIT`;
      } else if (Number(currentWord) > 0) {
        if (Number(currentWord) > 1) {
          return `${text} AND ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} CENTS`;
        } else {
          return `${text} AND ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} CENT`;
        }
      }
      return text;
    }, '');
    return `${combinedDepositWording} ONLY`;
  };

  render() {
    const { data, reservation, currency } = this.props;

    const refundData = {
      ...this.constructDataForRefundDepositPdf(reservation),
      ...data
    };

    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row'
      },
      image: {
        width: 80,
        height: 80
      },
      text: {
        color: '#212121',
        width: '30%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      flex: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 50,
        fontSize: 10,
        justifyContent: 'space-between'
      },
      row: {
        flexDirection: 'row'
      },
      paddingBtm: {
        paddingBottom: 5
      },
      body: {
        flexDirection: 'column',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        paddingTop: 20
      },
      move: {
        margin: '10px'
      },
      ReceiptNumtext: {
        textAlign: 'right',
        fontSize: 10,
        paddingTop: 25,
        paddingRight: 20
      },
      fields: {
        color: '#212121',
        width: '100%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 50
      },
      line: {
        borderBottomColor: '#112131',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        paddingTop: 20,
        paddingBottom: 20
      },
      issuedByHelper: {
        paddingLeft: 70
      },
      addressText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      title: {
        textAlign: 'center',
        fontSize: 20,
        paddingTop: 15,
        paddingLeft: 150
      },
      fieldsHostDetails: {
        fontSize: 10,
        width: '80%',
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        whiteSpace: 'normal'
      },
      groupFields: {
        width: '100%',
        flexDirection: 'row',
        paddingRight: 50,
        paddingLeft: 50,
        paddingBottom: 10,
        margin: 'auto',
        paddingTop: 20,
        alignItems: 'stretch'
      }
    });
    const address = refundData.hostData.useHostDetails
      ? refundData.hostData.street +
        (refundData.hostData.city ? ', ' + refundData.hostData.city : '') +
        (refundData.hostData.zipCode ? ', ' + refundData.hostData.zipCode : '') +
        (refundData.hostData.state ? ', ' + refundData.hostData.state : '') +
        (refundData.hostData.country ? ', ' + refundData.hostData.country : '')
      : refundData.street +
        (refundData.city ? ', ' + refundData.city : '') +
        (refundData.zipCode ? ', ' + refundData.zipCode : '') +
        (refundData.state ? ', ' + refundData.state : '') +
        (refundData.country ? ', ' + refundData.country : '');

    const MyDocument = (
      <Document>
        <Page size="A4" wrap>
          <View>
            <View style={styles.groupFields}>
              <View>
                <Image style={styles.image} src={refundData.image || ''} />
              </View>
              <View style={styles.fieldsHostDetails}>
                <Text style={{ ...styles.paddingBtm, fontSize: 12 }}>
                  {refundData.hostData.useHostDetails ? refundData.hostData.name : refundData.propertyName || '-'}
                </Text>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Address</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{address}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Email</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{refundData.hostEmail || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Contact Number</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{refundData.hostContactNo || '-'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%'
              }}
            >
              <View style={styles.row}>
                <View style={{ width: '70%' }}>
                  <Text style={styles.title}>Refund Deposit</Text>
                </View>
                <View style={{ width: '30%' }}>
                  <Text style={styles.ReceiptNumtext}>Receipt No : {refundData.confirmationCode || '-'}</Text>
                </View>
              </View>
            </View>
            <View style={styles.body}>
              <Text style={styles.fields}>RECEIVED FROM : {refundData.guestName || '-'}</Text>
            </View>
            {/* <View style={styles.line}>
              <Text style={styles.fields}>
                THE SUM OF RINGGIT : {refundData.depositCollected ? this.generateDepositWording(refundData.depositCollected) : '-'}{' '}
              </Text>
            </View> */}
            <View style={styles.line}>
              <Text style={styles.fields}>IN PAYMENT OF : REFUNDABLE DEPOSIT</Text>
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                paddingRight: 15,
                paddingLeft: 15,
                margin: 'auto'
              }}
            >
              <View
                style={{
                  color: '#212121',
                  width: '50%',
                  textAlign: 'left',
                  fontSize: 10,
                  paddingTop: 20,
                  paddingBottom: 5,
                  paddingRight: 35,
                  paddingLeft: 35
                }}
              >
                <Text style={styles.amount}>
                  {' '}
                  Amount : {currency ? currency : 'RM'} {numberWithCommas(refundData.depositCollected) || '-'}
                </Text>
              </View>
              <View
                style={{
                  color: '#212121',
                  width: '50%',
                  textAlign: 'right',
                  fontSize: 10,
                  paddingTop: 100,
                  paddingBottom: 5
                }}
              >
                <Text stlye={{ paddingRight: '100%' }}>ISSUED BY : ________________________</Text>
                <Text style={{ paddingRight: 20 }}>(Name/Sign/Stamp)</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    const generateRefundableDeposit = () => {
      const doc = new jsPDF();

      // Presets
      doc.setFont('NotoSansSC-Regular');
      doc.setFontSize(10);
      const imgWidth = 30;
      const imgHeight = 30;

      // Add black border for image
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.rect(15, 7, imgWidth, imgHeight);
      doc.addImage(refundData.image || '', 'JPEG', 15, 7, imgWidth, imgHeight);

      // Page - 1 content
      const headerText = [
        { text: refundData.hostData.useHostDetails ? refundData.hostData.name : refundData.propertyName || '-', x: 50, y: 10 },
        { text: 'Address', x: 50, y: 17 },
        { text: `: ${address}`, x: 80, y: 17 },
        { text: 'Email', x: 50, y: 23 },
        { text: `: ${refundData.hostEmail || '-'}`, x: 80, y: 23 },
        { text: 'Contact Number', x: 50, y: 29 },
        { text: `: ${refundData.hostContactNo || '-'}`, x: 80, y: 29 }
      ];

      const refundDeposit = [{ text: 'Refund Deposit', x: 70, y: 50 }];
      const receiptNo = [{ text: 'Receipt No', x: 160, y: 52 }, { text: `: ${refundData.confirmationCode || '-'}`, x: 180, y: 52 }];
      const receivedFrom = [
        { text: 'RECEIVED FROM', x: 15, y: 70 },
        { text: `: ${refundData.guestName || '-'}`, x: 50, y: 70 },
        { type: 'line', x1: 0, y1: 80, x2: 220, y2: 80 }
      ];
      const inPaymentOf = [
        { text: 'IN PAYMENT OF', x: 15, y: 100 },
        { text: `: REFUNDABLE DEPOSIT`, x: 50, y: 100 },
        { type: 'line', x1: 0, y1: 120, x2: 220, y2: 120 }
      ];
      const amount = [
        { text: 'Amount', x: 15, y: 130 },
        { text: `: ${currency ? currency : 'RM'} ${numberWithCommas(refundData.depositCollected) || '-'}`, x: 50, y: 130 }
      ];

      //padding + 7

      const signature = [
        { text: 'ISSUED BY: ', x: 120, y: 160 },
        { type: 'line', x1: 140, y1: 160, x2: 190, y2: 160 },
        { text: '(Name/Sign/Stamp)', x: 150, y: 165 }
      ];

      // Render each section of the card
      const renderText = (textArray, doc) => {
        textArray.forEach(item => {
          if (item.type === 'line') {
            doc.line(item.x1, item.y1, item.x2, item.y2);
          } else {
            doc.text(item.text, item.x, item.y);
          }
        });
      };

      renderText(headerText, doc);
      doc.setFontSize(20);
      renderText(refundDeposit, doc);
      doc.setFontSize(10);
      renderText(receiptNo, doc);
      renderText(signature, doc);
      renderText(receivedFrom, doc);
      renderText(inPaymentOf, doc);
      renderText(amount, doc);

      // Generate a Blob from the PDF
      const pdfBlob = doc.output('blob');
      // Create a URL for the Blob
      const pdfURL = URL.createObjectURL(pdfBlob);
      // Open the PDF in a new tab
      window.open(pdfURL);
    };

    return (
      <LangContextConsumer>
        {value => {
          return value.currentLocale === 'en-US' ? (
            <BlobProvider document={MyDocument} fileName="Refund_deposit.pdf">
              {({ blob, url, loading, error }) => (
                <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
                  {loading ? intl.get('listings.headerLabels.loading').d('Loading') : intl.get('reservations.csvModal.refund').d('Refund Deposit')}
                </Button>
              )}
            </BlobProvider>
          ) : (
            <div>
              <Button type="primary" className="modal-receipts-btn" onClick={() => generateRefundableDeposit()}>
                {intl.get('reservations.csvModal.refund').d('Refund Deposit')}
              </Button>
            </div>
          );
        }}
      </LangContextConsumer>
    );
  }
}

{
  /* <BlobProvider document={MyDocument} fileName="Refund_deposit.pdf">
        {({ blob, url, loading, error }) => (
          <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
            {loading ? 'Loading' : intl.get('reservations.csvModal.refund').d('Refund Deposit')}
          </Button>
        )}
      </BlobProvider> */
}
