import React from 'react';
import { Tabs, Skeleton } from 'antd';

import Card from 'components/Card/Card';
import CloseButton from 'components/CloseButton/CloseButton';
import { getUnitDetails } from 'utils/apis/unit';
import { getRelatedRoomTypeByUnitId } from 'utils/apis/bookingEngine';
import { buildListingUri } from 'utils/routes';

import RoomType from './RoomType/RoomType';
import Property from './Property/Property';
import Unit from './Unit/Unit';
import BookingEngineConfiguration from './BookingEngineConfiguration/BookingEngineConfiguration';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;

class ListingDetails extends React.Component {
  constructor() {
    super();

    this.state = {
      hasFetchedUnit: false,
      hasFetchedRoomTypes: false,
      unit: {},
      roomType: {},
      allowBookingEngine: false,
      allowHotelBookingEngine: false
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const unitId = match.params.id;
    this.fetchUnit(unitId);
    this.fetchRoomType(unitId);
  }

  fetchUnit = id => {
    getUnitDetails(id)
      .then(res => {
        this.setUnitState(res);
      })
      .catch(err => console.log(err));
  };

  fetchRoomType = id => {
    getRelatedRoomTypeByUnitId(id)
      .then(res => {
        this.setRoomTypeState(res);
      })
      .catch(err => console.log(err));
  };

  setUnitState = unit => {
    const allowBookingEngine = unit.roomType.property.host.allowBookingEngine;
    const allowHotelBookingEngine = unit.roomType.property.host.allowHotelBookingEngine;
    this.setState({
      hasFetchedUnit: true,
      unit,
      allowBookingEngine: allowBookingEngine,
      allowHotelBookingEngine: allowHotelBookingEngine
    });
  };

  setRoomTypeState = roomType => {
    this.setState({
      hasFetchedRoomTypes: true,
      roomType
    });
  };

  getPropertyData = () => {
    const { hasFetchedUnit, unit } = this.state;
    if (hasFetchedUnit) {
      return unit && unit.roomType && unit.roomType.property ? unit.roomType.property : {};
    }
    return {};
  };

  getRoomTypeData = () => {
    const { hasFetchedUnit, unit } = this.state;
    if (hasFetchedUnit && unit && unit.roomType) {
      return {
        ...unit.roomType,
        property: undefined,
        rate: unit.rate,
        currency: unit.currency,
        timezone: unit.timezone
      };
    }
    return {};
  };

  getBookingEngineConfigurationData = () => {
    const { hasFetchedRoomTypes, roomType } = this.state;
    if (hasFetchedRoomTypes && roomType) {
      return roomType && roomType.property ? roomType.property : {};
    }
    return {};
  };

  render() {
    const { hasFetchedUnit, unit, hasFetchedRoomTypes, roomType, allowBookingEngine, allowHotelBookingEngine } = this.state;
    return (
      <Card loading={!hasFetchedUnit}>
        <CloseButton
          onClick={() => {
            this.props.history.push(buildListingUri());
          }}
        />
        <Tabs defaultActiveKey="1">
          <TabPane tab={intl.get('listings.headerLabels.unit').d('Unit')} key="1">
            {!hasFetchedUnit ? (
              <Skeleton active />
            ) : (
              <Unit data={unit} hasFetchedUnit={hasFetchedUnit} allowBookingEngine={allowBookingEngine} currency={unit.currency} />
            )}
          </TabPane>

          <TabPane tab={intl.get('listings.headerLabels.roomType').d('Room Type')} key="2">
            {!hasFetchedUnit ? (
              <Skeleton active />
            ) : (
              <RoomType data={this.getRoomTypeData()} hasFetchedUnit={hasFetchedUnit} allowBookingEngine={allowBookingEngine} unitData={unit} />
            )}
          </TabPane>
          <TabPane tab={intl.get('listings.headerLabels.property').d('Property')} key="3">
            {!hasFetchedUnit ? (
              <Skeleton active />
            ) : (
              <Property data={this.getPropertyData()} hasFetchedUnit={hasFetchedUnit} allowBookingEngine={allowBookingEngine} unitData={unit} />
            )}
          </TabPane>
          {allowHotelBookingEngine && allowHotelBookingEngine === true && (
            <TabPane tab={intl.get('host.headerLabels.bookingEngineConfiguration').d('Booking Website Configuration')} key="4">
              {!hasFetchedUnit ? (
                <Skeleton active />
              ) : (
                <BookingEngineConfiguration data={roomType} hasFetchedRoomTypes={hasFetchedRoomTypes} currency={unit.currency} />
              )}
            </TabPane>
          )}
        </Tabs>
      </Card>
    );
  }
}

export default ListingDetails;
