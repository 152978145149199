import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import { withRouter } from 'react-router-dom';
import { Avatar, DatePicker, Modal, Tooltip, message } from 'antd';
import moment from 'moment';

import { guard, numberWithCommas } from 'utils/general';
import MCModalReservationSummary from '../MCModalReservationSummary/MCModalReservationSummary';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import agodaHomesLogo from 'images/agodahomes-logo-icon.png';
import bookingcomLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo-icon.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import homeAwayLogo from 'images/homeaway-logo-icon.png';
import staytionLogo from 'images/staytion-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import othersLogo from 'images/others-logo-icon.png';
import hostPlatformLogo from 'images/hostplatform-small-logo.png';
import staySuitesLogo from 'images/staysuites-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';
import BELogo from 'images/HBE-BE-logo.png';
import extIntegrationLogo from 'images/extIntegration-logo-icon.png';
import MapleHomeLogo from 'images/maplehome-logo-icon.png';

import styles from './MCReservation.module.css';
import { createReservation, deleteReservation } from 'utils/apis/reservation';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

moment.locale('es');

const DATE_FORMAT = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

const draggedReservationSource = {
  beginDrag(props) {
    const { propertyId, unitId, reservation, duration } = props;

    const draggedReservation = {
      sourcePropertyId: propertyId,
      sourceUnitId: unitId,
      reservation,
      duration
    };

    return draggedReservation;
  }
};

const draggedReservationCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem()
  };
};

const getReservationThumbnail = (reservation, showPrice, currency = 'RM') => {
  const { guestDetails, remarks, reservationPrice, bookingType } = reservation;
  const guestDetailsString =
    guestDetails && guestDetails.name + ', ' + guestDetails.numberOfPax + intl.get('multicalendar.headerLabels.guest').d('guest(s)');
  const processedRemark = remarks && `: ${remarks}`;
  switch (bookingType) {
    case 4:
      return intl.get('multicalendar.headerLabels.freeStay').d('Free Stay') + processedRemark;
    case 5:
      return intl.get('multicalendar.headerLabels.maintanence').d('Maintenance') + processedRemark;
    case 6:
      return intl.get('multicalendar.headerLabels.blocked').d('Blocked') + processedRemark;
    default:
      return showPrice ? guestDetailsString + `${currency} ` + numberWithCommas(reservationPrice) : guestDetailsString;
  }
};

const getBackgroundColorStyle = (reservationsColorsConstants, bookingType, bookingStatus, isModal) => {
  const reservationColor = guard(
    () =>
      reservationsColorsConstants.filter(
        reservationColor =>
          (!!bookingType && reservationColor.bookingType === bookingType) || (!!bookingStatus && reservationColor.bookingStatus === bookingStatus)
      ),
    reservationsColorsConstants.filter(color => color.hasNoBookingStatus)
  );
  const isBlockReservation = [5, 6].includes(bookingType);
  // console.log(test);
  // const reservationColor = guard(
  //   () =>
  //     reservationsColorsConstants.find(
  //       reservationColor =>
  //         (!!bookingType && reservationColor.bookingType === bookingType) || (!!bookingStatus && reservationColor.bookingStatus === bookingStatus)
  //     ),
  //   reservationsColorsConstants.find(color => color.hasNoBookingStatus)
  // );
  // const backgroundColor = isModal ? reservationColor.colorModal || reservationColor.color : reservationColor.color;
  const backgroundColor = isModal
    ? reservationColor[isBlockReservation ? 0 : reservationColor.length - 1].colorModal ||
      reservationColor[isBlockReservation ? 0 : reservationColor.length - 1].color
    : reservationColor[isBlockReservation ? 0 : reservationColor.length - 1].color;

  return backgroundColor;
};

const getBookingAvatar = (reservation, isNonProfitBooking) => {
  const { bookingType, platform } = reservation;

  let avatarColor = '';
  let icon = '';
  let src = '';

  if (isNonProfitBooking) {
    avatarColor = styles.avatarColorNonProfit;
    icon =
      bookingType === 4
        ? 'red-envelope' // bookingType "4"
        : bookingType === 5
        ? 'tool' // bookingType "5"
        : 'lock'; // bookingType "6"
  } else {
    avatarColor = styles.avatarColor;
    if (platform) {
      switch (platform) {
        case 'airbnb':
          src = airbnbLogo;
          break;
        case 'agoda':
          src = agodaLogo;
          break;
        case 'agodahomes':
          src = agodaHomesLogo;
          break;
        case 'ctrip':
          src = ctripLogo;
          break;
        case 'expedia':
          src = expediaLogo;
          break;
        case 'bookingcom':
          src = bookingcomLogo;
          break;
        case 'homeaway':
          src = homeAwayLogo;
          break;
        case 'staytion':
          src = staytionLogo;
          break;
        case 'traveloka':
          src = travelokaLogo;
          break;
        case 'staysuites':
          src = staySuitesLogo;
          break;
        case 'ctripcm':
          src = ctripLogo;
          break;
        case 'tiketcom':
          src = tiketcomLogo;
          break;
        case 'extIntegration':
          if (window.location.href.includes('host=5ca17d46124e180f8e0628e7')) {
            src = MapleHomeLogo;
          } else {
            src = extIntegrationLogo;
          }

          break;
        case 'others':
          src = othersLogo;
          break;
        case 'bookingEngine':
          src = BELogo;
          break;
        case 'hotelBookingEngine':
          src = BELogo;
          break;
        default:
          src = hostPlatformLogo;
      }
    } else {
      src = hostPlatformLogo;
    }
  }

  const avatar = <Avatar className={`${styles.avatar} ${avatarColor}`} icon={icon} src={src} />;

  return avatar;
};

const getWidth = (startDate, endDate, month) => {
  const firstDayOfTheMonth = moment(month)
    .startOf('month')
    .format(DATE_FORMAT);

  const firstDayOfNextMonth = moment(month)
    .endOf('month')
    .add(1, 'days')
    .format(DATE_FORMAT);

  let duration = 0;
  if (startDate < firstDayOfTheMonth && endDate > firstDayOfNextMonth) {
    const daysInMonth = moment(month).daysInMonth();
    duration = daysInMonth;
  } else if (startDate > firstDayOfTheMonth && endDate <= firstDayOfNextMonth) {
    duration = moment(endDate).diff(moment(startDate), 'days');
  } else if (endDate > firstDayOfNextMonth) {
    duration = moment(firstDayOfNextMonth).diff(startDate, 'days');
  } else if (endDate <= firstDayOfNextMonth) {
    duration = moment(endDate).diff(moment(firstDayOfTheMonth), 'days');
  }

  return duration;
};

const getBorderRadius = (startDate, endDate, month) => {
  const firstDayOfTheMonth = moment(month)
    .startOf('month')
    .format(DATE_FORMAT);
  const lastDayOfTheMonth = moment(month)
    .endOf('month')
    .format(DATE_FORMAT);
  const formattedEndDate = moment(endDate)
    .subtract(1, 'days')
    .format(DATE_FORMAT);
  // This month reservation
  if (startDate >= firstDayOfTheMonth && formattedEndDate <= lastDayOfTheMonth) {
    return '18px';
  } else if (startDate >= firstDayOfTheMonth && formattedEndDate >= lastDayOfTheMonth) {
    return '18px 0px 0px 18px';
  } else if (startDate <= firstDayOfTheMonth && formattedEndDate <= lastDayOfTheMonth) {
    return '0px 18px 18px 0px';
  } else {
    return '0';
  }
};

const disabledDate = (currentDate, startDate, endDate) => {
  const isDisable =
    currentDate <=
      moment(startDate, DATE_FORMAT)
        .subtract(1, 'day')
        .endOf('day') || currentDate >= moment(endDate, DATE_FORMAT).startOf('day');

  return isDisable;
};

class MCReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalReservationOnClick: false,
      showUnblockModal: false,
      blockDateRange: []
    };
  }

  handleOnClickReservation = () => {
    this.setState({ showModalReservationOnClick: true });
  };

  handleOnClickCancelReservationSummary = () => {
    this.setState({ showModalReservationOnClick: false });
  };

  handleOnClickUnblockShow = () => {
    const { reservation } = this.props;
    this.setState({
      showUnblockModal: true,
      blockDateRange: [moment(reservation.startDate, DATE_FORMAT), moment(reservation.endDate, DATE_FORMAT).subtract(1, 'day')]
    });
  };

  handleOnClickCancelUnblock = () => {
    this.setState({ showUnblockModal: false, blockDateRange: [] });
  };

  handleOnUnblock = e => {
    e.preventDefault();
    const { reservation, roomTypeId, unitId, handleOnMCRateDateActionSuccess } = this.props;
    const { blockDateRange } = this.state;
    const reservationId = reservation._id;
    const reservationRemarks = reservation.remarks;
    const bookingType = reservation.bookingType;
    const oriReservationBlockStartDate = reservation.startDate; //YYYY-MM-DD
    const oriReservationBlockEndDate = reservation.endDate; //YYYY-MM-DD
    let newBlockDateArr = [];

    if (
      moment(blockDateRange[0])
        .startOf('day')
        .isSame(moment(oriReservationBlockStartDate).startOf('day')) &&
      moment(blockDateRange[1])
        .add(1, 'day')
        .startOf('day')
        .isSame(moment(oriReservationBlockEndDate).startOf('day'))
    ) {
      // console.log('start and end are same');
    } else if (
      moment(blockDateRange[0])
        .startOf('day')
        .isSame(moment(oriReservationBlockStartDate).startOf('day'))
    ) {
      // console.log('start same');
      newBlockDateArr.push([
        moment(blockDateRange[1])
          .add(1, 'day')
          .format(DATE_FORMAT),
        moment(oriReservationBlockEndDate).format(DATE_FORMAT)
      ]);
    } else if (
      moment(blockDateRange[0])
        .startOf('day')
        .isAfter(moment(oriReservationBlockStartDate).startOf('day'))
    ) {
      newBlockDateArr.push([moment(oriReservationBlockStartDate).format(DATE_FORMAT), moment(blockDateRange[0]).format(DATE_FORMAT)]);
      if (
        moment(blockDateRange[1])
          .startOf('day')
          .isBefore(
            moment(oriReservationBlockEndDate)
              .subtract(1, 'day')
              .startOf('day')
          )
      ) {
        newBlockDateArr.push([
          moment(blockDateRange[1])
            .add(1, 'day')
            .format(DATE_FORMAT),
          moment(oriReservationBlockEndDate).format(DATE_FORMAT)
        ]);
      }
    }

    Modal.confirm({
      title: intl
        .get('multicalendar.message.unblockBlockingForm', {
          startDate: moment(blockDateRange[0]).format(DATE_FORMAT),
          endDate: moment(blockDateRange[1]).format(DATE_FORMAT)
        })
        .d(
          `Are you sure you want to unblock the blocking from ${moment(blockDateRange[0]).format(DATE_FORMAT)} to ${moment(blockDateRange[1]).format(
            DATE_FORMAT
          )}?`
        ),
      onOk: () => {
        deleteReservation(reservationId).then(res => {
          if (res.status === 204) {
            this.setState({ showUnblockModal: false });
            if (newBlockDateArr.length > 0) {
              Promise.all(
                newBlockDateArr.map(dates => {
                  // console.log(dates);
                  createReservation({
                    startDate: dates[0],
                    endDate: dates[1],
                    bookingType,
                    unit: unitId,
                    remarks: reservationRemarks
                  });
                })
              ).then(res => {
                handleOnMCRateDateActionSuccess({
                  currentRoomTypeId: roomTypeId,
                  selectedDate: blockDateRange[0]
                });
              });
            } else {
              handleOnMCRateDateActionSuccess({
                currentRoomTypeId: roomTypeId,
                selectedDate: blockDateRange[0]
              });
            }
          } else if (res.status === 403) {
            message.warning(intl.get('multicalendar.message.cannotBlock').d('Cannot unblock due to dependency.'));
          } else {
            message.error(intl.get('multicalendar.message.unblockFail').d('Unblock failed, please contact our support.'));
          }
        });
      }
    });
  };

  authorizedItem = (keys, isAdmin, permissions) => {
    if (isAdmin) {
      return true;
    }

    return keys.filter(key => permissions.includes(key)).length > 0 ? true : false;
  };

  render() {
    // params from react-dnd
    const { connectDragSource } = this.props;

    const { showModalReservationOnClick, showUnblockModal, blockDateRange } = this.state;
    const {
      reservation,
      unitName,
      roomTypeId,

      integrationSourceOptions,
      bookingTypeOptions,
      bookingStatusOptions,
      month,
      moveDownPx,

      reservationsColorsConstants,

      checkIsNonProfitBooking,
      checkIsBlockReservation,
      handleOnMCRateDateActionSuccess,
      permissions,
      permissionConstants,
      isAdmin,
      checkIsAdminReadOnly
    } = this.props;

    // react-dnd props
    const { isDragging } = this.props;

    const { startDate, endDate, bookingType, bookingStatus } = reservation;

    const WIDTH = 100 * getWidth(startDate, endDate, month);

    const borderRadius = getBorderRadius(startDate, endDate, month);
    const backgroundColor = getBackgroundColorStyle(reservationsColorsConstants, bookingType, bookingStatus, false);
    const modalBackgroundColor = getBackgroundColorStyle(reservationsColorsConstants, bookingType, bookingStatus, true);

    const isNonProfitBooking = checkIsNonProfitBooking(reservationsColorsConstants, bookingType);
    const isBlockReservation = checkIsBlockReservation(reservationsColorsConstants, bookingType);

    const reservationThumbnail = getReservationThumbnail(
      reservation,
      this.authorizedItem([permissionConstants.TRANSACTION_VIEW], isAdmin, permissions),
      this.props.currency
    );
    const reservationThumbnailTooltip = !isDragging && reservationThumbnail;
    const bookingAvatar = getBookingAvatar(reservation, isNonProfitBooking);
    moment.locale('de');

    return connectDragSource(
      <div>
        <div>
          <MCModalReservationSummary
            reservation={reservation}
            isNonProfitBooking={isNonProfitBooking}
            isBlockReservation={isBlockReservation}
            roomTypeId={roomTypeId}
            unitName={unitName}
            integrationSourceOptions={integrationSourceOptions}
            bookingTypeOptions={bookingTypeOptions}
            bookingStatusOptions={bookingStatusOptions}
            backgroundColor={modalBackgroundColor}
            showModalReservationOnClick={showModalReservationOnClick}
            onClickCancelReservationSummary={this.handleOnClickCancelReservationSummary}
            handleOnMCRateDateActionSuccess={handleOnMCRateDateActionSuccess}
            showUnblockModal={showUnblockModal}
            onClickUnblockButton={this.handleOnClickUnblockShow}
            permissions={permissions}
            permissionConstants={permissionConstants}
            isAdmin={isAdmin}
            currency={this.props.currency}
            checkIsAdminReadOnly={checkIsAdminReadOnly}
          />
        </div>

        <Tooltip title={reservationThumbnailTooltip} placement="topLeft">
          <div
            style={{ top: moveDownPx, width: `${WIDTH}px`, borderRadius: `${borderRadius}` }}
            className={`${styles.reservationBoxes} ${backgroundColor} ${styles.reservationThumbnail}`}
            onClick={this.handleOnClickReservation}
          >
            {bookingAvatar}
            <span className={styles.reservationThumbnailText}>{reservationThumbnail}</span>
          </div>
        </Tooltip>

        <Modal
          title={intl
            .get('multicalendar.message.unblock', {
              unitName: unitName,
              startDate: moment(reservation.startDate).format('Do MMM'),
              endDate: moment(reservation.endDate).format('Do MMM')
            })
            .d(`Unblock {unitName} for {startDate} - {endDate}`)}
          visible={showUnblockModal}
          onCancel={this.handleOnClickCancelUnblock}
          onOk={this.handleOnUnblock}
        >
          <div className={styles.blockRow}>
            <label className={styles.blockLabel}>{intl.get('multicalendar.headerLabels.dateToUnblock').d('Free Stay')}</label>
            <RangePicker
              value={blockDateRange}
              disabledDate={current => disabledDate(current, reservation.startDate, reservation.endDate)}
              onChange={(date, dateString) => {
                this.setState({ blockDateRange: date });
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default DragSource('draggedReservation', draggedReservationSource, draggedReservationCollect)(withAppContext(withRouter(MCReservation)));
