import React from 'react';
import { Card, Alert, Button, Col, Row, List } from 'antd';
import PropTypes from 'prop-types';
import styles from './RelationshipCard.module.css';
import FormSelection from 'components/FormSelection/FormSelection';
import { buildListingDetailsUri } from 'utils/routes';
import intl from 'react-intl-universal';

const ListItem = List.Item;

const modeConst = {
  NORELATION: 'solo',
  PARENT: 'isParent',
  CHILD: 'isChild'
};

class RelationshipCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: '',
      edited: ''
    };
  }

  componentWillMount() {
    let { relationship } = this.props;
    if (!relationship || relationship.type === 'solo') {
      this.setState({ mode: modeConst.NORELATION });
    } else if (relationship.type === 'parent') {
      this.setState({ mode: modeConst.PARENT });
    } else {
      this.setState({ mode: modeConst.CHILD });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.relationship !== this.props.relationship) {
      let { relationship } = this.props;
      if (!relationship || relationship.type === 'solo') {
        this.setState({ mode: modeConst.NORELATION });
      } else if (relationship.type === 'parent') {
        this.setState({ mode: modeConst.PARENT });
      } else {
        this.setState({ mode: modeConst.CHILD });
      }
    }
  }

  render() {
    const { form, relationship, potentialParents, potentialChild } = this.props;
    const { mode } = this.state;
    let parentId = form.getFieldsValue().parent || (relationship && relationship.parent ? relationship.parent._id : null);
    let parent = null;
    let children = relationship ? relationship.children || [] : [];
    if (parentId) {
      parent = potentialParents.filter(pp => pp._id.toString() === parentId.toString())[0];
      if (!parent) {
        // console.log('Error! Parent not in potentail parent list. Do check!');
      }
    }

    let childrenSelection = (children || []).concat(potentialChild || []);
    return (
      <Card title={intl.get('host.headerLabels.relationStatus').d('Relationship Status')} className={styles.relationshipCard}>
        <Alert
          className={styles.notes}
          message={intl.get('host.headerLabels.tips').d('Tips')}
          description={
            <div>
              <p className={styles.notesText}>
                {intl.get('listings.unit.headerLabels.parentChild').d("Setup your units' parent-child relationship here!")}
              </p>
              <p className={styles.notesText}>
                {intl
                  .get('listings.unit.headerLabels.parentChild2')
                  .d(
                    "Blocking a parent's calendar by either reservation or for maintenance will automatically block all it's children. Likewise, blocking a children will block it's parent, but leave the other children open for booking."
                  )}
              </p>
            </div>
          }
          type="info"
          showIcon
        />
        {mode === modeConst.NORELATION ? (
          <Card>
            <Row>
              <p>{intl.get('listings.unit.headerLabels.parentChildQuestion').d('Seems like your unit have no parent/child relationship.')}</p>
              <p>{intl.get('listings.unit.headerLabels.parentChildWouldAdd').d('Would you like to add one?')}</p>
              <Col>
                <Button
                  className={styles.selector}
                  size="large"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      mode: modeConst.CHILD
                    });
                  }}
                >
                  {intl.get('listings.unit.headerLabels.addParentUnit').d('Add to a Parent Unit?')}
                </Button>
              </Col>
              <Col>
                <Button
                  className={styles.selector}
                  size="large"
                  type="secondary"
                  onClick={() => {
                    this.setState({
                      mode: modeConst.PARENT
                    });
                  }}
                >
                  {intl.get('listings.unit.headerLabels.assignChildrenUnit').d('Assign Children Units')}
                </Button>
              </Col>
            </Row>
          </Card>
        ) : mode === modeConst.CHILD ? (
          <Card>
            <FormSelection
              name="parent"
              requiredErrorMessage={intl.get('listings.unit.placeholder.parentMsg').d('Please choose a parent unit')}
              label={intl.get('listings.unit.headerLabels.parent').d('Parent Unit')}
              placeholder={intl.get('listings.unit.placeholder.parent').d('Choose a parent')}
              form={form}
              defaultValue={relationship && relationship.parent ? relationship.parent._id : undefined}
              // shouldDisable={!hasFetchedPropertyTypes}
              selections={potentialParents.map(pp => {
                return {
                  key: pp._id,
                  value:
                    pp.name +
                    ' (' +
                    (pp.ownedBy && pp.ownedBy.userProfile
                      ? pp.ownedBy.userProfile.firstName + ' ' + pp.ownedBy.userProfile.lastName
                      : pp.username || intl.get('listings.unit.headerLabels.ownerNotSet').d('Owner Not Set')) +
                    ')'
                };
              })}
              size="large"
            />
            {parent && parent.children && parent.children.length > 0 ? (
              <React.Fragment>
                <p>
                  {intl.get('listings.unit.headerLabels.childrenIn').d('Children in ')}
                  <a target="_blank" rel="noopener noreferrer" href={buildListingDetailsUri(parent._id)}>
                    {intl.get('listings.unit.headerLabels.parentLabel').d('Parent')}
                  </a>
                </p>
                <List>
                  {parent.children.map(child => {
                    let isSelf = child._id.toString() === relationship.unit._id.toString();
                    return (
                      <ListItem
                        key={child._id}
                        actions={
                          isSelf
                            ? [
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      mode: modeConst.NORELATION
                                    });
                                    form.setFieldsValue({
                                      property: undefined
                                    });
                                  }}
                                  type="danger"
                                >
                                  {intl.get('listings.unit.headerLabels.remove').d('Remove')}
                                </Button>
                              ]
                            : []
                        }
                      >
                        {!isSelf ? (
                          <a target="_blank" rel="noopener noreferrer" href={buildListingDetailsUri(child._id)}>
                            {child.name +
                              (child.roomType ? ' - ' + child.roomType.name : '') +
                              (child.ownedBy && child.ownedBy.userProfile
                                ? ' - ' + (child.ownedBy.userProfile.firstName || '') + ' ' + (child.ownedBy.userProfile.lastName || '')
                                : '')}
                          </a>
                        ) : (
                          child.name +
                          (child.roomType ? ' - ' + child.roomType.name : '') +
                          (child.ownedBy && child.ownedBy.userProfile
                            ? ' - ' + (child.ownedBy.userProfile.firstName || '') + ' ' + (child.ownedBy.userProfile.lastName || '')
                            : '')
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </React.Fragment>
            ) : (
              <p>{intl.get('listings.unit.headerLabels.selectedMsg').d('Selected parent have no children')}</p>
            )}
            {!relationship.parent ? (
              <React.Fragment>
                <br />
                <Button
                  size="large"
                  onClick={() => {
                    this.setState({
                      mode: modeConst.NORELATION
                    });
                    form.setFieldsValue({
                      property: undefined
                    });
                  }}
                >
                  {intl.get('listings.unit.headerLabels.forgetIt').d('You know what? Forget about it.')}
                </Button>
              </React.Fragment>
            ) : (
              ''
            )}
          </Card>
        ) : mode === modeConst.PARENT ? (
          <Card>
            <FormSelection
              name="children"
              requiredErrorMessage={intl
                .get('listings.unit.placeholder.childMsg')
                .d('Please choose at least one child. If this unit is no longer a Parent, please press Remove All Children')}
              label={intl.get('listings.unit.headerLabels.child').d('Child Units')}
              placeholder={intl.get('listings.unit.placeholder.child').d('Choose related child')}
              form={form}
              multipleMode
              defaultValue={children.map(c => c._id)}
              // defaultValue={defaultValues.type}
              // shouldDisable={!hasFetchedPropertyTypes}
              selections={
                childrenSelection
                  ? childrenSelection.map(pc => {
                      return {
                        key: pc._id,
                        value: (
                          <div target="_blank" rel="noopener noreferrer" href={buildListingDetailsUri(pc._id)}>
                            {pc.name +
                              ' (' +
                              (pc.ownedBy && pc.ownedBy.userProfile
                                ? pc.ownedBy.userProfile.firstName + ' ' + pc.ownedBy.userProfile.lastName
                                : pc.username || intl.get('listings.unit.headerLabels.ownerNotSet').d('Owner Not Set')) +
                              ')'}
                          </div>
                        )
                      };
                    })
                  : []
              }
              size="large"
            />
            <br />
            <Button
              size="large"
              onClick={() => {
                this.setState({
                  mode: modeConst.NORELATION
                });
                form.setFieldsValue({
                  children: []
                });
              }}
            >
              {intl.get('listings.unit.headerLabels.removeChildren').d('Remove All Children')}
            </Button>
          </Card>
        ) : (
          ''
        )}
      </Card>
    );
  }
}

RelationshipCard.propTypes = {
  form: PropTypes.object.isRequired,
  relationship: PropTypes.object
};

RelationshipCard.defaultProps = {
  defaultValues: {}
};

export default RelationshipCard;
