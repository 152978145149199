import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col } from 'antd';
import intl from 'react-intl-universal';

import FormInput from 'components/FormInput/FormInput';

import { checkValidStrongPassword } from 'utils/general';

const FormPassword = ({ form, isSideBySide, shouldHideLabel, isResetPassword, passwordClassName }) => {
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const passwordString = isResetPassword
    ? intl.get('users.headerLabels.newPassword').d('new password')
    : intl.get('users.headerLabels.password').d('password');

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setIsPasswordChange(isPasswordChange || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback(intl.get('users.message.passwordError1').d('Both password are not the same'));
    }
    callback();
  };

  const validateToNextPassword = (rule, value, callback) => {
    const isValidPassword = checkValidStrongPassword(value);
    if (!isValidPassword) {
      return callback(intl.get('users.message.passwordError2').d('Password should be 12-30 alphanumeric with at least 1 lowercase and 1 uppercase'));
    } else if (value && isPasswordChange) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  return (
    <>
      <Row type="flex" style={{ width: '100%' }}>
        <Col span={24} className={passwordClassName}>
          <FormInput
            form={form}
            formLabel={!shouldHideLabel ? `${intl.get('users.headerLabels.your').d('Your')}${passwordString}` : undefined}
            requiredErrorMessage={`${intl.get('users.headerLabels.your').d('Your')}${passwordString} ${intl
              .get('users.headerLabels.isRequire')
              .d('is require')}`}
            inputType="password"
            name="password"
            placeholder={`${intl.get('users.headerLabels.type').d('Type your')}${passwordString}${intl.get('users.headerLabels.here').d('here')}`}
            extraProps={{ prefix: <Icon type="lock" /> }}
            extraRules={[{ validator: validateToNextPassword }]}
          />
        </Col>
        <Col span={24} className={passwordClassName}>
          <FormInput
            form={form}
            formLabel={!shouldHideLabel ? `${intl.get('users.headerLabels.confirm').d('Confirm your')}${passwordString}` : undefined}
            requiredErrorMessage={`${intl.get('users.headerLabels.confirm2').d('Please confirm your')}${passwordString}`}
            inputType="password"
            name="confirmPassword"
            placeholder={`${intl.get('users.headerLabels.retype').d('Retype your')}${passwordString}${intl.get('users.headerLabels.here').d('here')}`}
            extraProps={{ prefix: <Icon type="lock" />, onBlur: handleConfirmBlur }}
            extraRules={[{ validator: compareToFirstPassword }]}
          />
        </Col>
      </Row>
    </>
  );
};

FormPassword.propTypes = {
  form: PropTypes.object.isRequired,
  isSideBySide: PropTypes.bool,
  shouldHideLabel: PropTypes.bool
};

FormPassword.defaultProps = {
  isSideBySide: false,
  shouldHideLabel: false
};

export default FormPassword;
