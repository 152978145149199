import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Select, Tooltip } from 'antd';

import { withAppContext } from 'context/AppContext';
import { buildTaskManagementUri } from 'utils/routes';

import CloseButton from 'components/CloseButton/CloseButton';

import './PresetTaskForm.css';

import { getProperties } from 'utils/apis/property';
import { getUnits } from 'utils/apis/unit';
import {
  getCleaningTeamByPropertyId,
  getChecklistByPropertyId,
  createPresetTask,
  getPTById,
  deletePresetTaskById,
  updatePresetTask
} from 'utils/apis/taskManagement';
import { getBookingStatusConstant, getConstants } from 'utils/apis/constants';
import intl from 'react-intl-universal';

const FormItem = Form.Item;
const { Option } = Select;

const MODE_NEW = 'new';
const MODE_EDIT = 'edit';

class PresetTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      mode: MODE_NEW,
      fullUnitOptions: [],
      property: '',
      propertyOptions: [],
      roomType: [],
      roomTypeOptions: [],
      units: [],
      unitOptions: [],
      cleaningTeam: '',
      cleaningTeamOptions: [],
      checklist: '',
      checklistOptions: [],
      maxNoOfCleaner: 1,
      triggerType: '',
      triggerTypeOptions: [],
      triggerStatusOptions: [],
      triggerStatus: ''
    };
  }

  componentDidMount() {
    const { match } = this.props;
    getConstants('taskTriggerType').then(res => {
      if (res && res.status === 200) {
        let triggerType = Object.keys(res.data).map(i => {
          return res.data[i];
        });
        if (triggerType.length > 0) {
          this.setState({
            triggerTypeOptions: triggerType.map(i => {
              return {
                value: i.code,
                label: i.label
              };
            })
          });
        }
      } else {
        console.log('Error while retrieving trigger types');
      }
    });

    getBookingStatusConstant().then(res => {
      let triggerStatusOptions = [];
      if (res) {
        triggerStatusOptions = Object.values(res)
          .filter(status => status.showInMCFilter)
          .map(status => {
            return {
              label: status.label,
              value: status.code
            };
          });
      }
      this.setState({ triggerStatusOptions });
    });
    getProperties()
      .then(properties => {
        let propertyOptions = [];
        if (properties.length > 0) {
          propertyOptions = properties.map(property => {
            return { value: property._id, label: property.name };
          });
          this.setState({ propertyOptions, isLoading: false });
        }
      })
      .catch(err => {
        message.error(err);
        this.setState({ isLoading: false });
        return [{ label: '', value: '' }];
      });

    if (match.params.id) {
      this.getPresetTaskById(match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, match } = this.props;
    if (prevState.property !== this.state.property && this.state.property !== '') {
      getUnits(this.state.property, { fields: ['_id', 'name', 'roomType'] })
        .then(units => {
          // console.log(77, units);
          let unitOptions = [];
          let fullUnitOptions = [];
          let roomTypeOptions = [];

          if (units.length > 0) {
            fullUnitOptions = units
              .sort((a, b) => a.name - b.name)
              .map(u => {
                return { value: u._id, label: `${u.name} (${u.roomType.name})`, roomType: u.roomType._id };
              });
            roomTypeOptions = units
              .filter((unit, i, arr) => {
                return (
                  unit.roomType && unit.roomType._id && arr.map(unit => unit.roomType._id.toString()).indexOf(unit.roomType._id.toString()) === i
                );
              })
              .map(k => {
                return { value: k.roomType._id, label: k.roomType.name };
              });

            if (match.params.id) {
              for (let i = 0; i < this.state.roomType.length; i++) {
                if (!roomTypeOptions.some(roomType => roomType.value === this.state.roomType[i])) {
                  form.setFieldsValue({
                    roomTypes: []
                  });
                  this.setState({ roomType: [] });
                  break;
                } else {
                  form.setFieldsValue({
                    roomTypes: this.state.roomType
                  });
                }
              }
            } else {
              form.setFieldsValue({
                roomType: []
              });
              this.setState({ roomType: [] });
            }
          } else {
            form.setFieldsValue({
              roomType: [],
              units: []
            });
            this.setState({ roomType: [], units: [] });
          }
          this.setState({ fullUnitOptions, roomTypeOptions });
        })
        .catch(err => {
          message.error(err);
          return [{ label: '', value: '' }];
        });

      getCleaningTeamByPropertyId(this.state.property)
        .then(cleaningTeams => {
          let cleaningTeamOptions = [];
          if (cleaningTeams.data.length > 0) {
            cleaningTeamOptions = cleaningTeams.data.map(cleaningTeam => {
              return { value: cleaningTeam._id, label: cleaningTeam.name, numberOfCleaner: cleaningTeam.users.length };
            });
            if (match.params.id) {
              if (!cleaningTeamOptions.some(ct => ct.value === this.state.cleaningTeam)) {
                form.setFieldsValue({
                  cleaningTeam: undefined
                });
              } else {
                form.setFieldsValue({
                  cleaningTeam: this.state.cleaningTeam
                });
              }
            } else {
              form.setFieldsValue({
                cleaningTeam: undefined
              });
              this.setState({ cleaningTeam: '' });
            }
          } else {
            form.setFieldsValue({
              cleaningTeam: undefined
            });
            this.setState({ cleaningTeam: '' });
          }
          this.setState({ cleaningTeamOptions });
        })
        .catch(err => {
          message.error(err);
          return [{ label: '', value: '', numberOfCleaner: 0 }];
        });

      getChecklistByPropertyId(this.state.property)
        .then(checklists => {
          let checklistOptions = [];
          if (checklists.data.length > 0) {
            checklistOptions = checklists.data.map(checklist => {
              return { value: checklist._id, label: checklist.name };
            });
            if (match.params.id) {
              if (!checklistOptions.some(cl => cl.value === this.state.checklist)) {
                form.setFieldsValue({
                  checklist: []
                });
              } else {
                form.setFieldsValue({
                  checklist: this.state.checklist
                });
              }
            } else {
              form.setFieldsValue({
                checklist: undefined
              });
              this.setState({ checklist: '' });
            }
          } else {
            form.setFieldsValue({
              checklist: undefined
            });
            this.setState({ checklist: '' });
          }
          this.setState({ checklistOptions });
        })
        .catch(err => {
          message.error(err);
          return [{ label: '', value: '' }];
        });
    }

    // if (prevState.roomType !== this.state.roomType && this.state.fullUnitOptions.length > 0) {
    if (
      JSON.stringify(prevState.roomType) !== JSON.stringify(this.state.roomType) ||
      JSON.stringify(prevState.roomTypeOptions) !== JSON.stringify(this.state.roomTypeOptions) /*  && this.state.fullUnitOptions.length > 0 */
    ) {
      let unitOptions = [];
      let units = [];

      this.state.roomType.map(roomTypeId => {
        const foundUnits = this.state.fullUnitOptions.filter(u => u.roomType === roomTypeId);

        unitOptions.push(...foundUnits);
      });
      if (unitOptions.length > 0) {
        units = unitOptions.map(u => u.value);
      }

      form.setFieldsValue({
        units: units
      });
      this.setState({ units, unitOptions });
    }

    if (
      prevState.cleaningTeam !== this.state.cleaningTeam ||
      JSON.stringify(prevState.cleaningTeamOptions) !== JSON.stringify(this.state.cleaningTeamOptions)
    ) {
      let maxNoOfCleaner = 1;
      if (this.state.cleaningTeam !== '' && this.state.cleaningTeamOptions.length > 0) {
        maxNoOfCleaner = this.state.cleaningTeamOptions.find(team => team.value === this.state.cleaningTeam);
        if (maxNoOfCleaner) {
          maxNoOfCleaner = maxNoOfCleaner.numberOfCleaner;
        } else {
          maxNoOfCleaner = 0;
        }
      }

      this.setState({ maxNoOfCleaner });
      form.setFieldsValue({ noOfCleaner: maxNoOfCleaner });
    }
  }

  getPresetTaskById = async id => {
    try {
      let mode = MODE_EDIT;
      const res = await getPTById(id);
      console.log(290, res);
      this.setState({
        isLoading: true
      });
      if (res && res.data && res.status === 200) {
        setTimeout(() => {
          this.props.form.setFields({
            taskName: {
              value: res.data.name
            },
            property: {
              value: res.data.property
            },
            roomType: {
              value: res.data.roomTypes
            },
            units: { value: res.data.units },
            cleaningTeam: { value: res.data.cleaningTeam },
            noOfCleaner: { value: res.data.noOfCleaner },
            triggerType: { value: res.data.triggerType },
            checklist: { value: res.data.checklist },
            remark: { value: res.data.remarks }
          });
          setTimeout(() => {
            if (res.data.triggerStatus) {
              this.props.form.setFields({
                triggerStatus: {
                  value: res.data.triggerStatus
                }
              });
            }
          }, 500);
          this.setState({
            isLoading: false
          });
        }, 1500);
        if (res.data.triggerStatus) {
          this.props.form.setFieldsValue({ triggerStatus: res.data.triggerStatus });
        }
        this.setState({
          mode,
          property: res.data.property,
          roomType: res.data.roomTypes,
          units: res.data.units,
          cleaningTeam: res.data.cleaningTeam,
          triggerType: res.data.triggerType,
          triggerStatus: res.data.triggerStatus ? res.data.triggerStatus : '',
          checklist: res.data.checklist,
          isLoading: false
        });
      }
    } catch (ex) {
      message.error(intl.get('taskManagement.preset.message.errorRetrieve').d('Error retrieving preset task'));
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;
    const { mode } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        if (mode === MODE_NEW) {
          this.setState({ isSaveButtonLoading: true });
          createPresetTask(values).then(res => {
            if (res.status === 200) {
              history.push(`${buildTaskManagementUri()}/preset-task`);
              message.success(
                `${intl.get('taskManagement.preset.message.preset').d('Preset Task')} (${res.data.name}) ${intl
                  .get('taskManagement.preset.message.successCreate')
                  .d('success created')}`
              );
            }
          });
        } else if (mode === MODE_EDIT) {
          const presetTaskId = match.params.id;

          const handleOnOk = async () => {
            try {
              this.setState({ isSaveButtonLoading: true });
              const res = await updatePresetTask(presetTaskId, values);
              if (res && res.status === 204) {
                history.push(`${buildTaskManagementUri()}/preset-task`);
                message.success(intl.get('taskManagement.preset.message.updated').d('Preset task updated!'));
                this.setState({ isSaveButtonLoading: false });
              }
            } catch (ex) {
              message.error(ex);
              this.setState({ isSaveButtonLoading: false });
            }
          };

          Modal.confirm({
            title: intl.get('taskManagement.preset.message.confirmTitle').d('Are you sure want to overwrite existing data?'),
            content: intl
              .get('taskManagement.preset.message.confirmContent')
              .d('You will not be able to undo this action, but you may update it again.'),
            onOk: handleOnOk,
            onCancel() {},
            okButtonProps: { id: 'save-cfm-button-editat' },
            cancelButtonProps: { id: 'cancelsave-cfm-button-editat' }
          });
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const presetTaskId = match.params.id;

    const handleOnOk = async () => {
      try {
        this.setState({ isDeleteButtonLoading: true });
        const res = await deletePresetTaskById(presetTaskId);

        if (res.status === 204) {
          this.setState({ isDeleteButtonLoading: false });
          history.push(`${buildTaskManagementUri()}/preset-task`);
          message.success(intl.get('taskManagement.preset.message.delete').d('Preset Task Deleted'));
        } else {
          message.error(intl.get('taskManagement.preset.message.deleteError').d('Something went wrong and preset task is not deleted'));
          this.setState({ isDeleteButtonLoading: false });
        }
      } catch (ex) {
        message.error(ex || intl.get('taskManagement.preset.message.deleteError').d('Something went wrong and preset task is not deleted'));
        this.setState({ isDeleteButtonLoading: false });
      }
    };

    Modal.confirm({
      title: intl.get('taskManagement.preset.message.deleteTitle').d('Are you sure want to delete this preset task?'),
      content: intl.get('taskManagement.preset.message.deleteContent').d('This action cannot be reversed. Once deleted, it cannot be recovered'),
      onOk: handleOnOk,
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button-editat' },
      cancelButtonProps: { id: 'cancel-cfm-button-editat' }
    });
  };

  validateMaxNoOfCleaner = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value > this.state.maxNoOfCleaner) {
      callback(intl.get('taskManagement.preset.message.exceedCleaner').d('Exceed maximum number of cleaner in the team'));
    } else {
      callback();
    }
  };

  cleanerIncrement = () => {
    const { form } = this.props;
    const { maxNoOfCleaner } = this.state;
    if (form.getFieldValue('noOfCleaner') < maxNoOfCleaner) {
      form.setFieldsValue({ noOfCleaner: form.getFieldValue('noOfCleaner') + 1 });
    }
  };

  cleanerDecrease = () => {
    const { form } = this.props;
    if (form.getFieldValue('noOfCleaner') > 1) {
      form.setFieldsValue({
        noOfCleaner: form.getFieldValue('noOfCleaner') - 1
      });
    }
  };

  render() {
    const { form, history, checkIsAllowCreateTask, checkIsAllowDeleteTask, checkIsAllowEditTask, checkIsAdminReadOnly } = this.props;
    const { getFieldDecorator } = form;
    const {
      mode,
      isLoading,
      propertyOptions,
      roomTypeOptions,
      unitOptions,
      cleaningTeam,
      cleaningTeamOptions,
      checklistOptions,
      triggerTypeOptions,
      triggerStatusOptions,
      isSaveButtonLoading,
      isDeleteButtonLoading
    } = this.state;

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Card
          className="presettask-form-card"
          title={
            this.state.mode === MODE_NEW
              ? intl.get('taskManagement.preset.headerLabels.createPreset').d('Create Preset Task')
              : intl.get('taskManagement.preset.headerLabels.editPreset').d('Edit Preset Task')
          }
          loading={isLoading}
        >
          <CloseButton
            onClick={() => {
              history.push(`${buildTaskManagementUri()}/preset-task`);
            }}
          />
          <Row type="flex" justify="start" gutter={36}>
            <FormItem label={intl.get('taskManagement.preset.headerLabels.taskName').d('Task Name')} className="presettask-form-list">
              {getFieldDecorator('taskName', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.taskNameMsg').d('Please enter task name')
                  }
                ]
              })(<Input placeholder={intl.get('reservations.reservationDetails.placeholder.taskName').d('Enter task name')} />)}
            </FormItem>
            <FormItem label={intl.get('taskManagement.preset.headerLabels.property').d('Property')} className="presettask-form-list-half">
              {getFieldDecorator('property', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.propertyMsg').d('Please select a property')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.property').d('Select a property')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ property: e })}
                  disabled={propertyOptions.length === 0}
                >
                  {propertyOptions.map(propertyOption => {
                    return (
                      <Option key={propertyOption.value} value={propertyOption.value}>
                        {propertyOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.preset.headerLabels.roomType').d('Room Type')} className="presettask-form-list-half">
              {getFieldDecorator('roomTypes', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.atLeastRoomTypeMsg').d('Please select at least one room type')
                  }
                ]
              })(
                <Select
                  mode="multiple"
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.atLeastRoomType').d('Select at least one room type')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ roomType: e })}
                  disabled={roomTypeOptions.length === 0}
                >
                  {roomTypeOptions.map(roomTypeOption => {
                    return (
                      <Option key={roomTypeOption.value} value={roomTypeOption.value}>
                        {roomTypeOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.preset.headerLabels.unit').d('Unit')} className="presettask-form-list-half">
              {getFieldDecorator('units', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.atLeastUnitMsg').d('Select at least one room type')
                  }
                ]
              })(
                <Select
                  mode="multiple"
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.atLeastUnit').d('Select at least one unit')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ units: e })}
                  disabled={unitOptions.length === 0}
                >
                  {unitOptions.map(unitOption => {
                    return (
                      <Option key={unitOption.value} value={unitOption.value}>
                        {unitOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={
                <Tooltip
                  title={
                    <span>
                      <ol style={{ paddingLeft: 16 }}>
                        <li>
                          {intl
                            .get('taskManagement.preset.headerLabels.checkOutDate')
                            .d('By Check-out Date - Task will be created on next day 12:00AM after check-out date')}
                        </li>
                        <li>
                          {intl
                            .get('taskManagement.preset.headerLabels.statusChange')
                            .d('By Status Change - Task will be created upon status change to selected status')}
                        </li>
                      </ol>
                    </span>
                  }
                  placement="topLeft"
                >
                  <span>
                    {intl.get('taskManagement.preset.headerLabels.triggerType').d('Trigger Type')} <Icon type="info-circle" />
                  </span>
                </Tooltip>
              }
              className="presettask-form-list-half"
            >
              {getFieldDecorator('triggerType', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.triggerTypeMsg').d('Please select type of trigger')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.triggerType').d('Select type of trigger')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ triggerType: e })}
                  disabled={triggerTypeOptions.length === 0}
                >
                  {triggerTypeOptions.map(triggerType => {
                    return (
                      <Option key={triggerType.value} value={triggerType.value}>
                        {intl.get(`taskManagement.preset.triggerType.${triggerType.label}`).d('Trigger Type')}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.get('taskManagement.preset.headerLabels.cleaningTeam').d('Cleaning Team')}
              className="presettask-form-list-half"
              help={
                this.state.property && cleaningTeamOptions.length === 0 ? (
                  <span>
                    {intl.get('taskManagement.preset.headerLabels.noCleaningTeam').d('No cleaning team created, click ')}
                    <a target="_blank" href="/task-management/cleaning-team">
                      {intl.get('taskManagement.newTaskForm.headerLabels.link').d('link')}
                    </a>{' '}
                    {intl.get('taskManagement.newTaskForm.headerLabels.toCreate').d('to create')}
                  </span>
                ) : (
                  ''
                )
              }
            >
              {getFieldDecorator('cleaningTeam', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.cleaningTeamMsg').d('Please select a cleaning team')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.cleaningTeam').d('Select a cleaning team')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ cleaningTeam: e })}
                  disabled={cleaningTeamOptions.length === 0}
                >
                  {cleaningTeamOptions.map(cleaningTeam => {
                    return (
                      <Option key={cleaningTeam.value} value={cleaningTeam.value}>
                        {cleaningTeam.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>

            <FormItem
              label={intl.get('taskManagement.preset.headerLabels.cleanerNum').d('Number of Cleaner')}
              style={{ padding: '0px 18px' }}
              className="inputaddon presettask-form-list-quarter"
            >
              {getFieldDecorator('noOfCleaner', {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.cleanerNumMsg').d('At least one cleaner is require')
                  },
                  { validator: this.validateMaxNoOfCleaner }
                ]
              })(
                <Input
                  type="number"
                  addonBefore={<Icon type="minus" onClick={() => (cleaningTeam ? this.cleanerDecrease() : {})} />}
                  addonAfter={<Icon type="plus" style={{ color: '#fff' }} onClick={() => (cleaningTeam ? this.cleanerIncrement() : {})} />}
                  style={{ width: '100%', textAlign: 'center' }}
                  disabled={!cleaningTeam}
                />
              )}
            </FormItem>
            {form.getFieldValue('triggerType') === 1 && (
              <FormItem
                label={intl.get('taskManagement.preset.headerLabels.triggerStatus').d('Trigger Status')}
                className="presettask-form-list-quarter"
              >
                {getFieldDecorator('triggerStatus', {
                  rules: [
                    {
                      required: true,
                      message: intl.get('taskManagement.preset.headerLabels.triggerStatusMsg').d('Please select trigger status')
                    }
                  ]
                })(
                  <Select
                    placeholder={intl.get('taskManagement.preset.headerLabels.triggerStatus').d('Select a trigger status')}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={e => this.setState({ triggerStatus: e })}
                    disabled={triggerStatusOptions.length === 0}
                  >
                    {triggerStatusOptions.map(status => {
                      return (
                        <Option key={status.value} value={status.value}>
                          {status.label}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem
              label={intl.get('taskManagement.preset.headerLabels.checklistApplied').d('Checklist Applied')}
              className="presettask-form-list"
              help={
                this.state.property && checklistOptions.length === 0 ? (
                  <span>
                    {intl.get('taskManagement.newTaskForm.headerLabels.noChecklist').d('No checklist created, click ')}
                    <a target="_blank" href="/task-management/checklist">
                      {intl.get('taskManagement.newTaskForm.headerLabels.link').d('link')}
                    </a>{' '}
                    {intl.get('taskManagement.newTaskForm.headerLabels.toCreate').d('to create')}
                  </span>
                ) : (
                  ''
                )
              }
            >
              {getFieldDecorator('checklist', {
                rules: [
                  {
                    required: true,
                    message: intl.get('taskManagement.preset.headerLabels.checklistApplyMsg').d('Please select a checklist to apply')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.checklistApply').d('Select a checklist to apply')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ checklist: e })}
                  disabled={checklistOptions.length === 0}
                >
                  {checklistOptions.map(checklist => {
                    return (
                      <Option key={checklist.value} value={checklist.value}>
                        {checklist.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label={intl.get('taskManagement.preset.headerLabels.remark').d('Remark')} className="presettask-form-list">
              {getFieldDecorator('remark')(
                <Input.TextArea
                  placeholder={intl.get('reservations.reservationDetails.placeholder.enterRemark').d('Enter your remark here ...')}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              )}
            </FormItem>
          </Row>
        </Card>
        <div className="save-and-delete-buttons">
          <FormItem className="ct-button-wrapper">
            {mode === 'new' ? (
              checkIsAllowCreateTask() && (
                <Button id="create-button-presettask" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                  {intl.get('taskManagement.preset.headerLabels.createForm').d('Create')}
                </Button>
              )
            ) : (
              <React.Fragment>
                {checkIsAllowEditTask() && (
                  <Button
                    id="save-button-edit-presettask"
                    type="primary"
                    htmlType="submit"
                    loading={isSaveButtonLoading}
                    disabled={checkIsAdminReadOnly()}
                  >
                    {intl.get('taskManagement.preset.headerLabels.save').d('Save')}
                  </Button>
                )}
                {checkIsAllowDeleteTask() && (
                  <Button
                    id="del-button-edit-presettask"
                    type="danger"
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                    onClick={this.handleDelete}
                    disabled={checkIsAdminReadOnly()}
                  >
                    {intl.get('taskManagement.preset.headerLabels.delete').d('Delete')}
                  </Button>
                )}
              </React.Fragment>
            )}
          </FormItem>
        </div>
      </Form>
    );
  }
}

export default withRouter(withAppContext(Form.create()(PresetTaskForm)));
