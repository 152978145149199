import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { Button, Table, Form, Select, Modal, message } from 'antd';

import { formatToDateString, getCurrentMoment } from 'utils/date';

import { getUsers, updateUser, updateOwner } from 'utils/apis/user';
import { getHostsAutomated } from 'utils/apis/host';
import { getPropertiesByHostId } from 'utils/apis/property';
import { guard, uniqueArray, errorHandlerWrapper } from 'utils/general';
import { buildUserCreateUri, buildUserEditUri } from 'utils/routes';
import { constructColumnFilterCheckbox, constructColumnFilterSearch, constructColumn } from 'utils/table/table';
import intl from 'react-intl-universal';

import { StyledCard, StyledHeader } from './Users.styles';
import { withAppContext } from 'context/AppContext';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const useUsers = () => {
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [users, setUsers] = useState([]);
  const [hostOptions, setHosts] = useState([]);

  const fetchUsers = useCallback(async () => {
    setIsFetchingUsers(true);
    const usersData = await getUsers().then(res => res.data);
    const hostOptions = await errorHandlerWrapper(getHostsAutomated().then(res => res.data.map(host => ({ label: host.name, value: host._id }))), []);
    const users = usersData.map(user => {
      const userProfile = user.userProfile;
      const isAdmin = user.isAdmin;
      const userRole = guard(() => user.roles[0].name);

      const rolesType = isAdmin ? 'Admin' : userRole;

      const isFemale = userProfile.gender === 0;
      const isMale = userProfile.gender === 1;
      const genderString = isFemale ? 'Female' : isMale ? 'Male' : 'Unknown';

      return {
        ...user,
        userProfile: { ...userProfile, genderString },
        rolesType
      };
    });

    setUsers(users);
    setHosts(hostOptions);
    setIsFetchingUsers(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const roleTypesSelection = useMemo(() => {
    const userRoleTypes = users.map(user => user.rolesType).filter(roleType => !!roleType);
    const uniqueUserRoleTypes = uniqueArray(userRoleTypes);
    const sortedUniqueUserRoleTypes = uniqueUserRoleTypes.sort();

    return sortedUniqueUserRoleTypes.map(roleType => ({ label: roleType, value: roleType }));
  }, [users]);

  return { isLoadingUsers: isFetchingUsers, users, roleTypesSelection, hostOptions };
};

const constructColumns = roleTypesSelection => {
  return [
    {
      ...constructColumn(intl.get('users.tableColumns.username').d('Username'), 'username', {
        hasSorter: true,
        linkFunc: record => buildUserEditUri(record._id)
      }),
      ...constructColumnFilterSearch('username', intl.get('tables.username').d('Search Username'))
    },
    {
      ...constructColumn(intl.get('users.tableColumns.contact').d('Contact Number'), 'contactNos'),
      ...constructColumnFilterSearch('contactNos', intl.get('tables.contactNo').d('Search Contact Number'))
    },
    {
      ...constructColumn(intl.get('users.tableColumns.email').d('Email Address'), 'email', { hasSorter: true }),
      ...constructColumnFilterSearch('email', intl.get('tables.email').d('Search Email Address'))
    },
    {
      ...constructColumn(intl.get('users.tableColumns.role').d('Role Type'), 'rolesType', { hasSorter: true }),
      ...constructColumnFilterCheckbox('rolesType', roleTypesSelection),
      render: user => {
        return intl.get(`users.roleType.${user}`) === '' ? '-' : intl.get(`users.roleType.${user}`);
      }
    },
    {
      ...constructColumn(intl.get('users.tableColumns.host').d('Host'), 'hostName', { hasSorter: true }),
      ...constructColumnFilterSearch('hostName', intl.get('tables.hostName').d('Search Host'))
    }
  ];
};

const constructCSV = users => {
  return users.map(user => {
    const userProfile = user.userProfile;

    return {
      [intl.get('csv.username').d('Username')]: user.username,
      [intl.get('csv.email').d('Email')]: user.email,
      [intl.get('csv.firstName').d('First Name')]: userProfile.firstName,
      [intl.get('csv.lastName').d('Last Name')]: userProfile.lastName,
      [intl.get('csv.contact').d('Contact Number')]: user.contactNos,
      [intl.get('csv.gender').d('Gender')]: userProfile.genderString,
      [intl.get('csv.roleName').d("Role's Name")]: intl.get(`users.roleType.${user.rolesType}`).d(user.roleType),
      [intl.get('csv.host').d('Host')]: user.hostName
    };
  });
};

const Users = props => {
  const { isLoadingUsers, users, roleTypesSelection, hostOptions } = useUsers();

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);
  const csvName = useMemo(() => `users_${currentDate}.csv`, [currentDate]);
  const [isShowAssignPropertyModal, setIsShowAssignPropertyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHost, setHost] = useState('');
  const [propOptions, setProperties] = useState([]);
  const [assignedUserList, setAssignedUserList] = useState([]);
  const [unAssignedUserList, setUnassignedUserList] = useState([]);
  const [disableUser, toggleUser] = useState(true);

  const handleOnChangeHost = async host => {
    props.form.setFieldsValue({
      properties: [],
      users: []
    });

    const propertyOptions = await errorHandlerWrapper(
      getPropertiesByHostId(host).then(properties => properties.map(property => ({ label: property.name, value: property._id }))),
      []
    );

    toggleUser(true);
    setProperties(propertyOptions);
    setHost(host);
  };

  const handleOnChangeProperty = properties => {
    const usersByHost = users.filter(user => user.roles[0] && user.roles[0].host && user.roles[0].host._id === selectedHost);
    const unAssignedUsers = usersByHost.filter(user => user.roles.length > 0 && !user.roles[0].properties);
    const assignedUsers = usersByHost.filter(user => user.roles.length > 0 && !!user.roles[0].properties);
    const propertyUnassignedUsers = assignedUsers.filter(user => properties.some(prop => !user.roles[0].properties.includes(prop)));
    const finalUnassignedUsers = unAssignedUsers.concat(propertyUnassignedUsers);
    const finalAssignedUsers = assignedUsers.filter(user => !finalUnassignedUsers.includes(user));
    setAssignedUserList(finalAssignedUsers);
    setUnassignedUserList(finalUnassignedUsers);
    toggleUser(false);
    if (properties.length === 0) {
      toggleUser(true);
    }
  };

  const handleOnClickAssignProperty = () => {
    setIsShowAssignPropertyModal(true);
  };

  const handleOnModalAssignPropertyClose = () => {
    setIsShowAssignPropertyModal(false);
  };

  const handleOnFormSubmit = e => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);

        Promise.all([
          values.users.map(user => {
            return updateUser(user, {
              properties: values.properties,
              onlySupportSingleHost: true,
              onlyUpdateHostAndProperties: true
            })
              .then(res => {
                if (res && res.status === 204) {
                  Promise.all([
                    values.users.map(user => {
                      return updateOwner(user);
                    })
                  ]).then(res => {
                    setIsLoading(false);
                    message.success(intl.get('users.message.userUpdated').d('User Updated !'));
                    setTimeout(() => {
                      document.location.reload();
                    }, 1000);
                  });
                } else {
                  message.error(intl.get('users.message.updateError2').d('Something went wrong and user is not updated.'));
                  setIsLoading(false);
                }
              })
              .catch(ex => {
                message.error(ex);
                setIsLoading(false);
              })
              .catch(ex => {
                message.error(ex.toString());
              });
          })
        ]).catch(ex => {
          message.error(ex);
          this.setState({ isSaveButtonLoading: false });
        });
        setIsLoading(false);
      }
    });
  };

  return (
    <StyledCard>
      {isShowAssignPropertyModal && (
        <Modal
          title={`${intl.get('users.headerLabels.assignProperty').d('Assign Property')}`}
          style={{ minHeight: '80vh', minWidth: '200px', overflow: 'hidden' }}
          width="50vw"
          visible={isShowAssignPropertyModal}
          onCancel={handleOnModalAssignPropertyClose}
          footer={[
            <Button key="back" onClick={handleOnModalAssignPropertyClose}>
              {intl.get('users.headerLabels.cancel').d('Cancel')}
            </Button>,
            <Button key="submit" onClick={handleOnFormSubmit} type="primary" loading={isLoading}>
              {intl.get('users.headerLabels.save').d('Save')}
            </Button>
          ]}
        >
          <FormItem label={intl.get('users.headerLabels.host').d('Host Name')} className="users-form-host">
            {props.form.getFieldDecorator('host', {
              message: intl.get('reservations.reservationDetails.placeholder.hostMsg').d('Please select a host')
            })(
              <Select
                showSearch
                optionFilterProp="children"
                style={{ minWidth: '100%', overflow: 'hidden' }}
                placeholder={intl.get('reservations.reservationDetails.placeholder.host').d('Select a host')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={handleOnChangeHost}
              >
                {hostOptions.map(hostOption => {
                  return (
                    <Option key={hostOption.value} value={hostOption.value}>
                      {hostOption.label}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem label={intl.get('users.headerLabels.property').d('Property Name')} className="users-form-property">
            {props.form.getFieldDecorator('properties', {
              rules: [
                {
                  required: true,
                  message: intl.get('reservations.reservationDetails.placeholder.atLeastPropertyMsg').d('Please select at least one property')
                }
              ]
            })(
              <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                style={{ minWidth: '100%', overflow: 'hidden' }}
                placeholder={intl.get('reservations.reservationDetails.placeholder.propertyAssigned').d('Select the property to be assigned')}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={propOptions.length === 0}
                onChange={handleOnChangeProperty}
              >
                {propOptions.map(propertyOption => {
                  return (
                    <Option key={propertyOption.value} value={propertyOption.value}>
                      {propertyOption.label}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem label={intl.get('users.headerLabels.userApplied').d('Users Applied')} className="users-form-user">
            {props.form.getFieldDecorator('users', {
              rules: [
                {
                  required: true,
                  message: intl.get('reservations.reservationDetails.placeholder.userProperty').d('Select the users to apply properties')
                }
              ]
            })(
              <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                style={{ minWidth: '100%', overflow: 'hidden' }}
                placeholder={intl.get('reservations.reservationDetails.placeholder.userProperty').d('Select the users to apply properties')}
                // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={disableUser}
              >
                <OptGroup label={intl.get('users.headerLabels.unassigned').d('Unassigned Users')}>
                  {unAssignedUserList
                    .filter(user => !user.isAdmin && !(String(user._id) === String(props.user._id)))
                    .map(userOption => {
                      return (
                        <Option key={userOption._id} value={userOption._id}>
                          {userOption.username}
                        </Option>
                      );
                    })}
                </OptGroup>
                <OptGroup label={intl.get('users.headerLabels.assigned').d('Assigned Users')}>
                  {assignedUserList
                    .filter(user => !user.isAdmin && !(String(user._id) === String(props.user._id)))
                    .map(userOption => {
                      return (
                        <Option key={userOption._id} value={userOption._id} disabled>
                          {userOption.username}
                        </Option>
                      );
                    })}
                </OptGroup>
                {/* {users
                  .filter(user => !user.isAdmin && !(String(user._id) === String(props.user._id)))
                  .map(userOption => {
                    return (
                      <Option key={userOption._id} value={userOption._id}>
                        {userOption.username}
                      </Option>
                    );
                  })} */}
              </Select>
            )}
          </FormItem>
        </Modal>
      )}
      <StyledHeader>
        <Link to={buildUserCreateUri()}>
          <Button id="create-button5-users" type="primary" icon="plus" disabled={props.checkIsAdminReadOnly()}>
            {intl.get('users.headerLabels.create').d('Create User')}
          </Button>
        </Link>
        {props.checkIsAllowEditUser() && (
          <Button
            id="edit-button1-users"
            type="primary"
            style={{ marginLeft: 'auto', marginRight: '8px' }}
            onClick={handleOnClickAssignProperty}
            disabled={props.checkIsAdminReadOnly()}
          >
            {intl.get('users.headerLabels.propertyApply').d('Property Apply')}
          </Button>
        )}
        {props.checkAbleExportUser() && (
          <CSVLink filename={csvName} data={constructCSV(users)}>
            <Button id="csv-button4-users" type="primary" icon="download" disabled={props.checkIsAdminReadOnly()}>
              {intl.get('users.headerLabels.csv').d('Download CSV')}
            </Button>
          </CSVLink>
        )}
      </StyledHeader>
      <Table
        loading={isLoadingUsers}
        rowKey={record => record._id}
        columns={constructColumns(roleTypesSelection)}
        dataSource={users}
        scroll={{ x: 1000 }}
      />
    </StyledCard>
  );
};

export default withAppContext(Form.create()(Users));
