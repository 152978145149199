import React from 'react';
import { Card, Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import debounce from 'lodash.debounce';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import intl from 'react-intl-universal';

import { Country, State, City } from 'country-state-city';

const FIELD_NAME_ZIP_CODE = 'zipCode';
const FIELD_NAME_COUNTRY = 'countryCode';
const FIELD_NAME_CITY = 'city';
const FIELD_NAME_ADDRESS = 'street';
const FIELD_NAME_STATE = 'state';
const FIELD_NAME_LONGITUDE = 'longitude';
const FIELD_NAME_LATITUDE = 'latitude';

class LocationCard extends React.Component {
  constructor() {
    super();
    this.state = {
      hasCompletedForm: false
    };
  }

  // NOTE: Mainly for performance gain, if this component doesn't render as expected, try to comment out this function and test again
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.hasFetchedStates !== this.props.hasFetchedStates ||
      nextProps.hasFetchedCountries !== this.props.hasFetchedCountries ||
      nextProps.shouldUpdate
    );
  }

  getCountryValue = countryCode => {
    // const { countries } = this.props;
    // return countries.find(country => country.key === countryCode).value;

    return Country.getAllCountries()
      .map(country => {
        return {
          key: country.isoCode,
          label: country.name
        };
      })
      .find(country => country.key === countryCode).value;
  };

  getStateValue = stateCode => {
    const { states } = this.props;
    return states.find(state => state.key === stateCode).value;
  };

  handleOnMapSearch = addressInformation => {
    const { form } = this.props;

    // console.log(addressInformation, 51);

    const isMalaysia = addressInformation[FIELD_NAME_COUNTRY] === 'MY';
    const address = `${addressInformation[FIELD_NAME_ADDRESS]}, ${addressInformation[FIELD_NAME_CITY]}, ${addressInformation[FIELD_NAME_ZIP_CODE]} ${
      isMalaysia ? this.getStateValue(addressInformation[FIELD_NAME_STATE]) : addressInformation[FIELD_NAME_STATE]
    }, ${this.getCountryValue(addressInformation[FIELD_NAME_COUNTRY])}`;

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        return form.setFieldsValue({
          [FIELD_NAME_LATITUDE]: latLng.lat,
          [FIELD_NAME_LONGITUDE]: latLng.lng
        });
      })
      .catch(error => {
        form.setFieldsValue({
          [FIELD_NAME_LATITUDE]: undefined,
          [FIELD_NAME_LONGITUDE]: undefined
        });
        console.error(error);
        console.error('This address is not found in Google Map. Please use a different one');
      });
  };

  handleOnMapMarkerMove = newLocation => {
    const { form } = this.props;
    form.setFieldsValue({
      [FIELD_NAME_LATITUDE]: newLocation.lat,
      [FIELD_NAME_LONGITUDE]: newLocation.lng
    });
  };

  debounceHandleOnMapSearch = debounce(this.handleOnMapSearch, 500);

  handleOnFormChange = (fieldName, extraAction = () => {}) => event => {
    const { form } = this.props;
    // console.log('fieldName', fieldName);

    if (fieldName === FIELD_NAME_COUNTRY) {
      form.setFieldsValue({
        [FIELD_NAME_STATE]: undefined
      });
    }

    extraAction(event);

    const fieldValue = this.extractFieldValue(event);
    const allFieldsValue = form.getFieldsValue([FIELD_NAME_ADDRESS, FIELD_NAME_CITY, FIELD_NAME_ZIP_CODE, FIELD_NAME_STATE, FIELD_NAME_COUNTRY]);
    // Update the field value because onChange has not update the form value yet
    allFieldsValue[fieldName] = fieldValue;
    const hasCompletedForm = this.checkHasFormCompleted(allFieldsValue);
    if (this.state.hasCompletedForm !== hasCompletedForm) {
      this.setState({
        hasCompletedForm: hasCompletedForm
      });
    }

    if (hasCompletedForm /*  && form.getFieldValue('countryCode') === 'MY' */) {
      this.debounceHandleOnMapSearch(allFieldsValue);
    }
  };

  extractFieldValue = event => {
    let fieldValue = event;
    if (typeof fieldValue === 'object' && fieldValue.target) {
      fieldValue = fieldValue.target.value;
    }
    return fieldValue;
  };

  checkHasFormCompleted = allFieldsValue => {
    // Check all fields value and make sure they are set
    let hasCompletedForm = true;
    for (let i = 0; i < Object.keys(allFieldsValue).length; i++) {
      const key = Object.keys(allFieldsValue)[i];
      if (allFieldsValue[key]) {
        continue;
      } else {
        hasCompletedForm = false;
        break;
      }
    }
    return hasCompletedForm;
  };

  render() {
    const { form, cardClassname, hasFetchedStates, hasFetchedCountries, states, countries, defaultValues } = this.props;
    const formattedStates = states.map(state => ({
      key: state.key,
      value: state.value.replace(/\./g, '_')
    }));
    return (
      <Card title={intl.get('host.headerLabels.location').d('Location')} className={cardClassname}>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              form={form}
              name={FIELD_NAME_ADDRESS}
              label={intl.get('host.headerLabels.street').d('Street Address')}
              placeholder={intl.get('host.headerLabels.street').d('Street Address')}
              requiredErrorMessage={intl.get('host.placeholder.streetMsg').d('Please provide street address that your property locate')}
              defaultValue={defaultValues.address}
              onChange={this.handleOnFormChange(FIELD_NAME_ADDRESS)}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              name={FIELD_NAME_CITY}
              label={intl.get('host.headerLabels.city').d('City')}
              placeholder={intl.get('host.headerLabels.city').d('City')}
              requiredErrorMessage={intl.get('host.placeholder.cityMsg').d('Please provide city that your property locate')}
              form={form}
              defaultValue={defaultValues.city}
              onChange={this.handleOnFormChange(FIELD_NAME_CITY)}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              name={FIELD_NAME_ZIP_CODE}
              label={intl.get('host.headerLabels.zip').d('ZIP Code')}
              placeholder={intl.get('host.headerLabels.zip').d('ZIP Code')}
              requiredErrorMessage={intl.get('host.placeholder.zipMsg').d('Please provide the ZIP Code of your property')}
              form={form}
              defaultValue={defaultValues.zipcode}
              onChange={this.handleOnFormChange(FIELD_NAME_ZIP_CODE)}
              extraRules={[
                {
                  validator: (rule, value, callback) => {
                    console.log(rule, 196);
                    const country = form.getFieldValue(FIELD_NAME_COUNTRY);
                    console.log(country, 198);

                    switch (country) {
                      case 'MY':
                        const regex = /^([0-9]{5,6})$/;
                        if (!regex.test(value)) {
                          callback(intl.get('host.message.zipError4').d('ZIP Code has 5-6 numbers'));
                        }
                        callback();
                        break;

                      case 'AU':
                        const regexAU = /^([0-9]{4,5})$/;
                        if (!regexAU.test(value)) {
                          callback(intl.get('host.message.zipError5').d('ZIP Code has 4-5 numbers'));
                        }
                        callback();
                        break;

                      default:
                        const regexDefault = /^[0-9]{4-6}$/;
                        if (!regexDefault.test(value)) {
                          callback(intl.get('host.message.zipError6').d('ZIP Code must be numbers'));
                        }
                        callback();
                        break;
                    }
                  }
                }
              ]}
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            {form.getFieldValue(FIELD_NAME_COUNTRY) === 'MY' ? (
              <FormSelection
                name={FIELD_NAME_STATE}
                label={intl.get('listings.property.state').d('State')}
                requiredErrorMessage={intl.get('host.placeholder.stateMsg').d('Please select state that your property locate')}
                placeholder={intl.get('host.placeholder.state').d('Select a state')}
                form={form}
                defaultValue={defaultValues.state}
                shouldDisable={!hasFetchedStates}
                selections={formattedStates}
                onChange={this.handleOnFormChange(FIELD_NAME_STATE)}
                size="large"
              />
            ) : (
              <FormInput
                name={FIELD_NAME_STATE}
                label={<div style={{ marginBottom: 16 }}>{intl.get('host.headerLabels.state').d('State')}</div>}
                placeholder={intl.get('host.headerLabels.state').d('State')}
                requiredErrorMessage={intl.get('host.placeholder.stateMsg').d('Please select state that your property locate')}
                form={form}
                defaultValue={defaultValues.state}
                onChange={this.handleOnFormChange(FIELD_NAME_STATE)}
                size="large"
              />
            )}
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              name={FIELD_NAME_COUNTRY}
              label={intl.get('host.headerLabels.country').d('Country')}
              requiredErrorMessage={intl.get('host.placeholder.countryMsg').d('Please select country that your property locate')}
              placeholder={intl.get('host.placeholder.country').d('Select a country')}
              form={form}
              defaultValue={defaultValues.country}
              shouldDisable={!hasFetchedCountries}
              selections={Country.getAllCountries().map(country => {
                return {
                  key: country.isoCode,
                  label: intl.get(`countries.${country.name.replace(/\./g, '_')}`).d(country.name)
                };
              })}
              // selections={countries}
              onChange={this.handleOnFormChange(FIELD_NAME_COUNTRY)}
              size="large"
            />
          </Col>
          <Col span={24}>
            <div>
              {form.getFieldDecorator(FIELD_NAME_LONGITUDE, {
                rules: [
                  {
                    required: true
                  }
                ],
                initialValue: defaultValues.longitude
              })(<Input type="hidden" />)}

              {form.getFieldDecorator(FIELD_NAME_LATITUDE, {
                rules: [
                  {
                    required: true
                  }
                ],
                initialValue: defaultValues.latitude
              })(<Input type="hidden" />)}
              <h3>{intl.get('host.headerLabels.map').d('Move the pin to update your property location')}</h3>
              <GoogleMap
                lng={form.getFieldValue(FIELD_NAME_LONGITUDE)}
                lat={form.getFieldValue(FIELD_NAME_LATITUDE)}
                onMarkerMove={this.handleOnMapMarkerMove}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
              {(form.getFieldError(FIELD_NAME_LONGITUDE) || form.getFieldError(FIELD_NAME_LATITUDE)) && (
                <span>{intl.get('host.headerLabels.invalidMap').d('Your address is invalid, please check your address again.')}</span>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

LocationCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  hasFetchedStates: PropTypes.bool.isRequired,
  hasFetchedCountries: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  defaultValues: PropTypes.object,
  shouldUpdate: PropTypes.bool
};

LocationCard.defaultProps = {
  defaultValues: {},
  shouldUpdate: true
};

export default LocationCard;
