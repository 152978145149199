import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs } from 'antd';

import { SEPARATOR_DASH, STANDARD_RATE_CODE, RATE_DISTRIBUTION_TAB, RATE_DERIVATIVE, RATE_DERIVATIVES } from 'utils/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[3];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.rate._id === rateId || rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

class ExpediaAdvancedOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,

      roomsOfRates: []
    };
  }

  componentDidMount = async () => {
    const { mappedRoomTypes, expediaRateMapping, expediaRatePlans, rateModifierTypeConstants, rateModifierSignConstants } = this.props;

    this.setState({ isLoadingRateDistribution: true });

    const roomsOfRates = await this.getRoomsOfRates({
      mappedRoomTypes,
      expediaRateMapping,
      expediaRatePlans,
      rateModifierTypeConstants,
      rateModifierSignConstants
    });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ mappedRoomTypes, expediaRateMapping, expediaRatePlans, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      mappedRoomTypes.map(async roomType => {
        const roomName = roomType.roomName;
        const roomId = roomType.roomId;

        const rates = await getWebRateByRoomType(roomId);
        const webRate = rates.data.find(rate => rate.code === STANDARD_RATE_CODE);

        const roomRates = expediaRateMapping[roomId].map((rateObject, i) => {
          const otaId = String(rateObject.expRate) || '';
          const ratePlan = expediaRatePlans[roomId].find(
            ratePlan =>
              String(ratePlan.resourceId) === String(rateObject.expRate) ||
              String(ratePlan.resourceId) === String(rateObject.expRate).substring(0, String(rateObject.expRate).length - 1)
          );

          // FIX ME second condition is temporary for multiple rates under one rate plan, eg. 1234A

          let calculation = rateObject.rate.calculation;

          if (!rateObject.rate.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: webRate.weekday,
              weekend: webRate.weekend
            }
          };

          const rate = {
            _id: rateObject.rate._id,
            name: ratePlan && ratePlan.name,
            isDerived: rateObject.rate.isDerived,
            weekday: rateObject.rate.weekday,
            weekend: rateObject.rate.weekend,
            calculation
          };

          return { otaId, rate };
        });

        return {
          roomId,
          roomName,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();
        const rateDistributionPayload = getRateDistributionPayload(fieldValues);

        onConfirm(rateDistributionPayload);
      }
    });
  };

  render() {
    const { roomsOfRates } = this.state;
    const { form, isVisible, onClose, currency } = this.props;

    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab={intl.get('hostConnect.integration.headerLabels.rateDistribution').d('Rate Distribution')} key="rateDistribution">
            <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={currency} />
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

ExpediaAdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  expediaRateMapping: PropTypes.object.isRequired,
  mappedRoomTypes: PropTypes.array.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.string
};

ExpediaAdvancedOptionsModal.defaultProps = {
  isVisible: false,
  currency: 'RM',
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(ExpediaAdvancedOptionsModal);
