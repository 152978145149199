import React, { Component, Fragment } from 'react';
import { Col, Button, List } from 'antd';

import { BedRoomListItem, BedConfiguration } from 'components/Bedrooms/Bedrooms';

import styles from './FormBedrooms.module.css';
import intl from 'react-intl-universal';

const generateRoomName = index => `Room ${index + 1}`;
const mapBedTypesToConfiguration = (count, bedTypes) => {
  const newBedTypes = Object.keys(bedTypes).reduce((acc, key) => {
    return {
      ...acc,
      [key]: { ...bedTypes[key], count: 0 }
    };
  }, {});
  return {
    beds: { ...newBedTypes },
    name: generateRoomName(count),
    totalBedCount: 0
  };
};

const validateBedrooms = (rule, bedrooms, callback) => {
  for (let i = 0; i < bedrooms.length; i++) {
    if (bedrooms[i].totalBedCount === 0) {
      callback('Room must have at least 1 bed');
      return;
    }
  }
  callback();
};

class FormBedrooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoomIndex: 0
    };
  }

  handleOnAddBedroom = () => {
    const { value: bedrooms, onChange, bedTypes } = this.props;
    const bedroomConfiguration = mapBedTypesToConfiguration(bedrooms.length, bedTypes);
    onChange([...bedrooms, bedroomConfiguration]);
    this.setState({
      selectedRoomIndex: bedrooms.length
    });
  };

  handleOnBedroomRemove = indexToRemove => e => {
    e.preventDefault();
    const { value: bedrooms, onChange } = this.props;
    const newBedrooms = bedrooms
      .filter((config, index) => index !== indexToRemove)
      .map((bedroom, index) => {
        return {
          ...bedroom,
          name: generateRoomName(index)
        };
      });
    onChange(newBedrooms);
    this.setState(prevState => {
      return {
        selectedRoomIndex: this.calculateSelectedRoomIndexOnRemove(indexToRemove, prevState.selectedRoomIndex)
      };
    });
  };

  calculateSelectedRoomIndexOnRemove = (indexToRemove, selectedRoomIndex) => {
    if (indexToRemove === selectedRoomIndex) {
      return -1;
    } else if (selectedRoomIndex > indexToRemove) {
      return selectedRoomIndex - 1;
    }
    return selectedRoomIndex;
  };

  handleOnBedroomEdit = index => e => {
    e.preventDefault();
    this.setState({
      selectedRoomIndex: index
    });
  };

  generateBeds = () => {
    const { value: bedrooms } = this.props;
    const { selectedRoomIndex } = this.state;
    const selectedBedRooms = bedrooms[selectedRoomIndex].beds;
    return Object.keys(selectedBedRooms).map(key => {
      return {
        key,
        label: selectedBedRooms[key].label,
        count: selectedBedRooms[key].count
      };
    });
  };

  handleOnBedUpdate = (isIncrement = true) => key => e => {
    e.preventDefault();
    const { value: bedrooms, onChange } = this.props;
    const { selectedRoomIndex } = this.state;

    const newBedrooms = bedrooms;
    const selectedBedRoom = newBedrooms[selectedRoomIndex];
    const selectedBedTypeCount = selectedBedRoom.beds[key].count;

    let newBedroom;
    if (isIncrement) {
      newBedroom = {
        ...selectedBedRoom,
        beds: {
          ...selectedBedRoom.beds,
          [key]: {
            ...selectedBedRoom.beds[key],
            count: selectedBedTypeCount + 1
          }
        },
        totalBedCount: selectedBedRoom.totalBedCount + 1
      };
    } else {
      if (selectedBedTypeCount && selectedBedTypeCount > 0) {
        newBedroom = {
          ...selectedBedRoom,
          beds: {
            ...selectedBedRoom.beds,
            [key]: {
              ...selectedBedRoom.beds[key],
              count: selectedBedTypeCount - 1
            }
          },
          totalBedCount: selectedBedRoom.totalBedCount - 1
        };
      }
    }

    if (newBedroom) {
      newBedrooms[selectedRoomIndex] = newBedroom;
      onChange(newBedrooms);
    }
  };

  render() {
    const { value: bedrooms } = this.props;
    const { selectedRoomIndex } = this.state;

    return (
      <Fragment>
        <Col span={24} md={12}>
          <List className={styles.listArrangementContainer} itemLayout="horizontal" locale={{ emptyText: 'No bed room added' }}>
            {bedrooms &&
              bedrooms.length > 0 &&
              bedrooms.map((bedConfiguration, index) => {
                const { beds, name } = bedConfiguration;
                const description = Object.keys(beds).reduce((newDescription, bedKey) => {
                  const bed = beds[bedKey];
                  if (bed.count && bed.count > 0) {
                    return `${newDescription} ${bed.count} ${intl.get(`listings.roomType.bedType.${bed.label}`).d('bedType')},`;
                  }
                  return newDescription;
                }, '') || (
                  <span className={styles.bedRoomListError}>
                    {intl.get('listings.roomType.message.noBed').d('No bed added (At least 1 is required)')}
                  </span>
                );
                return (
                  <BedRoomListItem
                    key={name}
                    title={name}
                    description={description}
                    onEditClick={this.handleOnBedroomEdit(index)}
                    onRemoveClick={this.handleOnBedroomRemove(index)}
                    shouldDisableRemove={bedrooms.length === 1} // Disable if only left one room
                  />
                );
              })}
          </List>
          <div style={{ width: '100%' }}>
            <Button block type="primary" onClick={this.handleOnAddBedroom}>
              {intl.get('listings.roomType.headerLabels.addRoom').d('Add a new room')}
            </Button>
          </div>
        </Col>
        {bedrooms && bedrooms.length > 0 && selectedRoomIndex > -1 && (
          <Col span={24} md={12}>
            <div className={styles.listArrangementDetailsContainer}>
              <BedConfiguration
                roomName={bedrooms[selectedRoomIndex].name}
                beds={this.generateBeds()}
                onBedIncrement={this.handleOnBedUpdate(true)}
                onBedDecrement={this.handleOnBedUpdate(false)}
              />
            </div>
          </Col>
        )}
      </Fragment>
    );
  }
}

const WrappedFormBedrooms = ({ form, name, defaultValue, bedTypes }) => {
  return form.getFieldDecorator(name, {
    validateTrigger: false,
    rules: [{ required: true, validator: validateBedrooms }],
    initialValue: defaultValue || [mapBedTypesToConfiguration(0, bedTypes)]
  })(<FormBedrooms bedTypes={bedTypes} />);
};

export default WrappedFormBedrooms;
