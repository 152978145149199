import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Card, Button, Row } from 'antd';
import ExpensesUpdate from '../ExpensesUpdate/ExpensesUpdate';
import { numberWithCommas } from '../../utils/general';
import './ExpensesListing.css';
import intl from 'react-intl-universal';

export default class ExpensesListing extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onAfterUpdate: PropTypes.func
  };

  static defaultProps = {
    data: {},
    onAfterUpdate: () => {}
  };

  constructor() {
    super();

    this.state = {
      year: '',
      month: '',
      visible: false,
      data: {},
      electricity: 0,
      water: 0,
      internet: 0,
      cleaning: 0,
      laundry: 0,
      toiletry: 0,
      service: 0,
      checkIn: 0,
      checkOut: 0,
      others: 0,
      svcPacName: '',
      svcPacId: '',
      host: '',
      currency: '',
      timezone: ''
    };
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getTotalAmount = this.getTotalAmount.bind(this);
  }

  componentDidMount() {
    const { data, year, month } = this.props;
    this.setState({
      electricity: this.getTotalAmount('electricity', data.expenses),
      water: this.getTotalAmount('water', data.expenses),
      internet: this.getTotalAmount('internet', data.expenses),
      cleaning: this.getTotalAmount('cleaning', data.expenses),
      laundry: this.getTotalAmount('laundry', data.expenses),
      service: this.getTotalAmount('service', data.expenses),
      checkOut: this.getTotalAmount('checkOut', data.expenses),
      checkIn: this.getTotalAmount('checkIn', data.expenses),
      toiletry: this.getTotalAmount('toiletry', data.expenses),
      others: this.getTotalAmount('others', data.expenses),
      year,
      month,
      data,
      svcPacId: data._id,
      svcPacName: data.svcPacName,
      host: data.host.name,
      currency: data.currency,
      timezone: data.timezone
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = (updatedExpenses = null) => {
    const data = JSON.parse(JSON.stringify(this.state.data));
    if (updatedExpenses) {
      data.expenses = updatedExpenses;
    }
    this.setState({
      visible: false,
      data,
      electricity: this.getTotalAmount('electricity', updatedExpenses),
      water: this.getTotalAmount('water', updatedExpenses),
      internet: this.getTotalAmount('internet', updatedExpenses),
      cleaning: this.getTotalAmount('cleaning', updatedExpenses),
      laundry: this.getTotalAmount('laundry', updatedExpenses),
      toiletry: this.getTotalAmount('toiletry', updatedExpenses),
      service: this.getTotalAmount('service', updatedExpenses),
      checkIn: this.getTotalAmount('checkIn', updatedExpenses),
      checkOut: this.getTotalAmount('checkOut', updatedExpenses),
      others: this.getTotalAmount('others', updatedExpenses)
    });
    this.props.onAfterUpdate();
  };

  getTotalAmount = (expenseType, updatedExpenses = null) => {
    const data = JSON.parse(JSON.stringify(this.state.data));

    if (!updatedExpenses) {
      updatedExpenses = data.expenses;
    }
    if (!updatedExpenses || !updatedExpenses[0]) {
      return 0;
    } else if (expenseType === 'toiletry' || expenseType === 'others') {
      const expenses = updatedExpenses.map(ue => ue[expenseType] || []);
      let amtsArray = expenses.reduce((exp, cur) => {
        return exp.concat(cur.map(t => Number(t.amount || 0)));
      }, []);

      if (amtsArray.length > 0) {
        return amtsArray.reduce((total, amt) => {
          return total + amt;
        }, 0);
      } else {
        return 0;
      }
    } else {
      const currentExpense = updatedExpenses.map(ue => ue[expenseType] || 0);
      let subtotalExpenses = currentExpense.reduce((totalExp, curExp) => {
        return parseFloat(totalExp) + parseFloat(curExp);
      });
      const presetKey = 'preset' + expenseType;
      const currentPresetExpense = updatedExpenses.map(ue => ue[presetKey] || 0);
      let subtotalPreset = currentPresetExpense.reduce((totalPreset, curPreset) => {
        return parseFloat(totalPreset) + parseFloat(curPreset);
      });
      return subtotalExpenses + subtotalPreset;
    }
  };

  render() {
    const {
      svcPacName,
      host,
      electricity,
      water,
      internet,
      cleaning,
      laundry,
      service,
      checkIn,
      checkOut,
      toiletry,
      others,
      utilityType = [
        { label: 'Electricity', value: electricity },
        { label: 'Water', value: water },
        { label: 'Internet', value: internet },
        { label: 'Cleaning', value: cleaning },
        { label: 'Laundry', value: laundry },
        { label: 'Service', value: service },
        { label: 'Check In', value: checkIn },
        { label: 'Check Out', value: checkOut },
        { label: 'Toiletry Sets', value: toiletry },
        { label: 'Others', value: others }
      ],
      totalExpenses = electricity + water + internet + cleaning + laundry + toiletry + others + service + checkIn + checkOut,
      visible,
      data,
      year,
      month
    } = this.state;
    const { data: originalData, checkIsAdminReadOnly } = this.props;

    return (
      <React.Fragment>
        <Card className="el-card">
          <Row type="flex" justify="space-between">
            <Col className="el-label" span={12}>
              <label>{intl.get('expenses.headerLabels.servicePackage').d('Service Package:')} </label>
            </Col>
            <Col className="el-value" span={12}>
              <span>{svcPacName}</span>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col className="el-label" span={12}>
              <label>{intl.get('expenses.headerLabels.hostName').d('Host Name:')} </label>
            </Col>
            <Col className="el-value" span={12}>
              <span>{host}</span>
            </Col>
          </Row>
          <hr />
          {utilityType.map((u, idx) => {
            return (
              <Row key={`utilityPrice${idx}`} type="flex" justify="space-between">
                <Col className="el-label" span={12}>
                  <label>{intl.get(`expenses.utilityType.${u.label}`).d(u.label)} </label>
                </Col>
                <Col className="el-value" span={12}>
                  <span>
                    {this.state.currency} {numberWithCommas(u.value)}
                  </span>
                </Col>
              </Row>
            );
          })}
          <hr />
          <Row type="flex" justify="space-between">
            <Col className="el-label" span={12}>
              <label>{intl.get('expenses.headerLabels.totalExpenses').d('Total Expenses:')} </label>
            </Col>
            <Col className="el-value" span={12}>
              <span>
                {this.state.currency} {numberWithCommas(totalExpenses)}
              </span>
            </Col>
          </Row>
          <Row type="flex">
            <Button id="update-button1-exp" type="primary" className="el-button" onClick={this.showDrawer} disabled={checkIsAdminReadOnly()}>
              {intl.get('expenses.headerLabels.update').d('Update')}
            </Button>
          </Row>
        </Card>
        <ExpensesUpdate
          visible={visible}
          onClose={this.onClose}
          data={data}
          host={originalData.host}
          svcPacName={originalData.svcPacName}
          expenses={originalData.expenses}
          onInputChange={this.getTotalAmount}
          year={year}
          month={month}
          currency={this.state.currency}
          timezone={this.state.timezone}
        />
      </React.Fragment>
    );
  }
}
