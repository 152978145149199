import React, { Component, Fragment } from 'react';
import { Form, Row, Tabs, Select, Card, Col, Input, Checkbox, Icon, Upload, Button, message, Modal, Timeline, Descriptions } from 'antd';
import queryString from 'query-string';
import { withAppContext } from 'context/AppContext';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import CloseButtonReservation from 'components/CloseButton/CloseButtonReservation';
import UploadTaskPhoto from 'components/UploadTaskPhoto/UploadTaskPhoto';

import { getConstants } from 'utils/apis/constants';
import { buildTaskManagementUri } from 'utils/routes';
import { deleteTaskById, getChecklistByPropertyId, getCleaningTeamByPropertyId, getTaskById, updateTask } from 'utils/apis/taskManagement';
import { diff } from 'deep-diff';
import intl from 'react-intl-universal';

import './TaskEditForm.css';

const FormItem = Form.Item;
const { Option } = Select;
const TabPane = Tabs.TabPane;

const ActionButtons = ({
  shouldShowSaveButton,
  shouldDisableButton,
  shouldShowDeleteButton,
  isSaveButtonLoading,
  isDeleteButtonLoading,
  onDelete,
  className,
  checkIsAdminReadOnly
}) => {
  return (
    <FormItem className={className}>
      <Fragment>
        <div style={{ textAlign: 'center', margin: 'auto' }}>
          {shouldShowSaveButton && (
            <Button type="primary" htmlType="submit" loading={isSaveButtonLoading} disabled={shouldDisableButton || checkIsAdminReadOnly()}>
              {intl.get('taskManagement.preset.headerLabels.save').d('Save')}
            </Button>
          )}
          {shouldShowDeleteButton && (
            <Button
              type="danger"
              onClick={onDelete}
              className="sp-button-margin"
              loading={isDeleteButtonLoading}
              disabled={shouldDisableButton || checkIsAdminReadOnly()}
            >
              {intl.get('taskManagement.preset.headerLabels.delete').d('Delete')}
            </Button>
          )}
        </div>
      </Fragment>
    </FormItem>
  );
};

class TaskEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCleaner: false,
      isLoading: true,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      taskStatus: [],
      status: '',
      priorityTypeOptions: [],
      priorityType: '',
      cleaningTeam: '',
      cleaningTeamOptions: [],
      checklist: '',
      checklistOptions: [],
      checkedItems: [],
      checklistItems: {},
      noOfCleaner: 0,
      maxNoOfCleaner: 0,
      taskDetails: { cleaner: [] },
      activityLog: []
    };
  }

  componentDidMount() {
    const { match, form } = this.props;
    getConstants('taskPriority').then(res => {
      if (res && res.status === 200) {
        let priorityType = Object.keys(res.data).map(i => {
          return res.data[i];
        });
        if (priorityType.length > 0) {
          this.setState({
            priorityTypeOptions: priorityType.map(i => {
              return {
                value: i.code,
                label: i.label
              };
            })
          });
        }
      } else {
        console.log('Error while retrieving priority types');
      }
    });

    getConstants('taskStatus').then(res => {
      if (res && res.status === 200) {
        let status = Object.keys(res.data).map(i => {
          return res.data[i];
        });
        this.setState({ taskStatus: status });
      }
    });

    if (match.params.id) {
      getTaskById(match.params.id)
        .then(res => {
          console.log(res, 110);
          if (res.status === 200) {
            getCleaningTeamByPropertyId(res.data.property._id).then(cleaningTeams => {
              let cleaningTeamOptions = [];
              if (cleaningTeams.data.length > 0) {
                cleaningTeamOptions = cleaningTeams.data.map(cleaningTeam => {
                  return { value: cleaningTeam._id, label: cleaningTeam.name, numberOfCleaner: cleaningTeam.users.length };
                });
              }
              this.setState({ cleaningTeamOptions });
            });

            getChecklistByPropertyId(res.data.property._id).then(checklists => {
              let checklistOptions = [];
              if (checklists.data.length > 0) {
                checklistOptions = checklists.data.map(checklist => {
                  return { value: checklist._id, label: checklist.name };
                });
              }
              this.setState({ checklistOptions });
            });

            let checkedItems = [];
            Object.entries(res.data.checklistItems).map(item => {
              if (item[1]) {
                checkedItems.push(item[0]);
              }
            });

            form.setFieldsValue({
              code: res.data.code ? res.data.code : '-',
              createdDate: moment(res.data.createdAt).format('DD-MM-YYYY hh:mm a'),
              startDate: res.data.startedAt ? moment(res.data.startedAt).format('DD-MM-YYYY hh:mm a') : '-',
              completedDate: res.data.completedAt ? moment(res.data.completedAt).format('DD-MM-YYYY hh:mm a') : '-',
              // cleaner: res.data.formattedCleaner ? res.data.formattedCleaner : '-',
              cleaner: res.data.cleaner.map(x => x.id._id),
              property: res.data.property.name,
              roomType: res.data.roomType.name,
              unit: res.data.unit.name,
              notes: res.data.notes && res.data.notes,
              remarks: res.data.remarks && res.data.remarks,
              photos: this.extractPhotosData(res.data),
              completedDate: `${moment(res.data.finalCompletedAt).format('DD-MM-YYYY hh:mm a')}, ${intl
                .get('taskManagement.taskList.headerLabels.totalTimeTaken')
                .d('Total :')} : ${res.data.timeTaken}`
            });

            console.log(151, res.data.activityLog);
            res.data.activityLog.forEach((v, idx, array) => {
              let differences = [];
              if (v.oldData) {
                let dataDiff = diff(v.oldData, v.newData);
                differences = differences.concat(dataDiff);
              }
              if (idx !== 0) {
                let createdByDiff = diff(array[idx - 1].createdBy, v.createdBy);
                differences = differences.concat(createdByDiff);
              }
              v.differences = differences;
            });

            this.setState({
              status: res.data.status,
              checklistItems: res.data.checklistItems,
              checkedItems,
              cleaningTeam: res.data.cleaningTeam._id,
              priorityType: res.data.priority,
              noOfCleaner: res.data.noOfCleaner,
              checklist: res.data.checklist,
              isLoading: false,
              taskDetails: res.data,
              activityLog: res.data.activityLog
            });
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            Modal.error({
              title: intl.get('taskManagement.taskList.message.taskNotExist').d('Task not exist'),
              content: intl.get('taskManagement.taskList.message.taskNotExistContent').d('Oops...Task you looking for not exist.'),
              okText: intl.get('taskManagement.taskList.message.back').d('Back'),
              onOk: () => {
                console.log(this.props, 193);
                this.props.history.push('/task-management/tasklist');
              }
            });
          }
        });
    }

    this.setState({
      isCleaner: this.props.checkIsCleaner()
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.props;
    if (
      prevState.cleaningTeam !== this.state.cleaningTeam ||
      JSON.stringify(prevState.cleaningTeamOptions) !== JSON.stringify(this.state.cleaningTeamOptions)
    ) {
      let maxNoOfCleaner = 1;

      if (this.state.cleaningTeam !== '' && this.state.cleaningTeamOptions.length > 0) {
        maxNoOfCleaner = this.state.cleaningTeamOptions.find(team => team.value === this.state.cleaningTeam).numberOfCleaner;
        if (form.getFieldValue('noOfCleaner') > maxNoOfCleaner) {
          form.setFieldsValue({
            noOfCleaner: maxNoOfCleaner
          });
        }
      }

      this.setState({ maxNoOfCleaner });
    }

    if (prevState.status !== this.state.status || JSON.stringify(prevState.taskStatus) !== JSON.stringify(this.state.taskStatus)) {
      if (this.state.status !== '') {
        form.setFieldsValue({
          status: intl
            .get(`taskManagement.taskList.tableColumns.statusOptions.${this.state.taskStatus.find(s => s.code === this.state.status).label}`)
            .d(this.state.taskStatus.find(s => s.code === this.state.status).label)
        });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const taskId = match.params.id;
        let payload = {};

        payload.images = values.photos.length > 0 ? this.formatPhotosData(values) : [];
        let checklistItemsArr = Object.keys(this.state.checklistItems);
        let appendChecklistItem = checklistItemsArr.reduce((prevVal, currVal) => {
          return { ...prevVal, [currVal]: values.checklistItems.some(i => i === currVal) };
        }, {});

        payload = {
          ...payload,
          checklistItems: appendChecklistItem,
          checklist: values.checklist,
          notes: values.notes,
          remarks: values.remarks,
          cleaner: values.cleaner
        };

        // payload.checklistItems = appendChecklistItem;
        if (form.getFieldValue('status') === intl.get('taskManagement.taskList.tableColumns.statusOptions.Pending Cleaner').d('Pending Cleaner')) {
          payload = {
            ...payload,
            cleaningTeam: values.cleaningTeam,
            priority: values.priority,
            noOfCleaner: values.noOfCleaner,
            cleaner: values.cleaner
          };
        }

        const handleOnOk = async () => {
          try {
            this.setState({ isSaveButtonLoading: true });
            const res = await updateTask(taskId, payload);
            if (res && res.status === 204) {
              history.push(`${buildTaskManagementUri()}/tasklist`);
              message.success(intl.get('taskManagement.taskList.message.taskUpdated').d('Task Updated'));
              this.setState({ isSaveButtonLoading: false });
            }
          } catch (ex) {
            message.error(ex);
            this.setState({ isSaveButtonLoading: false });
          }
        };

        Modal.confirm({
          title: intl.get('taskManagement.taskList.message.updateTitle').d('Are you sure want to overwrite existing data?'),
          content: intl
            .get('taskManagement.taskList.message.updateContent')
            .d('You will not be able to undo this action, but you may update it again.'),
          onOk: handleOnOk,
          onCancel() {},
          okButtonProps: { id: 'save-cfm-button-edittask' },
          cancelButtonProps: { id: 'cancelsave-cfm-button-edittask' }
        });
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const taskId = match.params.id;

    const handleOnOk = async () => {
      try {
        this.setState({ isDeleteButtonLoading: true });
        const res = await deleteTaskById(taskId);

        if (res.status === 204) {
          this.setState({ isDeleteButtonLoading: false });
          history.push(`${buildTaskManagementUri()}/tasklist`);
          message.success(intl.get('taskManagement.taskList.message.taskDeleted').d('Task Deleted'));
        } else {
          message.error(intl.get('taskManagement.taskList.message.somethingWrongDelete').d('Something went wrong and task is not deleted'));
          this.setState({ isDeleteButtonLoading: false });
        }
      } catch (ex) {
        message.error(ex || intl.get('taskManagement.taskList.message.somethingWrongDelete').d('Something went wrong and task is not deleted'));
        this.setState({ isDeleteButtonLoading: false });
      }
    };

    Modal.confirm({
      title: intl.get('taskManagement.taskList.message.deleteTitle').d('Are you sure want to delete this task?'),
      content: intl.get('taskManagement.taskList.message.deleteContent').d('This action cannot be reversed. Once deleted, it cannot be recovered'),
      onOk: handleOnOk,
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button-edittask' },
      cancelButtonProps: { id: 'cancel-cfm-button-edittask' }
    });
  };

  handleOnUploadFinish = e => {
    const { form } = this.props;
    const photos = form.getFieldValue('photos') || [];
    const uploadedPhoto = {
      link: e.fileUrl
    };
    form.setFieldsValue({
      photos: [...photos, uploadedPhoto]
    });
  };

  handleOnImageDelete = index => e => {
    const { form } = this.props;

    e.preventDefault();

    const photos = form.getFieldValue('photos');

    this.setState({ isDeletedByUser: true });

    photos.splice(index, 1);
    form.setFieldsValue({
      photos
    });
  };

  formatPhotosData = data =>
    data.photos.map(data => ({
      imageUrl: data.link
    }));

  extractPhotosData = data => {
    return data.images && data.images.length > 0
      ? data.images.map(image => ({
          link: image.imageUrl,
          image
        }))
      : [];
  };

  validateMaxNoOfCleaner = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value > this.state.maxNoOfCleaner) {
      callback(intl.get('taskManagement.taskList.message.maxCleaner').d('Exceed maximum number of cleaner in the team'));
    } else {
      callback();
    }
  };

  cleanerIncrement = () => {
    const { form } = this.props;
    const { maxNoOfCleaner } = this.state;
    if (form.getFieldValue('noOfCleaner') < maxNoOfCleaner) {
      form.setFieldsValue({ noOfCleaner: form.getFieldValue('noOfCleaner') + 1 });
    }
  };

  cleanerDecrease = () => {
    const { form } = this.props;
    if (form.getFieldValue('noOfCleaner') > 1) {
      form.setFieldsValue({
        noOfCleaner: form.getFieldValue('noOfCleaner') - 1
      });
    }
  };

  setQueryToLink = activeTab => {
    const { history } = this.props;
    history.push({
      search: `?selectedTab=${activeTab}`
    });
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  activityLogUpdatedBy = updatedBy => {
    return updatedBy ? `${updatedBy.userProfile.firstName} ${updatedBy.userProfile.lastName}` : 'N/A';
  };

  checkDifferences = (data, index, type) => {
    const { differences, createdBy } = data;
    const { taskStatus } = this.state;

    switch (type) {
      case 'creator':
        const isCreatorDiff = !!(
          differences.length > 0 &&
          differences.findIndex(d => d && d.path.findIndex(v => v === 'userProfile') > -1 && d.path.findIndex(v => v === '_id') > -1) > -1
        );

        return (
          <Descriptions.Item
            className={isCreatorDiff ? 'activityLogDiff' : ''}
            label={
              <span style={{ fontWeight: 600 }}>
                {index === 0
                  ? intl.get('reservations.activityLogs.headerLabels.createdAt').d('Created At')
                  : intl.get('reservations.activityLogs.headerLabels.updatedAt').d('Updated At')}
              </span>
            }
          >
            {this.activityLogUpdatedBy(createdBy)}
          </Descriptions.Item>
        );

      case 'status':
        const isStatusDiff = !!(differences.length > 0 && differences.findIndex(d => d && d.path.findIndex(v => v === 'status') > -1) > -1);

        return (
          <Descriptions.Item
            /* className={isStatusDiff ? 'activityLogDiff' : ''} */ label={
              <span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.status').d('Status')}</span>
            }
          >
            {intl
              .get(`taskManagement.taskList.tableColumns.statusOptions.${taskStatus.find(t => t.code === data.newData.status).label}`)
              .d(taskStatus.find(t => t.code === data.newData.status).label)}
          </Descriptions.Item>
        );

      case 'priority':
        const isPriorityDiff = !!(differences.length > 0 && differences.findIndex(d => d && d.path.findIndex(v => v === 'priority') > -1) > -1);

        return (
          <Descriptions.Item
            className={isPriorityDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.priority').d('Priority')}</span>}
          >
            {intl.get(`taskManagement.taskList.priority.${data.newData.priority}`).d(data.newData.priority)}
          </Descriptions.Item>
        );

      case 'team':
        const isTeamDiff = !!(
          differences.length > 0 &&
          differences.findIndex(d => d && d.path.findIndex(v => v === 'cleaningTeam') > -1 && d.path.findIndex(v => v === '_id') > -1) > -1
        );

        return (
          <Descriptions.Item
            className={isTeamDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.teamName').d('Team Name')}</span>}
          >
            {data.newData.cleaningTeam.name}
          </Descriptions.Item>
        );

      case 'checklist':
        const isChecklistDiff = !!(
          differences.length > 0 &&
          differences.findIndex(d => d && d.path.findIndex(v => v === 'checklist') > -1 && d.path.findIndex(v => v === '_id') > -1) > -1
        );

        return (
          <Descriptions.Item
            className={isChecklistDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.checklist').d('Checklist')}</span>}
          >
            {data.newData.checklist.name}
          </Descriptions.Item>
        );

      case 'cleanerNumber':
        const isCleanerNoDiff = !!(differences.length > 0 && differences.findIndex(d => d && d.path.findIndex(v => v === 'noOfCleaner') > -1) > -1);
        return (
          <Descriptions.Item
            className={isCleanerNoDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.cleanerNum').d('Number of Cleaner')}</span>}
          >
            {data.newData.noOfCleaner}
          </Descriptions.Item>
        );

      case 'notes':
        const isNotesDiff = !!(differences.length > 0 && differences.findIndex(d => d && d.path.findIndex(v => v === 'notes') > -1) > -1);
        return (
          <Descriptions.Item
            className={isNotesDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.hostNote').d('Host Notes')}</span>}
          >
            {data.newData.notes}
          </Descriptions.Item>
        );

      case 'remarks':
        const isRemarksDiff = !!(differences.length > 0 && differences.findIndex(d => d && d.path.findIndex(v => v === 'remarks') > -1) > -1);
        return (
          <Descriptions.Item
            className={isRemarksDiff ? 'activityLogDiff' : ''}
            label={<span style={{ fontWeight: 600 }}>{intl.get('taskManagement.taskList.headerLabels.remark').d('Remarks')}</span>}
          >
            {data.newData.remarks ? data.newData.remarks : '-'}
          </Descriptions.Item>
        );

      default:
        break;
    }
  };

  render() {
    const {
      form,
      history,
      location,
      match: {
        params: { id }
      },
      checkIsAllowDeleteTask,
      checkIsAllowEditTask,
      checkIsAdminReadOnly
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const {
      isLoading,
      isSaveButtonLoading,
      isDeleteButtonLoading,
      priorityType,
      priorityTypeOptions,
      cleaningTeam,
      cleaningTeamOptions,
      checklist,
      checklistOptions,
      checklistItems,
      checkedItems,
      noOfCleaner,
      activityLog
    } = this.state;
    const photos = getFieldValue('photos');
    const query = queryString.parse(location.search);
    const notPendingStatus =
      getFieldValue('status') !== intl.get('taskManagement.taskList.tableColumns.statusOptions.Pending Cleaner').d('Pending Cleaner');
    const completedStatus = getFieldValue('status') === intl.get('taskManagement.taskList.tableColumns.statusOptions.Completed').d('Completed');

    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Tabs
            className="taskDetailsContainer"
            defaultActiveKey={query.selectedTab}
            onTabClick={this.setQueryToLink}
            activeKey={query.selectedTab ? query.selectedTab : 'taskDetails'}
            tabBarExtraContent={
              <CloseButtonReservation
                onClick={() => {
                  history.push(`${buildTaskManagementUri()}/tasklist`);
                }}
              />
            }
          >
            <TabPane
              tab={intl.get('taskManagement.taskList.headerLabels.taskDetail').d('Task Details')}
              key="taskDetails"
              style={{ padding: '0 16px' }}
            >
              <Form onSubmit={this.handleSubmit} layout="horizontal" colon={false}>
                <Row gutter={8}>
                  <Card
                    title={intl.get('taskManagement.taskList.headerLabels.taskDetail').d('Task Details')}
                    loading={isLoading}
                    className="details-card"
                    extra={
                      this.state.taskDetails.reservationId && (
                        <Button href={`/reservation/${this.state.taskDetails.reservationId._id}/edit`} target="_blank" type="primary">
                          {intl.get('taskManagement.taskList.headerLabels.viewReservation').d('View Reservation')}
                        </Button>
                      )
                    }
                  >
                    <Row gutter={24} justify="start" type="flex">
                      <Col span={16}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.confirmationCode').d('Confirmation Code')}>
                          {this.state.taskDetails.code ? (
                            <a href={`/reservation/${this.state.taskDetails.reservationId._id}/edit`} target="_blank">
                              <Icon type="link" /> {this.state.taskDetails.code}{' '}
                              {this.state.taskDetails.reservationId.sourceId && `(${this.state.taskDetails.reservationId.sourceId})`}
                            </a>
                          ) : (
                            <span style={{ color: 'rgba(0,0,0,.35)', fontStyle: 'italic' }}>
                              {intl.get('taskManagement.taskList.headerLabels.createdManually').d('Created manually by Host')}
                            </span>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.status').d('Status')}>
                          {getFieldDecorator('status')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.assignDate').d('Assign Date')}>
                          {getFieldDecorator('createdDate')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.startTime').d('Start Time')}>
                          {getFieldDecorator('startDate')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.endTime').d('End Time')}>
                          {getFieldDecorator('completedDate')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.teamName').d('Team Name')}>
                          {getFieldDecorator('cleaningTeam', { initialValue: cleaningTeam })(
                            <Select
                              placeholder={intl.get('taskManagement.taskList.placeholder.cleaningTeam').d('Select a cleaning team')}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onChange={e => this.setState({ cleaningTeam: e })}
                              disabled={cleaningTeamOptions.length === 0 || notPendingStatus}
                            >
                              {cleaningTeamOptions.map(cleaningTeam => {
                                return (
                                  <Option key={cleaningTeam.value} value={cleaningTeam.value}>
                                    {cleaningTeam.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.priority').d('Priority')}>
                          {getFieldDecorator('priority', { initialValue: priorityType })(
                            <Select
                              placeholder={intl.get('taskManagement.taskList.placeholder.priority').d('Select the priority')}
                              onChange={e => this.setState({ priorityType: e })}
                              disabled={priorityTypeOptions.length === 0 || notPendingStatus}
                            >
                              {priorityTypeOptions.map(priorityOption => {
                                return (
                                  <Option key={priorityOption.value} value={priorityOption.value}>
                                    {intl.get(`taskManagement.taskList.priority.${priorityOption.label}`).d(priorityOption.label)}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.cleanerNum').d('Number of Cleaner')} className="inputaddon">
                          {getFieldDecorator('noOfCleaner', { initialValue: noOfCleaner })(
                            <Input
                              type="number"
                              addonBefore={
                                <Icon
                                  type="minus"
                                  onClick={() => (cleaningTeam && !notPendingStatus && !this.state.isCleaner ? this.cleanerDecrease() : {})}
                                />
                              }
                              addonAfter={
                                <Icon
                                  type="plus"
                                  style={{ color: '#fff' }}
                                  onClick={() => (cleaningTeam && !notPendingStatus && !this.state.isCleaner ? this.cleanerIncrement() : {})}
                                />
                              }
                              style={{ width: '50%', textAlign: 'center' }}
                              disabled={!cleaningTeam || notPendingStatus}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.checklist').d('Checklist')}>
                          {getFieldDecorator('checklist', { initialValue: checklist })(
                            <Select
                              placeholder={intl.get('taskManagement.taskList.placeholder.checklist').d('Select a checklist to apply')}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              onChange={e => this.setState({ checklist: e })}
                              // disabled={checklistOptions.length === 0 || notPendingStatus}
                              disabled
                            >
                              {checklistOptions.map(checklist => {
                                return (
                                  <Option key={checklist.value} value={checklist.value}>
                                    {checklist.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={16}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.cleaner').d('Cleaner')}>
                          {getFieldDecorator('cleaner')(
                            <Select allowClear mode="multiple" disabled={this.state.isCleaner}>
                              {this.state.taskDetails.cleaner.map(cleaner => {
                                return (
                                  <Select.Option key={cleaner.id._id} value={cleaner.id._id}>
                                    {cleaner.id.userProfile.firstName} {cleaner.id.userProfile.lastName}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title={intl.get('taskManagement.taskList.headerLabels.roomDetails').d('Room Details')}
                    loading={isLoading}
                    className="details-card"
                  >
                    <Row gutter={16} justify="start">
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.property').d('Property')}>
                          {getFieldDecorator('property')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.roomType').d('Room Type')}>
                          {getFieldDecorator('roomType')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.unitName').d('Unit Name')}>
                          {getFieldDecorator('unit')(<Input disabled style={{ cursor: 'default' }} />)}
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label={intl.get('taskManagement.taskList.headerLabels.hostNote').d('Host Notes')}>
                          {getFieldDecorator('notes')(
                            <Input.TextArea
                              placeholder={intl.get('taskManagement.taskList.placeholder.message').d('Leave your message here...')}
                              disabled={completedStatus || this.state.isCleaner}
                              autoSize={{ minRows: 3, maxRows: 6 }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title={intl.get('taskManagement.taskList.headerLabels.checklist').d('Checklist')}
                    loading={isLoading}
                    className="details-card"
                  >
                    <FormItem>
                      {getFieldDecorator('checklistItems', {
                        initialValue: checkedItems
                      })(
                        <Checkbox.Group style={{ width: '100%' }} disabled={completedStatus}>
                          <Row>
                            {Object.keys(checklistItems).map(item => {
                              return (
                                <Col key={item} span={4}>
                                  <Checkbox value={item}>{item}</Checkbox>
                                </Col>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>
                      )}
                    </FormItem>
                  </Card>
                  <Card
                    title={intl.get('taskManagement.taskList.headerLabels.fileUpload').d('File(s) Upload')}
                    loading={isLoading}
                    className="details-card"
                  >
                    <FormItem>
                      {getFieldDecorator('photos', {
                        initialValue: []
                      })(<input type="hidden" />)}
                      <UploadTaskPhoto
                        photos={photos}
                        onImageDelete={this.handleOnImageDelete}
                        onUploadFinish={this.handleOnUploadFinish}
                        disabled={completedStatus}
                      />
                    </FormItem>
                  </Card>
                  <Card title={intl.get('taskManagement.taskList.headerLabels.remark').d('Remarks')} loading={isLoading} className="details-card">
                    <FormItem>
                      {getFieldDecorator('remarks')(
                        <Input.TextArea
                          placeholder={intl.get('taskManagement.taskList.placeholder.message').d('Leave your message here...')}
                          disabled={!this.state.isCleaner}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                      )}
                    </FormItem>
                  </Card>

                  <ActionButtons
                    shouldShowSaveButton={checkIsAllowEditTask()}
                    shouldDisableButton={getFieldValue('status') === 'Completed'}
                    shouldShowDeleteButton={checkIsAllowDeleteTask()}
                    isSaveButtonLoading={isSaveButtonLoading}
                    isDeleteButtonLoading={isDeleteButtonLoading}
                    onDelete={this.handleDelete}
                    checkIsAdminReadOnly={checkIsAdminReadOnly}
                  />
                </Row>
              </Form>
            </TabPane>
            <TabPane
              tab={intl.get('taskManagement.taskList.headerLabels.taskLog').d('Task Logs')}
              key="taskLogs"
              style={{ padding: '0 16px' }}
              forceRender={true}
            >
              <div>
                <Timeline>
                  {activityLog.map((log, idx) => (
                    <Timeline.Item key={log._id}>
                      <Descriptions
                        title={intl
                          .get(`taskManagement.taskList.actLog.${this.capitalizeFirstLetter(log.method)}`)
                          .d(this.capitalizeFirstLetter(log.method))}
                        size="small"
                        bordered
                        colon={false}
                      >
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: 600 }}>
                              {idx === 0
                                ? intl.get('reservations.activityLogs.headerLabels.createdAt').d('Created At')
                                : intl.get('reservations.activityLogs.headerLabels.updatedAt').d('Updated At')}
                            </span>
                          }
                        >
                          {moment(log.createdAt).format('DD-MMM-YYYY HH:mm')}
                        </Descriptions.Item>
                        {this.checkDifferences(log, idx, 'creator')}
                        {this.checkDifferences(log, idx, 'status')}
                        {this.checkDifferences(log, idx, 'priority')}
                        {this.checkDifferences(log, idx, 'team')}
                        {this.checkDifferences(log, idx, 'checklist')}
                        {this.checkDifferences(log, idx, 'cleanerNumber')}
                        {this.checkDifferences(log, idx, 'notes')}
                        {this.checkDifferences(log, idx, 'remarks')}
                      </Descriptions>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            </TabPane>
          </Tabs>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(withAppContext(Form.create()(TaskEditForm)));
