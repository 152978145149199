import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { AppContext } from 'context/AppContext';

import { formatToDateString, getCurrentMoment } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { buildTaskManagementPresetTaskCreateUri, buildTaskManagementPresetTaskEditUri } from 'utils/routes';
import { getAllPresetTasks } from 'utils/apis/taskManagement';

import { StyledCard, StyledHeader } from './PresetTask.styles';
import intl from 'react-intl-universal';

const PresetTask = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [presetTasks, setPresetTasks] = useState([]);

  useEffect(() => {
    getAllPresetTasks().then(res => {
      // console.log(res);
      if (res.status === 200) {
        setPresetTasks(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.preset').d('Preset Task Name'), 'taskName', {
          linkFunc: record => buildTaskManagementPresetTaskEditUri(record._id)
        }),
        ...constructColumnFilterSearch('taskName', intl.get('tables.preset').d('Search Task'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.property').d('Property Name'), 'property'),
        ...constructColumnFilterSearch('property', intl.get('tables.property').d('Search Property Name'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.cleaningTeam').d('Cleaning Team'), 'cleaningTeam'),
        ...constructColumnFilterSearch('cleaningTeam', intl.get('tables.cleaningTeam').d('Search Cleaning Team'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.cleanerNum').d('# of Cleaner'), 'noOfCleaner', { hasSorter: true })
        // ...constructColumnFilterSearch('noOfCleaner', 'Search Cleaning Team')
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.checklist').d('Checklist use'), 'checklist'),
        ...constructColumnFilterSearch('checklist', intl.get('tables.checkList').d('Search Checklist'))
      }
    ];
  };

  const constructCSV = presetTasks => {
    return presetTasks.map(presetTask => {
      return {
        [intl.get('csv.presetTaskName').d('Preset Task Name')]: presetTask.taskName,
        [intl.get('csv.propertyName').d('Property Name')]: presetTask.property,
        [intl.get('csv.cleaningTeam').d('Cleaning Team')]: presetTask.cleaningTeam,
        [intl.get('csv.cleanerNum').d('# of Cleaner')]: presetTask.noOfCleaner,
        [intl.get('csv.checklist').d('Checklist')]: presetTask.checklist,
        [intl.get('csv.unitsApplied').d('Units Applied')]: presetTask.units,
        [intl.get('csv.remark').d('Remarks')]: presetTask.remark
      };
    });
  };

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);
  const csvName = useMemo(() => `preset_tasks_${currentDate}.csv`, [currentDate]);

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementPresetTaskCreateUri()}>
            <Button id="create-button-preset-task" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              {intl.get('taskManagement.preset.headerLabels.create').d('Create Preset Task')}
            </Button>
          </Link>
        )}
        <CSVLink filename={csvName} data={constructCSV(presetTasks)}>
          <Button id="csv-button-tasklist" type="primary" icon="download" disabled={contextProvider.checkIsAdminReadOnly()}>
            {intl.get('taskManagement.preset.headerLabels.csv').d('Download CSV')}
          </Button>
        </CSVLink>
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={presetTasks} />
    </StyledCard>
  );
};

export default PresetTask;
