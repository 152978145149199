import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'context/AppContext';
import { StyledCard, StyledHeader } from './CleaningTeam.styles';

import { buildTaskManagementCTCreateUri, buildTaskManagementCTEditUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { getCleaningTeamsList } from 'utils/apis/taskManagement';
import intl from 'react-intl-universal';

const CleaningTeam = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [cleaningTeams, setCleaningTeams] = useState([]);

  useEffect(() => {
    getCleaningTeamsList().then(res => {
      if (res.status === 200) {
        setCleaningTeams(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.team').d('Team Name'), 'teamName', {
          linkFunc: record => buildTaskManagementCTEditUri(record._id)
        }),
        ...constructColumnFilterSearch('teamName', intl.get('tables.teamName').d('Search Team'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.host').d('Host'), 'host'),
        ...constructColumnFilterSearch('host', intl.get('tables.hostName').d('Search Host'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.users').d("User's Name"), 'users'),
        ...constructColumnFilterSearch('users', intl.get('tables.user').d('Search User'))
      },
      {
        ...constructColumn(intl.get('taskManagement.taskList.tableColumns.property').d('Property Name'), 'property'),
        ...constructColumnFilterSearch('property', intl.get('tables.property').d('Search Property Name'))
      }
    ];
  };

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementCTCreateUri()}>
            <Button id="create-button-cleaning-team" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              {intl.get('taskManagement.cleaner.headerLabels.create').d('Create Cleaner Team')}
            </Button>
          </Link>
        )}
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={cleaningTeams} />
    </StyledCard>
  );
};

export default CleaningTeam;
