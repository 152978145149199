import React from 'react';
import { Card, Row, Table } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import { convertVariableNameToReadableString, constructTwoDecimalsRule } from 'utils/general';

import styles from './OperationCostsCard.module.css';
import intl from 'react-intl-universal';

const contstructFormInput = (form, inputName, value, currency = 'RM') => (
  <FormInput
    className={styles.formInput}
    name={inputName}
    placeholder="0.00"
    form={form}
    defaultValue={value ? parseFloat(value).toFixed(2) : '0.00'}
    extraProps={{
      addonBefore: currency
    }}
    extraRules={[constructTwoDecimalsRule()]}
    size="large"
  />
);

class OperationCostsCard extends React.Component {
  render() {
    const { form, cardClassname, defaultValues, currency } = this.props;
    const columns = [
      {
        title: intl.get('listings.unit.tableColumns.cost').d('Operation Cost Type'),
        dataIndex: 'key',
        render: value => intl.get(`listings.unit.operationCosts.${convertVariableNameToReadableString(value)}`).d('Operation Cost Type')
      },
      {
        title: intl.get('listings.unit.tableColumns.reservationDay').d('Days of Reservations'),
        dataIndex: 'perDay',
        render: (value, row) => {
          return contstructFormInput(form, `${row.key}PerDay`, value, currency);
        }
      },
      {
        title: intl.get('listings.unit.tableColumns.reservationBased').d('Based on Reservations'),
        dataIndex: 'perCheckOut',
        render: (value, row) => {
          return contstructFormInput(form, `${row.key}PerCheckOut`, value, currency);
        }
      },
      {
        title: intl.get('listings.unit.tableColumns.months').d('Based on months'),
        dataIndex: 'perMonth',
        render: (value, row) => {
          return contstructFormInput(form, `${row.key}PerMonth`, value, currency);
        }
      }
    ];

    return (
      <Card title={intl.get('listings.unit.headerLabels.operationCost').d('Fixed Operation Costs')} className={cardClassname}>
        <Row gutter={16}>
          <Table columns={columns} dataSource={defaultValues} scroll={{ x: 700 }} bordered pagination={false} />
        </Row>
      </Card>
    );
  }
}

OperationCostsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.array,
  currency: PropTypes.string
};

OperationCostsCard.defaultProps = {
  defaultValues: [],
  currency: 'RM'
};

export default OperationCostsCard;
