import React, { Component } from 'react';
import { Form, Modal, Button, message, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getProperties, getPropertiesByHostId } from 'utils/apis/property';
import { getConstants } from 'utils/apis/constants';
import { getRoomTypesByProperty, getUnitsByRoomType, updateCalendarBulk } from 'utils/apis/multiCalendar';
import { DATE_FORMAT } from 'utils/constants';
import intl from 'react-intl-universal';

import queryString from 'query-string';
import MCRateUpdateBody from './components/MCRateUpdateBody/MCRateUpdateBody';

class MCModalRateUpdateWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isRateLoading: false,
      isDataSaveBefore: false,
      daysOfWeek: [],
      rates: [],
      rateTypes: [],
      selectedDays: [],
      selectedPropertyName: ''
    };
  }

  componentDidMount = async () => {
    this.setDataValue();
    await this.fetchAndSetRateData();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedPropertyId !== this.props.selectedPropertyId) {
      this.setDataValue();
      this.fetchAndSetRateData();
    }
  }

  fetchAndSetRateData = async () => {
    const { selectedPropertyId, selectedRateFilters } = this.props;

    const monthStartDate = moment().format(DATE_FORMAT);
    const monthEndDate = moment()
      .add(1, 'day')
      .format(DATE_FORMAT);

    this.setState({
      isRateLoading: true
    });

    const formattedRoomTypeAndUnitRates = await getRoomTypesByProperty(selectedPropertyId, monthStartDate, monthEndDate, selectedRateFilters)
      .then(roomTypes => {
        return Promise.all(
          roomTypes.map(async roomType => {
            const filteredRoomTypeRates = Object.entries(roomType.rates).filter(rate => {
              return rate[1].length > 0;
            });

            let filteredRoomTypeRateArr = [];
            if (filteredRoomTypeRates && filteredRoomTypeRates.length > 0) {
              const filteredRoomTypeRatesData = filteredRoomTypeRates
                .map(filteredRoomTypeRate => {
                  const [source, roomTypeRates] = filteredRoomTypeRate;
                  if (source === 'web' && roomTypeRates.length > 1) {
                    return [roomTypeRates[0]].map(rate => {
                      return {
                        id: rate._id,
                        name: rate.name,
                        source,
                        isDerived: rate.isDerived,
                        calculation: rate.calculation
                      };
                    });
                  }
                  return roomTypeRates.map(rate => {
                    return {
                      id: rate._id,
                      name: rate.name,
                      source,
                      isDerived: rate.isDerived,
                      calculation: rate.calculation
                    };
                  });
                })
                .flat()
                .reduce((combinedRoomTypeRates, roomTypeRate) => {
                  const existingRate = combinedRoomTypeRates.find(rate => {
                    return rate.id === roomTypeRate.id;
                  });
                  if (existingRate) {
                    existingRate.sources.push(roomTypeRate.source);
                    return combinedRoomTypeRates;
                  } else {
                    const newRoomTypeRate = { ...roomTypeRate, sources: [roomTypeRate.source] };
                    return [...combinedRoomTypeRates, newRoomTypeRate];
                  }
                }, []);
              filteredRoomTypeRateArr.push(...filteredRoomTypeRatesData);
            }

            const formattedUnitData = await getUnitsByRoomType(roomType._id, monthStartDate, monthEndDate, selectedRateFilters, 1, 200).then(
              units => {
                console.log(units, 94);
                return units.data
                  .map(unit => {
                    console.log(unit.rates, 96);
                    const filteredUnitRates = Object.entries(unit.rates).filter(rate => {
                      return rate[1].length > 0;
                    });

                    console.log(filteredUnitRates, 100);
                    if (filteredUnitRates && filteredUnitRates.length > 0) {
                      const formattedUnitRates = filteredUnitRates
                        .map(filteredUnitRate => {
                          const [source, unitRates] = filteredUnitRate;
                          return unitRates.map(unitRate => {
                            return {
                              id: unitRate._id,
                              name: unitRate.name,
                              source: source,
                              isDerived: unitRate.isDerived,
                              calculation: unitRate.calculation
                            };
                          });
                        })
                        .flat()
                        .reduce((combinedUnitRates, unitRate) => {
                          const existingRate = combinedUnitRates.find(rate => rate.id === unitRate.id);
                          if (existingRate) {
                            existingRate.sources.push(unitRate.source);
                            return combinedUnitRates;
                          } else {
                            const newUnitRate = { ...unitRate, sources: [unitRate.source] };
                            return [...combinedUnitRates, newUnitRate];
                          }
                        }, []);
                      return {
                        id: unit._id,
                        name: unit.name,
                        roomType: roomType._id,
                        rates: formattedUnitRates
                      };
                    }
                    return undefined;
                  })
                  .filter(unit => !!unit);
              }
            );
            console.log(formattedUnitData, 138);
            const roomTypeData = {
              id: roomType._id,
              name: roomType.name,
              units: formattedUnitData,
              rates: filteredRoomTypeRateArr
            };

            return roomTypeData;
          })
        );
      })
      .catch(err => message.error(err.message));
    this.setState({
      isRateLoading: false,
      rates: formattedRoomTypeAndUnitRates
    });
  };

  setDataValue = async () => {
    const { selectedPropertyId } = this.props;
    const queryHostId = queryString.parse(window.location.search).host || queryString.parse(window.location.search).hostId;
    // const propertyPromise = getProperties()
    const propertyPromise = getPropertiesByHostId(queryHostId) // Optimized by sending hostId instead of getting all properties
      .then(properties => {
        if (properties.length > 0) {
          const property = properties.filter(property => String(property._id) === String(selectedPropertyId));
          if (property.length > 0) {
            return property[0].name;
          }
        }
      })
      .catch(err => {
        console.error(err);
        message.error(err);
        return '';
      });

    const daysOfWeekPromise = getConstants('daysOfWeek').then(resDaysOfWeekPromise => {
      return Object.keys(resDaysOfWeekPromise.data).map(k => resDaysOfWeekPromise.data[k]);
    });

    const rateTypesPromise = getConstants('rateTypesName').then(resRateTypesPromise => {
      return Object.keys(resRateTypesPromise.data).map(k => resRateTypesPromise.data[k]);
    });

    const [selectedPropertyName, daysOfWeekConstant, rateTypes] = await Promise.all([propertyPromise, daysOfWeekPromise, rateTypesPromise]);

    const daysOfWeek = daysOfWeekConstant.map(day => {
      return { ...day, value: Number(day.code) };
    });

    const selectedDays = daysOfWeekConstant.map(day => Number(day.code));

    this.setState({
      selectedPropertyName,
      daysOfWeek,
      rateTypes,
      selectedDays
    });
  };

  handleSelectedDayChange = selectedDays => {
    this.setState({
      selectedDays
    });
  };

  handleOnSubmit = shouldCloseModal => e => {
    e.preventDefault();
    const { form } = this.props;
    const onOkAction = this.handleOnConfirmSubmit(shouldCloseModal);

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Modal.confirm({
          title: intl.get('multicalendar.message.updateRateTitle').d('Do you want to update these calendar rates?'),
          content: intl.get('multicalendar.message.updateRateContent').d('Only rates that had been modifed will be affected.'),
          onOk() {
            return onOkAction(values);
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnConfirmSubmit = shouldCloseModal => async formValues => {
    const { onSuccess, onCancel } = this.props;
    const { rates, selectedDays } = this.state;
    const { dateRange, roomTypesApplied, roomTypesRate, unitsApplied, unitsRate } = formValues;

    if (!!roomTypesApplied) {
      this.setState({
        isSaving: true
      });

      const roomTypePayload = [];
      const unitPayload = [];

      const defaultPayload = {
        startDate: dateRange[0].format(DATE_FORMAT),
        endDate: dateRange[1].format(DATE_FORMAT),
        selectedDays
      };

      const foundRoomTypes = rates.filter(roomType => roomTypesApplied.includes(roomType.id));

      for (let i = 0; i < foundRoomTypes.length; i++) {
        const foundRoomType = foundRoomTypes[i];

        for (let j = 0; j < foundRoomType.rates.length; j++) {
          const rate = foundRoomType.rates[j];

          if (!rate.isDerived) {
            const price = roomTypesRate[rate.source];

            if (!!price) {
              roomTypePayload.push({ ...defaultPayload, roomTypeId: foundRoomType.id, rateId: rate.id, price });
            }
          }
        }
      }

      const appliedUnits = foundRoomTypes
        .map(foundRoomType => foundRoomType.units)
        .flat()
        .filter(unit => unitsApplied.includes(unit.id));

      for (let i = 0; i < appliedUnits.length; i++) {
        const unit = appliedUnits[i];

        for (let j = 0; j < unit.rates.length; j++) {
          const rate = unit.rates[j];

          if (!rate.isDerived) {
            const price = unitsRate[rate.source];

            if (!!price) {
              unitPayload.push({ ...defaultPayload, unitId: unit.id, rateId: rate.id, price });
            }
          }
        }
      }

      updateCalendarBulk([...roomTypePayload, ...unitPayload])
        .then(() => {
          this.setState({ isSaving: false, isDataSaveBefore: true });
          shouldCloseModal && onSuccess();
          message.success(intl.get('multicalendar.message.updateRateSuccess').d('Successfully updated Calendar Rates!'));
        })
        .catch(e => message.error(e.message));
    } else {
      shouldCloseModal && onCancel();
    }
  };

  handleOnCancel = () => {
    const { isDataSaveBefore } = this.state;
    const { onSuccess, onCancel } = this.props;
    if (isDataSaveBefore) {
      // So we can properly reload data after user click cancel if data was saved before
      onSuccess();
    } else {
      onCancel();
    }
  };

  render() {
    const { title, isShow, form, defaultStartDate, defaultEndDate, defaultRoomType, defaultUnit } = this.props;
    const { daysOfWeek, selectedPropertyName, selectedDays, rates, rateTypes, isRateLoading, isSaving } = this.state;
    console.log(rates, 307);
    return (
      <Modal
        style={{ top: 20 }}
        title={`${title} ${intl.get('multicalendar.headerLabels.forProperty').d('for Property:')} ${selectedPropertyName}`}
        visible={isShow}
        destroyOnClose={true}
        width={1000}
        confirmLoading={isSaving}
        onCancel={this.handleOnCancel}
        footer={[
          <Button id="close-button1-ruw" key="back" onClick={this.handleOnCancel}>
            {intl.get('multicalendar.headerLabels.close').d('Close')}
          </Button>,
          <Button id="save-button1-ruw" key="submit" loading={isSaving} onClick={this.handleOnSubmit(false)}>
            {intl.get('multicalendar.headerLabels.save').d('Save')}
          </Button>,
          <Button id="saveclose-button1-ruw" key="submitAndSave" type="primary" loading={isSaving} onClick={this.handleOnSubmit(true)}>
            {intl.get('multicalendar.headerLabels.saveClose').d('Save and Close')}
          </Button>
        ]}
      >
        <Form onSubmit={this.handleOnSubmit}>
          {isRateLoading ? (
            <Skeleton loading={isRateLoading} />
          ) : (
            <MCRateUpdateBody
              form={form}
              roomTypesWithRatesAndUnits={rates}
              rateTypes={rateTypes}
              daySelections={daysOfWeek}
              selectedDays={selectedDays}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              defaultRoomType={defaultRoomType}
              defaultUnit={defaultUnit}
              onSelectedDayChange={this.handleSelectedDayChange}
              currency={this.props.currency}
              timezone={this.props.timezone}
            />
          )}
        </Form>
      </Modal>
    );
  }
}

MCModalRateUpdateWizard.propTypes = {
  form: PropTypes.object.isRequired,
  isShow: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  selectedPropertyId: PropTypes.string.isRequired,
  selectedRateFilters: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  currency: PropTypes.string,
  timezone: PropTypes.string
};

MCModalRateUpdateWizard.defaultProps = {
  form: {},
  isShow: false,
  onCancel: () => {},
  onSuccess: () => {},
  selectedRateFilters: [],
  title: 'Update Rate Wizard'
};

export default withRouter(Form.create()(MCModalRateUpdateWizard));
