import React, { Component } from 'react';
import { Modal, Button, Input, Row, Col, message } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { createReservation } from 'utils/apis/reservation';
import MCModalSetPrices from '../MCModalSetPrices/MCModalSetPrices';
import styles from './MCModalRateDayAction.module.css';
import { withAppContext } from 'context/AppContext';
import intl from 'react-intl-universal';

const MODALACTION_BLOCK = 'Block';
const MODALACTIION_SETPRICES = 'SetPrices';
const MODALACTIION_BACKTOMAIN = '';
const BOOKINGTYPE_MAINTENANCE = 5;
const BOOKINGTYPE_BLOCK = 6;

const DATE_FORMAT = 'YYYY-MM-DD';

class MCModalRateDayAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAction: '',

      remarks: ''
    };
  }

  generateLinkToBookingForm = (selectedPropertyId, roomTypeId, unitId, clickedStartDate, clickedEndDate) => {
    const startDate = clickedStartDate.format(DATE_FORMAT);
    const endDate = clickedEndDate.add(1, 'days').format(DATE_FORMAT);

    const propertyQuery = `property=${selectedPropertyId}`;
    const roomTypeQuery = `roomType=${roomTypeId}`;
    const unitQuery = `unit=${unitId}`;
    const startDateQuery = `startDate=${startDate}`;
    const endDateQuery = `endDate=${endDate}`;

    const queries = [propertyQuery, roomTypeQuery, unitQuery, startDateQuery, endDateQuery];
    const queriesToString = queries.join('&').toString();

    const link = `/bookingform?${queriesToString}`;

    return link;
  };

  blockMaintenance = (event, bookingType, unitId, roomTypeId, remarks, clickedStartDate, clickedEndDate) => {
    event.preventDefault();
    const { onModalRateDayActionCancel, handleOnMCRateDateActionSuccess } = this.props;
    const clickedStartDateInString = clickedStartDate.format(DATE_FORMAT);
    const clickedEndDateInString = clickedEndDate.add(1, 'days').format(DATE_FORMAT);

    createReservation({
      startDate: clickedStartDateInString,
      endDate: clickedEndDateInString,
      bookingType,
      unit: unitId,
      remarks: remarks
    })
      .then(res => {
        if (res && res.status === 201) {
          handleOnMCRateDateActionSuccess({
            currentRoomTypeId: roomTypeId,
            selectedDate: clickedStartDate
          });
        } else {
          message.error(intl.get('multicalendar.message.datesCouldNotBlock').d('Dates could not be blocked!'));
          onModalRateDayActionCancel();
        }
      })
      .catch(err => {});
  };

  drawModalClashedReservation = () => (
    <span>
      {intl.get('multicalendar.headerLabels.resClash').d('A reservation is already made within the selected time frame, please choose another date.')}
    </span>
  );

  drawModalMain = (selectedPropertyId, roomTypeId, unitId, isRoomType, clickedStartDate, clickedEndDate) => {
    const { onRateUpdateWizardButtonClick, clickedStartDateInString, clickedEndDateInString, checkIsAdminReadOnly } = this.props;

    const linkToBookingForm = isRoomType
      ? ''
      : this.generateLinkToBookingForm(selectedPropertyId, roomTypeId, unitId, clickedStartDate, clickedEndDate);

    return (
      <Row type="flex" gutter={12} justify="space-around">
        <Col className={styles.modalMainItem} span={24} md={6}>
          <Button
            id="block-button1-unitcal"
            className={styles.modalMainButton}
            type="primary"
            disabled={isRoomType || checkIsAdminReadOnly()}
            onClick={() => {
              this.handleOnClickAction(MODALACTION_BLOCK);
            }}
            style={{ whiteSpace: 'normal', overflowWrap: 'break-word', height: '50px' }}
          >
            {intl.get('multicalendar.headerLabels.blockDate').d('Block Dates')}
          </Button>
        </Col>

        <Col className={styles.modalMainItem} span={24} md={6}>
          <Link to={linkToBookingForm}>
            <Button
              id="create-button1-unitcal"
              style={{ whiteSpace: 'normal', overflowWrap: 'break-word', height: '50px' }}
              className={styles.modalMainButton}
              type="primary"
              disabled={isRoomType || checkIsAdminReadOnly()}
            >
              {intl.get('multicalendar.headerLabels.createReservation').d('Create Reservation')}
            </Button>
          </Link>
        </Col>

        {this.props.checkAbleEditRoomRates() && (
          <>
            <Col className={styles.modalMainItem} span={24} md={6}>
              <Button
                id="price-button1-unitcal"
                className={styles.modalMainButton}
                type="primary"
                disabled={checkIsAdminReadOnly()}
                onClick={() => {
                  this.handleOnClickAction(MODALACTIION_SETPRICES);
                }}
                style={{ whiteSpace: 'normal', overflowWrap: 'break-word', height: '50px' }}
              >
                {intl.get('multicalendar.headerLabels.setPrice').d('Set Prices')}
              </Button>
            </Col>

            <Col className={styles.modalMainItem} span={24} md={6}>
              <Button
                id="ruw-button2-unitcal"
                className={styles.modalMainButton}
                type="primary"
                disabled={checkIsAdminReadOnly()}
                onClick={e => {
                  e.preventDefault();
                  onRateUpdateWizardButtonClick(clickedStartDateInString, clickedEndDateInString);
                }}
                style={{ whiteSpace: 'normal', overflowWrap: 'break-word', height: '50px' }}
              >
                {intl.get('multicalendar.headerLabels.rateUpdateWizard').d('Rate Update Wizard')}
              </Button>
            </Col>
          </>
        )}
      </Row>
    );
  };

  drawModalBlock = (unitId, roomTypeId, remarks, clickedStartDate, clickedEndDate) => {
    const { checkIsAdminReadOnly } = this.props;

    return (
      <Row>
        <div className={`${styles.modalBlockDatesRemarks}`}>
          {intl.get('multicalendar.headerLabels.reason').d('Please fill in a reason (Optional):')}
          <Input name="remarks" value={remarks} onChange={this.handleOnRemarksChange} />
        </div>
        <Row className={styles.modalBlockDatesButtons}>
          <Button
            id="block-button1a-blockdate"
            type="danger"
            disabled={checkIsAdminReadOnly()}
            onClick={e => this.blockMaintenance(e, BOOKINGTYPE_BLOCK, unitId, roomTypeId, remarks, clickedStartDate, clickedEndDate)}
          >
            {intl.get('multicalendar.headerLabels.block').d('Block')}
          </Button>
          <Button
            id="block-button1b-blockdate"
            type="danger"
            disabled={checkIsAdminReadOnly()}
            onClick={e => this.blockMaintenance(e, BOOKINGTYPE_MAINTENANCE, unitId, roomTypeId, remarks, clickedStartDate, clickedEndDate)}
          >
            {intl.get('multicalendar.headerLabels.maintanence').d('Maintenance')}
          </Button>
          <Button onClick={() => this.handleOnClickAction(MODALACTIION_BACKTOMAIN)}>{intl.get('multicalendar.headerLabels.back').d('Back')}</Button>
        </Row>
      </Row>
    );
  };

  drawModalSetPrices = (unitId, roomTypeId, rates, clickedStartDateInString, clickedEndDateInString) => {
    const { handleOnMCRateDateActionSuccess } = this.props;

    const combineRatesObj = {};
    Object.keys(rates).forEach(key => {
      rates[key].forEach(rate => {
        if (Object.keys(combineRatesObj).includes(rate._id)) {
          combineRatesObj[rate._id].ota.push(key);
        } else {
          combineRatesObj[rate._id] = {
            ...rate,
            ota: [key]
          };
        }
      });
    });
    const combineRates = Object.values(combineRatesObj);

    return (
      <MCModalSetPrices
        combineRates={combineRates}
        clickedUnit={unitId}
        clickedRoomType={roomTypeId}
        startDate={clickedStartDateInString}
        endDate={clickedEndDateInString}
        handleOnMCModalSetPrices={handleOnMCRateDateActionSuccess}
        currency={this.props.currency}
        timezone={this.props.timezone}
      />
    ); //need pass more things here
  };

  handleOnClickAction = action => {
    this.setState({ modalAction: action });
  };

  handleOnRemarksChange = event => {
    this.setState({ remarks: event.target.value });
  };

  handleOnModalRateDayActionCancel = () => {
    const { onModalRateDayActionCancel } = this.props;

    this.setState({ modalAction: '' });

    onModalRateDayActionCancel();
  };

  render() {
    const { modalAction, remarks } = this.state;

    const {
      selectedPropertyId,

      roomTypeId,
      unitId,
      isRoomType,

      rates,

      clickedStartDateInString,
      clickedEndDateInString,

      showModalRateDayAction,
      isClashedReservationOnHightlight
    } = this.props;

    const clickedStartDate = moment(clickedStartDateInString);
    const clickedEndDate = moment(clickedEndDateInString);
    const formattedStartDate = clickedStartDate.format('dddd, YYYY-MM-DD');
    const [dayOfWeekStart, yyyyMMddStart] = formattedStartDate.split(', ');
    const formattedEndDate = clickedEndDate.format('dddd, YYYY-MM-DD');
    const [dayOfWeekEnd, yyyyMMddEnd] = formattedEndDate.split(', ');
    const title = intl
      .get('multicalendar.headerLabels.actionTitle', {
        startDate: intl.get(`hostConnect.integration.days.${dayOfWeekStart}`.d(dayOfWeekStart)) + ' ' + yyyyMMddStart,
        endDate: intl.get(`hostConnect.integration.days.${dayOfWeekEnd}`.d(dayOfWeekEnd)) + ' ' + yyyyMMddEnd
      })
      .d(`Action for ${clickedStartDate.format('dddd, YYYY-MM-DD')} to ${clickedEndDate.format('dddd, YYYY-MM-DD')}`);
    const isClashed = isClashedReservationOnHightlight;

    return (
      <Modal
        width={700}
        title={title}
        visible={showModalRateDayAction}
        onCancel={this.handleOnModalRateDayActionCancel}
        footer={null}
        destroyOnClose={true}
      >
        {isClashed && this.drawModalClashedReservation()}
        {!isClashed && !modalAction && this.drawModalMain(selectedPropertyId, roomTypeId, unitId, isRoomType, clickedStartDate, clickedEndDate)}
        {!isClashed && modalAction === MODALACTION_BLOCK && this.drawModalBlock(unitId, roomTypeId, remarks, clickedStartDate, clickedEndDate)}
        {!isClashed &&
          modalAction === MODALACTIION_SETPRICES &&
          this.drawModalSetPrices(unitId, roomTypeId, rates, clickedStartDateInString, clickedEndDateInString)}
      </Modal>
    );
  }
}

export default withAppContext(MCModalRateDayAction);
