import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Divider } from 'antd';

import {
  SEPARATOR_DASH,
  RATE_DISTRIBUTION_TAB,
  RATE_DERIVATIVE,
  WEEKDAY_RATE,
  WEEKEND_RATE,
  RATE_DERIVATIVES,
  MODIFIER_TYPE,
  MODIFIER_SIGN,
  MODIFIER_AMOUNT,
  PAX_THRESHOLD,
  EXTRA_PAX_FEE
} from 'utils/constants';
import { generatePercentageFromDisplay, generateDisplayPercentage, calculateDerivedRate, checkHasValue } from 'utils/general';

import FormSelection from 'components/FormSelection/FormSelection';
import RateModifierFormItems from 'components/RateModifierFormItems/RateModifierFormItems';
import OccupancyRate from './components/OccupancyRate/OccupancyRate';
import RateFormInputNumber from './components/RateFormInputNumber/RateFormInputNumber';

import styles from './RateDistribution.module.css';
import intl from 'react-intl-universal';

class RateDistribution extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    roomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxCapacity: PropTypes.number,
    otaId: PropTypes.string,
    rate: PropTypes.object.isRequired,
    isOccupancyRateApplicable: PropTypes.bool,
    rateModifierTypeConstants: PropTypes.array.isRequired,
    rateModifierSignConstants: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired
  };

  static defaultProps = {
    roomId: '',
    otaId: '',
    isOccupancyRateApplicable: false
  };

  constructor(props) {
    super(props);
    const { roomId, otaId, rate } = props;
    const rateId = rate._id || '';
    const sep = SEPARATOR_DASH;

    this.state = {
      fieldNameRateDerivative: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${RATE_DERIVATIVE}`,
      fieldNameModifierType: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${MODIFIER_TYPE}`,
      fieldNameModifierSign: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${MODIFIER_SIGN}`,
      fieldNameModifierAmount: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${MODIFIER_AMOUNT}`,
      fieldNameWeekdayRate: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${WEEKDAY_RATE}`,
      fieldNameWeekendRate: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${WEEKEND_RATE}`,
      fieldNamePaxThreshold: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${PAX_THRESHOLD}`,
      fieldNameExtraPaxFee: `${RATE_DISTRIBUTION_TAB}${sep}${roomId}${sep}${otaId}${sep}${rateId}${sep}${EXTRA_PAX_FEE}`,
      hasOccupancyRate: false
    };
  }

  componentDidMount = () => {
    const { rate, occupancyRate } = this.props;

    this.setState({ hasOccupancyRate: !!occupancyRate });
    this.setOccupancyRate(occupancyRate);
    this.setRate({ isDefaultToRateValue: true, isDerived: rate.isDerived });
  };

  setOccupancyRate = ({ noOfPaxThreshold, feePerExtraPax } = {}) => {
    const { form } = this.props;
    const { fieldNamePaxThreshold, fieldNameExtraPaxFee } = this.state;

    const payload = {};

    if (checkHasValue(noOfPaxThreshold)) {
      payload[fieldNamePaxThreshold] = noOfPaxThreshold;
    }
    if (checkHasValue(feePerExtraPax)) {
      payload[fieldNameExtraPaxFee] = feePerExtraPax;
    }

    form.setFieldsValue(payload);
  };

  setRate = ({ isDefaultToRateValue, isDerived, selectedType, selectedSign, selectedAmount }) => {
    const { fieldNameModifierType, fieldNameModifierSign, fieldNameModifierAmount, fieldNameWeekdayRate, fieldNameWeekendRate } = this.state;
    const { form, rate, rateModifierTypeConstants, rateModifierSignConstants } = this.props;

    const weekdayRate = rate.weekday;
    const weekendRate = rate.weekend;
    const rateType = rate.calculation.type;
    const rateIsPositive = rate.calculation.isPositive;
    const rateAmount = rate.calculation.amount;
    const parentWeekday = rate.calculation.parent.weekday;
    const parentWeekend = rate.calculation.parent.weekend;

    let {
      [fieldNameModifierType]: modifierType,
      [fieldNameModifierSign]: modifiersign,
      [fieldNameModifierAmount]: modifierAmount
    } = form.getFieldsValue([fieldNameModifierType, fieldNameModifierSign, fieldNameModifierAmount]);

    if (isDefaultToRateValue) {
      const isRatePercentage = !!rateModifierTypeConstants.find(type => type.code === rateType && type.isPercentage);

      modifierType = rateType;
      modifiersign = rateModifierSignConstants.find(sign => sign.isPositive === rateIsPositive).code;
      modifierAmount = isRatePercentage ? generateDisplayPercentage(rateAmount, false) : rateAmount;
    }

    const calcType = selectedType || modifierType || rateType;
    const isPercentage = !!rateModifierTypeConstants.find(type => type.code === calcType && type.isPercentage);
    const calcSign = selectedSign || modifiersign;
    const isPositive = !!calcSign ? !!rateModifierSignConstants.find(sign => sign.code === calcSign && sign.isPositive) : rateIsPositive;
    selectedAmount = !!selectedAmount || selectedAmount === 0 ? selectedAmount : modifierAmount;

    const calcAmount = isPercentage ? generatePercentageFromDisplay(selectedAmount) : selectedAmount;

    const { weekdayRate: resultWeekday, weekendRate: resultWeekend } = calculateDerivedRate({
      weekdayRate,
      weekendRate,
      isDerived,
      isPercentage,
      isPositive,
      calcAmount,
      parentWeekday,
      parentWeekend
    });

    form.setFieldsValue({
      [fieldNameWeekdayRate]: resultWeekday,
      [fieldNameWeekendRate]: resultWeekend
    });
  };

  handleOnRateDerivativeChange = selectedDerivative => {
    const isDerived = !!RATE_DERIVATIVES.find(derivative => derivative.code === selectedDerivative && derivative.isDerived);
    this.setRate({ isDefaultToRateValue: isDerived, isDerived });
  };

  handleOnTypeChange = selectedType => {
    this.setRate({ isDerived: true, selectedType });
  };

  handleOnSignChange = selectedSign => {
    this.setRate({ isDerived: true, selectedSign });
  };

  handleOnAmountChange = selectedAmount => {
    this.setRate({ isDerived: true, selectedAmount });
  };

  handleOnOccupancyRateChange = e => {
    this.setState({ hasOccupancyRate: e.target.value });
  };

  render() {
    const {
      fieldNameRateDerivative,
      fieldNameModifierType,
      fieldNameModifierSign,
      fieldNameModifierAmount,
      fieldNameWeekdayRate,
      fieldNameWeekendRate,
      fieldNamePaxThreshold,
      fieldNameExtraPaxFee,
      hasOccupancyRate
    } = this.state;
    const { form, maxCapacity, rate, otaId, isOccupancyRateApplicable, rateModifierTypeConstants, rateModifierSignConstants } = this.props;
    const { [fieldNameRateDerivative]: selectedRateDerivative, [fieldNameModifierType]: selectedAdjustmentType } = form.getFieldsValue([
      fieldNameRateDerivative,
      fieldNameModifierType
    ]);

    const rateDerivative = RATE_DERIVATIVES.find(derivative =>
      !!selectedRateDerivative ? derivative.code === selectedRateDerivative : !!rate ? derivative.isDerived === rate.isDerived : derivative.isDefault
    );
    const isDerived = !!rateDerivative && rateDerivative.isDerived;
    const rateDerivativeCode = !!rateDerivative && rateDerivative.code;

    const selectedRateCalculation = (!!rate && rate.calculation) || undefined;

    const adjustmentTypeObject = rateModifierTypeConstants.find(
      type => type.code === (selectedAdjustmentType || (selectedRateCalculation && selectedRateCalculation.type)) || type.isDefault
    );

    const isPercentageType = !!adjustmentTypeObject && adjustmentTypeObject.isPercentage;
    const defaultAdjustmentType = !!adjustmentTypeObject ? adjustmentTypeObject.code : '';

    const adjustmentSignObject = rateModifierSignConstants.find(sign =>
      !!selectedRateCalculation ? sign.isPositive === selectedRateCalculation.isPositive : sign.isDefault
    );
    const defaultAdjustmentSign = !!adjustmentSignObject ? adjustmentSignObject.code : '';

    const defaultRateAdjustmentAmount = !!selectedRateCalculation ? selectedRateCalculation.amount : 0;

    return (
      <Card className={styles.card} title={`${rate.name ? `${rate.name} | ` : ''}${otaId}`} type="inner">
        <Row>
          <FormSelection
            form={form}
            name={fieldNameRateDerivative}
            label={intl.get('hostConnect.integration.headerLabels.rateDerivative').d('Rate Derivative')}
            placeholder={intl.get('hostConnect.integration.placeholder.rateDerivative').d('Please select a rate derivative')}
            defaultValue={rateDerivativeCode}
            selections={RATE_DERIVATIVES}
            onSearch={() => {}}
            onChange={this.handleOnRateDerivativeChange}
          />
        </Row>

        {isDerived && (
          <Row>
            <RateModifierFormItems
              form={form}
              title={
                <span className={styles.rateModifierFormItemsTitle}>
                  {intl.get('hostConnect.integration.headerLabels.rateAdjustment').d('Rate Adjustment')}{' '}
                </span>
              }
              isPercentage={isPercentageType}
              modifierTypeName={fieldNameModifierType}
              defaultModifierType={defaultAdjustmentType}
              modifierSignName={fieldNameModifierSign}
              defaultModifierSign={defaultAdjustmentSign}
              modifierAmountName={fieldNameModifierAmount}
              defaultRateModifierAmount={defaultRateAdjustmentAmount}
              minValue={0}
              rateModifierTypeConstants={rateModifierTypeConstants}
              rateModifierSignConstants={rateModifierSignConstants}
              onTypeChange={this.handleOnTypeChange}
              onSignChange={this.handleOnSignChange}
              onAmountChange={this.handleOnAmountChange}
            />
          </Row>
        )}

        <Row gutter={8}>
          <Col span={12}>
            <RateFormInputNumber
              form={form}
              name={fieldNameWeekdayRate}
              label={intl.get('hostConnect.integration.headerLabels.weekday').d('Weekday')}
              isDisabled={isDerived}
              currency={this.props.currency}
            />
          </Col>

          <Col span={12}>
            <RateFormInputNumber
              form={form}
              name={fieldNameWeekendRate}
              label={intl.get('hostConnect.integration.headerLabels.weekend').d('Weekend')}
              isDisabled={isDerived}
              currency={this.props.currency}
            />
          </Col>
        </Row>

        {isOccupancyRateApplicable && (
          <>
            <Divider />

            <OccupancyRate
              form={form}
              hasOccupancyRate={hasOccupancyRate}
              onChange={this.handleOnOccupancyRateChange}
              maxCapacity={maxCapacity}
              fieldNames={{ fieldNameWeekdayRate, fieldNameWeekendRate, fieldNamePaxThreshold, fieldNameExtraPaxFee }}
            />
          </>
        )}
      </Card>
    );
  }
}

export default RateDistribution;
