import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Icon, Button, ConfigProvider } from 'antd';
import { debounce } from 'lodash';
import intl from 'react-intl-universal';
import zhCN from 'antd/lib/locale/zh_CN';

class MonthPicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    className: '',
    label: '',
    disabled: false,
    todayButtonId: '',
    onChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      date: props.defaultValue || moment()
    };
  }

  handleOnPreviousMonthClick = () => {
    const { date } = this.state;
    const newDate = date.subtract(1, 'month').startOf('month');
    this.handleOnMonthChange(newDate);
  };

  handleOnNextMonthClick = () => {
    const { date } = this.state;
    const newDate = date.add(1, 'month').startOf('month');
    this.handleOnMonthChange(newDate);
  };

  handleOnTodayClick = () => {
    const newDate = moment().startOf('month');
    this.handleOnMonthChange(newDate);
  };

  handleOnMonthChange = date => {
    date.set('date', 1);
    this.setState({
      date
    });
    this.onDateChange(date);
  };

  componentDidUpdate(prevProp) {
    if (this.props.defaultValue !== prevProp.defaultValue) {
      this.setState({ date: this.props.defaultValue });
    }
  }

  onDateChange = debounce(date => {
    const { onChange } = this.props;
    onChange(date);
  }, 300);

  render() {
    const { date } = this.state;
    const { className, label, disabled, todayButtonId } = this.props;

    return (
      <div className={className}>
        {label && <label className="el-pageTitle">{label} </label>}
        <Button disabled={disabled} onClick={this.handleOnPreviousMonthClick}>
          <Icon type="backward" />
        </Button>
        <DatePicker.MonthPicker
          style={{ width: '125px' }}
          allowClear={false}
          value={date}
          onChange={this.handleOnMonthChange}
          placeholder="Select month"
          disabled={disabled}
        />
        <Button disabled={disabled} onClick={this.handleOnNextMonthClick}>
          <Icon type="forward" />
        </Button>
        <Button id={todayButtonId} disabled={disabled} type="secondary" onClick={this.handleOnTodayClick}>
          {intl.get('expenses.headerLabels.today').d('Today')}
        </Button>
      </div>
    );
  }
}

export default MonthPicker;
