import React from 'react';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import { getIntegrationTypesConstant } from 'utils/general';

import styles from './Filter.module.css';
import intl from 'react-intl-universal';

const { LISTING_TYPE } = getIntegrationTypesConstant();

const Filter = ({ type, propertyOptions, unitOptions, selectedPropertyId, selectedUnitId, onChangeProperty, onChangeUnit }) => {
  const isListingIntegration = type === LISTING_TYPE.code;

  return (
    <div className={styles.selectionRow}>
      <BasicSelect
        label={intl.get('hostConnect.integration.headerLabels.property').d('Property')}
        selections={propertyOptions}
        value={selectedPropertyId}
        placeholder={intl.get('hostConnect.integration.placeholder.property').d('There is no property available')}
        className={isListingIntegration ? styles.selectionListing : styles.selectionHotel}
        selectClassName={styles.selectionBody}
        onChange={onChangeProperty}
      />
      {isListingIntegration && (
        <BasicSelect
          label={intl.get('hostConnect.integration.headerLabels.unit').d('Unit')}
          selections={unitOptions}
          value={selectedUnitId}
          placeholder={intl.get('hostConnect.integration.placeholder.unit').d('This property has no unit')}
          className={styles.selectionListing}
          selectClassName={styles.selectionBody}
          onChange={onChangeUnit}
        />
      )}
    </div>
  );
};

export default Filter;
