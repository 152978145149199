import React, { useContext } from 'react';
import { Layout, Select, Dropdown, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons'; // Import the icon you want to use
import './SingleViewLayout.css';
import Footer from './../../components/Footer/Footer';
import { LangContext } from '../../utils/LocaleProvider';
import { languageOptions } from '../../utils/constants';

const { Content } = Layout;

const SingleViewLayout = props => {
  const context = useContext(LangContext);

  const handleMenuClick = e => {
    context.onSelectLocale(e.key); // Pass the selected language key to the parent component
  };

  const langMenu = (
    <Menu onClick={handleMenuClick} defaultSelectedKeys={[context.currentLocale]}>
      {languageOptions.map(option => (
        <Menu.Item key={option.key}>
          <img src={option.flagUrl} alt={option.label} style={{ marginRight: '15px', width: '20px', height: 'auto' }} />
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Layout className={props.className + ' single-view-layout-bg'}>
      <div className="language-dropdown">
        <Dropdown overlay={langMenu} placement="bottomRight">
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <GlobalOutlined style={{ fontSize: '25px' }} /> {/* Use the icon for the language button */}
          </a>
        </Dropdown>
      </div>
      <Content>{props.children}</Content>
      <Footer />
    </Layout>
  );
};

export default SingleViewLayout;
