import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import { REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS } from 'config/env';

import {
  SEPARATOR_DASH,
  STANDARD_RATE_CODE,
  RATE_DISTRIBUTION_TAB,
  RATE_DERIVATIVE,
  RATE_DERIVATIVES,
  PRICING_TAB,
  CLEANING_FEE,
  CHARGE_TYPE
} from 'utils/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';
import AdvancedOptionsBookingcomPricingTab from '../../../../AdvancedOptionsPricing/AdvancedOptionsBookingcomPricingTab';
import intl from 'react-intl-universal';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => String(room.roomId) === String(roomId));

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: {} };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }
  return payload;
};

const getPricingSettingPayload = (fieldValues, pricingSetting) => {
  if (!REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS) {
    return {};
  }

  const decimalPlaces = (pricingSetting && pricingSetting.decimalPlaces) || 0;

  const cleaningFee = Number(fieldValues[`${PRICING_TAB}${SEPARATOR_DASH}${CLEANING_FEE}`]) * Number(10 ** decimalPlaces);
  const chargeType = fieldValues[`${PRICING_TAB}${SEPARATOR_DASH}${CHARGE_TYPE}`];

  return {
    cleaningFee,
    chargeType
  };
};

class BookingcomAdvancedOptionsModal extends Component {
  static propTypes = {
    roomMapping: PropTypes.array.isRequired,
    property: PropTypes.object.isRequired,
    rateModifierTypeConstants: PropTypes.array.isRequired,
    rateModifierSignConstants: PropTypes.array.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isVisible: false,
    onConfirm: () => {},
    onClose: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,

      roomsOfRates: [],
      policies: {},
      pricingSetting: {}
    };
  }

  componentDidMount = async () => {
    const { roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants, retrievedPricing } = this.props;
    let { pricingSetting } = this.props;

    if (pricingSetting.cleaningFee === undefined && retrievedPricing.cleaningFee) {
      pricingSetting = retrievedPricing;
    }

    this.setState({ isLoadingRateDistribution: true, pricingSetting });

    const roomsOfRates = await this.getRoomsOfRates({ roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ roomMapping, property, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      roomMapping.map(async room => {
        const roomType = !!property.roomTypes ? Object.values(property.roomTypes).find(roomType => room.roomType === roomType._id) : '';
        const roomName = !!roomType ? roomType.name : '';
        const roomId = !!roomType ? roomType._id : '';

        const rates = await getWebRateByRoomType(room.roomType);
        const webRate = rates.data.find(rate => rate.code === STANDARD_RATE_CODE);

        const roomRates = room.rates.map(rateObject => {
          const otaId = String(rateObject.bookingcomRateId) || '';
          const rateName = String(rateObject.bookingcomRateName) || '';
          let calculation = rateObject.rate.calculation;

          if (!rateObject.rate.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: webRate.weekday,
              weekend: webRate.weekend
            }
          };

          const rate = {
            isDerived: rateObject.rate.isDerived,
            weekday: rateObject.rate.weekday,
            weekend: rateObject.rate.weekend,
            calculation,
            name: rateName
          };

          return { otaId, rate };
        });

        return {
          roomId,
          roomName,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;
    const { pricingSetting } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();

        const payload = {
          rateDistributionPayload: getRateDistributionPayload(fieldValues),
          pricingSetting: getPricingSettingPayload(fieldValues, pricingSetting)
        };

        onConfirm(payload);
      }
    });
  };

  render() {
    const { isLoadingRateDistribution, roomsOfRates, pricingSetting } = this.state;
    const { form, isVisible, onClose } = this.props;
    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab={intl.get('hostConnect.integration.headerLabels.rateDistribution').d('Rate Distribution')} key="rateDistribution">
            <Skeleton loading={isLoadingRateDistribution} active>
              <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={this.props.currency} />
            </Skeleton>
          </TabPane>
          {REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS && (
            <TabPane tab={intl.get('hostConnect.integration.headerLabels.pricing').d('Pricing')} key="pricing">
              <AdvancedOptionsBookingcomPricingTab form={form} pricingSetting={pricingSetting} currency={this.props.currency} />
            </TabPane>
          )}
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

BookingcomAdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  roomMapping: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

BookingcomAdvancedOptionsModal.defaultProps = {
  isVisible: false,
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(BookingcomAdvancedOptionsModal);
