import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, Col, Form, Modal, message, notification, Row, Skeleton } from 'antd';

import { formatToMomentObject, getStartOfDay } from 'utils/date';
import { checkHasValue, constructTwoDecimalsRule, guard } from 'utils/general';
import { useGetUnitDetails, useGetUnitsWithoutPrimary, putUpdateUnitConfig } from 'utils/apis/unit';

import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';

import { StyledGeneralCard } from './UnitConfigModal.styles.js';
import intl from 'react-intl-universal';

const YES_NO_SELECTIONS = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
const ACTIVE_INACTIVE_SELECTIONS = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];

const useGetUnitsRelationSelection = (unitId, hostId) => {
  const postProcessGetUnitsWithoutPrimary = useCallback(units => {
    return units.map(unit => ({ value: unit._id, label: unit.name }));
  }, []);

  const { data: unitsPrimarySelection, isLoading: isUnitsPrimarySelectionLoading } = useGetUnitsWithoutPrimary(
    hostId,
    unitId,
    postProcessGetUnitsWithoutPrimary
  );

  return { unitsPrimarySelection, isUnitsPrimarySelectionLoading };
};

const GeneralCard = ({ form, unitTypes, subscriptionModes, unit, isFree, isYearlySubscription, onIsFreeButtonChange, onSubscriptionModeChange }) => {
  return (
    <StyledGeneralCard title="General Configuration">
      {/* <Alert message="The configuration changes made will only be updated on the following day." type="info" showIcon /> */}
      <Row gutter={16}>
        <Col span={24}>
          <FormInput form={form} name="name" formLabel="Unit Name" defaultValue={unit.name} disabled />
        </Col>
        <Col span={8}>
          <FormRadioButton
            form={form}
            formLabel="Free of Charge?"
            name="isFree"
            buttonStyle="solid"
            selections={YES_NO_SELECTIONS}
            defaultValue={unit.config.isFree}
            onChange={onIsFreeButtonChange}
            requiredErrorMessage="Please select if the unit is free."
          />
        </Col>
        <Col span={16}>
          <FormRadioButton
            form={form}
            formLabel="Unit Status"
            name="isActive"
            buttonStyle="solid"
            selections={ACTIVE_INACTIVE_SELECTIONS}
            defaultValue={unit.config.isActive}
            requiredErrorMessage="Please select if the unit is active."
          />
        </Col>
        <Col span={24}>
          <FormSelection
            form={form}
            name="unitType"
            formLabel="Unit Identity"
            placeholder="Select a unit type"
            defaultValue={unit.config.unitType}
            selections={unitTypes}
            requiredErrorMessage="Please select a unit type."
          />
        </Col>
        <Col span={12}>
          <FormSelection
            form={form}
            name="subscriptionMode"
            formLabel="Renewal Type"
            placeholder="Select a renewal type"
            defaultValue={unit.config.subscriptionMode}
            selections={subscriptionModes}
            requiredErrorMessage="Please select a renewal type."
            onChange={onSubscriptionModeChange}
          />
        </Col>
        <Col span={12}>
          <FormInput
            form={form}
            name="subscriptionFee"
            formLabel="Renewal Fee"
            defaultValue={unit.config.subscriptionFee}
            addonBefore="RM"
            requiredErrorMessage="Please key in the renewal fee."
            extraRules={[constructTwoDecimalsRule()]}
            disabled={isFree}
          />
        </Col>
        <Col span={12}>
          <FormDatePicker
            form={form}
            name="renewalDate"
            formLabel="Renewal Date"
            defaultValue={formatToMomentObject(unit.config.renewalDate)}
            requiredErrorMessage={isYearlySubscription ? 'Renewal Date is require for yearly renewal type' : undefined}
            disabled={!isYearlySubscription}
          />
        </Col>
      </Row>
    </StyledGeneralCard>
  );
};

const RelationCard = ({ form, unitsPrimarySelection, unitRelationDetails }) => {
  const primaryUnit = unitRelationDetails.primary;
  const parentUnit = unitRelationDetails.parent;

  return (
    <Card title="Primary-Room Configuration">
      <Row>
        <Col span={24} style={{ marginTop: 16 }}>
          <FormSelection
            form={form}
            name="primary"
            formLabel="Assign a primary unit"
            placeholder="assign a primary unit"
            defaultValue={guard(() => primaryUnit._id)}
            selections={unitsPrimarySelection}
          />
        </Col>
      </Row>
      {parentUnit && (
        <Row>
          <Col>
            <b>Parent Unit:</b> {parentUnit.name}
          </Col>
        </Row>
      )}
    </Card>
  );
};

const UnitConfigModal = ({ form, unitId, hostId, onCancel, onOk, unitTypes, subscriptionModes }) => {
  const [isFreeState, setIsFreeState] = useState();
  const [subscriptionModeState, setSubscriptionModeState] = useState();

  const { unitsPrimarySelection, isUnitsPrimarySelectionLoading } = useGetUnitsRelationSelection(unitId, hostId);

  const { data: unit, isLoading: isUnitLoading, refetch } = useGetUnitDetails(unitId, { isIncludeUnitRelation: true });

  const isLoading = useMemo(() => isUnitLoading || isUnitsPrimarySelectionLoading, [isUnitLoading, isUnitsPrimarySelectionLoading]);

  const isFree = useMemo(() => (checkHasValue(isFreeState) ? isFreeState : guard(() => unit.config.isFree)), [isFreeState, unit]);
  const subscriptionMode = useMemo(() => subscriptionModeState || guard(() => unit.config.subscriptionMode), [subscriptionModeState, unit]);
  const isYearlySubscription = useMemo(
    () => guard(() => subscriptionMode === subscriptionModes.find(subscriptionModeConst => subscriptionModeConst.isYearly).code, false),
    [subscriptionModes, subscriptionMode]
  );

  const handleOnUpdate = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        Object.values(err).forEach(field => message.error(field.errors[0].message));
      } else {
        const unitConfig = {
          unitType: values.unitType,
          renewalDate: getStartOfDay(values.renewalDate),
          subscriptionMode: values.subscriptionMode,
          subscriptionFee: values.subscriptionFee,
          isFree: values.isFree,
          primary: values.primary,
          isActive: values.isActive
        };
        putUpdateUnitConfig(unitId, unitConfig).then(() => {
          notification.success({
            message: 'Success',
            description: "You've successfully updated this unit's configuration."
          });
          refetch();
          onOk();
        });
      }
    });
  };

  const handleOnIsFreeButtonChange = e => {
    const isFree = e.target.value;
    setIsFreeState(isFree);

    if (isFree) {
      form.setFieldsValue({ subscriptionFee: '0.00' });
    }
  };

  const handleOnSubscriptionModeChange = subscriptionMode => {
    setSubscriptionModeState(subscriptionMode);

    if (guard(() => !(subscriptionMode === subscriptionModes.find(subscriptionModeConst => subscriptionModeConst.isYearly).code), false)) {
      form.setFieldsValue({ renewalDate: undefined });
    }
  };

  return (
    <Modal title="Unit Configuration" visible={true} okText="Update" onOk={handleOnUpdate} onCancel={onCancel} destroyOnClose>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Form>
          <Row>
            <GeneralCard
              form={form}
              unitTypes={unitTypes}
              subscriptionModes={subscriptionModes}
              unit={unit}
              isFree={isFree}
              isYearlySubscription={isYearlySubscription}
              onIsFreeButtonChange={handleOnIsFreeButtonChange}
              onSubscriptionModeChange={handleOnSubscriptionModeChange}
            />
            <RelationCard form={form} unitsPrimarySelection={unitsPrimarySelection} unitRelationDetails={unit.config.unitRelationDetails} />
          </Row>
        </Form>
      )}
    </Modal>
  );
};

UnitConfigModal.propTypes = {
  unitId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
};

export default Form.create()(UnitConfigModal);
