import React from 'react';
import { Card, Alert, Button, Row } from 'antd';
import PropTypes from 'prop-types';

import FormSelection from 'components/FormSelection/FormSelection';
import styles from './HostRelationshipCard.module.css';
import intl from 'react-intl-universal';

class HostRelationshipCard extends React.Component {
  render() {
    const { form, childrenList, children } = this.props;
    return (
      <Card title={intl.get('host.headerLabels.relationStatus').d('Relationship Status')} className={styles.hostRelationshipCard}>
        <Alert
          className={styles.notes}
          message={intl.get('host.headerLabels.tips').d('Tips')}
          description={
            <div>
              <p className={styles.notesText}>{intl.get('host.headerLabels.setup').d("Setup your host's multi level relationship here!")}</p>
            </div>
          }
          type="info"
          showIcon
        />
        <Card>
          <Row>
            <FormSelection
              name="children"
              label={intl.get('host.headerLabels.childHost').d('Child Host')}
              placeholder={intl.get('host.placeholder.chooseHost').d('Choose a host')}
              form={form}
              multipleMode
              defaultValue={children && children.map(child => child)}
              selections={childrenList.map(host => {
                return {
                  key: host._id,
                  value: host.name
                };
              })}
              size="large"
            />
          </Row>
          <Button
            onClick={() => {
              form.setFieldsValue({ children: [] });
            }}
            size="large"
          >
            {intl.get('host.headerLabels.removeChildren').d('Remove All Children')}
          </Button>
        </Card>
      </Card>
    );
  }
}

HostRelationshipCard.propTypes = {
  form: PropTypes.object.isRequired
};

export default HostRelationshipCard;
