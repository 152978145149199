import React from 'react';
import ProtectedRoute from 'utils/ProtectedRoute';
import { buildListingWizardUri, buildLWCreatePropertyUri, buildLWCreateRoomTypeUri, buildLWCreateUnitUri } from 'utils/routes';
import { Switch, Redirect } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';
import queryString from 'query-string';

import { getPropertiesByHostId, postCreateProperty } from 'utils/apis/property';
import { getUserRoomTypes, postCreateRoomType } from 'utils/apis/roomtype';
import { getUserHosts } from 'utils/apis/host';
import { postRate } from 'utils/apis/rate';
import { postCreateUnit } from 'utils/apis/unit';

import CreateProperty from './CreateProperty/CreateProperty';
import CreateRoomType from './CreateRoomType/CreateRoomType';
import CreateUnit from './CreateUnit/CreateUnit';
import Steps from './Steps/Steps';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import './ListingWizard.css';
import intl from 'react-intl-universal';
import { T } from 'antd/lib/upload/utils';

const convertObjectToArray = obj => Object.keys(obj).map(key => ({ ...obj[key], id: key }));

const getNextStepId = currentStep => {
  switch (currentStep) {
    case 'host':
      return 'property';
    case 'property':
      return 'roomType';
    case 'roomType':
      return 'unit';
    default:
      return '';
  }
};

class ListingWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currency: '',
      timezone: '',
      steps: {
        host: {
          title: 'Select your host',
          canCreate: false,
          enabled: true,
          progress: 0,
          selectedId: ''
        },
        property: {
          title: 'List your property',
          canCreate: true,
          createLink: buildLWCreatePropertyUri(),
          createQuery: '',
          enabled: false,
          progress: 0,
          selectedId: '',
          Component: CreateProperty,
          onStepFinish: this.proceedToFinishCreateProperty
        },
        roomType: {
          title: 'Set your room type',
          canCreate: true,
          createLink: buildLWCreateRoomTypeUri(),
          createQuery: '',
          enabled: false,
          progress: 0,
          selectedId: '',
          Component: CreateRoomType,
          onStepFinish: this.proceedToFinishCreateRoomType
        },
        unit: {
          title: 'Create your unit',
          canCreate: true,
          createLink: buildLWCreateUnitUri(),
          createQuery: '',
          enabled: false,
          progress: 0,
          selectedId: '',
          Component: CreateUnit,
          onStepFinish: this.proceedToFinishCreateUnit
        }
      }
    };
  }

  componentDidMount() {
    this.fetchUserHosts();
    this.fetchCurrentUserProperties();
    this.fetchCurrentUserRoomTypes();
  }

  proceedToFinishCreateProperty = payload => {
    const newPayload = {
      ...payload
    };
    return postCreateProperty(newPayload).then(res => {
      this.fetchCurrentUserProperties(newPayload.host).then(() => {
        this.setState(prevState => {
          return {
            steps: {
              ...prevState.steps,
              property: {
                ...prevState.steps.property,
                selectedId: res._id,
                progress: 100
              },
              roomType: {
                ...prevState.steps.roomType,
                createQuery: queryString.stringify({
                  propertyId: res._id
                }),
                enabled: true,
                selectedId: '',
                progress: 0,
                selections: []
              }
            }
          };
        });
      });
      return res;
    });
  };

  proceedToFinishCreateRoomType = payload => {
    const roomTypePayload = {
      ...payload
    };
    const { steps } = this.state;
    delete roomTypePayload.weekday;
    delete roomTypePayload.weekend;
    return postCreateRoomType(roomTypePayload).then(roomTypeRes => {
      this.fetchCurrentUserRoomTypes(payload.property);
      const ratePayload = {
        roomType: roomTypeRes._id,
        weekday: payload.weekday,
        weekend: payload.weekend,
        name: 'Web Rate',
        code: 'STD'
      };
      return postRate(ratePayload).then(rateRes => {
        this.setState(prevState => ({
          steps: {
            ...prevState.steps,
            roomType: {
              ...prevState.steps.roomType,
              selectedId: roomTypeRes._id,
              progress: 100
            },
            unit: {
              ...prevState.steps.unit,
              createQuery: queryString.stringify({
                roomTypeId: roomTypeRes._id,
                hostId: steps.host.selectedId
              }),
              enabled: true,
              selectedId: '',
              progress: 0
            }
          }
        }));
        return rateRes;
      });
    });
  };

  proceedToFinishCreateUnit = payload => {
    const unitPayload = {
      ...payload
    };
    return postCreateUnit(unitPayload).then(res => res);
  };

  updateStepWithApiData = (stepId, formattedSelections) => {
    this.setState(prevState => ({
      steps: {
        ...prevState.steps,
        [stepId]: {
          ...prevState.steps[stepId],
          selections: [...formattedSelections]
        }
      }
    }));
  };

  fetchCurrentUserProperties = selectedHostId => {
    return getPropertiesByHostId(selectedHostId)
      .then(res => {
        const formattedProperties = res.map(property => ({
          id: property._id,
          value: property.name
        }));

        this.updateStepWithApiData('property', formattedProperties);
      })
      .catch(err => console.error(err));
  };

  fetchCurrentUserRoomTypes = selectedPropertyId => {
    getUserRoomTypes({ propertyId: selectedPropertyId })
      .then(res => {
        const formattedProperties = res.map(property => ({
          id: property._id,
          value: property.name
        }));

        this.updateStepWithApiData('roomType', formattedProperties);
      })
      .catch(err => console.error(err));
  };

  fetchUserHosts = () => {
    getUserHosts()
      .then(res => {
        const formattedHost = res.map(host => ({
          id: host._id,
          value: host.name,
          currency: host.currency,
          timezone: host.timezone
        }));
        this.updateStepWithApiData('host', formattedHost);
      })
      .catch(err => console.error(err));
  };

  afterStepUpdate = (updatedStepId, updatedSelectionId) => {
    const nextStepId = getNextStepId(updatedStepId);
    if (nextStepId) {
      this.setState(prevState => {
        const newNextStepState = {
          enabled: true,
          selectedId: '',
          progress: 0
        };
        let resetAfterNextStep = {};

        switch (nextStepId) {
          case 'property':
            this.fetchCurrentUserProperties(updatedSelectionId);
            newNextStepState.createQuery = queryString.stringify({
              hostId: updatedSelectionId
            });
            resetAfterNextStep = {
              roomType: {
                ...prevState.steps.roomType,
                enabled: false,
                selectedId: '',
                progress: 0
              },
              unit: {
                ...prevState.steps.unit,
                enabled: false,
                selectedId: '',
                progress: 0
              }
            };
            break;
          case 'roomType':
            this.fetchCurrentUserRoomTypes(updatedSelectionId);
            newNextStepState.createQuery = queryString.stringify({
              propertyId: updatedSelectionId,
              currency: prevState.steps.host.currency,
              timezone: prevState.steps.host.timezone
            });
            break;
          case 'unit':
            newNextStepState.createQuery = queryString.stringify({
              roomTypeId: updatedSelectionId,
              hostId: prevState.steps.host.selectedId,
              currency: prevState.steps.host.currency,
              timezone: prevState.steps.host.timezone
            });
            break;
          default:
            break;
        }
        return {
          steps: {
            ...prevState.steps,
            ...resetAfterNextStep,
            [nextStepId]: {
              ...prevState.steps[nextStepId],
              ...newNextStepState
            }
          }
        };
      });
    }
  };

  handleOnStepSelectionChange = stepId => selectionId => {
    const { steps } = this.state;
    const step = steps[stepId];
    if (step) {
      const selectedSelection = step.selections.filter(selection => selection.id === selectionId)[0];

      if (selectedSelection) {
        const updatedStep = {
          ...step,
          selectedId: selectionId,
          progress: 100,
          ...(stepId === 'host'
            ? {
                currency: selectedSelection.currency,
                timezone: selectedSelection.timezone
              }
            : {
                currency: this.state.steps.host.currency,
                timezone: this.state.steps.host.timezone
              })
        };
        this.setState(prevState => ({
          steps: { ...prevState.steps, [stepId]: updatedStep }
        }));
        this.afterStepUpdate(stepId, selectionId);
      } else {
        console.error('Selection not found');
      }
    } else {
      console.error('Step is not found');
    }

    if (stepId && stepId === 'host') {
      const selectedSelection = step.selections.filter(selection => selection.id === selectionId)[0];
      this.setState({
        currency: selectedSelection.currency,
        timezone: selectedSelection.timezone
      });
    }
  };

  render() {
    const { steps } = this.state;
    const stepsArr = convertObjectToArray(steps);
    return (
      <div>
        <Switch>
          <ScrollToTop>
            <ProtectedRoute
              path={buildListingWizardUri()}
              render={props => <Steps {...props} steps={stepsArr} onSelectionChange={this.handleOnStepSelectionChange} />}
              exact
            />
            {stepsArr.map(({ canCreate, createLink, createQuery, Component, title, onStepFinish }, index) =>
              canCreate ? (
                <ProtectedRoute
                  key={title}
                  path={createLink}
                  query={createQuery}
                  render={props => (
                    <Component
                      {...props}
                      title={`${intl.get('listings.headerLabels.step').d('Step')} ${index + 1}: ${intl.get(`listings.title.${title}`).d(title)}`}
                      onFinish={onStepFinish}
                      currency={this.state.currency}
                      timezone={this.state.timezone}
                    />
                  )}
                />
              ) : null
            )}
          </ScrollToTop>
          <Redirect from="*" to="/404NotFound" />
        </Switch>
      </div>
    );
  }
}

export default withAppContext(ListingWizard);
