import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getHosts } from '../../../utils/apis/host';
import { getAgodaProduct, updateAgodaHost } from '../../../utils/apis/integration';
import { Select, Form, Radio, Input, Modal, notification } from 'antd';
import intl from 'react-intl-universal';

import './AgodaListingForm.css';

const Option = Select.Option;
const FormItem = Form.Item;

class AgodaListingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostOptions: [],
      isEdit: false,
      isSaving: false
    };
    this.isEdit = this.isEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount() {
    if (!this.props.hostOptions) {
      getHosts()
        .then(hostRes => {
          if (hostRes && hostRes.status === 200 && hostRes.data) {
            let hostOptions = hostRes.data.map(host => {
              return <Option value={host._id}>{host.name}</Option>;
            });
            this.setState({
              hostOptions
            });
          } else {
            // console.log('getHost have issues brah');
          }
        })
        .catch(ex => {
          // console.log(ex);
        });
    } else {
      this.setState({
        hostOptions: this.props.hostOptions
      });
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.agoda !== this.props.agoda) {
      let { setFieldsValue } = this.props.form;
      setFieldsValue({
        host: this.props.agoda ? this.props.agoda.host : '',
        isHomes: (this.props.agoda && this.props.agoda.isHomes) || false,
        agodaPropertyId: this.props.agoda && this.props.agoda.agodaPropertyId
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        isSaving: true
      });
      if (this.isEdit()) {
        let { agoda } = this.props;
        if (!agoda) {
          notification.error({
            message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
            description: (
              <div>
                <p>
                  <b>
                    {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.agodaPropertyId})
                  </b>
                </p>
                {intl.get('hostConnect.bookingcom.message.failDesc2').d('Try refresh the page')}
              </div>
            )
          });
        }
        updateAgodaHost(agoda._id, values.host, values.isHomes)
          .then(res => {
            this.setState({
              isSaving: false
            });
            if (this.props.refreshIntoEdit(agoda.agodaPropertyId)) {
              notification.success({
                message: `${intl.get('hostConnect.bookingcom.message.sucessMsg').d('Nice One')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.agoda.message.successAdd').d('Succesfully added Host to Agoda Hotel')}</b>
                    </p>
                  </div>
                )
              });
              this.props.hideModal();
            } else {
              notification.error({
                message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                description: (
                  <div>
                    <p>
                      <b>{intl.get('hostConnect.agoda.message.failedUpdate').d('Failed to update Agoda Hotel')}</b>
                    </p>
                    {intl
                      .get('hostConnect.bookingcom.message.updateFailDesc2')
                      .d('We are quite sure the hotel was updated succesfully, try refreshing the page. If problem presist, contact ')}{' '}
                    <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                      {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                    </a>
                    !
                  </div>
                )
              });
            }
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
              description: (
                <div>
                  <p>
                    <b>{intl.get('hostConnect.agoda.message.failedUpdate').d('Failed to update Agoda Hotel')}</b>
                  </p>
                  {intl.get('hostConnect.ctrip.message.updateFailDesc2').d('Try refreshing the page. If problem presist, contact ')}
                  <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                    {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                  </a>
                  !
                </div>
              )
            });
          });
      } else {
        notification.info({
          message: `${intl.get('hostConnect.agoda.message.asking').d('Asking Agoda nicely for Hotel Id')} (${values.agodaPropertyId})`
        });
        getAgodaProduct(values.agodaPropertyId)
          .then(res => {
            if (res && res.status === 200) {
              if (this.props.refreshIntoEdit(values.agodaPropertyId)) {
                notification.success({
                  message: `${intl.get('hostConnect.bookingcom.message.successRetrieveMsg').d('Succesfully retrieve Hotel')} (${
                    values.agodaPropertyId
                  })`,
                  description: <div>{intl.get('hostConnect.agoda.message.assignHost').d('Please assign a host to this Agoda Property')}</div>
                });
              } else {
                notification.error({
                  message: `${intl.get('hostConnect.bookingcom.message.failMsg').d('Oh Crap')}`,
                  description: (
                    <div>
                      <p>
                        <b>
                          {intl.get('hostConnect.bookingcom.message.failDesc1').d('Something Weird Happened')} ({values.agodaPropertyId})
                        </b>
                      </p>
                      {intl
                        .get('hostConnect.bookingcom.message.failRetrieveDesc')
                        .d('We are quite sure the hotel was retrieved succesfully, try refreshing the page. If problem presist, contact ')}
                      <a target="_blank" rel="noopener noreferrer" href="http://hostastay.com/helpdesk-cs/">
                        {intl.get('hostConnect.bookingcom.message.updateFailDesc3').d('tech support')}
                      </a>
                      !
                    </div>
                  )
                });
              }
            } else {
              notification.error({
                message: `${intl.get('hostConnect.agoda.message.failRetrieve').d('Failed to retrieve Agoda Hotel')}`,
                description: (
                  <div>
                    {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.agodaPropertyId}){' '}
                    {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                    <div>
                      {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fagoda-connect"
                      >
                        {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                      </a>
                      !
                    </div>
                  </div>
                )
              });
            }
            this.setState({
              isSaving: false
            });
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: `${intl.get('hostConnect.agoda.message.failRetrieve').d('Failed to retrieve Agoda Hotel')}`,
              description: (
                <div>
                  {intl.get('hostConnect.bookingcom.message.providedID').d('The provided hotel ID')} ({values.agodaPropertyId}){' '}
                  {intl.get('hostConnect.ctrip.message.failDesc2').d('is not associated with Host Platform.')}
                  <div>
                    {intl.get('hostConnect.ctrip.message.failDesc3').d('If you are sure it should, please contact ')}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fagoda-connect"
                    >
                      {intl.get('hostConnect.ctrip.message.failDesc4').d('customer support')}
                    </a>
                    !
                  </div>
                </div>
              )
            });
          });
      }
    });
  };

  isEdit() {
    let { agoda } = this.props;
    return !!agoda;
  }

  render() {
    let { agoda, visible, hideModal } = this.props;
    let { getFieldDecorator } = this.props.form;
    let { isSaving } = this.state;
    return (
      <Modal
        title={
          <React.Fragment>
            <div className="edit-title">
              {agoda
                ? `${intl.get('hostConnect.bookingcom.headerLabels.editing').d('Editing')} ${agoda.propertyData.name} (${agoda.agodaPropertyId})`
                : intl.get('hostConnect.agoda.headerLabels.new').d('New Agoda Property')}
            </div>
          </React.Fragment>
        }
        visible={visible}
        onOk={
          !isSaving
            ? this.onSubmit
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl.get('hostConnect.bookingcom.message.desc1').d('We are still working on it.')
                });
              }
        }
        onCancel={
          !isSaving
            ? hideModal
            : () => {
                notification.info({
                  message: intl.get('hostConnect.bookingcom.message.chillOut').d('Chill Out!'),
                  description: intl
                    .get('hostConnect.agoda.message.stillPull')
                    .d('We are still trying to pull from Agoda, do not close this page yet.')
                });
              }
        }
        okText={intl.get('reservations.headerLabels.save').d('Save')}
        cancelText={intl.get('reservations.headerLabels.cancel').d('Cancel')}
        maskClosable={false}
      >
        <Form onSubmit={this.onSubmit}>
          {this.isEdit() ? (
            <React.Fragment>
              {/* <div>{`${agoda.propertyData ? agoda.propertyData.name || '<No hotel name>' : '<No hotel name>'} (${agoda.agodaPropertyId})`}</div> */}
              <FormItem label={intl.get('hostConnect.bookingcom.headerLabels.assignHost').d('Assign a host:')}>
                {getFieldDecorator('host', {
                  rules: []
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {this.state.hostOptions}
                  </Select>
                )}
              </FormItem>
              {agoda.rooms.length === 1 && (
                <FormItem label={intl.get('hostConnect.agoda.headerLabels.platform').d('Which platform would you like to integrate to')}>
                  {getFieldDecorator('isHomes', {
                    rules: []
                  })(
                    <Radio.Group buttonStyle="solid" disabled={agoda.rooms.length !== 1}>
                      <Radio.Button value={false}>Agoda</Radio.Button>
                      <Radio.Button value={true}>Agoda Homes</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              )}
            </React.Fragment>
          ) : (
            <FormItem label={intl.get('hostConnect.agoda.headerLabels.property').d('Agoda Property Id')}>
              {getFieldDecorator('agodaPropertyId', {
                rules: [
                  {
                    required: true,
                    pattern: '^[0-9]+$',
                    message: intl.get('hostConnect.bookingcom.placeholder.bookingIdMsg').d('Please insert numbers only!')
                  }
                ]
              })(<Input placeholder={intl.get('hostConnect.agoda.placeholder.property').d('Agoda Property Id (example: 263492)')} />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(withRouter(AgodaListingForm));
