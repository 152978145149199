import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, DatePicker } from 'antd';
import { FileOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';

import { checkIsMoment, formatToDateString } from 'utils/date';
import { checkHasValue, generateDisplayFee, guard } from 'utils/general';
import intl from 'react-intl-universal';

import {
  StyledFilterCheckboxContainer,
  StyledFilterCheckboxGroup,
  StyledFilterCheckboxSelection,
  StyledFilterRadioButton,
  StyledFilterRadioContainer,
  StyledFilterRadioGroup,
  StyledFilterSearchContainer,
  StyledFilterSearchInput
} from './table.styles';

import moment from 'moment';

export const constructColumn = (
  title,
  dataIndex,
  { key, width, hasSorter, hasAggregationSorter, sorter: sorterInfo, linkFunc, isDate, isDocument, isNumber, isRM, isObj, fixed } = {}
) => ({
  title,
  dataIndex,
  fixed,
  key: key || dataIndex,
  width,
  ...(hasAggregationSorter && { sorter: () => false }),
  ...(hasSorter && constructColumnSorter(dataIndex, sorterInfo, { isNumber })),
  render: (data, record) => {
    if (!checkHasValue(data)) {
      return <span>-</span>;
    }

    let text = <span>{data}</span>;

    if (!!linkFunc) {
      text = <Link to={linkFunc(record)}>{data}</Link>;
    } else if (isDate && checkIsMoment(data)) {
      text = <span>{formatToDateString(data)}</span>;
    } else if (isDocument) {
      text = (
        <a href={data.url} target="_blank" rel="noopener noreferrer">
          <FileOutlined />
        </a>
      );
    } else if (isRM) {
      text = <span>{generateDisplayFee(data)}</span>;
    } else if (isObj) {
      if (isNumber) {
        let text = '';
        Object.keys(data).map(key => {
          return (text = text + `\n${data[key]}`);
        });
        return <span>{text}</span>;
      } else {
        let text = '';
        Object.keys(data).map(key => {
          return (text = text + `\n${key}`);
        });
        return <span>{text}</span>;
      }
    }

    return text;
  }
});

export const constructColumnDateSearch = (dataIndex, placeholder, filterInfo) => ({
  ...constructFilter(dataIndex, 'date', { dataIndex, placeholder }, filterInfo)
});

export const constructColumnFilterSearch = (dataIndex, placeholder, filterInfo) => ({
  ...constructFilter(dataIndex, 'search', { dataIndex, placeholder }, filterInfo),
  onFilterDropdownVisibleChange: visible => visible && setTimeout(() => document.getElementById(dataIndex).focus())
});

export const constructColumnFilterRadio = (dataIndex, filterSelections) => ({
  ...constructFilter(dataIndex, 'radio', { filterSelections })
});

export const constructColumnFilterCheckbox = (dataIndex, filterSelections) => ({
  ...constructFilter(dataIndex, 'checkbox', { filterSelections })
});

export const getCurrentIndexForPaginatedList = (index = 0, currentPage = 1, pageSize = 10) => {
  return (currentPage - 1) * pageSize + index;
};

export const handleOnAggregationTableChange = async ({ pagination, filters, sorter, ...otherProps }, setNewAggregationQuery) => {
  const filter = Object.entries(filters).reduce((filterQuery, [key, values]) => {
    // Escape validation (exclude '-' for date format )
    const escapedValues = values.map(value => (typeof value === 'string' ? escapeSpecialCharacters(value) : value));
    const value = escapedValues.length <= 1 ? escapedValues[0] : escapedValues;
    return { ...filterQuery, ...(!!values && { [key]: value }) };
  }, {});

  console.log('table filter', filter);

  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...otherProps,
    ...(Object.keys(filter).length > 0 && { filter }),
    ...(sorter && columnKey && order && { sorter: { [columnKey]: order === 'descend' ? -1 : 1 } }),
    ...(pagination && current && { limit: pageSize, currentPage: current })
  };

  console.log(query, 87);
  setNewAggregationQuery(query);
};

/* ========================================== Local Functions ========================================== */

function getTranslation(selection, keys) {
  for (let key of keys) {
    let translation = intl.get(key);
    if (translation) {
      return translation;
    }
  }
  return null;
}

const getValueFromRecord = (dataIndex, record) => {
  return guard(() => dataIndex.split('.').reduce((object, key) => object[key], record));
};

const escapeSpecialCharacters = str => {
  return str.replace(/[[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
const constructColumnSorter = (dataIndex, sorterInfo, { isNumber = false } = {}) => ({
  sorter: (nextRecord, currentRecord) => {
    let nextValue = getValueFromRecord(dataIndex, nextRecord);
    let currentValue = getValueFromRecord(dataIndex, currentRecord);

    if (!isNumber) {
      nextValue = checkHasValue(nextValue) ? String(nextValue).toUpperCase() : '';
      currentValue = checkHasValue(currentValue) ? String(currentValue).toUpperCase() : '';
    }

    return isNumber ? nextValue > currentValue : nextValue.localeCompare(currentValue);
  },
  ...(!!sorterInfo && { sortOrder: sorterInfo.columnKey === dataIndex && sorterInfo.order })
});

const constructFilter = (dataIndex, type, filterComponentProps, filterInfo, disabled = false) => {
  let FilterComponent, FilterIconComponent, onFilterCompareFunc;

  if (type === 'date') {
    FilterComponent = DateSearch;
    FilterIconComponent = SearchOutlined;
    onFilterCompareFunc = (string1, string2) => string1 === string2;
  } else if (type === 'search') {
    FilterComponent = FilterSearch;
    FilterIconComponent = SearchOutlined;
    onFilterCompareFunc = (string1, string2) => string1.includes(string2);
  } else if (type === 'radio') {
    FilterComponent = FilterRadio;
    FilterIconComponent = FilterOutlined;
    onFilterCompareFunc = (string1, string2) => string1 === string2;
  } else if (type === 'checkbox') {
    FilterComponent = FilterCheckbox;
    FilterIconComponent = FilterOutlined;
    onFilterCompareFunc = (string1, string2) => string1 === string2;
  }

  const onFilterFunc = (value, record) => {
    const formattedRecordData = String(getValueFromRecord(dataIndex, record)).toLowerCase();
    const formattedValue = String(value).toLowerCase();

    return onFilterCompareFunc(formattedRecordData, formattedValue);
  };

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <FilterComponent
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        {...filterComponentProps}
      />
    ),
    filterIcon: filtered => <FilterIconComponent type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
    onFilter: onFilterFunc,
    ...(!!filterInfo && { filteredValue: filterInfo[dataIndex] || [] })
  };
};

// =============================== Filter Components
const DateSearch = ({ dataIndex, placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <StyledFilterSearchContainer type="flex" gutter={8}>
      <Col>
        <DatePicker
          id={dataIndex}
          placeholder={placeholder}
          value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
          onChange={(date, dateString) => {
            // console.log(date, dateString);
            setSelectedKeys(dateString ? [dateString] : []);

            setTimeout(() => {
              confirm();
            }, 100);
          }}
        />
      </Col>
      <Col>
        <Button onClick={clearFilters}>Clear</Button>
      </Col>
    </StyledFilterSearchContainer>
  );
};

const FilterSearch = ({ dataIndex, placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <StyledFilterSearchContainer type="flex">
      <Col>
        <StyledFilterSearchInput
          id={dataIndex}
          value={selectedKeys[0]}
          placeholder={placeholder}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
        />
      </Col>
      <Col>
        <FilterButtons onConfirm={confirm} onClearFilter={clearFilters} />
      </Col>
    </StyledFilterSearchContainer>
  );
};

const FilterRadio = ({ filterSelections, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <StyledFilterRadioContainer>
    <StyledFilterRadioGroup onChange={e => setSelectedKeys(checkHasValue(e.target.value) ? [e.target.value] : [])} value={selectedKeys[0]}>
      {filterSelections.map(selection => {
        let translation = getTranslation(selection, [
          `taskManagement.taskList.priority.${selection.label}`,
          `rateModifier.rateType.${selection.label}`,
          `host.hostType.${selection.label}`,
          `host.partnershipStatus.${selection.label}`
        ]);
        return (
          <Row key={selection.label}>
            <StyledFilterRadioButton value={selection.value}>{translation ? translation : selection.label}</StyledFilterRadioButton>
          </Row>
        );
      })}
    </StyledFilterRadioGroup>
    <FilterButtons onConfirm={confirm} onClearFilter={clearFilters} />
  </StyledFilterRadioContainer>
);

const FilterCheckbox = ({ filterSelections, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <StyledFilterCheckboxContainer>
      <StyledFilterCheckboxGroup onChange={values => setSelectedKeys(values)} value={selectedKeys}>
        {filterSelections.map(selection => {
          let translation = getTranslation(selection, [
            `taskManagement.taskList.tableColumns.statusOptions.${selection.label}`,
            `users.roleType.${selection.label}`,
            `reservations.reservationDetails.bookingStatus.${selection.label}`,
            `overview.cleaningStatus.${selection.label}`
          ]);
          return (
            <Row key={selection.label}>
              <StyledFilterCheckboxSelection value={selection.value}>{translation ? translation : selection.label}</StyledFilterCheckboxSelection>
            </Row>
          );
        })}
      </StyledFilterCheckboxGroup>
      <FilterButtons onConfirm={confirm} onClearFilter={clearFilters} />
    </StyledFilterCheckboxContainer>
  );
};

const FilterButtons = ({ onConfirm, onClearFilter }) => {
  return (
    <Row gutter={4} type="flex" justify="start">
      <Col>
        <Button type="primary" onClick={onConfirm}>
          {intl.get('tables.search').d('Search')}
        </Button>
      </Col>
      <Col>
        <Button onClick={onClearFilter}>{intl.get('tables.reset').d('Reset')}</Button>
      </Col>
    </Row>
  );
};
