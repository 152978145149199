import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Alert } from 'antd';

import { OptGroupSelect } from 'components/FormSelection/FormSelection';

import { getRoomTypesByHostIdGroupedByProperty } from 'utils/apis/roomtype';
import { patchUpdateUnitRoomType } from 'utils/apis/unit';
import intl from 'react-intl-universal';

import styles from './ChangeRoomTypeModal.module.css';

const useFetchRoomTypeWithPropertyGroupOptions = (hostId, originalRoomTypeId) => {
  const [roomTypeWithPropertyGroupOptions, setRoomTypeWithPropertyGroupOptions] = useState([]);

  useEffect(() => {
    const fetchRoomTypeWithPropertyGroupOptions = async () => {
      const roomTypesGroupByProperties = await getRoomTypesByHostIdGroupedByProperty(hostId);
      const localRoomTypeWithPropertyGroupOptions = roomTypesGroupByProperties.map(property => ({
        label: property.name,
        value: property._id,
        selections: property.roomTypes.map(roomType => ({
          label: roomType.name,
          value: roomType._id,
          isDisabled: roomType._id === originalRoomTypeId
        }))
      }));

      setRoomTypeWithPropertyGroupOptions(localRoomTypeWithPropertyGroupOptions);
    };

    fetchRoomTypeWithPropertyGroupOptions();
  }, [hostId, originalRoomTypeId]);

  return roomTypeWithPropertyGroupOptions;
};

const ChangeRoomTypeModal = ({ unit, isVisible, onCancel, onAfterSave, shouldAllowChangeProperty }) => {
  const hostId = unit.roomType && unit.roomType.property && unit.roomType.property.host && unit.roomType.property.host._id;
  const propertyId = unit.roomType && unit.roomType.property && unit.roomType.property._id;
  const roomTypeId = useMemo(() => unit.roomType._id, [unit]);

  const [selectedRoomType, setSelectedRoomType] = useState();
  const roomTypeWithPropertyGroupOptions = useFetchRoomTypeWithPropertyGroupOptions(hostId, roomTypeId);

  const isSaveButtonEnabled = useMemo(() => {
    const selectedProperty = roomTypeWithPropertyGroupOptions.find(property =>
      property.selections.find(roomType => roomType.value === selectedRoomType)
    );
    return selectedRoomType && ((selectedProperty && String(propertyId) === String(selectedProperty.value)) || shouldAllowChangeProperty);
  }, [roomTypeWithPropertyGroupOptions, propertyId, selectedRoomType, shouldAllowChangeProperty]);
  const shouldShowAlertMessage = useMemo(() => selectedRoomType && !isSaveButtonEnabled, [selectedRoomType, isSaveButtonEnabled]);

  const showConfirm = () => {
    Modal.confirm({
      title: intl.get('listings.unit.message.assignTitle').d('Are you sure you want to assign this unit to a new room type?'),
      content: intl.get('listings.unit.message.assignContent').d('This change will affect both previous and new room type inventory.'),
      onOk: async () => {
        await patchUpdateUnitRoomType(unit._id, selectedRoomType);
        onAfterSave();
      },
      onCancel: () => {}
    });
  };

  const showNoRoomSelectedError = () => {
    Modal.error({
      title: intl.get('listings.unit.message.assignErrorTitle').d('You have not selected any room type!'),
      content: intl.get('listings.unit.message.assignErrorContent').d('Please select a room type you wish to change to before you proceed.')
    });
  };

  const handleOnChangeRoomType = value => {
    setSelectedRoomType(value);
  };

  const handleOnSave = () => {
    if (selectedRoomType) {
      if (String(unit.roomType._id) !== String(selectedRoomType)) {
        showConfirm();
      } else {
        onCancel();
      }
    } else {
      showNoRoomSelectedError();
    }
  };

  return (
    <Modal
      visible={isVisible}
      title={unit.name}
      destroyOnClose={true}
      onOk={handleOnSave}
      onCancel={onCancel}
      okText={intl.get('listings.unit.headerLabels.save').d('Save')}
      okButtonProps={{ disabled: !isSaveButtonEnabled }}
    >
      <div className={styles.currentRoomTypeLabel}>
        <label className={styles.label}>{intl.get('listings.unit.headerLabels.currentRoomType').d('Current Room Type:')} </label>{' '}
        <label>{unit.roomType.name}</label>
      </div>
      <OptGroupSelect
        name="newRoomType"
        label={intl.get('listings.unit.headerLabels.selectRoomType').d('Select New Room Type')}
        placeholder={intl.get('listings.unit.placeholder.selectRoomType').d('Please select a new room type')}
        value={selectedRoomType}
        selectionGroups={roomTypeWithPropertyGroupOptions}
        onChange={handleOnChangeRoomType}
        selectClassName={styles.select}
        labelClassName={styles.label}
      />
      {shouldShowAlertMessage && (
        <Alert
          className={styles.alert}
          message={intl
            .get('listings.unit.message.selectedRoomType')
            .d('You have selected a room type from another property. Please remove any parent/child units from this unit before proceeding.')}
          type="warning"
          showIcon
        ></Alert>
      )}
    </Modal>
  );
};

ChangeRoomTypeModal.propTypes = {
  unit: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onAfterSave: PropTypes.func,
  shouldAllowChangeProperty: PropTypes.bool
};

ChangeRoomTypeModal.defaultProps = {
  unit: {},
  onCancel: () => {},
  onAfterSave: () => {},
  shouldAllowChangeProperty: true
};

export default ChangeRoomTypeModal;
