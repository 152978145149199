import React from 'react';
import PropTypes from 'prop-types';
import { Row, Form } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import AmenitiesSelection from 'components/AmenitiesSelection/AmenitiesSelection';

import styles from './Amenities.module.css';
import intl from 'react-intl-universal';

class Amenities extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    onBack(allFieldsValue);
  };

  render() {
    const { defaultValues, form, isLoading, isLastPage, percentage, title, hasFetchedPropertyAmenities, propertyAmenities } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
        showNextButtonLoading={isLoading}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>{intl.get('listings.property.amnetities').d('What amenities does your property provide?')}</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          {hasFetchedPropertyAmenities &&
            propertyAmenities &&
            Object.keys(propertyAmenities).map(key => (
              <AmenitiesSelection
                form={form}
                key={key}
                title={key}
                fieldName={String(key).toLowerCase()}
                items={propertyAmenities[key].data}
                icon={propertyAmenities[key].icon}
                defaultValue={defaultValues[key]}
              />
            ))}
        </Row>
      </ListingWizardLayout>
    );
  }
}

Amenities.propTypes = {
  form: PropTypes.object.isRequired,
  hasFetchedPropertyAmenities: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  propertyAmenities: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

Amenities.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isLastPage: false
};

const WrappedFormAmenities = Form.create()(Amenities);

export default WrappedFormAmenities;
