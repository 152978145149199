import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { notification } from 'antd';
import uuid from 'uuid/v1';
import queryString from 'query-string';
import intl from 'react-intl-universal';

import {
  buildListingWizardUri,
  buildLWCreatePropertyUri,
  buildLWCreatePropertyBasicUri,
  buildLWCreatePropertyLocationUri,
  buildLWCreatePropertyAccommodationUri,
  buildLWCreatePropertyAmenitiesUri
} from 'utils/routes';

import ProtectedRoute from 'utils/ProtectedRoute';
import { getPropertyTypesConstant, getCountriesConstant, getStatesConstant, getPropertyAmenitiesConstant } from 'utils/apis/constants';
import { convertAmenitiesSelectionToObj } from 'utils/general';

import BasicDetails from './BasicDetails/BasicDetails';
import Location from './Location/Location';
import Accommodation from './Accommodation/Accommodation';
import Amenities from './Amenities/Amenities';

const generatePropertyCode = name => {
  return `${name.substring(0, 3).toUpperCase()}-${uuid()}`;
};

class CreateProperty extends React.Component {
  constructor() {
    super();

    this.state = {
      hasFetchedCountries: false,
      hasFetchedPropertyAmenities: false,
      hasFetchedPropertyTypes: false,
      hasFetchedStates: false,

      isCreatingProperty: false,

      countriesSelection: [],
      propertyAmenities: {},
      propertyTypesSelection: [],
      statesSelection: [],

      payload: {}
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (!this.checkHasHostId(location.search)) {
      history.push(buildListingWizardUri());
      return;
    }

    if (location.pathname !== buildLWCreatePropertyUri()) {
      if (location.pathname !== buildLWCreatePropertyBasicUri()) {
        history.push(buildLWCreatePropertyUri());
      }
    }
    this.fetchPropertyTypes();
    this.fetchCountries();
    this.fetchPropertyAmenities();
  }

  checkHasHostId = searchQuery => {
    let hasHostId = true;
    if (searchQuery) {
      const query = queryString.parse(searchQuery);
      if (!query.hostId) {
        hasHostId = false;
      }
    } else {
      hasHostId = false;
    }
    return hasHostId;
  };

  fetchPropertyTypes = () => {
    getPropertyTypesConstant()
      .then(res => {
        this.setPropertyTypesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchCountries = () => {
    getCountriesConstant()
      .then(res => {
        this.setCountriesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchStates = countryIsoCode => {
    getStatesConstant(countryIsoCode)
      .then(res => {
        this.setStatesState(res);
      })
      .catch(err => console.log(err));
  };

  fetchPropertyAmenities = () => {
    getPropertyAmenitiesConstant()
      .then(res => {
        this.setPropertyAmenitiesState(res);
      })
      .catch(err => console.log(err));
  };

  setPropertyTypesState = propertyTypes => {
    const formattedPropertyTypes = Object.keys(propertyTypes).map(key => ({
      key: propertyTypes[key].code,
      value: propertyTypes[key].label
    }));

    this.setState({
      hasFetchedPropertyTypes: true,
      propertyTypesSelection: formattedPropertyTypes
    });
  };

  setCountriesState = countries => {
    const formattedCountries = Object.keys(countries)
      .map(key => ({
        key: countries[key].iso2,
        value: countries[key].name
      }))
      .filter(country => country.key === 'MY'); // Only Malaysia has state now...

    this.setState({
      hasFetchedCountries: true,
      countriesSelection: formattedCountries
    });
  };

  setStatesState = states => {
    const formattedStates = Object.keys(states).map(key => ({
      key: states[key].code,
      value: states[key].label
    }));

    this.setState({
      hasFetchedStates: true,
      statesSelection: formattedStates
    });
  };

  setPropertyAmenitiesState = propertyAmenities => {
    let formattedPropertyAmenities = {};
    Object.keys(propertyAmenities).forEach(key => {
      const { code, label, type } = propertyAmenities[key];
      if (type in formattedPropertyAmenities) {
        formattedPropertyAmenities[type]['data'].push({
          key: code,
          value: label
        });
      } else {
        formattedPropertyAmenities[type] = {
          icon: this.getPropertyAmenitiesIcon(type),
          data: [
            {
              key: code,
              value: label
            }
          ]
        };
      }
    });
    this.setState({
      hasFetchedPropertyAmenities: true,
      propertyAmenities: formattedPropertyAmenities
    });
  };

  getPropertyAmenitiesIcon = type => {
    const icons = {
      Activities: 'dribbble',
      'Business Facilities': 'desktop',
      'Cleaning Services': 'smile-o',
      'Common Area': 'home',
      'Entertainment & Family Services': 'team',
      'Food & Drink': 'coffee',
      'Front Desk Services': 'customer-service',
      Miscellaneous: 'safety',
      Shops: 'shop',
      Transportation: 'car'
    };
    return icons[type] || 'tool';
  };

  formatBasicDetailsData = data => ({
    name: data.name,
    propertyTypes: data.type
  });

  formatLocationData = data => ({
    countryCode: data.country,
    street: data.address,
    city: data.city,
    zipCode: data.zipcode,
    state: data.state,
    latitude: data.latitude,
    longitude: data.longitude
  });

  formatAccomodationData = data => ({
    checkInTime: data.checkInTime,
    checkOutTime: data.checkOutTime,
    minNight: data.minNight,
    maxNight: data.maxNight
  });

  formatAmenitiesData = data => {
    const formattedAmenities = Object.keys(data).reduce((newData, key) => {
      if (data[key] && Array.isArray(data[key])) {
        return [...newData, ...data[key]];
      }
      return newData;
    }, []);
    return {
      propertyAmenities: formattedAmenities
    };
  };

  extractBasicDetailsData = () => {
    const { payload } = this.state;

    return {
      name: payload.name,
      type: payload.propertyTypes
    };
  };

  extractLocationData = () => {
    const { payload } = this.state;

    return {
      country: payload.countryCode,
      address: payload.street,
      city: payload.city,
      zipcode: payload.zipCode,
      state: payload.state,
      latitude: payload.latitude,
      longitude: payload.longitude
    };
  };

  extractAccomodationData = () => {
    const { payload } = this.state;

    return {
      checkInTime: payload.checkInTime,
      checkOutTime: payload.checkOutTime,
      minNight: payload.minNight,
      maxNight: payload.maxNight
    };
  };

  extractAmenitiesData = () => {
    const { payload, propertyAmenities } = this.state;
    return convertAmenitiesSelectionToObj(payload.propertyAmenities, propertyAmenities);
  };

  handleOnCountrySelectionChange = country => {
    this.fetchStates(country.toLowerCase());
  };

  handleOnSave = (url, formatDataFunc = data => data) => (formData = {}) => {
    const { history } = this.props;
    const formattedData = formatDataFunc(formData);
    this.setState(prevState => ({
      payload: { ...prevState.payload, ...formattedData }
    }));

    history.push(url);
  };

  handleOnSaveAndSend = (formData = {}) => {
    const formattedData = this.formatAmenitiesData(formData);
    this.setState({
      isCreatingProperty: true
    });

    this.setState((prevState, props) => {
      const { location } = this.props;
      const query = queryString.parse(location.search);
      const newPayload = {
        ...prevState.payload,
        ...formattedData,
        code: generatePropertyCode(prevState.payload.name),
        host: query.hostId
      };
      props
        .onFinish(newPayload)
        .then(() => {
          this.setState({
            isCreatingProperty: false
          });
          props.history.push(buildListingWizardUri());
        })
        .catch(e => {
          this.setState({
            isCreatingProperty: false
          });
          notification.error({
            message: intl.get('listings.property.message.propertyError').d('Something went wrong and your property is not created.')
            // description: e.message
          });
        });
      return {
        payload: newPayload
      };
    });
  };

  render() {
    const { title, location } = this.props;
    const {
      hasFetchedPropertyTypes,
      hasFetchedCountries,
      hasFetchedStates,
      hasFetchedPropertyAmenities,
      isCreatingProperty,
      propertyTypesSelection,
      countriesSelection,
      statesSelection,
      propertyAmenities
    } = this.state;

    return (
      <Fragment>
        <Switch>
          <ProtectedRoute
            path={buildLWCreatePropertyUri()}
            render={() => (
              <BasicDetails
                title={title}
                percentage={0}
                onSave={this.handleOnSave(buildLWCreatePropertyLocationUri(location.search), this.formatBasicDetailsData)}
                hasFetchedPropertyTypes={hasFetchedPropertyTypes}
                propertyTypes={propertyTypesSelection}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
            exact
          />
          <ProtectedRoute
            path={buildLWCreatePropertyBasicUri()}
            render={() => (
              <BasicDetails
                title={title}
                percentage={0}
                onSave={this.handleOnSave(buildLWCreatePropertyLocationUri(location.search), this.formatBasicDetailsData)}
                hasFetchedPropertyTypes={hasFetchedPropertyTypes}
                propertyTypes={propertyTypesSelection}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreatePropertyLocationUri()}
            render={() => (
              <Location
                title={title}
                percentage={25}
                onSave={this.handleOnSave(buildLWCreatePropertyAccommodationUri(location.search), this.formatLocationData)}
                onBack={this.handleOnSave(buildLWCreatePropertyBasicUri(location.search), this.formatLocationData)}
                defaultValues={this.extractLocationData()}
                onCountriesChange={this.handleOnCountrySelectionChange}
                hasFetchedCountries={hasFetchedCountries}
                hasFetchedStates={hasFetchedStates}
                countries={countriesSelection}
                states={statesSelection}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreatePropertyAccommodationUri()}
            render={() => (
              <Accommodation
                title={title}
                percentage={50}
                onSave={this.handleOnSave(buildLWCreatePropertyAmenitiesUri(location.search), this.formatAccomodationData)}
                onBack={this.handleOnSave(buildLWCreatePropertyLocationUri(location.search), this.formatAccomodationData)}
                defaultValues={this.extractAccomodationData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreatePropertyAmenitiesUri()}
            render={() => (
              <Amenities
                title={title}
                isLastPage
                percentage={75}
                onSave={this.handleOnSaveAndSend}
                onBack={this.handleOnSave(buildLWCreatePropertyAccommodationUri(location.search), this.formatAmenitiesData)}
                hasFetchedPropertyAmenities={hasFetchedPropertyAmenities}
                propertyAmenities={propertyAmenities}
                defaultValues={this.extractAmenitiesData()}
                isLoading={isCreatingProperty}
              />
            )}
          />
          <Redirect from="*" to="/404NotFound" />
        </Switch>
      </Fragment>
    );
  }
}

CreateProperty.propTypes = {
  title: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired
};

CreateProperty.defaultProps = {
  onFinish: () => {}
};

export default CreateProperty;
