import React, { Component } from 'react';
import { Calendar, Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

const dateSingleDigit = num => {
  if (num < 10) {
    return '0' + num;
  }
  return num + '';
};

export default class PayoutCalendar extends Component {
  static propTypes = {
    data: PropTypes.object,
    date: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {};
    this.dateCellRender = this.dateCellRender.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.toolTipTitle = this.toolTipTitle.bind(this);
  }

  renderCell(value, classN, state, booking) {
    return (
      <div>
        <div className="ant-fullcalendar-date">
          <div className="ant-fullcalendar-value">{dateSingleDigit(value.date())}</div>
        </div>
        {classN === 'mixedendstart' ? (
          <React.Fragment>
            <Tooltip placement="top" title={this.toolTipTitle(booking[0], state)}>
              <div className={state + ' end extraHeight'} />
            </Tooltip>
            <Tooltip placement="top" title={this.toolTipTitle(booking[1], state === 'booked' ? 'blocked' : 'booked')}>
              <div className={(state === 'booked' ? 'blocked' : 'booked') + ' start extraHeight'} />
            </Tooltip>
          </React.Fragment>
        ) : classN === 'endstart' ? (
          <React.Fragment>
            <Tooltip placement="top" title={this.toolTipTitle(booking[0], state)}>
              <div className={state + ' end extraHeight'} />
            </Tooltip>
            <Tooltip placement="top" title={this.toolTipTitle(booking[1], state)}>
              <div className={state + ' start extraHeight'} />
            </Tooltip>
          </React.Fragment>
        ) : (
          <Tooltip placement="top" title={this.toolTipTitle(booking[0], state)}>
            <div className={state + ' ' + classN + ' extraHeight'} />
          </Tooltip>
        )}
      </div>
    );
  }

  dateCellRender(value) {
    let { bookedDates, blockedDates } = this.props.data;

    let dateString = value.format('YYYY-MM-DD');
    let bookedStart = bookedDates.filter(b => b.startDate === dateString)[0];
    let bookedEnd = bookedDates.filter(b => b.endDate === dateString)[0];
    let blockedStart = blockedDates.filter(b => b.startDate === dateString)[0];
    let blockedEnd = blockedDates.filter(b => b.endDate === dateString)[0];
    let betweenBooked = bookedDates.filter(b => dateString > b.startDate && dateString < b.endDate)[0];
    let betweenBlocked = blockedDates.filter(b => dateString > b.startDate && dateString < b.endDate)[0];

    if (bookedStart && bookedEnd) {
      return this.renderCell(value, 'endstart', 'booked', [bookedEnd, bookedStart]);
    } else if (blockedStart && blockedEnd) {
      return this.renderCell(value, 'endstart', 'blocked', [blockedEnd, blockedStart]);
    } else if (bookedEnd && blockedStart) {
      return this.renderCell(value, 'mixedendstart', 'booked', [bookedEnd, blockedStart]);
    } else if (blockedEnd && bookedStart) {
      return this.renderCell(value, 'mixedendstart', 'blocked', [blockedEnd, bookedStart]);
    } else if (bookedStart) {
      return this.renderCell(value, 'start', 'booked', [bookedStart]);
    } else if (blockedStart) {
      return this.renderCell(value, 'start', 'blocked', [blockedStart]);
    } else if (bookedEnd) {
      return this.renderCell(value, 'end', 'booked', [bookedEnd]);
    } else if (blockedEnd) {
      return this.renderCell(value, 'end', 'blocked', [blockedEnd]);
    } else if (betweenBooked) {
      return this.renderCell(value, 'full', 'booked', [betweenBooked]);
    } else if (betweenBlocked) {
      return this.renderCell(value, 'full', 'blocked', [betweenBlocked]);
    }
    return this.renderCell(value, '', '', []);
  }

  toolTipTitle(booking, state) {
    if (booking) {
      if (state === 'booked') {
        return (
          <React.Fragment>
            <b>{intl.get('payout.headerLabels.checkIn').d('Check In:')}</b> {booking.startDate}
            <br />
            <b>{intl.get('payout.headerLabels.checkout').d('Check Out:')}</b> {booking.endDate}
            <br />
            <b>{intl.get('payout.headerLabels.status').d('Status')}</b> {intl.get('payout.headerLabels.booked').d('Booked')}
            <br />
            <b>{intl.get('payout.headerLabels.bookingSource').d('Booking Source')}</b> {booking.source}
            <br />
            <b>{intl.get('payout.headerLabels.guest').d('Guest Name')}</b> {booking.guestName}
            <br />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <b>{intl.get('payout.headerLabels.startDate').d('Start Date:')}</b> {booking.startDate}
            <br />
            <b>{intl.get('payout.headerLabels.endDate').d('End Date:')}</b> {booking.endDate}
            <br />
            <b>{intl.get('payout.headerLabels.status').d('Status:')}</b> {intl.get('payout.headerLabels.blocked').d('Blocked')}
            <br />
          </React.Fragment>
        );
      }
    }
    return '';
  }

  render() {
    let { date } = this.props;
    return <Calendar className="payoutcalendar" dateFullCellRender={this.dateCellRender} value={moment(date)} />;
  }
}
