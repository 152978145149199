import React, { Component } from 'react';
import { Input, Card, Form, Row, Col, Icon, Button, message } from 'antd';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';

import FormPassword from 'components/FormPassword/FormPassword';

import CloseButton from './../CloseButton/CloseButton';
import { userProfileResetPassword } from '../../utils/apis/authentication';

import './UserProfilePasswordForm.css';

const FormItem = Form.Item;

class UserProfilePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: []
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const history = this.props.history;
      const userId = this.props.match.params.id;
      const { getFieldsValue } = this.props.form;
      let { currentPassword, password, confirmPassword } = getFieldsValue();
      if (!err) {
        if (password === confirmPassword) {
          userProfileResetPassword(currentPassword, password)
            .then(res => {
              if (res.status === 204) {
                history.push(`/userProfile/${userId}`);
                message.success(intl.get('userProfile.message.passwordChangeSuccess').d('Password changed successfully!'));
              } else {
                message.error(intl.get('userProfile.message.passwordIncorrect').d('Current password is incorrect! Please try again!'));
              }
            })
            .catch(ex => {
              console.log(ex);
              message.error(intl.get('userProfile.message.errorChanging').d('Error while changing password!'));
            });
        }
      } else {
        console.log('Receive error while validate fields');
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="user-profile-passsword-form-card">
        <CloseButton
          onClick={() => {
            this.props.history.goBack();
          }}
        />
        <Form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="left">
            <Col span={24}>
              <FormItem label={intl.get('forms.placeholders.currentPassword').d('Current Password')} className="userprofile-form-list">
                {getFieldDecorator('currentPassword', {
                  rules: [
                    {
                      required: true,
                      message: intl
                        .get('forms.required', {
                          formField: intl.get('forms.placeholders.currentPassword').d('Current Password')
                        })
                        .d('Please enter your current password!')
                    }
                  ]
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder={intl.get('forms.placeholders.currentPassword').d('Current Password')}
                  />
                )}
              </FormItem>
            </Col>
            <FormPassword form={this.props.form} isResetPassword passwordClassName="userprofile-form-list" />
            <FormItem className="userprofile-button-wrapper">
              <Button type="primary" htmlType="submit">
                {intl.get('userProfile.headerLabels.save').d('Save')}
              </Button>
            </FormItem>
          </Row>
        </Form>
      </Card>
    );
  }
}

export default withRouter(Form.create()(UserProfilePasswordForm));
