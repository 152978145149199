import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Skeleton } from 'antd';
import intl from 'react-intl-universal';

const AdvancedOptionsModal = ({
  isVisible,
  isLoading,

  onSave,
  onClose,

  children
}) => {
  return (
    <Modal
      title={intl.get('hostConnect.integration.headerLabels.advanced').d('Advanced Options')}
      visible={isVisible}
      onOk={onSave}
      onCancel={onClose}
      okText={intl.get('hostConnect.integration.headerLabels.save').d('Save')}
      cancelText={intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
      width="60%"
      closable={!isLoading}
      maskClosable={false}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      centered
      bodyStyle={{ height: '75vh' }}
    >
      {isLoading ? <Skeleton active={true} /> : children}
    </Modal>
  );
};

AdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

AdvancedOptionsModal.defaultProps = {
  isLoading: false,
  onSave: () => {}
};

export default AdvancedOptionsModal;
