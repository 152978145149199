import React, { useState } from 'react';
import { Form, Button, Row, Col, Table, Tooltip, Icon } from 'antd';
import moment from 'moment';

import { getColumnFilterSearchProps, getColumnSorterProps } from 'components/Table/Table';

import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';
import intl from 'react-intl-universal';

const getTableColumns = (filtersInfo, sorterInfo, onClickSeasonalRule) => {
  let tableColumns = [];

  let sinceDateColumn = {
    title: intl.get('hostConnect.integration.tableColumns.startDate').d('Start Date'),
    dataIndex: 'sinceDate',
    key: 'sinceDate',
    width: '8%'
  };

  if (Object.entries(sorterInfo).length === 0) {
    sinceDateColumn = {
      ...sinceDateColumn,
      sortOrder: 'ascend',
      ...getColumnSorterProps('sinceDate')
    };
  } else {
    sinceDateColumn = {
      ...sinceDateColumn,
      ...getColumnSorterProps('sinceDate', { sorterInfo })
    };
  }

  const generateLinkToDetails = () => ({
    render: (text, record) => {
      return (
        <Button type="link" onClick={onClickSeasonalRule(record.key)}>
          {text || intl.get('hostConnect.integration.headerLabels.customRule').d('Custom Rule')}
        </Button>
      );
    }
  });

  tableColumns.push({
    title: intl.get('hostConnect.integration.tableColumns.seasonalRule').d('Seasonal Rule'),
    dataIndex: 'title',
    key: 'title',
    width: '8%',
    ...getColumnFilterSearchProps('title', intl.get('tables.seasonalRule').d('Seasonal Rule'), { filtersInfo }),
    ...generateLinkToDetails()
  });

  tableColumns.push(sinceDateColumn);

  tableColumns.push({
    title: intl.get('hostConnect.integration.tableColumns.endDate').d('End Date'),
    dataIndex: 'endDate',
    key: 'endDate',
    width: '8%',
    ...getColumnSorterProps('endDate', { sorterInfo }),
    render: (text, record) => {
      return (
        <>
          {text}{' '}
          {record.isMoreThanEndDate && (
            <Tooltip
              title={intl.get('hostConnect.integration.headerLabels.dateTooltip').d('This rule applies to a period beyond this search range.')}
            >
              <Icon type="question-circle" />
            </Tooltip>
          )}
        </>
      );
    }
  });

  return tableColumns;
};

const SeasonalRuleListing = ({ form, ruleTimelineWithDetails, onClickSeasonalRule, sinceDate, endDate, onClickSearch, isFetchLoading }) => {
  const [filtersInfo, setFilters] = useState({});
  const [sorterInfo, setSorter] = useState({});

  const handleOnClickSearch = () => {
    const formatDate = momentDate => {
      return moment(momentDate).format('YYYY-MM-DD');
    };

    const sinceDate = formatDate(form.getFieldValue('ruleListDateRange')[0]);
    const endDate = formatDate(form.getFieldValue('ruleListDateRange')[1]);

    onClickSearch(sinceDate, endDate);
  };

  const handleOnTableChange = (filtersInfo, sorterInfo) => {
    setFilters(filtersInfo);
    setSorter(sorterInfo);
  };

  return (
    <Row>
      <Row type="flex" justify="start" align="middle">
        <Col span={16}>
          <FormDatePickerRange
            formLabel={intl.get('hostConnect.integration.headerLabels.ruleTimeline').d('Seasonal rule timeline')}
            form={form}
            fieldName="ruleListDateRange"
            requiredErrorMessage={intl.get('hostConnect.integration.placeholder.dateMsg').d('Please select a range of dates')}
            defaultPickerValue={[moment(sinceDate), moment(endDate)]}
            placeholder={[
              intl.get('hostConnect.integration.placeholder.starDate').d('Start Date'),
              intl.get('hostConnect.integration.placeholder.endDate').d('End Date')
            ]}
            disabled={isFetchLoading}
          />
        </Col>
        <Col span={8}>
          <Button type="primary" icon="search" onClick={handleOnClickSearch} loading={isFetchLoading}>
            {intl.get('hostConnect.integration.headerLabels.search').d('Search')}
          </Button>
        </Col>
      </Row>
      <Row type="flex">
        <div style={{ width: '100%' }}>
          <Table
            rowKey={record => record._id}
            dataSource={ruleTimelineWithDetails}
            columns={getTableColumns(filtersInfo, sorterInfo, onClickSeasonalRule)}
            onChange={(pagination, filtersInfo, sorterInfo) => {
              handleOnTableChange(filtersInfo, sorterInfo);
            }}
            pagination={false}
            size="medium"
            loading={isFetchLoading}
          />
        </div>
      </Row>
      <Col span={8}></Col>
    </Row>
  );
};

export default Form.create()(SeasonalRuleListing);
