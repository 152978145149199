import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Table, Row, Col, Button } from 'antd';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import CreateChainIDModal from './component/CreateChainIDModal';
import { deleteAgodaChainID, getAgodaChainIDs } from 'utils/apis/agodaOnboarding';
import { addMoment } from 'utils/date';
import intl from 'react-intl-universal';

const ManageAdgodaChainIdModal = props => {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [mode, setMode] = useState('create');
  const [selectedRecord, setSelectedRecord] = useState(null);
  // Regiser Chain ID modal
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getChainIDList();
  }, []);

  const getChainIDList = async () => {
    setLoading(true);
    const res = await getAgodaChainIDs();
    console.log(res, 20);
    setdata(res);
    setLoading(false);
  };

  const handleDeleteChainID = async recordId => {
    setLoading(true);
    console.log(recordId, 28);
    const res = await deleteAgodaChainID(recordId);
    console.log(res);
    setLoading(false);
    await getChainIDList();
  };

  const columns = [
    {
      ...constructColumn(intl.get('hostConnect.agoda.tableColumns.creationDate').d('Creation Date'), 'createdAt', {
        sorter: true
      }),
      render: text => moment(text).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      ...constructColumn(intl.get('hostConnect.agoda.tableColumns.host').d('Host'), 'host.name'),
      ...constructColumnFilterSearch('host.name', intl.get('tables.hostName').d('Search Host'))
    },
    {
      ...constructColumn(intl.get('hostConnect.agoda.tableColumns.chainId').d('Chain ID'), 'chainID'),
      ...constructColumnFilterSearch('chainID', intl.get('tables.chainId').d('Search Chain ID'))
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setMode('edit');
                setSelectedRecord(record);
                setVisible(true);
              }}
            >
              {intl.get('hostConnect.agoda.headerLabels.edit').d('Edit')}
            </Button>{' '}
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm delete chain ID?',
                  content: 'Do you want to delete this chain ID?',
                  onOk: () => handleDeleteChainID(record._id)
                });
              }}
            >
              {intl.get('hostConnect.agoda.headerLabels.delete').d('Delete')}
            </Button>
          </>
        );
      }
    }
  ];

  return (
    <Modal
      title={intl.get('hostConnect.agoda.headerLabels.manageAgoda').d('Manage Agoda Host Chain Id')}
      visible={props.visible}
      onCancel={props.onCancel}
    >
      <Row
        type="flex"
        justify="end"
        style={{
          marginBottom: 16
        }}
      >
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setMode('create');
            }}
          >
            {intl.get('hostConnect.agoda.headerLabels.addChain').d('Add New Chain ID')}
          </Button>
        </Col>
      </Row>

      <Table rowKey={'_id'} dataSource={data} columns={columns} loading={loading} />

      <CreateChainIDModal
        mode={mode}
        selectedRecord={selectedRecord}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </Modal>
  );
};

export default ManageAdgodaChainIdModal;
