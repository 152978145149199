import React, { Component, Fragment } from 'react';
import { Card, Modal, Form, DatePicker, Steps, Button, Input, Select, Row, Col, Switch, Spin, Icon, message, Skeleton } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';

import WrappedDynamicFieldSet from 'components/DynamicFieldSet/DynamicFieldSet';
import WrappedTransportDynamicFieldSet from 'components/DynamicFieldSet/TransportFieldSet';
import ItemsProvided from 'components/DynamicFieldSet/ItemsProvided';

import {
  getConstants,
  getBookingStatusConstant,
  getIntegrationSourcesConstant,
  getBookingTypesConstant,
  getPaymentMethodsConstant
} from 'utils/apis/constants';
import { getProperties, getProperty } from 'utils/apis/property';
import { getUnitListings, calculatePrice, createReservation, getReservationsById, updateReservation } from 'utils/apis/reservation';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { getRateModifiersByHost } from 'utils/apis/rateModifier';
import { checkValidContactNumber, numberWithCommas, checkHasValue } from 'utils/general';
import { buildMultiCalendarUri } from 'utils/routes';

import './BookingForm.css';
import intl from 'react-intl-universal';

const Step = Steps.Step;
const InputGroup = Input.Group;
const { Option, OptGroup } = Select;
const latestAvailableUpdateDate = moment().add(729, 'days');

const getDisabledDate = currentDate => {
  const isDisable = currentDate > latestAvailableUpdateDate;

  return isDisable;
};

const generateSteps = bookingForm => {
  const state = bookingForm.state;
  const platformTypeHostPlatform = state.platformKeyValues.HOSTPLATFORM && state.platformKeyValues.HOSTPLATFORM.code;
  const bookingDetails = intl.get('booking_form.headerLabels.bookingDetails').d('Booking Details');
  const guestDetails = intl.get('booking_form.headerLabels.guestDetails').d('Guest Details');
  const otherCharges = intl.get('booking_form.headerLabels.otherCharges').d('Other Charges');

  return [
    {
      title: bookingDetails,
      content: (
        <Fragment>
          <Card
            title={intl.get('booking_form.bodyLabels.bookingDetails').d('Booking Details')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            {/* FIXME: Need to change to Form */}
            <div className="form-row">
              <label className="booking-label">{intl.get('booking_form.bodyLabels.status').d('Status')}</label>
              <Select value={state.bookingState} onChange={bookingForm.handleOnSelectChange('bookingState')} disabled={state.isNonProfitBookingType}>
                {state.bookingStatesOptions}
              </Select>
            </div>
            <div className="date-picker">
              <div className="date-picker-item">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.checkIn').d('Check-in')}</label>
                <DatePicker
                  value={state.startDateMoment}
                  placeholder={intl.get('booking_form.bodyLabels.placeholder.date').d('Select date')}
                  name="startDate"
                  disabledDate={getDisabledDate}
                  onChange={bookingForm.handleOnStartCalendarChange}
                />
              </div>
              {bookingForm.dateErr && <div>{bookingForm.dateErr}</div>}
              <div className="date-picker-item">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.checkOut').d('Check-out')}</label>
                <DatePicker
                  value={state.endDateMoment}
                  placeholder={intl.get('booking_form.bodyLabels.placeholder.date').d('Select date')}
                  name="endDate"
                  disabledDate={getDisabledDate}
                  onChange={bookingForm.handleOnEndCalendarChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.unitViewFilter').d('Unit View Filter')}</label>
                <div>
                  <Select
                    mode="multiple"
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.unitViewFilter').d('Start typing to choose unit views')}
                  >
                    <OptGroup label={intl.get('booking_form.bodyLabels.placeholder.unitViews').d('Unit Views')}>
                      {state.unitViewOptions.map(unitViewOption => (
                        <Option key={unitViewOption.value} value={unitViewOption.value}>
                          {intl.get(`booking_form.bodyLabels.unitViewFilterOptions.${unitViewOption.label}`)}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </div>
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.property').d('Property')}</label>
                <InputGroup compact>
                  <Select
                    value={state.currentProperty}
                    showSearch
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.property').d('Select a property')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnPropertyChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                  >
                    {bookingForm.state.propertyOptions.map(properties => (
                      <Option value={properties.value} key={properties.value}>
                        {properties.label}
                      </Option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.roomType').d('Room Type')}</label>
                <InputGroup compact>
                  <Select
                    value={state.currentRoomType}
                    showSearch
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.roomType').d('Select a room type')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnRoomTypeChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    disabled={bookingForm.state.isNoUnit}
                  >
                    {bookingForm.state.roomTypeOptions.map(roomTypes => (
                      <Option value={roomTypes.value} key={roomTypes.value}>
                        {roomTypes.label}
                      </Option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.unitListing').d('Unit Listing')}</label>
                <InputGroup compact>
                  <Select
                    // mode="multiple"
                    value={state.currentUnit}
                    showSearch
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.unit').d('Select a unit')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnUnitChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    disabled={bookingForm.state.isNoUnit}
                  >
                    <OptGroup label={intl.get('booking_form.bodyLabels.placeholder.availableUnits').d('Available Units')}>
                      {bookingForm.state.unitOptions
                        .filter(units => units.isAvailable)
                        .map(units => (
                          <Option value={units.value} key={units.value}>
                            {units.label}
                          </Option>
                        ))}
                    </OptGroup>
                    <OptGroup label={intl.get('booking_form.bodyLabels.placeholder.unavailableUnits').d('Unavailable Units')}>
                      {bookingForm.state.unitOptions
                        .filter(units => !units.isAvailable)
                        .map(units => (
                          <Option value={units.value} key={units.value} disabled>
                            {units.label}
                          </Option>
                        ))}
                    </OptGroup>
                  </Select>
                </InputGroup>
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.roomRateModifier').d('Room Rate Modifier')}</label>
                <InputGroup compact>
                  <Select
                    disabled={!bookingForm.checkIsCommercialReservation()}
                    showSearch
                    value={state.currentRateModifier}
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.roomRateModifier').d('Select a room rate modifier')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnRateModifierChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                  >
                    {bookingForm.state.rateModifierOptions.map(roomRates => (
                      <Option value={roomRates.value} key={roomRates.value}>
                        {intl.get(`multicalendar.rate.${roomRates.label}`).d(roomRates.label)}
                      </Option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.numberOfPax').d('Number of Pax')}</label>
                <Input
                  value={state.totalOccupants}
                  placeholder={intl.get('booking_form.bodyLabels.placeholder.numberOfPax').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="totalOccupants"
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.bookingType').d('bookingType')}</label>
                <InputGroup compact>
                  <Select
                    value={state.currentBookingType}
                    showSearch
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.bookingType').d('Select a booking type')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnBookingTypeChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                  >
                    {bookingForm.state.bookingTypeOptions.map(bookingTypes => (
                      <Option value={bookingTypes.value} key={bookingTypes.value}>
                        {intl.get(`booking_form.bodyLabels.bookingTypeOptions.${bookingTypes.label}`).d(bookingTypes.label)}
                      </Option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.bodyLabels.bookingSource').d('Booking Source')}</label>
                <InputGroup compact>
                  <Select
                    disabled={!bookingForm.checkIsPlatform()}
                    value={state.currentPlatform}
                    showSearch
                    placeholder={intl.get('booking_form.bodyLabels.placeholder.bookingSource').d('Select a booking source')}
                    optionFilterProp="children"
                    onChange={bookingForm.handleOnBookingSourceChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '100%' }}
                    defaultValue={platformTypeHostPlatform}
                  >
                    {bookingForm.getPlatformOptions().map(platformOption => (
                      <Option value={platformOption.value} key={platformOption.value}>
                        {platformOption.label}
                      </Option>
                    ))}
                  </Select>
                </InputGroup>
              </div>
            </div>
          </Card>
          <Card
            title={intl.get('booking_form.remarkLabels.remarks').d('Remarks')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <div className="form-row">
              <label className="booking-label">{intl.get('booking_form.remarkLabels.optional').d('Remarks (optional)')}</label>
              <Input.TextArea
                placeholder={intl.get('booking_form.bodyLabels.placeholder.remarks').d('Remarks goes here...')}
                name="remarks"
                autoSize={{ minRows: 3, maxRows: 6 }}
                value={bookingForm.state.remarks}
                onChange={bookingForm.handleOnInputChange}
              />
            </div>
          </Card>
        </Fragment>
      )
    },
    {
      title: guestDetails,
      content: (
        <Fragment>
          <Card
            title={intl.get('booking_form.headerLabels.guestDetails').d('Guest Details')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <WrappedDynamicFieldSet
              disabled={!bookingForm.checkIsCommercialReservation()}
              onChange={bookingForm.handleGuestSubmit}
              data={bookingForm.state.guestDetails}
            />
          </Card>
          <Card
            title={intl.get('booking_form.guestDetails.emergencyContact').d('Emergency Contact')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.guestDetails.emergencyName').d('Emergency Contact Name')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  placeholder={intl.get('booking_form.guestDetails.emergencyName').d('Emergency Contact Name')}
                  type="text"
                  name="emergencyName"
                  pattern="[a-zA-Z]*"
                  value={bookingForm.state.emergencyName}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.guestDetails.emergencyContactNo').d('Emergency Contact Number')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  placeholder={intl.get('booking_form.guestDetails.placeholder.emergencyContactNumber').d('Emergency Contact No.')}
                  type="tel"
                  pattern="/^[+0-9][-\s0-9]*$/"
                  name="emergencyContact"
                  value={bookingForm.state.emergencyContact}
                  onChange={bookingForm.handleOnEmergencyContactInputChange}
                />
                {bookingForm.state.emergencyContactErrorMessage && (
                  <span style={{ color: 'red' }}>{bookingForm.state.emergencyContactErrorMessage}</span>
                )}
              </div>
            </div>
          </Card>
          <Card
            title={intl.get('booking_form.guestDetails.tenantVehicleReg').d("Tenant's Vehicle Registration")}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <WrappedTransportDynamicFieldSet
              disabled={!bookingForm.checkIsCommercialReservation()}
              onChange={bookingForm.handleVehicleSubmit}
              data={bookingForm.state.vehicleDetails}
            />
          </Card>
          <Card
            title={intl.get('booking_form.guestDetails.itemsProvided').d('Items Provided')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <ItemsProvided
              disabled={!bookingForm.checkIsCommercialReservation()}
              onChange={bookingForm.handleItemsProvidedSubmit}
              data={bookingForm.state.itemsProvided}
            />
          </Card>
        </Fragment>
      )
    },
    {
      title: otherCharges,
      content: (
        <Fragment>
          <Card
            title={intl.get('booking_form.otherCharges.serviceFee').d('Services Fees')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.earlyCheckIn').d('Early Check-in Fee')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  type="number"
                  step="any"
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  name="earlyCheckinFee"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.earlyCheckinFee}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.lateCheckOut').d('Late Check-out Fee')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="lateCheckoutFee"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.lateCheckoutFee}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.shuttleFee').d('Shuttle Fee')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="shuttleFee"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.shuttleFee}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.transportationFee').d('Transportation Fee')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="transportationFee"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.transportationFee}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.breakfast').d('Breakfast')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="breakfast"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.breakfast}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.lunch').d('Lunch')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="lunch"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.lunch}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.dinner').d('Dinner')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="dinner"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.dinner}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.others').d('Others')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="others"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.others}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
          </Card>
          <Card
            title={intl.get('booking_form.otherCharges.otherFees').d('Other Fees')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <div className="side-by-side">
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.cleaningFees').d('Cleaning Fees')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  step="any"
                  name="cleaningFees"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.cleaningFees}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.extraGuestFees').d('Extra Guest Fees')}</label>
                <Input
                  disabled={!bookingForm.checkIsCommercialReservation()}
                  addonBefore={state.currency || 'RM'}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                  type="number"
                  name="extraGuestFees"
                  defaultValue={0}
                  min={0}
                  value={bookingForm.state.extraGuestFees}
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            </div>
            <div className="side-by-side">
              {state.taxOptions.map(taxOption => {
                return (
                  <div className="form-row" key={`${taxOption.label}-${taxOption.value}`}>
                    <label className="booking-label">{intl.get(`booking_form.otherCharges.taxTypes.${taxOption.label}`).d(taxOption.label)}</label>
                    <Input
                      disabled={!bookingForm.checkIsCommercialReservation()}
                      addonBefore={state.currency || 'RM'}
                      placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                      type="number"
                      step="any"
                      name={taxOption.label}
                      defaultValue={0}
                      min={0}
                      value={bookingForm.state.taxObj[taxOption.code]}
                      onChange={bookingForm.handleOnTaxInputChange(taxOption.code)}
                    />
                  </div>
                );
              })}
            </div>
          </Card>
          {state.addOns.length > 0 && (
            <Card title="Activities" className="booking-details-card-container" bodyStyle={{ paddingTop: '10px' }}>
              {state.addOns.map(addOn => {
                return (
                  <div className="form-row" key={`${addOn._id}`}>
                    <div className="side-by-side">
                      <label className="booking-label">{addOn.activity}</label>
                      <label className="booking-label">
                        {state.currency} {addOn.amount} x{' '}
                      </label>
                      <Input
                        disabled={!bookingForm.checkIsCommercialReservation()}
                        placeholder="0"
                        type="number"
                        step="any"
                        addonAfter="pax"
                        name={addOn.pax}
                        defaultValue={0}
                        min={0}
                        // value={bookingForm.state.addOnObj[addOn.activity]}
                        onChange={bookingForm.handleOnAddOnInputChange(addOn.activity, addOn.amount)}
                      />
                    </div>
                  </div>
                );
              })}
            </Card>
          )}
          <Card
            title={intl.get('booking_form.otherCharges.paymentType').d('Payment Type')}
            className="booking-details-card-container"
            bodyStyle={{ paddingTop: '10px' }}
          >
            <div className="form-row">
              <label className="booking-label">{intl.get('booking_form.otherCharges.paymentMethod').d('Payment Method')}</label>
              <InputGroup compact>
                <Select
                  value={state.paymentMethod}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.paymentMethod').d('Select a payment method')}
                  onChange={bookingForm.handleOnPaymentChange}
                  style={{ width: '100%' }}
                >
                  {state.paymentMethodOptions.map(method => (
                    <Option key={method.code} value={method.code}>
                      {intl.get(`booking_form.otherCharges.paymentMethodOptions.${method.label}`)}
                    </Option>
                  ))}
                </Select>
              </InputGroup>
            </div>
            {state.paymentMethod === 'creditCard' && (
              <div className="side-by-side">
                <div className="form-row">
                  <label className="booking-label">
                    {intl.get('booking_form.otherCharges.paymentTypeLabels.credit/debit').d('Credit Card/Debit Card No.')}
                  </label>
                  <Input
                    value={bookingForm.state.cardNo}
                    placeholder={intl.get('booking_form.otherCharges.placeholder.credit').d('Enter credit card number')}
                    type="number"
                    name="cardNo"
                    onChange={bookingForm.handleOnInputChange}
                  />
                </div>
                <div className="form-row">
                  <label className="booking-label">{intl.get('booking_form.otherCharges.paymentTypeLabels.expiryDate').d('Expiry Date')}</label>
                  <Input
                    value={bookingForm.state.expiryDate}
                    placeholder={intl.get('booking_form.otherCharges.placeholder.expiry').d('MM/YY')}
                    type="text"
                    name="expiryDate"
                    onChange={bookingForm.handleOnInputChange}
                  />
                </div>
              </div>
            )}
            {state.paymentMethod === 'others' && (
              <div className="form-row">
                <label className="booking-label">{intl.get('booking_form.otherCharges.paymentTypeLabels.others').d('Remarks (optional)')}</label>
                <Input
                  value={bookingForm.state.paymentRemarks}
                  placeholder={intl.get('booking_form.otherCharges.placeholder.remarks').d('Enter remarks here')}
                  type="text"
                  name="paymentRemarks"
                  onChange={bookingForm.handleOnInputChange}
                />
              </div>
            )}
          </Card>
        </Fragment>
      )
    }
  ];
};

class BookingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bookingState: '',
      unitId: '',
      current: 0,
      startDate: '',
      startDateMoment: null,
      endDate: '',
      endDateMoment: null,
      totalOccupants: 1,
      earlyCheckinFee: 0,
      lateCheckoutFee: 0,
      shuttleFee: 0,
      transportationFee: 0,
      breakfast: 0,
      lunch: 0,
      dinner: 0,
      others: 0,
      remarks: '',
      dateErr: '',
      paymentMethod: '',
      cardNo: '',
      expiryDate: '',
      month: '',
      unitViews: [],
      cleaningFees: 0,
      extraGuestFees: 0,
      refundableDeposit: 0,
      currentProperty: '',
      currentRoomType: '',
      currentUnit: '',
      currentBookingType: '',
      currentPlatform: '',
      currentRoomRate: '',
      currentRateModifier: '',
      currentHost: '',
      bookingNights: 0,
      bookingPrice: 0,
      outstandingBalance: 0,
      paymentReceived: 0,
      depositCollected: false,
      bookingType: [],
      isNonProfitBookingType: false,
      guestDetails: [],
      vehicleDetails: [],
      emergencyContact: '',
      emergencyContactErrorMessage: '',
      emergencyName: '',
      roomTypeOptions: [],
      unitViewOptions: [],
      propertyOptions: [],
      unitOptions: [],
      fullUnitOptions: [],
      bookingTypeOptions: [],
      bookingTypeKeyValues: {},
      bookingStatesOptions: [],
      rateModifierOptions: [],
      taxOptions: [],
      addOns: [],
      platformOptions: [],
      platformKeyValues: {},
      sourceConstants: [],
      taxObj: {},
      addOnObj: {},
      isNoUnit: false,
      bookingTotal: 0,
      originalBookingTotal: 0,
      currency: 'RM',
      timezone: '',
      isFeeReady: false,
      confirmationCode: '',
      submitting: false,
      itemsProvided: [],
      paymentMethodOptions: [],
      isButtonLoading: false,
      isLoading: true,
      guestDetailsNextReady: false,
      vehicleDetailsNextReady: true,
      itemsProvidedDetailsNextReady: true,
      duplicatedFrom: ''
    };
    this.setCurrentDate = this.setCurrentDate.bind(this);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.handleOnStartCalendarChange = this.handleOnStartCalendarChange.bind(this);
    this.handleOnEndCalendarChange = this.handleOnEndCalendarChange.bind(this);
    this.handleGuestSubmit = this.handleGuestSubmit.bind(this);
    this.handleVechicleSubmit = this.handleVehicleSubmit.bind(this);
    this.handleItemsProvidedSubmit = this.handleItemsProvidedSubmit.bind(this);
    this.handleOnToggleChange = this.handleOnToggleChange.bind(this);
    this.handleOnCheckboxChange = this.handleOnCheckboxChange.bind(this);
    this.handleOnPropertyChange = this.handleOnPropertyChange.bind(this);
    this.handleOnRoomTypeChange = this.handleOnRoomTypeChange.bind(this);
    this.handleOnBookingTypeChange = this.handleOnBookingTypeChange.bind(this);
    this.handleOnBookingSourceChange = this.handleOnBookingSourceChange.bind(this);
    this.handleOnUnitChange = this.handleOnUnitChange.bind(this);
    this.handleOnRateModifierChange = this.handleOnRateModifierChange.bind(this);
    this.handleOnPaymentChange = this.handleOnPaymentChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.checkIsCommercialReservation = this.checkIsCommercialReservation.bind(this);
    this.checkIsPlatform = this.checkIsPlatform.bind(this);
    this.success = this.success.bind(this);
    this.handleOnTaxInputChange = this.handleOnTaxInputChange.bind(this);
    this.handleOnAddOnInputChange = this.handleOnAddOnInputChange.bind(this);
    this.recalculatePrice = this.recalculatePrice.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
  }

  componentDidMount = async () => {
    this.setCurrentDate(new Date());
    this.fetchIntegrationSources();

    const { location } = this.props;

    if (location.search) {
      const unitQuery = queryString.parse(location.search).unit;
      const startDateQuery = queryString.parse(location.search).startDate;
      const endDateQuery = queryString.parse(location.search).endDate;
      const propertyQuery = queryString.parse(location.search).property;
      const roomTypeQuery = queryString.parse(location.search).roomType;
      const resIdQuery = queryString.parse(location.search).resId;

      if (!!resIdQuery) {
        getReservationsById(resIdQuery, { isWithTransaction: true })
          .then(res => {
            if (res && res.status === 200) {
              this.setState({
                currentHost: res.data.unit.roomType.property.host._id,
                currentProperty: res.data.unit.roomType.property._id,
                currentRoomType: res.data.unit.roomType._id,
                currentUnit: res.data.unit._id,
                startDate: res.data.endDate,
                startDateMoment: moment(res.data.endDate),
                endDate: moment(res.data.endDate)
                  .add(1, 'days')
                  .format('YYYY-MM-DD'),
                endDateMoment: moment(res.data.endDate).add(1, 'days'),
                currentBookingType: 1,
                bookingState: res.data.bookingStatus,
                currentPlatform: res.data.platform,
                remarks: res.data.remarks,
                guestDetails: [res.data.guestDetails.userProfile],
                currentUserProfile: res.data.guestDetails.userProfile._id,
                emergencyContact: res.data.guestDetails.emergency ? res.data.guestDetails.emergency.emergencyContact : '',
                emergencyName: res.data.guestDetails.emergency ? res.data.guestDetails.emergency.emergencyName : '',
                duplicatedFrom: res.data._id
              });
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      } else {
        this.setState({
          currentProperty: propertyQuery,
          currentUnit: unitQuery,
          currentRoomType: roomTypeQuery,
          startDate: startDateQuery,
          startDateMoment: moment(startDateQuery),
          endDate: endDateQuery,
          endDateMoment: moment(endDateQuery)
        });
      }
    }

    getConstants('roomViews').then(resUnitViews => {
      if (resUnitViews && resUnitViews.status === 200) {
        let unitViews = Object.keys(resUnitViews.data).map(k => {
          return resUnitViews.data[k];
        });
        if (unitViews.length > 0) {
          this.setState({
            unitViewOptions: unitViews.map(u => {
              return {
                value: u.code,
                label: u.label
              };
            })
          });
        }
      } else {
        console.log('Error while retrieving unit views');
      }
    });

    getPaymentMethodsConstant().then(res => {
      if (res) {
        const paymentMethods = Object.keys(res).map(k => res[k]);
        if (paymentMethods.length > 0) {
          this.setState({
            paymentMethodOptions: paymentMethods.map(method => {
              return { label: method.label, code: method.code };
            }),
            paymentMethod: paymentMethods[0].code
          });
        }
      }
    });

    const { bookingTypeKeyValues, bookingTypeOptions, currentBookingType } = await getBookingTypesConstant().then(resBookingTypes => {
      if (resBookingTypes) {
        const bookingTypes = Object.keys(resBookingTypes).map(k => resBookingTypes[k]);
        if (bookingTypes.length > 0) {
          return {
            bookingTypeKeyValues: resBookingTypes,
            bookingTypeOptions: bookingTypes.map(u => {
              return { value: u.code, label: u.label, hasPrice: u.hasPrice };
            }),
            currentBookingType: this.state.currentBookingType ? this.state.currentBookingType : bookingTypes[0].code
          };
        }
        return {};
      }
    });
    const { bookingStatesOptions, bookingState } = await getBookingStatusConstant().then(resBookingStates => {
      if (resBookingStates) {
        return {
          bookingStatesOptions: Object.values(resBookingStates)
            .filter(bookingStates => bookingStates.showDuringCreate)
            .map(bookingStates => {
              return (
                <Option key={bookingStates.code} value={bookingStates.code}>
                  {intl.get(`booking_form.bodyLabels.bookingStates.${bookingStates.label}`)}
                </Option>
              );
            }),
          bookingState: Object.values(resBookingStates).filter(b => b.showDuringCreate)[0]
            ? Object.values(resBookingStates).filter(b => b.showDuringCreate)[0].code
            : ''
        };
      }
      return {};
    });

    if (!!bookingTypeOptions && !!bookingState) {
      const isNonProfitBookingType = !!currentBookingType && !this.checkIsCommercialReservation(currentBookingType);
      const newBookingState = isNonProfitBookingType ? 'Confirmed' : bookingState;
      this.setState({
        bookingTypeKeyValues,
        bookingTypeOptions,
        bookingStatesOptions,
        currentBookingType: this.state.currentBookingType ? this.state.currentBookingType : currentBookingType,
        bookingState: newBookingState,
        isNonProfitBookingType
      });
    }

    getProperties()
      .then(properties => {
        if (properties.length > 0) {
          const currentProperty = queryString.parse(location.search).property || properties[0]._id;
          const foundProperty = properties.find(property => String(property._id) === String(currentProperty));
          this.setState({
            propertyOptions: properties.map(property => {
              return { value: property._id, label: property.name, host: property.host };
            }),
            currentProperty: this.state.currentProperty ? this.state.currentProperty : currentProperty,
            currentHost: this.state.currentHost ? this.state.currentHost : foundProperty && foundProperty.host
          });
        }
      })
      .catch(err => {
        message.error(err);
        return [{ label: '', value: '', host: '' }];
      });

    getConstants('taxType').then(resTaxes => {
      if (resTaxes && resTaxes.status === 200) {
        let taxes = Object.keys(resTaxes.data).map(k => {
          return resTaxes.data[k];
        });
        if (taxes.length > 0) {
          this.setState({
            taxOptions: taxes.map(u => {
              return { label: u.label, code: u.code };
            })
          });
        }
        let taxObj = {};
        this.state.taxOptions.forEach(taxType => {
          taxObj[taxType.code] = 0;
          this.setState({
            taxObj
          });
        });
      } else {
        console.log('Error while retrieving taxes');
      }
    });
  };

  getTaxArray() {
    let { taxObj } = this.state;
    if (taxObj) {
      return Object.keys(taxObj).map(key => {
        return {
          name: key,
          amount: parseFloat(taxObj[key] || 0)
        };
      });
    } else {
      return [];
    }
  }

  getAddOnArray() {
    let { addOnObj } = this.state;
    if (addOnObj) {
      return Object.keys(addOnObj).map(key => {
        return {
          activity: key,
          amount: parseFloat(addOnObj[key].amount || 0),
          amountPerPax: parseFloat(addOnObj[key].amountPerPax || 0),
          pax: addOnObj[key].pax
        };
      });
    } else {
      return [];
    }
  }

  fetchIntegrationSources = () => {
    getIntegrationSourcesConstant()
      .then(res => {
        this.setIntegrationState(res);
      })
      .catch(err => console.error(err));
  };

  setIntegrationState = sources => {
    const formattedSources = Object.keys(sources).map(key => ({
      value: sources[key].code,
      label: sources[key].label
    }));

    this.setState({
      platformOptions: formattedSources,
      platformKeyValues: sources,
      currentPlatform: sources.HOSTPLATFORM.code // Set default value
    });
  };

  getPlatformOptions = () => {
    const { platformOptions, currentBookingType, bookingTypeKeyValues, platformKeyValues } = this.state;
    const bookingTypePlatform = bookingTypeKeyValues.PLATFORM && bookingTypeKeyValues.PLATFORM.code;
    const platformTypeHostPlatform = platformKeyValues.HOSTPLATFORM && platformKeyValues.HOSTPLATFORM.code;
    switch (currentBookingType) {
      case bookingTypePlatform:
        return platformOptions.filter(option => option.value !== platformTypeHostPlatform);
      default:
        return platformOptions.filter(option => option.value === platformTypeHostPlatform);
    }
  };

  recalculatePrice() {
    if (this.state.isFeeReady === true) {
      this.setState({
        bookingTotal: 0,
        originalBookingTotal: 0,
        isFeeReady: false
      });
    }
    if (this.state.startDate && this.state.endDate && this.state.currentUnit && this.state.currentRoomRate) {
      if (this.checkIsCommercialReservation) {
        const {
          currentUnit,
          currentRoomRate,
          currentBookingType,
          currentRateModifier,
          startDate,
          endDate,
          cleaningFees,
          extraGuestFees,
          earlyCheckinFee,
          lateCheckoutFee,
          transportationFee,
          breakfast,
          lunch,
          dinner,
          others,
          shuttleFee,
          bookingTypeKeyValues
        } = this.state;
        calculatePrice(
          currentUnit,
          currentBookingType === bookingTypeKeyValues && bookingTypeKeyValues.FREESTAY && bookingTypeKeyValues.FREESTAY.code ? '' : currentRoomRate,
          startDate,
          endDate,
          cleaningFees,
          extraGuestFees,
          earlyCheckinFee,
          lateCheckoutFee,
          transportationFee,
          breakfast,
          lunch,
          dinner,
          others,
          shuttleFee,
          this.getTaxArray(),
          this.getAddOnArray(),
          currentRateModifier
        ).then(resObj => {
          // console.log(resObj);
          if (resObj && resObj.status === 200) {
            this.setState({
              bookingTotal: resObj.data.totalFee,
              originalBookingTotal: resObj.data.originalTotalFee,
              isFeeReady: true,
              currency: resObj.data.currency,
              timezone: resObj.data.timezone
            });
          } else {
            if (resObj && resObj.data && resObj.data.error) {
              // console.log(resObj.data.error);
            } else {
              if (resObj) {
                console.log({ error: resObj });
              }
              console.log('Calculate price error');
            }
          }
        });
      } else {
        this.setState({
          bookingTotal: 0,
          originalBookingTotal: 0,
          isFeeReady: false
        });
      }
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { location } = this.props;
    if (
      !this.state.dateErr &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate <= this.state.endDate &&
      (this.state.startDate !== prevState.startDate || this.state.endDate !== prevState.endDate)
    ) {
      this.setState({
        bookingNights: (new Date(this.state.endDate) - new Date(this.state.startDate)) / (1000 * 60 * 60 * 24)
      });
    } else if (this.state.dateErr && this.state.startDate && this.state.endDate && this.state.startDate < this.state.endDate) {
      this.setState({
        dateErr: ''
      });
    }

    if (this.state.endDate <= this.state.startDate) {
      const startDateYMD = this.state.startDate.split('-');
      this.setCurrentDate(new Date(startDateYMD[0], startDateYMD[1] - 1, startDateYMD[2]));
    }

    if (
      this.state.currentProperty &&
      (prevState.currentProperty !== this.state.currentProperty ||
        prevState.unitViews !== this.state.unitViews ||
        prevState.totalOccupants !== this.state.totalOccupants ||
        prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate)
    ) {
      getProperty(this.state.currentProperty).then(resProperty => {
        if (resProperty) {
          let property = resProperty;
          if (property.addOns && property.addOns.length > 0) {
            const addOns = property.addOns.map(a => {
              return {
                activity: a.activity,
                amount: a.amount
              };
            });
            this.setState({
              addOns: addOns
            });
            let addOnObj = {};
            this.state.addOns.forEach(addOn => {
              addOnObj[addOn.activity] = {
                amount: 0,
                pax: 0,
                amountPerPax: 0
              };
              this.setState({
                addOnObj
              });
            });
          } else {
            this.setState({
              addOns: []
            });
          }
        } else {
          console.log('Error while retrieving property');
        }
      });
      getUnitListings({
        propertyId: this.state.currentProperty,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        totalOccupants: this.state.totalOccupants,
        unitViews: this.state.unitViews,
        isBookingLogic: true
      }).then(resUnits => {
        if (resUnits && resUnits.status === 200) {
          let units = resUnits.data;
          if (units.length > 0) {
            const fullUnitOptions = units.map(u => {
              return {
                value: u._id,
                label: u.name,
                roomType: u.roomType._id,
                isAvailable: checkHasValue(u.isAvailable) ? u.isAvailable : u.reservations.length === 0
              };
            });
            let roomTypeOptions = units
              .filter((unit, i, arr) => {
                return (
                  unit.roomType && unit.roomType._id && arr.map(unit => unit.roomType._id.toString()).indexOf(unit.roomType._id.toString()) === i
                );
              })
              .map(k => {
                return { value: k.roomType._id, label: k.roomType.name };
              });
            this.setState({
              fullUnitOptions,
              roomTypeOptions,
              isNoUnit: false
            });
            let currentRoomType = this.state.currentRoomType;
            if (!this.state.currentRoomType || !roomTypeOptions.map(roomTypeOption => roomTypeOption.value).includes(this.state.currentRoomType)) {
              currentRoomType = roomTypeOptions.length > 0 ? roomTypeOptions[0].value : null;
              this.setState({ currentRoomType });
            }

            let unitOptions = fullUnitOptions.filter(u => u.roomType === currentRoomType);
            this.setState({ unitOptions });

            if (
              !this.state.currentUnit ||
              !unitOptions
                .filter(unitOption => unitOption.isAvailable)
                .map(unitOption => unitOption.value)
                .includes(this.state.currentUnit)
            ) {
              this.setState({
                currentUnit:
                  unitOptions.filter(unitOption => unitOption.isAvailable).length > 0
                    ? unitOptions.filter(unitOption => unitOption.isAvailable)[0].value
                    : null
              });
            }
          } else {
            this.setState({
              fullUnitOptions: [],
              roomTypeOptions: [],
              unitOptions: [],
              currentRoomType: this.state.currentRoomType ? this.state.currentRoomType : '',
              currentUnit: this.state.currentUnit ? this.state.currentUnit : queryString.parse(location.search).unit || '',
              isNoUnit: true
            });
          }
        } else {
          console.log('Error while retrieving booking types');
        }
      });
    }

    if (prevState.currentRoomType !== this.state.currentRoomType) {
      const unitOptions = this.state.fullUnitOptions.filter(unit => unit.roomType.toString() === this.state.currentRoomType.toString());
      getWebRateByRoomType(this.state.currentRoomType).then(resRoomRates => {
        if (resRoomRates && resRoomRates.status === 200) {
          const roomRates = resRoomRates.data[0];
          this.setState({
            currentRoomRate: roomRates._id || undefined
          });
        } else {
          console.log('Error while retrieving rates');
        }
      });
      const filteredUnits = unitOptions.filter(unitOption => unitOption.isAvailable);
      let currentUnit = filteredUnits.length > 0 ? filteredUnits[0].value : '';
      if (
        queryString.parse(location.search).roomType &&
        this.state.currentRoomType.toString() === queryString.parse(location.search).roomType.toString()
      ) {
        if (queryString.parse(location.search).unit && this.state.currentUnit === queryString.parse(location.search).unit) {
          currentUnit = queryString.parse(location.search).unit;
        }
      }
      this.setState({
        unitOptions,
        currentUnit: this.state.currentUnit ? this.state.currentUnit : currentUnit
      });
    }

    if (prevState.currentHost !== this.state.currentHost) {
      getRateModifiersByHost(this.state.currentHost)
        .then(rateModifiers => {
          const rateModifierOptions = [{ value: '', label: 'Web Rate' }].concat(
            rateModifiers.map(rateModifier => {
              const { _id, name, type, amount, isPositive } = rateModifier;
              return { value: _id, label: name, type, amount, isPositive };
            })
          );
          this.setState({
            rateModifierOptions
          });
        })
        .catch(ex => {
          this.setState({ rateModifierOptions: [{ value: '', label: '' }] });
          console.error(ex);
        });
    }

    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.currentUnit !== this.state.currentUnit ||
      prevState.currentRoomRate !== this.state.currentRoomRate ||
      prevState.cleaningFees !== this.state.cleaningFees ||
      prevState.extraGuestFees !== this.state.extraGuestFees ||
      prevState.earlyCheckinFee !== this.state.earlyCheckinFee ||
      prevState.lateCheckoutFee !== this.state.lateCheckoutFee ||
      prevState.transportationFee !== this.state.transportationFee ||
      prevState.breakfast !== this.state.breakfast ||
      prevState.lunch !== this.state.lunch ||
      prevState.dinner !== this.state.dinner ||
      prevState.others !== this.state.others ||
      prevState.shuttleFee !== this.state.shuttleFee ||
      prevState.taxObj !== this.state.taxObj ||
      prevState.addOnObj !== this.state.addOnObj ||
      prevState.currentBookingType !== this.state.currentBookingType ||
      prevState.currentRateModifier !== this.state.currentRateModifier
    ) {
      this.recalculatePrice();
    }

    if (prevState.currentBookingType !== this.state.currentBookingType) {
      const { bookingTypeKeyValues, platformKeyValues } = this.state;
      const bookingTypePlatform = bookingTypeKeyValues && bookingTypeKeyValues.PLATFORM && bookingTypeKeyValues.PLATFORM.code;
      const platformTypeHostPlatform = platformKeyValues && platformKeyValues.HOSTPLATFORM && platformKeyValues.HOSTPLATFORM.code;
      const platformTypeAirbnb = platformKeyValues && platformKeyValues.AIRBNB && platformKeyValues.AIRBNB.code;
      if (this.state.currentBookingType !== bookingTypePlatform) {
        this.setState({
          currentPlatform: platformTypeHostPlatform
        });
      } else {
        this.setState({
          currentPlatform: platformTypeAirbnb
        });
      }
    }
  }

  setCurrentDate(date) {
    const today = date;
    const dd = today.getDate();
    const nextDD = today.getDate() + 1;
    const mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();
    const todayString = yyyy + '-' + ('0' + mm).slice(-2) + '-' + ('0' + dd).slice(-2);
    const tomorrow = new Date(yyyy, mm - 1, nextDD);
    const tomorrowMM = tomorrow.getMonth() + 1;
    const nextDay = tomorrow.getFullYear() + '-' + ('0' + tomorrowMM).slice(-2) + '-' + ('0' + tomorrow.getDate()).slice(-2);
    this.setState({
      startDate: todayString,
      startDateMoment: moment(today),
      endDate: nextDay,
      endDateMoment: moment(nextDay),
      month: mm
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();

    const {
      startDate,
      endDate,
      guestDetails,
      totalOccupants,
      earlyCheckinFee,
      lateCheckoutFee,
      shuttleFee,
      transportationFee,
      breakfast,
      dinner,
      lunch,
      others,
      remarks,
      cleaningFees,
      extraGuestFees,
      refundableDeposit,
      currentProperty,
      currentRoomType,
      currentRoomRate,
      currentUnit,
      currentBookingType,
      bookingNights,
      bookingPrice,
      paymentReceived,
      bookingTotal,
      emergencyContact,
      emergencyName,
      vehicleDetails,
      currentUserProfile,
      paymentMethod,
      paymentRemarks,
      cardNo,
      expiryDate,
      itemsProvided,
      bookingState,
      currentPlatform,
      currentRateModifier,
      duplicatedFrom
    } = this.state;

    if (currentBookingType === '') {
      Modal.error({
        title: intl.get('booking_form.problemSubmit.title.bookingType').d('Missing booking type!'),
        content: intl.getHTML('booking_form.problemSubmit.content.bookingType').d('<div>Please choose a booking type!</div>')
      });
      this.setState({
        current: 0
      });
      return;
    }
    if (!startDate || !endDate) {
      Modal.error({
        title: intl.get('booking_form.problemSubmit.title.date').d('Missing Dates'),
        content: intl.getHTML('booking_form.problemSubmit.content.date').d('<div>Please enter start/end date of the reservation!</div>')
      });
      this.setState({
        current: 0
      });
      return;
    }
    if (!currentUnit) {
      Modal.error({
        title: intl.get('booking_form.problemSubmit.title.units').d('Missing Units'),
        content: intl.getHTML('booking_form.problemSubmit.content.units').d('<div>Please choose an available unit!</div>')
      });
      this.setState({
        current: 0
      });
      return;
    }
    if (this.checkIsCommercialReservation()) {
      if (!paymentMethod) {
        Modal.error({
          title: intl.get('booking_form.problemSubmit.title.paymentMethod').d('Missing Payment Method'),
          content: intl.getHTML('booking_form.problemSubmit.content.paymentMethod').d('<div>Please pick a payment method!</div>')
        });
        this.setState({
          current: 0
        });
        return;
      }
      if (!currentRoomRate) {
        Modal.error({
          title: intl.get('booking_form.problemSubmit.title.roomRate').d('Missing Room Rate'),
          content: intl.getHTML('booking_form.problemSubmit.content.roomRate').d('<div>Please pick a room rate!</div>')
        });
        this.setState({
          current: 0
        });
        return;
      }
      if (!currentUserProfile) {
        if (guestDetails.length === 0) {
          Modal.error({
            title: intl.get('booking_form.problemSubmit.title.guest').d('Missing Guest Details'),
            content: intl.getHTML('booking_form.problemSubmit.content.guest').d('<div>Please enter at least one guest detail!</div>')
          });
          this.setState({
            current: 1
          });
          return;
        }
      }
    }

    this.setState({ isButtonLoading: true });

    var isNotHasPriceBooking = {};
    if (![4, 5, 6].includes(currentBookingType)) {
      isNotHasPriceBooking = { bookingStatus: bookingState };
    }

    createReservation({
      startDate,
      endDate,
      guestDetails: {
        numberOfPax: totalOccupants,
        occupants: guestDetails
      },
      emergency: {
        emergencyContact,
        emergencyName
      },
      itemsProvided: itemsProvided.map(items => {
        return {
          item: items.item,
          label: items.label
        };
      }),
      vehicleDetails: vehicleDetails.map(vehicleDetail => {
        return {
          vehicleType: vehicleDetail.vehicleType,
          vehicleModel: vehicleDetail.vehicleModel,
          vehicleNo: vehicleDetail.vehicleNo,
          vehicleColor: vehicleDetail.vehicleColor
        };
      }),
      remarks,
      cleaningFee: cleaningFees,
      extraFee: extraGuestFees,
      depositeCollected: refundableDeposit,
      property: currentProperty,
      roomType: currentRoomType,
      rate: currentRoomRate,
      unit: currentUnit,
      bookingType: currentBookingType,
      bookingNights,
      bookingPrice,
      paymentReceived,
      bookingTotal,
      serviceFees: {
        earlyCheckinFee: earlyCheckinFee,
        lateCheckoutFee: lateCheckoutFee,
        transportFee: transportationFee,
        breakfastFee: breakfast,
        dinnerFee: dinner,
        lunchFee: lunch,
        otherFee: others,
        shuttleFee: shuttleFee
      },
      paymentMethod,
      paymentRemarks,
      creditCard: {
        cardNo: cardNo,
        expiryDate: expiryDate
      },
      tax: this.getTaxArray(),
      addOns: this.getAddOnArray(),
      // bookingStatus: bookingState,
      platform: currentPlatform,
      rateModifier: currentRateModifier,
      duplicatedFrom: duplicatedFrom,
      ...isNotHasPriceBooking
    })
      .then(res => {
        if (res.status === 201) {
          if (duplicatedFrom && currentBookingType === 1) {
            const formattedUpdatePayload = { duplicatedTo: res.data[0]._id };
            updateReservation(duplicatedFrom, formattedUpdatePayload);
          }
          const confirmationCode = res.data.map((val, index, array) => (index !== array.length - 1 ? val.code + ', ' : val.code));
          const unitId = res.data.map(val => ({ unitId: val._id, confirmationCode: val.code }));
          this.setState({
            // confirmationCode: res.data.code,
            // unitId: res.data._id
            confirmationCode: confirmationCode,
            unitId: unitId
          });
          this.success(startDate);
        }
      })
      .catch(error => {});
  }

  success(reservationStartDate) {
    const { confirmationCode, unitId, currentProperty, currentRoomType } = this.state;
    Modal.success({
      title: intl.getHTML('booking_form.sucessSubmit.title').d('Successfully created reservation!'),
      content: (
        <div>
          <div>
            {intl
              .getHTML('booking_form.sucessSubmit.confirmationCode', { confirmationCode })
              .d(`Your confirmation code is: <b>${confirmationCode}</b>`)}
          </div>
          {/* TOOD: Add actual links*/}
          <br />
          <div>
            <div>
              <Icon type="plus-square-o" className="icon-square" />
              <a href="/bookingform"> {intl.getHTML('booking_form.sucessSubmit.createAnotherReservation').d('Create another reservation')}</a>
            </div>
            {unitId.map(unit => (
              <div key={unit.unitId}>
                <Icon type="eye-o" className="icon-eye" />
                <a href={'/reservation/' + unit.unitId + '/edit'}>
                  {' '}
                  {intl.getHTML('booking_form.sucessSubmit.viewReservation').d('View the created reservation')}
                  {/* ({unit.confirmationCode}) */}
                </a>
              </div>
            ))}
            {/* <div>
              <Icon type="eye-o" className="icon-eye" />
              <a href={'/reservation/' + unitId + '/edit'}> View the created reservation</a>
            </div> */}
            <div>
              <Icon type="calendar" className="icon-calendar" />
              <a
                href={buildMultiCalendarUri({
                  property: currentProperty,
                  roomType: currentRoomType,
                  date: reservationStartDate
                })}
              >
                &nbsp;{intl.getHTML('booking_form.sucessSubmit.multiCalendar').d('Go to multi-calendar')}
              </a>
            </div>
          </div>
        </div>
      ),
      onOk: () => {
        window.location.reload();
      }
    });
  }

  checkIsCommercialReservation = bookingType => {
    const { bookingTypeOptions, currentBookingType } = this.state;
    const currentSelectedBookingType = bookingType || currentBookingType;

    const commercialBookingType = bookingTypeOptions.filter(bookingType => bookingType.hasPrice).map(bookingType => String(bookingType.value));
    return commercialBookingType.includes(currentSelectedBookingType.toString());
  };

  checkIsPlatform() {
    return ['3'].includes(this.state.currentBookingType.toString());
  }

  handleOnInputChange(e) {
    const target = e.target;
    let value = target.value;
    this.setState({
      [target.name]: value
    });
  }

  handleOnEmergencyContactInputChange = e => {
    const emergencyContact = e.target.value;
    let emergencyContactErrorMessage = '';
    if (emergencyContact && !checkValidContactNumber(emergencyContact)) {
      emergencyContactErrorMessage = intl
        .get('booking_form.problemSubmit.title.emergencyContactErrorMessage')
        .d('Please input a valid emergency contact number.');
    }
    this.setState({
      emergencyContact,
      emergencyContactErrorMessage
    });
  };

  handleOnTaxInputChange = taxName => e => {
    let { taxObj } = this.state;
    taxObj[taxName] = e.target.value;
    this.setState(
      {
        taxObj
      },
      () => {
        this.recalculatePrice();
      }
    );
  };

  handleOnAddOnInputChange = (activityName, amount) => e => {
    let { addOnObj } = this.state;
    addOnObj[activityName] = {
      amountPerPax: amount,
      amount: e.target.value * amount,
      pax: e.target.value
    };
    this.setState(
      {
        addOnObj
      },
      () => {
        this.recalculatePrice();
      }
    );
  };

  handleOnSelectChange = field => e => {
    this.setState({
      [field]: e
    });
  };

  handleOnPaymentChange(e) {
    this.setState({
      paymentMethod: e
    });
  }

  handleOnCheckboxChange(e) {
    this.setState({
      unitViews: e
    });
  }

  handleOnBookingTypeChange = selectedBookingType => {
    const { bookingState } = this.state;
    const isNonProfitBookingType = !!selectedBookingType && !this.checkIsCommercialReservation(selectedBookingType);
    const newBookingState = isNonProfitBookingType ? 'Confirmed' : bookingState;

    this.setState({
      bookingState: newBookingState,
      isNonProfitBookingType,
      currentBookingType: selectedBookingType
    });
  };

  handleOnBookingSourceChange(e) {
    this.setState({
      currentPlatform: e
    });
  }

  handleOnPropertyChange(e) {
    const { propertyOptions } = this.state;
    const chosenProperty = propertyOptions.find(property => property.value === e);
    this.setState({
      currentProperty: e,
      currentHost: chosenProperty && chosenProperty.host
    });
  }

  handleOnRoomTypeChange(e) {
    this.setState({
      currentRoomType: e
    });
  }

  handleOnUnitChange(e) {
    this.setState({
      currentUnit: e
    });
  }

  handleOnRateModifierChange(e) {
    this.setState({
      currentRateModifier: e
    });
  }

  handleOnToggleChange() {
    this.setState({ depositCollected: !this.state.depositCollected });
  }

  handleGuestSubmit = (values, nextReady, standard = true) => {
    if (values && standard) {
      const guestDetails = values.keys.map(key => {
        if (!values.firstName[key] || !values.lastName[key]) {
          nextReady = false;
        }

        return {
          key,
          firstName: values.firstName[key],
          lastName: values.lastName[key],
          nationality: values.nationality[key],
          contactNo: values.contactNo[key],
          state: values.state[key],
          icNo: values.icNo && values.icNo[key] ? values.icNo[key] : '',
          email: values.email && values.email[key] ? values.email[key] : ''
        };
      });
      this.setState({
        guestDetails
      });
    } else if (values && !standard) {
      if (!values.firstName || !values.lastName) {
        nextReady = false;
      }

      const guestDetails = {
        key: 0,
        firstName: values.firstName,
        lastName: values.lastName,
        nationality: values.nationality,
        contactNo: values.contactNos && values.contactNos[0] ? values.contactNos[0] : values.contactNo,
        state: values.state,
        icNo: values.identificationNo ? values.identificationNo : values.icNo,
        email: values.emails && values.emails[0] ? values.emails[0] : values.email,
        userProfile: this.state.currentUserProfile
      };
      this.setState({
        guestDetails: [guestDetails]
      });
    }
    this.setState({
      guestDetailsNextReady: nextReady
    });
  };

  handleVehicleSubmit = (values, nextReady) => {
    if (values) {
      const vehicleDetails = values.keys.map(key => {
        return {
          key,
          vehicleType: values.vehicleType ? values.vehicleType[key] : '',
          vehicleModel: values.vehicleModel ? values.vehicleModel[key] : '',
          vehicleNo: values.vehicleNo ? values.vehicleNo[key] : '',
          vehicleColor: values.vehicleColor ? values.vehicleColor[key] : ''
        };
      });
      this.setState({
        vehicleDetails
      });
    }

    this.setState({
      vehicleDetailsNextReady: nextReady
    });
  };

  handleItemsProvidedSubmit = (values, nextReady) => {
    if (values) {
      const itemsProvided = values.keys.map(key => {
        return {
          key,
          item: values.item ? values.item[key] : '',
          label: values.label ? values.label[key] : ''
        };
      });
      this.setState({
        itemsProvided
      });
    }

    this.setState({
      itemsProvidedDetailsNextReady: nextReady
    });
  };

  handleOnStartCalendarChange(date, dateString) {
    this.setState({
      startDateMoment: date,
      startDate: dateString
    });
  }

  handleOnEndCalendarChange(date, dateString) {
    this.setState({
      endDateMoment: date,
      endDate: dateString
    });
  }

  next = () => {
    const { current, guestDetailsNextReady, vehicleDetailsNextReady, itemsProvidedDetailsNextReady } = this.state;
    current === 1
      ? guestDetailsNextReady && vehicleDetailsNextReady && itemsProvidedDetailsNextReady
        ? this.setState({ current: current + 1 })
        : message.warning(intl.get('booking_form.problemSubmit.title.requiredFields').d('Please fill up all the required fields'))
      : this.setState({ current: current + 1 });
    window.scroll(0, 0);
  };
  prev = () => {
    const { current } = this.state;

    this.setState({
      current: current - 1
    });
    window.scroll(0, 0);
  };

  goToStep = e => i => {
    if (i >= 0 && i < 3) {
      this.setState({
        current: i
      });
    }
  };

  constructDiscountLabel = () => {
    const { currentRateModifier, rateModifierOptions, bookingTotal, originalBookingTotal, currentUnit, bookingNights } = this.state;
    if (!currentUnit) {
      return ``;
    }
    const currentRateModifierObj = rateModifierOptions.find(rateModifier => rateModifier.value === currentRateModifier);
    if (currentRateModifierObj) {
      const { isPositive, amount, type } = currentRateModifierObj;
      let discountAmount = 0;
      if (type === 'amount') {
        discountAmount = amount * bookingNights;
      } else {
        discountAmount = Math.abs(bookingTotal - originalBookingTotal);
      }
      if (discountAmount === 0) {
        return ``;
      } else {
        return isPositive
          ? `(+${this.state.currency}${numberWithCommas(discountAmount)})`
          : `(-${this.state.currency}${numberWithCommas(discountAmount)})`;
      }
    }
  };

  render() {
    const { current, isButtonLoading, isFeeReady, currentUnit, originalBookingTotal, bookingTotal, bookingNights } = this.state;
    const { checkIsAdminReadOnly } = this.props;
    const showFee = isFeeReady || !currentUnit;
    const discountAmountLabel = this.constructDiscountLabel();

    return (
      <Form className="booking-form-form">
        {this.state.loading ? (
          <Skeleton
            active
            paragraph={{
              rows: 25
            }}
          />
        ) : (
          <Row className="booking-form-arrangement">
            <Col span={24} lg={16} className="booking-form-left">
              <div className="stepsBox">
                <Steps current={current}>
                  {generateSteps(this).map(item => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>
              <div className="steps-content">{generateSteps(this)[current].content}</div>
              <div className="steps-action">
                {current > 0 && (
                  <Button className="booking-form-steps-toggle" onClick={this.prev}>
                    {intl.get('booking_form.page.prev').d('Previous')}
                  </Button>
                )}
                {current < generateSteps(this).length - 1 && (
                  <Button
                    className="booking-form-steps-toggle"
                    type="primary"
                    disabled={!this.checkIsCommercialReservation()}
                    onClick={
                      this.state.duplicatedFrom
                        ? () => {
                            this.handleGuestSubmit(this.state.guestDetails[0], true, !this.state.duplicatedFrom);
                            this.next();
                          }
                        : this.next
                    }
                  >
                    {intl.get('booking_form.page.next').d('Next')}
                  </Button>
                )}
              </div>
            </Col>
            <Col span={24} lg={8} className="booking-form-right">
              <Card title={intl.get('reservations.paymentDetails.paymentDetails').d('Payment Details')}>
                {this.checkIsCommercialReservation() ? (
                  <Fragment>
                    {/* <Row className="booking-form-payment-description">Payment Details</Row>                   */}
                    <Row className="booking-form-payment">
                      <Col span={24} className="responsive-payment-details">
                        {intl.get('booking_form.paymentDetailsLabels.checkIn').d('Check-in')}
                        <div className="booking-number-bottom">{this.state.startDate}</div>
                        <div className="bottom-divider" />
                      </Col>
                      <Col span={24} className="responsive-payment-details">
                        {intl.get('booking_form.paymentDetailsLabels.checkOut').d('Check-out')}
                        <div className="booking-number-bottom">{this.state.endDate}</div>
                      </Col>
                    </Row>
                    <Row className="booking-form-payment">
                      <Col span={24} className="responsive-payment-details">
                        {intl
                          .get('booking_form.paymentDetailsLabels.totalBookingNights', { bookingNights: bookingNights })
                          .d(`Total for ${bookingNights} Night(s)`)}
                        <div className="booking-number-bottom">{showFee ? this.state.currency + ' ' + numberWithCommas(bookingTotal) : <Spin />}</div>
                        {showFee && discountAmountLabel && (
                          <>
                            <div className="original-price">{this.state.currency + ' ' + numberWithCommas(originalBookingTotal)}</div>
                            <div className="discount-price">{discountAmountLabel}</div>
                          </>
                        )}
                        <div className="bottom-divider" />
                      </Col>
                      <Col span={24} className="responsive-payment-details">
                        {intl.get('booking_form.paymentDetailsLabels.paymentReceived').d('Payment Received')}
                        <br />
                        <div className="booking-number-bottom">
                          {showFee ? this.state.currency + ' ' + numberWithCommas(parseFloat(this.state.paymentReceived || 0)) : <Spin />}
                        </div>
                      </Col>
                    </Row>
                    <Row className="booking-form-divider" />
                    <Row className="booking-form-payment responsive-booking-form-payment">
                      <Col span={24} className="responsive-booking-form-payment">
                        {intl.get('booking_form.paymentDetailsLabels.outstandingBalance').d('Outstanding Balance')}
                      </Col>
                      <Col span={24} className="booking-number responsive-booking-form-payment">
                        {showFee ? this.state.currency + ' ' + numberWithCommas(this.state.bookingTotal - this.state.paymentReceived) : <Spin />}
                      </Col>
                    </Row>

                    <Row className="responsive-booking-form-payment booking-form-payment">
                      <Col span={24} className="responsive-middle-details">
                        {intl.get('booking_form.paymentDetailsLabels.collected').d('Collected')}
                      </Col>
                      <Col span={24} className="responsive-middle-details">
                        <Input
                          addonBefore={this.state.currency || 'RM'}
                          placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                          type="number"
                          step="any"
                          name="paymentReceived"
                          min={0}
                          onChange={this.handleOnInputChange}
                        />
                      </Col>
                    </Row>
                    <Row className="responsive-booking-form-payment booking-form-payment">
                      <Col span={24} className="booking-refund responsive-middle-details deposit-styles">
                        <Switch
                          checkedChildren={intl.get('booking_form.paymentDetailsLabels.depositCollected').d('Deposit Collected')}
                          unCheckedChildren={intl.get('booking_form.paymentDetailsLabels.depositNotCollected').d('Deposit Not Collected')}
                          onChange={this.handleOnToggleChange}
                        >
                          {intl.get('booking_form.paymentDetailsLabels.depositCollected').d('Deposit Collected')}
                        </Switch>
                      </Col>
                      {this.state.depositCollected && (
                        <Col span={24} className="booking-refund responsive-middle-details security-deposit-collected-style">
                          {intl.get('booking_form.paymentDetailsLabels.securityDepositCollected').d('Security Deposit Collected')}
                          <Input
                            value={this.state.refundableDeposit}
                            addonBefore={this.state.currency || 'RM'}
                            placeholder={intl.get('booking_form.otherCharges.placeholder.amount').d('Enter Amount')}
                            type="number"
                            name="refundableDeposit"
                            min={0}
                            onChange={this.handleOnInputChange}
                          />
                        </Col>
                      )}
                      <div className="steps-action-payment-details">
                        {this.state.current > 0 && (
                          <Button className="booking-form-steps-toggle" onClick={() => this.prev()}>
                            {intl.get('booking_form.page.prev').d('Previous')}
                          </Button>
                        )}
                        {this.state.current < generateSteps(this).length - 1 && (
                          <Button
                            className="booking-form-steps-toggle"
                            disabled={!this.checkIsCommercialReservation()}
                            type="primary"
                            onClick={() => this.next()}
                          >
                            {intl.get('booking_form.page.next').d('Next')}
                          </Button>
                        )}
                      </div>
                    </Row>

                    {this.state.current === generateSteps(this).length - 1 && (
                      <Row className="booking-form-payment">
                        <Col span={24}>
                          <Button id="book-button1-booking" type="primary" onClick={this.handleOnSubmit} loading={isButtonLoading}>
                            {intl.get('booking_form.paymentDetailsLabels.bookNow').d('Book Now')}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                ) : (
                  this.state.currentBookingType && (
                    <Row className="booking-form-payment">
                      <Col span={24}>
                        <Button id="book-button1-booking" type="primary" onClick={this.handleOnSubmit} loading={isButtonLoading}>
                          {intl.get('booking_form.paymentDetailsLabels.bookNow').d('Book Now')}
                        </Button>
                      </Col>
                    </Row>
                  )
                )}
              </Card>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export default withAppContext(withRouter(Form.create()(BookingForm)));
