import React, { Component } from 'react';
import { Card, Row, Col, Form, Tabs, Button, notification, Modal, Tooltip, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getExpediaMinMaxRate } from '../../../../utils/apis/integration';
import { updateUnitCalendarRateBulk, updateRoomTypeCalendarRateBulk } from '../../../../utils/apis/multiCalendar';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import bookingComLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import hostPlatformLogo from 'images/hostplatform-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';
import intl from 'react-intl-universal';
// import BELogo from 'images/HBE-BE-logo.png';
import BELogo from 'images/HBE-BE-logo.png';

const TabPane = Tabs.TabPane;

const FIELD_NAME_NAME = 'name';
const FIELD_NAME_ID = '_id';
const FIELD_NAME_WEEKDAY_PRICE = 'weekday';
const FIELD_NAME_WEEKEND_PRICE = 'weekend';
const FIELD_NAME_OTA = 'ota';
const FIELD_NAME_CALENDAR_WEEKDAY_PRICE = 'overrideWeekday';
const FIELD_NAME_CALENDAR_WEEKEND_PRICE = 'overrideWeekend';

class MCModalSetPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };

    this.handleOnFormSubmit = this.handleOnFormSubmit.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
  }

  componentDidMount = () => {
    this.getExpediaMinMaxRate();
  };

  checkIsDerivedRate = () => {
    const { combineRates } = this.props;
    const checkDerived = combineRates.map(rate => rate.isDerived);
    return checkDerived.includes(true);
  };

  getExpediaMinMaxRate = () => {
    const { clickedRoomType } = this.props;
    getExpediaMinMaxRate(clickedRoomType)
      .then(expediaRateData => {
        this.setState({
          expediaMin: expediaRateData.minAmount,
          expediaMax: expediaRateData.maxAmount
        });
        return expediaRateData;
      })
      .catch(ex => {});
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const onOkAction = this.handleOnSave;
        Modal.confirm({
          title: intl.get('multicalendar.message.calendarTitle').d('Do you want to update this calendar rate?'),
          content: intl.get('multicalendar.message.calendarContent').d('Updating this calendar rate will affect all rate that is selected.'),
          onOk() {
            return onOkAction(values);
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnSave = async values => {
    const { startDate, endDate, clickedUnit, clickedRoomType, handleOnMCModalSetPrices } = this.props;
    const body = [
      {
        startDate,
        endDate,
        override: values.override
          .filter(override => override && override._id && (override.overrideWeekday || override.overrideWeekend))
          .map(override => ({
            rate: override._id,
            weekday: override.overrideWeekday,
            weekend: override.overrideWeekend
          }))
      }
    ];

    try {
      if (clickedUnit) {
        await updateUnitCalendarRateBulk(clickedUnit, body);
      } else if (clickedRoomType) {
        await updateRoomTypeCalendarRateBulk(clickedRoomType, body);
      } else {
        throw new Error(intl.get('multicalendar.message.serverError').d('Internal Server Error!'));
      }
      this.setState({
        isSaving: false
      });
      // handleActionsModalCancel();
      notification.success({
        message: intl.get('multicalendar.message.calendarUpdate').d('Your calendar rate has been updated!')
      });
      handleOnMCModalSetPrices({
        currentRoomTypeId: clickedUnit ? clickedRoomType : null,
        currentMonthStartDate: startDate
      });
      return;
    } catch (ex) {
      this.setState({
        isSaving: false
      });
      notification.error({
        message: intl.get('multicalendar.message.calendarUpdateError').d('Something went wrong and your calendar rate is not updated!')
      });
      return;
    }
  };

  render() {
    const { form, combineRates } = this.props;
    const { isSaving, expediaMin, expediaMax } = this.state;
    const makeOtasIntoIcons = otas => {
      let logos = [];
      let i = 1;

      otas.map(ota => {
        if (i === 4) {
          logos.push(<br />);
        }

        if (ota === 'web') {
          logos.push(
            <Tooltip title="HostPlatform" placement="bottom">
              <img src={hostPlatformLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="hp logo" />
            </Tooltip>
          );
        }
        if (ota === 'hbe') {
          logos.push(
            <Tooltip title="Booking Website" placement="bottom">
              <img src={BELogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="be logo" />
            </Tooltip>
          );
        }
        if (ota === 'agoda' || ota === 'agodahomes') {
          logos.push(
            <Tooltip title="Agoda" placement="bottom">
              <img src={agodaLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="agoda logo" />
            </Tooltip>
          );
        }
        if (ota === 'airbnb') {
          logos.push(
            <Tooltip title="Airbnb" placement="bottom">
              <img src={airbnbLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="airbnb logo" />
            </Tooltip>
          );
        }
        if (ota === 'bookingcom') {
          logos.push(
            <Tooltip title="Booking.com" placement="bottom">
              <img src={bookingComLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="bcom logo" />
            </Tooltip>
          );
        }
        if (ota === 'ctrip') {
          logos.push(
            <Tooltip title="Ctrip" placement="bottom">
              <img src={ctripLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="ctrip logo" />
            </Tooltip>
          );
        }
        if (ota === 'expedia') {
          logos.push(
            <Tooltip title="Expedia" placement="bottom">
              <img src={expediaLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="expedia logo" />
            </Tooltip>
          );
        }
        if (ota === 'ctripcm') {
          logos.push(
            <Tooltip title="CtripV2" placement="bottom">
              <img src={ctripLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="ctripv2 logo" />
            </Tooltip>
          );
        }
        if (ota === 'traveloka') {
          logos.push(
            <Tooltip title="Traveloka" placement="bottom">
              <img src={travelokaLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="traveloka logo" />
            </Tooltip>
          );
        }
        if (ota === 'tiketcom') {
          logos.push(
            <Tooltip title="Tiket.com" placement="bottom">
              <img src={tiketcomLogo} width="20px" style={{ paddingLeft: '3px', paddingBottom: '3px' }} alt="Tiket.com logo" />
            </Tooltip>
          );
        }
        i++;
        return logos;
      });
      return <div style={{ flexDirection: 'row' }}>{logos}</div>;
    };
    const mapRates = (rate, i) => {
      const assignedToExpedia = rate[FIELD_NAME_OTA].includes('expedia');
      const minValue = expediaMin && assignedToExpedia ? Math.max(expediaMin, 50.0) : this.props.currency === 'USD' ? 10 : 50.0;
      const maxValue = expediaMax && assignedToExpedia ? Math.min(expediaMax, 99000000.0) : 99000000.0;

      let rateMessageInfo = '';

      const isDerived = rate.isDerived;

      if (assignedToExpedia) {
        rateMessageInfo = intl
          .get('multicalendar.headerLabels.rateMsgInfo', {
            currencySymbol: this.props.currency,
            minValue: minValue.toFixed(2),
            maxValue: maxValue.toFixed(2)
          })
          .d(`Expedia rate is limited from ${this.props.currency} ${minValue.toFixed(2)} to ${this.props.currency} ${maxValue.toFixed(2)}`);
      } else {
        rateMessageInfo = intl
          .get('multicalendar.headerLabels.rateMsgInfo2', {
            currencySymbol: this.props.currency,
            minValue: this.props.currency === 'USD' ? 10 : 50.0
          })
          .d(`Rate is limited from ${this.props.currency} ${this.props.currency === 'USD' ? 10 : 50.0} to ${this.props.currency} 99,000,000.00`);
      }
      const rateIconInfo = (
        <Tooltip title={<span style={{ fontSize: '12px' }}>{rateMessageInfo}</span>}>
          <Icon type="question-circle-o" style={{ cursor: 'pointer' }} />
        </Tooltip>
      );

      return (
        <TabPane
          tab={
            <div>
              {intl.get(`multicalendar.rate.${rate[FIELD_NAME_NAME].replace(/\./g, '_')}`).d(rate[FIELD_NAME_NAME])}{' '}
              {makeOtasIntoIcons(rate[FIELD_NAME_OTA])}
            </div>
          }
          key={i + 1}
        >
          <Row gutter={16}>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_NAME + ']'}
                defaultValue={intl.get(`multicalendar.rate.${rate[FIELD_NAME_NAME].replace(/\./g, '_')}`).d(rate[FIELD_NAME_NAME])}
                inputType="input"
                label={intl.get('multicalendar.headerLabels.rateName').d("Rate's Name")}
                placeholder={intl.get('multicalendar.placeholder.rateName').d("Type in your rate's name")}
                requiredErrorMessage={intl.get('multicalendar.placeholder.rateNameMsg').d("Rate's name is required")}
                extraProps={{ disabled: true }}
                size="large"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInputNumber
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_WEEKDAY_PRICE + ']'}
                defaultValue={rate[FIELD_NAME_WEEKDAY_PRICE]}
                label={`${intl.get('multicalendar.headerLabels.weekdayPricing').d('Original Weekday Pricing')} (${this.props.currency})`}
                minValue={1.0}
                precision={2}
                placeholder={intl.get('multicalendar.placeholder.weekdayPricing').d('Price to charge on weekday')}
                requiredErrorMessage={intl.get('multicalendar.placeholder.weekdayPricingMsg').d('Please set a price for weekday')}
                disabled={true}
                size="large"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInputNumber
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_CALENDAR_WEEKDAY_PRICE + ']'}
                label={
                  <span>
                    {intl.get('multicalendar.headerLabels.dailyWeekday').d('Daily Weekday Pricing')} ({this.props.currency}) &nbsp;
                    {rateIconInfo}
                  </span>
                }
                precision={2}
                minValue={minValue}
                maxValue={maxValue}
                size="large"
                disabled={isDerived}
              />
            </Col>
            <Col span={24} md={12}>
              <FormInputNumber
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_WEEKEND_PRICE + ']'}
                defaultValue={rate[FIELD_NAME_WEEKEND_PRICE]}
                label={`${intl.get('multicalendar.headerLabels.weekendPricing').d('Original Weekend Pricing')} (${this.props.currency})`}
                minValue={1.0}
                precision={2}
                placeholder={intl.get('multicalendar.placeholder.weekendPricing').d('Price to charge on weekend')}
                requiredErrorMessage={intl.get('multicalendar.placeholder.weekendPricingMsg').d('Please set a price for weekend')}
                disabled={true}
                size="large"
              />
            </Col>
            <Col span={24} md={12}>
              <FormInputNumber
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_CALENDAR_WEEKEND_PRICE + ']'}
                label={
                  <span>
                    {intl.get('multicalendar.headerLabels.dailyWeekend').d('Daily Weekend Pricing')} ({this.props.currency}) &nbsp;
                    {rateIconInfo}
                  </span>
                }
                precision={2}
                minValue={minValue}
                maxValue={maxValue}
                size="large"
                disabled={isDerived}
              />
            </Col>
            {/* Pass in rate Id */}
            <Col span={24} md={24} style={{ margin: -24 }}>
              <FormInput
                form={form}
                name={'override[' + i + '][' + FIELD_NAME_ID + ']'}
                defaultValue={rate[FIELD_NAME_ID]}
                extraProps={{ hidden: true }}
                size="large"
              />
            </Col>
          </Row>
        </TabPane>
      );
    };

    return (
      <Card title={intl.get('multicalendar.headerLabels.setPriceRate').d('Set Price Rate')} className="card-wrapper">
        <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
          <Tabs defaultActiveKey="1" size="small" tabPosition="left">
            {combineRates.map(mapRates)}
          </Tabs>
          <Row type="flex" justify="end">
            <Col>
              <Button id="save-button2-price" type="primary" size="large" htmlType="submit" loading={isSaving}>
                {isSaving ? intl.get('listings.unit.headerLabels.saving').d('Saving') : intl.get('listings.unit.headerLabels.save').d('Save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

MCModalSetPrices.propTypes = {
  form: PropTypes.object.isRequired,
  selectedDaysArr: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

MCModalSetPrices.defaultProps = {
  form: {},
  selectedDaysArr: []
};

export default withRouter(Form.create()(MCModalSetPrices));
