import React from 'react';
import { Card, Row, Switch } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import OTARateInput from '../../../../OTARateInput/OTARateInput';

import styles from './RoomListing.module.css';
import intl from 'react-intl-universal';

const RoomRates = ({ tiketcomRates, onChangeRate, currency = 'RM' }) => {
  return tiketcomRates.map(tiketcomRate => (
    <Row key={tiketcomRate.tiketcomRateId} className={styles.row}>
      <Card title={`${tiketcomRate.tiketcomRateName} | ${tiketcomRate.tiketcomRateId}`}>
        <OTARateInput rate={tiketcomRate.rate} updateRates={onChangeRate(tiketcomRate.tiketcomRateId)} currency={currency} />
      </Card>
    </Row>
  ));
};

const RoomListing = ({ tiketcomRoomsSelection, roomsMapping, onToggleRoom, onSelectTiketcomRoom, onChangeRate, currency = 'RM' }) => {
  const handleOnChangeRate = roomTypeId => tiketcomRateId => onChangeRate(roomTypeId, tiketcomRateId);

  return roomsMapping.map(roomMapping => (
    <Card style={{ marginBottom: '16px' }} key={roomMapping._id} title={roomMapping.name} headStyle={{ backgroundColor: '#cbf2f4' }}>
      <label className="booking-label">{`${intl.get('hostConnect.integration.headerLabels.sync').d('Sync')} ${roomMapping.name} ${intl
        .get('hostConnect.ticketcom.headerLabels.toTiketcom')
        .d('to Ticket.com')}`}</label>
      <Switch
        checkedChildren={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
        unCheckedChildren={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
        onChange={onToggleRoom(roomMapping._id)}
        checked={roomMapping.isToggled}
      />
      {roomMapping.isToggled && (
        <>
          <Row className={styles.row}>
            <BasicSelect
              selectClassName={styles.select}
              selections={tiketcomRoomsSelection}
              value={roomMapping.formData.tiketcomRoomId}
              placeholder={intl.get('hostConnect.ticketcom.placeholder.tiketcomRoom').d('Select Tiket.com room')}
              onChange={onSelectTiketcomRoom(roomMapping._id)}
            />
          </Row>
          {!!roomMapping.formData.tiketcomRates && (
            <RoomRates
              roomTypeId={roomMapping._id}
              tiketcomRates={roomMapping.formData.tiketcomRates}
              onChangeRate={handleOnChangeRate(roomMapping._id)}
              currency={currency}
            />
          )}
        </>
      )}
    </Card>
  ));
};

export default RoomListing;
