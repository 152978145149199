import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import intl from 'react-intl-universal';

import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';

import './403.css';
import styles from './page403.module.css';
import placeHolderImage from './403.svg';

class Forbidden403 extends Component {
  render() {
    return (
      <SingleViewLayout className={styles.youShallNotPass}>
        <Row className={styles.errorRowForException}>
          <Col span={24} md={10} className={styles.errorColumnForException}>
            <img src={placeHolderImage} alt="forbidden-page" className={styles.image} />
          </Col>
          <Col span={0} md={1} />
          <Col span={24} md={11} className={styles.errorColumnForException}>
            <div className={styles.errorColumnContent}>
              <span className={styles.errorTitle}>{intl.get('403and404.403').d(`403 Forbidden`)}</span>
              <span className={styles.errorSubtitle}>
                {intl.get('403and404.content').d(`Page not found. Looks like you're lost. Let me guide you back.`)}
              </span>
              <Button type="primary" size="large" style={{ width: 'auto', margin: '0 auto' }}>
                <Link to="/">{intl.get('403and404.turnBack').d(`Turn Back.`)}</Link>
              </Button>
            </div>
          </Col>
        </Row>
      </SingleViewLayout>
    );
  }
}

export default withRouter(Forbidden403);
