import React from 'react';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import FormInput from 'components/FormInput/FormInput';

class DetailedDescriptionCard extends React.Component {
  render() {
    const { form, cardClassname, defaultValues } = this.props;
    return (
      <Card title={intl.get('listings.unit.headerLabels.detailed').d('Detailed Description')} className={cardClassname}>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.description').d('Description')}
              name="summary"
              placeholder={intl
                .get('listings.unit.placeholder.descUnit')
                .d("Your unit description should help guests to imagine what it's like to stay at your place.")}
              requiredErrorMessage={intl.get('listings.unit.placeholder.descUnitMsg').d('Please provide summary description of your place.')}
              form={form}
              defaultValue={defaultValues.summary}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.aboutYourPlace').d('About your place')}
              name="space"
              placeholder={intl.get('listings.unit.placeholder.aboutYourPlace').d('Give more information about what makes your space unique.')}
              requiredErrorMessage={intl
                .get('listings.unit.placeholder.aboutYourPlaceMsg')
                .d('Please provide information about what makes your space unique.')}
              form={form}
              defaultValue={defaultValues.space}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.overview').d('Overview')}
              name="neighborhoodOverview"
              placeholder={intl
                .get('listings.unit.placeholder.overview')
                .d('Let guests know what your neighborhood is like, and what makes it unique.')}
              requiredErrorMessage={intl.get('listings.unit.placeholder.overviewMsg').d('Please provide description about your place neighbourhood')}
              form={form}
              defaultValue={defaultValues.neighborhoodOverview}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.gettingAround').d('Getting around')}
              name="transit"
              placeholder={intl
                .get('listings.unit.placeholder.gettingAround')
                .d(
                  'You can let guests know if your listing is close to public transportation (or far from it). You can also mention nearby parking options.'
                )}
              requiredErrorMessage={intl
                .get('listings.unit.placeholder.gettingAroundMsg')
                .d('Please provide description about how to access your place')}
              form={form}
              defaultValue={defaultValues.transit}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.guestAccess').d('Guest Access')}
              name="access"
              placeholder={intl
                .get('listings.unit.placeholder.guestAccess')
                .d('Let guests know what parts of the space they will be able to access.')}
              form={form}
              defaultValue={defaultValues.access}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.other').d('Other things to note')}
              name="notes"
              placeholder={intl.get('listings.unit.placeholder.other').d('Let guests know if there are other details that will impact their stay.')}
              form={form}
              defaultValue={defaultValues.notes}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.rule').d('House rules')}
              name="houseRules"
              placeholder={intl
                .get('listings.unit.placeholder.houseRule')
                .d(
                  "You can include any requirements around safety concerns, shared space rules, and your community regulations, as well as rules like: 'No solo travelers' or 'Quiet time after 10pm'"
                )}
              form={form}
              defaultValue={defaultValues.houseRules}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label={intl.get('listings.unit.headerLabels.guestInteraction').d('Guest interaction')}
              name="interaction"
              placeholder={intl
                .get('listings.unit.placeholder.guestInteraction')
                .d("Tell guests if you'll be available to offer help throughout their stay.")}
              form={form}
              defaultValue={defaultValues.interaction}
              size="large"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

DetailedDescriptionCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

DetailedDescriptionCard.defaultProps = {
  defaultValues: {}
};

export default DetailedDescriptionCard;
