import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber } from 'antd';

import styles from './FormInputNumber.module.css';
import intl from 'react-intl-universal';

const FormItem = Form.Item;

const sizes = {
  default: {
    className: styles.default
  },
  large: {
    className: `${styles.default} ${styles.large}`
  }
};

const addRules = (requiredErrorMessage, precision, extraRules) => {
  const rules = [
    {
      pattern: precision ? /^\d+\.?\d*$/ : /^[0-9]*$/,
      message: precision
        ? `${intl
            .get('listings.headerLabels.onlyAllowNumericPrecision', { precision: precision })
            .d(`Only allow numeric input with ${precision} decimal points`)}`
        : `${intl.get('listings.headerLabels.onlyAllowNumeric').d('Only allow numeric input')}`
    }
  ];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return [...rules, ...extraRules];
};

const FormInputNumber = ({
  className,
  classNameInput,
  extraRules,
  extraProps,
  defaultValue,
  form,
  formLabel,
  name,
  label,
  minValue,
  maxValue,
  formatter,
  parser,
  precision,
  placeholder,
  requiredErrorMessage,
  disabled,
  size,
  onChange,
  onSubmit,
  customStyle
}) => {
  const rules = addRules(requiredErrorMessage, precision, extraRules);

  return (
    <Fragment>
      {!formLabel && label && <p className={styles.inputNumberLabel}>{label}</p>}
      <FormItem style={{ paddingTop: '8px', ...customStyle }} label={formLabel} className={className} colon={false}>
        {form.getFieldDecorator(name, {
          rules,
          initialValue: defaultValue
        })(
          <InputNumber
            className={`${sizes[size].className} ${classNameInput}`}
            placeholder={placeholder}
            size={size}
            min={minValue}
            max={maxValue}
            formatter={formatter}
            parser={parser}
            precision={precision}
            disabled={disabled}
            onChange={onChange}
            onSubmit={onSubmit}
            {...extraProps}
          />
        )}
      </FormItem>
    </Fragment>
  );
};

FormInputNumber.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  defaultValue: PropTypes.number,
  classNameInput: PropTypes.string,
  extraProps: PropTypes.object,
  extraRules: PropTypes.array,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  precision: PropTypes.number,
  placeholder: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizes)),
  onChange: PropTypes.func
};

FormInputNumber.defaultProps = {
  classNameInput: '',
  extraRules: [],
  extraProps: {},
  formatter: undefined,
  parser: undefined,
  precision: 0,
  placeholder: '',
  requiredErrorMessage: '',
  disabled: false,
  size: Object.keys(sizes)[0],
  onChange: undefined
};

export default FormInputNumber;
