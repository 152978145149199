import React from 'react';
import { withAppContext } from 'context/AppContext';
import { Button, Col, Icon, Table as AntdTable, Tooltip, Tag, Row } from 'antd';

import OTALogo, { getOTALabel } from 'components/OTALogo/OTALogo';
import { getTableColumnProps } from 'components/Table/Table';

import { capitalizeFirstLetter, getIntegrationTypesConstant, guard, numberWithCommas } from 'utils/general';

import styles from './Table.module.css';
import intl from 'react-intl-universal';

const { LISTING_TYPE, HOTEL_TYPE } = getIntegrationTypesConstant();

const SOURCE_AIRBNB = 'airbnb';

const getSyncStatusRenderDetail = (otaSyncStatusConstantsObject, syncStatus) => {
  const AGODA = otaSyncStatusConstantsObject.AGODA;
  const AIRBNB = otaSyncStatusConstantsObject.AIRBNB;
  const BOOKINGCOM = otaSyncStatusConstantsObject.BOOKINGCOM;
  const CTRIP = otaSyncStatusConstantsObject.CTRIP;
  const EXPEDIA = otaSyncStatusConstantsObject.EXPEDIA;
  const CTRIPCM = otaSyncStatusConstantsObject.CTRIPCM;
  const TRAVELOKA = otaSyncStatusConstantsObject.TRAVELOKA;

  const isError = [
    AGODA.NOTFOUND.code,
    AGODA.NOPULL.code,
    AGODA.NOBIND.code,
    AIRBNB.REJECTED,
    AIRBNB.UNLISTED,
    AIRBNB.ERROR,
    AIRBNB.NOSYNC,
    BOOKINGCOM.NOTFOUND.code,
    BOOKINGCOM.NOPULL.code,
    BOOKINGCOM.NOBIND.code,
    CTRIP.FAILED,
    CTRIP.DEACTIVATED,
    CTRIP.NOSYNC,
    EXPEDIA.NOSYNC,
    CTRIPCM.ERROR,
    TRAVELOKA.ERROR
  ].includes(syncStatus);

  const isSyncing = [AIRBNB.PUSHING, AIRBNB.NEW, BOOKINGCOM.PENDING, CTRIP.PENDING, EXPEDIA.PENDING, CTRIPCM.NOBIND].includes(syncStatus);

  const isCompleted = [
    AGODA.SYNCED.code,
    AIRBNB.APPROVED,
    AIRBNB.READY,
    BOOKINGCOM.SYNCED.code,
    CTRIP.ACTIVE,
    EXPEDIA.SYNCED,
    CTRIPCM.SYNCED,
    TRAVELOKA.SYNCED.code
  ].includes(syncStatus);

  const isCompletedBookingCom = [BOOKINGCOM.READY.code].includes(syncStatus);

  if (isError) {
    return { type: 'exclamation-circle', spin: false, theme: 'filled', style: { color: '#FF5630' } };
  } else if (isSyncing) {
    return { type: 'sync', spin: true, theme: undefined, style: undefined };
  } else if (isCompleted) {
    return { type: 'check-circle', spin: false, theme: 'filled', style: { color: 'green' } };
  } else if (isCompletedBookingCom) {
    return { type: 'check-circle', spin: false, theme: 'filled', style: { color: 'orange' } };
  } else {
    return {};
  }
};

const getTableOTASourceRenderProp = () => ({
  render: (text, record) => {
    const isAirbnb = record.source === SOURCE_AIRBNB;
    const otaLabel = getOTALabel(text);
    const otaSourceText = record.listingName || otaLabel;

    return (
      <Tooltip title={otaLabel}>
        <OTALogo size="small" otaCode={text} className={styles.tableOTASourceLogo} />
        {isAirbnb ? (
          <a target="_blank" href={'https://www.airbnb.com/rooms/' + record.airbnbDetail.foreignAirbnbId} rel="noopener noreferrer">
            {otaSourceText}
          </a>
        ) : (
          otaSourceText
        )}
      </Tooltip>
    );
  }
});

const getPricingRenderProp = (text, record) => ({
  render: (text, record) => {
    return `${record.currency} ${numberWithCommas(text, 0)}`;
  }
});

const getTableSyncStatusRenderProp = (otaSyncStatusConstants, integrations) => ({
  render: (text, record) => {
    const status = capitalizeFirstLetter(
      intl
        .get(`hostConnect.integration.status.${text}`)
        .d('Create Listing')
        .d(text)
    );
    const { type, spin, theme, style } = getSyncStatusRenderDetail(otaSyncStatusConstants, text);

    let showCurrencyMismatchWarning = false;
    if (guard(() => record.currency !== record.propertyCurrency, false)) {
      showCurrencyMismatchWarning = true;
    }

    return (
      <Row type="flex" align="middle" gutter={4}>
        <Col>
          <Tooltip title={record.errorMessage || status}>
            <Icon type={type} spin={spin} theme={theme} style={style} />
          </Tooltip>{' '}
        </Col>
        <Col>
          {/* Travelok is the only OTA do not support multi currency */}
          {showCurrencyMismatchWarning && record.source !== 'traveloka' && (
            <Tooltip
              title={
                <div>
                  {intl
                    .get('hostConnect.integration.message.inconsistencyCurrency')
                    .d('Inconsistency in currency between the system and OTA may result in incorrect pricing updates.')}
                  <div
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    {intl.get('hostConnect.integration.headerLabels.system').d('System')} : {record.propertyCurrency}
                  </div>
                  <div
                    style={{
                      fontWeight: 'bold'
                    }}
                  >
                    {intl.get('hostConnect.integration.headerLabels.ota').d('OTA')} : {record.currency}
                  </div>
                </div>
              }
            >
              <Tag color="magenta">
                <Icon
                  type="warning"
                  style={{
                    color: 'red'
                  }}
                />{' '}
                {intl.get('hostConnect.integration.headerLabels.currencyMismatch').d('Currency mismatch')}
              </Tag>
            </Tooltip>
          )}
        </Col>
      </Row>
    );
  }
});

const getTableActionsRenderProp = (
  onClickButtonSeasonal,
  onClickButtonCreateEdit,
  onClickButtonDelete,
  isAllowEdit,
  isAllowDelete,
  checkIsAdminReadOnly
) => ({
  render: (text, record) => {
    const { airbnbDetail: { airbnbListingId } = {}, source } = record;
    const isAirbnb = source === SOURCE_AIRBNB;

    return (
      <div className={styles.actionButtons}>
        {isAirbnb && (
          <Button
            type="primary"
            icon="gift"
            className={styles.actionButtonSeasonalRule}
            onClick={() => onClickButtonSeasonal(airbnbListingId)}
            disabled={!isAllowEdit || checkIsAdminReadOnly()}
          >
            {intl.get('hostConnect.integration.headerLabels.seasonal').d('Seasonal Rule')}
          </Button>
        )}

        <Col className={styles.actionButtonsEditDelete}>
          <Button
            type="primary"
            className={styles.actionButtonEdit}
            onClick={() => onClickButtonCreateEdit(source)}
            disabled={!isAllowEdit || checkIsAdminReadOnly()}
          >
            {intl.get('hostConnect.integration.headerLabels.edit').d('Edit')}
          </Button>
          <Button
            type="danger"
            className={styles.actionButtonDelete}
            onClick={() => onClickButtonDelete(source, airbnbListingId)}
            disabled={!isAllowDelete || checkIsAdminReadOnly()}
          >
            {intl.get('hostConnect.integration.headerLabels.delete').d('Delete')}
          </Button>
        </Col>
      </div>
    );
  }
});

const Table = ({
  otaSyncStatusConstants,
  integrations,
  type,
  onClickButtonSeasonal,
  onClickButtonCreateEdit,
  onClickButtonDelete,
  checkIsAllowCreateIntegration,
  checkIsAllowEditIntegration,
  checkIsAllowDeleteIntegration,
  checkIsAdminReadOnly
}) => {
  const isAllowCreate = !!checkIsAllowCreateIntegration();
  const isAllowEdit = !!checkIsAllowEditIntegration();
  const isAllowDelete = !!checkIsAllowDeleteIntegration();

  const getTableColumns = () => {
    const tableColumns = [];

    tableColumns.push({
      ...getTableColumnProps(intl.get('hostConnect.integration.tableColumns.ota').d('OTA Source'), 'source'),
      ...getTableOTASourceRenderProp()
    });

    tableColumns.push({
      ...getTableColumnProps(intl.get('hostConnect.integration.tableColumns.sync').d('Sync Status'), 'status'),
      ...getTableSyncStatusRenderProp(otaSyncStatusConstants, integrations)
    });

    if (type === LISTING_TYPE.code) {
      tableColumns.push(getTableColumnProps(intl.get('hostConnect.integration.tableColumns.rateName').d('Rate Name'), 'rate.name'));

      tableColumns.push({
        ...getTableColumnProps(intl.get('hostConnect.integration.tableColumns.weekday').d('Weekday Price'), 'rate.weekday'),
        ...getPricingRenderProp()
      });

      tableColumns.push({
        ...getTableColumnProps(intl.get('hostConnect.integration.tableColumns.weekend').d('Weekend Price'), 'rate.weekend'),
        ...getPricingRenderProp()
      });
    } else if (type === HOTEL_TYPE.code) {
      tableColumns.push(getTableColumnProps(intl.get('hostConnect.integration.tableColumns.totalRoomType').d('Total Room Types'), 'roomTypeCount'));

      tableColumns.push(getTableColumnProps(intl.get('hostConnect.integration.tableColumns.totalUnit').d('Total Units'), 'unitCount'));
    }

    tableColumns.push({
      ...getTableColumnProps(intl.get('hostConnect.integration.tableColumns.action').d('Action'), 'action'),
      ...getTableActionsRenderProp(
        onClickButtonSeasonal,
        onClickButtonCreateEdit,
        onClickButtonDelete,
        isAllowEdit,
        isAllowDelete,
        checkIsAdminReadOnly
      )
    });

    return tableColumns;
  };

  return (
    <>
      <AntdTable rowKey={record => record.source} dataSource={integrations} columns={getTableColumns()} scroll={{ x: 1000 }} />
      <Button
        type="dashed"
        onClick={() => onClickButtonCreateEdit()}
        className={styles.buttonNewIntegration}
        disabled={!isAllowCreate || checkIsAdminReadOnly()}
      >
        <Icon type="plus" /> {intl.get('hostConnect.integration.headerLabels.new').d('New Integration')}
      </Button>
    </>
  );
};

export default withAppContext(Table);
