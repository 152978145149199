import React from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';

import { DEFAULT_FINE_PRINT } from 'utils/constants';

import styles from './FinePrintInfoCard.module.css';
import intl from 'react-intl-universal';

const FinePrintInfoCard = ({ form, cardClassname, defaultValues }) => {
  const shouldShowDefaultFinePrint = !form.getFieldValue('finePrint');
  return (
    <Card title={intl.get('listings.unit.headerLabels.finePrintInfo').d('Fine Print Information')} className={cardClassname}>
      <Row gutter={16}>
        <Col span={24}>
          <Alert
            className={styles.notes}
            message={intl.get('listings.unit.headerLabels.info').d('Information')}
            description={
              <div>
                <p className={styles.notesText}>
                  {intl
                    .get('listings.unit.headerLabels.noteText')
                    .d('This fine print text will be used to display in invoices, quotations, and guest registration cards. ')}
                  {shouldShowDefaultFinePrint && (
                    <span>
                      {intl
                        .get('listings.unit.headerLabels.noteDesc')
                        .d("By default, we've set a universal fine print. You may change it to fit to your business.")}
                    </span>
                  )}
                </p>
                {shouldShowDefaultFinePrint && <p className={styles.defaultFinePrintText}>{intl.get('constants.finePrint').d('finePrint')}</p>}
              </div>
            }
            type="info"
            showIcon
          />
          <br />
          <FormInput
            form={form}
            name="finePrint"
            defaultValue={defaultValues.finePrint}
            inputType="textarea"
            label={intl.get('listings.unit.headerLabels.finePrint').d('Fine Print')}
            placeholder={intl.get('listings.unit.placeholder.finePrint').d('Type in your fine print')}
          />
        </Col>
      </Row>
    </Card>
  );
};

FinePrintInfoCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

FinePrintInfoCard.defaultProps = {
  defaultValues: {}
};

export default FinePrintInfoCard;
