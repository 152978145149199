import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Form, Row, Col, DatePicker, Button } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import intl from 'react-intl-universal';

const FormItem = Form.Item;

class TaxesTabPane extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    taxes: PropTypes.array.isRequired,
    handleOnTaxSubmit: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired
  };

  static defaultProps = {
    form: {},
    taxes: [],
    currency: 'RM',
    handleOnTaxSubmit: () => {}
  };

  render() {
    const { form, taxes, handleOnTaxSubmit, currency, checkIsAdminReadOnly } = this.props;

    return (
      <Form onSubmit={event => handleOnTaxSubmit(event, form)} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <p style={{ marginBottom: '8px' }}>{intl.get('reservations.transactions.headerLabels.date').d('Date')}</p>
            <FormItem style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('taxDate', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.transactions.placeholder.dateAlert').d('Please select a date!')
                  }
                ],
                initialValue: moment(new Date(), 'YYYY-MM-DD')
              })(<DatePicker name="chargesDate" size="large" style={{ width: '100%', marginBottom: '16px' }} format={'YYYY-MM-DD'} />)}
            </FormItem>
          </Col>
          <Col span={24} md={18}>
            <FormSelection
              label={intl.get('reservations.transactions.headerLabels.taxType').d('Tax Type')}
              name="taxType"
              requiredErrorMessage={intl.get('reservations.transactions.errorMessage.taxType').d('Please select your tax type')}
              placeholder={intl.get('reservations.transactions.placeholder.taxType').d('Choose type of taxes')}
              form={form}
              selections={taxes}
              size="large"
            />
          </Col>
          <Col span={24} md={6}>
            <FormInputNumber
              form={form}
              name="amount"
              precision={2}
              minValue={1}
              label={`${intl.get('reservations.transactions.headerLabels.amountLabel').d('Amount')} (${currency})`}
              placeholder={intl.get('reservations.reservationDetails.placeholder.amount').d('Amount to be charged')}
              size="large"
            />
          </Col>
        </Row>
        <Row>
          <FormInput
            inputType="textarea"
            label={intl.get('reservations.transactions.headerLabels.remarks').d('Remarks (optional)')}
            name="remarks"
            placeholder={intl.get('reservations.transactions.placeholder.remarks').d('Provide your remark if you have any')}
            form={form}
            extraProps={{ rows: 2 }}
            size="large"
          />
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Button type="primary" htmlType="submit" size="large" style={{ width: '70%' }} disabled={checkIsAdminReadOnly()}>
            {intl.get('reservations.transactions.headerLabels.createTaxCharges').d('Create Tax Charges')}
          </Button>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(TaxesTabPane);
