import React, { Component } from 'react';
import { Card, Button, Row, Select, Switch, Modal, notification, Skeleton, Spin, Col } from 'antd';
import PropTypes from 'prop-types';

import { getConstants } from 'utils/apis/constants';
import { STANDARD_RATE_CODE } from 'utils/constants';
import { errorHandlerWrapper, generatePercentageFromDisplay, guard } from 'utils/general';
import { getRates, getWebRateByRoomType } from 'utils/apis/rate';
import { getExpediaRoomTypesByPropertyId, getExpediaRatePlansByRoomTypeId, putExpedia, getExpedias } from 'utils/apis/integration';

import OTARateInput from '../../../OTARateInput/OTARateInput';
import ExpediaSyncForm from './components/ExpediaSyncForm';
import ExpediaAdvancedOptionsModal from './components/ExpediaAdvancedOptionsModal';
import intl from 'react-intl-universal';

const Option = Select.Option;

class ExpediaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomTypes: [],
      expedias: [],
      expediaPropertyId: '',
      expediaRoomTypes: [],
      expediaRoomTypeMapping: {},
      expediaRatePlans: {},
      rates: {},
      expediaRateMapping: {},
      rate: 0,
      savedExpedia: {},

      rateModifierTypeConstants: [],
      rateModifierSignConstants: [],

      isSaving: false,
      isAdvancedOptionsModalVisible: false,
      openExpediaSyncModal: false,
      spinning: false,
      isExpediaLoading: false,
      currency: 'RM'
    };
  }

  componentDidMount = async () => {
    const { data } = this.props;

    let currency = guard(() => data.currency, 'RM');
    this.setState({ currency });

    errorHandlerWrapper(
      getExpedias().then(expediaRes => {
        if (expediaRes.status === 200 && expediaRes.data) {
          const expedias = expediaRes.data;

          this.setState({ expedias });

          if (data) {
            this.setIsExpediaLoading(true);
            this.handleOnExpediaPropertyIdChange(data.foreignPropertyId, expedias);
          }
        }
      })
    );

    const rateModifierTypeConstants = await errorHandlerWrapper(getConstants('rateModifierType').then(res => Object.values(res.data)), []);
    const rateModifierSignConstants = await errorHandlerWrapper(getConstants('rateModifierSign').then(res => Object.values(res.data)), []);

    this.setState({ rateModifierTypeConstants, rateModifierSignConstants });
  };

  fetchExpediaRoomTypes = async expediaPropertyId => {
    const expediaRoomTypes = await errorHandlerWrapper(getExpediaRoomTypesByPropertyId(expediaPropertyId).then(res => res.data.entity || []), []);
    return expediaRoomTypes;
  };

  handleOnExpediaPropertyIdChange = async (val, expedias) => {
    const { data } = this.props;
    const { expedias: stateExpedias } = this.state;
    const localExpedias = expedias || stateExpedias;

    if (localExpedias && localExpedias.length > 0 && val) {
      this.setState({ spinning: true });

      const currentExpedia = localExpedias.find(expedia => String(expedia.expediaPropertyId) === String(val));
      const expediaRoomTypes = await this.fetchExpediaRoomTypes(val);
      this.setState(
        {
          spinning: false,
          expediaPropertyId: val,
          expediaRoomTypes,
          currentExpediaId: currentExpedia && currentExpedia._id,
          expediaRatePlans: {},
          expediaRoomTypeMapping: {},
          currency: guard(() => currentExpedia.currency, 'RM')
        },
        () => {
          if (data && data.roomTypes && data.roomTypes.length > 0) {
            data.roomTypes.forEach(expRT => {
              this.handleOnExpediaRoomTypeIdChange(expRT.roomType._id)(expRT.roomType.expediaRoomTypeId, {}, {}, val);
              expRT.rates.forEach(expRate => this.updateRates(expRT.roomType._id, expRate.expediaRateId)(expRate.rate));
            });
          }
        }
      );
    }
  };

  handleOnExpediaRoomTypeIdChange = roomType => val => {
    const { expediaRoomTypeMapping, rates, expediaRatePlans, expediaPropertyId } = this.state;
    expediaRoomTypeMapping[roomType] = String(val);

    this.setState({
      spinning: true,
      expediaRoomTypeMapping
    });
    getExpediaRatePlansByRoomTypeId(expediaPropertyId, val).then(res => {
      getRates(roomType).then(ratesRes => {
        rates[roomType] = ratesRes.data;
        expediaRatePlans[roomType] = res.data.entity;
        this.setState({
          spinning: false,
          rates,
          expediaRatePlans
        });
        this.setIsExpediaLoading(false);
      });
    });
  };

  setIsExpediaLoading = status => {
    this.setState({
      isExpediaLoading: status
    });
  };

  handleOnSave = e => {
    const { expediaRateMapping, expediaRoomTypeMapping } = this.state;
    e.preventDefault();
    const title = intl.get('hostConnect.integration.message.integrationSettingTitle').d('Are you sure you want to save this integration setting?');
    const content = '';
    const onOkAction = this.saveExpedia;
    if (Object.keys(expediaRateMapping).length > 0 && Object.keys(expediaRoomTypeMapping).length > 0) {
      Modal.confirm({
        title,
        content,
        okText: intl.get('hostConnect.integration.headerLabels.continue').d('Continue'),
        okType: 'warning',
        cancelText: intl.get('hostConnect.integration.headerLabels.back').d('Back'),
        onOk() {
          return onOkAction();
        },
        onCancel() {}
      });
    } else {
      Modal.warning({
        title: `${intl.get('hostConnect.integration.message.integrationSettingError').d("Can't save without room type and rate")}`,
        content: '',
        okText: intl.get('hostConnect.integration.headerLabels.back').d('Back')
      });
    }
  };

  saveExpedia = () => {
    const { expediaPropertyId, expedias, expediaRoomTypeMapping, expediaRateMapping, expediaRoomTypes, currentExpediaId } = this.state;
    const { property } = this.props;
    const body = {
      property: property._id,
      expediaPropertyId,
      expediaPropertyName: expedias.find(ep => String(ep.expediaPropertyId) === String(expediaPropertyId)).expediaPropertyName,
      data: {
        roomTypes: Object.keys(expediaRoomTypeMapping)
          .filter(roomType => expediaRateMapping[roomType])
          .map(roomTypeId => {
            const expediaRoomType = expediaRoomTypes.find(expRt => String(expRt.resourceId) === String(expediaRoomTypeMapping[roomTypeId]));
            return {
              roomType: roomTypeId,
              expediaRoomTypeId: expediaRoomTypeMapping[roomTypeId],
              expediaRoomTypeName: expediaRoomType && expediaRoomType.name.value,
              rates: expediaRateMapping[roomTypeId].map(rate => {
                return {
                  rate: rate.rate,
                  expediaRateId: rate.expRate
                };
              })
            };
          })
      }
    };
    this.setState({
      isSaving: true
    });
    // update to nebula
    putExpedia(currentExpediaId, body)
      .then(response => {
        this.setState({
          isSaving: false,
          savedExpedia: response.data,
          openExpediaSyncModal: true
        });
        notification.success({
          message: intl.get('hostConnect.integration.message.integrationUpdateSuccuess').d('Successfully updated')
        });
      })
      .catch(e => {
        this.setState({
          isSaving: false
        });
        notification.error({
          message: intl
            .get('hostConnect.integration.message.integrationUpdateError')
            .d("Something went wrong and your changes on Expedia's is not saved")
        });
      });
  };

  updateRates = (roomType, expRate) => newRates => {
    const { expediaRateMapping } = this.state;
    if (expediaRateMapping[roomType]) {
      const currentRate = expediaRateMapping[roomType].find(rate => rate.expRate === expRate);
      if (currentRate) {
        currentRate.rate = {
          ...currentRate.rate,
          ...newRates
        };
      } else {
        expediaRateMapping[roomType].push({ rate: newRates, expRate });
      }
    } else {
      expediaRateMapping[roomType] = [{ rate: newRates, expRate }];
    }
    this.setState({ expediaRateMapping });
  };

  getRateByRoomTypeAndRatePlanId = (roomTypeId, expRate) => {
    const { expediaRateMapping } = this.state;
    const rateMap = expediaRateMapping[roomTypeId];
    const currentRate = rateMap && rateMap.find(rate => String(rate.expRate) === String(expRate));
    if (currentRate) {
      return currentRate.rate;
    } else {
      getWebRateByRoomType(roomTypeId).then(webRates => {
        const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
        if (stdRate) {
          const { weekday, weekend, isDerived = false } = stdRate;
          this.updateRates(roomTypeId, expRate)({ weekday, weekend, isDerived });
        }
      });
    }
  };

  extractExpediaSyncInfo = () => {
    const { savedExpedia } = this.state;
    if (Object.keys(savedExpedia).length > 0) {
      return {
        _id: savedExpedia._id,
        property: savedExpedia.property,
        propertyName: this.props.property.name,
        status: savedExpedia.status,
        expediaPropertyId: savedExpedia.expediaPropertyId,
        expediaPropertyName: savedExpedia.expediaPropertyName,
        roomTypes: savedExpedia.data.roomTypes
      };
    }
  };

  toggleToSync = roomTypeId => isSync => {
    const { expediaRoomTypeMapping } = this.state;
    let updatedRoomMapping = expediaRoomTypeMapping;

    if (isSync) {
      updatedRoomMapping[roomTypeId] = null;
    } else {
      delete updatedRoomMapping[roomTypeId];
    }

    this.setState({
      expediaRoomTypeMapping: updatedRoomMapping
    });
  };

  handleOnModalClose = () => {
    this.setState({
      openExpediaSyncModal: false
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  handleOnAdvancedOptionsClose = e => {
    e.preventDefault();
    this.setState({
      isAdvancedOptionsModalVisible: false
    });
  };

  handleOnAdvanceOptionsClick = e => {
    e.preventDefault();
    this.setState({
      isAdvancedOptionsModalVisible: true
    });
  };

  handleOnAdvancedOptionsConfirm = rateDistributionPayload => {
    const { expediaRateMapping, rateModifierTypeConstants, rateModifierSignConstants } = this.state;

    const processedRateMapping = Object.entries(expediaRateMapping).reduce((resultObject, roomKeyWithValue) => {
      const roomId = roomKeyWithValue[0];
      const rates = roomKeyWithValue[1];
      const rateDistribution = rateDistributionPayload.find(rateDistribution => String(roomId) === String(rateDistribution.roomId));
      let processedRates = [...rates];

      if (!!rateDistribution) {
        processedRates = rates.map(roomRateObject => {
          const rateObject = rateDistribution.rates.find(rateObject => String(rateObject.otaId) === String(roomRateObject.expRate));

          if (!!rateObject) {
            const isDerived = rateObject.rate.isDerived;
            let processedRoomRateObject = {
              expRate: String(roomRateObject.expRate),
              rate: {
                _id: rateObject.rate._id,
                weekday: rateObject.rate.weekdayRate,
                weekend: rateObject.rate.weekendRate,
                isDerived
              }
            };

            if (isDerived) {
              const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
              const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
              let amount = rateObject.rate.modifierAmount;
              amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

              processedRoomRateObject.rate.calculation = {
                type: rateObject.rate.modifierType,
                isPositive,
                amount
              };
            }

            return processedRoomRateObject;
          } else {
            return { ...roomRateObject };
          }
        });
      }

      resultObject[roomId] = processedRates;
      return resultObject;
    }, {});

    this.setState({ expediaRateMapping: processedRateMapping, isAdvancedOptionsModalVisible: false });
  };

  render() {
    const {
      expediaRoomTypeMapping,
      expedias,
      expediaRateMapping,
      expediaPropertyId,
      expediaRoomTypes,
      expediaRatePlans,
      rateModifierTypeConstants,
      rateModifierSignConstants,
      openExpediaSyncModal,
      isAdvancedOptionsModalVisible,
      isExpediaLoading,
      spinning
    } = this.state;
    const { property, data } = this.props;
    const curExpedia = expedias.find(exp => String(exp.expediaPropertyId) === String(expediaPropertyId));
    const mappedRoomTypes = !!property.roomTypes
      ? property.roomTypes.reduce((result, roomType) => {
          if (!!Object.keys(expediaRoomTypeMapping).find(expediaRoomTypeKey => expediaRoomTypeKey === roomType._id)) {
            return [
              ...result,
              {
                roomName: roomType.name,
                roomId: roomType._id
              }
            ];
          } else {
            return result;
          }
        }, [])
      : undefined;

    return (
      <Row className="scroll-bar-style">
        {isAdvancedOptionsModalVisible && (
          <ExpediaAdvancedOptionsModal
            mappedRoomTypes={mappedRoomTypes}
            expediaRateMapping={expediaRateMapping}
            expediaRatePlans={expediaRatePlans}
            rateModifierTypeConstants={rateModifierTypeConstants}
            rateModifierSignConstants={rateModifierSignConstants}
            isVisible={isAdvancedOptionsModalVisible}
            onConfirm={this.handleOnAdvancedOptionsConfirm}
            onClose={this.handleOnAdvancedOptionsClose}
            currency={this.state.currency}
          />
        )}
        <Skeleton loading={isExpediaLoading} active paragraph={{ rows: 1 }}>
          <Row className="ota-row">
            <label className="ota-label">{intl.get('hostConnect.integration.headerLabels.expediaPropertyId').d('Expedia Property ID')}</label>
            <Select
              showSearch
              placeholder={intl.get('hostConnect.integration.placeholder.selectProperty').d('Select a property')}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="full-length-box"
              value={curExpedia && curExpedia.expediaPropertyId}
              onChange={val => this.handleOnExpediaPropertyIdChange(val)}
              disabled={!!data}
            >
              {expedias.map(expProp => {
                return (
                  <Option value={expProp.expediaPropertyId} key={expProp.expediaPropertyId}>
                    {`${expProp.expediaPropertyId} | ${expProp.expediaPropertyName}`}
                  </Option>
                );
              })}
            </Select>
          </Row>
        </Skeleton>
        {/* Room type */}
        {property.roomTypes &&
          property.roomTypes.map(roomType => {
            const curRoomType =
              expediaRoomTypeMapping &&
              expediaRoomTypes &&
              expediaRoomTypes.find(
                expRT => expediaRoomTypeMapping[roomType._id] && expediaRoomTypeMapping[roomType._id] === expRT.resourceId.toString()
              );
            const isReadyToSync = !!expediaRoomTypeMapping[roomType._id] || expediaRoomTypeMapping[roomType._id] === null;
            const selectedExpediaRoomTypeIds = Object.values(expediaRoomTypeMapping);
            const unselectedExpediaRoomType = expediaRoomTypes.filter(expRT => {
              return !selectedExpediaRoomTypeIds.includes(String(expRT.resourceId)) || (curRoomType && curRoomType.resourceId === expRT.resourceId);
            });

            return (
              <Skeleton key={roomType._id} loading={isExpediaLoading} active>
                <Row>
                  <Card className="ota-card" title={roomType.name}>
                    <label className="booking-label">
                      {' '}
                      {`${intl.get('hostConnect.integration.headerLabels.sync').d('Sync')} ${roomType.name} ${intl
                        .get('hostConnect.integration.headerLabels.toExpedia')
                        .d('to Expedia')}`}
                    </label>
                    <Switch
                      checkedChildren={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
                      unCheckedChildren={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
                      onChange={this.toggleToSync(roomType._id)}
                      checked={isReadyToSync}
                      style={{ marginLeft: '5px' }}
                    />
                    {isReadyToSync && (
                      <>
                        <Spin spinning={spinning}>
                          <Select
                            showSearch
                            placeholder={intl.get('hostConnect.integration.placeholder.selectRoomType').d('Select a room type')}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            style={{ width: '100%', marginBottom: '25px' }}
                            value={curRoomType && curRoomType.resourceId}
                            onChange={this.handleOnExpediaRoomTypeIdChange(roomType._id)}
                          >
                            {unselectedExpediaRoomType.map(expRT => {
                              return <Option value={expRT.resourceId}>{`${expRT.name.value} (${expRT.resourceId})`}</Option>;
                            })}
                          </Select>
                        </Spin>
                        <Row>
                          {expediaRatePlans[roomType._id] && expediaRatePlans[roomType._id].length === 0 && (
                            <p className={'errorNoRateText'}>
                              {intl
                                .get('hostConnect.integration.headerLabels.cantSync')
                                .d("Can't sync to this room type as there is no rate to be found.")}
                            </p>
                          )}
                          {/* Rate */}
                          {expediaRatePlans[roomType._id] && expediaRatePlans[roomType._id].length > 0 && (
                            <Spin spinning={spinning}>
                              <Card title={intl.get('hostConnect.integration.headerLabels.ratePlan').d('Rate Plans')}>
                                {expediaRatePlans[roomType._id].map(ratePlan => {
                                  const expediaRatePlan = ratePlan.distributionRules.find(rate => rate.manageable === true);
                                  return (
                                    <Row>
                                      <Col>
                                        {/* Expedia Rate plan displayed is Managable for our side ONLY !, check via console log */}
                                        <label style={{ fontWeight: 'bold' }}>{`${ratePlan.name} | ${expediaRatePlan.expediaId}`}</label>
                                        <OTARateInput
                                          rate={this.getRateByRoomTypeAndRatePlanId(roomType._id, expediaRatePlan.expediaId)}
                                          updateRates={this.updateRates(roomType._id, expediaRatePlan.expediaId)}
                                          currency={this.state.currency}
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Card>
                            </Spin>
                          )}
                        </Row>
                      </>
                    )}
                  </Card>
                </Row>
              </Skeleton>
            );
          })}
        {/* Save Button */}
        {Object.keys(expediaRateMapping).length > 0 && !spinning && Object.keys(expediaRatePlans.length > 0) && (
          <>
            <Row>
              <a href=" " onClick={this.handleOnAdvanceOptionsClick}>
                {intl.get('hostConnect.integration.headerLabels.advanced').d('Advanced Options')}
              </a>
            </Row>
            <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
              <Col>
                <Button type="secondary" onClick={this.props.onClose}>
                  {intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={this.handleOnSave}>
                  {intl.get('hostConnect.integration.headerLabels.save').d('Save')}
                </Button>
              </Col>
            </Row>
            {/* Expedia sync's form */}
            <Modal
              visible={openExpediaSyncModal}
              onCancel={this.handleOnModalClose}
              footer={null}
              title={intl.get('hostConnect.integration.headerLabels.syncAvailability').d('Sync Availability, Rates, Inventory to Expedia')}
            >
              <ExpediaSyncForm expediaData={this.extractExpediaSyncInfo()} handleModalCancel={this.handleOnModalClose} />
            </Modal>
          </>
        )}
      </Row>
    );
  }
}

ExpediaForm.propTypes = {
  data: PropTypes.object,
  property: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ExpediaForm;
