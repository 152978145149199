import React from 'react';
import { Card, Row, Switch } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import OTARateInput from '../../../../OTARateInput/OTARateInput';

import styles from './RoomListing.module.css';
import intl from 'react-intl-universal';

const RoomRates = ({ travelokaRates, onChangeRate }) => {
  return travelokaRates.map(travelokaRate => (
    <Row key={travelokaRate.travelokaRateId} className={styles.row}>
      <Card title={`${travelokaRate.travelokaRateName} | ${travelokaRate.travelokaRateId}`}>
        <OTARateInput rate={travelokaRate.rate} updateRates={onChangeRate(travelokaRate.travelokaRateId)} />
      </Card>
    </Row>
  ));
};

const RoomListing = ({ travelokaRoomsSelection, roomsMapping, onToggleRoom, onSelectTravelokaRoom, onChangeRate }) => {
  const handleOnChangeRate = roomTypeId => travelokaRateId => onChangeRate(roomTypeId, travelokaRateId);

  return roomsMapping.map(roomMapping => (
    <Card style={{ marginBottom: '16px' }} key={roomMapping._id} title={roomMapping.name} headStyle={{ backgroundColor: '#cbf2f4' }}>
      <label className="booking-label">{`${intl.get('hostConnect.integration.headerLabels.sync').d('Sync')} ${roomMapping.name} ${intl
        .get('hostConnect.traveloka.headerLabels.toTraveloka')
        .d('to Traveloka')}`}</label>
      <Switch
        checkedChildren={intl.get('booking_form.guestDetails.alertMessage.yes').d('Yes')}
        unCheckedChildren={intl.get('booking_form.guestDetails.alertMessage.no').d('No')}
        onChange={onToggleRoom(roomMapping._id)}
        checked={roomMapping.isToggled}
      />
      {roomMapping.isToggled && (
        <>
          <Row className={styles.row}>
            <BasicSelect
              selectClassName={styles.select}
              selections={travelokaRoomsSelection}
              value={roomMapping.formData.travelokaRoomId}
              placeholder={intl.get('hostConnect.traveloka.placeholder.travelokaRoom').d('Select traveloka room')}
              onChange={onSelectTravelokaRoom(roomMapping._id)}
            />
          </Row>
          {!!roomMapping.formData.travelokaRates && (
            <RoomRates
              roomTypeId={roomMapping._id}
              travelokaRates={roomMapping.formData.travelokaRates}
              onChangeRate={handleOnChangeRate(roomMapping._id)}
            />
          )}
        </>
      )}
    </Card>
  ));
};

export default RoomListing;
