import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Alert, DatePicker, Modal } from 'antd';
import { withAppContext } from 'context/AppContext';
import moment from 'moment';
import styles from './InvoiceModal.module.css';

import { getHosts } from 'utils/apis/host';
import { getInvoiceById, getPaymentSessionById } from 'utils/apis/billingInvoice';
import FormSelection from 'components/FormSelection/FormSelection';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import UploadFiles from 'components/UploadFiles/UploadFiles';
import FpxModal from '../FpxModal/FpxModal';
import intl from 'react-intl-universal';

const stripe = !!window.Stripe && window.Stripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_ecHsiw8fixGWjZ7HwmP5bk3y00gwo2VxfK');

const FormItem = Form.Item;

const PAIDSTATUS = [{ key: 'paid', value: 'paid', displayValue: 'Paid' }, { key: 'unpaid', value: 'unpaid', displayValue: 'Unpaid' }];

class InvoiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceEmail: '',
      fullHostOptions: [],
      files: [],
      host: null,
      invoiceNo: null,
      invoiceDate: null,
      invoiceDueDate: null,
      amount: 0,
      paymentStatus: 'unpaid',
      isModalClicked: false
    };
  }

  componentDidMount() {
    const { record } = this.props;
    if (record && record._id) {
      getInvoiceById(record._id)
        .then(invoice => {
          this.setState({
            host: invoice.host._id,
            invoiceEmail: invoice.host.invoiceEmail,
            invoiceNo: invoice.invoiceNo,
            invoiceDate: moment(new Date(invoice.invoiceDate), 'YYYY-MM-DD'),
            invoiceDueDate: moment(new Date(invoice.invoiceDueDate), 'YYYY-MM-DD'),
            amount: invoice.amount,
            paymentStatus: invoice.paymentStatus,
            files: invoice.files || []
          });
        })
        .catch(ex => {
          console.log(ex);
        });
    }

    getHosts()
      .then(res => {
        const fullHostOptions = res.data.map(data => {
          return { value: data.name, key: data._id, invoiceEmail: data.invoiceEmail };
        });
        this.setState({
          fullHostOptions
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  handleOnUploadFinish = e => {
    this.setState(prevState => {
      const uploadedFile = {
        name: e.file.name,
        link: e.fileUrl
      };
      return {
        files: [...prevState.files, uploadedFile]
      };
    });
  };

  handleOnFileDelete = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      const { files } = prevState;
      files.splice(index, 1);
      return {
        files
      };
    });
  };

  handleOnMakePaymentBtnClicked = async id => {
    const sessionId = await getPaymentSessionById(id).then(paymentSession => paymentSession.id);
    return stripe
      .redirectToCheckout({
        sessionId
      })
      .then(result => {
        if (result.error) {
          console.log('There was an error: ', result.error.message);
        }
      });
  };

  handleOnMakeFpxPaymentBtnClicked = () => {
    this.setState({
      isModalClicked: true
    });
  };

  handleOnCancelModal = () => {
    this.setState({
      isModalClicked: false
    });
  };

  handleOnInvoiceHostChange = value => {
    const { fullHostOptions } = this.state;

    const host = fullHostOptions.find(data => {
      return data.key === value;
    });

    this.setState({
      invoiceEmail: host.invoiceEmail
    });
  };

  showOverdueNotice = record => {
    const { checkIsAdmin } = this.props;

    if (record && record.paymentStatus === 'unpaid' && moment(new Date(), 'YYYY-MM-DD') > moment(record.invoiceDueDate) && checkIsAdmin()) {
      return (
        <Row>
          <div className="users-info-wrapper">
            <Alert
              message={intl
                .get('invoice.message.overdueAdmin')
                .d('This invoice is overdue and should now be paid. Please collect payment as soon as possible.')}
              type="error"
              showIcon
            />
          </div>
        </Row>
      );
    } else if (record && record.paymentStatus === 'unpaid' && moment(new Date(), 'YYYY-MM-DD') > moment(record.invoiceDueDate)) {
      return (
        <Row>
          <div className="users-info-wrapper">
            <Alert
              message={intl
                .get('invoice.message.overdueUser')
                .d('This invoice is overdue and should now be paid. Please make payment as soon as possible.')}
              type="error"
              showIcon
            />
          </div>
        </Row>
      );
    }
  };

  render() {
    const { form, handleOnInvoiceSubmit, formDisabled, record, checkIsAdmin, isEditClicked, visible, onCancel } = this.props;
    const { files, host, invoiceNo, invoiceDate, invoiceDueDate, amount, paymentStatus, isModalClicked, invoiceEmail, fullHostOptions } = this.state;
    return (
      <Modal
        visible={visible}
        maskClosable={false}
        title={
          record
            ? `${intl.get('invoice.headerLabels.invoiceNum').d('Invoice Number: ')} ${record.invoiceNo}`
            : `${intl.get('invoice.headerLabels.create').d('Create Invoice')}`
        }
        footer={null}
        onCancel={onCancel}
      >
        <Form
          onSubmit={event => {
            event.preventDefault();
            handleOnInvoiceSubmit(form, files, record, false, invoiceEmail);
          }}
          style={{ width: '100%' }}
        >
          {isModalClicked && <FpxModal visible={isModalClicked} record={record} onCancel={this.handleOnCancelModal} />}
          {this.showOverdueNotice(record)}
          <Row>
            <FormSelection
              name="host"
              label={intl.get('invoice.headerLabels.host').d('Host')}
              requiredErrorMessage={intl.get('reservations.reservationDetails.placeholder.hostMsg').d('Please select a host')}
              selections={fullHostOptions}
              placeholder={intl.get('reservations.reservationDetails.placeholder.host').d('Select a host')}
              onSearch={() => {}}
              onChange={this.handleOnInvoiceHostChange}
              form={form}
              size="large"
              defaultValue={host}
              shouldDisable={formDisabled}
            />
          </Row>
          <Row>
            <FormInput
              form={form}
              name="invoiceNo"
              requiredErrorMessage={intl.get('reservations.reservationDetails.placeholder.invoiceMsg').d('Please enter invoice number')}
              inputType="input"
              label={intl.get('invoice.headerLabels.invoiceNumLabel').d('Invoice Number')}
              placeholder={intl.get('reservations.reservationDetails.placeholder.invoice').d('Invoice Number')}
              size="large"
              defaultValue={invoiceNo}
              disabled={formDisabled}
            />
          </Row>
          <Row>
            <p style={{ marginBottom: '8px' }}>{intl.get('invoice.headerLabels.invoiceDate').d('Invoice Date')}</p>
            <FormItem style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('invoiceDate', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.date').d('Please select a date!')
                  }
                ],
                initialValue: invoiceDate || moment(new Date(), 'YYYY-MM-DD')
              })(
                <DatePicker
                  name="invoiceDate"
                  size="large"
                  style={{ width: '100%', marginBottom: '24px' }}
                  format={'YYYY-MM-DD'}
                  disabled={formDisabled}
                  disabledDate={value => {
                    const form = this.props.form;
                    return value > form.getFieldValue('invoiceDueDate');
                  }}
                />
              )}
            </FormItem>
          </Row>
          <Row>
            <p style={{ marginBottom: '8px' }}>{intl.get('invoice.headerLabels.invoiceDueDate').d('Invoice Due Date')}</p>
            <FormItem style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('invoiceDueDate', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.date').d('Please select a date!')
                  }
                ],
                initialValue: invoiceDueDate || moment(new Date(), 'YYYY-MM-DD')
              })(
                <DatePicker
                  name="invoiceDueDate"
                  size="large"
                  style={{ width: '100%', marginBottom: '24px' }}
                  format={'YYYY-MM-DD'}
                  disabled={formDisabled}
                  disabledDate={value => {
                    const form = this.props.form;
                    return value < form.getFieldValue('invoiceDate');
                  }}
                />
              )}
            </FormItem>
          </Row>
          <Row>
            <FormInputNumber
              form={form}
              precision={2}
              minValue={0}
              name="amount"
              requiredErrorMessage={intl.get('reservations.reservationDetails.placeholder.amountMsg').d('Please enter an amount')}
              inputType="input"
              label={intl.get('invoice.headerLabels.amount').d('Amount') + '(RM)'}
              placeholder={intl.get('reservations.reservationDetails.placeholder.amount').d('Amount to be charged')}
              size="large"
              defaultValue={amount}
              disabled={formDisabled}
              extraRules={[
                {
                  validator: (rule, value, callback) => {
                    const form = this.props.form;
                    if (Number(form.getFieldValue('amount') === 0)) {
                      callback(intl.get('reservations.reservationDetails.placeholder.amountMsg').d('Please enter an amount'));
                    } else {
                      callback();
                    }
                  }
                }
              ]}
            />
          </Row>
          <Row>
            <FormRadioButton
              form={form}
              formLabel={intl.get('invoice.headerLabels.paymentStatus').d('Payment Status')}
              name="paymentStatus"
              buttonStyle="solid"
              selections={PAIDSTATUS}
              defaultValue={paymentStatus}
              shouldDisable={formDisabled}
            />
          </Row>
          {formDisabled ? (
            paymentStatus === 'paid' ? (
              <div className={styles.fileList}>
                {!!record.files && record.files.length > 0 && (
                  <>
                    <label>Files attached:</label>
                    {record.files.map(file => (
                      <div>
                        <a href={file.link} download={file.link} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <>
                <Row type="flex" justify="center" gutter={16}>
                  <Row type="flex" justify="center">
                    <UploadFiles
                      files={files}
                      onUploadFinish={this.handleOnUploadFinish}
                      onFileDelete={this.handleOnFileDelete}
                      checkIsAdmin={checkIsAdmin()}
                    />
                  </Row>
                  <Button
                    type="primary"
                    id="ccpay-button1-bill"
                    className={styles.button}
                    onClick={() => {
                      this.handleOnMakePaymentBtnClicked(record._id);
                    }}
                    size="large"
                    style={{ width: '70%', marginBottom: '24px' }}
                  >
                    {intl.get('invoice.headerLabels.credit').d('Make Payment via Credit Card')}
                  </Button>
                </Row>
                <Row type="flex" justify="center" gutter={16}>
                  <Button
                    type="primary"
                    id="fpxpay-button2-bill"
                    className={styles.button}
                    onClick={() => this.handleOnMakeFpxPaymentBtnClicked(record)}
                    size="large"
                    style={{ width: '70%', marginBottom: '24px' }}
                  >
                    {intl.get('invoice.headerLabels.fpx').d('Make Payment via FPX')}
                  </Button>
                </Row>
                <Row type="flex" justify="center" gutter={16}>
                  <Button
                    type="primary"
                    id="email-button3-bill"
                    onClick={() => {
                      handleOnInvoiceSubmit(form, files, record, true, invoiceEmail);
                    }}
                    size="large"
                    style={{ width: '70%' }}
                  >
                    {intl.get('invoice.headerLabels.saveAndEmail').d('Save and Send Email')}
                  </Button>
                </Row>
              </>
            )
          ) : (
            <>
              <Row type="flex" justify="center">
                <UploadFiles
                  files={files}
                  onUploadFinish={this.handleOnUploadFinish}
                  onFileDelete={this.handleOnFileDelete}
                  checkIsAdmin={checkIsAdmin()}
                />
              </Row>
              <Row type="flex" justify="center" gutter={16}>
                <Button type="primary" htmlType="submit" size="large" style={{ width: '70%', marginBottom: '16px' }}>
                  {intl.get('invoice.headerLabels.save').d('Save')}
                </Button>
              </Row>
              {isEditClicked && (
                <Row type="flex" justify="center" gutter={16}>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleOnInvoiceSubmit(form, files, record, true, invoiceEmail);
                    }}
                    size="large"
                    style={{ width: '70%' }}
                  >
                    {intl.get('invoice.headerLabels.saveAndEmail').d('Save and Send Email')}
                  </Button>
                </Row>
              )}
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  handleOnInvoiceSubmit: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool,
  isEditClicked: PropTypes.bool,
  checkIsAdmin: PropTypes.func,
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func
};

InvoiceModal.defaultProps = {
  record: {},
  handleOnInvoiceSubmit: () => {},
  formDisabled: false,
  isEditClicked: false,
  checkIsAdmin: () => {},
  visible: false,
  onCancel: () => {}
};

export default withAppContext(Form.create()(InvoiceModal));
