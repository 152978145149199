import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Col, Form, Icon, Input, message, Modal, Row, Select } from 'antd';

import { withAppContext } from 'context/AppContext';
import { buildTaskManagementUri } from 'utils/routes';

import CloseButton from 'components/CloseButton/CloseButton';

import { getHostsAutomated } from 'utils/apis/host';
import { getPropertiesByHostId } from 'utils/apis/property';

import intl from 'react-intl-universal';

import './ChecklistForm.css';
import { createChecklist, deleteChecklistById, getCLById, updateChecklist } from 'utils/apis/taskManagement';

const FormItem = Form.Item;
const { Option } = Select;

const MODE_NEW = 'new';
const MODE_EDIT = 'edit';
let id = 0;

class ChecklistForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      mode: MODE_NEW,
      host: '',
      hostOptions: [],
      property: [],
      propertyOptions: [],
      update: false,
      items: []
    };

    this.getHosts = this.getHosts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { form, match } = this.props;
    this.getHosts().then(() => this.setState({ isLoading: false }));
    if (!match.params.id) {
      form.setFieldsValue({
        keys: [id]
      });
      id++;
    }
    if (match.params.id) {
      this.getChecklistById(match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.host !== this.state.host) {
      let propertyOptions = [];

      getPropertiesByHostId(this.state.host).then(res => {
        propertyOptions = res.map(property => ({ label: property.name, value: property._id }));

        if (this.props.match.params.id) {
          for (let i = 0; i < this.state.property.length; i++) {
            if (!propertyOptions.some(p => p.value === this.state.property[i])) {
              this.props.form.setFieldsValue({
                properties: []
              });
              break;
            } else {
              this.props.form.setFieldsValue({
                properties: this.state.property
              });
            }
          }
        } else {
          this.props.form.setFieldsValue({
            properties: []
          });
          this.setState({ property: [] });
        }
        this.setState({ propertyOptions });
      });
    }

    if (this.state.update) {
      this.props.form.setFieldsValue({
        items: this.state.items
      });
      id = this.state.items.length;
      this.setState({ update: false });
    }
  }

  getHosts = async () => {
    const res = await getHostsAutomated();

    let hostOptions = [];

    if (res.status === 200) {
      hostOptions = res.data.map(host => {
        return { label: host.name, value: host._id, type: host.hostType, allowRentalListing: host.allowRentalListing };
      });
      if (hostOptions.length === 1) {
        this.setState({ host: hostOptions[0].value });
        this.props.form.setFieldsValue({
          host: hostOptions[0].value
        });
      }
      this.setState({ hostOptions });
    }
  };

  getChecklistById = async id => {
    try {
      let mode = MODE_EDIT;
      const res = await getCLById(id);

      if (res && res.data && res.status === 200) {
        let keys = res.data.items.map((item, idx) => idx);
        this.props.form.setFields({
          host: {
            value: res.data.host
          },
          checklistName: {
            value: res.data.name
          },
          properties: {
            value: res.data.properties
          },
          keys: {
            value: keys
          }
        });
        this.setState({ mode, host: res.data.host, property: res.data.properties, isLoading: false, update: true, items: res.data.items });
      }
    } catch (ex) {
      message.error(intl.get('taskManagement.checklist.messages.errorRetrieve').d('Error retrieving checklist'));
      console.log(ex);
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;
    const { mode } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        delete values.keys;
        values.items = values.items.filter(i => i);
        if (mode === MODE_NEW) {
          this.setState({ isSaveButtonLoading: true });
          createChecklist(values).then(res => {
            if (res.status === 200) {
              history.push(`${buildTaskManagementUri()}/checklist`);
              message.success(
                `${intl.get('taskManagement.checklist.messages.checklist').d('Checklist')} (${res.data.name}) ${intl
                  .get('taskManagement.checklist.messages.sucessCreate')
                  .d('sucess created')}`
              );
            }
          });
        } else if (mode === MODE_EDIT) {
          const checklistId = match.params.id;

          const handleOnOk = async () => {
            try {
              this.setState({ isSaveButtonLoading: true });
              const res = await updateChecklist(checklistId, values);
              if (res && res.status === 204) {
                history.push(`${buildTaskManagementUri()}/checklist`);
                message.success(intl.get('taskManagement.checklist.messages.updated').d('Checklist updated !'));
                this.setState({ isSaveButtonLoading: false });
              }
            } catch (ex) {
              message.error(ex);
              this.setState({ isSaveButtonLoading: false });
            }
          };

          Modal.confirm({
            title: intl.get('taskManagement.checklist.messages.confirmTitle').d('Are you sure want to overwrite existing data?'),
            content: intl
              .get('taskManagement.checklist.messages.confirmContent')
              .d('You will not be able to undo this action, but you may update it again.'),
            onOk: handleOnOk,
            onCancel() {},
            okButtonProps: { id: 'save-cfm-button-editcl' },
            cancelButtonProps: { id: 'cancelsave-cfm-button-editcl' }
          });
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const checklistId = match.params.id;

    const handleOnOk = async () => {
      try {
        this.setState({ isDeleteButtonLoading: true });
        const res = await deleteChecklistById(checklistId);
        if (res.status === 204) {
          this.setState({ isDeleteButtonLoading: false });
          history.push(`${buildTaskManagementUri()}/checklist`);
          message.success(intl.get('taskManagement.checklist.messages.deleted').d('Checklist deleted !'));
        } else {
          message.error(intl.get('taskManagement.checklist.messages.deleteError').d('Something went wrong and checklist is not deleted'));
          this.setState({ isDeleteButtonLoading: false });
        }
      } catch (ex) {
        message.error(ex || intl.get('taskManagement.checklist.messages.deleteError2').d('Something went wrong and checklist is not deleted'));
        this.setState({ isDeleteButtonLoading: false });
      }
    };

    Modal.confirm({
      title: intl.get('taskManagement.checklist.messages.deleteTitle').d('Are you sure want to delete this checklist?'),
      content: intl.get('taskManagement.checklist.messages.deleteContent').d('This action cannot be reversed. Once deleted, it cannot be recovered'),
      onOk: handleOnOk,
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button-editcl' },
      cancelButtonProps: { id: 'del-cfm-button-editcl' }
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id);
    id++;
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one item
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  render() {
    const { form, history, checkIsAllowCreateTask, checkIsAllowDeleteTask, checkIsAllowEditTask, checkIsAdminReadOnly } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { mode, isLoading, hostOptions, propertyOptions, isSaveButtonLoading, isDeleteButtonLoading } = this.state;
    const AddItemButton = (
      <FormItem style={{ padding: '0px 0px 8px', marginBottom: 12 }}>
        <Button type="primary" icon="plus" onClick={this.add}>
          {intl.get('taskManagement.checklist.headerLabels.addItem').d('Add new item')}
        </Button>
      </FormItem>
    );
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map(k => (
      <Col span={24} key={`item${k}`}>
        <FormItem style={{ marginBottom: 12 }}>
          {getFieldDecorator(`items[${k}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: intl.get('taskManagement.checklist.messages.itemError').d('Please insert a item or delete this field')
              }
            ]
          })(
            <Input
              placeholder={intl.get('reservations.reservationDetails.placeholder.egItem').d('e.g. Bedsheet, vacuum')}
              style={{ marginRight: 8, width: '90%' }}
            />
          )}
          {keys.length > 1 && <Icon type="delete" style={{ color: '#f00' }} onClick={() => this.remove(k)} />}
        </FormItem>
      </Col>
    ));

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Card
          className="checklist-form-card"
          title={intl.get('taskManagement.checklist.headerLabels.createChecklist').d('Create Checklist')}
          loading={isLoading}
        >
          <CloseButton
            onClick={() => {
              history.push(`${buildTaskManagementUri()}/checklist`);
            }}
          />
          <Row type="flex" justify="start" gutter={36}>
            <FormItem label={intl.get('taskManagement.checklist.headerLabels.hostName').d('Host Name')} className="checklist-form-list-half">
              {getFieldDecorator('host', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.hostMsg').d('Please select a host')
                  }
                ]
              })(
                <Select
                  showSearch
                  placeholder={intl.get('reservations.reservationDetails.placeholder.host').d('Select a host')}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ host: e })}
                >
                  {hostOptions.map(hostOption => {
                    return (
                      <Option key={hostOption.value} value={hostOption.value}>
                        {hostOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={intl.get('taskManagement.checklist.headerLabels.checklistName').d('Checklist Name')}
              className="checklist-form-list-half"
            >
              {getFieldDecorator('checklistName', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.checklistName').d('Please enter checklist name')
                  }
                ]
              })(<Input placeholder={intl.get('reservations.reservationDetails.placeholder.egChecklist').d('eg. Genting w/ coffee pack')} />)}
            </FormItem>
            <FormItem label={intl.get('taskManagement.checklist.headerLabels.property').d('Property Applied')} className="checklist-form-list">
              {getFieldDecorator('properties', {
                rules: [
                  {
                    required: true,
                    message: intl.get('reservations.reservationDetails.placeholder.atLeastPropertyMsg').d('Please select at least one property')
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  placeholder={intl.get('reservations.reservationDetails.placeholder.atLeastProperty').d('Select at least one property')}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={e => this.setState({ property: e })}
                  disabled={propertyOptions.length === 0}
                >
                  {propertyOptions.map(propertyOption => {
                    return (
                      <Option key={propertyOption.value} value={propertyOption.value}>
                        {propertyOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>

            <Col span={24} style={{ paddingBottom: 8 }}>
              <span style={{ color: '#f00' }}>*</span> {intl.get('taskManagement.checklist.headerLabels.item').d('Item Name')}
            </Col>
            {formItems}
            <Col span={24}>{AddItemButton}</Col>
          </Row>
        </Card>
        <div className="save-and-delete-buttons">
          <FormItem className="checklist-button-wrapper">
            {mode === 'new' ? (
              checkIsAllowCreateTask() && (
                <Button id="create-button-checklist" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                  {intl.get('taskManagement.checklist.headerLabels.create').d('Create')}
                </Button>
              )
            ) : (
              <React.Fragment>
                {checkIsAllowEditTask() && (
                  <Button
                    id="save-button-edit-checklist"
                    type="primary"
                    htmlType="submit"
                    loading={isSaveButtonLoading}
                    disabled={checkIsAdminReadOnly()}
                  >
                    {intl.get('taskManagement.checklist.headerLabels.save').d('Save')}
                  </Button>
                )}
                {checkIsAllowDeleteTask() && (
                  <Button
                    id="del-button-edit-checklist"
                    type="danger"
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                    onClick={this.handleDelete}
                    disabled={checkIsAdminReadOnly()}
                  >
                    {intl.get('taskManagement.checklist.headerLabels.delete').d('Delete')}
                  </Button>
                )}
              </React.Fragment>
            )}
          </FormItem>
        </div>
      </Form>
    );
  }
}

export default withRouter(withAppContext(Form.create()(ChecklistForm)));
