import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, notification } from 'antd';

import { errorHandlerWrapper, constructTwoDecimalsRule, generateFeeFromDisplay } from 'utils/general';
import { putRentalUnit } from 'utils/apis/unit';

import FormInput from 'components/FormInput/FormInput';
import intl from 'react-intl-universal';

const EditRentalUnitModal = ({ form, isShowingModal, selectedRentalUnit, closeModal, onEditRentalUnitSuccess, checkIsAdminReadOnly }) => {
  const handleOnEditRentalUnit = e => {
    e.preventDefault();

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const successNotification = () =>
          notification.success({
            duration: 3,
            message: intl.get('listings.property.message.success').d('Success'),
            description: intl.get('listings.property.message.successDesc').d("You've successfully updated this unit's rental detail.")
          });

        const rentalFee = generateFeeFromDisplay(values.rentalFee);
        const cleaningFee = generateFeeFromDisplay(values.cleaningFee);

        const payload = {
          rentalDetail: {
            rentalFee,
            cleaningFee,
            contactNumber: values.contactNumber,
            details: values.details
          }
        };

        errorHandlerWrapper(
          putRentalUnit(selectedRentalUnit._id, payload).then(() => {
            onEditRentalUnitSuccess();
            successNotification();
            closeModal();
          })
        );
      }
    });
  };

  return (
    <Modal
      title={intl.get('listings.headerLabels.rentalDetails').d('Rental Details')}
      visible={isShowingModal}
      okText={intl.get('listings.unit.headerLabels.save').d('Save')}
      onOk={handleOnEditRentalUnit}
      okButtonProps={{ id: 'save-button8-editrental', disabled: checkIsAdminReadOnly() }}
      cancelButtonProps={{ id: 'cancel-button1-editrental' }}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form>
        <FormInput
          form={form}
          name="unitName"
          formLabel={intl.get('listings.tableColumns.unit').d('Unit Name')}
          defaultValue={selectedRentalUnit.unitName}
          disabled
        />
        <FormInput
          form={form}
          name="propertyName"
          formLabel={intl.get('listings.tableColumns.property').d('Property Name')}
          defaultValue={selectedRentalUnit.propertyName}
          disabled
        />
        <FormInput
          form={form}
          name="roomTypeName"
          formLabel={intl.get('listings.tableColumns.roomType').d('Room Type Name')}
          defaultValue={selectedRentalUnit.roomTypeName}
          disabled
        />
        <FormInput
          form={form}
          name="weekdayPrice"
          formLabel={intl.get('listings.tableColumns.weekday').d('Weekday Price')}
          defaultValue={selectedRentalUnit.weekdayPrice}
          disabled
        />
        <FormInput
          form={form}
          name="weekendPrice"
          formLabel={intl.get('listings.tableColumns.weekend').d('Weekend Price')}
          defaultValue={selectedRentalUnit.weekendPrice}
          disabled
        />
        <FormInput
          form={form}
          name="rentalFee"
          formLabel={intl.get('listings.tableColumns.rental').d('Rental')}
          defaultValue={selectedRentalUnit.rentalFee}
          addonBefore={selectedRentalUnit.currency || 'RM'}
          extraRules={[constructTwoDecimalsRule()]}
          placeholder="0.00"
        />
        <FormInput
          form={form}
          name="cleaningFee"
          formLabel={intl.get('listings.tableColumns.cleaning').d('Cleaning Fee')}
          defaultValue={selectedRentalUnit.cleaningFee}
          addonBefore={selectedRentalUnit.currency || 'RM'}
          extraRules={[constructTwoDecimalsRule()]}
          placeholder="0.00"
        />
        <FormInput
          form={form}
          name="contactNumber"
          formLabel={intl.get('listings.tableColumns.contact').d('Contact No.')}
          defaultValue={selectedRentalUnit.contactNumber}
          placeholder="+603 7886 7829"
        />
        <FormInput
          form={form}
          name="details"
          formLabel={intl.get('listings.tableColumns.details').d('Details')}
          defaultValue={selectedRentalUnit.details}
          inputType="textarea"
          placeholder={intl.get('listings.property.placeholder.details').d('Details (Optional)')}
        />
      </Form>
    </Modal>
  );
};

EditRentalUnitModal.propTypes = {
  form: PropTypes.object.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  selectedRentalUnit: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onEditRentalUnitSuccess: PropTypes.func.isRequired
};

export default Form.create()(EditRentalUnitModal);
