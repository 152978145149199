import React, { useState, useMemo } from 'react';
import { Row, Col, Modal, Form, message } from 'antd';
import moment from 'moment';
import { CSVDownload } from 'react-csv';
import { useFetchConstant } from 'hooks/constants';
import FormDatePickerRange from '../../../../components/FormDatePickerRange/FormDatePickerRange';
import FormSelector from '../../../../components/FormSelection/FormSelection';
import { getTaskListWithDateRange } from '../../../../utils/apis/taskManagement';
import intl from 'react-intl-universal';

const DownloadCSVModal = props => {
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [data, setData] = useState();
  const { selection: taskStatusEnum, isLoading: taskStatusEnumLoading, refetch } = useFetchConstant('taskStatus');

  const handleOK = async () => {
    const values = await props.form.validateFields();
    console.log(values, 18);
    const startDate = values.dateRange[0].locale('en').format('DD-MMM-YYYY');
    const endDate = values.dateRange[1].locale('en').format('DD-MMM-YYYY');
    setLoading(true);
    const res = await getTaskListWithDateRange(values.status, startDate, endDate);
    //console.log(res);
    setLoading(false);

    if (res.status === 200) {
      setData(res.data);
      setReady(true);
      Modal.success({
        title: intl.get('taskManagement.taskList.headerLabels.downloadSuccessful').d('Download successful'),
        onOk: () => {
          props.onCancel();
        }
      });
    } else {
      message.error(intl.get('taskManagement.taskList.headerLabels.downloadError').d('Encounter error, please try again'));
    }
  };

  const constructCSV = tasklists => {
    console.log(tasklists);
    return tasklists.map(tasklist => {
      return {
        [intl.get('csv.creationDate').d('Creation Date')]: tasklist.createdAt,
        [intl.get('csv.unitName').d('Unit Name')]: tasklist.unitName,
        [intl.get('csv.propertyName').d('Property Name')]: tasklist.property,
        [intl.get('csv.teamName').d('Team Name')]: tasklist.teamName,
        [intl.get('csv.cleaningStatus').d('Status')]: intl
          .get(`taskManagement.taskList.tableColumns.statusOptions.${tasklist.status}`)
          .d(tasklist.status),
        [intl.get('csv.cleaner').d('Cleaner')]: tasklist.cleanerName,
        [intl.get('csv.priority').d('Priority')]: intl.get(`taskManagement.taskList.priority.${tasklist.priority}`).d(tasklist.priority),
        [intl.get('csv.completionDateTime').d('Completion Date Time')]: tasklist.finalCompletedAt
      };
    });
  };

  const formComponent = (
    <Form>
      <FormSelector
        form={props.form}
        name="status"
        formLabel={intl.get('taskManagement.taskList.headerLabels.taskStatus').d('Task Status : ')}
        allowClear={false}
        multipleMode
        selections={taskStatusEnum}
        defaultValue={['pending', 'completed', 'inProgress']}
        shouldDisable={loading}
      />
      <FormDatePickerRange
        form={props.form}
        label={intl.get('taskManagement.taskList.headerLabels.dateToExport').d('Select date to export : ')}
        fieldName="dateRange"
        requiredErrorMessage={intl.get('taskManagement.taskList.headerLabels.dateMsg').d('Please select date range.')}
        defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
        disabled={loading}
      />
    </Form>
  );
  return (
    <Modal
      onOk={handleOK}
      visible={props.visible}
      onCancel={props.onCancel}
      title={intl.get('taskManagement.taskList.headerLabels.exportTask').d('Export Task Record')}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Row>
        <Col>{formComponent}</Col>
      </Row>
      {ready && <CSVDownload filename={'my-file.csv'} data={constructCSV(data)} target="_blank" />}
    </Modal>
  );
};

export default Form.create()(DownloadCSVModal);
