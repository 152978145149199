import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';

import { MONTHS } from 'utils/constants';
import { checkIsDate, getTodayMoment } from 'utils/date';
import { guard, getConstantLabel, numberWithCommas } from 'utils/general';
import intl from 'react-intl-universal';

import ExpandedDetails from './components/ExpandedDetails/ExpandedDetails';
import PayoutInvoice from './components/PayoutInvoice/PayoutInvoice';

import styles from './PayoutDetailCard.module.css';

const calculateOtherCharges = reservationCharges => {
  const chargesDisplayed = ['total', 'rental', 'extraGuest', 'cleaning'];

  return Object.keys(reservationCharges).reduce((total, key) => {
    const charges = reservationCharges[key];

    if (!chargesDisplayed.includes(key)) {
      return total + charges;
    } else {
      return total;
    }
  }, 0);
};

const CardTitle = ({ propertyName, unitName, totalRevenue, onClickTitle, currency = 'RM' }) => {
  return (
    <div className={styles.title} onClick={onClickTitle}>
      <Row type="flex" justify="space-between">
        <Col span={12} className={styles.titleProperty}>
          {propertyName}
        </Col>
        <Col span={12} className={styles.titleTotal}>
          {intl.get('payout.headerLabels.cuurentCollected').d('Current Collected to Date')}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={12} className={styles.titleUnit}>
          {unitName}
        </Col>
        <Col span={12} className={styles.titleTotalAmount}>
          {currency} {<span className={styles.titleTotalAmountNumber}>{totalRevenue}</span>}
        </Col>
      </Row>
    </div>
  );
};

const CardActionBar = ({ isCardExpand, onClickCardAction }) => {
  return (
    <span className={styles.actionLink} onClick={onClickCardAction}>
      {isCardExpand ? intl.get('payout.headerLabels.lessDetail').d('Less details') : intl.get('payout.headerLabels.moreDetail').d('More details')}
    </span>
  );
};

const GeneralDetails = ({
  year,
  month,
  nettProfitInString,
  perUnitRevenueInString,
  integrationSourceConstants,
  packageTypeConstants,
  revenueShareConstants,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  shouldShowEarning,
  onClickGeneralDetails,
  shouldSplitBySqft
}) => {
  const className = shouldShowEarning ? styles.generalDetailsTextEarning : styles.generalDetailsTextNoEarning;
  const message = shouldShowEarning ? (
    <>
      {intl.get('payout.headerLabels.youEarn').d('You earn')}
      <Col>
        <span className={styles.titleTotalAmount}>{payoutDetail.unit.currency} </span>
        {shouldSplitBySqft ? perUnitRevenueInString : nettProfitInString}
      </Col>
    </>
  ) : (
    <>
      {intl.get('payout.headerLabels.generate').d('Generate AFTER END of')}{' '}
      {intl.get(`payout.months.${getConstantLabel(MONTHS, month)}`).d(getConstantLabel(MONTHS, month))} {year}
    </>
  );

  return (
    <div>
      <Row type="flex" justify="space-between" className={`${styles.generalDetailsText} ${className}`} onClick={onClickGeneralDetails}>
        {message}
      </Row>
      <div className={styles.generalDetailsButton}>
        <PayoutInvoice
          integrationSourceConstants={integrationSourceConstants}
          packageTypeConstants={packageTypeConstants}
          revenueShareConstants={revenueShareConstants}
          selectedHostImage={selectedHostImage}
          selectedOwnerName={selectedOwnerName}
          payoutDetail={payoutDetail}
          calculateOtherCharges={calculateOtherCharges}
        />
      </div>
    </div>
  );
};

class PayoutDetailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardExpand: false
    };
  }

  expandCollapseCard = () => {
    this.setState({
      isCardExpand: !this.state.isCardExpand
    });
  };

  render() {
    const {
      year,
      month,
      integrationSourceConstants,
      packageTypeConstants,
      revenueShareConstants,
      selectedHostImage,
      selectedOwnerName,
      payoutDetail,
      shouldShowEarning
    } = this.props;
    const { isCardExpand } = this.state;

    const hasProtection = guard(() => !!payoutDetail.protection.certNo);
    const isProtectionActive = hasProtection && checkIsDate('after', payoutDetail.protection.insuranceEndDate, getTodayMoment());

    const shouldSplitBySqft = payoutDetail.servicePackage.splitBySqft;

    const perUnitRevenueInString = numberWithCommas(payoutDetail.perUnitRevenue);
    const nettProfitInString = numberWithCommas(payoutDetail.nettProfit);

    return (
      <div className={styles.cardWrapper}>
        <Card
          className={`${styles.card} ${hasProtection ? styles.cardHostProtected : styles.cardHostUnprotected}`}
          title={
            <CardTitle
              propertyName={payoutDetail.property.name}
              unitName={payoutDetail.unit.name}
              currency={payoutDetail.unit.currency}
              totalRevenue={perUnitRevenueInString}
              onClickTitle={this.expandCollapseCard}
            />
          }
          actions={[<CardActionBar isCardExpand={isCardExpand} onClickCardAction={this.expandCollapseCard} />]}
        >
          {isCardExpand && (
            <ExpandedDetails
              year={year}
              month={month}
              payoutDetail={payoutDetail}
              hasProtection={hasProtection}
              isProtectionActive={isProtectionActive}
              shouldSplitBySqft={shouldSplitBySqft}
              calculateOtherCharges={calculateOtherCharges}
            />
          )}
          {
            <GeneralDetails
              year={year}
              month={month}
              nettProfitInString={nettProfitInString}
              perUnitRevenueInString={perUnitRevenueInString}
              integrationSourceConstants={integrationSourceConstants}
              packageTypeConstants={packageTypeConstants}
              revenueShareConstants={revenueShareConstants}
              selectedHostImage={selectedHostImage}
              selectedOwnerName={selectedOwnerName}
              payoutDetail={payoutDetail}
              shouldShowEarning={shouldShowEarning}
              onClickGeneralDetails={this.expandCollapseCard}
              shouldSplitBySqft={shouldSplitBySqft}
            />
          }
        </Card>
      </div>
    );
  }
}

PayoutDetailCard.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  integrationSourceConstants: PropTypes.array.isRequired,
  packageTypeConstants: PropTypes.array.isRequired,
  revenueShareConstants: PropTypes.array.isRequired,
  selectedHostImage: PropTypes.string.isRequired,
  selectedOwnerName: PropTypes.string.isRequired,
  payoutDetail: PropTypes.object.isRequired,
  shouldShowEarning: PropTypes.bool.isRequired
};

export default PayoutDetailCard;
