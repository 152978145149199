import React, { useState, useEffect } from 'react';
import { Form, Modal, Spin, message } from 'antd';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import { useGetPaginatedHosts } from 'utils/apis/host';
import { createAgodaChainID, updateAgodaChainID } from 'utils/apis/agodaOnboarding';
import intl from 'react-intl-universal';

const CreateChainIDModal = props => {
  const [loading, setLoading] = useState(false);
  const hosts = useGetPaginatedHosts({
    skip: 0,
    limit: 0
  });

  console.log(hosts, 14);
  useEffect(() => {
    setTimeout(() => {
      if (props.mode === 'edit') {
        props.form.setFieldsValue({
          host: props.selectedRecord.host._id,
          chainID: props.selectedRecord.chainID
        });
      }
    }, 500);
  }, [props.mode, props.selectedRecord]);

  const createHost = async () => {
    try {
      const values = await props.form.validateFields();
      setLoading(true);
      const res = await createAgodaChainID(values);
      setLoading(false);
      if (res.status !== 200) {
        return message.error(res.response.data.message);
      }
      props.form.resetFields();
      Modal.success({
        title: intl.get('hostConnect.agoda.message.updateTitle').d('Success'),
        content: intl.get('hostConnect.agoda.message.createContent').d('Create chain ID successfully'),
        onOk: () => {
          window.location.reload();
        }
      });
    } catch (error) {
      //   console.log(error, 28);
    }
  };

  const updateHost = async () => {
    try {
      const values = await props.form.validateFields();
      setLoading(true);
      const res = await updateAgodaChainID(values);
      setLoading(false);
      console.log(res, 54);
      if (res.status !== 200) {
        return message.error(res.response.data.message);
      }
      props.form.resetFields();
      Modal.success({
        title: intl.get('hostConnect.agoda.message.updateTitle').d('Success'),
        content: intl.get('hostConnect.agoda.message.updateContent').d('Update chain ID successfully'),
        onOk: () => {
          window.location.reload();
        }
      });
    } catch (error) {
      //   console.log(error, 28);
    }
  };

  return (
    <Modal
      title={intl.get('hostConnect.agoda.headerLabels.createChain').d('Create Chain ID')}
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={() => {
        if (props.mode === 'edit') {
          updateHost();
        } else {
          createHost();
        }
      }}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <FormSelection
            label={intl.get('hostConnect.agoda.headerLabels.host').d('Host')}
            name="host"
            form={props.form}
            selections={hosts.paginatedData.map(host => ({
              key: host._id,
              value: host.name
            }))}
            requiredErrorMessage={intl.get('hostConnect.agoda.placeholder.host').d('Host')}
          />

          <FormInput
            label={intl.get('hostConnect.agoda.headerLabels.chainId').d('Chain ID')}
            name="chainID"
            placeholder={'1234'}
            form={props.form}
            requiredErrorMessage={intl.get('hostConnect.agoda.placeholder.chainIdMsg').d('Please input chain ID')}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

export default Form.create()(CreateChainIDModal);
