import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, Modal, notification, Row, Skeleton, Alert } from 'antd';

import { getRateModifierTypeConstant, getRateModifierSignConstant } from 'utils/apis/constants';
import { getTraveloka, getTravelokas, putSyncTraveloka } from 'utils/apis/integration';
import { getSTDRateByRoomType } from 'utils/apis/rate';
import { checkIsObjectEmpty, errorHandlerWrapper, generatePercentageFromDisplay, guard } from 'utils/general';

import HotelDetails from './HotelDetails/HotelDetails';
import RoomListing from './RoomListing/RoomListing';
import TravelokaAdvancedOptionsModal from './TravelokaAdvancedOptionsModal/TravelokaAdvancedOptionsModal';

import styles from './TravelokaForm.module.css';
import intl from 'react-intl-universal';

const fetchAndConstructTravelokaRoomRates = async (travelokaRoomRates, roomTypeId) => {
  const isAllRoomHasRate = !travelokaRoomRates.find(travelokaRoomRate => !travelokaRoomRate.rate);
  const stdRate = isAllRoomHasRate ? undefined : await getSTDRateByRoomType(roomTypeId, { fields: ['weekday', 'weekend'] });

  return travelokaRoomRates.map(travelokaRoomRate => ({
    ...travelokaRoomRate,
    rate: travelokaRoomRate.rate || { weekday: stdRate.weekday, weekend: stdRate.weekend, isDerived: false }
  }));
};

// ===================================================== Others UseEffect functions
const useFetchConstants = () => {
  const [isLoadingConstants, setIsLoadingConstants] = useState(true);
  const [rateModifierTypeConstants, setRateModifierTypeConstants] = useState({});
  const [rateModifierSignConstants, setRateModifierSignConstants] = useState({});

  const fetchConstants = useCallback(async () => {
    setIsLoadingConstants(true);

    const rateModifierTypeConstants = await getRateModifierTypeConstant().then(constant => Object.values(constant));
    const rateModifierSignConstants = await getRateModifierSignConstant().then(constant => Object.values(constant));

    setRateModifierTypeConstants(rateModifierTypeConstants);
    setRateModifierSignConstants(rateModifierSignConstants);
    setIsLoadingConstants(false);
  }, []);

  useEffect(() => {
    fetchConstants();
  }, [fetchConstants]);

  return { isLoadingConstants, rateModifierTypeConstants, rateModifierSignConstants };
};

const useFetchTraveloka = (isLoadingDefaultData, selectedTravelokaId) => {
  const [traveloka, setTraveloka] = useState({});
  const [isLoadingTraveloka, setIsLoadingTraveloka] = useState(false);

  const fetchTraveloka = useCallback(async selectedTravelokaId => {
    setIsLoadingTraveloka(true);

    const traveloka = await getTraveloka(selectedTravelokaId, { fields: ['rooms', 'travelokaActualPropertyId', 'travelokaPropertyName'] });
    setTraveloka(traveloka);

    setIsLoadingTraveloka(false);
  }, []);

  useEffect(() => {
    if (!isLoadingDefaultData && !!selectedTravelokaId) {
      fetchTraveloka(selectedTravelokaId);
    }
  }, [isLoadingDefaultData, selectedTravelokaId, fetchTraveloka]);

  return { isLoadingTraveloka, traveloka };
};

const useFetchTravelokas = propertyId => {
  const [travelokasSelection, setTravelokasSelection] = useState([]);
  const [isLoadingTravelokasSelection, setIsLoadingTravelokasSelection] = useState(true);

  const fetchTravelokas = useCallback(async () => {
    setIsLoadingTravelokasSelection(true);

    const travelokasSelection = await getTravelokas({ query: { propertyId }, fields: ['travelokaPropertyName', 'isSynced'] }).then(travelokas =>
      travelokas.map(traveloka => ({ value: traveloka._id, label: traveloka.travelokaPropertyName, isDisabled: traveloka.isSynced }))
    );

    setTravelokasSelection(travelokasSelection);

    setIsLoadingTravelokasSelection(false);
  }, [propertyId]);

  useEffect(() => {
    fetchTravelokas();
  }, [fetchTravelokas]);

  return { isLoadingTravelokasSelection, travelokasSelection };
};

const useRoomsMapping = (isEdit, shouldProcessRoomTypeDetais, roomTypes, integratedRooms, travelokaRooms) => {
  const [roomsMapping, setRoomsMapping] = useState([]);

  const constructRoomsMappingDetails = useCallback(() => {
    const roomsMapping = roomTypes.map(roomType => {
      const integratedRoom = isEdit ? integratedRooms.find(integratedRoom => integratedRoom.roomType._id === roomType._id) : undefined;
      const integratedTravelokaRoom = !!integratedRoom
        ? travelokaRooms.find(travelokaRoom => travelokaRoom.travelokaRoomId === integratedRoom.travelokaRoomId)
        : undefined;

      return {
        _id: roomType._id,
        name: roomType.name,
        isToggled: !!integratedTravelokaRoom,
        formData: {
          ...(!!integratedTravelokaRoom && {
            travelokaRoomId: integratedTravelokaRoom.travelokaRoomId,
            travelokaRates: integratedTravelokaRoom.rates
          })
        }
      };
    });

    setRoomsMapping([...roomsMapping]);
  }, [isEdit, roomTypes, integratedRooms, travelokaRooms]);

  useEffect(() => {
    if (shouldProcessRoomTypeDetais) {
      constructRoomsMappingDetails();
    }
  }, [shouldProcessRoomTypeDetais, constructRoomsMappingDetails]);

  const rateDistributionRoomsMapping = useMemo(() => roomsMapping.filter(roomMapping => !!roomMapping.formData.travelokaRoomId), [roomsMapping]);

  return { roomsMapping, rateDistributionRoomsMapping, setRoomsMapping };
};

const useTravelokaRoomSelection = (shouldProcessRoomTypeDetais, travelokaRooms, roomsMapping) => {
  const travelokaRoomsSelection = useMemo(
    () =>
      shouldProcessRoomTypeDetais
        ? travelokaRooms.map(travelokaRoom => ({
            label: travelokaRoom.travelokaRoomName,
            value: travelokaRoom.travelokaRoomId,
            isDisabled: !!roomsMapping.find(roomMapping => roomMapping.formData.travelokaRoomId === travelokaRoom.travelokaRoomId)
          }))
        : [],
    [shouldProcessRoomTypeDetais, travelokaRooms, roomsMapping]
  );

  return { travelokaRoomsSelection };
};

// ===================================================== Main UseEffect functions
const useFetchDefaultData = (propertyId, selectedHotelIntegration) => {
  const isEdit = useMemo(() => !!selectedHotelIntegration, [selectedHotelIntegration]);
  const integratedTravelokaId = useMemo(() => (isEdit ? selectedHotelIntegration._id : undefined), [isEdit, selectedHotelIntegration]);
  const integratedRooms = useMemo(() => (isEdit ? selectedHotelIntegration.roomTypes : undefined), [isEdit, selectedHotelIntegration]);

  const { isLoadingConstants, rateModifierTypeConstants, rateModifierSignConstants } = useFetchConstants();
  const { isLoadingTravelokasSelection, travelokasSelection } = useFetchTravelokas(propertyId);

  const isLoadingDefaultData = useMemo(() => isLoadingConstants || isLoadingTravelokasSelection, [isLoadingConstants, isLoadingTravelokasSelection]);

  return {
    isEdit,
    isLoadingDefaultData,
    integratedTravelokaId,
    integratedRooms,
    rateModifierTypeConstants,
    rateModifierSignConstants,
    travelokasSelection
  };
};

const usePropertyDetails = (isLoadingDefaultData, integratedTravelokaId) => {
  const [selectedTravelokaId, setSelectedTravelokaId] = useState(integratedTravelokaId);

  const { isLoadingTraveloka, traveloka } = useFetchTraveloka(isLoadingDefaultData, selectedTravelokaId);

  const isLoadingPropertyDetails = useMemo(() => isLoadingTraveloka, [isLoadingTraveloka]);

  return { isLoadingPropertyDetails, selectedTravelokaId, traveloka, setSelectedTravelokaId };
};

const useRoomTypesDetails = (isLoadingPropertyDetails, isEdit, property, integratedRooms, travelokaRooms) => {
  const hasRoomTypes = useMemo(() => !!property.roomTypes && property.roomTypes.length > 0, [property]);
  const shouldProcessRoomTypeDetais = useMemo(() => !isLoadingPropertyDetails && hasRoomTypes && !!travelokaRooms, [
    isLoadingPropertyDetails,
    hasRoomTypes,
    travelokaRooms
  ]);

  const { roomsMapping, rateDistributionRoomsMapping, setRoomsMapping } = useRoomsMapping(
    isEdit,
    shouldProcessRoomTypeDetais,
    property.roomTypes,
    integratedRooms,
    travelokaRooms
  );
  const { travelokaRoomsSelection } = useTravelokaRoomSelection(shouldProcessRoomTypeDetais, travelokaRooms, roomsMapping);

  const canEdit = useMemo(
    () => guard(() => roomsMapping.length > 0 && roomsMapping.find(roomMapping => roomMapping.formData.travelokaRoomId), false),
    [roomsMapping]
  );

  return { hasRoomTypes, canEdit, travelokaRoomsSelection, travelokaRooms, roomsMapping, rateDistributionRoomsMapping, setRoomsMapping };
};

// ===================================================== Components
const Footer = ({ canEdit, onAdvanceOptionsButtonClick, onSubmit, onClose }) => {
  const handleOnSave = e => {
    e.preventDefault();
    Modal.confirm({
      title: intl.get('hostConnect.integration.message.saveBooking').d('Are you sure you want to save this integration setting?'),
      content: '',
      okText: intl.get('hostConnect.integration.headerLabels.continue').d('Continue'),
      okType: 'warning',
      cancelText: intl.get('hostConnect.integration.headerLabels.back').d('Back'),
      onOk: onSubmit,
      onCancel: () => {}
    });
  };

  return (
    <>
      {canEdit && (
        <Row>
          <Button type="link" onClick={onAdvanceOptionsButtonClick}>
            {intl.get('hostConnect.integration.headerLabels.advanced').d('Advanced Options')}
          </Button>
        </Row>
      )}
      <Row gutter={12} type="flex" justify="end">
        <Col>
          <Button type="secondary" onClick={onClose}>
            {intl.get('hostConnect.integration.headerLabels.cancel').d('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" disabled={!canEdit} onClick={handleOnSave}>
            {intl.get('hostConnect.integration.headerLabels.save').d('Save')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const TravelokaForm = ({ property, selectedHotelIntegration, onAfterSave, onClose }) => {
  const [isAdvancedOptionsModalVisible, setIsAdvancedOptionsModalVisible] = useState(false);

  const {
    isEdit,
    isLoadingDefaultData,
    integratedTravelokaId,
    integratedRooms,
    rateModifierTypeConstants,
    rateModifierSignConstants,
    travelokasSelection
  } = useFetchDefaultData(property._id, selectedHotelIntegration);
  const { isLoadingPropertyDetails, selectedTravelokaId, traveloka, setSelectedTravelokaId } = usePropertyDetails(
    isLoadingDefaultData,
    integratedTravelokaId
  );
  const {
    hasRoomTypes,
    canEdit,
    travelokaRoomsSelection,
    travelokaRooms,
    roomsMapping,
    rateDistributionRoomsMapping,
    setRoomsMapping
  } = useRoomTypesDetails(isLoadingPropertyDetails, isEdit, property, integratedRooms, traveloka.rooms);

  const handleOnToggleRoomSync = roomTypeId => isToggled => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    roomsMapping[roomMappingToUpdateIndex] = { ...roomsMapping[roomMappingToUpdateIndex], isToggled, formData: {} };

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnSelectTravelokaRoom = roomTypeId => async travelokaRoomId => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    const roomMappingToUpdate = roomsMapping[roomMappingToUpdateIndex];
    const selectedTravelokaRoom = !!travelokaRoomId
      ? travelokaRooms.find(travelokaRoom => travelokaRoom.travelokaRoomId === travelokaRoomId)
      : undefined;

    roomsMapping[roomMappingToUpdateIndex] = {
      ...roomMappingToUpdate,
      isToggled: roomMappingToUpdate.isToggled,
      formData: {
        ...(!!selectedTravelokaRoom && {
          travelokaRoomId: selectedTravelokaRoom.travelokaRoomId,
          travelokaRates: await fetchAndConstructTravelokaRoomRates(selectedTravelokaRoom.rates, roomTypeId)
        })
      }
    };

    setRoomsMapping([...roomsMapping]);
  };

  const handleonChangeRate = (roomTypeId, travelokaRateId) => newRates => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    const roomMappingToUpdate = roomsMapping[roomMappingToUpdateIndex];
    const roomRateToUpdateIndex = roomMappingToUpdate.formData.travelokaRates.findIndex(
      travelokaRate => travelokaRate.travelokaRateId === travelokaRateId
    );
    const roomRateToUpdate = roomMappingToUpdate.formData.travelokaRates[roomRateToUpdateIndex];

    roomsMapping[roomMappingToUpdateIndex].formData.travelokaRates[roomRateToUpdateIndex].rate = {
      ...roomRateToUpdate.rate,
      ...newRates
    };

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnAdvanceOptionsButtonClick = () => {
    setIsAdvancedOptionsModalVisible(true);
  };

  const handleOnAdvancedOptionsConfirm = ({ rateDistributionPayload }) => {
    rateDistributionPayload.forEach(rateDistribution => {
      const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === rateDistribution.roomId);
      const roomMappingRatesToUpdate = roomsMapping[roomMappingToUpdateIndex].formData.travelokaRates;

      roomsMapping[roomMappingToUpdateIndex].formData.travelokaRates = roomMappingRatesToUpdate.map(travelokaRate => {
        const travelokaRateUpdateData = rateDistribution.rates.find(
          travelokaRateUpdateData => String(travelokaRateUpdateData.otaId) === String(travelokaRate.travelokaRateId)
        );
        const isDerived = travelokaRateUpdateData.rate.isDerived;

        const processedRoomRateObject = {
          ...travelokaRate,
          rate: {
            weekday: travelokaRateUpdateData.rate.weekdayRate,
            weekend: travelokaRateUpdateData.rate.weekendRate,
            isDerived
          }
        };

        if (isDerived) {
          const isPercentage = !!rateModifierTypeConstants.find(type => type.code === travelokaRateUpdateData.rate.modifierType && type.isPercentage);
          const isPositive = !!rateModifierSignConstants.find(sign => sign.code === travelokaRateUpdateData.rate.modifierSign && sign.isPositive);
          const displayAmount = travelokaRateUpdateData.rate.modifierAmount;
          const amount = isPercentage ? generatePercentageFromDisplay(displayAmount) : displayAmount;

          processedRoomRateObject.rate.calculation = {
            type: travelokaRateUpdateData.rate.modifierType,
            isPositive,
            amount
          };
        }

        return processedRoomRateObject;
      });
    });

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnAdvancedOptionsClose = () => {
    setIsAdvancedOptionsModalVisible(false);
  };

  const handleOnSubmit = async () => {
    const roomsMappingPayload = roomsMapping
      .filter(roomMapping => !checkIsObjectEmpty(roomMapping.formData))
      .map(roomMapping => ({ _id: roomMapping._id, formData: roomMapping.formData }));

    errorHandlerWrapper(
      putSyncTraveloka(selectedTravelokaId, {
        propertyId: property._id,
        roomsMapping: roomsMappingPayload
      }).then(() => {
        notification.success({
          message: intl.get('hostConnect.traveloka.message.successSync').d('Successfully synced traveloka for the selected property and room types')
        });
        onAfterSave();
        onClose();
      })
    );
  };

  return (
    <>
      {isLoadingDefaultData ? (
        <Skeleton active loading />
      ) : (
        <div className="scroll-bar-style">
          <div className={styles.root}>
            <Row>
              <Alert
                type="warning"
                message={intl.get('hostConnect.integration.headerLabels.warning').d('Warning')}
                description={intl
                  .get('hostConnect.traveloka.message.warningDesc')
                  .d(
                    'Please make sure Traveloka booking is created in HostPlatform BEFORE sync to prevent double booking as Traveloka DOES NOT provide an API to pull all your booking(s).'
                  )}
                showIcon
              />
            </Row>
            <Row className={styles.row}>
              <HotelDetails
                isEdit={isEdit}
                travelokasSelection={travelokasSelection}
                selectedTravelokaId={selectedTravelokaId}
                selectedTraveloka={traveloka}
                setSelectedTravelokaId={setSelectedTravelokaId}
              />
            </Row>
            <Row className={styles.row}>
              {!hasRoomTypes ? (
                <Empty
                  description={intl
                    .get('hostConnect.traveloka.message.noSyncDesc')
                    .d('There is no room type for the selected property, you can still proceed to sync this property to Traveloka')}
                />
              ) : !selectedTravelokaId ? (
                <Empty
                  description={intl
                    .get('hostConnect.traveloka.message.selectHotelDesc')
                    .d('Please select a Traveloka hotel to begin to sync room types')}
                />
              ) : isLoadingPropertyDetails ? (
                <Skeleton active loading />
              ) : (
                <RoomListing
                  travelokaRoomsSelection={travelokaRoomsSelection}
                  roomsMapping={roomsMapping}
                  onToggleRoom={handleOnToggleRoomSync}
                  onSelectTravelokaRoom={handleOnSelectTravelokaRoom}
                  onChangeRate={handleonChangeRate}
                />
              )}
            </Row>
            <Row className={styles.row}>
              <Footer canEdit={canEdit} onAdvanceOptionsButtonClick={handleOnAdvanceOptionsButtonClick} onSubmit={handleOnSubmit} onClose={onClose} />
            </Row>
          </div>
        </div>
      )}
      {isAdvancedOptionsModalVisible && (
        <TravelokaAdvancedOptionsModal
          isVisible={isAdvancedOptionsModalVisible}
          rateModifierTypeConstants={rateModifierTypeConstants}
          rateModifierSignConstants={rateModifierSignConstants}
          roomsMapping={rateDistributionRoomsMapping}
          onConfirm={handleOnAdvancedOptionsConfirm}
          onClose={handleOnAdvancedOptionsClose}
        />
      )}
    </>
  );
};

export default TravelokaForm;
